import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { Provider } from 'react-redux';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import 'react-toastify/dist/ReactToastify.css';

import utils from 'utils';

import ClientChatNotifications from 'components/ClientChatNotifications';

import { CalcHeightContextProvider } from 'components/order/CalcHeightContext';

import MainNav from './components/MainNav/MainNav';
import DataPreload from './components/DataPreload';

import MyOrdersPage from './pages/MyOrdersPage';
import InboxPage from './pages/InboxPage';
import ProgressPage from './pages/ProgressPage';
import FinancesPage from './pages/FinancesPage';
import HelpPage from './pages/HelpPage';
import IssueResolution from './pages/IssueResolution';
import OrderStandalonePage from './pages/OrderStandalonePage';
import CommonFooter from './components/CommonFooter';
import Profile from './components/profile/Profile';
import Agreement from './components/agreement/Agreement';
import SuspiciousAlert from './components/SuspiciousAlert';
import TutorRecruitingBanner from './components/TutorRecruitingBanner';
import ShowAlert from './components/ShowAlert';
import SessionExtendModal from './components/SessionExtendModal';
import DNDModeContainer from './components/DNDModeContainer';
import GotoMobileNotification from './components/GotoMobileNotification';
import WriterPoll from './components/WriterPoll';
import LoginPage from './pages/LoginPage';
import FindOrdersAllPage from './pages/FindOrdersAllPage';
import PluginWrapper from './components/PluginWrapper';
import LRPixel from './components/LRPixel';
import VerifyForm from './components/VerifyForm';
import ModalContainer from './components/ModalContainer';
import WarBanner from './components/WarBanner';
import ImagePreview from './components/ImagePreview';
import FrozenModal from './components/profile/FrozenModal';
// import ProtectedByRoleRoute from './components/ProtectedByRoleRoute';
import ProtectedByDevModeRoute from './components/ProtectedByDevModeRoute';
import StoryBook from './components/StoryBook';
import BrowserNotificationsModal from './components/BrowserNotificationsModal';
import RequestPMSetupNotifications from './components/RequestPMSetupNotifications';
import InboxUpdatesModal from './components/InboxUpdatesModal';
import WisePKIntrusiveModal from './components/WisePKIntrusiveModal';

import Xmpp from './Xmpp';

import './index.scss';

const WrappedStandalonePage = props => (<CalcHeightContextProvider><OrderStandalonePage {...props} /></CalcHeightContextProvider>);


const LoggedContainer = () => (
  <DataPreload id="app">
    <ModalContainer />
    <FrozenModal />
    <SuspiciousAlert />
    <TutorRecruitingBanner />
    <WarBanner />
    <ShowAlert />
    <LRPixel />
    <ImagePreview />
    <ClientChatNotifications />
    <MainNav />
    <BrowserNotificationsModal />
    <RequestPMSetupNotifications />
    <WisePKIntrusiveModal />

    <Routes>
      <Route path="*" element={<Navigate to="findorders/all" />} />
      <Route path="/" element={<Navigate to="findorders/all" />} />
      <Route path="order/:id" element={<WrappedStandalonePage />} />
      <Route path="myorders/*" element={<MyOrdersPage />} />
      <Route path="inbox/*" element={<InboxPage />} />
      <Route path="finances/*" element={<FinancesPage />} />
      <Route path="help/*" element={<HelpPage />} />
      <Route path="issues/*" element={<IssueResolution />}>
        <Route path="*" element={<IssueResolution />} />
      </Route>
      <Route path="findorders/all" element={<FindOrdersAllPage />}>
        <Route path=":orderNumber" element={<FindOrdersAllPage />} />
      </Route>
      <Route path="learninglab/*" element={<Navigate to="../help/learninglab" replace />} />
      <Route path="progress/*" element={<ProgressPage />} />
      <Route element={<ProtectedByDevModeRoute />}>
        <Route path="story-book" element={<StoryBook />} />
      </Route>
    </Routes>

    <Agreement />
    <Profile />
    <SessionExtendModal />
    <DNDModeContainer />
    {utils.detectMob() && <GotoMobileNotification />}
    <WriterPoll />
    <PluginWrapper />
    <VerifyForm />
    <InboxUpdatesModal />
  </DataPreload>
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = props => (
  <Provider store={props.store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Xmpp>
          <div id="router">
            <Routes>
              <Route path="*" element={<LoggedContainer />} />
              <Route path="login/:token" element={<LoginPage />} />
            </Routes>
          </div>
          <CommonFooter />
        </Xmpp>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
);

export default App;
