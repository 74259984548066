import React, { useState, useMemo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { usePrevious } from 'hooks';

import { noticesActionFetch } from 'store/notices/actions';

import getNotices from 'store/notices/selector';

import classnames from 'utils/classNames';

import dropBoxImage from '../img/drop_box_image.png';

import NoticesContent from './NoticesContent';


const Notices = ({ user }) => {
  const notices = useSelector(getNotices);

  const [callBell, setCallBell] = useState(false);
  const [isOpen, toggleOpen] = useState(false);

  const dispatch = useDispatch();

  const unreadNotices = useMemo(() => notices.filter(it => it.status === 0), [notices]);
  const prevUnreadNotices = usePrevious(unreadNotices);

  const markAsRead = (ids) => {
    dispatch(noticesActionFetch(({ action: 'read', ids })));
  };

  const onShowNotices = () => {
    toggleOpen(true);
  };

  const handleDocumentClick = (e) => {
    const { target } = e;
    const className = target.getAttribute('class');

    if (className === 'icon-bell' || className === 'cnt') {
      return;
    }

    markAsRead(unreadNotices.map(it => it._id));
    toggleOpen(false);
  };

  useEffect(() => {
    if (unreadNotices.length === 0) return;

    if (!prevUnreadNotices || prevUnreadNotices.length === unreadNotices.length) return;

    if (prevUnreadNotices.length !== notices.length) {
      setCallBell(true);
    }
  }, [notices, prevUnreadNotices]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        window.root.addEventListener('click', handleDocumentClick);
      }, 10);
    } else {
      window.root.removeEventListener('click', handleDocumentClick);
    }

    return () => {
      window.root.removeEventListener('click', handleDocumentClick);
    };
  }, [isOpen]);

  return (
    <div className={classnames('header-last-warning active')} onClick={onShowNotices}>
      <button type="button">
        <span className="cnt-warnings">
          <i
            className={classnames('icon-bell', { 'bell-call-anim': callBell })}
            onAnimationEnd={() => setCallBell(false)}
          />
          {unreadNotices.length > 0 &&
            <i className="cnt">{unreadNotices.length}</i>
          }
        </span>
      </button>
      {isOpen &&
        <div className="dropdown-content">
          {notices.length > 0 && (
            <>
              <div className="dropdown-content-header">
                Notifications
              </div>
              <NoticesContent user={user} viewNotices={notices} />
              <div className="dropdown-content-footer">
                <img src={dropBoxImage} width="24px" height="24px" alt="" />
                <div className="dropdown-content-footer__text">That’s all your last your {notices.length} notifications.</div>
              </div>
            </>
          )}
        </div>
      }
    </div>
  );
};

export default Notices;
