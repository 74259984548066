import React, { Component } from 'react';

import classNames from 'utils/classNames';
import utils from 'utils';

import {
  STATUS_OPEN,
} from 'store/orders/actions';

import {
  TAB_INFO,
  TAB_MESSAGES,
  ORDER_BANNERS,
  NT_MAX_COURSE_ORDERS,
} from 'constants/order';

import InfoModal from 'components/agreement/InfoModal';

import Icon from 'components/Icon';
import Emoji from 'components/Emoji';

import { OrderErrorModal, OrderDeletedModal, OrderNaModal } from './modals';

import OrderFooter from './OrderFooter';
import OrderMessageBox from './OrderMessageBox';
import OrderReportForm from './OrderReportForm';

import OrderContent from './OrderContent';
import OrderClientChat from './OrderClientChat';
import OrderSuggestedSteps from './OrderSuggestedSteps';
import { CalcHeightContextProvider } from './CalcHeightContext';

import { getStatus } from './utils';

const bodyNoScrollClass = 'hide-scroll';

class Order extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: TAB_INFO,
      bannersQueue: [],
      isShowNtModal: null,
    };

    this.onResize = this.onResize.bind(this);
    this.onClickFavorite = this.onClickFavorite.bind(this);
    this.onNaCloseClick = this.onNaCloseClick.bind(this);
    this.onErrorCloseClick = this.onErrorCloseClick.bind(this);
    this.onRemoveOrderClick = this.onRemoveOrderClick.bind(this);
    this.showTab = this.showTab.bind(this);
    this.showIssueBanner = this.showIssueBanner.bind(this);
    this.removeBanner = this.removeBanner.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.showTutorKnowledgeBaseBanner = this.showTutorKnowledgeBaseBanner.bind(this);
    this.showCourseTutorBanner = this.showCourseTutorBanner.bind(this);
    this.showTutorModal = this.showTutorModal.bind(this);
    this.onClickNTTutorModal = this.onClickNTTutorModal.bind(this);
    this.onCloseNTTutorModal = this.onCloseNTTutorModal.bind(this);
  }

  componentDidMount() {
    const { standalone } = this.props;
    !standalone && document.body.classList.add(bodyNoScrollClass);
    const { loadDetails } = this.props;
    this.showBanner();
    loadDetails().then(() => {
      this.showIssueBanner();
      this.showTutorKnowledgeBaseBanner();
      this.showCourseTutorBanner();
      this.showTutorModal();
    });
  }

  componentDidUpdate(prevProps) {
    const {
      loadDetails, orderNumber, order, orderSetViewed, isLoading,
    } = this.props;

    if (!order) return;

    const {
      isFetching, didInvalidate, is_instructions_updated: isUpdated,
      is_socket_update: isSocketUpdate,
      is_viewed: isViewed, _id: orderId,
    } = order;

    if (isFetching) return;

    if (prevProps.orderNumber !== orderNumber) {
      this.showBanner();
      this.setState({ tab: TAB_INFO });
      this.containerRef && this.containerRef.scrollTo(0, 0);
      loadDetails(orderNumber).then(() => {
        this.showIssueBanner();
        this.showTutorKnowledgeBaseBanner();
        this.showCourseTutorBanner();
        this.showTutorModal();
      });
      return;
    }

    if (didInvalidate) {
      loadDetails(orderNumber);
      return;
    }

    if (isUpdated && isSocketUpdate) {
      this.showBanner('updateDataBanner');
    }

    if (!isViewed && !isLoading && orderSetViewed) {
      orderSetViewed(orderId);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.bannerTimeout);
    const { standalone } = this.props;
    !standalone && document.body.classList.remove(bodyNoScrollClass);
  }

  onClickFavorite() {
    const { order, setFavorite } = this.props;
    setFavorite(!order.is_favorite);
  }

  onResize() {
    this.filesStickyRef && this.filesStickyRef.handleMove();
    this.otherFilesStickyRef && this.otherFilesStickyRef.handleMove();
  }

  onNaCloseClick() {
    const { hideInLists, onSelect, orderNumber } = this.props;
    hideInLists && hideInLists(orderNumber);
    onSelect && onSelect(orderNumber);
  }

  onErrorCloseClick() {
    const { onSelect, orderNumber } = this.props;
    onSelect(orderNumber);
  }

  onRemoveOrderClick() {
    this.onErrorCloseClick();
    const { hideInLists, removeTakenNotification } = this.props;

    if (!hideInLists || !removeTakenNotification) return;

    hideInLists();
    removeTakenNotification();
  }

  onSelectHandler() {
    const { orderNumber, onSelect, updateOrderFieldHandler } = this.props;

    onSelect(orderNumber);

    if (!updateOrderFieldHandler) return;

    updateOrderFieldHandler({ fieldName: 'is_socket_update', value: false });
  }

  showTab(tab) {
    this.setState(state => Object.assign({}, state, { tab }));
  }

  showTutorKnowledgeBaseBanner() {
    const { order, user } = this.props;
    const isNTorder = utils.isNTorder(order);
    const isTutor = utils.isTutor(user);

    if (!isNTorder || isTutor) return;

    const { qc_group: userGroup } = user || {};

    if (userGroup === 'F') return;

    const tutorsCourse = utils.getTutorCourse(user);

    if (!tutorsCourse || tutorsCourse.status !== 'Completed') return;

    // Любая группа кроме F И
    // Прошел курс И
    // Нет #tutor

    this.showBanner('tutorKnowledgeBaseBanner');
  }

  showCourseTutorBanner() {
    const { order, user } = this.props;
    const isNTorder = utils.isNTorder(order);
    const isTutor = utils.isTutor(user);

    if (!isNTorder || isTutor) return;

    const { qc_group: userGroup, metric_data = {} } = user || {};
    const { completed_orders_count_nt, current_orders_count_nt } = metric_data;

    if (userGroup !== 'A' && userGroup !== 'A+') return;

    const tutorsCourse = utils.getTutorCourse(user) || {};

    if (tutorsCourse.status === 'Completed') return;

    if ((completed_orders_count_nt + current_orders_count_nt) >= NT_MAX_COURSE_ORDERS) return;

    // Группа А И
    // Курс не пройден И
    // < 10 NT заказов в статусе In  Progress, Completed, Revision И
    // Нет #tutor

    this.showBanner('courseTutorBanner');
  }

  showIssueBanner() {
    const { mobile, order } = this.props;

    const isNTorder = utils.isNTorder(order);

    if (mobile) return;
    if (getStatus(order) !== STATUS_OPEN && isNTorder) return;

    clearTimeout(this.bannerTimeout);
    const minutes = 3 * 60 * 1000;
    this.bannerTimeout = setTimeout(() => this.showBanner('issuesBanner'), minutes);
  }

  showBanner(bannerName) {
    if (!bannerName) {
      this.setState({ bannersQueue: [] });
      return;
    }

    const { bannersQueue } = this.state;

    if (bannersQueue.find(banner => banner.name === bannerName)) {
      return;
    }

    const { priority } = ORDER_BANNERS[bannerName] || {};

    this.setState(__state => ({
      ...__state,
      bannersQueue: [...__state.bannersQueue, {
        name: bannerName,
        priority,
      }].sort((a, b) => a.priority - b.priority),
    }));
  }

  showTutorModal() {
    const { order, user } = this.props;

    if (getStatus(order) !== STATUS_OPEN) return;

    const isNTorder = utils.isNTorder(order);
    const isTutor = utils.isTutor(user);

    if (!isNTorder || isTutor) return;

    const { is_recommended: isRecommended, recomended_by_client: recommendedByClient } = order;

    if (isRecommended || recommendedByClient) return;

    const { qc_group: userGroup, metric_data = {} } = user || {};
    const { completed_orders_count_nt, current_orders_count_nt } = metric_data;

    if (userGroup !== 'A' && userGroup !== 'A+') return;

    const tutorsCourse = utils.getTutorCourse(user) || {};

    if (tutorsCourse.status === 'Completed') return;

    // Группа А И
    // Курс не пройден И
    // Нет #tutor

    const NTorders = completed_orders_count_nt + current_orders_count_nt;

    if (NTorders > 0 && NTorders < NT_MAX_COURSE_ORDERS) return;


    this.setState({ isShowNtModal: NTorders === 0 ? 'NTInfoModal' : 'NTIntroductionModal' });
  }

  removeBanner(bannerName) {
    this.setState(__state => ({
      ...__state,
      bannersQueue: __state.bannersQueue.filter(banner => banner.name !== bannerName),
    }));
  }

  onClickNTTutorModal() {
    const { closeModalComponentHandler, user } = this.props;
    const { isShowNtModal } = this.state;
    const tutorsCourse = utils.getTutorCourse(user) || {};
    const { enroll_link: enrollLink = 'http://livingston-research.teachable.com/p/introduction-to-tutoring' } = tutorsCourse;
    window.open(enrollLink, '_blank').focus();

    if (!closeModalComponentHandler) return;

    closeModalComponentHandler(isShowNtModal);
    this.onCloseNTTutorModal();
  }

  onCloseNTTutorModal() {
    this.setState({ isShowNtModal: null });
  }

  render() {
    const {
      isLoading,
      orderNumber,
      error,
      onSelect,
      isLoadingAdd,
      addMessage,
      addMessageAttach,
      addMessageConfirm,
      messageList,
      standalone,
      order,
      invalidate,
      user,
      checkUser,
      mobile,
      isTaken = false,
    } = this.props;
    const { tab, bannersQueue, isShowNtModal } = this.state;

    const isNTorder = utils.isNTorder(order);

    const userGroup = user && user.group_name;
    const userStatus = user && user.view_status;

    let isPermission = (userGroup === 'A' || userGroup === 'A+' || userGroup === 'B' || userGroup === 'C' || userStatus === 'Fulltimer' || userStatus === 'Stem');
    let isShowOrder = !isPermission && ((order && order.custom_tags && order.custom_tags.indexOf('important') === -1) || (order && order.custom_tags && order.custom_tags.indexOf('important') >= 0 && (order.is_recommended || order.is_suggested)));
    if (!checkUser) {
      isPermission = true;
      isShowOrder = true;
    }

    const { jabber_node: jabberNode } = order;

    const isDisabledMessageBox = !!messageList.filter(m => m.confirm_type).length;

    const orderTabClass = classNames('order-tab', { 'order-tab--on-top': user && user.showProposed });

    if (isShowOrder || isPermission) {
      const { name: bannerName = '' } = bannersQueue[0] || {};
      const tutorsCourse = utils.getTutorCourse(user) || {};
      const { enroll_link: enrollLink = 'https://www.notion.so/lrtutors/LR-Tutors-Knowledge-Base-affb4cf7a67a4a0f8b31ef87e6cf4997' } = tutorsCourse;
      return (
        <div className={orderTabClass}>
          <div className="order-tab-head">
            {Boolean(ORDER_BANNERS[bannerName]) && order && (getStatus(order) === STATUS_OPEN || isNTorder) &&
              <div className="issue-banner" dangerouslySetInnerHTML={{ __html: ORDER_BANNERS[bannerName].text.replace('[enroll_link]', enrollLink) }} style={ORDER_BANNERS[bannerName].customStyles} />
            }
            <div className="row space-between oder-tab-head__head">
              <span className="order-number">{orderNumber}</span>
              {!isLoading && getStatus(order) === STATUS_OPEN &&
                <OrderReportForm orderNumber={orderNumber} />
              }
              {!!onSelect &&
                <button className="btn btn-close" onClick={this.onSelectHandler}><Icon className="svg-icon" iconName="close" /></button>
              }
            </div>
          </div>
          <div className="order-tab-container" onScroll={this.onResize} ref={ref => this.containerRef = ref}>
            {isLoading && <div className="order-tab-loading"><p>Loading order description</p></div>}
            {!isLoading && error && (
              <div className="order-tab-error">
                <Emoji id="crySad" />
                <h2 className="title">Oops, error loading order {orderNumber}</h2>
                <p className="notes">{error}</p>
              </div>
            )}
            <OrderContent {...this.props} __tab={tab} showTab={this.showTab} onResize={this.onResize} containerRef={this.containerRef} filesStickyRef={ref => this.filesStickyRef = ref} otherFilesStickyRef={ref => this.otherFilesStickyRef = ref} />
            {!!order && order.errorMessage &&
              <OrderNaModal onClose={() => window.location.reload(true)} message={order.errorMessage} />
            }
            {!!order && order.naError &&
              <OrderNaModal onClose={this.onNaCloseClick} />
            }
          </div>
          <div className="order-tab-footer">
            {isLoading || tab !== TAB_MESSAGES
              ? !jabberNode && <OrderFooter orderNumber={orderNumber} standalone={standalone} order={order} />
              : <OrderMessageBox
                  orderNumber={orderNumber}
                  orderId={order._id}
                  order={order}
                  orderTitle={order.title}
                  isLoadingAdd={isLoadingAdd}
                  addMessage={addMessage}
                  addMessageAttach={addMessageAttach}
                  addMessageConfirm={addMessageConfirm}
                  isDisabled={isDisabledMessageBox || order.threads_lock}
              />
            }
            {!!order && !order.naError && order.error && !isTaken &&
              <OrderErrorModal onClose={this.onErrorCloseClick} onRefresh={invalidate} />
            }
          </div>
          {isTaken && !order.naError &&
            <OrderDeletedModal onClose={this.onRemoveOrderClick} />
          }
          {
            isShowNtModal && (
              <InfoModal
                modalName={isShowNtModal}
                btnText="Take me to the course!"
                autoText
                canClose
                onNextClick={this.onClickNTTutorModal}
                closeModal={this.onCloseNTTutorModal}
              />
            )
          }
          <>
            { !isLoading && Boolean(jabberNode) && Boolean(user.jabber_jid) && !standalone &&
              <CalcHeightContextProvider>
                <OrderClientChat
                  parentRef={this.containerRef}
                  showTab={this.showTab}
                  order={order}
                  standalone={standalone}
                  mobile={mobile}
                  user={user}
                />
                <OrderSuggestedSteps
                  order={order}
                  standalone={standalone}
                  profile={user}
                />
              </CalcHeightContextProvider>
            }
          </>
        </div>
      );
    }
    return null;
  }
}

export default Order;
