import React, { useEffect, useRef, useContext } from 'react';

import { CalcHeightContext } from 'components/order/CalcHeightContext';

const HeightCalculations = ({
  standalone, chatBanner, order, reCalcByChatBanner,
}) => {
  const getDomNode = selector => document.querySelector(selector);
  const { setSuggestedStepHeight, setStandAloneContainerHeight, setChatHeight } = useContext(CalcHeightContext);

  const getAlertBanner = () => getDomNode('.suspicious-alert');
  const getOrderIssueBanner = () => getDomNode('.order-tab-head .issue-banner');

  const getDomNodeHeight = (node) => {
    if (!node) return 0;

    const { height } = node.getBoundingClientRect();
    return height;
  };

  const heightsRef = useRef({
    messageBoxHeight: 0,
    issueOrderBannerHeight: 0,
    issueOrderBanner: getOrderIssueBanner(),
    alertBanner: getAlertBanner(),
    constStandAloneHeight: (function () {
      if (!standalone) return;

      const pageLogo = getDomNode('.order-standalone-page.single-page a.logo');
      const orderTabHead = getDomNode('.oder-tab-head__head');
      const orderInfoChatContainerPadding = 20;
      const chatPadding = 10;
      const constStandAloneHeight = [pageLogo, orderTabHead].reduce((prev, next) => {
        prev += getDomNodeHeight(next);

        return prev;
      }, orderInfoChatContainerPadding + chatPadding);

      return `${constStandAloneHeight}px`;
    }()),
    unknownConstant: '70px',
  });

  useEffect(() => {
    if (!standalone) return;

    const alertBanner = getAlertBanner();
    const issueOrderBanner = getOrderIssueBanner();
    let chatBannerHeight = 0;

    if (chatBanner.current) {
      chatBannerHeight = getDomNodeHeight(chatBanner.current);
    }

    if (issueOrderBanner) {
      heightsRef.current.issueOrderBannerHeight = getDomNodeHeight(issueOrderBanner);
    }

    const {
      messageBoxHeight, constStandAloneHeight, issueOrderBannerHeight, unknownConstant,
    } = heightsRef.current;

    const constHeight = `${constStandAloneHeight} - ${messageBoxHeight}px - ${issueOrderBannerHeight}px`;


    if (!alertBanner) {
      setChatHeight(`${constHeight} - ${unknownConstant} - ${chatBannerHeight}px`);
      setSuggestedStepHeight(`${constStandAloneHeight} - ${unknownConstant} - ${issueOrderBannerHeight}px`);
      setStandAloneContainerHeight('calc(100vh)');
      return;
    }

    setSuggestedStepHeight(`${constStandAloneHeight} - ${unknownConstant} - ${getDomNodeHeight(alertBanner)}px - ${issueOrderBannerHeight}px`);
    setStandAloneContainerHeight(`calc(100vh - ${getDomNodeHeight(alertBanner)}px)`);
    setChatHeight(`${constHeight} - ${getDomNodeHeight(alertBanner)}px - 50px - ${chatBannerHeight}px`);
  }, [standalone, order, reCalcByChatBanner]);

  useEffect(() => {
    if (standalone) return;

    if (!chatBanner.current) return;

    setChatHeight(`${String(getDomNodeHeight(chatBanner.current))}px - ${heightsRef.current.messageBoxHeight}px`);
  }, [order, standalone, reCalcByChatBanner]);

  useEffect(() => {
    const messageBox = getDomNode('.client-chat-message-box');

    if (messageBox) {
      heightsRef.current.messageBoxHeight = getDomNodeHeight(messageBox);
    }

    if (!standalone) return;

    const pageLogo = getDomNode('.order-standalone-page.single-page a.logo');
    const orderTabHead = getDomNode('.oder-tab-head__head');
    const orderInfoChatContainerPadding = 20;
    const chatPadding = 10;
    const __constStandAloneHeight = [pageLogo, orderTabHead].reduce((prev, next) => {
      prev += getDomNodeHeight(next);

      return prev;
    }, orderInfoChatContainerPadding + chatPadding);

    heightsRef.current.constStandAloneHeight = `${__constStandAloneHeight}px`;

    const calcHeight = () => {
      let chatBannerHeight = 0;
      let alertBannerHeight = 0;
      const alertBanner = getAlertBanner();
      const issueOrderBanner = getOrderIssueBanner();

      if (chatBanner.current) {
        chatBannerHeight = getDomNodeHeight(chatBanner.current);
      }

      if (issueOrderBanner) {
        heightsRef.current.issueOrderBannerHeight = getDomNodeHeight(issueOrderBanner);
      }

      if (alertBanner) {
        alertBannerHeight = getDomNodeHeight(alertBanner);
      }

      const {
        messageBoxHeight, constStandAloneHeight, issueOrderBannerHeight, unknownConstant,
      } = heightsRef.current;

      const chatHeight = `${constStandAloneHeight} - ${messageBoxHeight}px - ${issueOrderBannerHeight}px - ${String(alertBannerHeight)}px - ${unknownConstant} - ${chatBannerHeight}px`;

      const stepHeight = `${constStandAloneHeight} - ${issueOrderBannerHeight}px - ${String(alertBannerHeight)}px - ${unknownConstant}`;

      const orderDetailsHeight = `calc(100vh - ${String(alertBannerHeight)}px)`;

      return {
        chatHeight,
        stepHeight,
        orderDetailsHeight,
      };
    };

    const observer = new MutationObserver((() => {
      const issueOrderBanner = getOrderIssueBanner();
      const alertBanner = getAlertBanner();

      if (heightsRef.current.alertBanner !== alertBanner) { // alert banner was closed
        const { chatHeight, stepHeight, orderDetailsHeight } = calcHeight();
        setChatHeight(chatHeight);
        setSuggestedStepHeight(stepHeight);
        setStandAloneContainerHeight(orderDetailsHeight);
        heightsRef.current.alertBanner = alertBanner;
      }

      if (heightsRef.current.issueOrderBanner !== issueOrderBanner) { // order tab issue banner show
        const { chatHeight, stepHeight, orderDetailsHeight } = calcHeight();
        setChatHeight(chatHeight);
        setSuggestedStepHeight(stepHeight);
        setStandAloneContainerHeight(orderDetailsHeight);
        heightsRef.current.issueOrderBanner = issueOrderBanner;
      }
    }));
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      setStandAloneContainerHeight('calc(100%)');
      if (!observer || !observer.observe.disconnect) return;

      observer.observe.disconnect();
    };
  }, []);

  return null;
};

export default HeightCalculations;
