import moment from 'moment-timezone';
import {
  TICKETS_REQUEST,
  TICKETS_RECEIVE,
  TICKETS_ACTION_REQUEST,
  TICKETS_ACTION_RECEIVE,
  TICKETS_FILES_UPLOAD_RECEIVE,
  TICKETS_FILES_REMOVE_REQUEST,
  TICKET_UPDATE_REQUEST,
  TICKET_UPDATE_RECEIVE,
  TICKETS_NEXT_REQUEST,
  TICKETS_NEXT_RECEIVE,
  TICKETS_SET_FILTER_REQUEST,
  TICKETS_SET_FILTER_RECEIVE,
  TICKET_UPDATE,
} from './actions';

const tickets = (state = {
  isFetching: false,
  didInvalidate: true,
  tickets: [],
}, action) => {
  switch (action.type) {
    case TICKETS_SET_FILTER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case TICKETS_SET_FILTER_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetching: false,
        didInvalidate: true,
        filter: action.filter,
        sorting: action.sorting,
      });
    case TICKETS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case TICKETS_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetching: false,
        updatedAt: moment(),
        didInvalidate: false,
        filter: action.filter,
        sorting: action.sorting,
      });
    case TICKETS_NEXT_REQUEST:
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    case TICKETS_NEXT_RECEIVE:
      return Object.assign({}, state, {
        ...state,
        page: action.data.page,
        tickets: [...state.tickets, ...action.data.tickets],
      });
    case TICKET_UPDATE_RECEIVE:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
        [action.ticketId]: action.data,
      });
    case TICKETS_ACTION_REQUEST:
      return {
        ...state,
        isFetchingAction: true,
      };
    case TICKETS_ACTION_RECEIVE:
      return {
        ...state,
        isFetchingAction: false,
      };
    case TICKET_UPDATE:
      return Object.assign({}, state, {
        ...state,
        tickets: state.tickets.map((ticket) => {
          if (ticket.id === action.data.id) {
            return { ...ticket, ...action.data };
          }
          return ticket;
        }),
      });
    default:
      return state;
  }
};

const newTicketFiles = (state = {
  isFetching: false,
  didInvalidate: true,
}, action) => {
  switch (action.type) {
    case TICKETS_FILES_UPLOAD_RECEIVE:
      return Object.assign({}, state, {
        ...state,
        files: state.files ? [...state.files, ...action.data] : action.data,
      });
    case TICKETS_FILES_REMOVE_REQUEST:
      return Object.assign({}, state, {
        ...state,
        files: state.files.filter(f => f._id !== action.fileId),
      });
    default:
      return state;
  }
};

const ticket = (state = {}, action) => {
  let data = {};
  switch (action.type) {
    case TICKETS_SET_FILTER_RECEIVE:
    case TICKETS_RECEIVE:
      action.data.tickets.map((t) => {
        data = { ...data, ...{ [t.id]: t } };
      });
      return Object.assign({}, state, {
        ...state,
        ...data,
        filter: action.filter,
        sorting: action.sorting,
      });
    case TICKET_UPDATE_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isFetchingUpdate: true,
      });
    case TICKET_UPDATE_RECEIVE:
      return Object.assign({}, state, {
        ...state,
        isFetchingUpdate: false,
        [action.ticketId]: action.data,
      });
    case TICKETS_NEXT_RECEIVE:
      action.data.tickets.map((t) => {
        data = { ...data, ...{ [t.id]: t } };
      });
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        ...state,
        ...data,
      });
    default:
      return state;
  }
};

export { tickets, newTicketFiles, ticket };
