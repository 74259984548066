import {
    UPDATES_REQUEST,
    UPDATES_RECEIVE,
    UPDATES_SET_VIEWED_REQUEST,
    UPDATES_SET_VIEWED_RECEIVE
} from './actions';


const update = (state = {}, action) => {
    switch (action.type) {
        case UPDATES_SET_VIEWED_REQUEST:
            return Object.assign({}, state, {
                isFetchingViewed: true,
            });
        case UPDATES_SET_VIEWED_RECEIVE:
            return Object.assign({}, state, {
                isFetchingViewed: false,
                status: action.payload.status,
                your_rate: action.payload.rate,
            });
        default:
            return state
    }
}


const updates = (state = {
    isFetching: false,
    didInvalidate: true,
    items: {}
}, action) => {
    switch (action.type) {
        case UPDATES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case UPDATES_RECEIVE:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                items: action.items
            });
        case UPDATES_SET_VIEWED_REQUEST:
        case UPDATES_SET_VIEWED_RECEIVE:
            return Object.assign({}, state, {
                items: Object.assign({}, state.items, {
                    [action.updateId]: update(state.items[action.updateId], action)
                })
            });
        default:
            return state;
    }
};

export default updates;