import React from 'react';

import classNames from 'utils/classNames';

const stopPropagateClick = event => event.stopPropagation();

const AgreementBox = (props) => {
  const {
    containerRef, children, onOverlayClick, narrow = false, nonCenter = false, customClasses = [],
  } = props;
  return (
    <div>
      <div className="overlay" />
      <div className="order-tab tab-agreement">
        <div className={classNames('order-tab-container', ...customClasses)} ref={containerRef} onClick={onOverlayClick}>
          <div className={classNames('order-detail-body agreement-wrap', { 'order-detail-body--narrow': narrow, 'order-detail-body--non-center': nonCenter }, ...customClasses)} onClick={stopPropagateClick}>
            <div className="agreement-box">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgreementBox;
