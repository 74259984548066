import React from 'react';

import Modal from 'components/Modal';

import tooLate from 'img/icons/too-late-ill.svg';

const refreshPage = () => window.location.reload(true);

const OrderErrorModal = (props) => {
  const { onClose, onRefresh } = props;
  return (
    <Modal onClose={onClose} className="ta-center" wide>
      <img className="modal-icon" src={tooLate} alt="" />

      <h2 className="title mb30">Sorry, an error occurred while loading the order.</h2>

      <p className="modal-text">Please try following actions:</p>

      <button type="button" className="btn btn-bright" onClick={onRefresh}>Try again</button>
      {' '}
      <button type="button" className="btn btn-bright" onClick={refreshPage}>Refresh page</button>
      {' '}
      <button type="button" className="btn btn-bright" onClick={onClose}>Close order</button>
    </Modal>
  );
};

export default OrderErrorModal;
