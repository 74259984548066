import React, { Component } from 'react';

import classNames from './../utils/classNames';

import Icon from './Icon';

const contains = (parentNode, childNode) => {
    if (!!parentNode.contains) {
        return parentNode.contains(childNode);
    }
    else {
        return parentNode.compareDocumentPosition(childNode) % 16;
    }
}

class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.onButtonHover = this.onButtonHover.bind(this);
        this.setOpen = this.setOpen.bind(this);
    }

    handleDocumentClick(event) {
        const ddAttr = event.target.getAttribute('data-dropdown');
        if (ddAttr === 'keep') {
            return;
        }
        if (ddAttr === 'close') {
            this.setState(state => Object.assign({}, state, { isOpen: false }));
            return;
        }
        const { closeAlways, keepOpen } = this.props;
        if (keepOpen) {
            return;
        }
        if (closeAlways || !contains(this.element, event.target)) {
            this.setState(state => Object.assign({}, state, { isOpen: false }));
        }
    }

    onButtonHover() {
        const { onButtonHover } = this.props;
        if (onButtonHover) {
            if (!onButtonHover()) {
                return;
            }
        }
        this.setState(state => Object.assign({}, state, {
            isOpen: true
        }));
    }

    setOpen() {
        this.setState(state => Object.assign({}, state, {
            isOpen: true
        }));
    }

    componentDidMount() {
        window.root.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        window.root.removeEventListener('click', this.handleDocumentClick)
    }

    render() {
        const { text, children, fluid, up, icon, buttonId, title } = this.props;
        const { isOpen } = this.state;

        const className = classNames('dropdown', { 'dropdown-fluid': fluid }, { 'dropdown-up': up })


        return (
            <div className={className} ref={element => this.element = element}>
                <span className="hover-dropdown" onMouseOver={this.onButtonHover} id={buttonId} title={title}>
                    {!!icon && <Icon className="svg-icon" iconName={icon} />} {text}
                    <span className="arrow-right"></span>
                </span>
                {isOpen &&
                    <div className="dropdown-content">
                        {children}
                    </div>
                }
            </div>
        );
    };
}

export default Dropdown;
