const isValidProfile = (user = {}) => {
  const isDe = user.profile_type === 'D';

  let isValid =
    !!user.public_name &&
    !!user.public_initial &&
    !!user.gender &&
    !!user.birth_day &&
    !!user.university &&
    !!user.profile_involvement &&
    !!user.major &&
    !!user.public_degree &&
    !!user.timezone_str &&
    !!user.available_from &&
    !!user.available_to &&
    !!user.profile_cellphoneos &&
    !!user.profile_employment &&
    !!user.profile_experience &&
    !!user.public_overview &&
    !!user.public_photo;

  if (!isDe) {
    isValid = isValid && !!user.address?.street;
  } else {
    isValid = isValid && !!user.public_city && !!user.country;
  }

  const cannotClose = user.subjects && !!user.subjects.length && !!isValid;

  return {
    isValid, cannotClose,
  };
};

export default isValidProfile;
