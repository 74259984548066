import { REPORT_SUB_TYPE, REPORT_TYPE } from './writerTicketing';

export const TYPE_ISSUE = 'issue_resolution';
export const UNDER_REVIEW = 'Under review';
export const DEFAULT_ROLE = 'OFFF';
export const SUPPORT_ISSUE_TYPE = 'support team';
export const SUPPORT_QA_ISSUE_TYPE = 'qa score';

export const SUB_TYPE_MAPPER = {
  'I need to access order details': 'Need to access order details',
};

export const FILTERED_TYPES = {
  [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED]: [REPORT_SUB_TYPE.I_DO_NOT_AGREE_WITH_CLIENTS_SCORE, REPORT_SUB_TYPE.UNFAIR_QA_SCORE],
};

export const FILTERED_WITHOUT_FINES = {
  [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_WORKING_ON_AN_ORDER]: [REPORT_SUB_TYPE.UNFAIR_FINE],
  [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED]: [REPORT_SUB_TYPE.UNFAIR_FINE],
};

export const FILTERED_REVISION_FINES_TYPES = {
  [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_WORKING_ON_AN_ORDER]: [REPORT_SUB_TYPE.REVISION_REJECT_UNFAIR_FINE],
  [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED]: [REPORT_SUB_TYPE.REVISION_REJECT_UNFAIR_FINE],
};

export const DISABLED_TYPE = {
  [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED]: [REPORT_SUB_TYPE.PAYMENT_BONUS_NOT_RECEIVED],
};

export const DISABLED_TYPE_ISSUE = [
  REPORT_SUB_TYPE.I_WANT_TO_DELETE_MY_ACCOUNT,
  REPORT_SUB_TYPE.I_WANT_TO_FREEZE_MY_ACCOUNT,
];

export const QA_MAX_SCORE = 4;
export const CLIENT_MAX_SCORE = 3;

export const DEFAULT_FORM_DATA = {
  stateOrderTitle: '',
  stateOrderNumber: null,
  stateOrderId: null,
  message: '',
  typeIssue: '',
  subTypeIssue: '',
  rating: null,
  tags: [],
  showTags: false,
  loading: false,
  isShowOtherText: false,
  otherText: '',
  showIssueError: false,
  isNTOrder: false,
  issueErrorText: '',
  ordersTickets: {},
};

