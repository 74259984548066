import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { settingsFetchIfNeeded } from 'store/settings/actions';
import { getDataListOfFiles } from 'store/settings/selectors';

import StarRating from 'components/StarRating';
import OrderFileList from 'components/order/OrderFileList';

import Comment from './Comment';
import FixesList from './FixesList';
import QACategoryItem from './QACategoryItem';
import QACollapseItem from './QACollapseItem';

import { getFillRating, getFines, getRatingString } from './utils';
import { ORDER_TYPE_RUBICS, ORDER_TYPE_RUBICS_WITH_REVISION_ORDER, TYPE_RUBRICS, TYPE_RUBRICS_WITH_REVISION_ORDER, RUBICS_SWITCH_STATUS, QA_TEMPLATE, RUBICS_SCORE, criticalCriteria } from './constants/index';

const DEFAULT_REPORT_TYPE = 'writing';
const DEFAULT_REPORT_REVISION_TYPE = 'Revision by new Freelancer';
const DEFAULT_VIEWS = [0, 0, 0, 0, 0];
const DEFAULT_VALUES = [0.0001, 0.0002, 0.0003, 0.0004, 0];


const ScoringRubric = ({ order }) => {
  const dispatch = useDispatch();
  const dataListOfFixes = useSelector(state => getDataListOfFiles(state));

  const initaialData = useRef({
    listFines: [],
    listFixes: [],
    comment: null,
    loading: false,
    graderRating: 0,
    sumFines: null,
  });


  const orderTypeReportRubrics = order.is_revision_duplicate ? ORDER_TYPE_RUBICS_WITH_REVISION_ORDER : ORDER_TYPE_RUBICS;
  // const orderTypeReportRubrics = ORDER_TYPE_RUBICS;
  const typeRubrics = order.is_revision_duplicate ? TYPE_RUBRICS_WITH_REVISION_ORDER : TYPE_RUBRICS;
  // const typeRubrics = TYPE_RUBRICS;
  const defaultType = order.is_revision_duplicate ? DEFAULT_REPORT_REVISION_TYPE : DEFAULT_REPORT_TYPE;
  // const defaultType = DEFAULT_REPORT_TYPE;

  const [stateData, setStateData] = useState(initaialData.current);
  const [dataReport, setDataReport] = useState(null);
  const [orderType, setOrderType] = useState(defaultType);
  const [dataCriterion, setDataCriterion] = useState(orderTypeReportRubrics);
  const [statusRubrics, setStatusRubrics] = useState(RUBICS_SWITCH_STATUS);
  const [maxValues, setMaxValues] = useState(null);

  const setInitialData = () => {
    const {
      qa_list_fixes: qaListFixes,
      writer_price_base: writerPrice,
      primary_type_pricing: primaryTypePricing,
      qa_evaluation_sheet: qaEvaluationSheet,
      qa_report: dataReportNew,
      qa_report_score: graderRating,
      pregrader_recommendations: comment,
    } = order;

    const oType = typeRubrics[qaEvaluationSheet] || (primaryTypePricing).toLowerCase() || DEFAULT_REPORT_TYPE;
    const listFixes = (qaListFixes && qaListFixes.fixes) || [];
    const listFines = (qaListFixes && qaListFixes.fines) || [];
    const sumFines = getFines(listFixes, listFines, writerPrice);

    if (dataReportNew) {
      Object.entries(dataReportNew).map(([key, value]) => {
        const itemRubric = statusRubrics[orderType][key];
        if (value === null && itemRubric) {
          itemRubric.active = false;
        }
      });
    }

    const data = {
      ...stateData,
      listFixes,
      listFines,
      loading: false,
      graderRating,
      comment,
      sumFines,
    };

    setOrderType(oType);
    setDataReport({ ...QA_TEMPLATE, [oType]: dataReportNew });
    setStateData(data);
    setStatusRubrics(statusRubrics);
  };

  const getGraderSum = () => {
    if (!dataReport) return;
    const activeDataCriterion = dataCriterion[orderType];
    const keys = Object.keys(activeDataCriterion);
    let totalSum = 0;
    let data = stateData;

    keys.forEach((idCriterion) => {
      const keysSection = Object.keys(activeDataCriterion[idCriterion].data);

      const resSumOut = keysSection.reduce(
        (sumOut, current) => {
          sumOut += dataReport[orderType][current];
          return sumOut;
        },
        0,
      );
      const resSumOf = keysSection.reduce(
        (sumOf, current) => sumOf + activeDataCriterion[idCriterion].data[current].score[0],
        0,
      );

      data = {
        ...data,
        [orderType]: {
          ...data[orderType],
          [idCriterion]: {
            sumOf: Number(resSumOf).toFixed(1),
            sumOut: Number(resSumOut).toFixed(1),
          },
        },
      };

      totalSum += resSumOut;
    });

    data = { ...data, graderRating: Number((totalSum).toFixed(1)) };

    criticalCriteria[orderType].forEach((criteria) => {
      if (dataReport[orderType][criteria] === 0) {
        data = { ...data, graderRating: 1 };
      }
    });

    setStateData(data);
  };

  const calcMaxValue = () => {
    if (dataReport && !maxValues) {
      let objNewMaxVal = null;

      const categoryRubricIds = Object.keys(RUBICS_SCORE[orderType]);
      let newDataCriterion = cloneDeep(dataCriterion);

      categoryRubricIds.map((categoryRubricId) => {
        const rubrics = cloneDeep(orderTypeReportRubrics[orderType][categoryRubricId].data);
        const rubricIds = Object.keys(rubrics);
        const curMaxVal = (maxValues && maxValues[categoryRubricId]) || (objNewMaxVal && objNewMaxVal[categoryRubricId]) || RUBICS_SCORE[orderType][categoryRubricId].max;
        let newMaxVal = curMaxVal;

        rubricIds.map((rubricId) => {
          const initailVal = RUBICS_SCORE[orderType][categoryRubricId].data[rubricId].default;
          let calcVal = 0;

          newMaxVal = newMaxVal.map((item, i) => {
            if (dataReport[orderType][rubricId] === null) {
              calcVal = item - initailVal[i];
              return (calcVal > 0 ? calcVal : 0);
            }
            return item;
          });
        });

        objNewMaxVal = { ...objNewMaxVal, [categoryRubricId]: newMaxVal };
        setMaxValues({ ...maxValues, ...objNewMaxVal });

        rubricIds.forEach((rubricId) => {
          const defaultValue = RUBICS_SCORE[orderType][categoryRubricId].data[rubricId].default;

          const currentMaxValues = objNewMaxVal[categoryRubricId];
          const currentVal = [];
          defaultValue.forEach((val, i) => {
            const calc = val ? ((val / currentMaxValues[i]) * RUBICS_SCORE[orderType][categoryRubricId].max[i]).toFixed(3) : 0;
            currentVal.push(Number(calc));
          });

          const notActive = dataReport[orderType][rubricId] === null || !(rubricId in dataReport[orderType]);

          rubrics[rubricId].score_view = notActive ? DEFAULT_VIEWS : currentVal;
          rubrics[rubricId].score = notActive ? DEFAULT_VALUES : currentVal;

          if (rubricId === 'plagiarism_free' || rubricId === 'plagiarism') {
            rubrics[rubricId].score_view = DEFAULT_VIEWS;
            rubrics[rubricId].score = DEFAULT_VALUES;
          }

          const allRubricFromOrderType = newDataCriterion[orderType];

          newDataCriterion = {
            ...newDataCriterion,
            [orderType]: {
              ...allRubricFromOrderType,
              [categoryRubricId]: {
                ...allRubricFromOrderType[categoryRubricId],
                data: {
                  ...allRubricFromOrderType[categoryRubricId].data,
                  [rubricId]: rubrics[rubricId],
                },
              },
            },
          };
        });
      });

      setDataCriterion(newDataCriterion);
    }
  };

  useEffect(() => {
    setInitialData();
  }, []);

  useEffect(() => {
    calcMaxValue();
    getGraderSum();
  }, [dataReport]);

  useEffect(() => {
    if (!dataListOfFixes) dispatch(settingsFetchIfNeeded());
  }, [dataListOfFixes]);

  const { graderRating, listFixes, sumFines, comment } = stateData;
  const { items: fileList, webhook: fileWebhook } = order.files;

  const graderFiles = useMemo(() => Object.values(fileList).filter(file => !file.deleted && file.type === 'file_qareport_comment'), [fileList]);
  const fixPaperFiles = useMemo(() => Object.values(fileList).filter(file => file.is_fixed_paper && !file.deleted), [fileList]);
  const datalistFixes = useMemo(() => (listFixes && dataListOfFixes ? dataListOfFixes.filter(item => listFixes.indexOf(item.id) >= 0) : []), [listFixes]);

  const dataCriterionKeys = Object.keys(dataCriterion[orderType]);
  const fill = getFillRating(graderRating);

  if (!dataReport) return null;

  return (
    <div className="grade-box">
      <div className="row grade-info">
        <div className="grade_sum">
          <h3>Your grade:</h3>
          <StarRating rate={graderRating} width={140} customFill={fill} />
          <span>{getRatingString(graderRating)}</span>
        </div>

        <div className="col-2 ta-right">
          <a href="https://drive.google.com/file/d/1CKhVACGxGjrn67NzzZ8tnzOFDFKuOq94/view" target="_blank" rel="noopener noreferrer">Scoring rubric</a>
        </div>
      </div>

      {comment &&
        <Comment comment={comment} />
      }
      <div className="upload-file-box">
        {!!graderFiles.length &&
          <OrderFileList fileWebhook={fileWebhook} orderNumber={order.number} fileList={graderFiles} icon="clip" title="Comments to the paper" />
        }

        {!!fixPaperFiles.length &&
          <OrderFileList fileWebhook={fileWebhook} orderNumber={order.number} fileList={fixPaperFiles} icon="clip" title="Fixed paper" />
        }
      </div>

      {dataReport && dataReport[orderType] &&
        dataCriterionKeys.map(itemCriterion => (
          <div className="person-info-box" key={`data_criterion_${itemCriterion}`}>
            <QACollapseItem
              title={dataCriterion[orderType][itemCriterion].name}
              key={`collapse_${itemCriterion}`}
            >
              <QACategoryItem
                dataSection={dataCriterion[orderType][itemCriterion]}
                dataRow={dataReport[orderType]}
                key={`category_${itemCriterion}`}
              />
            </QACollapseItem>
          </div>
        ))}

      {datalistFixes && datalistFixes.length > 0 &&
        <FixesList datalistFixes={datalistFixes} sumFines={sumFines} />
      }
    </div>
  );
};

export default ScoringRubric;
