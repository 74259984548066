import moment from 'moment-timezone';

import { urls } from './../../config';
import authFetch from './../../utils/authFetch';

import { ordersAddList } from './../orders/actions';

export const ORDERS_REFUNDED_SET_SORTING = 'ORDERS_REFUNDED_SET_SORTING';
export const ORDERS_REFUNDED_SET_FILTER_TIME = 'ORDERS_REFUNDED_SET_FILTER_TIME';
export const ORDERS_REFUNDED_SET_FILTER_SEARCH = 'ORDERS_REFUNDED_SET_FILTER_SEARCH';
export const ORDERS_REFUNDED_RESET_FILTER = 'ORDERS_REFUNDED_RESET_FILTER';
export const ORDERS_REFUNDED_SET_MESSAGE_FILTER = 'ORDERS_REFUNDED_SET_MESSAGE_FILTER';

export const ORDERS_REFUNDED_REQUEST = 'ORDERS_REFUNDED_REQUEST';
export const ORDERS_REFUNDED_RECEIVE = 'ORDERS_REFUNDED_RECEIVE';

const ordersRefundedRequest = () => ({
  type: ORDERS_REFUNDED_REQUEST,
});

export const ordersRefundedSetSorting = value => ({
  type: ORDERS_REFUNDED_SET_SORTING,
  value,
});

export const ordersRefundedSetFilterTime = value => ({
  type: ORDERS_REFUNDED_SET_FILTER_TIME,
  value,
});

export const ordersRefundedSetFilterSearch = value => ({
  type: ORDERS_REFUNDED_SET_FILTER_SEARCH,
  value,
});

export const ordersRefundedResetFilter = () => ({
  type: ORDERS_REFUNDED_RESET_FILTER,
});

const ordersRefundedReceive = (numbers, total, limit, page, unreadOrderMessages = 0) => ({
  type: ORDERS_REFUNDED_RECEIVE,
  numbers,
  total,
  limit,
  page,
  unreadOrderMessages,
});

export const setRefundedMessageFilter = value => ({
  type: ORDERS_REFUNDED_SET_MESSAGE_FILTER,
  value,
});

export const ordersRefundedFetch = (page = 1, sort = 'writer_deadline', filter = '') => (dispatch) => {
  dispatch(ordersRefundedRequest());
  return authFetch(`${urls.orders}?folder=refunded&page=${page}&sort=${sort}&${filter}`).then((json) => {
    const {
      results = [], total = null, limit = 25, page: pageFromSer = 1, unread_order_messages: unreadOrderMessages = 0,
    } = json || {};
    const numbers = results.map(order => order.number);
    const normalizeOrders = results.reduce((prev, next) => {
      prev = { ...prev, [next.number]: { ...next, didInvalidate: true } };
      return prev;
    }, {});

    dispatch(ordersAddList(normalizeOrders));
    dispatch(ordersRefundedReceive(numbers, total, limit, pageFromSer, unreadOrderMessages));
  });
};

const ordersRefundedShouldFetch = (state) => {
  const { ordersRefunded } = state;
  if (ordersRefunded.isFetching) {
    return false;
  }
  if (ordersRefunded.updatedAt && moment().diff(ordersRefunded.updatedAt, 'minutes') > 1) {
    return true;
  }
  return ordersRefunded.didInvalidate;
};

export const ordersRefundedFetchIfNeeded = page => (dispatch, getState) => {
  if (ordersRefundedShouldFetch(getState())) {
    return dispatch(ordersRefundedFetch(page));
  }
};
