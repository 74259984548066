import React, { Fragment } from 'react';
import Checkbox from 'components/Checkbox';
import { FILTER, OTHER } from './sharedConstants';

const SubjectGroupsFilter = ({
  orderSubjectGroupsCount, stateFilter, orderSubjectGroups, onChangeFilter, isMobile = false,
}) => (
  <Fragment>
    {Object.keys(orderSubjectGroupsCount).map((s) => {
      if (s !== OTHER) {
        const subjects = orderSubjectGroupsCount[s];
        const isChecked = stateFilter.subjectGroups.indexOf(subjects.name) > -1;
        let isAllInclude = false;
        if (isChecked) {
          isAllInclude = true;
          orderSubjectGroups[s].forEach((sb) => {
            if (stateFilter.subjects.indexOf(sb) === -1) {
              isAllInclude = false;
            }
          });
        }
        return (
          <li key={`group${subjects.name}`}>
            <Checkbox
              name={subjects.name}
              disabled={subjects.count === 0}
              checked={stateFilter.subjectGroups.indexOf(subjects.name) > -1}
              onChange={() => onChangeFilter(FILTER.subjectGroups, subjects.name)}
              minus={isChecked && !isAllInclude && !isMobile}
            >
              {subjects.name}
              <span> ({subjects.count || 0})</span>
            </Checkbox>
          </li>
        );
      }
    })}
  </Fragment>
);

export default SubjectGroupsFilter;
