import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import modalList from 'constants/modalList';

import { addModalComponent, closeModalComponent } from 'store/modals/actions';

import { orderPrice } from 'components/order/utils';
import CreateEstimateModal from 'components/order/EstimationModal/CreateEstimateModal';
import Tooltip from 'components/Tooltip';

import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';

const Estimate = ({ order, profile }) => {
  const {
    writer_chat_message_at: writerChatMessageAt,
    let_tutor_decide: letTutorDecide,
    estimates = [], size, is_complex: isComplex,
    extras, writer_per_hour_price: writerPerHourPrice,
    writer_completed: writerCompleted,
  } = order;

  const { profile_type: profileType } = profile;

  const dispatch = useDispatch();

  const isDe = profileType === 'D';

  const isHaveAcceptedEstimate = useMemo(() => estimates.some(item => item.status === 'accepted'), [estimates]);
  const viewSize = useMemo(() => {
    let orderSizes = '';
    let orderSizeMH = null;
    if (isComplex && extras && extras.length > 0) {
      orderSizes = extras.filter(s => s.size && s.payment_status === 'Paid');
      orderSizes = orderSizes.map((s) => {
        const [__size, __type] = s.size.split(' ');
        if (__type === 'minutes' && !isDe) {
          return convertMinsToHrsMins(__size);
        }
        return s.size;
      });
      orderSizes = orderSizes.join('   +   ');
    }

    if (size && !isDe) {
      const [size, type] = order.size.split(' ');
      if (type === 'minutes') {
        orderSizeMH = convertMinsToHrsMins(size);
      }
    }

    return orderSizes || orderSizeMH;
  }, [isComplex, extras, size, isDe]);

  const isHaveActiveEstimate = useMemo(() => estimates.some(item => item.status === 'active'), [estimates]);

  const isDisabledCreateNewEstimate = useMemo(() => {
    if (Boolean(writerCompleted) || !writerChatMessageAt) return true;

    return isHaveActiveEstimate;
  }, [estimates, writerCompleted, writerChatMessageAt, isHaveActiveEstimate]);

  const onCreateNewSizeEstimate = () => {
    const onCloseCreateNewSizeEstimate = () => {
      dispatch(closeModalComponent(modalList.NewEstimate.name));
    };

    const dataModal = modalList.NewEstimate;
    const component = <CreateEstimateModal order={order} toggleOpen={onCloseCreateNewSizeEstimate} />;
    dispatch(addModalComponent({ component, ...dataModal }));
  };

  const renderText = () => {
    if (letTutorDecide && !isHaveAcceptedEstimate) {
      return (
        <>
          <div className="step-container__title">
            {`Current order rate is: $${writerPerHourPrice}/hour.`}
          </div>
          <div className="step-container__title">
            Please estimate how much time it will take for you to help the client, and create a new estimate.
          </div>
        </>
      );
    }

    return (
      <>
        <div className="step-container__title">
          {`Current order size is: ${viewSize} total, and the final order price is ${orderPrice({ order, profile })}`}
        </div>
        <div className="step-container__title">
          Please estimate how much time it will take for you to help the client.
        </div>
      </>
    );
  };

  const renderTips = () => {
    if (!letTutorDecide && !isHaveAcceptedEstimate) {
      return 'Use if order size is underestimated';
    }

    return 'Obligatory to accept the order';
  };

  const renderSubmitTips = () => {
    if (!letTutorDecide && !isHaveAcceptedEstimate) {
      return 'Obligatory to accept the order';
    }

    return 'Obligatory to accept the order, will be available once client approves the estimate';
  };

  return (
    <div>
      {renderText()}
      <div className="step-container__tips">{renderTips()}</div>
      {
        isDisabledCreateNewEstimate && isHaveActiveEstimate ?
        (
          <Tooltip className="step-container__tooltip" content="Please wait for the client to approve or decline the previous sent estimate before you can create a new one."><button className="btn btn-light mb8" type="button" disabled>Create new size estimate</button></Tooltip>
        ) : (
          <button className="btn btn-light mb8" type="button" disabled={isDisabledCreateNewEstimate} onClick={onCreateNewSizeEstimate}>Create new size estimate</button>
        )
      }
      <div className="step-container__tips">{renderSubmitTips()}</div>
    </div>
  );
};

export default Estimate;
