import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useConnectContext } from '@connect/connect-xmpp';

import { trackClicks } from 'lrtracker';

import { closeModalComponent } from 'store/modals/actions';

import modalList from 'constants/modalList';

import {
  STATUS_OPEN,
} from 'store/orders/actions';

import { getStatus } from 'components/order/utils';
import classNames from 'utils/classNames';

import { CalcHeightContext } from 'components/order/CalcHeightContext';

import { Introduce, Instructions, HelpFormat, Estimate, Accept } from './StepItems';
import StepContainer from './StepContainer';

import './index.scss';

const findNextStep = (order, owenStep) => {
  const { chat_progress_state = {} } = order;

  const firstUnChecked = ['instructions', 'output', 'price'].filter(step => step !== owenStep).find(step => !chat_progress_state[step]);

  if (!firstUnChecked) return 'Accept the order';

  return firstUnChecked;
};

const STEPS = [
  {
    header: 'Join chat',
    isChecked: true,
  },
  {
    header: 'Introduce yourself to client',
    component: <Introduce />,
    isChecked: (order = {}) => getStatus(order) !== STATUS_OPEN || Boolean(order.writer_chat_message_at),
    defaultOpenState: true,
    canBeClosed: false,
    canBeReopenAfterSubmit: false,
    nextWillOpen: 'instructions',
  },
  {
    header: 'Discuss task instructions',
    component: <Instructions />,
    canBeClosed: true,
    isChecked: (order = {}) => getStatus(order) !== STATUS_OPEN || Boolean((order.chat_progress_state || {}).instructions),
    defaultOpenState: (order = {}) => getStatus(order) === STATUS_OPEN && !(order.chat_progress_state || {}).instructions && Boolean(order.writer_chat_message_at),
    withSubmit: true,
    stepBackendName: 'instructions',
    stepName: 'instructions',
    prevStepValidation: order => Boolean(order.writer_chat_message_at),
    trackItem: 'tutorInstructionsCompleted',
    nextWillOpen: (order = {}) => findNextStep(order, 'instructions'),
  },
  {
    header: 'Confirm help format',
    component: <HelpFormat />,
    canBeClosed: true,
    isChecked: (order = {}) => getStatus(order) !== STATUS_OPEN || Boolean((order.chat_progress_state || {}).output),
    defaultOpenState: (order = {}) => getStatus(order) === STATUS_OPEN && !(order.chat_progress_state || {}).output && (order.chat_progress_state || {}).instructions,
    withSubmit: true,
    stepBackendName: 'output',
    stepName: 'help format',
    prevStepValidation: order => getStatus(order) !== STATUS_OPEN || Boolean(order.writer_chat_message_at),
    trackItem: 'tutorOutputCompleted',
    nextWillOpen: (order = {}) => findNextStep(order, 'output'),
  },
  {
    header: 'Estimate required order size',
    component: <Estimate />,
    defaultOpenState: (order = {}) => {
      if (getStatus(order) !== STATUS_OPEN) return true;

      const { chat_progress_state: chatProgressState = {} } = order;
      const { price, output, instructions } = chatProgressState;

      return (output && instructions && !price);
    },
    canBeClosed: true,
    isChecked: (order = {}) => getStatus(order) !== STATUS_OPEN || Boolean((order.chat_progress_state || {}).price),
    withSubmit: true,
    stepBackendName: 'price',
    stepName: 'estimate',
    prevStepValidation: (order) => {
      const { let_tutor_decide: letTutorDecide, estimates = [], writer_chat_message_at: writerChatMessageAt } = order;
      if (!(writerChatMessageAt)) return false;

      const isHaveAcceptedEstimate = estimates.some(item => item.status === 'accepted');

      if ((letTutorDecide && !isHaveAcceptedEstimate) || getStatus(order) !== STATUS_OPEN) return false;

      return true;
    },
    trackItem: 'tutorPriceCompleted',
    nextWillOpen: (order = {}) => findNextStep(order, 'price'),
  },
  {
    header: 'Accept the order',
    component: <Accept />,
    defaultOpenState: (order = {}) => {
      const { chat_progress_state: chatProgressState = {}, writer_accept_at: writerAcceptAt } = order;
      const { instructions, price, output } = chatProgressState;
      const _isAllStepsConfirmed = [instructions, price, output].every(step => step);

      return Boolean(writerAcceptAt) || _isAllStepsConfirmed;
    },
    canBeClosed: true,
    isChecked: (order = {}) => Boolean(order.writer_accept_at),
  },
];

const OrderSuggestedSteps = ({ order, profile, standalone }) => {
  const [autoOpenStep, setAutoOpenStep] = useState('');
  const { suggestedStepHeight } = useContext(CalcHeightContext);

  const { jabber_jid: username } = profile;
  const {
    jabber_node: node, jabber_server: to,
    _id: orderId,
  } = order;

  const dispatch = useDispatch();

  const clientChatConfig = {
    username,
    node,
    to,
  };

  const { send } = useConnectContext();

  const onSubmit = (text = '', trackValue) => {
    dispatch(closeModalComponent(modalList.Templates.name));
    const trimmedMessage = text.trim();

    if (!trimmedMessage) return;

    send({ variables: clientChatConfig, message: trimmedMessage.replaceAll('<br />', ' ') });

    if (!trackValue) return;

    trackClicks(null, trackValue, { context: { stage: 'ClientTutorChat', order_id: orderId } })();
  };

  const onClose = () => {
    dispatch(closeModalComponent(modalList.Templates.name));
  };

  return (
    <div
      className={classNames(
      'order-suggested-steps-container',
      { 'order-suggested-steps-container--standalone': standalone },
      )}
      style={{ height: `calc(100vh - ${suggestedStepHeight})` }}
    >
      <h2 className="order-suggested-steps-container__header">You can use this flow for communication with the client. It contains tips and adjustable templates for every step.</h2>
      {STEPS.map(({ header, component = null, ...other }) => <StepContainer key={header} onClose={onClose} onSubmit={onSubmit} autoOpenStep={autoOpenStep} setAutoOpenStep={setAutoOpenStep} {...other} header={header} StepComponent={component} order={order} profile={profile} />)}
    </div>
  );
};

export default OrderSuggestedSteps;
