import { createSelector } from 'reselect';

const isNotRefunded = order => !order.refund_tag || (order.refund_tag !== 'Lateness' && order.refund_tag !== 'Quality issue' && order.refund_tag !== 'Quality');

const getUnreadMessages = state => Object.values(state.notification.unreadMessageItems);
const getUnreadFinances = state => Object.values(state.notification.unreadFinanceItems);
const getUnreadUpdates = state => state.notification.unreadUpdateItems;
const getCntBonusesTask = state => state.notification.cntRewardTaskInprogress;
const getUnreadWarnings = state => state.notification.unreadWarnings;

const getOrdersProgressIds = state => state.ordersProgress.numbers.map(number => state.orders[number]?._id);
const getOrdersRevisionIds = state => state.ordersRevision.numbers.map(number => state.orders[number]?._id);
const getOrdersCompleteIds = state => state.ordersComplete.numbers.map(number => state.orders[number]).filter(isNotRefunded).map(order => order._id);

const hasChatNotifications = state => state.clientChatNotifications.hasNotification;
const hasProgressChatNotifications = state => state.clientChatNotifications.notifications.filter(item => item.count > 0 && state.ordersProgress.numbers.includes(item.number)).length > 0;
const hasRevisionChatNotifications = state => state.clientChatNotifications.notifications.filter(item => item.count > 0 && state.ordersRevision.numbers.includes(item.number)).length > 0;
const hasCompletedChatNotifications = state => state.clientChatNotifications.notifications.filter(item => item.count > 0 && state.ordersComplete.numbers.includes(item.number)).length > 0;


export const getHasUnreadMessages = createSelector(
    [getUnreadMessages, hasChatNotifications],
    (unreadMessages, __hasChatNotifications) => !!unreadMessages.length || __hasChatNotifications,
);

export const getHasUnreadFinances = createSelector(
    [getUnreadFinances],
    unreadFinances => !!unreadFinances.length
);

export const getHasUnreadInboxItems = createSelector(
    [getUnreadUpdates],
    unreadUpdates => unreadUpdates > 0,
);

export const getHasCntBonusesTask = createSelector(
    [getCntBonusesTask],
    rewardTaskInprogress => rewardTaskInprogress > 0
);

export const getHasUnreadMessagesOrdersProgress = createSelector(
    [getUnreadMessages, getOrdersProgressIds, hasProgressChatNotifications],
    (unreadMessages, ordersProgressIds, __hasChatNotifications) => unreadMessages.some(messageItem => ordersProgressIds.indexOf(messageItem.order) > -1) || __hasChatNotifications,
);

export const getHasUnreadMessagesOrdersRevision = createSelector(
    [getUnreadMessages, getOrdersRevisionIds, hasRevisionChatNotifications],
    (unreadMessages, ordersRevisionIds, __hasChatNotifications) => unreadMessages.some(messageItem => ordersRevisionIds.indexOf(messageItem.order) > -1) || __hasChatNotifications,
);

export const getHasUnreadChatMessagesOrdersRevision = createSelector(
    [hasRevisionChatNotifications],
  __hasChatNotifications => __hasChatNotifications,
);

export const getHasUnreadMessagesOrdersComplete = createSelector(
    [getUnreadMessages, getOrdersCompleteIds, hasCompletedChatNotifications],
    (unreadMessages, ordersCompleteIds, __hasChatNotifications) => unreadMessages.some(messageItem => ordersCompleteIds.indexOf(messageItem.order) > -1) || __hasChatNotifications,
);

export const getHasUnreadChatMessagesOrdersComplete = createSelector(
    [hasCompletedChatNotifications],
  __hasChatNotifications => __hasChatNotifications,
);

export const getOrderIdsWithUnreadMessages = createSelector(
    [getUnreadMessages],
    unreadMessages => [...unreadMessages.reduce((result, item) => {
        result.add(item.order);
        return result;
    }, new Set())]
)

export const getHasUnreadWarnings = createSelector(
    [getUnreadWarnings],
    unreadWarnings => unreadWarnings > 0
)
