import React, { useState } from 'react';

import { useConnectContext } from '@connect/connect-xmpp';

const AcceptConfirm = ({
  onConfirm, onClose, profile, order,
}) => {
  const [confirmText, setConfirmText] = useState('Thank you, I do not have any questions as for now. I will start working on your task, and will finish it by the due date.');
  const { jabber_jid: username } = profile;
  const {
    jabber_node: node, jabber_server: to,
  } = order;

  const clientChatConfig = {
    username,
    node,
    to,
  };

  const { send } = useConnectContext();

  const onChangeText = (e) => {
    const { target: { value } } = e;
    setConfirmText(value);
  };

  const _onConfirm = () => {
    onConfirm();
    setTimeout(() => {
      send({ variables: clientChatConfig, message: confirmText.replaceAll('<br />', ' ') });
    }, 1000);
  };

  return (
    <div>
      <div className="mb10"><strong>Note</strong>: once you accept the order, you become fully responsible for its timely completion.</div>
      <div className="mb10">Refusal to complete the order after acceptance will result in 100% and a warning. </div>
      <div className="mb24">If you have any additional questions, please clarify them in the chat.</div>
      <div className="mb24">This adjustable recap message will be sent to the client once you confirm order acceptance.</div>
      <textarea
        className="modal-textarea mb24"
        onChange={onChangeText}
        value={confirmText}
      />
      <div className="row flex-end column_gap_12">
        <button className="btn btn-light btn-md2" type="button" onClick={onClose}>Return to chat</button>
        <button className="btn btn-bright" disabled={!confirmText} type="button" onClick={_onConfirm}>Confirm order acceptance</button>
      </div>
    </div>
  );
};

export default AcceptConfirm;
