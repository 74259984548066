import React, { useCallback } from 'react';
import Slider from 'react-slick';

import classNames from 'utils/classNames';

import utils from 'utils';

import isImage from 'utils/isImage';


import { AVAILABLE_FORMATS } from '../../constants';

import './styles/index.mobile.scss';

const SideBar = ({
  docs, selectedID,
  setPreviewCallback,
  activeIndex,
}) => {
  const clickHandler = useCallback((index) => {
    setPreviewCallback(index);
  }, [setPreviewCallback]);

  const settings = {
    dots: false,
    arrow: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: activeIndex,
  };

  return (
    <div className="mobile-bar">
      <Slider
        {...settings}
      >
        {docs.map(({ name, real_location: url, id }, index) => {
          const extension = utils.getFileExtension(name).toLocaleLowerCase();
          const isFileImage = isImage(name);
          const isUnFormatFile = !isFileImage && !AVAILABLE_FORMATS.includes(extension);
          return (
            <div className={classNames('mini_preview_container', { 'mini_preview_container--active': selectedID === id })} key={id} onClick={() => clickHandler(index)}>
              {
                isFileImage ? (
                  <div className="mini_preview_container_item_img_wrapper">
                    <i className="mini_preview_container_item" style={{ backgroundImage: `url(${url})`, backgroundSize: 'cover' }} />
                  </div>
                ) : (
                    <i className={classNames('mini_preview_container_item', `mini_preview_container_item__${isUnFormatFile ? 'unknown' : extension}`)} />
                  )
              }
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SideBar;
