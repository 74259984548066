import React, { Component } from 'react';

import { withPathNoRender } from 'hocs';

import AgreementBox from './agreement/AgreementBox';
import PoliciesAndConditions from './agreement/PoliciesAndConditions';

import TermsOfUse from './agreement/TermsOfUse';

class CommonFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
      isModalTermsOfUseVisible: false,
    };

    this.onToggleClick = this.onToggleClick.bind(this);
    this.onToggleTermsOfUse = this.onToggleTermsOfUse.bind(this);
  }

  onToggleClick() {
    this.setState(state => Object.assign({}, state, {
      isModalVisible: !state.isModalVisible,
    }));
  }

  onToggleTermsOfUse() {
    const { isModalTermsOfUseVisible } = this.state;

    this.setState({ isModalTermsOfUseVisible: !isModalTermsOfUseVisible });
  }

  render() {
    const {
      isModalVisible, isModalTermsOfUseVisible, isFirstTermsOfUsePage, isLastTermsOfUsePage, currentTermsOfUsePagePage,
    } = this.state;
    const currnetYear = new Date().getFullYear();
    return (
      <footer>
        <div className="container">
          <div>2009-{currnetYear} Livingston Research Inc.</div>
          <div>
            Contact us: <a href="mailto:support@liv-research.com">support@liv-research.com</a>
            <span className="dotted-devider">·</span>
            {/* Skype: <a href="skype:livingston_research">livingston_research</a>
                        <span className="dotted-devider">·</span> */}
            <a onClick={this.onToggleClick}>Terms and Conditions</a>
            <span className="dotted-devider">·</span>
            <a onClick={this.onToggleTermsOfUse}>Terms of Use</a>
          </div>
        </div>
        {isModalVisible &&
        <AgreementBox onOverlayClick={this.onToggleClick} narrow>
          <PoliciesAndConditions />
          <div className="ta-right mt20 agreement-info">
            <span>You’ve already accepted this agreement.</span>
            <button onClick={this.onToggleClick} type="button" className="btn btn-8-4 btn-grey">Close</button>
          </div>
        </AgreementBox>
        }
        {isModalTermsOfUseVisible &&
        <AgreementBox onOverlayClick={this.onToggleTermsOfUse} nonCenter>
          <TermsOfUse closeModal={this.onToggleTermsOfUse} />
        </AgreementBox>
        }
      </footer>
    );
  }
}

export default withPathNoRender(CommonFooter, 'order/');
