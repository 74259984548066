import React, { Fragment } from 'react';
import moment from 'moment-timezone';

import formatTime from 'utils/formatTime';
import classNames from 'utils/classNames';

import OrderTags from 'components/OrderTags';

const ProposedOrder = ({
  order, onSelect, isSelect = false, user,
}) => {
  const joinExtended = (...items) => items.filter(a => a).join(' · ');

  const proposedOrderDeadline = (deadline) => {
    const momentDeadLine = moment(deadline);
    const formatTimeMomentDeadLine = formatTime(momentDeadLine, 'dt');
    const hoursDiff = Math.abs(Math.round(momentDeadLine.diff(moment(), 'hours', true)));
    const className = classNames('deadline-price-container__diff-text', { warning: hoursDiff < 24 });
    return (
      <Fragment>
        <div className="deadline-price-container__deadline">{formatTimeMomentDeadLine}</div>
        <div className={className}>{`${momentDeadLine.fromNow(true)} from now`}</div>
      </Fragment>
    );
  };
  const proposedOrderClasses = classNames('proposed-order no-wrap', { 'proposed-order--select': isSelect });

  return (
    <div className={proposedOrderClasses} onClick={() => onSelect(order.number, order._id)}>
      <div className="proposed-order__title text-ellipsis">{order.title}</div>
      <div className="tag-subject-container">
        <OrderTags order={order} user={user} />
        <div className="tag-subject-container__subject text-ellipsis">{order.subject}</div>
      </div>
      <div className="proposed-order__order-extended text-ellipsis">{joinExtended(order.type, order.size, order.level)}</div>
      <div className="deadline-price-container">
        <div>{proposedOrderDeadline(order.writer_deadline)}</div>
        <div className="deadline-price-container__price">${order.writer_price.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default ProposedOrder;
