import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { userSetFieldFetch } from 'store/user/actions';

import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

const PaymentsTypeDropdown = (props) => {
  const {
    payments, payMethod, onChange, disabled,
  } = props;

  return (
    <Dropdown text={payMethod} closeAlways disabled={disabled}>
      <Select className="menu menu-selectable" onChange={onChange}>
        {Object.values(payments).map(item =>
          <li key={item} value={item} className="menu-item">{item}</li>)}
      </Select>
    </Dropdown>
  );
};

const mapStateToProps = (state, props) => ({
  translate: getTranslate(state.locale),
  value: state.user[props.name] || 'my',
});
const mapDispatchToProps = (dispatch, props) => ({
  save: value => dispatch(userSetFieldFetch(props.name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTypeDropdown);
