import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userTasksFetchIfNeeded } from './../store/user/actions';

import { getTasksSorted } from './../store/user/selectors';

import taskDeadline from './taskDeadline';
import taskIcon from './taskIcon';
import TaskStatus from './TaskStatus';
import Icon from './Icon';
// import MyProgressTableRow from './MyProgressTableRow';

class MyProgressTable extends Component {

  componentDidMount() {
    const { loadTasks } = this.props;
    loadTasks();
  }
  render() {
    const { taskList } = this.props;

    return (
      <div className="table progress-table">
        <div className="table-header">
          <div className="row vertical">
            <div className="col-0"><Icon className="svg-icon hide" iconName="money-income" /></div>
            <div className="col-2">Reward</div>
            <div className="col-3">Description</div>
            <div className="col-1">Date</div>
            <div className="col-1">Status</div>
          </div>
        </div>
        <div className="sticky-table-body">
          {taskList.map((task, idx) =>
            (<div key={idx} className={'table-row row vertical break-word' + (task.status === 'done' ? ' is-completed' : '')}>
              <div className="col-0"><Icon className="svg-icon" iconName={taskIcon(task)} /></div>
              <div className="col-2">{task.reward}</div>
              <div className="col-3">{task.title}</div>
              <div className="col-1">{taskDeadline(task)}</div>
              <div className="col-1">
                <TaskStatus task={task} />
              </div>
            </div>)
          )}
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => {
  const isLoading = !state.user.tasks || state.user.tasks.didInvalidate || state.user.tasks.isFetching;
  const taskList = isLoading ? [] : getTasksSorted(state);
  return {
    isLoading,
    taskList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadTasks: () => dispatch(userTasksFetchIfNeeded()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProgressTable);


/*
    <div className="table-row row vertical">
        <div className="col-0"><Icon className="svg-icon" iconName="money-income" /></div>
        <div className="col-2">Get extra $30!</div>
        <div className="col-3">Withdraw at least $100 to your Payoneer account</div>
        <div className="col-1">Jun 27 2017 / <b className="text-warning">2h</b></div>
        <div className="col-1"><span className="btn btn-success btn-xs btn-small">new</span></div>
    </div>
    <div className="table-row row vertical">
        <div className="col-0"><Icon className="svg-icon" iconName="system-update" /></div>
        <div className="col-2">Update your payment details</div>
        <div className="col-3">Withdraw at least $100 to your Payoneer account</div>
        <div className="col-1">Jun 27 2017 / <b className="text-warning">2h</b></div>
        <div className="col-1"><span className="btn btn-success btn-xs btn-small">new</span></div>
    </div>
    <div className="table-row row vertical">
        <div className="col-0"><Icon className="svg-icon" iconName="system-update" /></div>
        <div className="col-2">New update</div>
        <div className="col-3">Withdraw at least $100 to your Payoneer account</div>
        <div className="col-1">Jun 27 2017</div>
        <div className="col-1">In progress</div>
    </div>
    <div className="table-row row vertical">
        <div className="col-0"><Icon className="svg-icon" iconName="system-update" /></div>
        <div className="col-2">New update</div>
        <div className="col-3">Withdraw at least $100 to your Payoneer account</div>
        <div className="col-1">Jun 27 2017</div>
        <div className="col-1">In progress</div>
    </div>
    <div className="table-row row vertical is-completed">
        <div className="col-0"><Icon className="svg-icon" iconName="money-income" /></div>
        <div className="col-2">Get extra $30!</div>
        <div className="col-3">Withdraw at least $100 to your Payoneer account</div>
        <div className="col-1">Jun 27 2017</div>
        <div className="col-1">Completed</div>
    </div>
    <div className="table-row row vertical is-completed">
        <div className="col-0"><Icon className="svg-icon" iconName="money-income" /></div>
        <div className="col-2">Get extra $30!</div>
        <div className="col-3">Withdraw at least $100 to your Payoneer account</div>
        <div className="col-1">Jun 27 2017</div>
        <div className="col-1">Completed</div>
    </div>
*/
