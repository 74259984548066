import React from 'react';

import Icon from 'components/Icon';

import { PhoneVerificationDropdown, PasswordDropdown } from '../Controls';

const ContactInfo = ({ profile, isStem }) => {
  const { email, phone } = profile;
  return (
    <div className="row person-contact-info vertical">
      <div className="person-contact-info-item contact-info-email">
        <div className="tooltip">
          <div className="tooltip-trigger">{email || 'Enter email'}</div>
          <div className="tooltip-content">To change this field, please contact your Care manager via Issue Resolution Center.</div>
        </div>
        <Icon className="svg-icon" iconName="devider" />
      </div>
      <div className="person-contact-info-item contact-info-phone">
        <div className="tooltip">
          <div className="tooltip-trigger">{phone || 'Enter phone'}</div>
          <div className="tooltip-content">To change this field, please contact your Care manager via Issue Resolution Center.</div>
        </div>
        <PhoneVerificationDropdown isEditable={!isStem} />
        <Icon className="svg-icon" iconName="devider" />
      </div>
      {!isStem &&
        <div className="person-contact-info-item contact-info-password">
          <PasswordDropdown isEditable={!isStem} />
        </div>
      }
    </div>
  );
};

export default ContactInfo;
