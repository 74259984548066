import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'hooks';
import moment from 'moment-timezone';

import utils from 'utils';
import { trackClicks } from 'lrtracker';
import { isNewbie } from 'utils/groups';

import modalList from 'constants/modalList';

import { getOrdersFindCounts } from 'store/ordersFind/selectors';
import { getOrdersProgressSorted } from 'store/ordersProgress/selectors';
import { getOrdersRevisionSorted } from 'store/ordersRevision/selectors';
import { ordersProgressFetch } from 'store/ordersProgress/actions';
import {
  ordersSetReservationOver,
  ordersSetStatusCheckFetch,
  STATUS_OPEN,
  STATUS_PROGRESS,
} from 'store/orders/actions';
import { closeModalComponent, addModalComponent } from 'store/modals/actions';

import ChatReject from 'components/order/modals/ChatReject';
import AcceptConfirm from 'components/order/modals/AcceptConfirm';

import OrderAction from './OrderAction';
import WriterAccept from './WriterAccept';
import WriterReserved from './WriterReserved';
import WriterSuggested from './WriterSuggested';
import WriterCompleted from './WriterCompleted';
import WriterRecommended from './WriterRecommended';

import { MAX_RESERVED, MAX_PROGRESS } from './constants';
import { isDisableNTAccept, isDisabledJoinChatByCourse, renderJoinChatTooltip } from './utils';


const OrderOpenActions = ({
  order = {}, status, setJoinChat, setRejected, setAccepted, setReserved, isStatusLoading, setRejectJoinChat,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const orderList = useSelector(state => [...getOrdersProgressSorted(state), ...getOrdersRevisionSorted(state)]);
  const counts = useSelector(state => getOrdersFindCounts(state));
  const canReserve = (counts.reserved < MAX_RESERVED && (!(order && order.was_reserved) || false));

  const {
    group_max_order_count: groupMaxOrderCount,
    can_accept_nt: canAcceptNt,
    verified_order_limitation_ok: verifiedOrderLimitationOk,
    metric_data: {
      current_chat_count: currentChatCount = 0,
    },
  } = user;

  const {
    _id: orderId,
    writer_in_chat: writerInChat = false,
    was_reserved: wasReserved = null,
    writer_status: writerStatus,
    current_writer_in_chat: currentWriterInChat = false,
    can_join_chat: canJoinChat = true,
    is_recommended: isRecommended = false,
    recomended_by_client: recommendedByClient = false,
  } = order;

  const [becomeAccepted, setBecomeAccepted] = useState(false);
  const [isRecomOver, setRecomOver] = useState(false);
  const [isSuggestOver, setSuggestOver] = useState(false);

  const prevOrderId = usePrevious(orderId);
  const prevStatus = usePrevious(status);

  const isOrderRecommended = isRecommended || recommendedByClient;

  const isNTorder = useMemo(() => utils.isNTorder(order), [orderId]);
  const cannotReserveByGroupMaxOrderCount = useMemo(() => {
    if (isNTorder) return false;
    if (isOrderRecommended) return false;
    if (order.is_suggested) return false;

    return orderList.length >= (groupMaxOrderCount || MAX_PROGRESS);
  }, [orderList, groupMaxOrderCount, isNTorder, order]);
  const isDisabledReserve = useMemo(() => isStatusLoading || !canReserve || wasReserved || (isNTorder && !canAcceptNt), [isStatusLoading, !canReserve, wasReserved, isNTorder, !canAcceptNt]);
  const isDisabledAccept = useMemo(() => isStatusLoading || (isNTorder && isDisableNTAccept()) || !verifiedOrderLimitationOk, [isStatusLoading, isNTorder, verifiedOrderLimitationOk]);
  const withToolTipDisabled = useMemo(() => !verifiedOrderLimitationOk, [verifiedOrderLimitationOk]);
  const isDisabledJoinChat = useMemo(() => isStatusLoading || (!verifiedOrderLimitationOk && !isOrderRecommended) || (writerInChat && !currentWriterInChat) || !canJoinChat || isDisabledJoinChatByCourse(user, order) || (currentChatCount >= 2 && !isOrderRecommended), [isStatusLoading, verifiedOrderLimitationOk, writerInChat, currentWriterInChat, canJoinChat, currentChatCount, order, user]);

  useEffect(() => {
    if (prevOrderId !== orderId) {
      setBecomeAccepted(false);
      return;
    }
    if (prevStatus === STATUS_OPEN && status === STATUS_PROGRESS) {
      setBecomeAccepted(true);
    }
  }, [status, orderId]);


  const setReservationOver = () => {
    dispatch(ordersSetReservationOver(order.number));
    dispatch(ordersProgressFetch());
  };

  const checkReserve = () => dispatch(ordersSetStatusCheckFetch(order.number));
  const _addModalComponent = data => dispatch(addModalComponent(data));
  const _closeModalComponent = modalName => dispatch(closeModalComponent(modalName));


  const setRecommendationOver = () => {
    setRecomOver(true);
  };


  const setSuggestionOver = () => {
    setSuggestOver(true);
  };


  const onSetReserved = () => {
    if (canReserve) {
      setReserved();
    }
  };


  const onAcceptWithEvent = () => (
    trackClicks(setAccepted, 'orderAcceptBtn', { context: { type: 'standardBtn', order_id: orderId, useragent: navigator.userAgent } })
  );


  const onAcceptNTWithEvent = () => {
    const dataModal = modalList.AcceptConfirm;
    _closeModalComponent(dataModal.name);
    onAcceptWithEvent();
  };


  const onAcceptConfirm = () => {
    const dataModal = modalList.AcceptConfirm;
    _addModalComponent({
      component: <AcceptConfirm
        onConfirm={onAcceptNTWithEvent}
        onClose={() => _closeModalComponent(dataModal.name)}
        order={order}
        profile={user}
      />,
      ...dataModal,
    });
  };


  const onJoinChatWithEvent = () => {
    const { client: clientId } = order;

    return trackClicks(setJoinChat, 'tutorConnectedByJoinChatBtn', {
      context: {
        stage: 'ClientTutorChat',
        client_id: clientId,
        order_id: orderId,
        timestamp: moment().utc().toISOString(),
        useragent: navigator.userAgent,
      },
    });
  };


  const onRejectChatWithEvent = (reason, otherText) => {
    const { client: clientId } = order;
    const { _id: tutorId } = user;
    const dataModal = modalList.ChatReject;

    const __reason = reason === 'Other reason' ? `${reason}: ${otherText}` : reason;

    trackClicks(null, 'tutor_chat_reject', {
      context: {
        client_id: clientId,
        tutor_id: tutorId,
        order_id: orderId,
        timestamp: moment().utc().toISOString(),
        useragent: navigator.userAgent,
        reason: __reason,
      },
    })();
    setRejectJoinChat({ reason: __reason });
    _closeModalComponent(dataModal.name);
  };


  const onRejectChat = () => {
    const dataModal = modalList.ChatReject;
    _addModalComponent({
      component: <ChatReject
        onReject={(reason, otherText) => onRejectChatWithEvent(reason, otherText)}
        onClose={() => _closeModalComponent(dataModal.name)}
      />,
      ...dataModal,
    });
  };


  const renderData = () => {
    const dataActions = {
      setRejected,
      writerInChat,
      checkReserve,
      canReserve,
      isStatusLoading,
      setReservationOver,
      isDisabledReserve,
      isDisabledJoinChat,
      currentWriterInChat,
      isDisabledAccept,
      withToolTipDisabled,
      cannotReserveByGroupMaxOrderCount,
      onSetReserved,
      onRejectChat,
      onAcceptConfirm,
      renderJoinChatTooltip,
      onAcceptWithEvent,
      onJoinChatWithEvent,
    };

    if (status !== STATUS_OPEN && !becomeAccepted) {
      return null;
    }

    if (writerStatus === 'writer_completed') {
      return <WriterCompleted />;
    }

    if (writerStatus === 'writer_accept') {
      return <WriterAccept acceptAt={order.writer_accept_at} />;
    }

    if (order.reserved) {
      return (
        <WriterReserved
          order={order}
          user={user}
          isNTorder={isNTorder}
          {...dataActions}
        />
      );
    }

    if (writerStatus === 'client_paid') {
      if (isOrderRecommended) {
        return (
          <WriterRecommended
            isRecomOver={isRecomOver}
            order={order}
            user={user}
            isNTorder={isNTorder}
            canReserve={canReserve}
            setRecommendationOver={setRecommendationOver}
            setReserved={setReserved}
            {...dataActions}
          />
        );
      }

      if (order.is_suggested) {
        return (
          <WriterSuggested
            order={order}
            user={user}
            isNTorder={isNTorder}
            isSuggestOver={isSuggestOver}
            setSuggestionOver={setSuggestionOver}
            {...dataActions}
          />
        );
      }

      return (
        <OrderAction
          order={order}
          user={user}
          isNTorder={isNTorder}
          groupMaxOrderCount={groupMaxOrderCount}
          {...dataActions}
        />
      );
    }

    return null;
  };


  return (
    <div>
      {renderData()}
    </div>
  );
};


export default OrderOpenActions;
