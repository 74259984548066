import React from 'react';

import { Card } from '@connect/connect-ui-js';

import { CLIENT_CHAT_AVATARS_SERVICE } from 'config';

import './index.scss';

const WRITER_TEXT_RATE = ["It's horrible", "It's bad", "It's disappointing", "It's good", "It's great!"];

const RateRequested = ({ msgData, getPic, order }) => {
  const { client: clientId, mark } = order;

  const getHeader = () => {
    if (mark) return 'Your rating';

    return 'Waiting for student rating...';
  };

  const getBody = () => {
    if (mark) {
      return (
        <div className="rate-requested-card-body">
          <div className="row center_center column_gap_8">
            <div className="rate-requested-card-body__mark">{mark}</div>
            <i className="rate-requested-card-body__mark-icon" />
          </div>
          <div className="rate-requested-card-body__text-mark">{WRITER_TEXT_RATE[mark - 1]}</div>
        </div>
      );
    }

    return (
      <div className="rate-requested-card-body row center_center">
        <i className="rate-requested-card-body__star-animated" />
        <i className="rate-requested-card-body__avatar" style={{ backgroundImage: `url(${CLIENT_CHAT_AVATARS_SERVICE}/${clientId})` }} />
      </div>
    );
  };

  return (
    <Card data={msgData} getPic={getPic}>
      <Card.Main>
        <div className="rate-requested-card">
          <h3 className="rate-requested-card__header centered">{getHeader()}</h3>
          {getBody()}
        </div>
      </Card.Main>
      <Card.Footer data={msgData} />
    </Card>
  );
};

export default RateRequested;
