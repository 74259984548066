import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getProfile } from 'store/user/selectors';

import localStorageHandler from 'utils/localStorageHandler';
import isValidProfile from 'utils/isValidProfile';
import getVerificationRequesters from 'utils/getVerificationRequesters';

import InfoVerifyContent from './InfoVerifyContent';
import AgreementInner from './AgreementInner';

const Agreement = (props) => {
  const profile = useSelector(getProfile) || {};
  const { isVerificationRequest, isVerificationCallRequest } = getVerificationRequesters(profile);

  const { showAgreementWizard } = profile;
  const { isMobile } = props;

  const willOpenVerifyModal = () => {
    const { last_veriff_session_object: veriffSession = {} } = profile;
    const { isValid, cannotClose } = isValidProfile(profile);
    if (!isValid || !cannotClose) return false;

    if (veriffSession.status) return false;

    return Boolean(!localStorageHandler.get('infoVerifyInfoModalShow'));
  };

  const [showVerifyModal, toggleShowVerifyModal] = useState(willOpenVerifyModal());


  useEffect(() => {
    toggleShowVerifyModal(willOpenVerifyModal());
  }, [profile]);

  if (isVerificationRequest || isVerificationCallRequest) {
    return (
      <InfoVerifyContent isVerificationRequest={isVerificationRequest} isVerificationCallRequest={isVerificationCallRequest} profile={profile} canClose={false} onCloseModal={toggleShowVerifyModal} />
    );
  }

  if (!showAgreementWizard) {
    if (showVerifyModal) {
      return (
        <InfoVerifyContent profile={profile} onCloseModal={toggleShowVerifyModal} />
      );
    }

    return null;
  }

  if (isMobile) return null;

  return (
    <AgreementInner />
  );
};

export default Agreement;
