import React, { Component } from 'react';
import moment from 'moment-timezone';
import classNames from './../utils/classNames';

import ArrowDown from './ArrowDown';

import calendarUtils from './../utils/calendar';
import formatTime from './../utils/formatTime';
// import styles from './calendar.styl';

const meridiemMap = {
  AM: {
    id: 'AM',
    change: 'PM',
    plusTime: 0,
  },
  PM: {
    id: 'PM',
    change: 'AM',
    plusTime: 12,
  },
};

const parseDate = (date, hours, minutes, meridiem) => {
  const mDate = moment(date);
  const parseStr = `${mDate.year()} ${mDate.month() + 1} ${mDate.date()} ${+hours} ${+minutes} ${meridiem}`;
  const result = moment(parseStr, 'YYYY M D h m a');
  return result;
};

export default class Calendar extends Component {
  constructor(props) {
    super();
    const chosenDate = moment(props.chosenDate);
    const time = chosenDate.format('h m A').split(' ');
    this.state = {
      month: calendarUtils.generateMonthDates(props.chosenDate),
      displayedMonth: chosenDate.month(),
      chosenDate,
      meridiem: time[2],
      hours: time[0],
      minutes: time[1],
    };

    this.onBackArrowClick = this.onBackArrowClick.bind(this);
    this.onForwardArrowClick = this.onForwardArrowClick.bind(this);
    this.onDateClick = this.onDateClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onMeridiemClick = this.onMeridiemClick.bind(this);
    this.onHoursChange = this.onHoursChange.bind(this);
    this.onMinutesChange = this.onMinutesChange.bind(this);
  }

  onBackArrowClick() {
    const month = calendarUtils.generateMonthDates(this.state.month[1][0].subtract(1, 'month'));
    this.setState({
      month,
      displayedMonth: month[1][0].month(),
    });
  }

  onForwardArrowClick() {
    const month = calendarUtils.generateMonthDates(this.state.month[1][0].add(1, 'month'));
    this.setState({
      month,
      displayedMonth: month[1][0].month(),
    });
  }

  onDateClick(date) {
    if (!this.props.dateValidator || this.props.dateValidator(date)) {
      this.setState({ chosenDate: date });
    }
  }

  onMeridiemClick() {
    this.setState({ meridiem: meridiemMap[this.state.meridiem].change });
  }

  onHoursChange(e) {
    const hours = parseInt(e.target.value, 10);
    if (e.target.value === '' || (hours && hours >= 0 && hours <= 12)) {
      this.setState({ hours: hours || 0 });
    }
  }

  onMinutesChange(e) {
    const minutes = parseInt(e.target.value, 10);
    if (e.target.value === '' || (minutes && minutes >= 0 && minutes <= 59)) {
      this.setState({ minutes: minutes || 0 });
    }
  }

  onSubmit(e) {
    e.stopPropagation();
    const { chosenDate, hours, minutes, meridiem } = this.state;
    const { name } = this.props;
    const result = parseDate(chosenDate, hours, minutes, meridiem);
    this.props.onSubmit(result, name);
  }

  render() {
    const { hideTime, ntOptions = {} } = this.props;
    const { writerTimezone, clientTimeZone } = ntOptions;
    const { displayedMonth, chosenDate, hours, minutes, meridiem } = this.state;
    const { onDateClick } = this;
    return (
      <div className="calendar-container">
        <header className="calendar-header">
          <span onClick={this.onBackArrowClick} className="calendar-back_arrow">
            <ArrowDown size={10} />
          </span>

          <span>{this.state.month[1][0].format('MMMM YYYY')}</span>

          <span onClick={this.onForwardArrowClick} className="calendar-forward_arrow">
            <ArrowDown size={10} />
          </span>
        </header>
        <div className="calendar-date_part">
          <div className="calendar-week_title">
            <span className="calendar-calendar_cell">Mo</span>
            <span className="calendar-calendar_cell">Tu</span>
            <span className="calendar-calendar_cell">We</span>
            <span className="calendar-calendar_cell">Th</span>
            <span className="calendar-calendar_cell">Fr</span>
            <span className="calendar-calendar_cell">Sa</span>
            <span className="calendar-calendar_cell">Su</span>
          </div>
          {this.state.month.map((week, i) => (
            <div key={i}>
              {week.map((day, j) => (
                <span
                  key={j}
                  className={classNames(
                    "calendar-calendar_day",
                    "calendar-calendar_cell",
                    // { "calendar-disabled_day": day.month() !== displayedMonth },
                    { "calendar-disabled_day calendar-disabled_day--un-chosen": clientTimeZone && day.isBefore(moment(), 'day') },
                    { "calendar-chosen_day": day.isSame(chosenDate, 'day') },
                  )}
                  onClick={() => { onDateClick(day); }}
                >
                  {day.format('D')}
                </span>
              ))}
            </div>
          ))}
        </div>
        {!hideTime &&
          <div className="calendar-time_part">
            {clientTimeZone && <div className="mb8 calendar-time_part__tutor-zone">{`Your time (GTM ${writerTimezone > 0 ? `+${writerTimezone}` : `${writerTimezone}`})`}</div> }
            <input
              type="number"
              value={this.state.hours}
              onChange={this.onHoursChange}
              className={classNames("calendar-time_item", "calendar-time_input")}
              min="1"
              max="12"
              step="1"
            />
            <span>&nbsp;:&nbsp;</span>
            <input
              type="number"
              value={this.state.minutes}
              onChange={this.onMinutesChange}
              className={classNames("calendar-time_item", "calendar-time_input")}
              min="0"
              max="59"
              step="1"
            />
            <span
              onClick={this.onMeridiemClick}
              className={classNames("calendar-time_item", "calendar-meridiem")}
            >
              {this.state.meridiem}
            </span>
            {
              clientTimeZone &&
              <>
                <div className="mt8 mb8 calendar-time_part__tutor-zone">{`Student’s time (${clientTimeZone})`}</div>
                <div className="calendar-time_part__tutor-zone">{formatTime(parseDate(chosenDate, hours, minutes, meridiem).tz(clientTimeZone), 'dt')}</div>
              </>
            }
          </div>
        }
        <div
          onClick={this.onSubmit}
          className="calendar-submit_button"
          data-name="calendarUpdateBtn"
          data-dropdown="close"
        >
          <span data-name="calendarUpdateBtn" className="calendar-submit_title">Update</span>
        </div>
      </div>
    );
  }
}
