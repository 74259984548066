
import React from 'react';

import Tooltip from 'components/Tooltip';
import ButtonAccept from './ButtonAccept';

const ButtonAcceptDisabled = ({ onAcceptWithEvent, isDisabled = false }) => (
  <Tooltip className="top-btn-tooltip" isHtml content="Please verify your account <br /> to accept a new order into work.">
    <ButtonAccept isDisabled={isDisabled} onAcceptWithEvent={onAcceptWithEvent} />
  </Tooltip>
);

export default ButtonAcceptDisabled;
