import React from 'react';

import formatTime from 'utils/formatTime';
import amountToString from 'utils/amountToString';
import moment from 'moment-timezone';

const PayloadDropDown = ({ payload, className }) => (
  <div className={className}>
    <div className="row">
      <div className="col-2">Payment method:</div>
      <div className="col-3">{payload.payment_details}</div>
    </div>
    <div className="row">
      <div className="col-2">Withdrawal amount:</div>
      <div className="col-3">{amountToString(payload.amount)}</div>
    </div>
    <div className="row">
      <div className="col-2">Withdrawal fee:</div>
      <div className="col-3">{amountToString(payload.fee)}</div>
    </div>
    <div className="row">
      <div className="col-2">Net withdrawal:</div>
      <div className="col-3">{amountToString(payload.net_withdrawal)}</div>
    </div>
    <br />
    <div className="row">
      <div className="col-2">Requested on:</div>
      <div className="col-3">{formatTime(payload.requested_on, 'd')}</div>
    </div>
    <div className="row">
      <div className="col-2">Requested before:</div>
      <div className="col-3">{formatTime(payload.expected_on, 'd')}</div>
    </div>
    <div className="row">
      <div className="col-2">Payment due date:</div>
      <div className="col-3">{formatTime(moment(payload.expected_on).add(10, 'days'), 'd')}</div>
    </div>
  </div>
);

export default PayloadDropDown;
