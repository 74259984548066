import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';

import classNames from './../utils/classNames';

const DNDModeContainer = ({ DNDmode }) => {
  const [show, switchShow] = useState(false);
  const didMount = useRef(false);
  let timeoutID = null;
  useEffect(() => {
    if (didMount.current) {
      switchShow(true);
      timeoutID = setTimeout(() => switchShow(false), 3000);
    } else didMount.current = true;
  }, [DNDmode]);
  const onClickHandler = useCallback(
    () => {
      if (timeoutID) clearTimeout(timeoutID);
      switchShow(false);
    },
    [DNDmode],
  );
  const className = classNames('dnd-mode-container', { 'dnd-mode-container--show': show });
  return (
    <div
      className={className}
      onClick={onClickHandler}
    >
      Do not disturb mode is { DNDmode ? 'on' : 'off' }
    </div>

  );
};

const mapStateToProps = store => ({
  DNDmode: store.settings.DNDmode,
});

export default connect(mapStateToProps, null)(DNDModeContainer);
