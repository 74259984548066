import React, { useState, useMemo, useEffect } from 'react';

import { usePrevious } from 'hooks';

import utils from 'utils';
import classNames from 'utils/classNames';
import getPreferredHelp from 'utils/getPreferredHelp';

import {
  STATUS_OPEN,
  STATUS_REVISION,
} from 'store/orders/actions';

import {
  TAB_INFO,
  TAB_QA,
  TAB_MESSAGES,
  TAB_REVISION,
  TAB_CLIENT_CHAT,
  TAB_CLIENT_SESSIONS,
  OUTPUT_FORMATS_MAP,
  OUTPUT_FORMATS_MAP_TOOLTIPS,
} from 'constants/order';

import OrderTags from 'components/OrderTags';
import Icon from 'components/Icon';


import { getStatus } from '../utils';

import OrderInfo from './OrderInfo';
import TabButton from './TabButton';
import TabContent from './TabContent';

const ORDER_CONTENT_HIDE_TABS = [TAB_MESSAGES, TAB_QA, TAB_CLIENT_CHAT];

const OrderContent = (props = {}) => {
  const
    {
      order = {}, user = {},
      setRejectAccepted, onSelect, mobile,
      messageList, hasClientChatNotification,
      isLoadingAdd, containerRef, isLoading, error,
      showTab, standalone,
      __tab,
    } = props;

  const previousIsLoadingAdd = usePrevious(isLoadingAdd);
  const previousMessageList = usePrevious(messageList);

  const isDuplicateAndRevisionOrder = utils.isDuplicateAndRevisionOrder(order);
  const isNTorder = utils.isNTorder(order);

  const [tab, setTab] = useState(TAB_INFO);

  const previousTab = usePrevious(tab);

  const status = getStatus(order);

  const {
    qa_report_score: qaReportScore,
    pregrader_assessing_avg: pregraderAssessingAvg,
    no_sensitive: noSensitive = false,
  } = order;

  const showTabRevision = useMemo(() => utils.isOrderOnRevision(order), [order]);
  const showTabQA = useMemo(() => (!!pregraderAssessingAvg || !!qaReportScore), [order]);

  const classNameTabBtns = classNames('btn-toggle-group row vertical', { 'order-static-tab': tab === TAB_MESSAGES });
  let classNameDetailsBody = classNames('order-detail-body', { 'with-static': tab === TAB_MESSAGES }, { 'order-detail-body--standalone-NT': isNTorder && standalone });
  classNameDetailsBody = classNames(classNameDetailsBody, { 'with-static--big': (tab === TAB_MESSAGES && showTabRevision && showTabQA) });
  const classNameTab = classNames({ 'row-border': tab !== TAB_QA });
  const questionContainer = classNames('tooltip-container row', { 'tooltip tooltip__icon': mobile });


  const {
    jabber_node: jabberNode, title: orderTitle = '',
    output_formats: helpFormats = [],
    other_output_format: otherHelpFormat = '',
    writer_status: writerStatus,
  } = order || {};

  useEffect(() => {
    if (!containerRef) return;

    if (previousTab !== tab || previousIsLoadingAdd !== isLoadingAdd) {
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [previousIsLoadingAdd, isLoadingAdd, tab, previousTab, containerRef]);

  useEffect(() => {
    if (!containerRef || tab !== TAB_MESSAGES) return;

    if (messageList.length !== previousMessageList.length && mobile && messageList.length) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [tab, messageList, mobile, previousMessageList, containerRef]);

  useEffect(() => {
    if (!showTab) return;

    showTab(tab);
  }, [tab]);

  useEffect(() => {
    setTab(__tab);
  }, [__tab]);

  useEffect(() => {
    if (status === STATUS_REVISION || (isDuplicateAndRevisionOrder && !noSensitive)) {
      setTab(TAB_REVISION);
      return;
    }

    if (isDuplicateAndRevisionOrder || tab === TAB_INFO) return;

    if (!jabberNode && status === STATUS_OPEN) {
      setTab(TAB_INFO);
    }
  }, [isLoading, jabberNode, isDuplicateAndRevisionOrder, noSensitive, status]);

  if (isLoading || error) return null;

  const _helpFormats = getPreferredHelp(order, true);


  return (
    <section className={classNameDetailsBody}>
      <div className={classNameTab}>
        {!ORDER_CONTENT_HIDE_TABS.includes(tab) &&
          <div>
            <h1>{orderTitle}</h1>
            <div className="order-info-statuses">
              <OrderTags order={order} user={user} isOpenStatus={status === STATUS_OPEN} />
            </div>
            {
              isNTorder && (
                <div className="order-info-help-format">
                  <div>Preferred help format:</div>
                  {_helpFormats.length > 0 &&
                    <ul className="mt4">
                      {
                        _helpFormats.map((hf = '') => (
                          <li className="row vertical order-info-help-format__item mb4">
                            <div>{hf}</div>
                            <div className={questionContainer}>
                              <Icon className="ml8 svg-icon tooltip-trigger tooltip-trigger-size" iconName="question" />
                              <div className="tooltip-content output-format-tooltip" dangerouslySetInnerHTML={{ __html: otherHelpFormat && (hf.toLocaleLowerCase() || '').includes('other') ? OUTPUT_FORMATS_MAP_TOOLTIPS.other : OUTPUT_FORMATS_MAP_TOOLTIPS[hf.toLocaleLowerCase()] }} />
                            </div>
                          </li>
                          ))
                      }
                    </ul>
                  }
                  {_helpFormats.length === 0 && (
                  <div className="order-info-help-format__item row">
                    <div>Client didn’t specified yet - need to ask</div>
                    <div className={questionContainer}>
                      <Icon className="ml8 svg-icon tooltip-trigger tooltip-trigger-size" iconName="question" />
                      <div className="tooltip-content output-format-tooltip" dangerouslySetInnerHTML={{ __html: OUTPUT_FORMATS_MAP_TOOLTIPS['need ask'] }} />
                    </div>
                  </div>
                )}
                </div>)
            }

            <div className="order-info-wrap">
              <OrderInfo
                order={order}
                status={status}
                setRejectAccepted={setRejectAccepted}
                onSelect={onSelect}
                mobile={mobile}
              />
            </div>
          </div>
        }
        {(writerStatus !== 'client_paid' || Boolean(jabberNode) || isDuplicateAndRevisionOrder) && tab !== TAB_QA &&
          <div className={classNameTabBtns}>
            {
              [
                { tabName: TAB_REVISION, text: 'Revision details', viewFactor: showTabRevision || isDuplicateAndRevisionOrder },
                { tabName: TAB_INFO, text: isDuplicateAndRevisionOrder ? 'Initial order details' : 'Order Info', viewFactor: true },
                { tabName: TAB_CLIENT_CHAT, text: 'Client chat', viewFactor: jabberNode && (mobile || standalone) },
                { tabName: TAB_CLIENT_SESSIONS, text: 'Tutoring sessions', viewFactor: jabberNode },
                { tabName: TAB_MESSAGES, text: 'Operator messages', viewFactor: writerStatus !== 'client_paid' || Boolean(jabberNode) },
                { tabName: TAB_QA, text: 'QA Report', viewFactor: showTabQA },
              ].map(({ tabName, text, viewFactor }) => (viewFactor && <TabButton
                key={tabName}
                tab={tab}
                tabName={tabName}
                text={text}
                order={order}
                messageList={messageList}
                hasClientChatNotification={hasClientChatNotification}
                showTabRevision={showTabRevision}
                showTabQA={showTabQA}
                setTab={setTab}
                standalone={standalone}
              />))
            }
          </div>
        }
      </div>
      <TabContent {...props} tab={tab} status={status} setTab={setTab} showTabRevision={showTabRevision} showTabQA={showTabQA} />
    </section>
  );
};

export default OrderContent;
