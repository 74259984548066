import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';

import { getInboxUpdates } from 'store/inboxUpdates/selectors';
import { closeModalComponent, addModalComponent } from 'store/modals/actions';
import { inboxUpdatesFetchIfNeeded, getInboxUpdatesNextAction, inboxUpdatesFetchExtraNeeded } from 'store/inboxUpdates/actions';

import Switch from 'components/Switch';
import { StickyContainer, StickyHead, StickyBody } from 'components/Sticky';
import Emoji from 'components/Emoji';

import modalList from 'constants/modalList';

import FilterTags from './FilterTags';
import NotificationTags from './NotificationTags';
import InboxUpdateDetail from './InboxUpdateDetail';


import './styles/inbox_updates.scss';


const InboxUpdates = () => {
  const {
    items: updateList = [], total, page, sorting, filter = {},
  } = useSelector(getInboxUpdates);

  const dispatch = useDispatch();
  const [notSeenFilter, setNotSeenFilter] = useState(filter.not_seen || false);
  const [filterTags, setFilterTags] = useState([]);
  const dataModal = modalList.InboxUpdateDetail;

  useEffect(() => {
    dispatch(inboxUpdatesFetchIfNeeded(page, sorting, filter));
  }, []);

  const loadNext = (numPage, _sorting, _filter) => {
    dispatch(getInboxUpdatesNextAction(numPage, _sorting, { ..._filter, tags: filterTags, not_seen: notSeenFilter }));
  };

  const onFilterTag = (tag) => {
    let newTags = [];
    if (filterTags.includes(tag)) {
      newTags = filterTags.filter(_tag => _tag !== tag);
    } else {
      newTags = [...filterTags, tag];
    }
    setFilterTags(newTags);
    dispatch(inboxUpdatesFetchExtraNeeded(1, sorting, { ...filter, tags: newTags, not_seen: notSeenFilter }));
  };

  const setSeenFilter = (value) => {
    setNotSeenFilter(value);
    dispatch(inboxUpdatesFetchExtraNeeded(1, sorting, { ...filter, tags: filterTags, not_seen: value }));
  };

  const closeHandler = () => {
    dispatch(closeModalComponent(dataModal.name));
  };


  const onOpenNotification = (id, isSeen) => {
    const component = <InboxUpdateDetail closeHandler={closeHandler} id={id} isSeen={isSeen} />;
    dispatch(addModalComponent({ component, ...dataModal }));
  };

  const renderNotificationRow = item => (
    <div className={classNames('table-row row vertical read_inbox', { 'not_read_inbox': !item.seen })} onClick={() => onOpenNotification(item.id, item.seen)}>
      <div className="col-3">{item.name}</div>
      <div className="col-4">{item.tags.length > 0 && <NotificationTags activeTags={item.tags} />}</div>
      <div className="col-2">{formatTime(item.publish_at, 'dt')}</div>
      <div className="col-3">{item.text_preview}</div>
    </div>
  );

  const renderNotificationList = () => {
    if (!updateList.length) {
      return (
        <div className="sticky-table container">
          <div className="page-not-result no-border">
            <Emoji id="crySad" />
            <h1>Oops, there are no updates.</h1>
            <p className="notes">Please try again later.</p>
          </div>
        </div>
      );
    }

    return (
      updateList.map(item => <div key={item.id}>{renderNotificationRow(item)}</div>)
    );
  };

  const renderNextButton = () => {
    if (!total || updateList.length === total) {
      return null;
    }

    const numPage = page + 1;
    const text = `Load next items (${updateList.length} / ${total})`;

    return (
      <button
        className="btn btn-show-next"
        onClick={() => { loadNext(numPage, sorting, filter); }}
      >
        {text}
      </button>
    );
  };

  return (
    <div className="container">
      <div className="table orders-table">
        <StickyContainer className="sticky-table">
          <StickyHead className="sticky-table-head" classNameFixed="sticky-table-head-fixed">

            <div className="table-filter row space-between">
              <div className="col-9">
                <FilterTags onClick={onFilterTag} activeTags={filterTags} />
              </div>
              <div className="col-3">
                <Switch
                  className={classNames('switch-filter ta-right-flex', { 'switch-filter--active': notSeenFilter })}
                  name="seen"
                  isOn={notSeenFilter}
                  handleToggle={() => setSeenFilter(!notSeenFilter)}
                >
                  <div className="switch-filter__name">Show only unread</div>
                </Switch>
              </div>
            </div>

            <div className="table-header">
              <div className="row">
                <div className="col-3">Update Name</div>
                <div className="col-4">Tags</div>
                <div className="col-2">Date & time</div>
                <div className="col-3">Preview</div>
              </div>
            </div>
          </StickyHead>

          <StickyBody className="sticky-table-body">
            {renderNotificationList()}
          </StickyBody>
        </StickyContainer>
      </div>
      {renderNextButton()}
    </div>
  );
}

export default InboxUpdates;
