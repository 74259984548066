import React from 'react';

import Modal from 'components/Modal';

import Emoji from 'components/Emoji';

const OrderDeletedModal = ({ onClose }) => (
  <Modal onClose={onClose} className="ta-center" small>
    <Emoji id="verySad" classname="emojiFont" />
    <h2 className="title mb30 mt20">Sorry, this order has been <br />accepted by another Writer</h2>
    <button type="button" className="btn btn-bright" onClick={onClose}>Got it</button>
  </Modal>
);

export default OrderDeletedModal;
