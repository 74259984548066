export const HELP_SET_SEARCH = 'HELP_SET_SEARCH';

export const helpSetSearch = value => ({
  type: HELP_SET_SEARCH,
  value,
});

export const wsConnect = () => ({ type: 'WS_CONNECT' });
export const wsSubscribe = payload => ({ type: 'WS_SUBSCRIBE', payload });
export const wsUnSubscribe = payload => ({ type: 'WS_UNSUBSCRIBE', payload });
