import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ordersFindSetFilterSubject } from './../store/ordersFind/actions';
import { getOrdersFind, getOrdersSubjectsWithCount, getOrdersSubjectsByGroupWithCount } from './../store/ordersFind/selectors';
import Emoji from './../components/Emoji';

import Checkbox from './Checkbox';

class SubjectsSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFilter: [],
    };

    this.changeFilterItem = this.changeFilterItem.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.uncheckAll = this.uncheckAll.bind(this);
  }

  componentDidMount() {
    const { filter } = this.props;
    this.setState(state => Object.assign({}, state, {
      currentFilter: [...filter.subject],
    }));
  }

  getOrdersCountByFilter() {
    const { orders, isDe, orderSubjectGroups } = this.props;
    const { currentFilter } = this.state;
    if (!currentFilter.length) {
      return orders.length;
    }
    if (isDe) {
      return orders.filter(order => currentFilter.some(filterItem => filterItem.toLowerCase() === order.subject.toLowerCase())).length;
    }

    const currentFilterSubjects = currentFilter.reduce((result, item) => [...result, ...orderSubjectGroups[item]], []);
    return orders.filter(order => currentFilterSubjects.some(filterItem => filterItem.toLowerCase() === order.subject.toLowerCase())).length;
  }

  changeFilterItem(event) {
    const { checked, name } = event.target;
    this.setState((state) => {
      if (checked) {
        state.currentFilter.push(name);
        return Object.assign({}, state, {
          currentFilter: [...new Set(state.currentFilter)],
        });
      }
      return Object.assign({}, state, {
        currentFilter: state.currentFilter.filter(item => name !== item),
      });
    });
  }

  applyFilter() {
    const { setFilterSubject } = this.props;
    const { currentFilter } = this.state;
    setFilterSubject(currentFilter);
  }

  uncheckAll() {
    this.setState(state => Object.assign({}, state, {
      currentFilter: [],
    }));
  }

  render() {
    const { orderSubjects } = this.props;
    const { currentFilter } = this.state;
    const count = this.getOrdersCountByFilter();
    return (
      <div>
        <ul className="menu">
          {Object.keys(orderSubjects).map((s) => {
            const item = orderSubjects[s];
            return (
              <li key={item.name} className="menu-item">
                <Checkbox name={item.name} disabled={!item.count} onChange={this.changeFilterItem} checked={currentFilter.indexOf(item.name) > -1}>
                  {item.name}
                </Checkbox>
              </li>
            )
          })}
        </ul>
        <div className="dropdown-footer">
          {!count ?
            <p /> :
            <p><b>{count} orders</b> found matching to selected filters <Emoji id=":)" /></p>
          }
          <div className="btn-group">
            {!!currentFilter.length && <button type="button" className="btn btn-light btn-sm" data-dropdown="keep" onClick={this.uncheckAll}>Uncheck all</button>}
            <button type="button" className="btn btn-bright btn-sm" data-dropdown="close" onClick={this.applyFilter}>Apply</button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const isDe = state.user && (state.user.profile_type === 'D');
  const filter = state.ordersFind.filter[ownProps.typeId];
  const orderSubjects = isDe
    ? getOrdersSubjectsWithCount[ownProps.typeId](state)
    : getOrdersSubjectsByGroupWithCount[ownProps.typeId](state);

  const { orderSubjectGroups } = state.settings;
  const orders = getOrdersFind[ownProps.typeId](state);
  return {
    isDe,
    orderSubjectGroups,
    filter,
    orderSubjects,
    orders,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFilterSubject: value => dispatch(ordersFindSetFilterSubject(ownProps.typeId, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubjectsSearchForm);
