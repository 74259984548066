import React, { useMemo } from 'react';

import utils from 'utils';

import AvatarOverview from './AvatarOverview';
import About from './About';
import Education from './Education';
import AdditionalInfo from './AdditionalInfo';
import Location from './Location';
import ContactInfo from './ContactInfo';

const ProfileHead = ({ data, dataLevel,
  onChangeFields, isDe, countryList, profile 
}) => {
  const { iconClassName, levelName } = dataLevel;

  const isStem = utils.isStem(profile);
  const isTutor = utils.isTutor(profile);

  const __countryList = useMemo(() => countryList.sort((a, b) => a.localeCompare(b)), [countryList]);

  return (
    <div className="person-info-head">
      <div className="writer-info">
        <div className="row space-between vertical">
          <span className={iconClassName}>
            <a href="/progress/activity/warnings" target="_blank" rel="noopener noreferrer">{levelName}-level</a>
          </span>
          {!isStem &&
          <div>
            <a className="i-question" href="https://drive.google.com/file/d/1z6nu320fe8CCYQoAA-yEfrgkO2M2CeEs/view?usp=sharing" target="_blank" rel="noopener noreferrer">How to set up my profile</a>
          </div>
              }
        </div>
      </div>

      <AvatarOverview data={data} isStem={isStem} onChangeFields={onChangeFields} />
      <About data={data} isStem={isStem} onChangeFields={onChangeFields} />
      <Education data={data} isStem={isStem} onChangeFields={onChangeFields} />
      <Location data={data} isStem={isStem} onChangeFields={onChangeFields} isDe={isDe} countryList={__countryList} />
      <AdditionalInfo data={data} isStem={isStem} onChangeFields={onChangeFields} isTutor={isTutor} />

      <h4>Contact info:</h4>
      <ContactInfo profile={profile} isStem={isStem} />
    </div>
  );
};

export default ProfileHead;
