import React from 'react';

import InboxUpdates from './../components/InboxUpdates';

const InboxUpdatesPage = () => (
  <div>
    <InboxUpdates />
  </div>
);

export default InboxUpdatesPage;
