import { createSelector } from 'reselect';
import moment from 'moment-timezone';

const stable = require('stable');

export const getSessions = state => state.clientSessions.sessions;
export const isFetching = state => state.clientSessions.isFetching;

export const getOrderSessions = orderId => createSelector(
  getSessions,
  (sessions) => {
    const __sessions = [...(sessions[orderId] || [])];
    const sorter = (a, b) => moment(a.schedule_at).startOf('second').diff(moment(b.schedule_at).startOf('second'));
    return stable(__sessions, sorter);
  },
);

