import React from 'react';

import classNames from 'utils/classNames';

import localStorageHandler from 'utils/localStorageHandler';

import slides from './slides';

const TermsOfUseButtons = ({
  currentPage, closeTermsOfUse, goToNextPage, goToPrevPage, isNewWriter, agreeTermsOfUse,
}) => {
  const isFirstTermsOfUsePage = currentPage === 1;
  const isLastTermsOfUsePage = currentPage === slides.length;

  const agreeTermsOfUseHandler = () => {
    localStorageHandler.remove('termsOfUsePage');
    agreeTermsOfUse();
  };

  return (
    <div className={classNames('row vertical', { 'space-between': !isFirstTermsOfUsePage, 'flex-end': isFirstTermsOfUsePage })}>
      {isFirstTermsOfUsePage && (
        <button onClick={goToNextPage} type="button" className="btn btn-bright btn-8-4">Next</button>
      )}
      {(!isFirstTermsOfUsePage && !isLastTermsOfUsePage) && (
        <>
          <button onClick={goToPrevPage} type="button" className="btn btn-8-4 btn-grey">Back</button>
          <button onClick={goToNextPage} type="button" className="btn btn-bright btn-8-4">Next</button>
        </>
      )}
      {
        isLastTermsOfUsePage && (
        <>
          <button onClick={goToPrevPage} type="button" className="btn btn-8-4 btn-grey">Back</button>
          {!isNewWriter && <button onClick={closeTermsOfUse} type="button" className="btn btn-bright btn-8-4">Close</button> }
          {isNewWriter && <button onClick={agreeTermsOfUseHandler} type="button" className="btn btn-bright btn-8-4">Agree</button> }
        </>
        )
      }
    </div>
  );
};

export default TermsOfUseButtons;
