import React, { Component } from 'react';
import { UnmountClosed } from 'react-collapse';

import Icon from 'components/Icon';

class OrderComment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(state => Object.assign({}, state, {
      isOpen: !state.isOpen,
    }));
  }

  render() {
    const {
      children, title, icon, onResize,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="order-tab-collapse">
        <div className="order-tab-collapse-head" onClick={this.toggle}>
          <Icon className="svg-icon" iconName={icon} />
          <span className="order-tab-head-name">{title}</span>
          {isOpen
                        ? <span className="arrow-down active" />
                        : <span className="arrow-down" />
                    }
        </div>
        <UnmountClosed isOpened={isOpen} className="order-tab-collapse-body order-client-info" onRender={onResize}>
          <div className="order-tab-content">
            <div className="order-comment">{children}</div>
          </div>
        </UnmountClosed>
      </div>
    );
  }
}

export default OrderComment;
