import React from 'react';

import Modal from 'components/Modal';
import Radio from 'components/Radio';

const SUB_TITLE = {
  'I want to delete my account': 'Your account will be deleted after you submit your selection.',
  'I want to freeze my account': 'Your account will be frozen after you submit your selection. Note: you will need to pass standard de-freezing procedure if you want to gain access to your account again.'
};

const REASONS = {
  'I want to delete my account': [
    'I do not have time to work here',
    'I initially misunderstood my position\'s conditions',
    'I am not happy with evaluation of my work\'s quality',
    'Prices are too low',
    'Ethical reasons',
    'I do not want to pass verification',
    'No specific reason',
  ],
  'I want to freeze my account': [
    'I do not have time to work here',
    'I initially misunderstood my position’s conditions',
    'I am not happy with evaluation of my work’s quality',
    'Prices are too low',
    'Ethical reasons',
  ]
};

const ModalReasonSelector = ({ onClose, onSubmit, onChangeReason, reason = '', typeIssue }) => {
  const handleSelectReason = (event) => {
    onChangeReason(event.target.value);
  };

  const handleSubmit = () => {
    if (reason) {
      onSubmit();
    }
  };

  return (
    <Modal onClose={onClose} wide>
      <h4 className="title mb20">Select the reason for deleting your account</h4>
      <p className="title-md mb20 text-light">{SUB_TITLE[typeIssue]}</p>
      <div className="withdrawal-box">
        {REASONS[typeIssue].map(r => (
          <div key={r} className="field-val">
            <Radio name="reason" value={r} checked={reason === r} onChange={handleSelectReason}>
              <span>{r}</span>
            </Radio>
          </div>
        ))}
      </div>
      <div className="btn-group">
        <button type="button" className="btn btn-light btn-sm" onClick={onClose}>Back</button>
        <button type="button" disabled={!reason} className="btn btn-bright btn-sm" onClick={handleSubmit}>Submit</button>
      </div>
    </Modal>
  );
};

export default ModalReasonSelector;
