import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import formatTime from 'utils/formatTime';

import { inboxUpdatesSetViewedFetch, inboxUpdatesDetailFetch } from 'store/inboxUpdates/actions';
import { getInboxUpdateDetail } from 'store/inboxUpdates/selectors';

import Icon from 'components/Icon';
import Emoji from 'components/Emoji';
import NotificationTags from '../NotificationTags';

import './styles/inbox_update.scss';

const InboxUpdateDetail = ({ closeHandler, id, isSeen }) => {
  const dispatch = useDispatch();
  const { items = {} } = useSelector(getInboxUpdateDetail);
  const { isFetching } = items;
  const inboxUpdateDetail = items[id];

  useEffect(() => {
    if (!isSeen) {
      dispatch(inboxUpdatesSetViewedFetch(id));
    }
    if (!items[id]) {
      dispatch(inboxUpdatesDetailFetch(id));
    }
  }, []);

  if (isFetching) {
    return (
      <div className="inbox_update_container">
        <div className="preloading preloading-box">
          <button className="btn btn-close" type="button" onClick={closeHandler}><Icon className="svg-icon" iconName="close" /></button>
          <p>Loading</p>
        </div>
      </div>
    );
  }

  if (!inboxUpdateDetail) {
    return (
      <div className="inbox_update_container">
        <button className="btn btn-close" type="button" onClick={closeHandler}><Icon className="svg-icon" iconName="close" /></button>
        <div className="page-not-result no-border">
          <Emoji id="crySad" />
          <h1>Oops, there are no updates.</h1>
          <p className="notes">Please try again later.</p>
        </div>
      </div>
    );
  };

  return (
    <div className="inbox_update_container">
      <div className="inbox_update_container__header">
        <h6 className="inbox_update_container__header__title">{inboxUpdateDetail.title}</h6>
        <div className="row vertical">
          <span className="inbox_update_container__header__date">{formatTime(inboxUpdateDetail.publish_at, 'dt')}</span>
          <NotificationTags activeTags={inboxUpdateDetail.tags} />
        </div>
        <button className="btn btn-close" type="button" onClick={closeHandler}><Icon className="svg-icon" iconName="close" /></button>
      </div>
      <div className="inbox_update_container__body" dangerouslySetInnerHTML={{ __html: inboxUpdateDetail.text }} />
    </div>
  );
};

export default InboxUpdateDetail;
