import { urls } from './../../config';
import authFetch from './../../utils/authFetch';

export const NOTICES_REQUEST = 'NOTICES_REQUEST';
export const NOTICES_RECEIVE = 'NOTICES_RECEIVE';
export const NOTICES_ACTION_REQUEST = 'NOTICES_ACTION_REQUEST';
export const NOTICES_ACTION_RECEIVE = 'NOTICES_ACTION_RECEIVE';
export const ADD_NOTICE = 'ADD_NOTICE';

const noticesRequest = () => ({
  type: NOTICES_REQUEST,
});

const noticesReceive = data => ({
  type: NOTICES_RECEIVE,
  data,
});

export const addNotice = data => ({
  type: ADD_NOTICE,
  data,
});

const noticesFetch = () => (dispatch) => {
  dispatch(noticesRequest());
  return authFetch(urls.notices)
    .then((json) => {
      dispatch(noticesReceive(json));
      return Promise.resolve();
    })
    .catch(er => console.error(er));
};

const noticesShouldFetch = (state) => {
  const { notices } = state;
  if (notices.isFetching) {
    return false;
  }
  return notices.didInvalidate;
};

export const noticesFetchExtraNeeded = () => dispatch => authFetch(urls.notices).then((json) => {
  dispatch(noticesReceive(json));
});

export const noticesFetchIfNeeded = () => (dispatch, getState) => {
  if (noticesShouldFetch(getState())) {
    return dispatch(noticesFetch());
  }
};

const noticesActionRequest = () => ({
  type: NOTICES_ACTION_REQUEST,
});

const noticesActionReceive = data => ({
  type: NOTICES_ACTION_RECEIVE,
  data,
});

export const noticesActionFetch = data => (dispatch) => {
  dispatch(noticesActionRequest());
  return authFetch(urls.notices, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => dispatch(noticesActionReceive(data)));
};
