const SIZES_ORDER = {
  minute: 'm',
  minutes: 'm',
  pages: 'p',
  page: 'p',
  words: 'w',
  word: 'w',
  slides: 's',
  slide: 's',
  source: 'src',
  sources: 'src',
};

const TAB_REVISION = 'TAB_REVISION';
const TAB_INFO = 'TAB_INFO';
const TAB_MESSAGES = 'TAB_MESSAGES';
const TAB_QA = 'TAB_QA';
const TAB_CLIENT_CHAT = 'TAB_CLIENT_CHAT';
const TAB_CLIENT_SESSIONS = 'TAB_CLIENT_SESSIONS';

const OUTPUT_FORMATS_MAP = {
  'Live session': 'live session',
  'No live session': 'written help',
  'Decide later': "client isn't sure",
};

const OUTPUT_FORMATS_MAP_TOOLTIPS = {
  'need ask': [
    '<div class="mb8">You can ask student what help format he prefer, or suggest to student the best one based on your opinion.</div>',
    '<div class="mb8">Most popular help formats:</div>',
    '<ul><li class="row vertical">tutoring session;</li>',
    '<li class="row vertical">written explanation;</li>',
    '<li class="row vertical">step by step solution;</li>',
    '<li class="row vertical">exam preparation;</li>',
    '<li class="row vertical">file.</li></ul>',
  ].join(''),
  'tutoring session': '<div>Tutoring session: setup an online call with student to explain with voice, whiteboard or screen-sharing.</div>',
  'written explanation': '<div>Written explanation: complete the task and leave written comments.</div>',
  file: '<div>File: send user the file with completed task.</div>',
  'step by step solution': '<div>Step by step solution: complete the task and provide step-by-step solution on how to do it.</div>',
  'exam preparation': '<div>Exam preparation: explain a particular topic for Client using a tutoring session, Q&A format, or freeform written format.</div>',
  other: '<div>Client specified other type of help. You need to discuss with the client specific format needed.</div>',
};

const ORDER_BANNERS = {
  issuesBanner: { text: 'Noticed any issues in this order? Let us know, we\'ll fix them!', priority: 2 },
  updateDataBanner: { text: 'Heads up! The instructions were updated', priority: 1 },
  tutorKnowledgeBaseBanner: {
    text: 'Need to refresh how do tutoring orders work? <a class="ml8 mr8" target="_blank" href="https://www.notion.so/lrtutors/LR-Tutors-Knowledge-Base-affb4cf7a67a4a0f8b31ef87e6cf4997">Click here</a>for Knowledge Base.',
    priority: Infinity,
    customStyles: { backgroundColor: 'rgba(42, 134, 225, 0.1)', color: '#000' },
  },
  courseTutorBanner: {
    text: 'Complete the LR Tutors: Introduction to Tutoring course for free! <a class="ml8" target="_blank" href="[enroll_link]">Click here</a>',
    priority: Infinity,
    customStyles: { backgroundColor: 'rgba(42, 134, 225, 0.1)', color: '#000' },
  },
};

const NT_MAX_COURSE_ORDERS = 10;

const GROUPS = ['topic', 'loginDetails', 'format', '__abstract', 'source', 'language', 'complexity', 'writtenExplanations', 'fileFormat', 'speakerNotes'];


export {
  TAB_REVISION,
  TAB_INFO,
  TAB_MESSAGES,
  TAB_QA,
  TAB_CLIENT_CHAT,
  TAB_CLIENT_SESSIONS,
  ORDER_BANNERS,
  SIZES_ORDER,
  OUTPUT_FORMATS_MAP,
  OUTPUT_FORMATS_MAP_TOOLTIPS,
  NT_MAX_COURSE_ORDERS,
  GROUPS,
};
