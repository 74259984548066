import React from 'react';

import Icon from './../components/Icon';

const OrderTableSearch = props => {
    const { onSubmit, value, onChange, onToggle } = props;
    return (
        <form className="form-search" onSubmit={onSubmit}>
            <div className="search-wrap">
                <Icon className="svg-icon" iconName="search" />
                <input className="input-text" name="searchValue" type="text" value={value} onChange={onChange} placeholder="Find order by number" />
                <button className="btn btn-close" type="button" onClick={onToggle}><Icon className="svg-icon" iconName="close" /></button>
            </div>
            <button className="btn btn-bright btn-sm" type="sumit">Find order</button>
        </form>
    );
};

const OrderTableSearchBtn = props => {
    return (
        <button type="button" className="btn btn-filter-search" onClick={props.onClick}><Icon className="svg-icon" iconName="search" /></button>
    );
};

export default OrderTableSearch;
export { OrderTableSearchBtn }
