export const MSG_MAP = {
  'crm:estimate_expired': 'Additional time request has just expired.',
  'crm:estimate_canceled': 'Additional time request was canceled.',
  'crm:estimate_paid': 'Your request was approved by the client! New current order size is: [00 hours 00 minutes]',
  'crm:estimate_declined': 'Your request was declined by the client! Please discuss other options and send another request, if needed.',
  'crm:freelancer_rejected': 'The tutor was rejected.',
  'crm:order_assigned': 'This is your chat with the student. Please make sure you have all of the inputs you need to help them out.',
  'crm:revision_requested': 'Student submitted a task revision request.',
  'crm:order_offered': 'This is the beginning of the chat.',
  'crm:chat_idle_fake': 'Dear Tutor, please send your introduction message ASAP, otherwise you will be rejected from the chat with Client.',
  'crm:chat_connected_fake': 'Hey! This is your chat with the student. Here you need to introduce yourself and clarify any questions you have about the task details or confirm that everything is clear. Then you can accept the order into work.',
  'crm:chat_re_connected_fake': 'Hey! You’ve joined this chat for the second time. Please note that you have 5 minutes to start the conversation with the Client by introducing yourself. Otherwise, you will be disconnected from chat with no opportunity to rejoin it once again.',
  'crm:chat_connected': 'This is your chat with the student. Please make sure you have all of the inputs you need to help them out',
  'crm:chat_disconnected': 'Tutor [TutorName] disconnected from chat',
  'crm:live_session_requested': 'Your client would like to have a live session with you. Please schedule a session, clarify session’s details and send an additional time estimate, if needed.',
  'crm:added_top_tutor_addon': 'Your client would like to receive extra attention while working on this task.',
  'crm:bundle_balance_order': 'Keep up with good work!',
  'crm:request_top_tutor_addon': 'The information about the task details was collected from the Client.',
};
