import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment-timezone';

import localStorageHandler from 'utils/localStorageHandler';
import formatTime from 'utils/formatTime';
import classNames from 'utils/classNames';

import { getModalList } from 'store/modals/selectors';

import { addVerifyModal } from 'store/verifyModal/actions';

import VERIFY_STATUSES from 'constants/verifyStatuses';

import InfoModal from '../InfoModal';

const calendlyUrl = 'https://calendly.com/livres-test/meeting-with-lr-manager';

const VerificationRequestIntrusive = '<div class="info-modal-container__header">Hello!</div>' +
'<div class="info-modal-container__text">' +
  'A suspicious activity was detected in your account.' +
  '<div class="mt10">This issue can be resolved by successful verification of your identity via the integrated verification software.</div>' +
  '<div class="mt10">Please go through the verification process <br /> by <strong>[DDL]</strong> to activate your account and continue working.</div>' +
  '<div class="mt10">Otherwise, for your own security, the account will be suspended.</div>' +
'</div>' +
'<div class="info-modal-container__text-light as-grey-text">' +
  'In case you have any questions or concerns, please contact us at <a mailto:freelancer.care@liv-research.com>freelancer.care@liv-research.com</a>' +
'</div>';

const VerificationCallRequestIntrusive = '<div class="info-modal-container__header">Hello!</div>' +
'<div class="info-modal-container__text">' +
  'A suspicious activity was detected in your account.' +
  '<div class="mt10">This issue can be resolved by successful verification of your identity via a short video-call with a Livingston Research representative.</div>' +
  '<div class="mt10">You can reserve an available time-slot by clicking the button below.</div>' +
  '<div class="mt10">Please go through with reserving a time-slot by <strong>[DDL]</strong>.</div>' +
  '<div class="mt10">Otherwise, for your own security, the account will be suspended.</div>' +
'</div>' +
'<div class="info-modal-container__text-light as-grey-text">' +
  'In case you have any questions or concerns, please contact us at <a mailto:freelancer.care@liv-research.com>freelancer.care@liv-research.com</a>' +
'</div>';

const MixRequestsIntrusive = '<div class="info-modal-container__header">Hello there!</div>' +
'<div class="info-modal-container__text">' +
  'A suspicious activity was detected in your account.' +
  '<div class="mt10">This issue can be resolved by successfully confirming your identity in two steps.</div>' +
  '<div class="mt10">1. Verify your identity via the integrated verification software [here].</div>' +
  `<div class="mt10">2. Verify your identity on a short video-call with a Livingston Research representative. You can reserve an available time-slot <a href="${calendlyUrl}" target="_blank" rel="noopener noreferrer">here</a>.</div>` +
  '<div class="mt10">Please go through the verification process by <br />[DDL] to activate your account and continue working. Otherwise, for your own security, the account will be suspended.</div>' +
'</div>' +
'<div class="info-modal-container__text-light as-grey-text">' +
  'In case you have any questions or concerns, please contact us at <a mailto:freelancer.care@liv-research.com>freelancer.care@liv-research.com</a>' +
'</div>';

const CustomVerifyBtn = ({ verifyStatus, handler }) => (
  <button
    className={classNames('btn mr10 btn-next', verifyStatus.class || 'btn-bright')}
    onClick={handler}
    disabled={verifyStatus.disabled || false}
  >
    {verifyStatus.text}
  </button>
);

const InfoVerifyContent = ({
  onCloseModal: __onCloseModal, modalName = 'VerificationRequired', noBtn, canClose = true,
  profile = {}, isVerificationRequest, isVerificationCallRequest,
}) => {
  const [isVerifyStatusLoading, toggleVerifyStatusLoading] = useState(false);
  const modalList = useSelector(getModalList);

  const { last_veriff_session_object: veriffSession = {} } = profile;
  let veriffSessionStatus = veriffSession.status;

  if (veriffSession.active_status === 'declined' && veriffSession.crm_status === 'Resubmission') {
    veriffSessionStatus = 'resubmission_requested';
  }

  const verifyStatus = VERIFY_STATUSES[veriffSessionStatus] || VERIFY_STATUSES.default;

  const dispatch = useDispatch();

  const onCloseModal = () => {
    localStorageHandler.set('infoVerifyInfoModalShow', true);
    __onCloseModal(false);
  };

  const verifyStatusHandler = (status) => {
    toggleVerifyStatusLoading(status);

    if (!status) {
      onCloseModal(false);
    }
  };

  const startVerify = () => {
    dispatch(addVerifyModal({
      verifyStatusHandler: isVerificationRequest ? null : verifyStatusHandler,
    }));
  };

  useEffect(() => {
    let verifyLink = null;

    setTimeout(() => {
      verifyLink = document.querySelector('.verify-link');
      if (isVerificationRequest && isVerificationCallRequest && verifyLink) {
        verifyLink.addEventListener('click', startVerify);
      }
    }, 10);

    return () => {
      if (isVerificationRequest && isVerificationCallRequest && verifyLink) {
        verifyLink.removeEventListener('click', startVerify);
      }
    };
  }, [isVerificationRequest, isVerificationCallRequest, modalList]);

  const verificationCallRequestHandler = () => {
    window.open(calendlyUrl, '_blank').focus();
  };

  const getCustomHtml = () => {
    if (isVerificationRequest && isVerificationCallRequest) {
      const ddlVer = moment(isVerificationRequest);
      const ddlCall = moment(isVerificationCallRequest);
      const { isLink, text } = verifyStatus;
      return {
        modalName: 'MixRequestsIntrusive',
        html: MixRequestsIntrusive.replace('[DDL]', formatTime(ddlVer.isBefore(ddlCall) ? ddlCall.add(120, 'hours') : ddlVer.add(120, 'hours'), 'MMM DD', true))
          .replace('[here]', `<a class="${!isLink ? 'disabled as-grey-text' : 'verify-link'}">${text}</a>`),
      };
    }

    if (isVerificationRequest) {
      const ddl = moment(isVerificationRequest);

      return {
        modalName: 'VerificationRequestIntrusive',
        html: VerificationRequestIntrusive.replace('[DDL]', formatTime(ddl.add(120, 'hours'), 'MMM DD', true)),
      };
    }

    if (isVerificationCallRequest) {
      const ddl = moment(isVerificationCallRequest);

      return {
        modalName: 'VerificationCallRequestIntrusive',
        html: VerificationCallRequestIntrusive.replace('[DDL]', formatTime(ddl.add(120, 'hours'), 'MMM DD', true)),
      };
    }

    return null;
  };

  const getBtnText = () => {
    if (isVerificationCallRequest) return 'Schedule a call';

    if (isVerificationRequest) return '';

    return isVerifyStatusLoading ? 'Loading ...' : 'Verify me!';
  };

  const getCustomBtn = () => {
    if (!isVerificationRequest) return null;

    if (isVerificationRequest && isVerificationCallRequest) return null;

    return {
      component: CustomVerifyBtn, props: { verifyStatus, handler: startVerify },
    };
  };


  return (
    <InfoModal
      closeModal={onCloseModal}
      canClose={canClose}
      modalName={modalName}
      btnText={getBtnText()}
      autoText
      onNextClick={isVerificationCallRequest ? verificationCallRequestHandler : startVerify}
      isBtnDisabled={isVerifyStatusLoading}
      noBtn={noBtn || isVerificationRequest}
      customHtml={getCustomHtml()}
      customBtn={getCustomBtn()}
    />
  );
};

export default InfoVerifyContent;
