import React from 'react';

import StarRating from 'components/StarRating';

import classNames from 'utils/classNames';
import { isNewbie, isBeginner } from 'utils/groups';

const GROUPS = {
  Beginner: 'beginner',
  Newbie: 'newbie',
  a: 'a',
  'a+': 'a-plus',
  b: 'b',
  c: 'c',
  d: 'd',
  f: 'f',
};


const WriterGroupLevel = ({ user = {} }) => {
  const group = user.qc_group.toLowerCase();
  const isNewbieGroup = isNewbie(group);
  const isBeginnerGroup = isBeginner(group);
  const clientAvg = user.metric_data.client_last_avg;
  const levelName = isNewbieGroup ? 'Newbie' : isBeginnerGroup ? 'Beginner' : group.substring(0, 2);

  return (
    <div
      className={classNames('writer-group', `${GROUPS[levelName]}-level`, {
      newbie: isNewbieGroup,
      beginner: isBeginnerGroup,
    })}
    >
      <h2 className="aside-title">{levelName}-level</h2>
      <div className="client-score-wrap">
        <StarRating rate={clientAvg} width={100} />
        <span>({clientAvg})</span>
      </div>
      <span className="client-score-desc">Average client’s score</span>
    </div>
  );
};

export default WriterGroupLevel;
