import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import copy from 'clipboard-copy';

import Tooltip from 'components/Tooltip';

import formatTime from 'utils/formatTime';
import classNames from 'utils/classNames';

const Session = ({ session, toggleModal, onDelete, writerTimezone, clientTimeZone, mobile }) => {
  const [sessionState, setSessionState] = useState('planned');
  const { schedule_at: scheduleAt = '', duration = '', link = '', _id: sessionId } = session;
  const date = useMemo(() => (formatTime(moment(scheduleAt), 'dt')), [scheduleAt, formatTime]);
  const [showCopy, toggleShowCopy] = useState(false);

  const onCopy = () => {
    copy(link);
    toggleShowCopy(true);

    setTimeout(() => {
      toggleShowCopy(false);
    }, 1000);
  };

  const tooltip = `Your time ${date} \n Student’s time ${formatTime(moment(scheduleAt).tz(clientTimeZone), 'dt')}`;


  useEffect(() => {
    const nowMoment = moment();
    const isAfterNow = nowMoment.isAfter(moment(scheduleAt).subtract(10, 'minutes'));
    const isSessionOver = nowMoment.isAfter(moment(scheduleAt).add(duration + 60, 'minutes'));

    if (isSessionOver) {
      setSessionState('isOver');
    }

    if (isAfterNow) {
      setSessionState('live');
    }
  }, []);

  return (
    <li className="session-item" key={sessionId}>
      <div className={classNames(`session-container row vertical session-container--${sessionState}`)}>
        {
          mobile || sessionState === 'isOver' ? (
            <div className="session-container__date">
              <a href={link} target="_blank" rel="noreferrer">
                {date}
              </a>
            </div>
          ) : (
            <Tooltip content={tooltip} className="session-item__tooltip">
              <div className="session-container__date">
                <a href={link} target="_blank" rel="noreferrer">
                  {date}
                </a>
              </div>
            </Tooltip>
          )
        }
        <div className="session-container-icon-block row vertical">
          {
            sessionState !== 'isOver' && (
              <button className={classNames('btn mr8 btn-sm pos-relative', { 'btn-error': sessionState !== 'planned', 'btn-bright': sessionState === 'planned' })} onClick={onCopy}>Copy link
                <div className={classNames(`session-container__copied session-container__copied--${sessionState}`, { 'session-container__copied--show': showCopy })}>Copied</div>
              </button>
            )
          }
          {
            sessionState === 'planned' &&
            <>
              <i className="session-container-icon-block__icon session-container-icon-block__icon--edit" onClick={() => toggleModal(session)} />
              <i className={classNames(`session-container-icon-block__icon session-container-icon-block__icon--delete session-container-icon-block__icon--delete--${sessionState}`)} onClick={() => onDelete(sessionId)} />
            </>
          }
          {
            sessionState === 'isOver' && <p className="session-container-icon-block__over-text">live session is over</p>
          }
        </div>
      </div>
    </li>
  );
};

export default Session;
