import React, { useState } from 'react';

import classnames from 'utils/classNames';

import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

import './index.scss';

const BTNS = [
  {
    text: 'Common button',
    customClass: 'btn',
  },
  {
    text: 'Btn-default',
    customClass: 'btn-default',
  },
  {
    text: 'Btn-default transparent',
    customClass: 'btn-default transparent',
  },
  {
    text: 'Btn-light',
    customClass: 'btn-light',
  },
  {
    text: 'Btn-bright-success',
    customClass: 'btn-bright-success',
  },
  {
    text: 'Btn-grey',
    customClass: 'btn-grey',
  },
  {
    text: 'Btn-bright',
    customClass: 'btn-bright',
  },
  {
    text: 'Btn-error',
    customClass: 'btn-error',
  },
  {
    text: 'Btn-error-inverse',
    customClass: 'btn-error-inverse',
  },
  {
    text: 'Btn-accepted',
    customClass: 'btn-accepted',
  },
  {
    text: 'Btn-ok',
    customClass: 'btn-ok',
  },
  {
    text: 'Btn-success',
    customClass: 'btn-success',
  },
  {
    text: 'Btn-link',
    customClass: 'btn-link',
  },
];

const BTNS_SIZES = [
  {
    size: 'initial',
  },
  {
    size: 'xs',
  },
  {
    size: '8-4',
  },
  {
    size: '100-4',
  },
  {
    size: '30square',
  },
  {
    size: '70',
  },
  {
    size: 'sm',
  },
  {
    size: 'md',
  },
  {
    size: 'md2',
  },
  {
    size: 'xl',
  },
  {
    size: 'big',
  },
  {
    size: 'extra',
  },
  {
    size: 'small',
  },
];

const StoryButtons = () => {
  const [isOpenBtnSize] = useState(false);
  const [btnSize, setBtnSize] = useState('initial');

  const onSetBtnSize = (value) => {
    setBtnSize(value);
  };

  return (
    <div className="story-btn-container">
      <Dropdown isOpen={isOpenBtnSize} closeAlways buttonClassName="btn btn-xl row" text={btnSize}>
        <Select className="menu menu-selectable" value={btnSize} onChange={onSetBtnSize}>
          {BTNS_SIZES.map(({ size }) => <li value={size} className="menu-item" key={size}>{size}</li>)}
        </Select>
      </Dropdown>
      <div className="row column row_gap_12">
        {BTNS.map(({ text, customClass }) => (
          <div className="row column_gap_10">
            <button className={classnames('btn', customClass, `btn-${btnSize}`)}>{text}</button>
            <button disabled className={classnames('btn', customClass, `btn-${btnSize}`)}>{text}</button>
          </div>
            ))}
      </div>
    </div>
  );
};

export default StoryButtons;
