import React from 'react';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import ReservedCounter from 'components/ReservedCounter';

import OrderTimeOver from './OrderTimeOver';
import ButtonAccept from './ButtonAccept';
import ButtonJoinChat from './ButtonJoinChat';
import ButtonAcceptNT from './ButtonAcceptNT';
import ButtonRejectChat from './ButtonRejectChat';
import ButtonRejectOrder from './ButtonRejectOrder';
import ButtonReserveOrder from './ButtonReserveOrder';
import ButtonAcceptDisabled from './ButtonAcceptDisabled';
import ButtonJoinChatTooltip from './ButtonJoinChatTooltip';
import ButtonAcceptDisabledNT from './ButtonAcceptDisabledNT';
import { renderDescriptionReserve, renderDescriptionTag, renderNTAcceptTooltip } from './utils';

const WriterReserved = ({ order, user, isNTorder, setRejected, writerInChat, checkReserve, isStatusLoading, setReservationOver, isDisabledJoinChat, currentWriterInChat, isDisabledAccept, withToolTipDisabled, cannotReserveByGroupMaxOrderCount, onSetReserved, onRejectChat, onAcceptConfirm, renderJoinChatTooltip, onAcceptWithEvent, onJoinChatWithEvent }) => {

  const renderNTButtons = () => (
    <>
      {currentWriterInChat ? <ButtonRejectChat onRejectChat={onRejectChat} /> : <ButtonJoinChatTooltip onJoinChatWithEvent={onJoinChatWithEvent} isDisabledJoinChat={isDisabledJoinChat} order={order} user={user} isButtonSuccess={!isDisabledJoinChat} />}
      {isDisabledAccept ? <ButtonAcceptDisabledNT isDisabled onAcceptConfirm={onAcceptConfirm} /> : <ButtonAcceptNT onAcceptConfirm={onAcceptConfirm} />}
    </>
  );

  const renderButtons = () => (
    withToolTipDisabled ? <ButtonAcceptDisabled isDisabled={isDisabledAccept || cannotReserveByGroupMaxOrderCount} onAcceptWithEvent={onAcceptWithEvent()} /> : <ButtonAccept isDisabled={isDisabledAccept || cannotReserveByGroupMaxOrderCount} onAcceptWithEvent={onAcceptWithEvent} />
  );

  const renderButtonsReservedByMe = () => (
    <div className="col-1 ta-right">
      {writerInChat ? <ButtonReserveOrder isDisabled setReserved={onSetReserved} /> : <ButtonRejectOrder isDisabled={isStatusLoading} setRejected={setRejected} />}
      {isNTorder ? renderNTButtons() : renderButtons()}
    </div>
  );

  const renderButtonsNotReservedByMe = () => (
    <div className="col-1 ta-right">
      <ButtonReserveOrder isDisabled setReserved={onSetReserved} />
      {
        isNTorder ? (
          <>
            {writerInChat ? <ButtonJoinChatTooltip onJoinChatWithEvent={onJoinChatWithEvent} isDisabledJoinChat order={order} user={user} /> : <ButtonJoinChat isDisabled />}
            <Tooltip className="top-btn-tooltip" isHtml content={renderNTAcceptTooltip()}><button className="btn btn-bright btn-md2 ml10 mb15" type="button" disabled onClick={onAcceptWithEvent()}>Accept</button></Tooltip>
          </>
        ) : (
          <ButtonAccept isDisabled />
        )
      }
    </div>
  );


  if (order.isReservationOver) {
    return <OrderTimeOver textOver="Reservation time is over" onClick={checkReserve} withIcon />;
  }


  return (
    <div>
      <div className={`order-tab-actions ${order.reserved_by_me ? ' my-reserve' : ''}`}>
        {!writerInChat && !currentWriterInChat && (
          <div className={`col-1 order-tab-actions-info mb15 ${order.reserved_by_me ? ' my-reserve' : ''}`}>
            <Icon className={`svg-icon icon-time ${order.reserved_by_me ? ' icon-time--green' : ''}`} iconName="icon-time" />
            <span className="mr15 fsSm mr15">Reserved time left:</span>
            <ReservedCounter time={order.reserve_deadline} onOver={setReservationOver} />
          </div>
        )}
        <div className="row space-between vertical">
          {order.reserved_by_me ? renderButtonsReservedByMe() : renderButtonsNotReservedByMe()}
        </div>
      </div>
      {
        order.reserved_by_me &&
        <div className="row vertical space-between">
          {renderDescriptionTag(order.custom_tags)}
          {renderDescriptionReserve(true, isNTorder)}
        </div>
      }
    </div>
  );
};

export default WriterReserved;
