/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';

import classNames from './../utils/classNames';

const Radio = (props) => {
  const {
    name, value, children, checked, onChange, disabled, className,
  } = props;
  const classNameRadio = classNames(className, 'radiobutton', { disabled });
  return (
    <label className={classNameRadio}>
      <input type="radio" name={name} value={value} checked={checked} onChange={onChange} disabled={disabled} />
      <i className="radio-icon" />
      <span className={classNames('radio-label', { disabled })}>
        {children}
      </span>
    </label>
  );
};

export default Radio;
