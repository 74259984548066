import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import './index.scss';

const CircularProgressBar = ({ percentage }) => (
  <div className="circular-progressbar-container">
    <CircularProgressbar
      value={percentage}
      styles={buildStyles({
        strokeLinecap: 'butt',
        pathTransitionDuration: 0.5,
        pathColor: '#2A86E1',
        trailColor: '#9CA9B6',
        backgroundColor: 'transparent',
  })}
    />
  </div>
);

export default CircularProgressBar;
