/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { urls } from 'config';

import authFetch from 'utils/authFetch';

import {
  ORDERS_SET_FAVORITE_REQUEST,
  ORDERS_SET_FAVORITE_RECEIVE,
} from '../constants';

const ordersSetFavoriteRequest = number => ({
  type: ORDERS_SET_FAVORITE_REQUEST,
  number,
});

const ordersSetFavoriteReceive = (number, value) => ({
  type: ORDERS_SET_FAVORITE_RECEIVE,
  number,
  value,
});

export const ordersSetFavoriteFetch = (number, value) => (dispatch, getState) => {
  const order = getState().orders[number] || {};
  const { _id: orderId } = order;
  if (!orderId) {
    console.error('Order is not found');
    return;
  }
  dispatch(ordersSetFavoriteRequest(number));
  return authFetch(`${urls.orders}/${orderId}`, {
    method: 'POST',
    body: JSON.stringify({ action: value ? 'favorite_set' : 'favorite_unset' }),
  }).then(() => dispatch(ordersSetFavoriteReceive(number, value)));
};
