import { connect } from 'react-redux';

import {
  ordersDetailsInvalidate,
  ordersDetailsFetchIfNeeded,
  ordersSetViewedFetchIfNeeded,
  ordersFilesFetchIfNeeded,
  ordersSetFavoriteFetch,
  ordersSetStatusReservedFetch,
  ordersSetStatusRejectedFetch,
  ordersSetStatusAcceptedFetch,
  ordersSetStatusRejectedAcceptedFetch,
  ordersSetStatusRevisionCompletedFetch,
  ordersMessagesFetch,
  ordersMessagesSetReadFetch,
  ordersMessagesAddFetch,
  orderFileUploadFetch,
  orderFilesUploadFetch,
  ordersMessagesAddAttachFetch,
  ordersMessagesAddConfirmFetch,
  ordersMessagesDeleteConfirmFetch,
  ordersFilesRemoveFetch,
  ordersHideInLists,
} from 'store/orders/actions';
import { ordersRevisionFetchIfNeeded } from 'store/ordersRevision/actions';

import Order from 'components/order/Order';

const mapStateToProps = (state, ownProps) => {
  const { user } = state;
  const { orderNumber } = ownProps;
  const order = state.orders[orderNumber] || {};
  const error = order.error || null;
  const isFilesLoading = !order.files || order.files.isFetching || order.files.didInvalidate;
  const isMessagesLoading = !order.messages || order.messages.isFetching || order.messages.didInvalidate;
  const isLoading = order.isFetching || order.didInvalidate || isFilesLoading || isMessagesLoading;
  const fileItems = !order.files || !order.files.items ? {} : order.files.items;
  const fileWebhook = !order.files || !order.files.webhook ? '' : order.files.webhook;
  const messageList = !order.messages || !order.messages.items ? [] : Object.values(order.messages.items);
  const isLoadingAdd = !order.messages ? true : order.messages.isFetchingAdd;
  const isStatusLoading = order.isFetchingStatus || false;
  const hasClientChatNotification = Boolean((state.clientChatNotifications.notifications.find(it => it.node === order.jabber_node) || {}).count) || false;
  return {
    user, isLoading, order, error, fileItems, fileWebhook, isStatusLoading, messageList, isLoadingAdd, hasClientChatNotification,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { orderNumber, onSelect } = ownProps;

  return {
    loadDetails: () => new Promise((resolve, reject) => {
      try {
        dispatch(ordersDetailsFetchIfNeeded(orderNumber)).then(async (json = {}) => {
          if (json.noFetch) {
            resolve();
            return;
          }

          if (!json.details) {
            onSelect(orderNumber);
            resolve();
            return;
          }

          const { details: { _id: orderId } } = json;
          await dispatch(ordersSetViewedFetchIfNeeded(orderNumber, orderId));
          await dispatch(ordersFilesFetchIfNeeded(orderNumber, orderId));
          await dispatch(ordersMessagesFetch(orderNumber, orderId));
          resolve();
        });
      } catch (e) {
        console.error(e);
        reject();
      }
    }),
    invalidate: () => dispatch(ordersDetailsInvalidate(orderNumber)),
    loadOrders: () => dispatch(ordersRevisionFetchIfNeeded()),
    setFavorite: value => dispatch(ordersSetFavoriteFetch(orderNumber, value)),
    setReserved: () => dispatch(ordersSetStatusReservedFetch(orderNumber)),
    setRejected: () => dispatch(ordersSetStatusRejectedFetch(orderNumber)),
    setAccepted: () => dispatch(ordersSetStatusAcceptedFetch(orderNumber)),
    setRejectAccepted: payload => dispatch(ordersSetStatusRejectedAcceptedFetch(orderNumber, payload)),
    setRevisionCompleted: () => dispatch(ordersSetStatusRevisionCompletedFetch(orderNumber)),
    setMessageRead: (messageId, message) => dispatch(ordersMessagesSetReadFetch(orderNumber, messageId, message)),
    addMessage: (to, message) => dispatch(ordersMessagesAddFetch(orderNumber, to, message)),
    uploadFile: (file, group) => dispatch(orderFileUploadFetch(orderNumber, file, group, null, ['revision'])),
    uploadOrderFiles: (files = [], group) => dispatch(orderFilesUploadFetch(orderNumber, files, group, ['revision'])),
    addMessageAttach: (to, files, message) => dispatch(ordersMessagesAddAttachFetch(orderNumber, to, files, message)),
    addMessageConfirm: (to, message, files) => dispatch(ordersMessagesAddConfirmFetch(orderNumber, to, message, files)),
    deleteMessageConfirm: (number, message) => dispatch(ordersMessagesDeleteConfirmFetch(number, message)),
    removeFile: fileId => dispatch(ordersFilesRemoveFetch(orderNumber, fileId)),
    hideInLists: number => dispatch(ordersHideInLists(orderNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
