export const ADD_IMAGES = 'ADD_IMAGES';
export const CLEAR_PREVIEW = 'CLEAR_PREVIEW';

export const addImagesToPreview = (images = []) => ({
  type: ADD_IMAGES,
  payload: images,
});

export const clearPreview = () => ({
  type: CLEAR_PREVIEW,
});
