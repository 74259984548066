import {
  SET_PLUGINS,
  CLOSE_PLUGINS,
} from './actions';


const plugins = (state = {
  pluginsList: [],
}, action) => {
  switch (action.type) {
    case SET_PLUGINS:
      return Object.assign({}, state, {
        pluginsList: [...state.pluginsList, action.payload],
      });
    case CLOSE_PLUGINS:
      return Object.assign({}, state, {
        pluginsList: state.pluginsList.filter(plugin => plugin.id !== action.payload),
      });
    default:
      return state;
  }
};

export default plugins;
