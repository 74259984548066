import React from 'react';

import LearningLab from './../components/LearningLab';

const LearningLabPage = () => (
  <div className="container mt20">
    <LearningLab />
  </div>
);

export default LearningLabPage;
