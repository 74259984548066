import moment from 'moment-timezone';
import {
  FEEDBACKS_REQUEST,
  FEEDBACKS_RECEIVE,
  FEEDBACKS_ACTION_REQUEST,
  FEEDBACKS_ACTION_RECEIVE,
  FEEDBACK_UPDATE_RECEIVE,
  FEEDBACKS_NEXT_REQUEST,
  FEEDBACKS_NEXT_RECEIVE,
  FEEDBACKS_SET_FILTER_REQUEST,
  FEEDBACKS_SET_FILTER_RECEIVE,
} from './actions';

const feedbacks = (state = {
  isFetching: false,
  didInvalidate: true,
}, action) => {
  switch (action.type) {
    case FEEDBACKS_SET_FILTER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case FEEDBACKS_SET_FILTER_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetching: false,
        didInvalidate: true,
        filter: action.filter,
        sorting: action.sorting,
      });
    case FEEDBACKS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case FEEDBACKS_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetching: false,
        updatedAt: moment(),
        didInvalidate: false,
        filter: action.filter,
        sorting: action.sorting,
      });
    case FEEDBACKS_NEXT_REQUEST:
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    case FEEDBACKS_NEXT_RECEIVE:
      return Object.assign({}, state, {
        ...state,
        page: action.data.page,
        results: [...state.results, ...action.data.results],
      });
    case FEEDBACK_UPDATE_RECEIVE:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
        [action.feedbackId]: action.data,
      });
    case FEEDBACKS_ACTION_REQUEST:
      return {
        ...state,
        isFetchingAction: true,
      };
    case FEEDBACKS_ACTION_RECEIVE:
      return {
        ...state,
        isFetchingAction: false,
      };
    default:
      return state;
  }
};

export { feedbacks };
