import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { trackValues } from 'lrtracker';

import { userSetFieldsFetch, userSetField } from 'store/user/actions';

import { closeModalComponent } from 'store/modals/actions';

import logout from 'utils/logout';
import localStorageHandler from 'utils/localStorageHandler';

import Modal from 'components/Modal';
import Icon from 'components/Icon';

import TermsOfUse from '../agreement/TermsOfUse';

import PoliciesAndConditions from './PoliciesAndConditions';
import AgreementBox from './AgreementBox';
import InfoModal from './InfoModal';

const bodyNoScrollClass = 'hide-scroll-agreement';

class AgreementInner extends Component {
  constructor(props) {
    super(props);

    this.state =
    {
      isWelcomeComplete: Boolean(localStorageHandler.get('isWelcomeComplete')) || false,
      isNarrowModal: false,
      showLogOutModal: false,
      iNonCenter: false,
      isModalView: false,
    };
    this.onNextClick = this.onNextClick.bind(this);
    this.toggleLogOutModal = this.toggleLogOutModal.bind(this);
    this.renderTermsOfUse = this.renderTermsOfUse.bind(this);
    this.setInfoModalOptions = this.setInfoModalOptions.bind(this);
  }

  componentDidMount() {
    document.body.classList.add(bodyNoScrollClass);
  }

  componentWillUnmount() {
    document.body.classList.remove(bodyNoScrollClass);
  }

  onNextClick() {
    const { showAgreement, showTermsOfUse } = this.props;

    this.setState({
      isWelcomeComplete: true,
      isNarrowModal: showAgreement,
      iNonCenter: showTermsOfUse,
      isModalView: false,
    });
  }

  setInfoModalOptions() {
    this.state.isNarrowModal = true;
    this.state.isModalView = true;
  }

  toggleLogOutModal() {
    const { showLogOutModal } = this.state;

    this.setState({ showLogOutModal: !showLogOutModal });
  }

  renderViewPolicies() {
    const { saveAgreement } = this.props;
    const { showLogOutModal } = this.state;
    this.state.isNarrowModal = true;
    return (
      <div>
        <PoliciesAndConditions />
        <div className="row vertical space-between mt20">
          <button
            onClick={() => {
              trackValues('termsAndConditions', 'FALSE', { context: {} });
              this.toggleLogOutModal();
            }}
            type="button"
            className="btn btn-grey btn-8-4"
          >Decline</button>
          <button
            onClick={() => {
              trackValues('termsAndConditions', 'TRUE', { context: {} });
              saveAgreement();
            }}
            type="button"
            className="btn btn-bright btn-8-4"
          >Accept</button>
        </div>
        {
            showLogOutModal && (
            <Modal className="ta-center" small>
              <div className="row flex-end mb8">
                <button
                  className="btn btn-empty"
                  type="button"
                  onClick={() => {
                    trackValues('termsAndConditions', 'cancel log out', { context: {} });
                    this.toggleLogOutModal();
                  }}
                >
                  <Icon className="svg-icon" iconName="close" />
                </button>
              </div>
              <div className="row on-top">
                <i className="modal-info-orange-icon mr5" />
                <h3 className="modal-info-orange-text ta-left">You can start working on the platform only after you accept the Terms & Conditions.</h3>
              </div>
              <div className="fsL ta-left line-height-1-5">Otherwise, you will be logged out. You will be able to read and accept the Terms & Conditions when you log in next time.If you have any questions, please contact us at <a href="mailto:support@liv-research.com">support@liv-research.com</a>.</div>
              <div className="row mt20">
                <button
                  onClick={() => {
                    trackValues('termsAndConditions', 'log out', { context: {} });
                    logout();
                  }}
                  type="button"
                  className="btn btn-bright btn-8-4"
                >Log out</button>
              </div>
            </Modal>
            )
        }
      </div>
    );
  }

  renderTermsOfUse() {
    const { saveTermsOfUse, profile } = this.props;
    this.state.iNonCenter = true;
    return (<TermsOfUse agreeTermsOfUse={saveTermsOfUse} isNewWriter profile={profile} />);
  }

  renderContent() {
    const {
      isNewWriter, showAgreement, showTermsOfUse, saveCloseAgreementWizard,
      deleteModalToState,
    } = this.props;
    const { isWelcomeComplete } = this.state;
    this.state.isNarrowModal = false;
    this.state.iNonCenter = false;
    this.state.isModalView = false;
    if (isNewWriter) {
      if (!isWelcomeComplete) {
        this.setInfoModalOptions();
        return (<InfoModal
          modalName="WelcomeModal"
          onNextClick={() => {
          deleteModalToState('WelcomeModal');
          localStorageHandler.set('isWelcomeComplete', true);
          this.onNextClick();
      }}
        />);
      }

      if (showAgreement) {
        return this.renderViewPolicies();
      }

      if (showTermsOfUse) {
        return this.renderTermsOfUse();
      }

      this.setInfoModalOptions();
      return (<InfoModal modalName="WellDoneModal" onNextClick={() => { deleteModalToState('WellDoneModal'); saveCloseAgreementWizard(); }} />);
    }
    if (!isWelcomeComplete) {
      this.setInfoModalOptions();
      return (<InfoModal modalName="WelcomeBackWriter" onNextClick={() => { deleteModalToState('WelcomeBackWriter'); this.onNextClick(); }} />);
    }

    if (showAgreement) {
      return this.renderViewPolicies();
    }

    if (showTermsOfUse) {
      return this.renderTermsOfUse();
    }

    this.setInfoModalOptions();
    return (<InfoModal modalName="ViewAwesome" onNextClick={saveCloseAgreementWizard} />);
  }

  render() {
    const { isNarrowModal, iNonCenter, isModalView } = this.state;
    if (isModalView) {
      return (
        <>
          {this.renderContent()}
        </>
      );
    }

    return (
      <AgreementBox containerRef={ref => this.containerRef = ref} narrow={isNarrowModal} nonCenter={iNonCenter} >
        {this.renderContent()}
      </AgreementBox>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.user,
  isNewWriter: moment(state.user.created_at).isAfter('2017-09-04'),
  showAgreement: !state.user.profile_tag_policies_agree,
  tagPol: state.user.profile_tag_policies_agree,
  tagTerm: state.user.profile_tag_terms_agree,
  showTermsOfUse: !state.user.profile_tag_terms_agree,
});

const mapDispatchToProps = dispatch => ({
  saveAgreement: () => {
    dispatch(userSetFieldsFetch({ profile_tag_policies_agree: true }));
  },
  saveTermsOfUse: () => {
    dispatch(userSetFieldsFetch({ profile_tag_terms_agree: true }));
  },
  saveCloseAgreementWizard: () => dispatch(userSetField('showAgreementWizard', false)),
  deleteModalToState: name => dispatch(closeModalComponent(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementInner);
