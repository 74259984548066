import { useState } from 'react';

/*
how to use

const { array, set, push, remove, filter, update, clear } = useArray([
    1, 2, 3, 4, 5, 6,
  ]);
  push(7);
  update(index, value);
  filter(n => n < 3);
*/

export default function useArray(defaultValue) {
  const [array, setArray] = useState(defaultValue);

  function push(element) {
    setArray(a => [...a, element]);
  }

  function filter(callback) {
    setArray(a => a.filter(callback));
  }

  function update(index, newElement) {
    setArray(a => [
      ...a.slice(0, index),
      newElement,
      ...a.slice(index + 1, a.length),
    ]);
  }

  function remove(index) {
    setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  }

  function clear() {
    setArray([]);
  }

  return {
    array, set: setArray, push, filter, update, remove, clear,
  };
}
