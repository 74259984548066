import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { withRouter } from 'hocs';

import { helpSetSearch, wsSubscribe, wsUnSubscribe } from 'store/help/actions';
import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';

import { ticketsFetchIfNeeded, ticketsSetFilter, getTicketsNextAction, ticketUpdateActionFetch } from 'store/tickets/actions';
import { addAlert } from 'store/alerts/actions';

import Icon from 'components/Icon';
import Emoji from 'components/Emoji';
import Modal from 'components/Modal';
import WriterTicketingIssue from 'components/WriterTicketing/WriterTicketingIssue';
import { StickyContainer, StickyHead, StickyBody } from 'components/Sticky';

import { issueResolutionTopic } from 'constants/wsTopics';
import { TICKET_STATUS_MAPPING, INITIAL_PARAMS_URL, SERVICE_REPORTS_TYPES } from 'components/WriterTicketing/constants/writerTicketing';

const HIDE_TYPE = [
  'Support team didn\'t follow the policies',
  'Support team misguided me',
  'Support team ignored me',
  'Support team was rude',
];

class WriterTicketingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenForm: false,
      isViewIssue: false,
      showNotice: false,
      hashViewIssueCall: true,
    };

    this.onOpenForm = this.onOpenForm.bind(this);
    this.onViewIssue = this.onViewIssue.bind(this);
    this.onShowNotice = this.onShowNotice.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.renderTicketsList = this.renderTicketsList.bind(this);
  }

  componentDidMount() {
    const { loadTickets, writerId, filter: issueResolutionFilter } = this.props;
    const { sorting } = INITIAL_PARAMS_URL;
    const filter = { ...INITIAL_PARAMS_URL.filter, parent_object_id: [writerId] };
    loadTickets(1, sorting, issueResolutionFilter || filter);
    this.props.wsSubscribe(issueResolutionTopic);
  }

  componentDidUpdate(prevProp, prevState) {
    const { hash = {}, isLoading = false, tickets = [] } = this.props;
    const { isViewIssue = false, hashViewIssueCall = true } = this.state;
    if (isLoading || Object.keys(hash).length === 0 || isViewIssue || prevState.isViewIssue) return;

    const { ticketId = '' } = hash;
    const isTicketOnPage = tickets.some(ticket => ticket.id === parseInt(ticketId, 0));

    if (isTicketOnPage && hashViewIssueCall) {
      this.onViewIssue(parseInt(ticketId, 0), false);
    }
  }

  componentWillUnmount() {
    this.props.wsUnSubscribe(issueResolutionTopic);
  }

  onOpenForm() {
    this.setState({
      isOpenForm: !this.state.isOpenForm,
    });
  }

  onViewIssue(ticketId, manualCall = true) {
    this.setState({
      isViewIssue: !this.state.isViewIssue,
      ticketId,
      hashViewIssueCall: false,
    });
    if (manualCall && ticketId) this.props.history.push(`#ticketId=${ticketId}`);
    else this.props.history.push('issues');
  }

  onShowNotice(message) {
    this.setState({
      textNotice: message,
      isViewIssue: false,
      showNotice: true,
    });
    setTimeout(() => {
      this.setState({
        showNotice: false,
        textNotice: '',
      });
    }, 7000);
  }

  renderNextButton() {
    const { total, tickets, loadNextTickets, sorting, filter, page } = this.props;
    if (!tickets.length) return null;

    if (!total || tickets.length === total) {
      return null;
    }

    const numPage = page + 1;
    const text = `Load next items (${tickets.length} / ${total})`;

    return (
      <button
        className="btn btn-show-next"
        onClick={() => { loadNextTickets(numPage, sorting, filter); }}
      >
        {text}
      </button>
    );
  }

  onCancel(e) {
    e.preventDefault();
    e.stopPropagation();
    const { updateTicket, addAlertSuccess } = this.props;
    const { ticket: ticketId } = e.target.dataset;
    const data = {
      aasm_state: 'canceled',
    };
    updateTicket(ticketId, data);
    addAlertSuccess(<p className="success-container">Your ticket is canceled.</p>);
  }

  renderTicketsList() {
    const { tickets = [] } = this.props;
    if (!tickets.length) return null;
    return (
      tickets.map((t) => {
        const orderNumber = t.info.order_info ? t.info.order_info.number : 'NA';
        let initState = t.aasm_state;

        if (initState === 'canceled' && (t.comment || t.description) && !SERVICE_REPORTS_TYPES.includes(t.subtype)) {
          initState = 'closed';
        }

        const status = TICKET_STATUS_MAPPING[initState];
        const isActive = initState === 'active' || initState === 'snoozed';
        const className = classNames('table-row row vertical', { 'active-row': isActive });
        
        if (HIDE_TYPE.indexOf(t.info.issue_subtype) > -1) {
          return false;
        }

        return (
          <div key={t.id} className={className} onClick={() => this.onViewIssue(t.id)}>
            <div className="col-2">{formatTime(t.created_at, 'd')}</div>
            <div className="col-2">{orderNumber}</div>
            <div className="col-4">{t.info.issue_subtype}</div>
            <div className="col-2"><b className={`status status-${status.toLowerCase()}`}>{status}</b></div>
            <div className="col-1">
              {t.aasm_state.toLowerCase() === 'active' && !SERVICE_REPORTS_TYPES.includes(t.subtype) &&
                <a data-ticket={t.id} onClick={this.onCancel}>Cancel request</a>
              }
            </div>
            {isActive &&
              <div className="col-1 row vertical">
                <Icon className="svg-icon empty-clock" iconName="empty-clock" /><span className="date-closed-at">by {formatTime(t.deadline, 'd')}</span>
              </div>
            }
            {!isActive &&
              <div className="col-1 row vertical">
                <span className="date-closed-at">{formatTime(t.updated_at, 'd')}</span>
              </div>
            }
          </div>
        );
      })
    );
  }

  render() {
    const { isOpenForm, isViewIssue, ticketId, showNotice, textNotice } = this.state;
    const { tickets, isLoading } = this.props;

    return (
      <div className="container-ticketing">

        {isOpenForm &&
          <Modal onClose={this.onOpenForm} wide notHandleOutside>
            <WriterTicketingIssue onClose={this.onOpenForm} onShowNotice={this.onShowNotice} />
          </Modal>
        }

        {isViewIssue &&
          <Modal onClose={this.onViewIssue} wide notHandleOutside>
            <WriterTicketingIssue callFromOrderContent onClose={this.onViewIssue} ticketId={ticketId} onShowNotice={this.onShowNotice} />
          </Modal>
        }

        {showNotice &&
          <div className="ticketing-success-wrap">
            <p className="ticketing-success">
              <Icon className="svg-icon success" iconName="check-fill" />
              {textNotice}
            </p>
          </div>
        }

        <div className="row help-container ticketing_info">
          <section className="padding-content">
            <div className="title-wrap">
              <h1 className="title">Issue resolution</h1>
              <button onClick={this.onOpenForm} className="btn btn-bright">+ Report an issue</button>
            </div>
            <p>Welcome to the issue resolution page!</p>
            <p>We always strive to hear each and every concern/problem our writers might have, as well as to make our platform a better and more convenient place to work at! </p>
            <p>Here we encourage you to let us know about any issue you might face with. This can be anything from missing files or unclear order instructions to system glitches that resulted in unfair fines or incorrect bonuses. </p>
            <p>The time it takes to review your case depends on the LR team responsible.</p>
            <ul className="list_dark">
              <li>Support Team works 24/7, so proccessing requests usually takes them up to 30 minutes, but can take more depending on various outside factors and/or hectic team workload.</li>
              <li>Care Team works Mon-Fri, and processing requests usually takes them up to 5 days, but we'll do our best to get back to you ASAP!</li>
              <li>QA Managers work on a shift-based schedule. Processing requests usually takes them up to 7 days.</li>
            </ul>


            <p>Once the issue is resolved, we will let you know about it by sending a system notification. Also, this page allows you to keep track of the progress, and stores a full history of your requests.</p>

            <div className="table orders-table">
              <StickyContainer className="sticky-table">
                <StickyHead className="sticky-table-head" classNameFixed="sticky-table-head-fixed">
                  <div className="table-header">
                    <div className="row">
                      <div className="col-2">Create at</div>
                      <div className="col-2">Order number</div>
                      <div className="col-4">Issue name</div>
                      <div className="col-2">Status</div>
                      <div className="col-1" />
                      <div className="col-1">Closed at</div>
                    </div>
                  </div>
                </StickyHead>
                <StickyBody className="sticky-table-body">
                  {!!tickets.length &&
                    this.renderTicketsList()
                  }
                  {!tickets.length && !isLoading &&
                    <div className="page-not-result">
                      <Emoji id="crySad" />
                      <h1>{'Oops, we don\'t have tickets matching this filter set.'}</h1>
                      <p className="notes">Please try again with other filter configuration.</p>
                    </div>
                  }
                </StickyBody>
              </StickyContainer>
            </div>
            {this.renderNextButton()}
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const isLoading = state.tickets.isFetching;
  const tickets = state.tickets.tickets || [];
  const total = state.tickets.total || 0;
  const page = parseInt(state.tickets.page || 1);
  const filter = state.tickets.filter || null;
  const sorting = state.tickets.sorting || null;
  const { ticket } = state;
  const profile = state.user;
  const writerId = profile._id;
  const isFetchingUpdate = ticket.isFetchingUpdate || false;
  const hash = queryString.parse(ownProps.location.hash) || null;

  return {
    profile,
    isLoading,
    tickets,
    writerId,
    isFetchingUpdate,
    total,
    filter,
    sorting,
    hash,
    page,
  };
};

const mapDispatchToProps = dispatch => ({
  addAlertSuccess: text => dispatch(addAlert(text)),
  clearSearch: () => dispatch(helpSetSearch('')),
  loadNextTickets: (page, sorting, filter) => dispatch(getTicketsNextAction(page, sorting, filter)),
  loadTickets: (page, sorting, filter) => dispatch(ticketsFetchIfNeeded(page, sorting, filter)),
  setFilter: (page, sorting, filter) => dispatch(ticketsSetFilter(page, sorting, filter)),
  wsSubscribe: payload => dispatch(wsSubscribe(payload)),
  wsUnSubscribe: payload => dispatch(wsUnSubscribe(payload)),
  updateTicket: (ticketId, data) => dispatch(ticketUpdateActionFetch(ticketId, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WriterTicketingPage));
