import React, { useCallback, useState, useRef, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import debounce from 'debounce';
import { v4 as uuidv4 } from 'uuid';

import { usePrevious } from 'hooks';

import classNames from 'utils/classNames';
import containsChildElement from 'utils/containsChildElement';

import MarkableText from 'components/MarkableText';
import Icon from 'components/Icon';

import './index.scss';

const InputWithSearch = ({
  className, onSearch = () => {}, inputPlaceholder, inputOnFocusPlaceholder, onInputChange, inputValue,
  onClearSearch, searchValues = [], filterFunction, renderListValues, maxItems = +Infinity,
  isLoading, onSelectHandler, extraItem = null, toggleReset, isDisabled, needSearch = true,
  isError, isErrorOnlyBorder, type = 'text',
}) => {
  const [__inputValue, setInputValue] = useState('');
  const [filteredSearchValues, setFilteredSearchValues] = useState([...searchValues]);
  const [showSearchValue, toggleSearchValue] = useState(false);
  const [withSearch, toggleWithSearch] = useState(false);
  const [showKeepTyping, toggleShowKeepTyping] = useState(false);

  const prevSearchValues = usePrevious(searchValues);

  const inputRef = useRef();
  const containerRef = useRef();

  const onSearchDebounce = useCallback(debounce((value) => {
    if (!value) {
      setFilteredSearchValues([...searchValues]);
      return;
    }

    setFilteredSearchValues(filterFunction ? filterFunction(value, searchValues) : searchValues.filter(sV => sV.includes(value)));
    toggleWithSearch(true);
  }, 200), [searchValues]);


  const onInputChangeInner = (e) => {
    if (isLoading) return;

    const { value } = e.target;

    const trimValue = value.trim();
    const trimStart = value.trimStart();
    setInputValue(trimStart);
    onInputChange && onInputChange(e, trimValue);

    if (trimStart.length > 0 && trimStart.length === 1) {
      toggleShowKeepTyping(true);
      toggleWithSearch(false);
      return;
    }

    toggleShowKeepTyping(false);
    onSearchDebounce(trimValue);
  };

  const onBlur = (e) => {
    if (containsChildElement(containerRef.current, e.target)) return;

    inputRef.current.placeholder = inputPlaceholder;
    toggleSearchValue(false);
  };

  const onSelect = (value, e) => {
    setInputValue(value);
    onBlur({ target: null });

    onSelectHandler && onSelectHandler(value, e);
  };

  const onClearSearchInner = () => {
    setFilteredSearchValues([...searchValues]);
    toggleShowKeepTyping(false);
    toggleWithSearch(false);
    onSelect('');
    onSelectHandler && onSelectHandler(null);
    onClearSearch && onClearSearch(null);
    setTimeout(() => {
      inputRef.current.focus();
    }, 10);
  };

  const onFocus = () => {
    if (!searchValues.length && !isLoading) return;

    inputRef.current.placeholder = inputOnFocusPlaceholder || 'Search by order number or title ';
    toggleSearchValue(true);

    if (extraItem && (extraItem === __inputValue)) return;

    if (!__inputValue) return;

    onSearchDebounce(__inputValue);
  };

  const renderValuesList = () => {
    if (isLoading) {
      return (<div className="input-search-container-search-container__info">Loading ...</div>);
    }

    if (showKeepTyping) {
      return (<div className="input-search-container-search-container__info">Keep typing to get search results.</div>);
    }

    let listOfElements = renderListValues ? renderListValues(filteredSearchValues.slice(0, maxItems), 'input-search-container-search-container__item', onSelect, withSearch) : filteredSearchValues.slice(0, maxItems).map(filteredSearchValue => <li className="input-search-container-search-container__item" key={filteredSearchValue} onClick={() => onSelect(filteredSearchValue)}>{filteredSearchValue}</li>);

    if (withSearch) {
      listOfElements = listOfElements.map(listElem => <MarkableText onClick={(listElem.props || {}).onClick} key={uuidv4()} options={{ className: 'input-search-container-search-container__mark' }} html={ReactDOMServer.renderToString(listElem)} mark={__inputValue} />);
    }

    if (listOfElements.length === 0) {
      return (<div className="input-search-container-search-container__info">No orders match your search.</div>);
    }

    return listOfElements;
  };

  useEffect(() => {
    if (prevSearchValues && prevSearchValues.length !== searchValues.length) {
      setFilteredSearchValues([...searchValues]);
    }
  }, [searchValues, prevSearchValues]);

  useEffect(() => {
    if (showSearchValue) {
      window.root.addEventListener('click', onBlur);
    } else {
      window.root.removeEventListener('click', onBlur);
    }

    return () => {
      window.root.removeEventListener('click', onBlur);
    };
  }, [showSearchValue]);

  useEffect(() => {
    if (toggleReset === undefined) return;

    setInputValue('');
    setFilteredSearchValues([...searchValues]);
    toggleShowKeepTyping(false);
    toggleWithSearch(false);
  }, [toggleReset]);

  useEffect(() => {
    if (!inputValue) return;

    if (inputValue !== __inputValue && isDisabled) {
      setInputValue(inputValue);
    }
  }, [inputValue, __inputValue, isDisabled]);

  return (
    <div
      className={classNames(
      'input-search-container',
      { 'input-search-container--disabled': isDisabled },
      { 'input-search-container--no-search': !needSearch },
      (Array.isArray(className) ? className.join(' ') : className),
      )}
      ref={containerRef}
    >
      {!isDisabled && needSearch && <Icon className="svg-icon" iconName="search" onClick={onSearch} />}
      <input
        className={classNames(
          'input-text',
          'input-search-container__input',
          { 'is-error': isError },
          { 'is-error-only-border': isErrorOnlyBorder },
        )}
        type={type}
        placeholder={inputPlaceholder}
        onChange={onInputChangeInner}
        value={__inputValue}
        name="search"
        onFocus={onFocus}
        ref={inputRef}
        autoComplete="off"
      />
      <button className={classNames('btn btn-close', { 'btn-close--show': __inputValue && !isDisabled })} type="button" onClick={onClearSearchInner}>
        <Icon className="svg-icon" iconName="close" />
      </button>
      {showSearchValue && (
        <ul className="input-search-container-search-container">
          {renderValuesList()}
        </ul>
      )}
    </div>
  );
};

export default InputWithSearch;
