import React from 'react';
import ClassNames from './../utils/classNames';

const Tooltip = (props) => {
  const { children, content, className, isHtml = false, notShow = false } = props;
  const contentClassNames = ClassNames('tooltip-content', {
    'position-down': props.positionDown,
    'right-position': props.positionRight,
    'left-position': props.positionLeft,
    'with-wrap-content': props.withWrapContent,
    'with-list': props.withList,
    'w-270': props.mW270,
    'no-arrow': props.noArrow,
    'white-with-grey-text': props.whiteWithGreyText,
    [className]: className,
    'light_tooltip': props.light,
  });

  if (notShow) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <div className="tooltip-container">
      <span className="tooltip-trigger">
        {children}
      </span>
      {!!content && !isHtml &&
        <span className={contentClassNames}>
          {content}
        </span>
      }
      {
        !!content && isHtml &&
          <span className={contentClassNames} dangerouslySetInnerHTML={{ __html: content }} />
      }
    </div>
  );
};

export default Tooltip;
