import { ADD_TOASTED_NOTIFICATIONS, removeToastNotifications } from 'store/ordersNotifications/actions';
import { getToastOrderNotifications } from 'store/ordersNotifications/selectors';
import { addToast } from 'store/ordersNotifications/helpers';

import { STATUS_OPEN } from 'store/orders/actions';

import { getStatus } from 'components/order/utils';

import changeFavicon from 'utils/changeFavicon';
import triggerWebNotification from 'utils/triggerWebNotification';
import callNoticesSound from 'utils/callNoticesSound';


export const CLIENT_CHAT_NOTICES_RECEIVE = 'CLIENT_CHAT_NOTICES_RECEIVE';

const renderBrowserNotification = (node, orderId) => {
  const { lastMessage = {}, number } = node || {};

  const {
    type = '', message = '', files = [], event = {},
  } = lastMessage;

  if (type === 'event' && event.event_name === 'crm:live_session_upcoming') {
    return {
      title: `Live session in ${number} starts in 10 minutes”`,
      text: 'Please, join the session by clicking on this message.',
      link: (event.event_params || {}).link,
    };
  }

  const title = `Message from Client in ${number}`;
  const link = `/order/${orderId}`;
  let text = '';

  if (type === 'message') {
    text = `${message.substr(0, 88)}`;
  }

  if (type === 'files') {
    text = files.reduce((prev, next) => {
      const { name } = next;
      prev = `${name};\n${prev}`;
      return prev;
    }, '');
  }

  if (type === 'audio') {
    text = 'Audio message';
  }

  return {
    title,
    link,
    text,
  };
};

const noticesReceive = data => ({
  type: CLIENT_CHAT_NOTICES_RECEIVE,
  data,
});

export const subscribeChatNotifications = (data = {}) => (dispatch, getState) => {
  const { hasNotification, nodes = [] } = data;
  const { href } = window.location;

  dispatch(noticesReceive(data));

  const isFindOrdersPage = href.includes('findorders');
  const isMyOrdersPage = href.includes('myorders');

  if (!hasNotification) {
    document.title = window.page_title;
    changeFavicon('/favicons/favicon.ico');
    return;
  }

  document.title = 'You have a new message';
  callNoticesSound();
  changeFavicon('/favicons/favicon_new_msg.ico');
  nodes.forEach((node) => {
    const { number, count } = node;
    const order = ((getState().orders || {})[number]) || {};
    const notifications = getToastOrderNotifications(getState());
    const { _id: orderId } = order;

    if (!orderId) return;

    if (notifications.includes(number) && count === 0) {
      dispatch(removeToastNotifications(number));
    }

    if (count > 0) {
      triggerWebNotification(renderBrowserNotification(node, orderId));
    }

    if (count === 0 || href.includes(orderId) || href.includes(number)) return;

    if (isFindOrdersPage && getStatus(order) === STATUS_OPEN) return;

    if (isMyOrdersPage && getStatus(order) !== STATUS_OPEN) return;

    addToast(order, getState(), true);
    dispatch({
      type: ADD_TOASTED_NOTIFICATIONS,
      payload: [number],
    });
  });
};
