import React from 'react';

import { CroppieImage } from '../Controls';

import Overview from '../Overview';

const AvatarOverview = ({ isStem, data, onChangeFields }) => {
  const { public_photo, gender, public_overview } = data;

  return (
    <div className="row person-common-info">
      <div className="col-2 person-common-info-title">
        <CroppieImage
          onChangeFields={!isStem && onChangeFields}
          value={public_photo}
          fieldName="public_photo"
          gender={gender}
        />
      </div>
      <div className="col-4">
        <Overview
          onChangeFields={!isStem && onChangeFields}
          value={public_overview}
          fieldName="public_overview"
          title="Overview"
        />
      </div>
    </div>
  );
};

export default AvatarOverview;
