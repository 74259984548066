import React from 'react';

import Select from 'components/Select';
import Tooltip from 'components/Tooltip';
import Dropdown from 'components/Dropdown';

import { REPORT_SUB_TYPE } from './constants/writerTicketing';
import { DISABLED_TYPE_ISSUE } from './constants/common';

const DropdownTypeIssue = ({ typeIssue, onChangeType, issueTypeList, isDisabled = false, hasProgressOrder = false, selectedReason, changeReason }) => {

  const checkDisabledItem = (item) => {
    let tooltipContent = '';
    let isDisabledItem = false;
    switch (item) {
      case REPORT_SUB_TYPE.I_WANT_TO_DELETE_MY_ACCOUNT:
      case REPORT_SUB_TYPE.I_WANT_TO_FREEZE_MY_ACCOUNT:
        if (hasProgressOrder) {
          tooltipContent = '<b>Note:</b> To report this issue you need to complete all your orders in progress.';
          isDisabledItem = true;
        }
        break;
      default:
        break;
    }

    return { tooltipContent, isDisabledItem };
  };

  return (
    <>
      <h3 className="filter-name">Specify issue type</h3>
      <Dropdown text={typeIssue || '— Select one —'} closeAlways disabled={isDisabled}>
        <Select className="menu menu-selectable" value={typeIssue} onChange={onChangeType}>
          {issueTypeList.map((item) => {
            const hasDisabledItem = (DISABLED_TYPE_ISSUE || []).includes(item);
            if (hasDisabledItem) {
              const { tooltipContent, isDisabledItem } = checkDisabledItem(item);
              if (isDisabledItem) {
                return (
                  <li key={item} value={item} disabled className="row vertical tooltip_issue_subtype">
                    <span className="menu-item disabled">
                      {item}
                    </span>
                    <Tooltip content={tooltipContent} positionDown positionRight isHtml>
                      <i className="icon_info" />
                    </Tooltip>
                  </li>
                );
              }
            }
            return (<li key={item} value={item} className="menu-item">{item}</li>)})
          }
        </Select>
      </Dropdown>
      {selectedReason &&
        <p className="filter-name">
          <span>Reason: </span> <a onClick={changeReason}>{selectedReason}</a>
        </p>
      }
    </>
  );
};

export default DropdownTypeIssue;
