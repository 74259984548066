import React from 'react';

import FinancesTable from './../components/FinancesTable';

const FinancesPage = () => (
  <div className="container mt20">
    <FinancesTable />
  </div>
);

export default FinancesPage;
