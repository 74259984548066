
import React, { Component } from 'react';
import classnames from './../../utils/classNames';

const fieldName = 'public_overview';
// const CNT_SYMBOLS = 200;

class Overview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: false,
      cntSymbols: props.cntSymbols || 200,
      allCntSymbols: props.cntSymbols || 200,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const { value } = this.props;
      this.setCountSymbols(value);
    }
  }

  onChange(event) {
    const inputValue = (event.target.value).slice(0, this.state.allCntSymbols);
    const { onChangeFields } = this.props;
    this.setCountSymbols(inputValue);
    onChangeFields(this.props.fieldName || fieldName, inputValue);
  }

  setCountSymbols(value = '') {
    const cntSymbols = this.state.allCntSymbols - (value.length || 0);
    this.setState({
      cntSymbols: cntSymbols > 0 ? cntSymbols : 0,
      isCompleted: cntSymbols <= 0 || false,
    });
  }

  render() {
    const { isCompleted, cntSymbols } = this.state;
    const { value, onChangeFields, title, placeholder, disabled, className, hideCount } = this.props;
    const errorClassName = !value ? 'text-danger' : null;
    const text = value;
    const placeholderText = placeholder || 'E.g. I am an experienced freelancer with an MA in English Literature. I can help with any Human science academic tasks. My analytical mind, attentiveness and precision ensure excellent quality and timely delivery of my work.';
    const textareaClassName = classnames('textarea', className, { 'is-completed': isCompleted });

    return (
      <div>
        <div className="row space-between vertical">
          {title &&
            <span className={errorClassName}>Overview</span>
          }
          {!!onChangeFields && !hideCount &&
            <span className={classnames('cnt-symbols', { 'text-danger': isCompleted })}>{cntSymbols}</span>
          }
        </div>
        {!onChangeFields ?
          <span className=" textarea overview-text">{text}</span> :
          <textarea disabled={disabled} className={textareaClassName} placeholder={placeholderText} value={text} onChange={this.onChange}>{text}</textarea>
        }
      </div>
    );
  }
}
export default Overview;
