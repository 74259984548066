
import React from 'react';
import alarm from 'img/icons/alarm.svg';

const FilesWarning = ({ onClick }) => (
  <div className="modal-sm ta-center">
    <img className="modal-icon" src={alarm} alt="" />
    <p className="title mb30">You can upload only two file. Max 50m per file.</p>
    <button type="button" className="btn btn-bright btn-big" onClick={onClick}>Got it</button>
  </div>
);

export default FilesWarning;
