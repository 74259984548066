export const isNewbie = group => group.indexOf('new') > -1;

export const isBeginner = group => group.indexOf('beg') > -1;

const groups = [
  'A-pro',
  'A-standard',
  'A-outsider',
  'B-pro',
  'B-standard',
  'B-outsider',
  'C-standard',
  'C-outsider',
  'D-standard',
  'D-outsider',
  'F',
];

export const isGroup = group => groups.includes(group);

export const getGroupName = (group = '') => {
  if (isBeginner(group)) return 'Beginners';

  if (isNewbie(group)) return 'Newbies';

  if (group.includes('A')) return 'A+/A-group';

  if (group.includes('B')) return 'B-group';

  if (group.includes('C')) return 'C-group';

  return 'Beginners';
};
