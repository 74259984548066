import getRandomIntInclusive from 'utils/getRandomIntInclusive';

const templates = [
  [// 0
    'Hello, my name is [TutorName]! I will do my best to help you with your task. :) \n',
    'Please give me a couple of minutes to check all the provided instructions, ',
    'and I will get back to you if I have any questions left.',
  ],
  [// 1
    'Hello there! My name is [TutorName] and I’m interested in becoming your tutor. :) \n',
    'I will carefully check the instructions you provided, and get back to you ASAP.',
  ],
  [// 2
    'Hi there! \n',
    'I’m [TutorName], and I’m here to help you out with your assignment. ',
    'Please allow me 5-10 minutes to check the task details, and I will get back to you.',
  ],
  [// 3
    'Hello! My name is [TutorName], nice to meet you. :) \n',
    'Please allow me a couple of mins to carefully read the instructions, and I will ',
    'get back to discuss any additional clarifications that may be needed to help you out.',
  ],
  [// 4
    'Hi there, my name is [TutorName] \n',
    'Looking forward to being your tutor! Please give me a couple of mins to check all ',
    'the task details you provided, and I will get back to you. :)',
  ],
  [// 5
    'Nice to meet you! \n',
    'My name is [TutorName], and I’ll do my best to help you with your assignment. \n',
    'I will carefully check the task instruction you provided, and get back in a couple ',
    'of minutes to discuss any further clarification that may be needed.',
  ],
  [// 6
    'I have checked your task instructions. \n',
    'Could you please provide me the following information: \n ...',
  ],
  [// 7
    'By any chance, have you made a decision on what kind ',
    'of help do you need with this task? For example, it ',
    'written explanations, step by step solution or just the ',
    'file with completed task.',
  ],
  [// 8
    'Would you like to add a tutoring session to your order, ',
    'so we can go over the completed task together?',
  ],
  [// 9
    'Do you have a preferable time to conduct a tutoring session?',
  ],
];

export default (index = undefined) => templates[index || getRandomIntInclusive(0, 5)].join('');
