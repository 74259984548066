import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dropdown from 'components/Dropdown';

import { userActionFetch } from 'store/user/actions';

const STATUSES = {
  Unverified: 'Unverified',
  Pending: 'Pending',
  Verified: 'Verified',
};

class PhoneVerificationDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      codeError: false,
    };

    this.codeSent = false;

    this.onChange = this.onChange.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onRequest = this.onRequest.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      if (this.codeSent && this.props.status !== STATUSES.Verified) {
        this.setState({ codeError: true });
      }
    }
  }

  onSubmit() {
    const { code } = this.state;
    const { submit } = this.props;
    if (!code) {
      return;
    }
    this.codeSent = true;
    this.setState({ codeError: false });
    submit(code);
  }

  onRequest() {
    const { verify } = this.props;
    this.codeSent = false;
    this.setState({ codeError: false, code: '' });
    verify();
  }

  onChange(event) {
    const code = event.target.value;
    this.codeSent = false;
    this.setState({ code, codeError: false });
  }

  onButtonClick() {
    const { status, isLoading } = this.props;

    if (!isLoading && status === STATUSES.Unverified) {
      this.onRequest();
    }

    return true;
  }

  render() {
    const { code, codeError } = this.state;
    const {
      status, isLoading, phone, isEditable,
    } = this.props;

    if (status === STATUSES.Verified || !isEditable) {
      return null;
    }

    return (
      <Dropdown
        buttonClassName="btn btn-bright btn-small"
        text="Verify"
        onButtonClick={this.onButtonClick}
        hideArrow
      >
        <div>
          Enter code which was sent to number <b>{phone}</b>
        </div>
        <br />
        {!codeError && <br />}
        {!!codeError &&
        <div className="ta-center">Invalid code</div>
                }
        <div>
          <input
            className="input-text"
            type="text"
            maxLength="100"
            onChange={this.onChange}
            autoFocus
            value={code}
            placeholder="Confirmation code"
            disabled={isLoading}
          />
        </div>
        <br />
        <button
          type="button"
          className="btn btn-block btn-bright ta-center"
          disabled={!code || isLoading}
          onClick={this.onSubmit}
        >Submit code</button>
        <br />
        <button
          type="button"
          className="btn btn-block btn-bright-success ta-center"
          disabled={isLoading}
          onClick={this.onRequest}
        >Re-send code</button>
      </Dropdown>
    );
  }
}

const mapStateToProps = state => ({
  phone: state.user.phone,
  status: state.user.phone_number_status,
  isLoading: state.user.isFetchingAction,
});

const mapDispatchToProps = dispatch => ({
  verify: () => dispatch(userActionFetch({ action: 'phone_verification_send' })),
  submit: code => dispatch(userActionFetch({ action: 'phone_verification_submit', phone_verification_code: code })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerificationDropdown);
