import shortid from 'shortid';
import { urls } from './../../config';

import authFetch from './../../utils/authFetch';
import uploadFetchAmazon from './../../utils/uploadFetchAmazon';
import { messageConvertToTicketFetch } from './../orders/actions';
import uploadUtils from './../../utils/uploadFetch';
import moment from 'moment-timezone';
import { INITIAL_PARAMS_URL } from 'components/WriterTicketing/constants/writerTicketing';
import utils from '../../utils';

export const TICKETS_REQUEST = 'TICKETS_REQUEST';
export const TICKETS_RECEIVE = 'TICKETS_RECEIVE';
export const TICKETS_ACTION_REQUEST = 'TICKETS_ACTION_REQUEST';
export const TICKETS_ACTION_RECEIVE = 'TICKETS_ACTION_RECEIVE';
export const TICKETS_SET_FILTER_REQUEST = 'TICKETS_SET_FILTER_REQUEST';
export const TICKETS_SET_FILTER_RECEIVE = 'TICKETS_SET_FILTER_RECEIVE';

export const TICKET_UPDATE_REQUEST = 'TICKET_UPDATE_REQUEST';
export const TICKET_UPDATE_RECEIVE = 'TICKET_UPDATE_RECEIVE';

export const TICKETS_NEXT_RECEIVE = 'TICKETS_NEXT_RECEIVE';
export const TICKETS_NEXT_REQUEST = 'TICKETS_NEXT_REQUEST';

export const TICKETS_FILES_REQUEST = 'TICKETS_FILES_REQUEST';
export const TICKETS_FILES_RECEIVE = 'TICKETS_FILES_RECEIVE';
export const TICKETS_FILES_UPLOAD_REQUEST = 'TICKETS_FILES_UPLOAD_REQUEST';
export const TICKETS_FILES_UPLOAD_PROGRESS = 'TICKETS_FILES_UPLOAD_PROGRESS';
export const TICKETS_FILES_UPLOAD_COMPLETE = 'TICKETS_FILES_UPLOAD_COMPLETE';
export const TICKETS_FILES_UPLOAD_RECEIVE = 'TICKETS_FILES_UPLOAD_RECEIVE';
export const TICKETS_FILES_UPLOAD_ERROR = 'TICKETS_FILES_UPLOAD_ERROR';
export const TICKETS_FILES_REMOVE_REQUEST = 'TICKETS_FILES_REMOVE_REQUEST';
export const TICKETS_FILES_REMOVE_RECEIVE = 'TICKETS_FILES_REMOVE_RECEIVE';
export const TICKET_UPDATE = 'TICKET_UPDATE';

// ==== load tickets ====
const ticketsRequest = () => ({
  type: TICKETS_REQUEST,
});

const ticketsReceive = (data, sorting, filter) => ({
  type: TICKETS_RECEIVE,
  data,
  filter,
  sorting,
});

const ticketsFetch = (page, sorting, filter) => {
  return (dispatch, getState) => {
		const { user } = getState();
    const isDe = user && user.profile_type === 'D';
    const params = utils.getTicketsQueryString(page, sorting, filter, isDe);
    dispatch(ticketsRequest());
    return authFetch(urls.tickets(params)).then((json) => {
      dispatch(ticketsReceive(json, sorting, filter));
    });
  };
};

const ticketsShouldFetch = (state) => {
  const { tickets } = state;
  if (tickets.isFetching) {
    return false;
  }
  return tickets.didInvalidate;
};

export const ticketsFetchIfNeeded = (page, sorting, filter) => {
  return (dispatch, getState) => {
    if (ticketsShouldFetch(getState())) {
      return dispatch(ticketsFetch(page, sorting, filter));
    }
  };
};

const ticketsSetFilterRequest = () => ({
  type: TICKETS_SET_FILTER_REQUEST,
});

const ticketsSetFilterReceive = (data, sorting, filter) => ({
  type: TICKETS_SET_FILTER_RECEIVE,
  data,
  filter,
  sorting,
});
// ==== ./load tickets ====


export const ticketsSetFilter = (page, sorting, filter) => {
  const params = utils.getTicketsQueryString(page, sorting, filter);
  return (dispatch) => {
    dispatch(ticketsSetFilterRequest());
    // dispatch(ticketsRequest());
    return authFetch(urls.tickets(params)).then((json) => {
      // dispatch(ticketsReceive(json, sorting, filter));
      dispatch(ticketsSetFilterReceive(json, sorting, filter));
    });
  };
};

// ==== load next tickets ====
const ticketsNextReceive = data => ({
  type: TICKETS_NEXT_RECEIVE,
  data,
});

const ticketsNextRequest = data => ({
  type: TICKETS_NEXT_REQUEST,
  data,
});

const ticketsNextFetch = (page, sorting, filter) => {
  const params = utils.getTicketsQueryString(page, sorting, filter);
  return (dispatch) => {
    return authFetch(urls.tickets(params)).then((json) => {
      dispatch(ticketsNextReceive(json));
    });
  };
};

export const getTicketsNextAction = (page, sorting, filter) => {
  return (dispatch) => {
    dispatch(ticketsNextRequest());
    return dispatch(ticketsNextFetch(page, sorting, filter));
  };
};
// ==== ./load next tickets ====

const ticketsActionRequest = () => ({
  type: TICKETS_ACTION_REQUEST,
});

const ticketsActionReceive = data => ({
  type: TICKETS_ACTION_RECEIVE,
  data,
});

export const ticketsActionFetch = (dataTicket, isConvertMessage) => {
  return (dispatch) => {
    dispatch(ticketsActionRequest());
    return authFetch(urls.ticket, {
      method: 'POST',
      body: JSON.stringify(dataTicket),
    }).then((data) => {
      dispatch(ticketsActionReceive(data));
      if (isConvertMessage) {
        const number = (data.info.order_info && data.info.order_info.number) || null;
        const ticketId = data.id;
        const messageId = data.info.message_id;
        dispatch(messageConvertToTicketFetch(number, messageId, ticketId));
      }
      const writerId = data.parent_object_id;
      const { sorting } = INITIAL_PARAMS_URL;
      const filter = { ...INITIAL_PARAMS_URL.filter, parent_object_id: [writerId] };
      dispatch(ticketsFetch(1, sorting, filter));
      Promise.resolve();
    })
      .catch(e => Promise.reject(e));
  };
};


// ==== for upload files ====
export const ticketsFilesRemoveRequest = fileId => ({
  type: TICKETS_FILES_REMOVE_REQUEST,
  fileId,
});

const ticketsFilesUploadRequest = (fileId, fileName, group, abort) => ({
  type: TICKETS_FILES_UPLOAD_REQUEST,
  fileId,
  fileName,
  group,
  abort,
});

const ticketsFilesUploadProgress = (fileId, progress) => ({
  type: TICKETS_FILES_UPLOAD_PROGRESS,
  fileId,
  progress,
});

const ticketsFilesUploadComplete = fileId => ({
  type: TICKETS_FILES_UPLOAD_COMPLETE,
  fileId,
});

export const ticketsFilesUploadReceive = (fileId, data) => {
  return ({
    type: TICKETS_FILES_UPLOAD_RECEIVE,
    fileId,
    data,
  })
};

const ticketsFilesUploadError = fileId => ({
  type: TICKETS_FILES_UPLOAD_ERROR,
  fileId,
});

export const ticketFilesUploadFetch = (file, group, writerId) => (dispatch, getState) => {
  const shortId = shortid.generate();
  const fileId = `wt502fy0/tickets/${shortId}/${uploadUtils.recursiveDecodeURIComponent(file.name)}`;
  const xhr = new XMLHttpRequest();
  dispatch(ticketsFilesUploadRequest(fileId, file.name, group, () => xhr.abort()));
  return uploadFetchAmazon(fileId, file, percent => dispatch(ticketsFilesUploadProgress(fileId, percent)), xhr).then((fileUrl) => {
    dispatch(ticketsFilesUploadComplete(fileId));

    let type = '';

    switch (group) {
      case 'message':
        type = 'msgs_ticket';
      case 'file_ticket':
        type = group;
        break;
      default:
        type = 'file_ticket';
        break;
    }

    return ([{
      created_at: moment().utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
      owner_role: 'writer',
      name: file.name,
      file: file.name,
      location: fileUrl,
      size: file.size,
      type,
      _id: shortId,
      owner: writerId,
    }]);
  }).then((itemFile) => {
    dispatch(ticketsFilesUploadReceive(fileId, itemFile));
  }).catch((error) => {
    console.log(error);
    dispatch(ticketsFilesUploadError(fileId));
  });
};
// ==== ./ for upload files =======


// ==== ticket update ====
const ticketUpdateRequest = data => ({
  type: TICKET_UPDATE_REQUEST,
  data,
});

export const ticketUpdate = data => ({
  type: TICKET_UPDATE,
  data: data.ticket,
});

export const ticketUpdateReceive = data => ({
  type: TICKET_UPDATE_RECEIVE,
  data,
  ticketId: data.id,
});

export const ticketUpdateActionFetch = (ticketId, data) => {
  return (dispatch) => {
    dispatch(ticketUpdateRequest());
    return authFetch(urls.updateTicket(ticketId), {
      method: 'PATCH',
      body: JSON.stringify(data),
    }).then(data => dispatch(ticketUpdateReceive(data)));
  };
};
// ==== ./ticket update ====
