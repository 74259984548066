export const ADD_VERIFY_MODAL = 'ADD_VERIFY_MODAL';
export const CLOSE_VERIFY_MODAL = 'CLOSE_VERIFY_MODAL';

export const addVerifyModal = (data = {}) => ({
  type: ADD_VERIFY_MODAL,
  verifyStatusHandler: data.verifyStatusHandler,
});

export const closeVerifyModal = () => ({
  type: CLOSE_VERIFY_MODAL,
});
