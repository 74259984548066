export const SET_ORDER_FILTERED_LIST = 'SET_ORDER_FILTERED_LIST';

export const ORDERS_TYPE_ALL = 'all';
export const ORDERS_TYPE_FAVORITE = 'favorite';
export const ORDERS_TYPE_RESERVED = 'reserved';

export const setFilteredOrderList = (numbers, typeId) => ({
  type: SET_ORDER_FILTERED_LIST,
  numbers,
  typeId,
});
