import React, { Component } from 'react';

const contains = (parentNode, childNode) => {
  if (!!parentNode.contains) {
    return parentNode.contains(childNode);
  }
  parentNode.compareDocumentPosition(childNode) % 16;
};

class DottedDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  componentDidMount() {
    window.root.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    window.root.removeEventListener('click', this.handleDocumentClick);
  }


  onButtonClick() {
    this.setState(state => Object.assign({}, state, {
      isOpen: !state.isOpen,
    }));
  }

  setClose() {
    this.setState(state => Object.assign({}, state, {
      isOpen: false,
    }));
  }

  setOpen() {
    this.setState(state => Object.assign({}, state, {
      isOpen: true,
    }));
  }

  handleDocumentClick(event) {
    if (event.target === this.button || contains(this.button, event.target)) {
      return;
    }

    const { closeAlways, keepOpen } = this.props;
    if (keepOpen) {
      return;
    }
    if (closeAlways || !contains(this.element, event.target)) {
      this.setState(state => Object.assign({}, state, { isOpen: false }));
    }
  }

  render() {
    const {
      children,
      disabled,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="dropdown" ref={element => this.element = element}>
        <button type="button" className="dotted-block" onClick={this.onButtonClick} ref={element => this.button = element} disabled={disabled}>
          <i />
        </button>
        {isOpen &&
          <div className="dropdown-content">
            {children}
          </div>
        }
      </div>
    );
  };
}

export default DottedDropdown;
