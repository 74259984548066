import React, { Component } from 'react';
import { connect } from 'react-redux';

import { MAX_VIEW_ITEMS } from 'constants';

import { financesFetchIfNeeded } from './../store/finances/actions';
import { getFinancesByFilterSorted } from './../store/finances/selectors';

import { StickyContainer, StickyHead, StickyBody } from './../components/Sticky';
import UpToButton from './../components/UpToButton';
import FinancesTableRow from './FinancesTableRow';
import FinancesTableFilter from './FinancesTableFilter';

class FinancesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: MAX_VIEW_ITEMS,
    };

    this.onMoreClick = this.onMoreClick.bind(this);
  }

  componentDidMount() {
    const { loadFinances } = this.props;
    loadFinances();
  }

  componentDidUpdate() {
    const { loadFinances } = this.props;
    loadFinances();
  }

  onMoreClick() {
    const { financeList } = this.props;
    this.setState(state => Object.assign({}, state, {
      count: Math.min(state.count + MAX_VIEW_ITEMS, financeList.length),
    }));
  }

  renderFinances() {
    const { isLoading, financeList } = this.props;
    const { count } = this.state;

    if (isLoading) {
      return (<div className="preloading preloading-box"><p>Loading</p></div>);
    }
    if (!financeList.length) {
      return (
        <div className="page-not-result">
          <span className="emoji" role="img" aria-label="">😭</span>
          <h1>Oops, there are no finance items matching this filter set.</h1>
          <p className="notes">Please try again with other filter configuration.</p>
        </div>
      );
    }

    return financeList.slice(0, count).map(finance => <FinancesTableRow className="table-row" id={finance._id} key={finance._id} />);
  }

  renderNextButton() {
    const { financeList, isLoading } = this.props;

    if (!financeList.length || isLoading) {
      return null;
    }

    const { count } = this.state;
    const nextCount = Math.min(financeList.length - count, MAX_VIEW_ITEMS);
    if (nextCount <= 0) {
      return null;
    }
    return (
      <button type="button" className="btn btn-show-next" onClick={this.onMoreClick}>Show next {nextCount} items</button>
    );
  }

  render() {
    return (
      <div className="table finances-table">
        <StickyContainer className="sticky-table">
          <StickyHead className="sticky-table-head" classNameFixed="sticky-table-head-fixed">
            <FinancesTableFilter className="table-filter" />
            <div className="table-header row">
              <div className="col-1" />
              <div className="col-6">Status</div>
              <div className="col-4">Amount</div>
              <div className="col-4">Type</div>
              <div className="col-12">Title</div>
              <div className="col-4">Date</div>
              <div className="col-4">Additional info</div>
            </div>
          </StickyHead>
          <StickyBody className="sticky-table-body">
            {this.renderFinances()}
          </StickyBody>
        </StickyContainer>
        <UpToButton />
        {this.renderNextButton()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const financeList = getFinancesByFilterSorted(state);
  const isLoading = state.finances.isFetching;
  return {
    financeList,
    isLoading,
    profile: state.user,
  };
};

const mapDispatchToProps = dispatch => ({
  loadFinances: () => dispatch(financesFetchIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancesTable);
