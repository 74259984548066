import { SHORT_ORDER_TYPES } from './sharedConstants';

export const mapShortOrderTypes = (orderTypesNames) => {
  if (!orderTypesNames) return null;
  return Object.keys(SHORT_ORDER_TYPES).reduce((prev, next) => {
    const shortOrdType = SHORT_ORDER_TYPES[next];
    let count = 0;
    shortOrdType.forEach(type => count += orderTypesNames[type].count);
    prev = {
      ...prev,
      [next]: {
        name: next,
        count,
        values: shortOrdType,
      },
    };
    return prev;
  }, {});
};

export const getCntPreferSubj = (preferSubj, orderSubjectGroupsCount) => preferSubj.reduce((prev, next) => {
  if (orderSubjectGroupsCount && orderSubjectGroupsCount[next]) {
    prev += orderSubjectGroupsCount[next].count;
  }
  return prev;
}, 0);
