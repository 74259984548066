export default {
  default: {
    text: 'Verify me',
    isLink: true,
  },
  submitted: {
    text: 'In progress...',
    disabled: true,
  },
  resubmission_requested: {
    text: 'Resubmit verification?',
    class: 'btn-error-inverse',
    isLink: true,
  },
  declined: {
    text: 'Verification declined',
    class: 'empty-verify-btn btn-error bold-text',
    disabled: true,
  },
};

// not_required
// created
// started
// submitted
// inflow_completed
// resubmission_requested
// review
// approved
// abandoned
// expired
// declined
