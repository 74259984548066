import Radio from 'components/Radio';

import classNames from 'utils/classNames';

const CURRENCY = ['USD', 'PKR'];

const WisePKCurrencyBtns = ({
  onSetPKCurrency, PKSelectedCurrency, isModal = false, isDisabled = false,
}) => (
  <div className={classNames(
    'wise-pk-currency-btn-container',
    { 'wise-pk-currency-btn-container--modal': isModal },
  )}
  >
    {CURRENCY.map(currency => (
      <Radio
        key={currency}
        name="deadlinesFilter"
        value={currency}
        checked={PKSelectedCurrency === currency}
        onChange={onSetPKCurrency}
        disabled={isDisabled}
        className="wise-pk-currency-btn"
      >
        {`${currency} Account`}
      </Radio>
    ))}
  </div>
);

export default WisePKCurrencyBtns;
