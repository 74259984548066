import React from 'react';
import classNames from '../../utils/classNames';
import StrictTooltip from './../../components/StrictTooltip';
import StarRating from './../StarRating';
import { groupsWriters, groupLevelWriter } from './../../constants/writerGroups';

const LevelDescription = (props) => {
  const { active, group, writer, cntWarnings } = props;

  const writerGroup = groupsWriters[group];
  const writerFullGroup = writer.qc_full_group;
  const fullGroup = groupLevelWriter[writerFullGroup];
  const clientAvg = writer.metric_data.client_last_avg;
  const groupClassName = group === 'a+' ? 'a-plus' : group;
  const groupName = group === 'a+' ? 'A+ Level' : `${group}-Level`;

  const classname = classNames(
    'level-description',
    `label-${groupClassName.toLowerCase()}`,
    {
      'label-pro': writerFullGroup.indexOf('pro') > -1,
      'label-standard': writerFullGroup.indexOf('standard') > -1,
      'label-outsider': writerFullGroup.indexOf('outsider') > -1,
      active,
    },
  );

  // const nextLevelTooltip = (
  //   <span>Complete <b>{writer.qc_group_orders_left} orders</b> with no warnings<br />
  //     to unlock a higher group.</span>
  // );

  const nextLevelTooltip = (
    <span>Write more orders, receive good Client scores, <br /> and get to a higher group to earn more!</span>
  );

  const isSwowTooltip = active && group !== 'a' && group !== 'a+' && !writer.is_group_override;

  return (
    <div className={classname}>
      {isSwowTooltip &&
        <div className="warning-content-nextlvl-tooltip-container">
          <StrictTooltip
            content={nextLevelTooltip}
            isYellow
            positionLeft
            className="warning-content-nextlvl-tooltip"
          />
        </div>
      }
      <span className="level-description-border" />
      <span className="level-description-label" />

      <div className="level-description-header">
        <h2>{groupName}</h2>
        {active &&
          <span>{clientAvg}</span>
        }
        {active &&
          <StarRating rate={clientAvg} width={60} />
        }
      </div>

      <h3>Avg. Client Score <b>{`${writerGroup.score}`}</b></h3>
      {group !== 'f' &&
        <h4>Your benefits:</h4>
      }
      {group === 'f' &&
        <h4>Your group gives you no benefits, and: </h4>
      }
      <ul className="level-description-list">
        {writerGroup.benefits.map((b, i) => {
          const isLineThrough = active && fullGroup && !!fullGroup.lineThrough.length && fullGroup.lineThrough.indexOf(i) !== -1;
          return (
            <li key={i}>
              {isLineThrough &&
                <span className="line-through" dangerouslySetInnerHTML={{ __html: b }} />
              }
              {!isLineThrough &&
                <span dangerouslySetInnerHTML={{ __html: b }} />
              }
            </li>
          );
        })}
      </ul>
      {active &&
        <div>
          <div className="tooltip">
            <div className="terms-wrap tooltip-trigger">
              <i className="icon-question" />
              <span className="terms">Terms of benefits</span>
            </div>
            <div className="tooltip-content">You are eligible for all the benefits of this group only if you have not more <b>than 1 active warning</b> at a single point of time.</div>
          </div>

          <div className="description-wrap">
            <p className="title-description">{fullGroup && fullGroup.descriptionTitle}</p>
            <p className="description">{fullGroup && fullGroup.description}</p>
          </div>
        </div>
      }
      {active && fullGroup && fullGroup.bage &&
        <div className="level-description-bage">
          {fullGroup && fullGroup.bage}
        </div>
      }

      {active && fullGroup && !fullGroup.bage && cntWarnings > 0 &&
        <div className="level-description-bage">
          {cntWarnings} active warnings
        </div>
      }
    </div>
  );
};

export default LevelDescription;
