import React from 'react';
import { connect } from 'react-redux';

import Checkbox from './Checkbox';
import IconTag from './shared/IconTag';
// import Icon from './Icon';

import { setFilterStatuses } from './../store/ordersFind/actions';
import { getOrdersFind } from './../store/ordersFind/selectors';

const orderStatuses = {
  is_recommended: {
    name: 'Recommended',
    icon: 'icon-recommended',
  },
  is_suggested: {
    name: 'Suggested',
    icon: 'icon-suggested',
  },
  // is_prioritized: {
  //   name: 'Suggested',
  //   icon: 'icon-suggested',
  // },
  tag_trust: {
    name: 'Client trusts you',
    icon: 'icon-trust',
  },
  tag_draft: {
    name: 'Draft',
    icon: 'icon-draft',
  },
};

const StatusesSearchForm = (props) => {
  const { filter, setFilterStatuses, mobile } = props;
  const keys = Object.keys(orderStatuses);
  return (
    <ul className={mobile ? '' : 'menu menu__statuses'}>
      {keys.map(item =>
        (<li key={item} className={mobile ? 'filter-status-item' : 'menu-item'}>
          <Checkbox name={item} onChange={setFilterStatuses} checked={filter.statuses.indexOf(item) > -1}>
            <IconTag className="svg-icon" iconName={orderStatuses[item].icon} /> {orderStatuses[item].name}
          </Checkbox>
        </li>))}
    </ul>
  );
};

const mapStateToProps = (state, ownProps) => {
  const filter = state.ordersFind.filter[ownProps.typeId];
  const orders = getOrdersFind[ownProps.typeId](state);
  return { filter, orders };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFilterStatuses: event => dispatch(setFilterStatuses(ownProps.typeId, event.target.checked, event.target.name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusesSearchForm);
