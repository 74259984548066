import { urls } from './../../config';
import authFetch from './../../utils/authFetch';
import { notificationSetUpdateRead } from './../notification/actions';

export const UPDATES_REQUEST = 'UPDATES_REQUEST';
export const UPDATES_RECEIVE = 'UPDATES_RECEIVE';
export const UPDATES_SET_VIEWED_REQUEST = 'UPDATES_SET_VIEWED_REQUEST';
export const UPDATES_SET_VIEWED_RECEIVE = 'UPDATES_SET_VIEWED_RECEIVE';


// set viewed 

const updatesSetViewedRequest = (updateId) => {
    return {
        type: UPDATES_SET_VIEWED_REQUEST,
        updateId
    }
};

const updatesSetViewedReceive = (updateId, payload) => {
    return {
        type: UPDATES_SET_VIEWED_RECEIVE,
        updateId,
        payload
    }
};

const updatesSetViewedFetch = (updateId, rate) => {
    return (dispatch, getState) => {
        const payload = rate === undefined
                        ? {status: 'read'}
                        : {status: 'read', rate: rate}
        dispatch(updatesSetViewedRequest(updateId));
        return authFetch(`${urls.updates}/${updateId}`, {
            method: 'PATCH',
            body: JSON.stringify(payload)
        }).then(() => {
            dispatch(notificationSetUpdateRead(updateId))
            dispatch(updatesSetViewedReceive(updateId, payload))
        });
    }
};

const updatesSetViewedShouldFetch = (updateId, state) => {
    const update = state.updates.items[updateId];
    
    if (update && !update.isFetchingViewed && update.status !== 'read')
        return true
    
    if (update && !update.isFetchingViewed && update.your_rate === undefined)
        return true

    return false
};

export const updatesSetViewedFetchIfNeeded = (updateId, rate) => {
    return (dispatch, getState) => {
        if (updatesSetViewedShouldFetch(updateId, getState())) {
            return dispatch(updatesSetViewedFetch(updateId, rate));
        }
    };
};


// load updates

const updatesRequest = () => {
    return {
        type: UPDATES_REQUEST
    };
};

const updatesReceive = (items) => {
    return {
        type: UPDATES_RECEIVE,
        items
    };
};

const updatesFetch = () => {
    return dispatch => {
        dispatch(updatesRequest());
        return authFetch(urls.updates).then(json => {
            const items = json.results.reduce((result, item) => {
                result[item._id] = item;
                return result;
            }, {});
            dispatch(updatesReceive(items));
        });
    };
};

const updatesShouldFetch = state => {
    const updates = state.updates;
    if (updates.isFetching) {
        return false;
    } else {
        return updates.didInvalidate;
    }
};


export const updatesFetchIfNeeded = () => {
    return (dispatch, getState) => {
        if (updatesShouldFetch(getState())) {
            return dispatch(updatesFetch());
        }
    };
};
