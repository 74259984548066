import moment from 'moment-timezone';

import { urls } from './../../config';
import authFetch from './../../utils/authFetch';

import { ordersAddList } from './../orders/actions';

export const ORDERS_PROGRESS_REQUEST = 'ORDERS_PROGRESS_REQUEST';
export const ORDERS_PROGRESS_RECEIVE = 'ORDERS_PROGRESS_RECEIVE';

export const ORDERS_PROGRESS_SET_SORTING = 'ORDERS_PROGRESS_SET_SORTING';
export const ORDERS_PROGRESS_SET_FILTER_SEARCH = 'ORDERS_PROGRESS_SET_FILTER_SEARCH';
export const ORDERS_PROGRESS_SET_MESSAGE_FILTER = 'ORDERS_PROGRESS_SET_MESSAGE_FILTER';
export const ORDERS_PROGRESS_RESET_FILTER = 'ORDERS_PROGRESS_RESET_FILTER';

export const ORDERS_HIDE_FROM_TABLE = 'ORDERS_HIDE_FROM_TABLE';

const ordersProgressRequest = () => ({
  type: ORDERS_PROGRESS_REQUEST,
});

const ordersProgressReceive = numbers => ({
  type: ORDERS_PROGRESS_RECEIVE,
  numbers,
});

export const setProgressMessageFilter = value => ({
  type: ORDERS_PROGRESS_SET_MESSAGE_FILTER,
  value,
});

export const resetProgressMessageFilter = () => ({
  type: ORDERS_PROGRESS_RESET_FILTER,
});

export const ordersProgressFetch = () => (dispatch) => {
  dispatch(ordersProgressRequest());
  return authFetch(`${urls.orders}?folder=in_progress`).then((json) => {
    const numbers = json.results.map(order => order.number);
    const items = json.results.reduce((result, item) => {
      item.didInvalidate = true;
      result[item.number] = item;
      return result;
    }, {});
    dispatch(ordersAddList(items));
    dispatch(ordersProgressReceive(numbers));
  });
};

const ordersProgressShouldFetch = (state) => {
  const { ordersProgress } = state;
  if (ordersProgress.isFetching) {
    return false;
  }
  if (ordersProgress.updatedAt && moment().diff(ordersProgress.updatedAt, 'minutes') > 1) {
    return true;
  }
  return ordersProgress.didInvalidate;
};

export const ordersProgressFetchIfNeeded = () => (dispatch, getState) => {
  if (ordersProgressShouldFetch(getState())) {
    return dispatch(ordersProgressFetch());
  }
};

export const ordersProgressSetSorting = value => ({
  type: ORDERS_PROGRESS_SET_SORTING,
  value,
});

export const ordersProgressSetFilterSearch = value => ({
  type: ORDERS_PROGRESS_SET_FILTER_SEARCH,
  value,
});

export const removeOrdersFromInProgressTable = number => ({
  type: ORDERS_HIDE_FROM_TABLE,
  number,
});
