import React from 'react';
import moment from 'moment-timezone';

import { useMessageQuery } from '@connect/connect-xmpp';

import {
  Card,
  ChatMessage,
} from '@connect/connect-ui-js';

import { AudioItem } from '@connect/connect-audio-message-plugin';

import { CLIENT_CHAT_AVATARS_SERVICE } from 'config';

import { getGroupDate } from 'components/order/utils';
import OrderClientChatCards from 'components/order/OrderClientChatCards';

import { theme } from 'components/order/OrderClientChat/theme';

const connectedFake = eventName => (order = {}) => {
  const { join_chat_at: joinChatAt, first_join_chat_at: firstJoinChatAt } = order;
  const eventTime = eventName === 'chat_connected_fake' ? firstJoinChatAt || joinChatAt : joinChatAt;
  const utcValueOf = moment(eventTime).utc().valueOf();

  return {
    createdAt: moment.tz(eventTime, moment.tz.guess()).format('HH:mm'),
    event: {
      event_name: `crm:${eventName}`,
      event_params: {},
    },
    from: 'smstool',
    groupDate: getGroupDate(utcValueOf),
    id: eventName,
    isFirstInGroup: true,
    time: utcValueOf,
    type: 'event',
  };
};

const FAKE_MSGS = {
  chat_connected_fake: connectedFake('chat_connected_fake'),
  chat_re_connected_fake: connectedFake('chat_re_connected_fake'),
  chat_idle_fake: (order) => {
    const { join_chat_at: joinChatAt } = order;
    const joinChatAtAdd4MinutesTz = moment.tz(joinChatAt, moment.tz.guess()).add(4, 'minutes');

    return {
      createdAt: joinChatAtAdd4MinutesTz.format('HH:mm'),
      event: {
        event_name: 'crm:chat_idle_fake',
        event_params: {},
      },
      from: 'smstool',
      groupDate: '',
      id: 'chat_idle_fake',
      isFirstInGroup: true,
      time: joinChatAtAdd4MinutesTz.valueOf(),
      type: 'event',
    };
  },
};

const Message = ({
  messageId, profile, order, showTab,
}) => {
  const { jabber_jid: username } = profile;
  const clientChatConfig = {
    username,
    node: order.jabber_node,
    to: order.jabber_server,
  };

  let msgProps = {
    retry: () => {},
    remove: () => {},
    reply: () => {},
    read: () => {},
  };

  if (FAKE_MSGS[messageId]) {
    msgProps = { ...msgProps, data: FAKE_MSGS[messageId](order) };
  } else {
    const messageData = useMessageQuery(
      messageId,
      clientChatConfig,
    );
    msgProps = { ...msgProps, ...messageData };
  }

  const { retry, ...rest } = msgProps;

  const getPic = (from) => {
    const parts = from.split('@');
    if (parts.length > 1) return `${CLIENT_CHAT_AVATARS_SERVICE}/${parts[0]}`;
    return '';
  };

  const renderEvents = (msgData) => {
    let MainCard = () => null;
    const { event: { event_name: eventName = '' } } = msgData;
    const MainComponents = OrderClientChatCards[eventName];
    if (MainComponents) {
      MainCard = () => React.createElement(MainComponents, {
        msgData, order, getPic, profile, showTab,
      });
    } else {
      MainCard = () => (<Card order={order} data={msgData}><Card.Main>here is template for {eventName}</Card.Main><Card.Footer data={msgData} /></Card>);
    }

    return {
      MainCard,
    };
  };

  const renderAudio = (props = {}) => (<AudioItem theme={theme} {...props} />);

  return (
    <ChatMessage
      renderEvents={renderEvents}
      renderAudio={renderAudio}
      {...rest}
      retrySend={retry}
      getPic={getPic}
    />
  );
};

export default Message;
