import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackValues } from 'lrtracker';

import { urls } from 'config';

import { getProfile, getAgreementInfo } from 'store/user/selectors';

import authFetch from 'utils/authFetch';
import storage from 'utils/localStorageHandler';

import { LAST_WS_SUBSCRIPTION, IS_SHOWED_BROWSER_NOTIFICATIONS_MODAL } from 'constants/localStorage';

import InfoComponent from 'components/agreement/InfoModal/InfoComponent';

import { closeModalComponent, addModalComponent } from 'store/modals/actions';

import modalList from 'constants/modalList';

const BrowserNotificationsModal = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile) || {};
  const showAgreement = useSelector(getAgreementInfo);

  const dataModal = modalList.BrowserNotifications;

  const sendWSCredentials = async () => {
    const sw = await navigator.serviceWorker.ready;

    if (!sw || !sw.pushManager) return;

    const subscription = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
          'BJmDrgPXKlqZb4vdv2XJWChBCrsmm9G57fzxGl-u3NZsfI-T-HDBOFwwjmd0ZI3Dtg9vZIKHQiM2fc_eBQa0KQk',
    });
    authFetch(urls.swSubscribe, {
      method: 'POST',
      body: JSON.stringify({
        subscription,
        domain: 'WT',
      }),
    }).then(() => {
      storage.set(LAST_WS_SUBSCRIPTION, profile._id);
    });
  };

  const onCloseModal = () => {
    dispatch(closeModalComponent(dataModal.name));
    storage.set(IS_SHOWED_BROWSER_NOTIFICATIONS_MODAL, true);
  };

  const onRequestPermission = async () => {
    onCloseModal();

    if (Notification.permission === 'granted') return;

    const permission = await Notification.requestPermission();
    const trackValue = permission === 'granted' ? 'TRUE' : 'FALSE';
    const context = {
      browser: navigator.userAgent,
    };
    trackValues('freelancerBrowserNotificationStatusReceived', trackValue, { context });

    if (permission === 'granted') {
      sendWSCredentials();
    }
  };

  useEffect(() => {
    if (!profile || !profile._id) return;

    if (!('Notification' in window)) return;

    if (showAgreement) return;

    if (Notification.permission === 'granted') {
      const lastWsSubscription = storage.get(LAST_WS_SUBSCRIPTION) || '';

      if (profile._id === lastWsSubscription) return;

      sendWSCredentials();
    }

    if (Notification.permission !== 'default') return;

    const isShowedBrowserNotificationsModal = storage.get(IS_SHOWED_BROWSER_NOTIFICATIONS_MODAL) || '';

    if (isShowedBrowserNotificationsModal) return;

    const component = (<InfoComponent
      modalName={dataModal.name}
      onNextClick={onRequestPermission}
      btnText="Allow browser notifications"
      canClose
      closeHandler={onCloseModal}
      autoText
    />);
    dispatch(addModalComponent({ component, ...dataModal }));
    storage.remove(LAST_WS_SUBSCRIPTION);
  }, [showAgreement]);

  return null;
};

export default BrowserNotificationsModal;
