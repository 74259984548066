import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import tracker, { trackClicks } from 'lrtracker';

import { getBannersList } from 'store/banners/selector.js';

import BannersList from 'constants/banners';

import { addBanner, deleteBanner } from 'store/banners/actions';

import storage from 'utils/localStorageHandler';

import Icon from 'components/Icon';

const DEFAULT_TEXT = `Do you want to get access to a new stream of earnings and work at tasty tutoring orders?<br />
                      Are you interested in chatting directly with your client and building your own client base?<br />
                      <a href=https://lrtutors.com/become-a-tutor-2021 target=_blank class=suspicious-alert__tutor-link>Click on me to become a freelance tutor</a> 
                      and open a new direction for your career!`;

const dateCompareFunc = (dateArray = [], currentValue) => moment(currentValue).isBetween(dateArray[0], dateArray[1], 'undefined', '[]');
const includeCompareFunc = (valuesArray = [], currentValue) => valuesArray.includes(currentValue);
const includeOneCompareFunc = (valuesArray = [], currentValues = []) => valuesArray.some(value => currentValues.includes(value));
const stringCompareFunc = (stringValue = '', currentValue) => stringValue === currentValue;
const isBetweenValuesFunc = (valuesArray = [], currentValue) => currentValue >= valuesArray[0] && currentValue <= valuesArray[1];

const dataMap = {
  hired_at: 'created_at',
  profile_status: 'view_status',
  public_profile_status: 'profile_public_status',
  subjects_current: 'subjects',
  orders_completed: 'metric_data@completed_orders_count',
  pages_completed: 'metric_data@completed_orders_size_pages',
  orders_this_month: 'metric_data@completed_orders_count_month',
  orders_prev_month: 'metric_data@last_completed_orders_count_month',
  orders_in_work: 'metric_data@current_orders_count',
  pages_in_work_status: 'metric_data@current_orders_size_pages',
  client_scores_n: 'metric_data@scores_count',
  client_score_mean: 'metric_data@client_feedbacks@count',
  client_score_mean20: 'metric_data@client_last_avg',
  ref_rate: 'metric_data@refund_perc',
  active_tags: 'tags',
  group_name: 'qc_full_group',
};

const dataCheckMap = {
  created_at: dateCompareFunc,
  view_status: includeCompareFunc,
  profile_public_status: includeCompareFunc,
  country_final: includeCompareFunc,
  major_subject: stringCompareFunc,
  subjects: includeOneCompareFunc,
  qc_full_group: includeCompareFunc,
  'metric_data@completed_orders_count': isBetweenValuesFunc,
  'metric_data@completed_orders_size_pages': isBetweenValuesFunc,
  'metric_data@completed_orders_count_month': isBetweenValuesFunc,
  'metric_data@last_completed_orders_count_month': isBetweenValuesFunc,
  'metric_data@current_orders_count': isBetweenValuesFunc,
  'metric_data@current_orders_size_pages': isBetweenValuesFunc,
  'metric_data@scores_count': isBetweenValuesFunc,
  'metric_data@client_feedbacks@count': isBetweenValuesFunc,
  'metric_data@client_last_avg': isBetweenValuesFunc,
  'metric_data@refund_perc': isBetweenValuesFunc,
  tags: includeCompareFunc,
};


const TutorRecruitingBanner = () => {
  const profile = useSelector(state => state.user || {});
  const tutorBannerRef = useRef({});

  const [showBanner, toggleShowBanner] = useState(false);
  const [bannerText, setBannerText] = useState(DEFAULT_TEXT);

  const dispatch = useDispatch();

  const bannerList = useSelector(getBannersList);

  const showBannerProbability = percent => tutorBannerRef.current.random < (percent * 100);

  const showBannerHandler = async () => {
    const { _id: userId } = profile;

    const { userToken: prevUserToken } = storage.get('isShownTutorBanner') || {};
    const userToken = localStorage.getItem('token');

    if (prevUserToken === userToken) return false;

    if (prevUserToken) {
      storage.remove('isShownTutorBanner');
    }

    try {
      tracker.config.setToken(localStorage.getItem('token').split('Bearer ')[1]);
      const tutorBannerJson = await tracker.store.get('tutorBanner');
      const {
        params, blacklist = [], whitelist = [], p, bannerText: __bannerText = DEFAULT_TEXT,
      } = await tutorBannerJson.json() || {};

      setBannerText(__bannerText);

      if (blacklist.includes(userId)) return false;

      if (whitelist.includes(userId) && showBannerProbability(p * 2)) return true;

      if (!params) return false;

      const isValidByParams = Object.keys(params).every((param) => {
        let profileValue = null;
        const mapParam = dataMap[param] || param;

        const paramsKey = mapParam.split('@');
        profileValue = paramsKey.reduce((prev, next) => prev[next], profile);

        const paramsValue = params[param];

        if (profileValue === undefined || paramsValue === undefined) return false;

        return dataCheckMap[mapParam](paramsValue, profileValue);
      });

      if (isValidByParams && showBannerProbability(p)) return true;
    } catch (e) {
      console.error('error while fetch or parse data from lrtracker ', e.message);
      return false;
    }
    return false;
  };

  useEffect(() => {
    tutorBannerRef.current.random = (Math.random() * 100) | 0;
    async function showBannerCalc() {
      dispatch(addBanner({ name: 'recruitingBanner', ...BannersList.recruitingBanner }));
      const isShowBanner = await showBannerHandler();
      toggleShowBanner(isShowBanner);
      if (!isShowBanner) {
        dispatch(deleteBanner({ name: 'recruitingBanner' }));
      }
    }
    showBannerCalc();
  }, []);


  if (!showBanner) return null;
  if (bannerList.length > 0 && bannerList[0].name !== 'recruitingBanner') return null;

  const closeHandler = () => {
    const userToken = localStorage.getItem('token');

    toggleShowBanner(false);

    storage.set('isShownTutorBanner', { userToken });
    dispatch(deleteBanner({ name: 'recruitingBanner' }));
  };

  const bannerHandleClick = (e) => {
    const { target: { nodeName } } = e;

    if (nodeName !== 'A') return;

    trackClicks(closeHandler, 'tutor_banner', {})();
  };


  return (
    <div style={{ '--marginAnim': '-51px' }} className="suspicious-alert suspicious-alert--animate suspicious-alert--blue-bg suspicious-alert--black-text suspicious-alert--extra-padding suspicious-alert--medium-weight line-height-20">
      <div className="suspicious-alert__close-block"><button className="btn btn-empty btn-close" onClick={trackClicks(closeHandler, 'tutor_banner_close_click', {})}><Icon className="svg-icon" iconName="close" /></button></div>
      <div onClick={bannerHandleClick} dangerouslySetInnerHTML={{ __html: bannerText }} />
    </div>
  );
};

export default TutorRecruitingBanner;
