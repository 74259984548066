import React from 'react';
import classNames from './../utils/classNames';

export default props => (
  <span
    style={{ width: props.size, height: props.size }}
    className={classNames("container-arrow", props.className)}
  >
    <span className={classNames("calendar-arrow", { "active": props.active })} />
  </span>
);
