import triggerWebNotification from 'utils/triggerWebNotification';

const orderEventsParser = (payload) => {
  const { event_tag: eventTag, order_info: orderInfo = {} } = payload;
  const { number, _id: orderId, revision_comment: revisionComment = 'revision comment' } = orderInfo;

  const link = `/order/${orderId}`;
  let title = '';
  let text = '';

  if (eventTag === 'revision_accept') {
    title = `You have a new revision request in ${number}`;
    text = `${revisionComment.substr(0, 88)}`;
  }

  if (eventTag === 'half_revision') {
    title = `Revision confirmation required in ${number}`;
    text = `${revisionComment.substr(0, 88)}`;
  }

  if (eventTag === 'half_writer_deadline_pre') {
    title = `Confirmation required in ${number}`;
    text = 'You need to confirm working on the order';
  }

  if (eventTag === 'half_writer_deadline') {
    title = `Confirmation required in ${number}`;
    text = 'Confirm working on the order ASAP, otherwise you may be rejected';
  }

  triggerWebNotification({
    title,
    link,
    text,
  });

  return {
    type: '',
  };
};


export default orderEventsParser;
