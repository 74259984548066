import React, { Component } from 'react';
import { UnmountClosed } from 'react-collapse';
import { connect } from 'react-redux';

import { withRouterMatch } from 'hocs';

import { getFaqListFiltered } from './../store/help/selectors';
import MarkableText from './MarkableText';

class HelpTabFaq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openId: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.setState({ openId: null });
    }
  }

  toggleCollapse(id) {
    this.setState({
      openId: this.state.openId === id ? null : id,
    });
  }

  render() {
    const { faqItemTitle, faqList, search } = this.props;
    const { openId } = this.state;
    const isShowAccordion = faqList.length > 1;

    return (
      <section className="right-content padding-content">

        {search
          ? <h1 className="title title-search"><span>Search results for:</span> {search}</h1>
          : <h1 className="title">{faqItemTitle}</h1>
        }
        {isShowAccordion &&
          <ul className="accordion">
            {faqList.map(faqItem => (
              <li
                key={faqItem.title}
                className="accordion-item"
              >
                <a
                  className="accordion-trigger"
                  onClick={() => this.toggleCollapse(faqItem.title)}
                >
                  <MarkableText html={faqItem.title} mark={search} />
                  <span className="arrow-down" />
                </a>

                <UnmountClosed isOpened={!!search || openId === faqItem.title} className="accordion-content">
                  <div className="border-notes"><MarkableText html={faqItem.text} mark={search} /></div>
                </UnmountClosed>
              </li>
            ))}
          </ul>
        }
        {!isShowAccordion &&
          <div className="help-faq-text">
            {faqList.map(faqItem => (
              <MarkableText key={faqItem.title} html={faqItem.text} mark={search} />
            ))}
          </div>
        }
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { category } = ownProps.match.params;
  const { search } = state.help;
  const faqList = getFaqListFiltered(state);
  const catFaqList = !search ? faqList.filter(item => item.category === category) : faqList;

  const faqItemTitle = state.help.faqFlatCats[category].text;
  return {
    faqList: catFaqList,
    faqItemTitle,
    category,
    search,
  };
};


export default withRouterMatch(connect(mapStateToProps)(HelpTabFaq));
