import { urls } from './../../config';
import authFetch from './../../utils/authFetch';

export const TICKETS_SETTINGS_REQUEST = 'TICKETS_SETTINGS_REQUEST';
export const TICKETS_SETTINGS_RECEIVE = 'TICKETS_SETTINGS_RECEIVE';

const ticketsSettingsRequest = () => ({
  type: TICKETS_SETTINGS_REQUEST,
});

const ticketsSettingsReceive = data => ({
  type: TICKETS_SETTINGS_RECEIVE,
  data,
});

const ticketsSettingsFetch = () => (dispatch) => {
  dispatch(ticketsSettingsRequest());
  return authFetch(urls.ticketsSettings)
    .then((json) => {
      dispatch(ticketsSettingsReceive(json));
      return Promise.resolve();
    })
    .catch(er => console.error(er));
};

// export const ticketsSettingsFetch = () => (dispatch) => {
//   dispatch(ticketsSettingsRequest());
//   return authFetch(urls.ticketsSettings).then((json) => {
//     dispatch(ticketsSettingsReceive(json));
//   });
// };

const ticketsSettingsShouldFetch = (state) => {
  const { ticketsSettings } = state;
  if (ticketsSettings.isFetching) {
    return false;
  }
  return ticketsSettings.didInvalidate;
};

export const ticketsSettingsFetchIfNeeded = () => (dispatch, getState) => {
  if (ticketsSettingsShouldFetch(getState())) {
    return dispatch(ticketsSettingsFetch());
  }
  return Promise.resolve();
};
