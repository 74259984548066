import React from 'react';
import moment from 'moment-timezone';

import utils from 'utils';

import classnames from 'utils/classNames';

import {
  STATUS_PROGRESS,
  STATUS_REVISION,
  STATUS_COMPLETE,
} from 'store/orders/actions';

import OrderFileList from 'components/order/OrderFileList';

import OrderOpenActions from 'components/order/OrderContent/TabContent/OrderTabInfo/OrderOpenActions';
import OrderDraftPaperList from 'components/order/OrderContent/TabContent/OrderTabInfo/OrderDraftPaperList';
import OrderFinalPaperList from 'components/order/OrderContent/TabContent/OrderTabInfo/OrderFinalPaperList';

import OrderComment from '../components/OrderComment';
import LoginDetail from '../components/LoginDetail';

import OrderRevisionPaperList from './OrderRevisionPaperList';

const OrderTabRevision = (props) => {
  const {
    order,
    fileItems,
    fileWebhook,
    onResize,
    status,
    uploadFile,
    uploadOrderFiles,
    setRevisionCompleted,
    isStatusLoading,
    removeFile,
    setFilesStickyRef,
    onOpenIssueForm,
    ...rest
  } = props;
  const fileList = Object.values(fileItems).filter(file => !file.deleted);
  const revDate = moment(order.revision_accept_at);
  const revisedFiles = fileList.filter(file => file.group === 'paper' && moment(file.created_at).isAfter(revDate));
  const revisionFiles = fileList.filter(file => file.type === 'client_revision_request_file');
  const duplicateAndRevisionFiles = fileList.filter(file => file.type === 'origin_revision_related');
  const plagFiles = fileList.filter(file => file.group === 'plagreport');

  const showRevisedList = status === STATUS_REVISION || status === STATUS_COMPLETE;
  const isDuplicateAndRevisionOrder = utils.isDuplicateAndRevisionOrder(order);

  const finalFiles = fileList.filter(file => file.group === 'paper' && file.type !== 'file_draft_paper');
  const draftFiles = fileList.filter(file => file.type === 'file_draft_paper');
  const hasParallelOrder = order.has_paid_parallels;
  const draftNeeded = order.tags.indexOf('draft_needed') > -1;

  const renderDuplicateAndRevisionDetails = () => {
    const { origin_revision_comment: originRevisionComment } = order;

    if (!originRevisionComment) return null;

    return (
      <OrderComment icon="comments-with-strokes" title="Initial revision instructions" onResize={onResize}>
        {originRevisionComment}
      </OrderComment>
    );
  };

  return (
    <div>
      <LoginDetail order={order} onResize={onResize} status={status} />
      {!!order.revision_comment &&
        <OrderComment icon="comments" title={isDuplicateAndRevisionOrder ? 'Current revision comment from operator' : 'Revision comment from operator'} onResize={onResize}>
          {order.revision_comment}
        </OrderComment>
      }
      {showRevisedList &&
        <OrderFileList
          fileList={revisionFiles}
          fileWebhook={fileWebhook}
          orderNumber={order.number}
          onResize={onResize}
          setStickyRef={setFilesStickyRef}
          icon="clip"
          title={isDuplicateAndRevisionOrder ? 'Current revision-related files' : 'Revision-related files'}
          order={order}
          withCategories
          isRevisionRelatedFiles
        />
      }
      {isDuplicateAndRevisionOrder && renderDuplicateAndRevisionDetails()}
      {isDuplicateAndRevisionOrder && (
        <div>
          <OrderFileList
            fileList={duplicateAndRevisionFiles}
            fileWebhook={fileWebhook}
            orderNumber={order.number}
            onResize={onResize}
            setStickyRef={setFilesStickyRef}
            icon="clip"
            title={status === STATUS_REVISION || status === STATUS_COMPLETE ? 'Initial revision-related files' : 'Revision-related files'}
            order={order}
            withCategories
            isRevisionRelatedFiles
          />
          <div className="mt8">
            <OrderOpenActions
              order={order}
              isStatusLoading={isStatusLoading}
              status={status}
              {...rest}
            />
          </div>
          <div className={classnames('upload-btns', { row: !draftFiles.length && !finalFiles.length })}>
            {(status === STATUS_PROGRESS || status === STATUS_REVISION || status === STATUS_COMPLETE) && draftNeeded &&
            <OrderDraftPaperList
              order={order}
              fileList={draftFiles}
              onResize={onResize}
              uploadFile={uploadFile}
              removeFile={removeFile}
              status={status}
              hasParallelOrder={hasParallelOrder}
              orderNumber={order.number}
            />
        }
            {(status === STATUS_PROGRESS || status === STATUS_REVISION || status === STATUS_COMPLETE) &&
            <OrderFinalPaperList
              order={order}
              fileList={finalFiles}
              plagFileList={plagFiles}
              onResize={onResize}
              uploadOrderFiles={uploadOrderFiles}
              status={status}
              orderNumber={order.number}
              hasParallelOrder={hasParallelOrder}
              onOpenIssueForm={onOpenIssueForm}
            />
        }
          </div>
        </div>
      )}
      {showRevisedList &&
      <OrderRevisionPaperList
        fileList={revisedFiles}
        onResize={onResize}
        uploadOrderFiles={uploadOrderFiles}
        status={status}
        setRevisionCompleted={setRevisionCompleted}
        isStatusLoading={isStatusLoading}
        orderNumber={order.number}
        order={order}
        plagFileList={plagFiles}
        onOpenIssueForm={onOpenIssueForm}
      />
      }
    </div>
  );
};

export default OrderTabRevision;
