import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

const formatDate = (value) => {
  const momentObj = moment.isMoment(value) ? value : moment(value);
  return momentObj.format('MMM D, YYYY');
};

class ActiveWarningsTableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seen: props.warning.seen,
    }

    this.getClosedTimeOrOrders = this.getClosedTimeOrOrders.bind(this);
    this.handleDocumentScroll = this.handleDocumentScroll.bind(this);
  }

  componentDidMount() {
    window.document.addEventListener('scroll', this.handleDocumentScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.warningIsFetching && !this.props.warningIsFetching) {
      this.setState({ seen: true });
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener('scroll', this.handleDocumentScroll);
  }

  getClosedTimeOrOrders() {
    const { orders_live, closed_at } = this.props.warning;
    if (closed_at) {
      return formatDate(closed_at);
    }
    if (orders_live) {
      return orders_live === 1 ?
        `${orders_live} order` :
        `${orders_live} orders`;
    }
    return '';
  }

  handleDocumentScroll() {
    const { update, warning, warningIsFetching } = this.props;
    const { seen } = this.state;

    if (warningIsFetching) return null;

    if (window.pageYOffset + window.innerHeight > this.row.offsetTop && window.pageYOffset - this.row.offsetTop < this.row.clientHeight) {
      if (!seen) {
        update(warning.tag);
      }
    }
  }

  render() {
    const { title, comment, created_at } = this.props.warning;
    return (
      <div ref={(row) => { this.row = row; }} className="active-warnings-table-row">
        <div className="active-warnings-table-row-type">{title}</div>
        <div className="active-warnings-table-row-description">{comment}</div>
        <div className="active-warnings-table-row-created">{formatDate(created_at)}</div>
        <div className="active-warnings-table-row-closed">{this.getClosedTimeOrOrders()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const tagWarning = ownProps.warning.tag;
  const warning = (state.user && state.user.warnings && state.user.warnings[tagWarning]) || null;
  const warningIsFetching = (warning && warning.isFetching) || false;
  return { warningIsFetching, tagWarning };
};

export default connect(mapStateToProps)(ActiveWarningsTableRow);
