import React from 'react';

const Comment = ({ comment }) => (
  <div className="grade-box-head">
    <div className="grade-comment">
      <h4>Comments</h4>
      <p>{comment}</p>
    </div>
  </div>
);

export default Comment;
