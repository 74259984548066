import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import { getProfile } from 'store/user/selectors';

import { trackValues, setCid } from 'lrtracker';

import storage from 'utils/localStorageHandler';

const LRPixel = () => {
  const profile = useSelector(getProfile);
  const { _id: writerId, timezone_str: profileTz } = profile;

  useEffect(() => {
    setCid(writerId);
    let instanceKey = storage.get('_x1');
    const writerIdFromLS = storage.get('writerId');

    if (instanceKey && writerIdFromLS && writerIdFromLS === writerId) return;

    instanceKey = uuidv4();
    storage.set('_x1', instanceKey);
    storage.set('writerId', writerId);
    trackValues('_x1', true, {
      context: { _x1: instanceKey, cid: writerId },
    });
  }, []);

  useEffect(() => {
    if (!profileTz) return;

    const lsBrowserTz = storage.get('browser_tz');
    const lsProfileTz = storage.get('profile_tz');
    const browserTz = moment.tz.guess(true);
    if (!lsBrowserTz || lsBrowserTz !== browserTz) {
      storage.set('browser_tz', browserTz);
    }

    if (!lsProfileTz || lsProfileTz !== profileTz) {
      storage.set('profile_tz', profileTz);
    }

    if (browserTz === profileTz) return;

    if ((browserTz !== lsBrowserTz || lsProfileTz !== profileTz)) {
      trackValues('_x2', true, {
        context: { browser_tz: browserTz, profile_tz: profileTz },
      });
    }
  }, [profileTz]);

  return null;
};

export default LRPixel;
