import React from 'react';

import { AreaInvolvement, degreeList } from 'constants';

import { ProfileInput, ProfileDropdown } from '../Controls';


const Education = ({ data, isStem, onChangeFields }) => {
  const { university, profile_involvement, major, public_degree } = data;

  return (
    <div className="row person-common-info">
      <div className="col-2 person-common-info-title">Education</div>
      <div className="col-4">
        <ul>
          <li className="person-info__item">
            <ProfileInput
              value={university}
              fieldName="university"
              onChangeFields={!isStem && onChangeFields}
              title="University"
              placeholder="Enter your university"
            />
          </li>
          <li className="person-info__item">
            <ProfileDropdown
              value={profile_involvement}
              fieldName="profile_involvement"
              list={AreaInvolvement}
              onChangeFields={!isStem && onChangeFields}
              title="Area of involvement"
            />
          </li>
          <li className="person-info__item">
            <ProfileInput
              value={major}
              fieldName="major"
              onChangeFields={!isStem && onChangeFields}
              title="Major"
              placeholder="Enter your major"
            />
          </li>
          <li className="person-info__item">
            <ProfileDropdown
              value={public_degree}
              fieldName="public_degree"
              list={degreeList}
              onChangeFields={!isStem && onChangeFields}
              title="Degree"
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Education;
