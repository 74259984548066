import React from 'react';

import Icon from 'components/Icon';

import { PATH_WARNINGS } from '../constants';

const AdditionalInfo = ({ user }) => {
  const cntActiveWarnings = user.activeWarnings && user.activeWarnings.results && user.activeWarnings.results.total;
  if (!cntActiveWarnings) return null;

  return (
    <a href={PATH_WARNINGS} className="writer-warnings">
      <Icon className="svg-icon" iconName="icon_cnt_warning" />
      {cntActiveWarnings}
    </a>
  );
};

export default AdditionalInfo;
