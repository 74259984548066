/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import classNames from 'utils/classNames';
import { downloadFile } from 'utils/downloadFile';

import { useFirstRender, usePrevious } from 'hooks';

import './index.scss';

const ImgComponent = ({ imgProps, onLoadHandler, resetTransform }) => {
  const isFirstRender = useFirstRender();
  const previousImgProps = usePrevious(imgProps);

  useEffect(() => {
    if (isFirstRender) return;
    if (previousImgProps && (imgProps.src === previousImgProps.src)) return;

    setTimeout(() => {
      resetTransform();
    }, 10);
  }, [imgProps, resetTransform, previousImgProps]);

  return (
    <img {...imgProps} onLoad={onLoadHandler} />
  );
};

const ImageZoom = ({ imgProps, toolsClass = '', disabled = false, setZoom = {}, realLocation = null }) => {
  const [showTools, setShowTools] = useState(false);
  const onLoadHandler = useCallback(
    () => {
      setShowTools(true);
    },
    [setShowTools],
  );

  const onDownload = useCallback(
    () => {
      const fileUrls = realLocation ? realLocation.split('/') : imgProps.src.split('/');
      downloadFile(realLocation || imgProps.src, fileUrls.slice(-1)[0] || 'file');
    },
    [imgProps, realLocation],
  );

  return (
    <TransformWrapper
      defaultScale={1}
      defaultPositionX={0}
      defaultPositionY={0}
      disabled={disabled}
      {...setZoom}
    >
      {({ zoomIn, zoomOut, resetTransform }) => (
        <div className="zoom_container">
          {
            showTools && (
              <div className={classNames('tools', toolsClass, 'row column vertical')}>
                <button onClick={zoomIn}>+</button>
                <button onClick={zoomOut}>-</button>
                <button onClick={resetTransform}>x</button>
                <button onClick={onDownload} className="no_padding">
                  <div className="btn_icon download_btn" />
                </button>
              </div>
            )
          }
          <TransformComponent>
            <ImgComponent imgProps={imgProps} onLoadHandler={onLoadHandler} resetTransform={resetTransform} disabled={disabled} />
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  );
};

export default ImageZoom;
