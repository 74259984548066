const dataContentSectionContent = {
  essay1: {
    id: 'essay1',
    name: 'Client requirements met',
    tooltip: 'The paper follows client’s initial instructions. There is no irrelevant or generic content.',
    value: ['20', '17', '3'],
  },
  essay2: {
    id: 'essay2',
    name: 'No plagiarism',
    tooltip: 'The information that is not common knowledge is cited (no unsupported claims). Direct quotations do not exceed 10% of the word.',
    value: ['15', '10', '8'],
  },
  essay3: {
    id: 'essay3',
    name: 'Appropriate sources',
    tooltip: 'Those required by the Client, academic, scholarly, published within the last 5-7 years) and their number is correct (requested by the Client, OR 1 page = 1 source).',
    value: ['15', '13', '8'],
  },
  essay4: {
    id: 'essay4',
    name: 'Appropriate word count',
    tooltip: 'From introduction to conclusion. Max 10% deviation.',
    value: ['5', '4', '3'],
  },
};

const dataContentSectionStructure = {
  essay5: {
    id: 'essay5',
    name: 'Appropriate introduction & <br/> conclusion',
    tooltip: 'Max 10% of the entire word count (each) and are not generic.',
    value: ['5', '4', '3'],
  },
  essay6: {
    id: 'essay6',
    name: 'Thesis is an arguable claim',
    tooltip: 'The last sentence of the introduction.',
    value: ['5', '4', '3'],
  },
  essay7: {
    id: 'essay7',
    name: 'Body well <br/> organized & coherent',
    tooltip: '<ul class="list"><li>One paragraph is min 3 sentences, and max 2/3 of a page</li><li>Each paragraph has a topic sentence supporting the thesis, and a concluding sentence</li><li>Each paragraph is dedicated to one idea, which together form a coherent text</li></ul>',
    value: ['5', '4', '3'],
  },
};

const dataContentSectionLanguage = {
  essay8: {
    id: 'essay8',
    name: 'Paper proofread',
    tooltip: 'Number of typos per page specified in the grading rubric.',
    value: ['2', '1', '0'],
  },
  essay9: {
    id: 'essay9',
    name: 'No grammar mistakes',
    tooltip: 'Sentence structure and word choice are correct.',
    value: ['9', '8', '7'],
  },
  essay10: {
    id: 'essay10',
    name: 'Writing style is academic',
    tooltip: 'No colloquialisms, contractions, phrasal verbs, rhetorical questions, personal pronouns.',
    value: ['9', '8', '7'],
  },
};

const dataContentSectionFormat = {
  essay11: {
    id: 'essay11',
    name: 'Proper Formatting',
    tooltip: 'Adherence to one consistent format (MLA, APA etc.)',
    value: ['10', '9', '8'],
  },
};

export { dataContentSectionContent, dataContentSectionStructure, dataContentSectionLanguage, dataContentSectionFormat };
