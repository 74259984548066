import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import modalList from 'constants/modalList';
import timeConstants from 'constants/time';

import { closeModalComponent, addModalComponent } from 'store/modals/actions';

import { getTokenExpire, getToken, setToken } from './../../utils/tokenStorage';
import { urls } from './../../config';

import SessionPromptModal from './SessionPromptModal';
import ExpiredModal from './ExpiredModal';

const SessionExtendModal = () => {
  const serverDate = useSelector(state => state.user.date || {});
  const dispatch = useDispatch();
  let dataModal = null;
  let component = null;

  const [showPrompt, setShowPrompt] = useState(false);
  const [showExpired, setShowExpired] = useState(false);

  let timeoutToLogout = null;
  let timeoutToPrompt = null;

  useEffect(() => {
    const expireVal = getTokenExpire();
    const expireDate = moment.unix(expireVal);
    timeoutToLogout = expireDate.diff(moment(serverDate));

    if (timeoutToLogout < 0) {
      timeoutToLogout = 0;
    }
    timeoutToPrompt = timeoutToLogout - timeConstants.TIME_BEFORE_LOGOUT;
    if (timeoutToPrompt < 0) {
      timeoutToPrompt = 0;
    }
    timeoutToLogout = setTimeout(() => {
      setShowPrompt(false);
      setShowExpired(true);
    }, timeoutToLogout);
    if (timeoutToPrompt !== timeoutToLogout) {
      timeoutToPrompt = setTimeout(() => {
        setShowPrompt(true);
      }, timeoutToPrompt);
    }

    return () => {
      clearTimeout(timeoutToLogout);
      clearTimeout(timeoutToPrompt);
    };
  }, []);

  const refreshToken = () => {
    fetch(urls.signIn, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: getToken(),
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      const headers = Array.from(response.headers.entries());
      for (let i = 0; i < headers.length; i += 1) {
        const header = headers[i];
        if (header[0].toLowerCase() === 'authorization') {
          setToken(header[1]);
          break;
        }
        throw new Error();
      }
      setShowPrompt(false);
      setShowExpired(false);
      dispatch(closeModalComponent(modalList.SessionPromptModal.name));
      // this.setState({
      //   showPrompt: false,
      //   showExpired: false,
      // }, () => this.componentDidMount());
    }).catch(() => {
      setShowPrompt(false);
      setShowExpired(true);
    });
  };


  const onLogout = () => {
    window.location = urls.logout;
    dispatch(closeModalComponent(modalList.ExpiredModal.name));
  };

  if (showPrompt) {
    dataModal = modalList.SessionPromptModal;
    component = <SessionPromptModal onBtnClick={refreshToken} />;
    dispatch(addModalComponent({ component, ...dataModal }));
  }
  if (showExpired) {
    dataModal = modalList.ExpiredModal;
    component = <ExpiredModal onBtnClick={onLogout} />;
    dispatch(addModalComponent({ component, ...dataModal }));
  }

  return null;
};
export default SessionExtendModal;
