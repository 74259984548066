import React, { Component } from 'react';

import { withRouterMatch } from 'hocs';

import OrdersRevisionTable from './../components/OrdersRevisionTable';
import OrderRevisionTabPage from './OrderRevisionTabPage';

const myPath = '/myorders/revisions';

class MyOrdersRevisionPage extends Component {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(orderNumber) {
    const { history, match } = this.props;
    const prevOrderNumber = match.params.orderNumber;
    if (prevOrderNumber === orderNumber) {
      history.push(myPath);
      return;
    }
    history.push(`${myPath}/${orderNumber}`);
  }

  render() {
    const { match } = this.props;
    const { orderNumber } = match.params;
    return (
      <div className="container">
        <OrdersRevisionTable onSelect={this.onSelect} selectedNumber={orderNumber} />
        {!!orderNumber &&
        <OrderRevisionTabPage orderNumber={orderNumber} onSelect={this.onSelect} />
                }
      </div>
    );
  }
}

export default withRouterMatch(MyOrdersRevisionPage);
