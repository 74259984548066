import React, { Component } from 'react';

import Dropdown from 'components/Dropdown';
import Select from 'components/Select';
import Icon from 'components/Icon';

const timeItems = [];
for (let i = 0; i < 24; i++) {
  const str = `${(i < 10 ? '0' : '') + i}:00`;
  timeItems.push(str);
}

class AvailabilityDropdown extends Component {
  constructor(props) {
    super(props);

    this.onChangeFrom = this.onChangeFrom.bind(this);
    this.onChangeTo = this.onChangeTo.bind(this);
  }

  onChangeFrom(value) {
    const { onChangeFields } = this.props;
    onChangeFields('available_from', value);
  }

  onChangeTo(value) {
    const { onChangeFields } = this.props;
    onChangeFields('available_to', value);
  }

  render() {
    const { valueFrom, valueTo, onChangeFields } = this.props;
    const isValid = !!valueFrom && !!valueTo;
    const errorClassName = !isValid ? 'text-danger' : null;
    const textFrom = valueFrom || 'From';
    const textTo = valueTo || 'To';
    return (
      <div>
        <span className={errorClassName}>Availability:</span>
        {!onChangeFields ?
          <span className="profile-noneditable-field">{`${textFrom} - ${textTo}`}</span> :
          <span>
            <Dropdown buttonClassName="lnk-dropdown" text={textFrom} closeAlways>
              <Select value={valueFrom} onChange={this.onChangeFrom} className="menu menu-selectable menu-selectable-overflow">
                {timeItems.map(item =>
                                  (<li key={item} value={item} className="menu-item">
                                    {item}
                                    <Icon className="svg-icon" iconName="check-empty" />
                                  </li>))}
              </Select>
            </Dropdown>
            {' - '}
            <Dropdown buttonClassName="lnk-dropdown" text={textTo} closeAlways>
              <Select value={valueTo} onChange={this.onChangeTo} className="menu menu-selectable menu-selectable-overflow">
                {timeItems.map(item =>
                                  (<li key={item} value={item} className="menu-item">
                                    {item}
                                    <Icon className="svg-icon" iconName="check-empty" />
                                  </li>))}
              </Select>
            </Dropdown>
          </span>
                }
      </div>
    );
  }
}
export default AvailabilityDropdown;
