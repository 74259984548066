import { urls } from './../../config';

import authFetch from './../../utils/authFetch';
import utils from '../../utils';

export const FEEDBACKS_REQUEST = 'FEEDBACKS_REQUEST';
export const FEEDBACKS_RECEIVE = 'FEEDBACKS_RECEIVE';
export const FEEDBACKS_ACTION_REQUEST = 'FEEDBACKS_ACTION_REQUEST';
export const FEEDBACKS_ACTION_RECEIVE = 'FEEDBACKS_ACTION_RECEIVE';
export const FEEDBACKS_SET_FILTER_REQUEST = 'FEEDBACKS_SET_FILTER_REQUEST';
export const FEEDBACKS_SET_FILTER_RECEIVE = 'FEEDBACKS_SET_FILTER_RECEIVE';

export const FEEDBACK_UPDATE_REQUEST = 'FEEDBACK_UPDATE_REQUEST';
export const FEEDBACK_UPDATE_RECEIVE = 'FEEDBACK_UPDATE_RECEIVE';

export const FEEDBACKS_NEXT_RECEIVE = 'FEEDBACKS_NEXT_RECEIVE';
export const FEEDBACKS_NEXT_REQUEST = 'FEEDBACKS_NEXT_REQUEST';

// ==== load feedbacks ====
const feedbacksRequest = () => ({
  type: FEEDBACKS_REQUEST,
});

const feedbacksReceive = (data, sorting, filter) => ({
  type: FEEDBACKS_RECEIVE,
  data,
  filter,
  sorting,
});

export const feedbacksFetch = (page, sorting, filter) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const isDe = user && user.profile_type === 'D';
    const params = utils.getTicketsQueryString(page, sorting, filter, isDe);
    dispatch(feedbacksRequest());
    return authFetch(urls.writer_feedbacks(params, user._id)).then((json) => {
      dispatch(feedbacksReceive(json, sorting, filter));
    });
  };
};

const feedbacksShouldFetch = (state) => {
  const { feedbacks } = state;
  if (feedbacks.isFetching) {
    return false;
  }
  return feedbacks.didInvalidate;
};

export const feedbacksFetchIfNeeded = (page, sorting, filter) => {
  return (dispatch, getState) => {
    if (feedbacksShouldFetch(getState())) {
      return dispatch(feedbacksFetch(page, sorting, filter));
    }
  };
};

const feedbacksSetFilterRequest = () => ({
  type: FEEDBACKS_SET_FILTER_REQUEST,
});

const feedbacksSetFilterReceive = (data, sorting, filter) => ({
  type: FEEDBACKS_SET_FILTER_RECEIVE,
  data,
  filter,
  sorting,
});
// ==== ./load feedbacks ====


export const feedbacksSetFilter = (page, sorting, filter) => {
  const params = utils.getTicketsQueryString(page, sorting, filter);
  return (dispatch, getState) => {
    const { user } = getState();
    dispatch(feedbacksSetFilterRequest());
    return authFetch(urls.writer_feedbacks(params, user._id)).then((json) => {
      dispatch(feedbacksSetFilterReceive(json, sorting, filter));
    });
  };
};

// ==== load next feedbacks ====
const feedbacksNextReceive = data => ({
  type: FEEDBACKS_NEXT_RECEIVE,
  data,
});

const feedbacksNextRequest = data => ({
  type: FEEDBACKS_NEXT_REQUEST,
  data,
});

const feedbacksNextFetch = (page, sorting, filter) => {
  const params = utils.getTicketsQueryString(page, sorting, filter);
  return (dispatch, getState) => {
    const { user } = getState();
    return authFetch(urls.writer_feedbacks(params, user._id)).then((json) => {
      dispatch(feedbacksNextReceive(json));
    });
  };
};

export const getFeedbacksNextAction = (page, sorting, filter) => {
  return (dispatch) => {
    dispatch(feedbacksNextRequest());
    return dispatch(feedbacksNextFetch(page, sorting, filter));
  };
};
// ==== ./load next feedbacks ====
