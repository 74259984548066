import React from 'react';

import classNames from 'utils/classNames';

import CommonLoading from 'components/CommonLoading';
import OrdersTableRow from 'components/OrdersTableRow';
import NoOrders from 'components/NoOrders';

const OrdersTableBody = ({
  profile, isSearch, isLoading, selectedNumber, onSelect, orderList, allOrders,
}) => {
  const { qc_full_group: qcFullGroup } = profile;
  const isAPlusPro = qcFullGroup === 'A+-pro';

  if (isLoading) return (<CommonLoading />);

  if (!orderList.length) return (<NoOrders isSearch={isSearch} option={!allOrders.length ? 'noFilters' : 'withFilters'} />);

  return orderList.map((order) => {
    const {
      reserved_by_me: reservedByMe, reserved, number, _id,
    } = order;

    const isReserved = reservedByMe || reserved;
    const isSelected = selectedNumber === number;
    const className = classNames('table-row', 'row', 'vertical', { active: isSelected });

    return (<OrdersTableRow
      isSelected={isSelected}
      className={className}
      key={_id}
      number={number}
      isReserved={isReserved}
      onClick={() => onSelect(number)}
      isAPlusPro={isAPlusPro}
    />);
  });
};

export default OrdersTableBody;
