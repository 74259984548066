import React, { Component } from 'react';
import moment from 'moment-timezone';
import debounce from 'debounce';

import Icon from 'components/Icon';
import Calendar from 'components/Calendar';
import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

import {
  ISSUE_TYPE,
  NOT_ORDER_RELATED,
  ORDER_RELATED,
  TICKETING_STATUS,
  INITIAL_PARAMS_URL,
  TICKET_STATUS_FILTER_MAPPING,
} from 'components/WriterTicketing/constants/writerTicketing';

const PAGE = 1;
const TICKETING_TYPE = [...Object.values(ISSUE_TYPE[NOT_ORDER_RELATED]), ...Object.values(ISSUE_TYPE[ORDER_RELATED])].join(',').split(',').filter((v, i, a) => a.indexOf(v) === i).sort();

class WriterTicketingFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      filter: {},
      sorting: {},
      showFilter: true,
      page: PAGE,
    };

    this.onSetFilter = this.onSetFilter.bind(this);
    this.onSetFilterSearch = this.onSetFilterSearch.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.debounceSave = debounce(this.debounceSave, 1000);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.filter && this.props.filter) {
      const { sorting, filter, page } = this.props;
      this.setState({
        sorting,
        filter,
        page,
      });
    }
  }

  onChangeSearch(e) {
    const value = e.target.value.trim();

    this.setState({
      search: value,
      showFilter: !value,
    });
    this.debounceSave(value, this.onSetFilterSearch);
  }

  debounceSave(value, method) {
    method(value);
  }

  onSetFilterSearch(value) {
    const { setFilter, filter, sorting } = this.props;
    const { page } = this.state;

    const newFilter = { ...INITIAL_PARAMS_URL.filter, parent_object_id: filter.parent_object_id, search: value };
    this.setState({
      search: value,
      filter: {},
    });
    setFilter(page, sorting, newFilter);
  }

  onSetFilter(value, name) {
    const { setFilter, filter, sorting } = this.props;
    this.dropdownRef && this.dropdownRef.setClose();
    let newValue = value;

    if (name === 'by_created_at') {
      newValue = moment(value).utc().format();
    }

    const newFilter = { ...filter, [name]: newValue };

    this.setState({
      filter: newFilter,
      [name]: newValue,
    });
    setFilter(1, sorting, newFilter);
  }

  clearSearch() {
    const {
      setFilter, sorting, filter, page,
    } = this.props;
    const newFilter = { ...filter, search: '' };
    this.setState({
      filter: {},
      search: '',
      showFilter: true,
    });
    setFilter(page, sorting, newFilter);
  }

  resetFilter() {
    const { setFilter, sorting, filter } = this.props;
    const newFilter = { ...INITIAL_PARAMS_URL.filter, parent_object_id: filter.parent_object_id };
    this.setState({
      filter: {},
      search: '',
    });
    setFilter(1, sorting, newFilter);
  }

  render() {
    const { filter, search, showFilter } = this.state;
    const date = filter.by_created_at ? moment(filter.by_created_at).format('MMM D, YYYY') : 'Select creation date';

    return (
      <aside className="left-sidebar content-item">
        <div className="search-wrap">
          <a onClick={() => this.onSetFilter(search, 'search')}>
            <Icon className="svg-icon" iconName="search" />
          </a>
          <input className="input-text" type="text" placeholder="Find issue by order number" onChange={this.onChangeSearch} value={search} name="search" />
          <button className="btn btn-close" type="button" onClick={this.clearSearch}>
            <Icon className="svg-icon" iconName="close" />
          </button>
        </div>
        {showFilter &&
          <div>
            <h2 className="title-filter">Filter Reports</h2>

            <div className="filter-wrap filter-period">
              <h3 className="filter-name">By creation date</h3>
              <Dropdown text={date} hideArrow icon="calendar" ref={ref => this.dropdownRef = ref}>
                <Calendar onSubmit={this.onSetFilter} chosenDate={moment(filter.by_created_at)} hideTime name="by_created_at" />
              </Dropdown>
            </div>

            <div className="filter-wrap">
              <h3 className="filter-name">By status</h3>
              <Dropdown text={TICKET_STATUS_FILTER_MAPPING[filter.by_issue_states] || 'Show all'} closeAlways>
                <Select className="menu menu-selectable" onChange={this.onSetFilter}>
                  {TICKETING_STATUS.map(item =>
                    <li key={item.name} name="by_issue_states" value={[item.status]} className={`menu-item ${(filter.by_issue_states || []).indexOf(item.status) > -1 ? 'active' : ''}`}>{item.name}</li>)}
                </Select>
              </Dropdown>
            </div>

            <div className="filter-wrap">
              <h3 className="filter-name">Issue type</h3>
              <Dropdown text={filter.by_subtype || 'Show all'} closeAlways>
                <Select className="menu menu-selectable" onChange={this.onSetFilter}>
                  {TICKETING_TYPE.map((item, idx) =>
                    <li key={`${idx}_${item}`} name="by_subtype" value={[item]} className={`menu-item ${(filter.by_subtype || []).indexOf(item) > -1 ? 'active' : ''}`}>{item}</li>)}
                </Select>
              </Dropdown>
            </div>

            <div className="reset-filter">
              <a onClick={this.resetFilter}><Icon className="svg-icon" iconName="close" /> Reset all filters</a>
            </div>
          </div>
        }
      </aside>
    );
  }
}

export default WriterTicketingFilter;
