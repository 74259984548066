import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getProfile } from 'store/user/selectors';

import { userActiveWarningFetchIfNeeded } from 'store/user/actions';

import { Notices, GroupLevel, UserAvg, Bage, AdditionalInfo } from './components';

const WriterGroup = () => {
  const user = useSelector(getProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;

    const { _id: profileId } = user;
    dispatch(userActiveWarningFetchIfNeeded(profileId));
  }, []);

  if (!user) return null;

  return (
    <div className="right-header">
      <Notices user={user} />
      <div className="writer-level">
        <GroupLevel user={user} />
        <UserAvg user={user} />
        <Bage user={user} />
        <AdditionalInfo user={user} />
      </div>
    </div>
  );
};

export default WriterGroup;
