import {
    ORDER_SINGLE_REQUEST,
    ORDER_SINGLE_RECEIVE,
    ORDER_SINGLE_ERROR,
} from './actions';

const orderSingle = (state = {
    isFetching: false,
    number: null,
    error: false,
}, action) => {
    switch (action.type) {
        case ORDER_SINGLE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: false
            });
        case ORDER_SINGLE_RECEIVE:
            return Object.assign({}, state, {
                isFetching: false,
                number: action.number
            });
        case ORDER_SINGLE_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        default:
            return state;
    }
};

export default orderSingle;
