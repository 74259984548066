const groupsWriters = {
  'a+': {
    default: 'A+-pro',
    list: [
      'A+-pro',
      'A+-standard',
      'A+-outsider',
    ],
    benefits: [
      '<b>+35% to the base order price</b>',
      'priority time to accept VIP orders',
      'additional seasonal and loyalty bonuses',
      '<b>a 10% bonus</b> for every 5 star client score',
    ],
    score: '4.7 - 5',
  },
  a: {
    default: 'A-pro',
    list: [
      'A-pro',
      'A-standard',
      'A-outsider',
    ],
    benefits: [
      '<b>+25% to the base order price</b>',
      'priority time to accept VIP orders',
      'additional seasonal and loyalty bonuses',
      '<b>a 10% bonus</b> for every 5 star client score',
    ],
    score: '4.5 - 4.6',
  },
  b: {
    default: 'B-pro',
    list: [
      'B-pro',
      'B-standard',
      'B-outsider',
    ],
    benefits: [
      'additional seasonal and loyalty bonuses',
      '<b>a 10% bonus</b> for every 5 star client score',
    ],
    score: '4 - 4.4',
  },
  c: {
    default: 'C-standard',
    list: [
      'C-standard',
      'C-outsider',
    ],
    benefits: [
      'additional seasonal and loyalty bonuses',
      '<b>a 10% bonus</b> for every 5 star client score ',
    ],
    score: '3.5 - 3.9',
  },
  d: {
    default: 'D-standard',
    list: [
      'D-standard',
      'D-outsider',
    ],
    benefits: [
      'additional seasonal and loyalty bonuses',
      '<b>a 10% bonus</b> for every 5 star client score ',
    ],
    score: '3 - 3.4',
  },
  f: {
    default: 'F',
    list: [
      'F',
    ],
    benefits: [
      'restricts your access to available orders',
      'sets your account under review for termination',
    ],
    score: '2.9 and less',
  },
};

const groupLevelWriter = {
  'A+-pro': {
    lineThrough: [],
    descriptionTitle: 'You are doing simply amazing!',
    description: 'Clients are very happy with your work, and you do not have active warnings. Thank you for your impressive quality, and keep going!',
    bage: '+35% to the base order price',
  },
  'A+-standard': {
    lineThrough: [0],
    descriptionTitle: 'Doing not that great!',
    description: 'You have a few active warnings. Please, fix those ASAP to keep getting +35% to your base order price!',
  },
  'A+-outsider': {
    lineThrough: [0, 1, 2],
    descriptionTitle: 'Doing not good at all!',
    description: 'You have to many active warnings. Please, fix those ASAP or we will consider terminating your account!',
  },
  'A-pro': {
    lineThrough: [],
    descriptionTitle: 'You are doing just great!',
    description: 'We are really impressed with your progress. The next step is to join the A+ level and earning even more!',
    bage: '+25% to the base order price',
  },
  'A-standard': {
    lineThrough: [0],
    descriptionTitle: 'Doing not that great!',
    description: 'You have a few active warnings. Please, fix those ASAP to keep getting +25% to your base order price!',
  },
  'A-outsider': {
    lineThrough: [0, 1, 2],
    descriptionTitle: 'Doing not good at all!',
    description: 'You have to many active warnings. Please, fix those ASAP or we will consider terminating your account!',
  },
  'B-pro': {
    lineThrough: [],
    descriptionTitle: 'Doing not that great!',
    description: 'Keep on doing your best, avoid warnings and become an A+ or A-level freelancer to start earning more!',
  },
  'B-standard': {
    lineThrough: [0],
    descriptionTitle: 'Doing not that great!',
    description: 'Keep on doing your best, avoid warnings and become an A+, or A-level freelancer to start earning more!',
  },
  'B-outsider': {
    lineThrough: [0, 1, 2],
    descriptionTitle: 'Doing not good at all!',
    description: 'You have too many active warnings. Please, fix those ASAP or we will consider terminating your account.',
  },
  'C-standard': {
    lineThrough: [],
    descriptionTitle: 'Doing not that great!',
    description: 'Clients are not very happy with your work. Keep on doing your best, avoid warnings and become an A+ or A-level freelancer to start earning more!',
  },
  'C-outsider': {
    lineThrough: [0],
    descriptionTitle: 'Doing not good at all!',
    description: 'You have too many active warnings. Please, fix those ASAP or we will consider terminating your account.',
  },
  'D-standard': {
    lineThrough: [],
    descriptionTitle: 'Doing not that great!',
    description: 'Clients are not very happy with your work. Keep on doing your best, avoid warnings and become an A+ or A-level freelancer to start earning more!',
  },
  'D-outsider': {
    lineThrough: [0],
    descriptionTitle: 'Doing not good at all!',
    description: 'You have too many active warnings. Please, fix those ASAP or we will consider terminating your account.',
  },
  F: {
    lineThrough: [],
    descriptionTitle: 'Clients are not happy with your work at all!',
    description: 'Your account is being reviewed for termination. Please, contact your Care Manager if you have any questions/ concerns.',
  },
};

// 'A-pro',
//   'A-standard',
//   'A-outsider',
//   'B-pro',
//   'B-standard',
//   'B-outsider',
//   'C-standard',
//   'C-outsider',
//   'D-standard',
//   'D-outsider',
//   'F',
// 'newbie-standard',
// 'newbie-outsider',
// 'beginner-standard',
// 'beginner-outsider',

export { groupsWriters, groupLevelWriter };
