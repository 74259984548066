import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withRouterMatch } from 'hocs';

import { getUpdateListSorted } from './../store/updates/selectors';
import { updatesFetchIfNeeded, updatesSetViewedFetchIfNeeded } from './../store/updates/actions';
import Emoji from './../components/Emoji';
import formatTime from './../utils/formatTime';


class InboxUpdatesDetailPage extends Component {

    constructor(props) {
        super(props);

        this.onSetRateClick = this.onSetRateClick.bind(this);
    }

    onSetRateClick(rate) {
        const { SetRateClick } = this.props;
        SetRateClick(rate)
    }

    componentDidMount() {
        const { loadUpdates, SetViewedUpdate } = this.props;
        loadUpdates()
        this.timeout = setTimeout(() => SetViewedUpdate(), 2000)
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    renderRateControls() {
        const { update } = this.props;

        if (update.your_rate === undefined)
            return (
                <div className="voit-box">
                    <span onClick={() => this.onSetRateClick(1)}><Emoji id="smile" /></span>
                    <span onClick={() => this.onSetRateClick(0)}><Emoji id="noWords" /></span>
                    <span onClick={() => this.onSetRateClick(-1)}><Emoji id="sad" /></span>
                </div>
            )

        return (
            <div className="voit-box">
                <span className="badge badge-answer">Thanks for your honest answer</span>
            </div>
        )
    }

    render() {
        const { isLoading, update, nextUpdateId } = this.props;

        if (isLoading || !update) {
            return (<div className="preloading preloading-box"><p>Loading</p></div>);
        }

        return (
            <div className="container container-border content-item">

                <div className="row">
                    <div className="col-2">
                        <Link to="/inbox/updates" className="btn btn-back">Back to updates</Link>
                    </div>

                    <div className="col-6">

                        <article className="article-item-container">

                            <div className="article-item-info">
                                {update.status === 'unread' &&
                                    <span className="badge badge-new">new</span>
                                }
                                <span className="article-item-author">{update.author} <span className="dotted-devider">·</span> {formatTime(update.created_at, 'd')}</span>
                            </div>

                            <h1 className="title">{update.subject}</h1>

                            {!!update.thumbnail &&
                                <img className="article-item-image" src={update.thumbnail} alt="" />
                            }
                            <div className="article-item-text">
                                {update.message}
                            </div>

                        </article>

                        <div className="article-item-footer">
                            <h2 className="text-success sub-title">What do you think about that update?</h2>
                            <p className="notes">Tell us the truth! All answers are anonymous.</p>
                            {this.renderRateControls()}
                        </div>

                    </div>

                    <div className="col-2 ta-right">
                        {!!nextUpdateId &&
                            <Link className="btn btn-back" to={`/inbox/updates/${nextUpdateId}`}>Show next update</Link>
                        }
                    </div>
                </div>
            </div>

        );
    }
};

const mapStateToProps = (state, ownProps) => {
    const isLoading = state.updates.isFetching;
    const updateId = ownProps.match.params.id;
    const update = state.updates.items[updateId]
    const updateIds = getUpdateListSorted(state).map(item => item._id)
    const nextUpdateId = updateIds.indexOf(updateId) < updateIds.length ? updateIds[updateIds.indexOf(updateId) + 1] : null
    return { update, isLoading, nextUpdateId }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadUpdates: () => dispatch(updatesFetchIfNeeded()),
        SetRateClick: (rate) => dispatch(updatesSetViewedFetchIfNeeded(ownProps.match.params.id, rate)),
        SetViewedUpdate: (rate) => dispatch(updatesSetViewedFetchIfNeeded(ownProps.match.params.id)),
    };
};

export default withRouterMatch(connect(mapStateToProps, mapDispatchToProps)(InboxUpdatesDetailPage));
