import { getToken } from './tokenStorage';

const cropUnsafeSymbols = (string) => {
  const regexp = /[^0-9a-zA-Z!\-_.*'()\ ]/g;
  return string.replace(regexp, '').replace(/ +/g, ' ');
};

const recursiveDecodeURIComponent = (uriComponent) => {
  try {
    const decodedURIComponent = decodeURIComponent(uriComponent);
    if (decodedURIComponent === uriComponent) {
      return cropUnsafeSymbols(decodedURIComponent);
    }
    return recursiveDecodeURIComponent(decodedURIComponent);
  } catch (e) {
    return cropUnsafeSymbols(uriComponent);
  }
};

const uploadFetch = (href, formData, onProgress) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', href);
        xhr.withCredentials = true;
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        const token = getToken();
        if (token) {
            xhr.setRequestHeader('Authorization', getToken());
        }
        xhr.upload.addEventListener('progress', e => {
            if (e.lengthComputable) {
                const percent = Math.round(100 * e.loaded / e.total);
                onProgress(percent);
            }
        });
        xhr.addEventListener('load', () => { resolve(JSON.parse(xhr.response)) });
        xhr.addEventListener('error', () => { reject() });
        xhr.send(formData);
    });
};

export default {
  uploadFetch,
  cropUnsafeSymbols,
  recursiveDecodeURIComponent,
};
