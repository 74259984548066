import React from 'react';

import { phoneOsList, EmploymentStatus, experienceList } from 'constants';


import { ProfileInput, ProfileDropdown, AvailabilityDropdown } from '../Controls';

const AdditionalInfo = ({
  data, onChangeFields, isStem, isTutor,
}) => {
  const {
    available_from, available_to,
    profile_cellphoneos, profile_employment,
    profile_experience, discord_id
  } = data;
  return (
    <div className="row person-common-info">
      <div className="col-2 person-common-info-title">Additional info</div>
      <div className="col-4">
        <ul>
          <li className="person-info__item">
            <AvailabilityDropdown
              valueFrom={available_from}
              valueTo={available_to}
              onChangeFields={!isStem && onChangeFields}
            />
          </li>
          <li className="person-info__item">
            <ProfileDropdown
              value={profile_cellphoneos}
              fieldName="profile_cellphoneos"
              list={phoneOsList}
              onChangeFields={!isStem && onChangeFields}
              title="Cell phone OS"
            />
          </li>
          <li className="person-info__item">
            <ProfileDropdown
              value={profile_employment}
              fieldName="profile_employment"
              list={EmploymentStatus}
              onChangeFields={!isStem && onChangeFields}
              title="Employment status"
            />
          </li>
          <li className="person-info__item">
            <ProfileDropdown
              value={profile_experience}
              fieldName="profile_experience"
              list={experienceList}
              onChangeFields={!isStem && onChangeFields}
              title="Experience"
            />
          </li>
          {
              isTutor && (
                <li className="person-info__item">
                  <ProfileInput
                    value={discord_id}
                    fieldName="discord_id"
                    onChangeFields={!isStem && onChangeFields}
                    title="Discord ID"
                    placeholder="Enter your Discord ID"
                    withValidation={false}
                  />
                </li>
              )
            }
        </ul>
      </div>
    </div>
  );
};

export default AdditionalInfo;
