/* eslint-disable consistent-return */
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProfile, getAgreementInfo } from 'store/user/selectors';
import { closeModalComponent, addModalComponent } from 'store/modals/actions';
import { userSetFieldFetch } from 'store/user/actions';

import InfoComponent from 'components/agreement/InfoModal/InfoComponent';

import isWriterFromPK from 'utils/isWriterFromPK';

import modalList from 'constants/modalList';

import Body from './Body';

const WisePKIntrusiveModal = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile) || {};
  const showAgreement = useSelector(getAgreementInfo);

  const isCurrentCall = useRef(false);

  const dataModal = modalList.WisePKIntrusiveModal;

  const prevWiseValue = useMemo(() => {
    if (!profile) return;

    return (profile.pay_methods_list.Wise || {}).value;
  }, [profile]);

  const onConfirmCurrency = (currencyValue) => {
    const data = {
      name: 'Wise',
      value: prevWiseValue,
      info: {
        currency: currencyValue,
      },
    };
    isCurrentCall.current = true;
    dispatch(userSetFieldFetch('set_pay_method', data));
    dispatch(closeModalComponent(dataModal.name));
  };

  useEffect(() => {
    if (!profile || !profile.pay_method) return;
    if (showAgreement) return;

    if (!isWriterFromPK(profile)) return;

    if (profile.pay_method !== 'Wise') return;

    if (profile.pay_methods_list.Wise?.info?.currency) return;

    if (isCurrentCall.current) {
      isCurrentCall.current = false;
      return;
    }

    const component = (<InfoComponent
      modalName={dataModal.name}
      customBodyComponent={<Body onConfirmCurrency={onConfirmCurrency} />}
      noBtn
    />);
    dispatch(addModalComponent({ component, ...dataModal }));
  }, [showAgreement, profile]);

  return null;
};

export default WisePKIntrusiveModal;
