import React, { useMemo, useCallback } from 'react';

import classNames from 'utils/classNames';

import './index.scss';

const SubCategory = ({ category, openedCategories, toggleCategory, subcategoryFiles, renderFileRow }) => {
  const { id, name } = category;
  const isCategoryOpen = openedCategories.includes(id);
  const subcategoryFilesLength = useMemo(() => subcategoryFiles.length, [subcategoryFiles]);

  const toggleCategoryHandler = useCallback(
    () => {
      toggleCategory(id);
    },
    [toggleCategory],
  );

  return (
    <div className="subcategory-item">
      <div className="subcategory-item__header row vertical space-between" onClick={toggleCategoryHandler}>
        {`${name} (${subcategoryFilesLength})`}
        <i className={classNames('arrow-down', { active: isCategoryOpen })} />
      </div>
      {
        isCategoryOpen && subcategoryFiles.map(renderFileRow)
      }
    </div>
  );
};

export default SubCategory;
