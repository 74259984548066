import React, { useMemo } from 'react';
import classnames from 'classnames';

import { useDispatch } from 'react-redux';

import { addVerifyModal } from 'store/verifyModal/actions';

import formatTime from 'utils/formatTime';

import Icon from 'components/Icon';

import { PATH_TICKETS_ORDER_FEEDBACK, PATH_ORDER,
  TAGS_BAD, TAGS_GOOD,
  TAGS_RESOLVED, PATH_TICKETS_REPORTED_ISSUES,
  PATH_SCORES, PATH_WARNINGS, PATH_TICKETS } from '../constants';

const NoticesContent = ({ viewNotices, user }) => {
  const { last_veriff_session_object: veriffSession } = user;

  const dispatch = useDispatch();

  const classLiIconsName = useMemo(() => [...TAGS_BAD, ...TAGS_GOOD, ...TAGS_RESOLVED].reduce((prev, next) => {
    if (TAGS_BAD.includes(next)) {
      prev[next] = {
        liClass: 'warning-danger',
        iconName: 'close-fill-orange',
        type: 'bad',
      };
    }
    if (TAGS_GOOD.includes(next)) {
      prev[next] = {
        liClass: 'warning-success',
        iconName: 'close-fill-green',
        type: 'good',
      };
    }
    if (TAGS_RESOLVED.includes(next)) {
      prev[next] = {
        liClass: 'warning-success',
        iconName: 'close-fill-grey',
        type: 'resolved',
      };
    }
    return prev;
  }, {}), [TAGS_BAD, TAGS_GOOD, TAGS_RESOLVED]);


  const renderNoticeContent = (notice, type) => {
    const {
      event_tag: eventTag, comment, issue_type: issueType, issue_id: issueId,
      order_info: orderInfo, data, solved_tag: solvedTag,
    } = notice;

    const { number: orderNumber = null, _id: orderId } = orderInfo || {};
    let WrappedCommentComponent = null;

    if (orderNumber && orderId) {
      WrappedCommentComponent = comment.replaceAll(orderNumber, `<a target="_blank" rel="noreferrer" href="${PATH_ORDER}/${orderId}" class="order_link_notice">${orderNumber}</a>`);
    }

    const { rdd } = data || {};

    let feedbackPath = PATH_TICKETS_ORDER_FEEDBACK;
    let feedbackLinkTarget = '_self';

    if (solvedTag && issueType === 'good' && orderId) {
      feedbackPath = `${PATH_ORDER}/${orderId}`;
      feedbackLinkTarget = '_blank';
    }

    if (eventTag === 'notify_writer_order_feedback_cancelled') {
      return (<a href={feedbackPath} target={feedbackLinkTarget}><span dangerouslySetInnerHTML={{ __html: WrappedCommentComponent }} /></a>);
    }

    if (issueType === 'Review penalties') {
      return (<a href={`${PATH_TICKETS_REPORTED_ISSUES}#ticketId=${issueId}`}>Your issue Rejected order was checked</a>);
    }

    if (eventTag === 'notify_veriff_declined') {
      return (<b className="ml8">Hey! Unfortunately, your verification was declined by the decision of Veriff™</b>);
    }

    if (eventTag === 'notify_veriff_resubmission') {
      return (
        <b className="ml8">
          <a
            onClick={() => {
                    const { active_status, crm_status, url } = veriffSession;
                    if (active_status === 'declined' && crm_status === 'Resubmission') {
                      dispatch(addVerifyModal());
                    } else {
                      window.open(url, '_blank').focus();
                    }
                  }}
            className="warning-danger__error-text"
          >
            {`Hey! There was an issue during your verification process: ${comment || ''} Please try again.`}
          </a>
        </b>
      );
    }

    if (eventTag === 'notify_rdd_changed') {
      return (<a href={`${PATH_ORDER}/${orderId}`} target="_blank" rel="noreferrer">Revision DDL in order <a target="_blank" rel="noreferrer" className="order_link_notice" href={`${PATH_ORDER}/${orderId}`}>{orderNumber}</a> was updated. New DDL is <strong>{formatTime(rdd, 'dt')}.</strong> </a>);
    }

    if (eventTag === 'notify_revision_accept') {
      return (<a href={`${PATH_ORDER}/${orderId}`} target="_blank" rel="noreferrer">You have a new revision request in order <strong><a className="order_link_notice" target="_blank" rel="noreferrer" href={`${PATH_ORDER}/${orderId}`}>{orderNumber}</a></strong> (DDL is {formatTime(rdd, 'dt')}). Please, review revision comments and start working on revision ASAP.</a>);
    }

    if (issueType && type === 'bad') {
      return (<a href={`${PATH_TICKETS_REPORTED_ISSUES}#ticketId=${issueId}`}>Your issue {issueType} <b>has been cancelled</b></a>);
    }

    if (eventTag && eventTag === 'notify_mark_bad' && orderNumber) {
      return (<a href={`${PATH_SCORES}`} target="_blank" rel="noreferrer">You’ve received a bad <b>Client score</b> {orderNumber ? <span> in <a className="order_link_notice" target="_blank" rel="noreferrer" href={`${PATH_ORDER}/${orderId}`}>{orderNumber}</a></span> : ''}</a>);
    }

    if (eventTag === 'notify_group_down') {
      return (<a href={PATH_SCORES}>You've joined <b>{(data || {}).qc_group} Level</b> quality group.</a>);
    }

    if (type === 'bad' && orderNumber) {
      return (<a href={PATH_WARNINGS} target="_blank" rel="noreferrer">You've received <b>a warning {comment && `${comment}`}</b>
        {orderNumber ? <span> in <a className="order_link_notice" target="_blank" rel="noreferrer" href={`${PATH_ORDER}/${orderId}`}>{orderNumber}</a></span> : ''}</a>);
    }

    if (eventTag === 'notify_mark_update' || eventTag === 'notify_mark_hide') {
      return (<a href={PATH_SCORES}><span dangerouslySetInnerHTML={{ __html: WrappedCommentComponent }} /></a>);
    }

    if (eventTag === 'notify_writer_order_feedback_resolved') {
      return (<a href={feedbackPath} target={feedbackLinkTarget}><span dangerouslySetInnerHTML={{ __html: WrappedCommentComponent }} /></a>);
    }

    if (eventTag === 'notify_veriff_approved') {
      return (<b className="ml8">Hey! Your verification was successfully completed. You can now proceed with withdrawal requests</b>);
    }

    if (type === 'resolved') {
      return (<a href={`${PATH_TICKETS}#ticketId=${issueId}`}>Your issue <strong>{issueType}</strong> has been reviewed.</a>);
    }

    if (eventTag === 'notify_group_up') {
      return (<a href={PATH_SCORES}>Сongrats, you've improved your average client score! Now you are in <b>{comment}</b> quality group.</a>);
    }

    if (type === 'good') {
      return (<a href={PATH_SCORES} target="_blank" rel="noreferrer">You've received a <b>client score</b> {orderNumber ? <span> in <a className="order_link_notice" target="_blank" rel="noreferrer" href={`${PATH_ORDER}/${orderId}`}>{orderNumber}</a></span> : ''}</a>);
    }

    return (<b className="ml8"><span dangerouslySetInnerHTML={{ __html: WrappedCommentComponent }} /></b>);
  };

  return (
    <ul className="last-warning">
      {viewNotices.reduce((notices, notice) => {
        const {
          event_tag: eventTag,
          _id: noticeId,
          status = 0,
          created_at: createdAt = '',
        } = notice;

        const { liClass, iconName, type } = classLiIconsName[eventTag] || {};

        if (!liClass || !iconName) return notices;

        notices.push(<li
          key={noticeId}
          className={classnames(
            liClass,
            { new_notice: status === 0 },
            )}
        >
          <span className="icon-wrap">
            <Icon className="svg-icon" iconName={iconName} />
          </span>
          <div>
            {renderNoticeContent(notice, type)}
            <div className="mt4 notice_date">{formatTime(createdAt, 'dt')}</div>
          </div>
        </li>);

        return notices;
      }, [])}
    </ul>
  );
};

export default NoticesContent;
