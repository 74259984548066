import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';

import storage from './../../utils/localStorageHandler';
import { writerTags } from '../../constants/index';

import {
  ORDERS_FIND_REQUEST,
  ORDERS_FIND_RECEIVE,

  ORDERS_FIND_SET_PRICE_BOUNDS,

  ORDERS_FIND_SET_SORTING,
  ORDERS_FIND_SET_FILTER_TIME,
  ORDERS_FIND_SET_FILTER_STEP,
  ORDERS_FIND_SET_FILTER_RECOMMENDED,
  ORDERS_FIND_SET_FILTER_HOT,
  ORDERS_FIND_SET_FILTER_MY,
  ORDERS_FIND_SET_FILTER_SEARCH,
  ORDERS_FIND_SET_FILTER_SUBJECT,
  ORDERS_FIND_SET_FILTER_SUBJECT_GROUP,
  ORDERS_FIND_SET_FILTER_SUBJECT_GROUP_WITH_SUBJECTS,
  ORDERS_FIND_SET_FILTER_TYPE,
  ORDERS_FIND_SET_FILTER_ONLINE,
  ORDERS_FIND_SET_FILTER_STATUSES,
  ORDERS_FIND_SET_FILTER_RESERVER_BY_OTHER,
  RESET_ALL_FILTERS,

  ORDERS_TYPE_ALL,
  ORDERS_TYPE_FAVORITE,
  ORDERS_TYPE_RESERVED,

  ORDERS_FIND_UPDATE,

  ORDERS_HIDE_FROM_TABLE,

} from './actions';

import { ORDERS_HIDE_IN_LISTS } from './../orders/actions';

const sorting = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_FIND_SET_SORTING:
      const newSort = Object.assign({}, state, {
        field: action.value,
        isAsc: state.field === action.value ? !state.isAsc : true,
      });
      storage.set('sort', newSort)
      return newSort;
    default:
      return state;
  }
};

const filter = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_FIND_SET_FILTER_TIME:
      return Object.assign({}, state, {
        time: action.value,
      });
    case ORDERS_FIND_SET_FILTER_STEP:
      return Object.assign({}, state, {
        steps: action.value,
      });
    case ORDERS_FIND_SET_FILTER_RECOMMENDED:
      return Object.assign({}, state, {
        recommended: action.value,
      });
    case ORDERS_FIND_SET_FILTER_HOT:
      return Object.assign({}, state, {
        hot: action.value,
      });
    case ORDERS_FIND_SET_FILTER_MY:
      return Object.assign({}, state, {
        my: action.value,
      });
    case ORDERS_FIND_SET_FILTER_SEARCH:
      return Object.assign({}, state, {
        search: action.value,
      });
    case ORDERS_FIND_SET_FILTER_STATUSES:
      if (action.set) {
        return Object.assign({}, state, {
          statuses: [...state.statuses, action.value],
        });
      }
      if (action.value === writerTags.IS_SUGGESTED) {
        return Object.assign({}, state, {
          statuses: state.statuses.filter(item => action.value !== item && action.value !== writerTags.IS_PRIORITIZED),
        });
      }
      return Object.assign({}, state, {
        statuses: state.statuses.filter(item => action.value !== item),
      });
    case ORDERS_FIND_SET_FILTER_SUBJECT:
      return Object.assign({}, state, {
        subject: [...action.value],
      });
    case ORDERS_FIND_SET_FILTER_SUBJECT_GROUP:
      return Object.assign({}, state, {
        subject_group: [...action.value],
      });
    case ORDERS_FIND_SET_FILTER_SUBJECT_GROUP_WITH_SUBJECTS:
      return Object.assign({}, state, {
        subject_group: [...action.value.subjectGroups],
        subject: [...action.value.subjects],
      });
    case ORDERS_FIND_SET_FILTER_TYPE:
      return Object.assign({}, state, {
        type: [...action.value],
      });
    case ORDERS_FIND_SET_FILTER_ONLINE:
      return Object.assign({}, state, {
        show_online: action.value,
      });
    case ORDERS_FIND_SET_FILTER_RESERVER_BY_OTHER:
      return Object.assign({}, state, {
        show_reserved: action.value,
      });
    case ORDERS_FIND_SET_PRICE_BOUNDS:
      return Object.assign({}, state, {
        priceMax: (!state.priceMax || state.priceMax > action.max) ? action.max : state.priceMax,
        priceBoundMax: action.max,
        priceStep: action.step,
      });
    default:
      return state;
  }
};

const localSt = storage.get('filter') || {};
let localStFilter = {};
if (isEmpty(localSt)) {
  localStFilter = {
    time: [],
    subject: [],
    subject_group: [],
    show_online: true,
    show_reserved: false,
    type: [],
    steps: [],
  };
} else {
  localStFilter = {
    time: localSt.times,
    subject: localSt.subjects,
    subject_group: localSt.subjectGroups,
    show_online: !!localSt.showOnline,
    show_reserved: !!localSt.showReserved,
    type: localSt.types,
    steps: localSt.steps,
  };
}

const defaultFilter = {
  ...localStFilter,
  statuses: [],
  recommended: false,
  hot: false,
  my: false,
  priceMin: 0,
  priceMax: 0,
  priceStep: 0,
  search: '',
};

const localStSorting = storage.get('sort') || { field: '', isAsc: true };

export const ordersFind = (state = {
  isFetching: false,
  didInvalidate: true,
  numbers: [],
  sorting: localStSorting,
  filter: {
    [ORDERS_TYPE_ALL]: defaultFilter,
    [ORDERS_TYPE_FAVORITE]: defaultFilter,
    [ORDERS_TYPE_RESERVED]: defaultFilter,
  },
}, action) => {
  switch (action.type) {
    case ORDERS_HIDE_IN_LISTS:
    case ORDERS_HIDE_FROM_TABLE:
      if (Array.isArray(action.number)) {
        return Object.assign({}, state, {
          numbers: state.numbers.filter(number => !action.number.includes(number)),
        });
      }
      return Object.assign({}, state, {
        numbers: state.numbers.filter(number => number !== action.number),
      });
    case ORDERS_FIND_UPDATE:
      return Object.assign({}, state, {
        numbers: [...new Set([...state.numbers, ...action.numbers])],
      });
    case ORDERS_FIND_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case ORDERS_FIND_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        updatedAt: moment(),
        numbers: [...new Set([...state.numbers, ...action.numbers])],
      });
    case ORDERS_FIND_SET_SORTING:
      return Object.assign({}, state, {
        sorting: sorting(state.sorting, action),
      });
    case ORDERS_FIND_SET_FILTER_TIME:
    case ORDERS_FIND_SET_FILTER_STEP:
    case ORDERS_FIND_SET_FILTER_RECOMMENDED:
    case ORDERS_FIND_SET_FILTER_HOT:
    case ORDERS_FIND_SET_FILTER_MY:
    case ORDERS_FIND_SET_FILTER_SEARCH:
    case ORDERS_FIND_SET_FILTER_SUBJECT:
    case ORDERS_FIND_SET_FILTER_SUBJECT_GROUP:
    case ORDERS_FIND_SET_FILTER_SUBJECT_GROUP_WITH_SUBJECTS:
    case ORDERS_FIND_SET_FILTER_STATUSES:
    case ORDERS_FIND_SET_FILTER_ONLINE:
    case ORDERS_FIND_SET_FILTER_RESERVER_BY_OTHER:
    case ORDERS_FIND_SET_FILTER_TYPE:
      return Object.assign({}, state, {
        filter: Object.assign({}, state.filter, {
          [action.typeId]: filter(state.filter[action.typeId], action),
        }),
      });
    case ORDERS_FIND_SET_PRICE_BOUNDS:
      return Object.assign({}, state, {
        filter: Object.assign({}, state.filter, {
          [ORDERS_TYPE_ALL]: filter(state.filter[ORDERS_TYPE_ALL], action),
          [ORDERS_TYPE_FAVORITE]: filter(state.filter[ORDERS_TYPE_FAVORITE], action),
          [ORDERS_TYPE_RESERVED]: filter(state.filter[ORDERS_TYPE_RESERVED], action),
        }),
      });
    case RESET_ALL_FILTERS:
      return Object.assign({}, state, {
        filter: Object.assign({}, state.filter, {
          [action.typeId]: {
            ...state.filter[action.typeId], ...{
              subject: [],
              type: [],
              statuses: [],
              subject_group: [],
              time: [],
              show_online: true,
              show_reserved: false,
              steps: [],
            },
          },
        }),
      });
    default:
      return state;
  }
};

export default ordersFind;
