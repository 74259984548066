import {
  ORDERS_DETAILS_INVALIDATE,

  ORDERS_FILES_REQUEST,
  ORDERS_FILES_RECEIVE,
  ORDERS_FILES_UPLOAD_REQUEST,
  ORDERS_FILES_UPLOAD_PROGRESS,
  ORDERS_FILES_UPLOAD_COMPLETE,
  ORDERS_FILES_UPLOAD_RECEIVE,
  ORDERS_FILES_UPLOAD_ERROR,
  ORDERS_FILES_REMOVE_REQUEST,
  ORDERS_FILES_REMOVE_RECEIVE,
} from '../constants';

import file from './file';

const files = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_DETAILS_INVALIDATE:
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    case ORDERS_FILES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case ORDERS_FILES_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.files,
        webhook: action.webhook,
      });
    case ORDERS_FILES_UPLOAD_REQUEST:
    case ORDERS_FILES_UPLOAD_PROGRESS:
    case ORDERS_FILES_UPLOAD_COMPLETE:
    case ORDERS_FILES_REMOVE_REQUEST:
    case ORDERS_FILES_UPLOAD_ERROR:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.fileId]: file(state.items[action.fileId], action),
        }),
      });
    case ORDERS_FILES_UPLOAD_RECEIVE: {
      const newItems = Object.assign({}, state.items, {
        [action.data._id]: action.data,
      });
      delete newItems[action.fileId];
      return Object.assign({}, state, {
        items: newItems,
      });
    }
    case ORDERS_FILES_REMOVE_RECEIVE: {
      const newItemsDeleted = Object.assign({}, state.items);
      delete newItemsDeleted[action.fileId];
      return Object.assign({}, state, {
        items: newItemsDeleted,
      });
    }
    default:
      return state;
  }
};

export default files;
