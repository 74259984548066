import React from 'react';

import Icon from 'components/Icon';
import ReservedCounter from 'components/ReservedCounter';

import OrderTimeOver from './OrderTimeOver';
import ButtonRejectChat from './ButtonRejectChat';
import ButtonJoinChatTooltip from './ButtonJoinChatTooltip';
import ButtonAcceptDisabledNT from './ButtonAcceptDisabledNT';
import ButtonAcceptNT from './ButtonAcceptNT';
import ButtonAcceptDisabled from './ButtonAcceptDisabled';
import ButtonAccept from './ButtonAccept';
import ButtonReserveOrder from './ButtonReserveOrder';
import ButtonRejectOrder from './ButtonRejectOrder';
import { renderDescriptionReserve, renderDescriptionTag } from './utils';

const WriterSuggested = ({ order, user, isNTorder, setRejected, isSuggestOver, checkReserve, canReserve, isStatusLoading, isDisabledReserve, isDisabledJoinChat, currentWriterInChat, isDisabledAccept, withToolTipDisabled, cannotReserveByGroupMaxOrderCount, setSuggestionOver, onRejectChat, onAcceptConfirm, onSetReserved, onAcceptWithEvent, onJoinChatWithEvent }) => {

  const renderNTButtons = () => (
    <>
      {currentWriterInChat ? <ButtonRejectChat onRejectChat={onRejectChat} /> : <ButtonJoinChatTooltip onJoinChatWithEvent={onJoinChatWithEvent} isDisabledJoinChat={isDisabledJoinChat} order={order} user={user} />}
      {isDisabledAccept ? <ButtonAcceptDisabledNT isDisabled onAcceptConfirm={onAcceptConfirm} /> : <ButtonAcceptNT onAcceptConfirm={onAcceptConfirm} />}
    </>
  );

  const renderButtons = () => (withToolTipDisabled ? <ButtonAcceptDisabled isDisabled={isDisabledAccept || cannotReserveByGroupMaxOrderCount} onAcceptWithEvent={onAcceptWithEvent()} /> : <ButtonAccept isDisabled={isDisabledAccept || cannotReserveByGroupMaxOrderCount} onAcceptWithEvent={onAcceptWithEvent} />);

  if (isSuggestOver) {
    return (
      <OrderTimeOver textOver="Suggestion time is over" onClick={checkReserve} withIcon />
    );
  }
  return (
    <div>
      <div className="col-3 mt15">
        <Icon className="svg-icon success" iconName="clock" />
        <span className="text-success mr15">Suggested time left</span>
        <ReservedCounter time={order.suggest_expired} onOver={setSuggestionOver} />
      </div>
      <div className="order-tab-actions row vertical space-between">
        <div className="col-4 ta-right">
          <ButtonRejectOrder isDisabled={isStatusLoading} setRejected={setRejected} />
          <ButtonReserveOrder isDisabled={isDisabledReserve} setReserved={onSetReserved} />
          {isNTorder ? renderNTButtons() : renderButtons()}
        </div>
      </div>
      <div className="row vertical space-between">
        {renderDescriptionTag(order.custom_tags)}
        {renderDescriptionReserve(canReserve, isNTorder)}
      </div>
    </div>
  );
};

export default WriterSuggested;
