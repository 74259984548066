import React from 'react';
import ReactDOMServer from 'react-dom/server';

import classNames from 'utils/classNames';

import SvgSprite from './SvgSprite';

const iconPortal = document.getElementById('writer-icon-portal');

if (!iconPortal) {
  const divPortal = document.createElement('div');
  divPortal.setAttribute('id', 'writer-icon-portal');
  document.body.appendChild(divPortal);
  const sprites = ReactDOMServer.renderToString(<SvgSprite />);
  divPortal.insertAdjacentHTML('beforeend', sprites);
}


const Icon = ({ className, iconName, styles = {} }) => (
  <svg
    role="img"
    className={classNames(className, iconName)}
    style={styles}
  >
    <use href={`#${iconName}`} />
  </svg>
);

export default Icon;
