import React from 'react';

import classNames from './../utils/classNames';

const SubNav = props => {
  const className = classNames('nav', props.className, { 'sub-menu': props.sub, 'menu-no-border': props.noBorder });
  return (
    <nav className={className}>
      <ul>
        {React.Children.map(props.children, (child, idx) => <li key={idx}>{child}</li>)}
      </ul>
    </nav>
  );
};

export default SubNav;
