import React from 'react';
import './styles/index.scss';

const Switch = ({ className, isOn, handleToggle, children, id = 'react-switch-new', onColor = '#2383e5' }) => (
  <div className={className}>
    {children}
    <input
      checked={isOn}
      onChange={handleToggle}
      className="react-switch-checkbox"
      id={id}
      type="checkbox"
    />
    <label
      className="react-switch-label"
      htmlFor={id}
    >
      <span className="react-switch-button" />
    </label>
  </div>
);

export default Switch;
