import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { useMobile } from 'hooks';

import classNames from 'utils/classNames';

import './styles.scss';

const PDF = ({ pdf, hideLoader, show }) => {
  const [pdfPages, setPdfPages] = useState(null);
  const { isMobile } = useMobile(760);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfPages(numPages);
    hideLoader();
  };

  return (
    <Document
      file={pdf}
      onLoadSuccess={onDocumentLoadSuccess}
      className={classNames('react_pdf__Document', {
        'react_pdf__Document--active': show,
      })}
    >
      {Array.from(new Array(pdfPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} height={isMobile ? 500 : 800} />
      ))}
    </Document>
  );
};

export default PDF;
