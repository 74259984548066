import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';
import utils from 'utils';
import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';
import convertTimeFilter from 'utils/convertTimeFilter';
import convertSorting from 'utils/convertSorting';

import { ordersCompleteFetchIfNeeded, ordersCompleteSetSorting, ordersCompleteFetch, setCompleteMessageFilter, ordersCompleteResetFilter } from 'store/ordersComplete/actions';
import { ordersRefundedSetSorting, ordersRefundedFetch, ordersRefundedFetchIfNeeded, setRefundedMessageFilter, ordersRefundedResetFilter } from 'store/ordersRefunded/actions';
import { getOrderList } from 'store/ordersComplete/selectors';
import { getOrderRefundList } from 'store/ordersRefunded/selectors';
import { getOrderIdsWithUnreadMessages, getHasUnreadChatMessagesOrdersComplete } from 'store/notification/selectors';

import { SIZES_ORDER } from 'constants/order';

import { StickyContainer, StickyHead, StickyBody } from './Sticky';
import Icon from './Icon';
import Emoji from './Emoji';
import OrdersCompleteTableFilter from './OrdersCompleteTableFilter';
import TableSortingHead from './TableSortingHead';
import UpToButton from './UpToButton';


const renderDeadline = (value) => {
  const momentDeadLine = moment(value);
  return (
    <span>{formatTime(momentDeadLine, 'dt')}</span>
  );
};

const renderPayout = (value, payoutDateMin) => {
  const cntAddDays = payoutDateMin ? 10 : 15;
  const payoutDate = moment(value).add(cntAddDays, 'days');
  return (
    <span>{formatTime(payoutDate, 'd')}</span>
  );
};


class OrdersCompleteTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearch: false,
    };

    this.onMoreClick = this.onMoreClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    const { loadOrders } = this.props;
    loadOrders(1);
  }

  componentDidUpdate(prevProps) {
    const { filter: prevFilter = {}, sorting: prevSorting = {} } = prevProps;
    const { filter = {}, sorting = {}, forceLoadOrders } = this.props;
    const { isSearch } = this.state;
    if (JSON.stringify(prevFilter) !== JSON.stringify(filter) ||
        JSON.stringify(prevSorting) !== JSON.stringify(sorting)) {
      const searchParams = new URLSearchParams(convertTimeFilter(filter, isSearch)).toString();
      forceLoadOrders(1, convertSorting(sorting), searchParams);
    }
  }

  componentWillUnmount() {
    const {
      filter = {}, resetFilters, forceLoadOrders,
    } = this.props;
    const { new_messages: newMessages } = filter;

    if (newMessages) {
      resetFilters();
      forceLoadOrders();
    }
  }

  onSearchChange(isSearch) {
    this.setState(state => Object.assign({}, state, {
      isSearch,
    }));
  }

  onMoreClick() {
    const {
      forceLoadOrders, page, sorting, filter,
    } = this.props;
    forceLoadOrders(page + 1, convertSorting(sorting), new URLSearchParams(convertTimeFilter(filter)).toString());
  }

  getGradingText = (pregraderRating) => {
    let pregraderRatingStr = '';

    if (!!pregraderRating && pregraderRating < 55) {
      pregraderRatingStr = '1';
    }

    if (pregraderRating) {
      pregraderRatingStr = `${(pregraderRating / 20).toFixed(1)}`;
    }

    return pregraderRatingStr;
  };

  hasUnread(orderId) {
    const { unreadOrderIds } = this.props;
    return unreadOrderIds.indexOf(orderId) > -1;
  }

  renderRow(order) {
    const {
      onSelect,
      selectedNumber,
      refundedMode,
      profile,
      payoutDateMin,
      isDe,
    } = this.props;
    const className = classNames('table-row row vertical', { active: selectedNumber === order.number });
    const writerPrice = order.writer_price ? order.writer_price.toFixed(2) : '';
    const isStem = utils.isStem(profile);

    let isSizeTooltip = false;
    let orderSizes = null;
    let orderSizeMH = null;

    if (order.is_complex && order.extras && order.extras.length > 0) {
      orderSizes = order.extras.filter(s => s.size && s.payment_status === 'Paid');
      if (orderSizes.length > 1) {
        isSizeTooltip = true;
        orderSizes = orderSizes.map((s) => {
          const [size, type] = s.size.split(' ');
          if (type === 'minutes' && !isDe) {
            return convertMinsToHrsMins(size, false, ['h', 'm']);
          }
          return size + SIZES_ORDER[type];
        }).join(' /');
      } else {
        orderSizes = orderSizes.map((s) => {
          const [size, type] = s.size.split(' ');
          if (type === 'minutes' && !isDe) {
            return convertMinsToHrsMins(size);
          }
          return s.size;
        }).toString();
      }
    }

    if (order.size && !isDe) {
      const [size, type] = order.size.split(' ');
      if (type === 'minutes') {
        orderSizeMH = convertMinsToHrsMins(size);
      }
    }

    const qaReportScore = order.qa_report_score || this.getGradingText(order.pregrader_assessing_avg);

    return (
      <div className={className} key={order._id} onClick={() => onSelect(order.number)}>
        <div className="col-4">
          <span>{order.number}</span>
          <a href={`/order/${order._id}`} target="_blank" rel="noopener noreferrer">
            <Icon className="svg-icon" iconName="open-new-window" />
          </a>
        </div>
        <div className="col-3 text-ellipsis no-wrap">{order.subject}</div>
        <div className="col-3">{order.type}</div>
        <div className="col-2">
          {isStem ?
                  utils.getMinutesSize(orderSizes || order.size) :
                  writerPrice
              }
        </div>
        {isSizeTooltip &&
          <div className="col-2 block tooltip tooltip-top tooltip-table">
            <a className="tooltip-trigger">{orderSizes}</a>
            <div className="tooltip-content">
              {orderSizes}
            </div>
          </div>
            }
        {!isSizeTooltip &&
          <div className="col-2">
            {orderSizes || orderSizeMH || order.size}
          </div>
            }
        <div className="col-4">{renderDeadline(order.writer_deadline)}</div>
        {!isStem &&
          <div className="col-3">{renderPayout(order.writer_deadline, payoutDateMin)}</div>
        }
        <div className="col-2 ta-center">{qaReportScore}</div>
        {refundedMode &&
          <div className="col-2">
            <span className="badge badge-refund-tag">{order.refund_tag}</span>
          </div>
        }
      </div>
    );
  }

  renderOrderList() {
    const { orderList, isLoading } = this.props;

    if (isLoading) {
      return (<div className="preloading preloading-box"><p>Loading</p></div>);
    }

    if (!orderList.length) {
      return (
        <div className="page-not-result">
          <Emoji id="crySad" />
          <h1>Oops, there are no orders in this section.</h1>
          <p className="notes">Only completed orders are shown here.</p>
        </div>
      );
    }

    return orderList.map(order => this.renderRow(order));
  }

  renderNextButton() {
    const {
      orderList, isLoading, total, limit = 25, page = 1,
    } = this.props;

    if (!orderList.length || isLoading) return null;

    const nowLoadedOrders = page * limit;

    if (nowLoadedOrders >= total) return null;

    return (
      <button type="button" className="btn btn-show-next" onClick={this.onMoreClick}>Show next orders ({nowLoadedOrders}/{total})</button>
    );
  }

  render() {
    const { isSearch } = this.state;
    const {
      setSorting,
      sorting,
      refundedMode,
      profile,
      filter,
    } = this.props;
    const isStem = utils.isStem(profile);
    return (
      <div className="table orders-table">
        <StickyContainer className="sticky-table">
          <StickyHead className="sticky-table-head" classNameFixed="sticky-table-head-fixed">
            <OrdersCompleteTableFilter
              isSearch={isSearch}
              onSearchChange={this.onSearchChange}
              className="table-filter"
              refundedMode={refundedMode}
              filter={filter}
            />
            <div className="table-header">
              <div className="row">
                <div className="col-4">Order Number</div>
                <div className="col-3">Subject</div>
                <div className="col-3">Type of work</div>
                <div className="col-2">
                  <TableSortingHead sorting={sorting} setSorting={setSorting} field={isStem ? 'pages' : 'writer_price'}>Your price</TableSortingHead>
                </div>
                <div className="col-2">
                  <TableSortingHead sorting={sorting} setSorting={setSorting} field="pages">Size</TableSortingHead>
                </div>
                <div className="col-4">
                  <TableSortingHead sorting={sorting} setSorting={setSorting} field="writer_deadline">Deadline / time left</TableSortingHead>
                </div>
                {!isStem &&
                  <div className="col-3">
                    <TableSortingHead sorting={sorting} setSorting={setSorting} field="payoutDateComputed">Payout date</TableSortingHead>
                  </div>
                }
                <div className="col-2 ta-center">
                  <TableSortingHead sorting={sorting} setSorting={setSorting} field="pregrader_assessing_avg">Quality</TableSortingHead>
                </div>
                {refundedMode &&
                  <div className="col-2">Additional info</div>
                }
              </div>
            </div>
          </StickyHead>
          <StickyBody className="sticky-table-body">
            {this.renderOrderList()}
          </StickyBody>
        </StickyContainer>
        <UpToButton />
        {this.renderNextButton()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { refundedMode } = ownProps;
  const userGroup = state.user && state.user.group_name;
  const payoutDateMin = (userGroup === 'A' || userGroup === 'A+' || userGroup === 'B' || userGroup === 'C') || false;
  const isDe = state.user && state.user.profile_type === 'D';

  return {
    isLoading: refundedMode ? state.ordersRefunded.isFetching : state.ordersComplete.isFetching,
    total: refundedMode ? state.ordersRefunded.total : state.ordersComplete.total,
    limit: refundedMode ? state.ordersRefunded.limit : state.ordersComplete.limit,
    page: refundedMode ? state.ordersRefunded.page : state.ordersComplete.page,
    orderList: refundedMode ? getOrderRefundList(state) : getOrderList(state),
    sorting: refundedMode ? state.ordersRefunded.sorting : state.ordersComplete.sorting,
    filter: refundedMode ? state.ordersRefunded.filter : state.ordersComplete.filter,
    unreadOrderMessages: refundedMode ? state.ordersRefunded.unreadOrderMessages : state.ordersComplete.unreadOrderMessages || getHasUnreadChatMessagesOrdersComplete(state),
    unreadOrderIds: getOrderIdsWithUnreadMessages(state),
    profile: state.user,
    payoutDateMin,
    isDe,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { refundedMode } = ownProps;
  return {
    loadOrders: page => dispatch(refundedMode ? ordersRefundedFetchIfNeeded(page) : ordersCompleteFetchIfNeeded(page)),
    forceLoadOrders: (page, sorting, filter) => dispatch(refundedMode ? ordersRefundedFetch(page, sorting, filter) : ordersCompleteFetch(page, sorting, filter)),
    setSorting: value => dispatch(refundedMode ? ordersRefundedSetSorting(value) : ordersCompleteSetSorting(value)),
    setMessageFilter: value => dispatch(refundedMode ? setRefundedMessageFilter(value) : setCompleteMessageFilter(value)),
    resetFilters: () => dispatch(refundedMode ? ordersRefundedResetFilter() : ordersCompleteResetFilter()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersCompleteTable);
