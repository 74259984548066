import React, { useState, useMemo } from 'react';
import { UnmountClosed } from 'react-collapse';

import classNames from 'utils/classNames';

import Icon from 'components/Icon';

import { getItems } from 'components/order/utils';

import DetailsContent from './DetailsContent';

const RevisionOrderInfo = ({
  order, profile, mobile, isDe,
}) => {
  const [isOpenOrderDetails, toggleIsOpenOrderDetails] = useState(false);

  const onToggleOrderDetails = () => {
    toggleIsOpenOrderDetails(__prevValue => !__prevValue);
  };

  const groupItem = useMemo(() => ['orderType', 'orderSize', 'orderSubject', 'orderPrice'].map(group => getItems({
    item: group, order, profile, mobile, isDe,
  })), [order, profile, mobile, isDe]);

  return (
    <div className="order-tab-collapse">
      <div className="order-tab-collapse-head row space-between" onClick={onToggleOrderDetails}>
        <div className="row vertical">
          <Icon className="svg-icon" iconName="icon-detail" />
          <span className="order-tab-head-name"><b>Order details</b></span>
        </div>
        <span className={classNames('arrow-down', { active: isOpenOrderDetails })} />
      </div>
      <UnmountClosed isOpened={isOpenOrderDetails} className="order-tab-collapse-body" hasNestedCollapse>
        <DetailsContent groupItem={groupItem} />
      </UnmountClosed>
    </div>
  );
};

export default RevisionOrderInfo;
