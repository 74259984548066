import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { deleteAllModalComponent } from 'store/modals/actions';
import { userSetAvailableFetch } from 'store/user/actions';

import timeConstants from 'constants/time';

import Dropdown from './HoverDropdown';
import Select from './Select';
import Emoji from './Emoji';
import classNames from './../utils/classNames';


const getTime = () => {
  const timeItems = [];
  for (let i = 1; i <= 12; i++) {
    const str = `${i < 10 ? '0' : ''}${i}:00`;
    timeItems.push(str);
  }
  return timeItems;
};

let timeItems = getTime();

class AvailabilityDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      diff: 0,
    };
    this.setDiff = this.setDiff.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { ONE_MINUTE } = timeConstants;
    this.setDiff();
    this.interval = setInterval(this.setDiff, ONE_MINUTE);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.available !== this.props.available) {
      this.setDiff();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onChange(value) {
    const { setAvailable, deleteModal } = this.props;
    setAvailable(`${value}:00`);
    deleteModal();
  }

  setDiff() {
    const { available } = this.props;
    const diff = !available ? 0 : moment(available).diff(moment());
    this.setState(state => Object.assign({}, state, { diff }));
  }

  render() {
    const { diff } = this.state;
    const { isMobile = false } = this.props;
    // const { isLoading } = this.props;
    const nowAvailable = moment.utc(diff).format('HH:mm');
    const text = diff <= 0 ? 'Not available now' : `Available ${nowAvailable}`;
    const nAMenuItemClassname = classNames('menu-item', {
      active: diff <= 0,
    });

    if (isMobile) {
      const hour = `${moment.utc(diff).format('HH')}:00`;
      timeItems = getTime();
      if (diff > 0) {
        timeItems.splice(timeItems.indexOf(hour) + 1, 0, nowAvailable);
      }
      return (
        <ul className="availability-dropdown">
          <li value="00:00" className={nAMenuItemClassname} onClick={() => this.onChange('00:00')}>Not available</li>
          {timeItems.map(item => (
            <li key={item} value={item} className={classNames('menu-item', { active: nowAvailable === item })} onClick={() => this.onChange(item)}>{item}</li>
            ))}
        </ul>
      );
    }

    return (
      <div className="availability-dropdown">
        <Dropdown buttonClassName="btn btn-empty" icon="working-status" text={text} closeAlways>
          <Select className="menu menu-selectable" onChange={this.onChange}>
            <li value="00:00" className={nAMenuItemClassname}>Not available <Emoji id=":(" /></li>
            {timeItems.map(item => (
              <li key={item} value={item} className="menu-item">{item}</li>
            ))}
          </Select>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  available: state.user.available_for,
  isLoading: state.user.isFetchingAvailable,
});

const mapDispatchToProps = dispatch => ({
  setAvailable: value => dispatch(userSetAvailableFetch(value)),
  deleteModal: () => dispatch(deleteAllModalComponent()),
});

const availabilityDropdownCon = connect(mapStateToProps, mapDispatchToProps)(AvailabilityDropdown);

availabilityDropdownCon.displayName = 'My Availability';

export default availabilityDropdownCon;
