import { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';

import formatTime from 'utils/formatTime';
import { MIN_DEADLINE_RANGE, MAX_DEADLINE_RANGE, DEADLINE_PERCENTS_PRESETS } from 'constants/reportForm';

const DATA_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';


const useDeadlineRange = ({ clientDeadline, writerDeadline, clientPaidAt }) => {
  const [startDeadlineRange, setStartDeadlineRange] = useState(MIN_DEADLINE_RANGE);
  const [isDisabledDeadline, setIsDisabledDeadline] = useState(false);
  const [presets, setPresets] = useState([]);


  // ***** calculate client ddl in hours
  const clientHours = useMemo(() => (moment(clientDeadline).diff(clientPaidAt, 'hours', true).toFixed()), [clientDeadline, clientPaidAt]);


  // ***** calculate writer ddl in hours
  const writerHours = useMemo(() => (moment(writerDeadline).diff(clientPaidAt, 'hours', true).toFixed()), [writerDeadline, clientPaidAt]);


  // ***** if the maximum deadline (MAX_DEADLINE_RANGE) has expired disable this option (checkbox 'Order deadline is too short')
  useEffect(() => {
    const clHoursFromMaxPercent = (clientHours * (MAX_DEADLINE_RANGE / 100)).toFixed();
    const clDateFromMaxPercent = moment(moment(clientPaidAt).add(clHoursFromMaxPercent, 'hours')).format(DATA_FORMAT);
    const isExpired = moment().isAfter(clDateFromMaxPercent);
    setIsDisabledDeadline(isExpired);
  }, [clientHours]);


  // ***** determine presets for shortcuts of deadlines
  useEffect(() => {
    const newPresets = [];
    DEADLINE_PERCENTS_PRESETS.forEach((percent) => {
      const percentClHours = (clientHours * (percent / 100)).toFixed();
      const hours = percentClHours - writerHours;
      const wrDate = moment(writerDeadline).utc().add(hours, 'hours').format();

      if (hours > 0) {
        const ddlFormat = formatTime(wrDate, 'dt');
        newPresets.push([`+${hours}h`, hours, `+${hours}h / ${ddlFormat}`]);
      }
    });
    setPresets(newPresets);
  }, [clientHours, writerHours]);


  // ***** determine the minimum deadline for range component deadline
  useEffect(() => {
    let startDeadline = MIN_DEADLINE_RANGE;

    // ***** if the percent of minimum deadline (MIN_DEADLINE_RANGE) less then percent of current date, set minimum - percent of current date
    const clientDdlHoursFromCurrentDate = moment().diff(clientPaidAt, 'hours', true).toFixed();
    const clientDdlPercentFromCurrentDate = Math.round((100 * clientDdlHoursFromCurrentDate) / clientHours);
    startDeadline = clientDdlPercentFromCurrentDate > MIN_DEADLINE_RANGE ? clientDdlPercentFromCurrentDate : MIN_DEADLINE_RANGE;

    // ***** if the minimum deadline (MIN_DEADLINE_RANGE) less then writerDeadline, set minimum (writerDeadline + 1h)
    const writerDeadlinePercentFromClientDeadline = Math.round(((100 / clientHours) * writerHours).toFixed());
    if (writerDeadlinePercentFromClientDeadline > MIN_DEADLINE_RANGE && writerDeadlinePercentFromClientDeadline > clientDdlPercentFromCurrentDate) {
      const newWriterHours = Number(writerHours) + 1;
      const writerDeadlinePlusHoursPercentFromClientDeadline = Math.round(((100 / clientHours) * newWriterHours).toFixed());
      startDeadline = writerDeadlinePlusHoursPercentFromClientDeadline;
    }

    setStartDeadlineRange(startDeadline);
  }, [clientPaidAt]);


  // ***** calculate writer deadline and hours from percent of client deadline
  const calculateCustomDeadlineFromPercent = useCallback((customDeadlinePercent) => {
    const calcFromPercentClientHours = ((clientHours / 100) * customDeadlinePercent).toFixed();
    const hours = calcFromPercentClientHours - writerHours;
    const wrDate = moment(writerDeadline).utc().add(hours, 'hours').format();

    return { wrDate, wrHours: hours };
  }, [clientHours, writerHours]);


  return [isDisabledDeadline, presets, startDeadlineRange, calculateCustomDeadlineFromPercent];
};

export default useDeadlineRange;
