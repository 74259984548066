const isNumber = (value) => {
  if (typeof value === 'number') {
    return true;
  }
  if (typeof value === 'string') {
    return /^[+-]?\d+\.?\d*$/.test(value);
  }
  return false;
};

const isCurrency = (value) => {
  if (!isNumber(value)) {
    return false;
  }

  const checkString = typeof value === 'string' ? value : value.toString();
  const decimalPart = checkString.toString().split('.')[1];
  const decimalCount = !decimalPart ? 0 : decimalPart.length;
  return decimalCount < 3;
};

const isNotLess = (value, min) => {
  if (typeof min !== 'number') {
    throw new TypeError('min should be a number');
  }
  if (!isNumber(value)) {
    return false;
  }
  const checkNumber = typeof value === 'number' ? value : Number(value);
  return checkNumber >= min;
};

const isNotOver = (value, max) => {
  if (typeof max !== 'number') {
    throw new TypeError('max should be a number');
  }
  if (!isNumber(value)) {
    return false;
  }
  const checkNumber = typeof value === 'number' ? value : Number(value);
  return checkNumber <= max;
};

const isEmail = value => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

// [а-яА-ЯёЁ] - Русский алфавит
// (?![×÷])[A-Za-zÀ-ÿ] — Вся латиница плюс акцентированные символы
// [ёа-зй-шы-яЁА-ЗЙ-ШЫІіЎў] — Белорусский алфавит
// [а-ъьюяА-ЪЬЮЯ] — Болгарский алфавит
// [\u0370-\u03FF\u1F00-\u1FFF] — Греческий и коптский алфавиты вместе
// [a-zA-ZáéíñóúüÁÉÍÑÓÚÜ] — Испанский алфавит
// [a-zA-ZàèéìíîòóùúÀÈÉÌÍÎÒÓÙÚ] — Итальянский алфавит
// [a-zA-ZäöüßÄÖÜẞ] — Немецкий алфавит
// [a-zA-ZæøåÆØÅ] — Норвежский алфавит
// [a-pr-uwy-zA-PR-UWY-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ] - Польский алфавит
// [a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ] — польский + латиница:
// [a-zA-ZĂÂÎȘȚăâîșț] — Румынский алфавит
// [А-ИК-ШЂЈ-ЋЏа-ик-шђј-ћџ] - Сербский алфавит (кириллица)
// [а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'] — Украинский
// [a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒ] - Французский алфавит
// [a-zA-ZäöåÄÖÅ] - Шведский алфавит

const isValidName = value => /[a-zA-Zа-яА-ЯёЁA-Za-zÀ-ÿёа-зй-шы-яЁА-ЗЙ-ШЫІіЎўа-ъьюяА-ЪЬЮЯa-zA-ZáéíñóúüÁÉÍÑÓÚÜa-zA-ZàèéìíîòóùúÀÈÉÌÍÎÒÓÙÚa-zA-ZäöüßÄÖÜẞa-zA-ZæøåÆØÅa-pr-uwy-zA-PR-UWY-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻa-zA-ZĂÂÎȘȚăâîșțА-ИК-ШЂЈ-ЋЏа-ик-шђј-ћџа-щА-ЩЬьЮюЯяЇїІіЄєҐґ'a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒa-zA-ZäöåÄÖÅ]$/.test(value);
const matchValidName = value => value.match(/[a-zA-Zа-яА-ЯёЁA-Za-zÀ-ÿёа-зй-шы-яЁА-ЗЙ-ШЫІіЎўа-ъьюяА-ЪЬЮЯa-zA-ZáéíñóúüÁÉÍÑÓÚÜa-zA-ZàèéìíîòóùúÀÈÉÌÍÎÒÓÙÚa-zA-ZäöüßÄÖÜẞa-zA-ZæøåÆØÅa-pr-uwy-zA-PR-UWY-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻa-zA-ZĂÂÎȘȚăâîșțА-ИК-ШЂЈ-ЋЏа-ик-шђј-ћџа-щА-ЩЬьЮюЯяЇїІіЄєҐґ'a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒa-zA-ZäöåÄÖÅ ]*/)[0];
// const isValidString = value => /^\w+$/.test(value);
const regOnlyOneSymbol = /[a-zA-Zа-яА-ЯёЁA-Za-zÀ-ÿёа-зй-шы-яЁА-ЗЙ-ШЫІіЎўа-ъьюяА-ЪЬЮЯa-zA-ZáéíñóúüÁÉÍÑÓÚÜa-zA-ZàèéìíîòóùúÀÈÉÌÍÎÒÓÙÚa-zA-ZäöüßÄÖÜẞa-zA-ZæøåÆØÅa-pr-uwy-zA-PR-UWY-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻa-zA-ZĂÂÎȘȚăâîșțА-ИК-ШЂЈ-ЋЏа-ик-шђј-ћџа-щА-ЩЬьЮюЯяЇїІіЄєҐґ'a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒa-zA-ZäöåÄÖÅ ]/;
const isValidString = value => (value.match(regOnlyOneSymbol) && value.match(regOnlyOneSymbol)[0]);

export default {
  isCurrency,
  isNotLess,
  isNotOver,
  isEmail,
  isValidName,
  isValidString,
  matchValidName,
  isNumber,
};
