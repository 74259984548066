import React, { useState, useEffect, useMemo } from 'react';

import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

import classNames from 'utils/classNames';

import wallet from 'img/icons/wallet.svg';

import '../styles/bankInfoSection.scss';

const IFSC_COUNTRY = ['india'];

const BankInfo = ({ bankInfo, setBankInfo, countryList = [] }) => {
  const [bankAccount, setBankAccount] = useState(bankInfo.bank_account);
  const [bankSWIFT, setBankSWIFT] = useState(bankInfo.bank_swift);
  const [bankName, setBankName] = useState(bankInfo.bank_name);
  const [bankStreetAddress, setBankStreetAddress] = useState(bankInfo.bank_address);
  const [bankIFSC, setBankIFSC] = useState(bankInfo.bank_ifsc);

  const showIFSC = useMemo(() => IFSC_COUNTRY.includes(bankInfo.bank_country.toLocaleLowerCase()), [bankInfo.bank_country]);

  const onBankAccountChange = (e) => {
    const { target } = e;
    setBankAccount(target.value);
  };

  const onBankSWIFTChange = (e) => {
    const { target } = e;
    setBankSWIFT(target.value);
  };

  const onBankNameChange = (e) => {
    const { target } = e;
    setBankName(target.value);
  };

  const onBankStreetAddressChange = (e) => {
    const { target } = e;
    setBankStreetAddress(target.value);
  };

  const onBankIFSCChange = (e) => {
    const { target: { validity = {}, value } } = e;

    if (!validity.valid) return;

    setBankIFSC(value);
  };

  const changeParentProps = (key, value) => {
    if (bankInfo[key] === value) return;
    let dopOptions = {};

    if (key === 'bank_account') {
      dopOptions = {
        isBankAccountValid: true,
      };
    }
    if (key === 'bank_swift') {
      dopOptions = {
        isBankSwiftValid: true,
      };
    }

    if (key === 'bank_ifsc') {
      dopOptions = {
        isBankIFSCValid: String(value).length === 11,
      };
    }

    setBankInfo({
      ...bankInfo, ...{ [key]: value, ...dopOptions },
    });
  };

  useEffect(() => {
    setBankAccount(bankInfo.bank_account);
    setBankSWIFT(bankInfo.bank_swift);
    setBankName(bankInfo.bank_name);
    setBankStreetAddress(bankInfo.bank_address);
  }, [bankInfo]);

  useEffect(() => {
    if (showIFSC) {
      if (!bankInfo.bank_ifsc) {
        setBankInfo({
          ...bankInfo, ...{ bank_ifsc: '', isBankIFSCValid: false },
        });
      }
      return;
    }

    if (bankInfo.bank_ifsc === null) return;

    const __bankInfo = { ...bankInfo };
    delete __bankInfo.bank_ifsc;
    delete __bankInfo.isBankIFSCValid;
    setBankIFSC('');
    setBankInfo(__bankInfo);
  }, [showIFSC]);

  return (
    <div className="bank-info-section">
      <div className="sections-title">
        <img src={wallet} alt="wallet" className="sections-title__icon" />
        <div className="sections-title__text">Bank information</div>
      </div>
      <div className="wire-transfer-form-form-group-column">
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
          <label htmlFor="IBAN" className="wire-transfer-form-form-group__label">Bank Account (or IBAN)</label>
          <input id="IBAN" className={classNames('wire-transfer-form-form-group__input input-text', { 'is-error-only-border': !bankInfo.isBankAccountValid })} value={bankAccount} onChange={onBankAccountChange} onBlur={() => changeParentProps('bank_account', bankAccount)} />
        </div>
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
          <label htmlFor="SWIFT" className="wire-transfer-form-form-group__label">Bank SWIFT</label>
          <input id="SWIFT" className={classNames('wire-transfer-form-form-group__input input-text', { 'is-error-only-border': !bankInfo.isBankSwiftValid })} value={bankSWIFT} onChange={onBankSWIFTChange} onBlur={() => changeParentProps('bank_swift', bankSWIFT)} />
        </div>
      </div>
      <div className="wire-transfer-form-form-group-column">
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
          <label htmlFor="BankCountry" className="wire-transfer-form-form-group__label">Bank Country</label>
          <Dropdown closeAlways id="BankCountry" text={bankInfo.bank_country} extraDropdownClass="wire-transfer-form-form-group__dropdown" closeAlways>
            <Select className="menu menu-selectable menu-selectable-overflow" value={bankInfo.bank_country} onChange={value => changeParentProps('bank_country', value)}>
              {countryList.map(country => <li className="menu-item wire-transfer-form-form-group__dropdown__item" key={country} value={country}>{country}</li>)}
            </Select>
          </Dropdown>
        </div>
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
          <label htmlFor="BankName" className="wire-transfer-form-form-group__label">Bank Name</label>
          <input id="BankName" className="wire-transfer-form-form-group__input input-text" value={bankName} onChange={onBankNameChange} onBlur={() => changeParentProps('bank_name', bankName)} />
        </div>
      </div>
      <div className={classNames('wire-transfer-form-form-group-column', { minWidth100: !showIFSC })}>
        <div className={classNames('wire-transfer-form-form-group', { 'wire-transfer-form-form-group--column-item': showIFSC, minWidth100: !showIFSC })}>
          <label htmlFor="StreetAddress" className="wire-transfer-form-form-group__label">Bank Street Address</label>
          <input id="StreetAddress" className="wire-transfer-form-form-group__input input-text" value={bankStreetAddress} onChange={onBankStreetAddressChange} onBlur={() => changeParentProps('bank_address', bankStreetAddress)} />
        </div>
        {
          showIFSC && (
            <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
              <label htmlFor="IFSC" className="wire-transfer-form-form-group__label">IFSC code</label>
              <input id="IFSC" pattern="[0-9,a-z,A-Z]{1,11}" className={classNames('wire-transfer-form-form-group__input input-text', { 'is-error-only-border': !bankInfo.isBankIFSCValid && bankIFSC })} value={bankIFSC} onChange={onBankIFSCChange} onBlur={() => changeParentProps('bank_ifsc', bankIFSC)} />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default BankInfo;
