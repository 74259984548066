import React, { Component } from 'react';

import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

class ProfileDropdown extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    const { onChangeFields, fieldName } = this.props;
    onChangeFields(fieldName, value);
  }

  render() {
    const {
      value,
      list,
      title,
      onChangeFields,
    } = this.props;
    const text = value || 'Select';
    const isValid = !!value;
    const errorClassName = !isValid ? 'text-danger' : null;
    return (
      <div>
        <span className={errorClassName}>{title}:</span>
        {!onChangeFields ?
          <span className="profile-noneditable-field">{value}</span> :
          <Dropdown buttonClassName="lnk-dropdown" text={text} closeAlways>
            <Select value={value} onChange={this.onChange} className="menu menu-selectable menu-selectable-overflow">
              {list.map(gender =>
                              (<li key={gender} value={gender} className="menu-item">
                                {gender}
                                <Icon className="svg-icon" iconName="check-empty" />
                              </li>))}
            </Select>
          </Dropdown>
                }
      </div>
    );
  }
}

export default ProfileDropdown;
