import moment from 'moment-timezone';

import { NOTICES_NOTIFICATION_LIMIT, TAGS_BAD, TAGS_GOOD, TAGS_RESOLVED } from 'components/WriterGroup/constants';

import {
  NOTICES_REQUEST,
  NOTICES_RECEIVE,
  NOTICES_ACTION_REQUEST,
  NOTICES_ACTION_RECEIVE,
  ADD_NOTICE,
} from './actions';

const notification = (state = {
  isFetching: false,
  didInvalidate: true,
}, action) => {
  switch (action.type) {
    case NOTICES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case NOTICES_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetching: false,
        updatedAt: moment(),
        didInvalidate: false,
      });
    case NOTICES_ACTION_REQUEST:
      return {
        ...state,
        isFetchingAction: true,
      };
    case NOTICES_ACTION_RECEIVE:
      return {
        ...state,
        ...action.data,
        isFetchingAction: false,
      };
    case ADD_NOTICE: {
      let newResults;
      let { total } = state;
      const stateResults = state.results || [];

      const { event_tag: eventTag } = action.data || {};
      const isNewNoticeValidByTag = [...TAGS_BAD, ...TAGS_GOOD, ...TAGS_RESOLVED]
        .includes(eventTag);

      if (!isNewNoticeValidByTag) return state;

      if (stateResults.length === NOTICES_NOTIFICATION_LIMIT) {
        newResults = stateResults.slice(0, -1);
        // here logic to find last read notice
        // const lastReadNotice = stateResults.findLastIndex(it => it.status === 1);

        // if (lastReadNotice === -1) {
        //   newResults = stateResults.slice(0, -1);
        // } else {
        //   newResults = [...stateResults.slice(0, lastReadNotice), ...stateResults.slice(lastReadNotice + 1)];
        // }

        newResults = [...[action.data], ...newResults || []];
      } else {
        newResults = [...[action.data], ...state.results || []];
        total = state.total + 1;
      }

      return {
        ...state,
        ...{ results: newResults, total },
        isFetching: false,
        updatedAt: moment(),
        didInvalidate: false,
      };
    }
    default:
      return state;
  }
};

export default notification;
