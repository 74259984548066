import React from 'react';
import { connect } from 'react-redux';
import {
  NavLink,
  Outlet,
} from 'react-router-dom';

import Icon from './../components/Icon';
import { helpSetSearch } from './../store/help/actions';

const HelpGuidesPage = (props) => {
  const {
    searchValue,
    setSearch,
    clearSearch,
  } = props;
  const { guidesCats = [] } = props;
  return (
    <div className="container">
      <div className="row help-container">
        <aside className="left-sidebar content-item">
          <div className="search-wrap">
            <Icon className="svg-icon" iconName="search" />
            <input className="input-text" name="searchValue" type="text" placeholder="Search for answers" onChange={setSearch} value={searchValue} />
            <button className="btn btn-close" type="button" onClick={clearSearch}>
              <Icon className="svg-icon" iconName="close" />
            </button>
          </div>
          <ul className="nav-left">
            {guidesCats.map(cat => (
              <li key={cat.id}>
                <NavLink onClick={searchValue ? clearSearch : null} to={cat.id}>{cat.text}</NavLink>
              </li>
            ))}
          </ul>
        </aside>
        <section className="right-content padding-content container-border">
          <Outlet />
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const guidesCats = Object.values(state.help.guidesCats);
  const searchValue = state.help.search;
  return {
    guidesCats,
    searchValue,
    paymentMethodsCategory: state.help.paymentMethodsCategory,
    profile: state.user,
  };
};

const mapDispatchToProps = dispatch => ({
  setSearch: event => dispatch(helpSetSearch(event.target.value)),
  clearSearch: () => dispatch(helpSetSearch('')),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpGuidesPage);
