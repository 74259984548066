import React from 'react';

import Checkbox from 'components/Checkbox';

const FixesList = ({ datalistFixes, sumFines }) => (
  <section className="container_fix">
    <div className="container_fix_header">
      <h5>
        List of fixes
        <div className="tooltip">
          <span className="tooltip-trigger" />
          <div className="tooltip-content">This is a list of issues that were fixed in your paper by our QA team on spot. Large amount of fixes may result in a 20% fine.</div>
        </div>
      </h5>
      {sumFines &&
        <span className="container_fix_cost">${sumFines}</span>
      }
    </div>

    <div className="container_fix_body">
      {datalistFixes.map(itemFixes => (
        <div key={itemFixes.id}>
          <Checkbox
            checked
            disabled
          >
            {itemFixes.name}
          </Checkbox>
        </div>
      ))}
    </div>
  </section>
);

export default FixesList;
