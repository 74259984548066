import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'debounce';

import { ordersDetailsFetch } from 'store/orders/actions';
import { orderSessions } from 'store/clientSessions/actions';

import { ConnectProvider } from '@connect/connect-xmpp';

const Xmpp = ({ children }) => {
  const profile = useSelector(state => state.user || {});
  const { jabber_jid: userJid, jabber_password: password } = profile;
  const dispatch = useDispatch();

  if (!password) return <>{children}</>;

  const clientXmppConfig = {
    userJid,
    password,
  };

  const callOrderDetails = {};
  const callSessionsDetails = {};

  const getOrderDetails = (id) => {
    if (!id) return;

    dispatch(ordersDetailsFetch(id, { withId: true, quite: true }));
  };

  const getOrderSessions = (orderId) => {
    dispatch(orderSessions(orderId));
  };

  const parseOrderEvent = (event) => {
    const {
      event_params: {
        order_id: orderId,
      },
    } = event;

    if (!orderId) return;

    if (callOrderDetails[orderId]) {
      callOrderDetails[orderId](orderId);
      return;
    }

    callOrderDetails[orderId] = debounce(getOrderDetails, 100);
    callOrderDetails[orderId](orderId);
  };

  const parseSessionsEvent = (event) => {
    const {
      event_params: {
        live_session_id: liveSessionId, order_id: orderId,
      },
    } = event;

    if (!liveSessionId) return;

    if (!callSessionsDetails.live_session) {
      callSessionsDetails.live_session = debounce(getOrderSessions, 100);
      callSessionsDetails.live_session(orderId);
      return;
    }
    callSessionsDetails.live_session(orderId);
  };

  const onMessageHandler = (message) => {
    const { message: { event = null } } = message;

    if (!event) return;

    parseOrderEvent(event);
    parseSessionsEvent(event);
  };

  return (
    <ConnectProvider
      xmppConfig={clientXmppConfig}
      isProduction={process.env.REACT_APP_BUILD === 'prod'}
      onMessage={onMessageHandler}
      isXmppLogsEnabled
    >
      <>{children}</>
    </ConnectProvider>
  );
};

export default Xmpp;
