import { createSelector } from 'reselect';
import { getTakenOrderNotifications } from 'store/ordersNotifications/selectors';

import { getSorterByField } from './../orderSorters';

const stable = require('stable');

const getOrdersFind = state => state.ordersFind.numbers || [];
const getUserSubjects = state => state.user.subjects || [];
const getOrderSubjectGroups = state => state.settings.orderSubjectGroups || [];
const getAllOrders = state => state.orders || {};

const USE_SUBJECTS_TYPES = ['preferred', 'middle_preferred', 'low_preferred'];

const getOrdersProposed = createSelector([getAllOrders, getOrdersFind, getOrderSubjectGroups, getUserSubjects, getTakenOrderNotifications], (allOrders, orderFindList, orderSubjectGroups, userSubjects, takenOrders = []) => {
  const orderList = orderFindList.map(number => allOrders[number]).filter((order = {}) => !order.reserved && !order.reserved_by_me && !takenOrders.includes(order.number)) || [];
  const recommendedList = orderList.filter((order = {}) => order.is_recommended || order.recomended_by_client) || [];
  const writerPriceSorter = getSorterByField('pricePerWords', false);

  if (!userSubjects || !userSubjects.length || userSubjects.length < 3) return [];

  const userOrdersProposed = userSubjects.reduce((prev, next, index) => {
    prev[USE_SUBJECTS_TYPES[index]] = {
      subjects: orderSubjectGroups[next] || [],
      orders: [],
    };
    return prev;
  }, {});

  let otherOrders = [];

  orderList.forEach((order) => {
    if (order && order.subject &&
      !(order.is_recommended || order.recomended_by_client)) {
      let caught = false;
      Object.values(userOrdersProposed).forEach((it) => {
        if (it.subjects.includes(order.subject)) {
          it.orders = [...it.orders, order];
          caught = true;
        }
      });
      if (!caught) {
        otherOrders = [...otherOrders, order];
      }
    }
  });

  return [
    ...stable(recommendedList, writerPriceSorter),
    ...stable(userOrdersProposed[USE_SUBJECTS_TYPES[0]].orders, writerPriceSorter),
    ...stable(userOrdersProposed[USE_SUBJECTS_TYPES[1]].orders, writerPriceSorter),
    ...stable(userOrdersProposed[USE_SUBJECTS_TYPES[2]].orders, writerPriceSorter),
    ...stable(otherOrders, writerPriceSorter),
  ];
});

export default getOrdersProposed;
