import React from 'react';

import Icon from 'components/Icon';

import './index.scss';

const StoryIcons = ({ allIcons = [] }) => (
  <div className="row story-icons-container">
    {allIcons.map(({ name, styles = {} }) => <div key={name} title={name} className="story-icons-container__icon"><Icon iconName={name} styles={styles} /></div>)}
  </div>
);

export default StoryIcons;
