import {
  SESSIONS_REQUEST,
  SESSIONS_RECEIVE,
  SESSIONS_ORDER_RECEIVE,
  SESSIONS_ADD,
  SESSIONS_DELETE,
  SESSIONS_UPDATE,
} from './actions';

const clientSessions = (state = {
  isFetching: false,
  sessions: {},
}, action) => {
  switch (action.type) {
    case SESSIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case SESSIONS_RECEIVE:
      return { ...state, sessions: action.session, isFetching: false };
    case SESSIONS_ADD: {
      const { order_id: orderId } = action.session;
      const orderSessions = state.sessions[orderId] || [];
      const currentSession = { ...state.sessions };
      currentSession[orderId] = [...orderSessions, action.session];
      return { ...state, sessions: currentSession, isFetching: false };
    }
    case SESSIONS_ORDER_RECEIVE: {
      const { sessions: orderSections = [], orderId } = action;
      const orderSessions = state.sessions[orderId] || [];
      const currentSessions = { ...state.sessions };
      currentSessions[orderId] = orderSessions.length === 0 ? orderSections : [...orderSessions, ...orderSections.filter(orderSession => !orderSessions.some(currentSession => currentSession._id === orderSession._id))];
      return { ...state, sessions: currentSessions, isFetching: false };
    }
    case SESSIONS_UPDATE: {
      const { order_id: orderId, _id: sessionID } = action.session;
      const orderSessions = state.sessions[orderId] || [];
      const currentSession = { ...state.sessions };
      currentSession[orderId] = orderSessions.map((session) => {
        if (session._id === sessionID) return action.session;
        return session;
      });
      return { ...state, sessions: currentSession, isFetching: false };
    }
    case SESSIONS_DELETE: {
      const orderSessions = state.sessions[action.orderId];
      const currentSession = { ...state.sessions };
      currentSession[action.orderId] = orderSessions.filter(session => session._id !== action.sessionId);
      return { ...state, sessions: currentSession, isFetching: false };
    }
    default:
      return state;
  }
};

export default clientSessions;
