import moment from 'moment-timezone';

import { urls } from './../../config';
import authFetch from './../../utils/authFetch';

import { ordersAddList } from './../orders/actions';
import { clearNewOrdersNotifications } from '../ordersNotifications/actions';

export const ORDERS_FIND_RECEIVE = 'ORDERS_FIND_RECEIVE';
export const ORDERS_FIND_REQUEST = 'ORDERS_FIND_REQUEST';

export const ORDERS_FIND_SET_PRICE_BOUNDS = 'ORDERS_FIND_SET_PRICE_BOUNDS';

export const ORDERS_FIND_SET_FILTER_HOT = 'ORDERS_FIND_SET_FILTER_HOT';
export const ORDERS_FIND_SET_FILTER_MY = 'ORDERS_FIND_SET_FILTER_MY';
export const ORDERS_FIND_SET_FILTER_RECOMMENDED = 'ORDERS_FIND_SET_FILTER_RECOMMENDED';
export const ORDERS_FIND_SET_FILTER_SEARCH = 'ORDERS_FIND_SET_FILTER_SEARCH';
export const ORDERS_FIND_SET_FILTER_STATUSES = 'ORDERS_FIND_SET_FILTER_STATUSES';
export const ORDERS_FIND_SET_FILTER_SUBJECT = 'ORDERS_FIND_SET_FILTER_SUBJECT';
export const ORDERS_FIND_SET_FILTER_SUBJECT_GROUP = 'ORDERS_FIND_SET_FILTER_SUBJECT_GROUP';
export const ORDERS_FIND_SET_FILTER_SUBJECT_GROUP_WITH_SUBJECTS = 'ORDERS_FIND_SET_FILTER_SUBJECT_GROUP_WITH_SUBJECTS';
export const ORDERS_FIND_SET_FILTER_TIME = 'ORDERS_FIND_SET_FILTER_TIME';
export const ORDERS_FIND_SET_FILTER_TYPE = 'ORDERS_FIND_SET_FILTER_TYPE';
export const ORDERS_FIND_SET_FILTER_ONLINE = 'ORDERS_FIND_SET_FILTER_ONLINE';
export const ORDERS_FIND_SET_FILTER_STEP = 'ORDERS_FIND_SET_FILTER_STEP';
export const ORDERS_FIND_SET_FILTER_RESERVER_BY_OTHER = 'ORDERS_FIND_SET_FILTER_RESERVER_BY_OTHER';
export const ORDERS_FIND_SET_SORTING = 'ORDERS_FIND_SET_SORTING';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';

export const ORDERS_FIND_UPDATE = 'ORDERS_FIND_UPDATE';
export const ORDERS_HIDE_FROM_TABLE = 'ORDERS_HIDE_FROM_TABLE';

export {
  ORDERS_TYPE_ALL,
  ORDERS_TYPE_FAVORITE,
  ORDERS_TYPE_RESERVED,
} from './orderTypes';

const ordersFindSetPriceBounds = (min, max, step) => ({
  type: ORDERS_FIND_SET_PRICE_BOUNDS,
  min,
  max,
  step,
});

const ordersFindRequest = () => ({
  type: ORDERS_FIND_REQUEST,
});

const ordersFindReceive = (numbers, items) => ({
  type: ORDERS_FIND_RECEIVE,
  numbers,
  items,
});

export const ordersFindUpdate = numbers => ({
  type: ORDERS_FIND_UPDATE,
  numbers,
});

export const removeOrdersFromTable = number => ({
  type: ORDERS_HIDE_FROM_TABLE,
  number,
});

const calcPerWord = (order) => {
  if (order.pages) {
    order.pricePerWords = (order.writer_price / order.pages) || order.writer_price;
  } else order.pricePerPage = order.writer_price || 1;
};

const loadOrdersCallback = (dispatch, json, initial = true) => {
  const numbers = json.results.map(order => order.number);
  const items = json.results.reduce((result, item) => {
    if (initial) item.didInvalidate = true;
    calcPerWord(item);
    result[item.number] = item;
    return result;
  }, {});
  dispatch(clearNewOrdersNotifications(true));
  dispatch(ordersAddList(items));
  dispatch(ordersFindReceive(numbers, items));
  const { min, max, step } = calculatePriceBounds(json.results);
  dispatch(ordersFindSetPriceBounds(min, max, step));
}

export const ordersFindFetch = () => {
  return dispatch => {
    dispatch(ordersFindRequest());
    return authFetch(urls.orders).then(json => {
      loadOrdersCallback(dispatch, json);
    });
  };
};

export const quietFindFetch = () => dispatch => {
  return authFetch(urls.orders).then(json => {
    loadOrdersCallback(dispatch, json, false);
  });
};

const ordersFindShouldFetch = state => {
  const ordersFind = state.ordersFind;
  if (ordersFind.isFetching) {
    return false;
  }
  if (ordersFind.updatedAt && moment().diff(ordersFind.updatedAt, 'minutes') > 1) {
    return true;
  }
  return ordersFind.didInvalidate;
};

export const ordersFindFetchIfNeeded = () => (dispatch, getState) => {
  if (ordersFindShouldFetch(getState())) {
    return dispatch(ordersFindFetch());
  }
  return Promise.resolve();
};

export const ordersFindSetSorting = value => ({
  type: ORDERS_FIND_SET_SORTING,
  value,
});

export const ordersSetFilterTime = (typeId, value) => (dispatch) => {
  dispatch(clearNewOrdersNotifications());
  dispatch({
    type: ORDERS_FIND_SET_FILTER_TIME,
    typeId,
    value,
  });
};

export const ordersSetFilterStep = (typeId, value) => (dispatch) => {
  dispatch({
    type: ORDERS_FIND_SET_FILTER_STEP,
    typeId,
    value,
  });
};

export const ordersSetFilterRecommended = (typeId, value) => ({
  type: ORDERS_FIND_SET_FILTER_RECOMMENDED,
  typeId,
  value,
});

export const ordersSetFilterHot = (typeId, value) => ({
  type: ORDERS_FIND_SET_FILTER_HOT,
  typeId,
  value,
});

export const ordersSetFilterMy = (typeId, value) => ({
  type: ORDERS_FIND_SET_FILTER_MY,
  typeId,
  value,
});

export const ordersSetFilterSearch = (typeId, value) => ({
  type: ORDERS_FIND_SET_FILTER_SEARCH,
  typeId,
  value,
});

export const resetAllFilters = typeId => ({
  type: RESET_ALL_FILTERS,
  typeId,
});
export const ordersFindSetFilterSubject = (typeId, value) => (dispatch) => {
  dispatch(clearNewOrdersNotifications());
  dispatch({
    type: ORDERS_FIND_SET_FILTER_SUBJECT,
    typeId,
    value,
  });
};

export const ordersFindSetFilterSubjectGroup = (typeId, value) => (dispatch) => {
  dispatch(clearNewOrdersNotifications());
  dispatch({
    type: ORDERS_FIND_SET_FILTER_SUBJECT_GROUP,
    typeId,
    value,
  });
};

export const ordersFindSetFilterSubjectGroupWithSubjects = (typeId, value) => (dispatch) => {
  dispatch(clearNewOrdersNotifications());
  dispatch({
    type: ORDERS_FIND_SET_FILTER_SUBJECT_GROUP_WITH_SUBJECTS,
    typeId,
    value,
  });
};

export const ordersFindSetFilterType = (typeId, value) => (dispatch) => {
  dispatch(clearNewOrdersNotifications());
  dispatch({
    type: ORDERS_FIND_SET_FILTER_TYPE,
    typeId,
    value,
  });
};

export const ordersFindSetFilterOnline = (typeId, value) => (dispatch) => {
  dispatch({
    type: ORDERS_FIND_SET_FILTER_ONLINE,
    typeId,
    value,
  });
};

export const setFilterReservedByOther = (typeId, value) => (dispatch) => {
  dispatch({
    type: ORDERS_FIND_SET_FILTER_RESERVER_BY_OTHER,
    typeId,
    value,
  });
};

export const setFilterStatuses = (typeId, set, value) => (dispatch) => {
  dispatch(clearNewOrdersNotifications());
  dispatch({
    type: ORDERS_FIND_SET_FILTER_STATUSES,
    typeId,
    set,
    value,
  });
};

const calculatePriceBounds = (orderList) => {
  const prices = orderList.map(order => order.writer_price);
  let max = prices.length ? Math.max(...prices) : 0;
  const stepNumber = 20;
  let step = Math.ceil(max / stepNumber);

  if (step > 75) {
    // do nothing
  } else if (step > 50) {
    step = 75;
  } else if (step > 30) {
    step = 50;
  } else if (step > 20) {
    step = 30;
  } else if (step > 15) {
    step = 20;
  } else if (step > 10) {
    step = 15;
  } else if (step > 5) {
    step = 10;
  }

  max = step * stepNumber;
  return { min: 0, max, step };
};
