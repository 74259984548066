import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userSetFieldFetch } from 'store/user/actions';

import Dropdown from 'components/Dropdown';


const fieldName = 'password';

class PasswordDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      password_confirm: '',
    };

    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
  }

  onChange(event) {
    const { name } = event.target;
    const { value } = event.target;
    this.setState({ [name]: value });
  }

  save(event) {
    const { password } = this.state;
    const { save } = this.props;
    save(password);
    this.setState({ password: '' });
  }

  render() {
    const { password, password_confirm } = this.state;
    const isError = !!password && !!password_confirm && password !== password_confirm;
    const isBtnDisabled = !password || !password_confirm || password !== password_confirm;

    return (
      <Dropdown buttonClassName="lnk-dropdown" text="password">
        <input
          className="input-text mb10"
          type="password"
          maxLength="100"
          onChange={this.onChange}
          autoFocus
          value={password}
          name="password"
          placeholder="Enter new password"
        />

        <input
          className="input-text mb10"
          type="password"
          maxLength="100"
          onChange={this.onChange}
          value={password_confirm}
          name="password_confirm"
          placeholder="Confirm password"
        />


        <div className="desc_password text-danger mb10">
          {isError &&
          <span>Confirm password is not valid</span>
                        }
        </div>

        <button className="btn btn-bright btn-block ta-center" onClick={this.save} data-dropdown="close" disabled={isBtnDisabled}>Update</button>
      </Dropdown>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  save: value => dispatch(userSetFieldFetch(fieldName, value)),
});

export default connect(null, mapDispatchToProps)(PasswordDropdown);
