import React from 'react';

import Modal from 'components/Modal';

import tooLate from 'img/icons/too-late-ill.svg';

const OrderNaModal = (props) => {
  const { onClose, message } = props;
  return (
    <Modal onClose={onClose} className="modal-sm ta-center">
      <img className="modal-icon" src={tooLate} alt="" />
      {message &&
        <h2 className="title mb30">{message}</h2>
      }
      {!message &&
        <h2 className="title mb30">Sorry, but this order is <br />no longer available</h2>
      }

      <button type="button" className="btn btn-bright btn-big" onClick={onClose}>Got it</button>
    </Modal>
  );
};

export default OrderNaModal;
