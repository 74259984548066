import React from 'react';
import { MessageText } from '@connect/connect-ui-js';

import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';

import { MSG_MAP } from '../constants';
import Estimations from '../Estimations';

const REPLACE_MAP = {
  '[00 hours 00 minutes]': (eventText, eventParams) => {
    const { size = '' } = eventParams;
    const [__size, __type] = size.split(' ');
    let newSize = size;
    if (__type === 'minutes') {
      newSize = convertMinsToHrsMins(__size);
    }

    return eventText.replace('[00 hours 00 minutes]', newSize);
  },
  '[TutorName]': (eventText, eventParams) => {
    const { freelancers_public_name: tutorName } = eventParams;

    return eventText.replace('[TutorName]', tutorName || '');
  },
  '[msg:confirmation]': (eventText, eventParams) => {
    const { confirmation = 'This is your chat with the student. Please make sure you have all of the inputs you need to help them out.' } = eventParams;

    return eventText.replace('[msg:confirmation]', confirmation || '');
  },
};

const REPLACE_REGEX = /\[(.*?)\]/;

const CardMessage = ({
  msgData, getPic, profile, order,
}) => {
  const { event: { event_name: eventName = '', event_params: eventParams = {} } } = msgData;
  const { freelancer_id: freelancerId, auto_confirm: autoConfirm } = eventParams;
  const { _id: writerId } = profile;
  let message = '';

  const isMyEvent = freelancerId === writerId;
  const eventText = MSG_MAP[eventName];

  if (!eventText) return null;

  if (Array.isArray(eventText)) {
    message = isMyEvent ? eventText[0] : eventText[1];
  } else {
    message = eventText;
  }

  const replaceRegex = REPLACE_REGEX.exec(message);

  if (replaceRegex) {
    const [replaceString] = replaceRegex;

    const replaceFunction = REPLACE_MAP[replaceString];

    if (replaceFunction) {
      message = replaceFunction(message, eventParams);
    }
  }

  if (autoConfirm && eventName === 'crm:estimate_paid') {
    return (
      <div className="mt4 mb4">
        {React.createElement(Estimations, {
            msgData, order, getPic, profile,
        })}
      </div>
    );
  }

  return (
    <div className="mt4 mb4">
      <MessageText
        data={{ ...msgData, message }}
        getPic={getPic}
      />
    </div>
  );
};

export default CardMessage;
