function classNames() {
    const classes = [];
    [...arguments].forEach(arg => {
        if (!arg) {
            return;
        }
        const type = typeof arg;
        if (type === 'string' || type === 'number') {
            classes.push(arg);
        }
        if (type === 'object') {
            Object.keys(arg).forEach(key => arg[key] && classes.push(key));
        }
    });
    return classes.join(' ');
};

export default classNames;