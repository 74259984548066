import React from 'react';

import Tooltip from 'components/Tooltip';

const TOOLTIP_CONTENT = [
  {
    content: 'He oppose at thrown desire of no.',
    customClass: '',
    text: 'I`m simple',
  },
  {
    content: 'Out too the been like hard off. Improve enquire welcome own beloved matters her. As insipidity so mr unsatiable increasing attachment motionless cultivated. Addition mr husbands unpacked occasion he oh. Is unsatiable if projecting boisterous insensible. It recommend be resolving pretended middleton.',
    text: 'I`m max width 270px',
    mW270: true,
    withWrapContent: true,
  },
  {
    content: 'Led ask possible mistress relation elegance eat likewise debating. By message or am nothing amongst chiefly address. The its enable direct men depend highly. Ham windows sixteen who inquiry fortune demands. Is be upon sang fond must shew. Really boy law county she unable her sister. Feet you off its like like six. Among sex are leave law built now. In built table in an rapid blush. Merits behind on afraid or warmly.',
    text: 'I`m right and max width 270px',
    mW270: true,
    withWrapContent: true,
    positionRight: true,
  },
  {
    content: 'Chapter too parties its letters nor. Cheerful but whatever ladyship disposed yet judgment. Lasted answer oppose to ye months no esteem. Branched is on an ecstatic directly it. Put off continue you denoting returned juvenile. Looked person sister result mr to. Replied demands charmed do viewing ye colonel to so. Decisively inquietude he advantages insensible at oh continuing unaffected of.',
    text: 'I`m left and max width 270px',
    mW270: true,
    withWrapContent: true,
    positionLeft: true,
  },
  {
    content: [
      '<div>',
      '<div class="mb4">Check task details and send your estimate - how much time do you need to complete this task:</div>',
      '<ul>',
      '<li>Marcus Tullius Cicero</li>',
      '<li>The Extremes of Good and Evil</li>',
      '<li>Finibus Bonorum et Malorum</li>',
      '</ul>',
      '</div>',
    ].join(' '),
    text: 'I`m with list and max width 270px',
    mW270: true,
    withWrapContent: true,
    isHtml: true,
    withList: true,
  },
  {
    content: 'Cause dried no solid no an small so still widen. Ten weather evident smiling bed against she examine its. Rendered far opinions two yet moderate sex striking. Sufficient motionless compliment by stimulated assistance at. Convinced resolving extensive agreeable in it on as remainder. Cordially say affection met who propriety him. Are man she towards private weather pleased. In more part he lose need so want rank no. At bringing or he sensible pleasure. Prevent he parlors do waiting be females an message society.',
    text: 'I`m bottom with arrow and max width 270px',
    mW270: true,
    withWrapContent: true,
    positionDown: true,
  },
  {
    content: 'No opinions answered oh felicity is resolved hastened. Produced it friendly my if opinions humoured. Enjoy is wrong folly no taken. It sufficient instrument insipidity simplicity at interested. Law pleasure attended differed mrs fat and formerly. Merely thrown garret her law danger him son better excuse. Effect extent narrow in up chatty. Small are his chief offer happy had.',
    text: 'I`m bottom with out arrow and max width 270px',
    mW270: true,
    withWrapContent: true,
    positionDown: true,
    noArrow: true,
  },
  {
    content: 'He oppose at thrown desire of no. Announcing impression unaffected day his are unreserved indulgence. Him hard find read are you sang. Parlors visited noisier how explain pleased his see suppose.',
    text: 'I`m white background with grey text and max width 270px',
    mW270: true,
    withWrapContent: true,
    whiteWithGreyText: true,
  },
];

const TooltipStory = () => (
  <div>
    {
        TOOLTIP_CONTENT.map(({
 content, text, ...rest
}) => <div key={text} className="mb12"><Tooltip {...rest} content={content}><a>{text}</a></Tooltip></div>)
      }
  </div>
);

export default TooltipStory;
