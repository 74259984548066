import React from 'react';

import Modal from 'components/Modal';

import '../styles/formModal.scss';

import FormModalInner from './FormModal';

const FormModal = ({ toggleFormWireTransferModal, profile, countryList, savePayMethodValue }) => {
  return (
    <Modal wrapperClassName="wire-transfer-form">
      <FormModalInner savePayMethodValue={savePayMethodValue} toggleFormWireTransferModal={toggleFormWireTransferModal} profile={profile} countryList={countryList} />
    </Modal>
  );
};

export default FormModal;
