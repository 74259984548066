import React from 'react';
import { ThemeProvider } from 'styled-components';
import { themeDefault } from '@connect/connect-ui-js';

import imageSet from './img';

const colorBlack = '#000000';
const colorWhite = '#FFFFFF';

const colorBlue = '#389bfe';

const colorGrey1 = '#f2f3f5';
const colorGrey2 = '#474747';
const colorGrey3 = '#71737A';
const colorGrey4 = '#969AA3';
const colorGrey6 = '#F2F2F2';

const colorGreyText = '#637280';

const colorSuccess1 = '#34C759';

const colorError1 = '#ff3c3c';

const colorTypographyPrimary = '#474747';

const typography = {
  headline1: {
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 28,
    letterSpacing: -0.45,
  },
  body1: {
    fontFamily: 'Lato',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: -0.32,
  },
  body2: {
    fontFamily: 'Lato',
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.15,
  },
  capture1: {
    fontFamily: 'Lato',
    fontSize: 12,
    lineHeight: 20,
  },
};

const boxShadow = '0px 4px 8px rgba(0, 53, 136, 0.1)';

export const theme = themeDefault;

theme.connectUi.smallPadding = 8;

theme.connectUi.edgePadding = 10;

theme.connectUi.messageInput.font = typography.body1;
theme.connectUi.messageInput.color = colorGrey1;
theme.connectUi.messageInput.borderColor = 'transparent';
theme.connectUi.messageInput.placeholderColor = colorGrey4;
theme.connectUi.messageInput.backgroundColor = colorGrey6;
theme.connectUi.messageInput.borderTopColor = colorGrey6;


theme.connectUi.message.shadowOffset = {
  width: 0,
  height: 4,
};

theme.connectUi.audio.playBtn.outBackground = colorWhite;
theme.connectUi.audio.playBtn.outIconColor = colorBlue;

theme.connectUi.audio.pauseBtn.outBackground = colorWhite;
theme.connectUi.audio.pauseBtn.outIconColor = colorBlue;

theme.connectUi.audio.playBtn.inBackground = colorBlue;
theme.connectUi.audio.playBtn.inIconColor = colorWhite;

theme.connectUi.audio.pauseBtn.inBackground = colorBlue;
theme.connectUi.audio.pauseBtn.inIconColor = colorWhite;

theme.connectUi.message.maxWidth = '75%';
theme.connectUi.message.font = typography.body1;
theme.connectUi.message.timeBackground = colorBlack;
theme.connectUi.message.timeOpacity = 0.7;

theme.connectUi.message.inBackgroundHover = colorGrey1;
theme.connectUi.message.inBackground = colorGrey1;
theme.connectUi.message.inColor = colorGreyText;
theme.connectUi.message.inTimeColor = colorGreyText;

theme.connectUi.message.outBackgroundHover = colorBlue;
theme.connectUi.message.outBackground = colorBlue;
theme.connectUi.message.outTimeColor = colorWhite;

theme.connectUi.message.progressColor = colorSuccess1;
theme.connectUi.message.textIndent = 70;
theme.connectUi.message.showError = false;

theme.connectUi.dropdown.color = colorGrey1;
theme.connectUi.dropdown.deleteColor = colorError1;

theme.connectUi.header.font = typography.headline1;
theme.connectUi.header.titleColor = colorTypographyPrimary;

theme.connectUi.messageList.backgroundColor = colorWhite;
theme.connectUi.messageList.typingDotColor = colorBlue;
// audio record
theme.connectUi.audioRecord.header.font = typography.headline1;
theme.connectUi.audioRecord.header.color = colorGrey2;

theme.connectUi.audioRecord.subHeader.font = typography.body2;
theme.connectUi.audioRecord.subHeader.color = colorGrey3;
theme.connectUi.audioRecord.recordTime.font = typography.headline1;
theme.connectUi.audioRecord.recordTime.color = colorGrey2;

theme.connectUi.audioRecord.buttonMic.backgroundColor = colorBlue;
theme.connectUi.audioRecord.buttonMic.backgroundHoverColor = colorBlue;

theme.connectUi.audioRecord.buttonRePlay.backgroundColorPlay = colorBlue;
theme.connectUi.audioRecord.buttonRePlay.backgroundHoverColorPlay = colorBlue;
theme.connectUi.audioRecord.buttonRePlay.backgroundColorPause = colorGrey6;
theme.connectUi.audioRecord.buttonRePlay.backgroundHoverColorPause = colorGrey6;

theme.connectUi.audioRecord.buttonTrash.label.font = typography.capture1;
theme.connectUi.audioRecord.buttonTrash.label.color = colorGrey2;
theme.connectUi.audioRecord.buttonTrash.label.font = typography.capture1;
theme.connectUi.audioRecord.buttonTrash.label.color = colorGrey2;

theme.connectUi.audioRecord.buttonSend.label.font = typography.capture1;
theme.connectUi.audioRecord.buttonSend.label.color = colorGrey2;

theme.connectUi.imageSet = imageSet;
theme.connectUi.typography = typography;
theme.connectUi.boxShadow = boxShadow;
theme.connectUi.message.boxShadow = boxShadow;

theme.connectUi.btnSend = {
  default: colorBlue,
  hover: {
    background: 'transparent',
    color: colorBlue,
  },
  active: {
    color: colorBlue,
    background: 'transparent',
  },
};

theme.connectUi.btnAttach = {
  default: colorBlue,
  hover: {
    background: 'transparent',
    color: colorBlue,
  },
  active: {
    color: colorBlue,
    background: 'transparent',
  },
};

theme.connectUi.btnClose = {
  default: colorBlue,
  hover: {
    background: 'transparent',
    color: colorBlue,
  },
  active: {
    background: 'transparent',
    color: colorBlue,
  },
};

theme.connectUi.btnWarning = {
  default: colorError1,
  hover: {
    color: colorError1,
  },
  active: {
    color: colorError1,
  },
};

theme.connectUi.btnTrash = {
  default: colorBlue,
  hover: {
    background: 'transparent',
    color: colorBlue,
  },
  active: {
    color: colorBlue,
    background: 'transparent',
  },
};

theme.connectUi.btnMic = {
  default: colorBlue,
  hover: {
    background: 'transparent',
    color: colorBlue,
  },
  active: {
    color: colorBlue,
    background: 'transparent',
  },
};


theme.connectUi.badgeBackground = colorBlue;
theme.connectUi.badgeColor = colorWhite;
theme.connectUi.badgeBorderRadius = 12;
theme.connectUi.badgeMinWidth = 20;
theme.connectUi.badgeHeight = 20;
theme.connectUi.badgeTop = -10;

theme.connectUi.colors.backgroundColor = colorWhite;
theme.connectUi.colors.dateSeparatorColor = colorGrey4;

theme.connectUi.events.cardContainer.background = colorGrey1;
theme.connectUi.events.cardContainer.timeColor = colorGreyText;


const ThemeUIProvider = props => <ThemeProvider theme={theme} {...props} />;

export default ThemeUIProvider;
