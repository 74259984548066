import React from 'react';
import { useLocation } from 'react-router-dom';

const withPathNoRender = (Component, path) => {
  const WrapComponent = (props) => {
    const location = useLocation();
    const { renderAnyWay = false } = props;

    if (!renderAnyWay && location.pathname.includes(path)) return null;

    return (
      <Component
        {...props}
      />
    );
  };

  return WrapComponent;
};

export default withPathNoRender;
