const issuesList = [
  {
    name: 'I’d like to accept this order, but it has issues',
    type: 'success',
    reaction: 'good',
    title: 'I’d like to accept this order, but...',
  },
  {
    name: 'I’m not interested in this order, but I’d like to leave some feedback',
    type: 'danger',
    reaction: 'bad',
    title: 'I’m not interested in this order, but...',
  },
];

// const subIssueList = ['Order size', 'Deadline', 'Instructions', 'Sources'];
// const orderSizeList = [0.5, 1, 2, 3];
const subIssueList = ['Order price should be higher', 'Order deadline is too short', 'Instructions are not okay', 'Order is too complicated for my expertise'];
const orderSizeList = [['+30min', 30], ['+1h', 60], ['+2h', 120], ['+3h', 180]];
const instructionsList = [['Missing instructions', 'Missing instructions'], ['Contradictory instructions', 'Contradictory instructions'], ['Missing required sources', 'Missing required sources'], ['Corrupted attachments', 'Corrupted attachments']];
const sourcesList = [['Missing sources', 'Missing sources'], ['Corrupted attachments', 'Corrupted attachments']];
const typeIssue = {
  ORDER_SIZE: 'Order size',
  DEADLINE: 'Deadline',
  INSTRUCTIONS: 'Instructions',
  SOURCES: 'Sources',
  PRICE: 'Order price should be higher',
  DEADLINE_TOO_SHORT: 'Order deadline is too short',
  INSTRUCTIONS_NOT_OK: 'Instructions are not okay',
  TOO_COMPLICATED: 'Order is too complicated for my expertise',
};

const ISSUE_TYPE = {
  instructions: 'Instructions',
  size: 'Order size',
  deadline: 'Deadline',
  sources: 'Sources',
  price: 'Order price should be higher',
  deadline_too_short: 'Order deadline is too short',
  instructions_not_ok: 'Instructions are not okay',
  too_complicated: 'Order is too complicated for my expertise',
};

const FEEDBACK_STATUS_MAPPING = {
  new: 'Under review',
  cancelled: 'Cancelled',
  resolved: 'Resolved',
};

const FEEDBACK_STATUS = [
  {
    name: 'Under review',
    status: 'new',
  },
  {
    name: 'Resolved',
    status: 'resolved',
  },
  {
    name: 'Cancelled',
    status: 'cancelled',
  }
];

const DEADLINE_PERCENTS_PRESETS = [85, 94];
const MIN_DEADLINE_RANGE = 86;
const MAX_DEADLINE_RANGE = 300;

export { issuesList, subIssueList, orderSizeList, instructionsList, sourcesList, typeIssue, ISSUE_TYPE, FEEDBACK_STATUS_MAPPING, FEEDBACK_STATUS, DEADLINE_PERCENTS_PRESETS, MIN_DEADLINE_RANGE, MAX_DEADLINE_RANGE };
