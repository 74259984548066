export const ORDERS_ADD_LIST = 'ORDERS_ADD_LIST';

export const ORDERS_DETAILS_REQUEST = 'ORDERS_DETAILS_REQUEST';
export const ORDERS_DETAILS_RECEIVE = 'ORDERS_DETAILS_RECEIVE';
export const ORDERS_DETAILS_INVALIDATE = 'ORDERS_DETAILS_INVALIDATE';
export const ORDERS_DETAILS_NA_ERROR = 'ORDERS_DETAILS_NA_ERROR';
export const ORDERS_DETAILS_STATUS_ERROR = 'ORDERS_DETAILS_STATUS_ERROR';
export const ORDERS_DETAILS_ERROR = 'ORDERS_DETAILS_ERROR';

export const ORDERS_SET_FAVORITE_REQUEST = 'ORDERS_SET_FAVORITE_REQUEST';
export const ORDERS_SET_FAVORITE_RECEIVE = 'ORDERS_SET_FAVORITE_RECEIVE';
export const ORDERS_SET_VIEWED_REQUEST = 'ORDERS_SET_VIEWED_REQUEST';
export const ORDERS_SET_VIEWED_RECEIVE = 'ORDERS_SET_VIEWED_RECEIVE';
export const ORDERS_SET_NEW_ORDER_RECEIVE = 'ORDERS_SET_NEW_ORDER_RECEIVE';
export const ORDERS_SET_RESERVED_RECEIVE = 'ORDERS_SET_RESERVED_RECEIVE';
export const ORDERS_SET_STATUS_REQUEST = 'ORDERS_SET_STATUS_REQUEST';
export const ORDERS_SET_STATUS_RECEIVE = 'ORDERS_SET_STATUS_RECEIVE';

export const ORDERS_SET_RESERVATION_OVER = 'ORDERS_SET_RESERVATION_OVER';

export const ORDERS_FILES_REQUEST = 'ORDERS_FILES_REQUEST';
export const ORDERS_FILES_RECEIVE = 'ORDERS_FILES_RECEIVE';
export const ORDERS_FILES_UPLOAD_REQUEST = 'ORDERS_FILES_UPLOAD_REQUEST';
export const ORDERS_FILES_UPLOAD_PROGRESS = 'ORDERS_FILES_UPLOAD_PROGRESS';
export const ORDERS_FILES_UPLOAD_COMPLETE = 'ORDERS_FILES_UPLOAD_COMPLETE';
export const ORDERS_FILES_UPLOAD_RECEIVE = 'ORDERS_FILES_UPLOAD_RECEIVE';
export const ORDERS_FILES_UPLOAD_ERROR = 'ORDERS_FILES_UPLOAD_ERROR';
export const ORDERS_FILES_REMOVE_REQUEST = 'ORDERS_FILES_REMOVE_REQUEST';
export const ORDERS_FILES_REMOVE_RECEIVE = 'ORDERS_FILES_REMOVE_RECEIVE';

export const ORDERS_MESSAGES_ADD_CONFIRM_REQUEST = 'ORDERS_MESSAGES_ADD_CONFIRM_REQUEST';
export const ORDERS_MESSAGES_ADD_CONFIRM_RECEIVE = 'ORDERS_MESSAGES_ADD_CONFIRM_RECEIVE';
export const ORDERS_MESSAGES_DELETE_CONFIRM_REQUEST = 'ORDERS_MESSAGES_DELETE_CONFIRM_REQUEST';
export const ORDERS_MESSAGES_DELETE_CONFIRM_RECEIVE = 'ORDERS_MESSAGES_DELETE_CONFIRM_RECEIVE';
export const ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE = 'ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE';

export const ORDERS_MESSAGES_REQUEST = 'ORDERS_MESSAGES_REQUEST';
export const ORDERS_MESSAGES_RECEIVE = 'ORDERS_MESSAGES_RECEIVE';
export const ORDERS_MESSAGES_SET_READ_REQUEST = 'ORDERS_MESSAGES_SET_READ_REQUEST';
export const ORDERS_MESSAGES_SET_READ_RECEIVE = 'ORDERS_MESSAGES_SET_READ_RECEIVE';
export const ORDERS_MESSAGES_ADD_REQUEST = 'ORDERS_MESSAGES_ADD_REQUEST';
export const ORDERS_MESSAGES_ADD_RECEIVE = 'ORDERS_MESSAGES_ADD_RECEIVE';

export const ORDERS_MESSAGES_ADD_ATTACH_RECEIVE = 'ORDERS_MESSAGES_ADD_ATTACH_RECEIVE';

export const ORDERS_REPORT_REQUEST = 'ORDERS_REPORT_REQUEST';
export const ORDERS_REPORT_RECEIVE = 'ORDERS_REPORT_RECEIVE';


export const ORDERS_DDL_EXTENSION_REQUEST = 'ORDERS_DDL_EXTENSION_REQUEST';
export const ORDERS_DDL_EXTENSION_RECEIVE = 'ORDERS_DDL_EXTENSION_RECEIVE';
export const ORDERS_DDL_EXTENSION_ERROR = 'ORDERS_DDL_EXTENSION_ERROR';

export const ORDERS_FIELD_UPDATE = 'ORDERS_FIELD_UPDATE';
