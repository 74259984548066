import React from 'react';
import Icon from 'components/Icon';

import { SERVICE_REPORTS_TYPES } from 'components/WriterTicketing/constants/writerTicketing';

const ModalFooter = (props) => {
  const { onCancel, onCancelTicket, onSubmit, onReset } = props;
  const { showIssueError, issueErrorText, isDisabledReset, isConvertMessage, orderId, isDisableSubmit, ticket } = props;

  if (!ticket) {
    return (
      <div className="modal-footer">
        {
          showIssueError &&
          <div className="modal-footer__error-block">
            {issueErrorText || 'Issue Error'}
          </div>
        }
        <div className="btn-group">
          {!isDisabledReset && !isConvertMessage && !orderId &&
            <div className="reset-filter">
              <a onClick={onReset}><Icon className="svg-icon" iconName="close" />Reset form</a>
            </div>
          }
          <button type="button" className="btn btn-light btn-sm" onClick={onCancel}>Cancel</button>
          <button type="button" disabled={isDisableSubmit} className="btn btn-bright btn-sm" onClick={onSubmit}>Submit</button>
        </div>
      </div>
    );
  }

  const { aasm_state = '', subtype } = ticket || {};
  if (aasm_state.toLowerCase() === 'active' && !SERVICE_REPORTS_TYPES.includes(subtype)) {
    return (
      <div className="btn-group pt20">
        <button type="button" className="btn btn-light" onClick={onCancelTicket} data-ticket={ticket.id}>Cancel request</button>
      </div>
    );
  }

  return null;
};

export default ModalFooter;
