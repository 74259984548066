/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { urls } from 'config';

import authFetch from 'utils/authFetch';

import {
  ORDERS_DDL_EXTENSION_REQUEST,
  ORDERS_DDL_EXTENSION_RECEIVE,
  ORDERS_DDL_EXTENSION_ERROR,
} from '../constants';


const ordersDdlExtensionRequest = number => ({
  type: ORDERS_DDL_EXTENSION_REQUEST,
  number,
});

const ordersDdlExtensionReceive = (number, json) => ({
  type: ORDERS_DDL_EXTENSION_RECEIVE,
  number,
  json,
});

const ordersDdlExtensionError = (number, error) => ({
  type: ORDERS_DDL_EXTENSION_ERROR,
  number,
  error,
});

export const ordersSetDdlExtensionFetch = (number, params) => (dispatch, getState) => {
  const order = getState().orders[number] || {};
  const { _id: orderId } = order;
  if (!orderId) {
    console.error('Order is not found');
    return;
  }
  dispatch(ordersDdlExtensionRequest(number));
  return authFetch(`${urls.orders}/${orderId}`, {
    method: 'POST',
    body: JSON.stringify({
      action: 'wdd_extension_request',
      minutes: params.minutes,
      cost: params.cost,
    }),
  })
    .then(json => dispatch(ordersDdlExtensionReceive(number, json)))
    .catch((error) => {
      if (error.status === 422) {
        dispatch(ordersDdlExtensionError(number, error.error_message));
      }
    });
};
