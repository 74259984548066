import React, { useRef, useEffect } from 'react';

import classNames from 'utils/classNames';

import './styles.scss';

const Iframe = ({ iframe, hideLoader, showFrame }) => {
  const refFrame = useRef(null);

  useEffect(() => {
    if (!refFrame.current.children[0]) return;

    refFrame.current.children[0].addEventListener('load', () => {
      hideLoader();
    });

    refFrame.current.children[0].addEventListener('error', () => {
      hideLoader(true);
    });
  });

  return (
    <div
      className={classNames('frame_container', {
        'frame_container--show': showFrame,
      })}
      ref={refFrame}
      dangerouslySetInnerHTML={{ __html: iframe }}
    />
  );
};

export default Iframe;
