import React from 'react';

import Modal from 'components/Modal';
import Icon from 'components/Icon';

import wireTransfer from 'img/icons/wire_transfer_2x.png';

const WelcomeModal = ({ toggleWelcomeWireTransferModal, toggleFormWireTransferModal }) => (
  <Modal onClose={toggleWelcomeWireTransferModal} small>
    <button className="btn btn-close" onClick={toggleWelcomeWireTransferModal}><Icon className="svg-icon" iconName="close" /></button>
    <img src={wireTransfer} alt="" width="107px" className="mb30" />
    <p className="modal-text fsL">We are testing a new payment method right now. Fill in the form to be the first to choose this method as your primary one when the testing is over. Below there's a guide that will help you complete this process quickly.</p>
    <div className="row vertical space-between">
      <a href="https://drive.google.com/file/d/1Wf9T0IY6ZHS_ediqZ-I3D372Xn4ztlwM/view" target="__blank" className="row center_center">
        <div className="as-link-text">Check the Guide</div>
        <i className="icon-link ml8" />
      </a>
      <button className="btn btn-bright btn-big" onClick={toggleFormWireTransferModal}>Fill in the Form</button>
    </div>
  </Modal>
);

export default WelcomeModal;
