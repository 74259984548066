import { SET_ORDER_FILTERED_LIST } from './actions';

export const ordersFilteredFind = (state = {
  numbers: [],
}, action) => {
  switch (action.type) {
    case SET_ORDER_FILTERED_LIST:
      if (action.numbers) {
        return Object.assign({}, state, {
          numbers: action.numbers,
        });
      }
      break;
    default:
      return state;
  }
};

export default ordersFilteredFind;
