
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import modalList from 'constants/modalList';

import { userActionFetch } from 'store/user/actions';
import { closeModalComponent, addModalComponent } from 'store/modals/actions';
import { getProfile } from 'store/user/selectors';

import ReactivatingWarm from './ReactivatingWarm';
import NonReactivatingWarm from './NonReactivatingWarm';


import './styles/index.scss';

const FrozenModal = () => {
  const profile = useSelector(getProfile);
  const {
    is_frozen: isFrozen,
    frozen_warm: isReactivating,
    isFetchingAction: loading,
  } = profile;

  const dispatch = useDispatch();
  const dataModal = modalList.FrozenModal;

  const reactivateWriter = () => {
    dispatch(userActionFetch({ action: 'frozen_warm', ...dataModal }));
    dispatch(closeModalComponent(dataModal.name));
  };

  useEffect(() => {
    if (!isFrozen) return;

    const component = isReactivating ? <ReactivatingWarm /> : <NonReactivatingWarm loading={loading} reactivateWriter={reactivateWriter} />;
    dispatch(addModalComponent({ component, ...dataModal }));
  }, [isFrozen, isReactivating, loading]);

  return null;
};

export default FrozenModal;
