import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { useArray, usePrevious } from 'hooks';

import { addAlert } from 'store/alerts/actions';
import { ordersFeedbackFetch } from 'store/orders/actions';

import utils from 'utils/index';
import formatTime from 'utils/formatTime';

import { subIssueList, orderSizeList, instructionsList, typeIssue, MAX_DEADLINE_RANGE } from 'constants/reportForm';

import IssuePrice from './IssuePrice';
import IssueDeadline from './IssueDeadline';
import IssueInstructions from './IssueInstructions';
import IssueSources from './IssueSources';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Checkbox from '../../Checkbox';

import './index.scss';
import useDeadlineRange from 'hooks/useDeadlineRange';

const DATA_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[000Z]';

const OrderReportForm = ({ orderNumber }) => {
  const dispatch = useDispatch();
  const order = useSelector(state => state.orders[orderNumber]);

  const {
    writer_price: writerPrice,
    final_deadline: finalDeadline,
    client_paid_at: clientPaidAt,
    writer_deadline: writerDeadline,
    isReportSent: isSent,
    tokens_size: tokenSize,
    feedbacks_list: feedbacksList,
    isFetching,
    isFetchingReport,
    reserved_by_me: isReserved,
    is_recommended: isRecommended,
    is_suggested: isSuggested,
    current_writer_in_chat: currentWriterInChat = false,
  } = order;


  const prevIsReserved = usePrevious(isReserved);
  const prevIsRecommended = usePrevious(isRecommended);
  const prevIsSuggested = usePrevious(isSuggested);
  const preCurrentWriterInChat = usePrevious(currentWriterInChat);
  const isLoading = isFetching || isFetchingReport;

  const refOrderReportForm = useRef({
    customSizeHour: '',
    customSizeMin: '',
    sizeInHour: null,
    sizeInMin: null,
    minutes: 0,
    selected: null,
    setOtherDeadline: false,
    priceList: [],
    deadline: [],
    deadlinesCustom: null,
    biddingDisabled: false,
    offerPrice: 0,
    offerTime: 0,
    priceInHour: null,
    customOrderSize: '',
    instructions: [],
    instructionComment: '',
    sourcesComment: '',
    deadlineComment: '',
    reaction: '',
    biddingCloses: {},
    disableDeadline: false,
    isOrderRejected: false,
    currentSizeInHour: null,
    otherPrice: [],
    allPrice: [],
    allHour: 0,
    allMin: 0,
    isExpired: false,
  });

  const [isOpen, setOpen] = useState(false);
  const { array: arSelectIssue, push: arSelectIssuePush, filter: arSelectIssueFilter } = useArray([]);
  const [dataIssue, setDataIssue] = useState(refOrderReportForm.current);

  const dataDeadline = {
    clientPaidAt,
    writerDeadline,
    clientDeadline: finalDeadline,
  };

  const [isDisabledDeadline, presets, startDeadlineRange, calculateCustomDeadlineFromPercent] = useDeadlineRange(dataDeadline);

  const onMountInitialize = () => {
    let updatedDataIssue = { ...refOrderReportForm.current, offerPrice: writerPrice.toFixed(2) };

    let sizeInHour = Math.floor(tokenSize / 60);
    sizeInHour = sizeInHour < 1 ? 0 : sizeInHour;
    const sizeInMin = tokenSize;
    const priceInMin = writerPrice / tokenSize;
    const priceInHour = priceInMin * 60;
    const minutes = sizeInMin % 60;

    if (writerPrice) {
      const priceList = orderSizeList.map(p => ([p[0], p[1], ((p[1] * priceInMin) + writerPrice).toFixed(2)]));
      updatedDataIssue = { ...updatedDataIssue, priceList, allPrice: priceList, priceInMin, sizeInHour, sizeInMin, minutes, priceInHour, allMin: minutes, allHour: sizeInHour };
    }

    updatedDataIssue = {
      ...updatedDataIssue,
      currentSizeInHour: sizeInHour.toFixed(2),
      deadlineRange: [startDeadlineRange, startDeadlineRange],
    };
    setDataIssue(updatedDataIssue);
  };

  const {
    instructionComment,
    currentSizeInHour,
    setOtherDeadline,
    deadlineDateUTC,
    isOrderRejected,
    deadlinesCustom,
    deadlineComment,
    sourcesComment,
    customSizeHour,
    customSizeMin,
    deadlineRange,
    instructions,
    sizeComment,
    priceInHour,
    priceInMin,
    allPrice,
    offerPrice,
    offerTime,
    priceList,
    minutes,
    sizeInHour,
    sizeInMin,
    deadline,
    allHour,
    allMin,
  } = dataIssue;


  useEffect(() => {
    onMountInitialize();
  }, []);

  useEffect(() => {
    if (!currentWriterInChat && preCurrentWriterInChat) return;

    const isReject = (prevIsReserved && !isReserved) || false;
    if ((isReject ||
      (prevIsRecommended && !isRecommended) ||
      (prevIsSuggested && !isSuggested)) && feedbacksList.length === 0) {
      setOpen(true);
      setDataIssue({ ...dataIssue, isOrderRejected: isReject });
    }
  }, [isRecommended, isReserved, prevIsReserved, prevIsRecommended, isRecommended, prevIsSuggested, isSuggested, preCurrentWriterInChat, currentWriterInChat]);


  const onOpenModal = () => {
    setOpen(!isOpen);
  };

  const onSelectIssue = (e) => {
    const { value } = e.target.dataset;
    if (arSelectIssue.includes(value)) {
      return arSelectIssueFilter(i => i !== value);
    }
    return arSelectIssuePush(value);
  };

  const onChangeOrderSize = (value) => {
    const suggestHours = priceList.filter(item => item[1] === Number(value))[0];
    const valAllHour = Math.floor((sizeInMin + Number(suggestHours[1])) / 60);
    const valAllMin = (Number(suggestHours[1]) + sizeInMin) % 60;

    const price = suggestHours[2];
    const time = offerPrice === value ? '' : value;

    let customSizeHourNew = Math.floor((Number(suggestHours[1]) / 60));
    let customSizeMinNew = (Number(suggestHours[1])) % 60;
    customSizeHourNew = customSizeHourNew || '';
    customSizeMinNew = customSizeMinNew || '';

    setDataIssue(__dataIssue => ({
      ...__dataIssue,
      offerTime: time,
      offerPrice: price,
      customOrderSize: '',
      customSizeHour: customSizeHourNew,
      customSizeMin: customSizeMinNew,
      otherPrice: [],
      allPrice: priceList,
      allHour: valAllHour,
      allMin: valAllMin,
    }));
  };

  const getPrice = (min = 0, hour = 0) => {
    const valueMin = Number(min);
    const valueHour = Number(hour);

    const price = ((valueMin * priceInMin) + (valueHour * priceInHour) + writerPrice).toFixed(2);
    let arPrice = [];
    let label = '';

    const valAllHour = Math.floor(((sizeInMin + valueMin) / 60)) + valueHour;
    const valAllMin = (valueMin + sizeInMin) % 60;

    if (valueHour) {
      label = `${valueHour}h `;
    }
    if (valueMin) {
      label = `${label}${valueMin}min`;
    }
    if (label) {
      label = `+${label}`;
      arPrice = [[label, (valueMin + (valueHour * 60)), price]];
    }
    return { price, arPrice, valAllHour, valAllMin };
  };

  const onChangeOrderMin = (e) => {
    const { value } = e.target;
    if ((/^\d*$/.test(value) && parseInt(value) <= 59 && value > 0) || value === '') {
      const { arPrice, price, valAllHour, valAllMin } = getPrice(value, customSizeHour);
      const time = Number(value) + (customSizeHour * 60);

      let allPriceNew = priceList;
      if (allPrice.filter(p => p[1] === time).length > 0) {
        setDataIssue(__dataIssue => ({
          ...__dataIssue, customSizeMin: value,
        }));
        return onChangeOrderSize(time);
      }
      allPriceNew = allPriceNew.concat(arPrice);
      return setDataIssue(__dataIssue => ({
        ...__dataIssue, customSizeHour, customSizeMin: value, offerPrice: price, offerTime: time, otherPrice: arPrice, allPrice: allPriceNew, allHour: valAllHour, allMin: valAllMin,
      }));
    }
  };

  const onChangeOrderHour = (e) => {
    const { value } = e.target;
    if ((/^\d*$/.test(value) && parseInt(value) <= 99 && value > 0) || value === '') {
      const { arPrice, price, valAllHour, valAllMin } = getPrice(customSizeMin, value);
      const time = (value * 60) + Number(customSizeMin);

      let allPriceNew = priceList;
      if (allPrice.filter(p => p[1] === time).length > 0) {
        setDataIssue(__dataIssue => ({
          ...__dataIssue, customSizeHour: value,
        }));
        return onChangeOrderSize(time);
      }

      allPriceNew = allPriceNew.concat(arPrice);
      return setDataIssue(__dataIssue => ({
        ...__dataIssue, customSizeMin, customSizeHour: value, offerPrice: price, offerTime: time, otherPrice: arPrice, allPrice: allPriceNew, allHour: valAllHour, allMin: valAllMin,
      }));
    }
  };


  const onChangeSubIssue = (value, field, isOther) => {
    let data = {
      [field]: [value],
    };
    if (field === 'deadline') {
      const deadlineDate = !isOther ? presets.filter(item => item[1] === value)[0][2].split(' / ')[1] : value.split(' / ')[1];
      const ddlDateUTC = moment(deadlineDate, 'MMM D, h:mm A').utc().format(DATA_FORMAT);

      data = {
        ...data,
        deadlineDateUTC: ddlDateUTC,
        setOtherDeadline: isOther || false,
        deadlinesCustom: isOther ? value : null,
      };
    }

    data = {
      ...dataIssue,
      ...data,
    };

    setDataIssue(data);
  };

  const setDeadline = (ddlRage) => {
    const ddl = calculateCustomDeadlineFromPercent(ddlRage[1]);
    const { wrDate, wrHours } = ddl;

    const ddlFormat = formatTime(wrDate, 'dt');
    const ddlDateUTC = moment(wrDate).utc().format(DATA_FORMAT);
    const ddlCustom = `+${wrHours}h / ${ddlFormat}`;

    setDataIssue(__dataIssue => ({
      ...__dataIssue,
      deadlineRange: ddlRage,
      deadlineDateUTC: ddlDateUTC,
      deadlinesCustom: ddlCustom,
      setOtherDeadline: true,
      deadline: [`${wrHours}`],
    }));
  };

  const onCommentChange = (field, value) => {
    const newValue = utils.deleteNbsp(value);
    setDataIssue(__dataIssue => ({ ...__dataIssue, [field]: newValue }));
  };

  const onSubmit = () => {
    const reports = {};
    const data = {};

    if (isOrderRejected) {
      data.is_order_rejected = true;
    }

    if (arSelectIssue.includes(typeIssue.PRICE)) {
      reports.price = {
        current_hours: Number(currentSizeInHour),
        current_price: writerPrice,
        current_deadline: writerDeadline,
        suggested_price: Number(offerPrice),
        comment: sizeComment,
        hours: allHour,
        minutes: allMin,
      };
    }

    if (arSelectIssue.includes(typeIssue.DEADLINE_TOO_SHORT)) {
      reports.deadline_too_short = {
        hours: Number(deadline[0]),
        comment: deadlineComment,
        suggested_deadline: deadlineDateUTC,
      };
    }

    if (arSelectIssue.includes(typeIssue.INSTRUCTIONS_NOT_OK)) {
      reports.instructions_not_ok = {
        comment: instructionComment,
        reason: instructions[0],
      };
    }

    if (arSelectIssue.includes(typeIssue.TOO_COMPLICATED)) {
      reports.too_complicated = {
        comment: sourcesComment,
      };
    }

    data.reports = reports;
    dispatch(ordersFeedbackFetch(orderNumber, data)).then(() => {
      dispatch(addAlert(<div className="success-container">Thank you for your feedback! The resolution of this issue will be displayed in the Issue <Link to="/issues/order-feedback">Resolution tab.</Link></div>));
      onOpenModal();
    }).catch((error) => {
      console.error(error);
    });
  };

  const checkValid = () => {
    let isValid = true;
    let errorInstructionComment = false;
    let errorPriceComment = false;

    if (arSelectIssue.length < 1) {
      isValid = false;
    }
    if (arSelectIssue.includes(typeIssue.INSTRUCTIONS_NOT_OK) && (!instructionComment || !instructions.length)) {
      isValid = false;
    }
    if (arSelectIssue.includes(typeIssue.INSTRUCTIONS_NOT_OK) && (!instructionComment && instructions.length)) {
      isValid = false;
      errorInstructionComment = true;
    }
    if (arSelectIssue.includes(typeIssue.PRICE) && (!offerTime || !sizeComment)) {
      isValid = false;
    }
    if (arSelectIssue.includes(typeIssue.PRICE) && (offerTime && !sizeComment)) {
      errorPriceComment = true;
    }
    if (arSelectIssue.includes(typeIssue.DEADLINE_TOO_SHORT) && !deadline.length) {
      isValid = false;
    }

    return { isValid, errorInstructionComment, errorPriceComment };
  };

  if (isSent) {
    return (
      <div className="order-report">
        <div className="text-success">
          <Icon className="svg-icon" iconName="check" />
          Report sent
        </div>
      </div>
    );
  }

  const { isValid: isDisabledSubmit, errorInstructionComment, errorPriceComment } = checkValid();

  const isDisabledSelect = arSelectIssue.length === 2;


  return (
    <div className="order-report">
      <Icon className="svg-icon" iconName="finger" />
      <a onClick={onOpenModal} className="add-issue-link">Report the issue</a>

      {isOpen &&
        <Modal className="order-report__issue" isUpper>
          <button className="btn btn-close modal-close" type="button" onClick={onOpenModal}><Icon className="svg-icon" iconName="close" /></button>

          <div className="title-container">
            <h2 className="title">This order does not look appealing to you? Please let us know what’s wrong and we’ll <br /> try to fix it.</h2>
            <h3 className="sub-title">You can select up to 2 issues.</h3>
          </div>

          <div className="order-report__issue__container">
            {subIssueList.map((item) => {
              const isActive = arSelectIssue.some(itemSelected => item === itemSelected);
              const isIssuePrice = item === typeIssue.PRICE;
              const isIssueInstr = item === typeIssue.INSTRUCTIONS_NOT_OK;
              const isIssueSources = item === typeIssue.TOO_COMPLICATED;
              const isIssueDeadline = item === typeIssue.DEADLINE_TOO_SHORT;
              const textSizeInHour = Number(sizeInHour) < 10 ? `0${sizeInHour}` : sizeInHour;
              const isDisabled = (isDisabledSelect && !isActive) || (isIssueDeadline && isDisabledDeadline);

              const suggestPrice = ((priceInHour * customSizeHour) + (priceInMin * customSizeMin)).toFixed(2);

              return (
                <div key={item}>
                  <Checkbox
                    disabled={isDisabled}
                    checked={isActive}
                    onChange={onSelectIssue}
                    value={item}
                  >{item}</Checkbox>

                  {/* PRICE */}
                  {isIssuePrice && isActive &&
                    <IssuePrice
                      textSizeInHour={textSizeInHour}
                      minutes={minutes}
                      offerTime={offerTime}
                      onChangeOrderSize={onChangeOrderSize}
                      allPrice={allPrice}
                      customSizeHour={customSizeHour}
                      onChangeOrderHour={onChangeOrderHour}
                      customSizeMin={customSizeMin}
                      onChangeOrderMin={onChangeOrderMin}
                      suggestPrice={suggestPrice}
                      writerPrice={writerPrice}
                      offerPrice={offerPrice}
                      allHour={allHour}
                      allMin={allMin}
                      onCommentChange={onCommentChange}
                      sizeComment={sizeComment}
                      errorPriceComment={errorPriceComment}
                    />
                  }

                  {/* DEADLINE */}
                  {isIssueDeadline && isActive &&
                    <IssueDeadline
                      chooseValue={deadlinesCustom || deadline}
                      onChangeSubIssue={onChangeSubIssue}
                      arDeadlineList={presets}
                      setOtherDeadline={setOtherDeadline}
                      deadlinesCustom={deadlinesCustom}
                      startDeadlineRange={startDeadlineRange}
                      endDeadlineRange={MAX_DEADLINE_RANGE}
                      deadlineRange={deadlineRange}
                      setDeadline={setDeadline}
                    />
                  }

                  {/* INSTRUCTIONS */}
                  {isIssueInstr && isActive &&
                    <IssueInstructions
                      instructions={instructions}
                      onChangeSubIssue={onChangeSubIssue}
                      instructionsList={instructionsList}
                      onCommentChange={onCommentChange}
                      instructionComment={instructionComment}
                      errorInstructionComment={errorInstructionComment}
                    />
                  }

                  {/* SOURCES */}
                  {isIssueSources && isActive &&
                    <IssueSources
                      onCommentChange={onCommentChange}
                      sourcesComment={sourcesComment}
                    />
                  }
                </div>
              );
            })}
            <p className="order-report__issue__note">We will send a notification and suggest you this order once the issue is addressed.</p>

            {arSelectIssue.length > 0 &&
              <div className="ta-right btn-group">
                <button
                  className="btn btn-bright"
                  type="button"
                  disabled={!isDisabledSubmit || isLoading}
                  onClick={onSubmit}
                >Submit feedback</button>
              </div>
            }
          </div>

        </Modal>
      }
    </div>
  );
};

export default OrderReportForm;

