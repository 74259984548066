import moment from 'moment-timezone';
import {
  FINANCES_REQUEST,
  FINANCES_RECEIVE,

  FINANCES_SET_FILTER_TIME,
  FINANCES_SET_FILTER_TYPE,
  FINANCES_SET_FILTER_STATUS,

  FINANCE_SET_VIEWED_REQUEST,
  FINANCE_SET_VIEWED_RECEIVE,

  FINANCES_WITHDRAWAL_REQUEST,
  FINANCES_WITHDRAWAL_RECEIVE,
  FINANCES_WITHDRAWAL_ERROR,

  FINANCES_CANCEL_REQUEST,
  FINANCES_CANCEL_RECEIVE,

  RESET_FINANCES_FILTERS,
} from './actions';

const finance = (state = {}, action) => {
  switch (action.type) {
    case FINANCE_SET_VIEWED_REQUEST:
      return Object.assign({}, state, {
        isFetchingViewed: true,
      });
    case FINANCE_SET_VIEWED_RECEIVE:
      return Object.assign({}, state, {
        isFetchingViewed: false,
        mark: 'read',
      });
    default:
      return state;
  }
};

const filter = (state = {}, action) => {
  switch (action.type) {
    case FINANCES_SET_FILTER_TIME:
      return Object.assign({}, state, {
        time: action.value,
      });
    case FINANCES_SET_FILTER_TYPE:
      return Object.assign({}, state, {
        type: action.value,
      });
    case FINANCES_SET_FILTER_STATUS:
      return Object.assign({}, state, {
        status: action.value,
      });
    default:
      return state;
  }
};

const finances = (state = {
  isFetching: false,
  didInvalidate: true,
  items: {},
  filter: {
    time: 'all',
    type: 'all',
    status: 'all',
  },
  available: 0,
  pending: 0,
  completed: 0,
  allow_withdrawal: false,
  pending_withdrawal: false,
}, action) => {
  switch (action.type) {
    case FINANCES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case FINANCES_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        updatedAt: moment(),
        items: action.items,
        available: action.available,
        pending: action.pending,
        completed: action.completed,
        execution: action.execution,
        minWithdrawal: action.minWithdrawal,
        nextWithdrawal: action.nextWithdrawal,
        withdrawalTo: action.withdrawalTo,
        allow_withdrawal: action.allow_withdrawal,
        pending_withdrawal: action.pending_withdrawal,
        fee: action.fee,
        feePerc: action.feePerc,
      });
    case FINANCES_SET_FILTER_TIME:
    case FINANCES_SET_FILTER_TYPE:
    case FINANCES_SET_FILTER_STATUS:
      return Object.assign({}, state, {
        filter: filter(state.filter, action),
      });
    case FINANCE_SET_VIEWED_REQUEST:
    case FINANCE_SET_VIEWED_RECEIVE:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.id]: finance(state.items[action.id], action),
        }),
      });
    case FINANCES_WITHDRAWAL_REQUEST:
    case FINANCES_CANCEL_REQUEST:
      return Object.assign({}, state, {
        isFetchingWithdrawal: true,
        withdrawalErrorMessage: null,
      });
    case FINANCES_WITHDRAWAL_RECEIVE:
    case FINANCES_CANCEL_RECEIVE:
      return Object.assign({}, state, {
        isFetchingWithdrawal: false,
        didInvalidate: true,
      });
    case FINANCES_WITHDRAWAL_ERROR:
      return Object.assign({}, state, {
        isFetchingWithdrawal: false,
        withdrawalErrorMessage: action.message,
      });
    case RESET_FINANCES_FILTERS:
      return Object.assign({}, state, {
        filter: {
          time: 'all',
          type: 'all',
          status: 'all',
        },
      });
    default:
      return state;
  }
};

export default finances;
