export const ADD_BANNER = 'ADD_BANNER';
export const DELETE_BANNER = 'DELETE_BANNER';
export const DELETE_ALL_BANNER = 'DELETE_ALL_BANNER';

export const addBanner = payload => ({
  type: ADD_BANNER,
  payload,
});

export const deleteBanner = payload => ({
  type: DELETE_BANNER,
  payload,
});

export const deleteAllBanners = () => (dispatch) => {
  dispatch({
    type: DELETE_ALL_BANNER,
  });
};
