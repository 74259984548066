import { useState } from 'react';

function getItem() {
  const { keyName } = this;
  return localStorage.getItem(keyName);
}

const useLocalStorage = (keyName) => {
  const [lsValue, setLsValue] = useState(getItem.bind({ keyName }));

  const setItem = (key, value) => {
    const newValue = typeof value === 'function' ? value() : value;
    setLsValue(newValue);
    localStorage.setItem(key, newValue);
  };

  const removeItem = (key) => {
    setLsValue('');
    localStorage.removeItem(key);
  };

  return { lsValue, setItem, removeItem };
};

export default useLocalStorage;
