import React, { Fragment, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { removeToastNotifications } from 'store/ordersNotifications/actions';

import Icon from './Icon';

const OrdersNotifications = ({ order = {}, removeToast, newMsg }) => {
  const {
    writer_deadline: writerDeadline,
    subject = [],
    number,
    _id: orderId,
    writer_price: writerPrice,
  } = order;
  const dateDraftDeadline = useMemo(() => moment(writerDeadline || false), [writerDeadline]);
  const subjectLength = subject.length;
  const __subject = subjectLength < 29 ? subject : `${subject.slice(0, 26)}...`;

  const renderHeader = () => {
    if (newMsg) {
      return `You have a new message from the client in ${number}`;
    }

    return `A new order in ${__subject} has just been placed!`;
  };

  const renderTitle = () => {
    if (newMsg) return null;

    return (
      <div className="orders-notifications-body__title">
        {`It costs $${writerPrice} and is due in ${dateDraftDeadline.fromNow(true)}`}
      </div>
    );
  };

  useEffect(() => () => {
    removeToast(number);
  }, []);

  return (
    <Fragment>
      <div className="orders-notifications-body__header">
        {renderHeader()}
      </div>
      {renderTitle()}
      <a href={`/order/${orderId}`} target="_black" onClick={() => removeToast(number)} className="orders-notifications-body__link-block">
        <Icon className="svg-icon" iconName="arrow" />
        <div>Check order</div>
      </a>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  removeToast: id => dispatch(removeToastNotifications(id)),
});

export default connect(null, mapDispatchToProps)(OrdersNotifications);

