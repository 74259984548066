import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'utils/classNames';

import { userSetSubjectsFetch } from 'store/user/actions';

import Checkbox from 'components/Checkbox';

const MAX_COUNT = 7;
const subjectsText = `Please select subjects that you are ready to write about. You can choose not more than ${MAX_COUNT} preferred subjects at a time. Our team leaders will recommend you orders according to these subject preferences.`;

class MySubjectsForm extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.renderCheckbox = this.renderCheckbox.bind(this);
  }

  componentDidUpdate() {
    const { setError, isMobile, selectedSubjects } = this.props;

    if (!isMobile) return;

    setError(selectedSubjects.length > MAX_COUNT);
  }

  onChange(event) {
    const { target } = event;
    const { selectedSubjects, save, isEditable, onChangeHandler, isMobile = false } = this.props;
    if (!isEditable) {
      return;
    }

    if (isMobile && !target.checked && selectedSubjects.length === 1) return;

    const subjects = target.checked ? [...selectedSubjects, target.name] : selectedSubjects.filter(subject => subject !== target.name);
    if (onChangeHandler) {
      onChangeHandler(subjects);
      return;
    }
    save(subjects);
  }

  renderCheckbox(subject) {
    const { selectedSubjects, isLoading } = this.props;
    const selected = selectedSubjects.indexOf(subject) > -1;
    const disabled = isLoading || (selectedSubjects.length >= MAX_COUNT && !selected);
    return (
      <Checkbox
        key={subject}
        disabled={disabled}
        name={subject}
        checked={selected}
        onChange={this.onChange}
      >{subject}</Checkbox>
    );
  }

  render() {
    const { subjects, isMobile = false, selectedSubjects } = this.props;
    const subjects1 = subjects.slice(0, Math.ceil(subjects.length / 2));
    const subjects2 = subjects.slice(Math.ceil(subjects.length / 2));

    if (isMobile) {
      return (
        <div className={classNames('preferred-subjects', { 'preferred-subjects--error': selectedSubjects.length > MAX_COUNT })}>
          <div className="preferred-subjects__header">
            {subjectsText}
          </div>
          <ul className="preferred-subjects-list">
            {subjects1.map(this.renderCheckbox)}
            {subjects2.map(this.renderCheckbox)}
          </ul>
        </div>
      );
    }
    return (
      <div className="subjects-box">
        <p>{subjectsText}</p>
        <div className="row">
          <div className="col-1">
            {subjects1.map(this.renderCheckbox)}
          </div>
          <div className="col-1">
            {subjects2.map(this.renderCheckbox)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedSubjects: (state.user.subjects || []).filter(subject => state.settings.orderSubjects.indexOf(subject) > -1),
  isLoading: state.user.isFetchingSubjects,
  subjects: state.settings.orderSubjects || [],
});

const mapDispatchToProps = dispatch => ({
  save: subjects => dispatch(userSetSubjectsFetch(subjects)),
});


const ConnectedMySubjectsForm = connect(mapStateToProps, mapDispatchToProps)(MySubjectsForm);
ConnectedMySubjectsForm.displayName = 'Preferred subjects';
export default ConnectedMySubjectsForm;
