import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { Card } from '@connect/connect-ui-js';

import { CLIENT_CHAT_AVATARS_SERVICE, testCallHost } from 'config';

import ReservedCounter from 'components/ReservedCounter';

import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';

import './index.scss';

const LiveSession = ({ msgData, order, getPic }) => {
  const liveSessionRef = useRef({ current: {} });

  // const {
  //   event: {
  //     event_name: eventName = '', event_params: {
  //       scheduled_at: scheduledAt, duration, order_id: orderId, live_session_id: liveSessionId,
  //       t_token,
  //     },
  //   },
  // } = msgData;

  const {
    event: {
      event_name: eventName = '', event_params: {
        scheduled_at: scheduledAt, link, duration, order_id: orderId, live_session_id: liveSessionId,
      },
    },
  } = msgData;

  const isSessionsLoading = useSelector(state => state.clientSessions.isFetching);
  const sessionsForOrder = useSelector(state => state.clientSessions.sessions[orderId]) || [];

  const currentSessionFromStore = useMemo(() => sessionsForOrder.find(session => session._id === liveSessionId), [liveSessionId]);

  const { deleted = false, duration: durationFromStore } = currentSessionFromStore || {};

  const scheduleAtDur = useMemo(() => moment(scheduledAt).add(duration || durationFromStore, 'minutes'), [scheduledAt]);


  const { client: clientId } = order;

  const getSessionStatus = () => {
    if (liveSessionRef.current) clearTimeout(liveSessionRef.current.timer);

    if (eventName === 'crm:live_session_canceled' || deleted) return 'cancelled';

    if (eventName === 'crm:live_session_scheduled' || eventName === 'crm:live_session_rescheduled') return 'planned';

    const nowMoment = moment();
    const isAfterNow = nowMoment.isAfter(moment(scheduledAt));
    const isSessionOver = nowMoment.isAfter(scheduleAtDur);

    if (isSessionOver) return 'isOver';
    if (isAfterNow) return 'live';

    return 'counter';
  };

  const [sessionStatus, setSessionStatus] = useState(getSessionStatus());

  const onCountOver = () => {
    setSessionStatus('live');
  };

  const renderHeader = () => {
    if (eventName === 'crm:live_session_canceled') return 'Live session is canceled';

    switch (sessionStatus) {
      case 'isOver':
        return 'Live session is over';
      case 'live':
      case 'counter':
        return 'Live session';
      default:
        return eventName === 'crm:live_session_rescheduled' ? 'Live session rescheduled' : 'Live session scheduled';
    }
  };

  const renderBtnText = () => {
    if (sessionStatus === 'live') return 'Join Now';

    return (
      <div className="row center_center fsMd">
        <div className="mr8">Join after:</div>
        <ReservedCounter time={scheduledAt} onOver={onCountOver} />
      </div>
    );
  };

  useEffect(() => {
    if (sessionStatus !== 'live') return;

    const diff = scheduleAtDur.diff(moment());

    clearTimeout(liveSessionRef.current.timer);
    liveSessionRef.current.timer = setTimeout(() => {
      setSessionStatus('isOver');
    }, diff);
  }, [sessionStatus, scheduledAt, scheduleAtDur]);

  useEffect(() => () => {
    clearTimeout(liveSessionRef.current.timer);
  }, []);

  if (isSessionsLoading) {
    return (
      <Card data={msgData}>
        <Card.Main>
          <div>loading ....</div>
        </Card.Main>
        <Card.Footer data={msgData} />
      </Card>
    );
  }

  // const link = `${testCallHost}?${t_token}`;

  return (
    <Card data={msgData} getPic={getPic}>
      <Card.Main>
        <div className="card-live-session-container">
          <div className="row vertical">
            <i className={classNames('card-live-session-container__header-icon', `card-live-session-container__header-icon--${sessionStatus}`)} />
            <h3 className="card-live-session-container__header">{renderHeader()}</h3>
          </div>
          <div className="row vertical mt24 mb16 space-between">
            <div className="card-live-session-time-container">
              <div className="row vertical">
                <div className="card-live-session-time-container__hours">{formatTime(scheduledAt, 't')}</div>
                <div className="card-live-session-time-container__hours">&nbsp;- {formatTime(scheduleAtDur, 't')}</div>
              </div>
              <div className="card-live-session-time-container__date">{formatTime(scheduledAt, 'dDayName')}</div>
            </div>
            <i className="card-live-session-time-container__avatar" style={{ backgroundImage: `url(${CLIENT_CHAT_AVATARS_SERVICE}/${clientId})` }} />
          </div>
          {
            (sessionStatus === 'live' || sessionStatus === 'counter') && <a href={link} target="_blank" rel="noopener noreferrer" className="btn btn-100-4 btn-bright card-live-session-container__btn">{renderBtnText()}</a>
          }
        </div>
      </Card.Main>
      <Card.Footer data={msgData} />
    </Card>
  );
};

export default LiveSession;
