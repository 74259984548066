import React from 'react';

const WriterCompleted = () => (
  <div className="order-tab-actions">
    <div className="ta-right mt20">
      <span className="btn btn-completed btn-md">Completed</span>
    </div>
  </div>
);

export default WriterCompleted;
