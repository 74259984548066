
import React from 'react';
import frozen from 'img/icons/frozen@2x.png';

import './styles/index.scss';

const NonReactivatingWarm = ({ reactivateWriter, loading }) => (
  <div className="frozen-container">
    <img className="frozen-icon" src={frozen} alt="" />
    <h2 className="frozen-title">
      Oops!<br />
      Your account has been frozen.
    </h2>
    <p className="frozen-text">
      Let us know if you want to get access to your account again and your Personal Freelancer Care Manager will contact you accordingly.
    </p>
    <button
      type="button"
      className="btn btn-bright btn-block btn-big"
      onClick={reactivateWriter}
      disabled={loading}
    >Warm it up!</button>
  </div>
);

export default NonReactivatingWarm;
