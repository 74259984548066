import moment from 'moment-timezone';

import { urls } from './../../config';
import authFetch from './../../utils/authFetch';

import { ordersAddList } from './../orders/actions';

export const ORDERS_REVISION_REQUEST = 'ORDERS_REVISION_REQUEST';
export const ORDERS_REVISION_RECEIVE = 'ORDERS_REVISION_RECEIVE';

export const ORDERS_REVISION_SET_SORTING = 'ORDERS_REVISION_SET_SORTING';
export const ORDERS_REVISION_SET_FILTER_SEARCH = 'ORDERS_REVISION_SET_FILTER_SEARCH';
export const ORDERS_REVISION_SET_MESSAGE_FILTER = 'ORDERS_REVISION_SET_MESSAGE_FILTER';
export const ORDERS_REVISION_RESET_FILTER = 'ORDERS_REVISION_RESET_FILTER';

const ordersRevisionRequest = () => ({
  type: ORDERS_REVISION_REQUEST,
});

const ordersRevisionReceive = (numbers, unreadOrderMessages) => ({
  type: ORDERS_REVISION_RECEIVE,
  numbers,
  unreadOrderMessages,
});

export const setRevisionMessageFilter = value => ({
  type: ORDERS_REVISION_SET_MESSAGE_FILTER,
  value,
});

export const resetRevisionMessageFilter = () => ({
  type: ORDERS_REVISION_RESET_FILTER,
});

export const ordersRevisionFetch = (filter = '') => (dispatch) => {
  dispatch(ordersRevisionRequest());
  return authFetch(`${urls.orders}?folder=onrevision&${filter}`).then((json = {}) => {
    const numbers = json.results.map(order => order.number);
    const items = json.results.reduce((result, item) => {
      item.didInvalidate = true;
      result[item.number] = item;
      return result;
    }, {});
    dispatch(ordersAddList(items));
    dispatch(ordersRevisionReceive(numbers, json.unread_order_messages));
  });
};

const ordersRevisionShouldFetch = (state) => {
  const { ordersRevision } = state;
  if (ordersRevision.isFetching) {
    return false;
  }
  if (ordersRevision.updatedAt && moment().diff(ordersRevision.updatedAt, 'minutes') > 1) {
    return true;
  }
  return ordersRevision.didInvalidate;
};

export const ordersRevisionFetchIfNeeded = () => (dispatch, getState) => {
  if (ordersRevisionShouldFetch(getState())) {
    return dispatch(ordersRevisionFetch());
  }
};

export const ordersRevisionSetSorting = value => ({
  type: ORDERS_REVISION_SET_SORTING,
  value,
});

export const ordersRevisionSetFilterSearch = value => ({
  type: ORDERS_REVISION_SET_FILTER_SEARCH,
  value,
});
