import { IBAN_CODE_LENGTHS } from 'constants';

const mod97 = (string) => {
  let checksum = string.slice(0, 2);
  let fragment = null;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
};

const isValidIBANNumber = (input) => {
  const iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
  let digits = null;
  // check syntax and length
  if (!code || iban.length !== IBAN_CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => letter.charCodeAt(0) - 55);
  // final check
  return mod97(digits);
};

export default isValidIBANNumber;
