import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BannersList from 'constants/banners';

import { getBannersList } from 'store/banners/selector.js';

import { addBanner, deleteBanner } from 'store/banners/actions';

import storage from 'utils/localStorageHandler';

import Icon from 'components/Icon';


const WarBanner = () => {
  const [isShowedBefore, toggleShowBanner] = useState(localStorage.getItem('war_banner'));
  const dispatch = useDispatch();
  const bannerList = useSelector(getBannersList);

  useEffect(() => {
    if (isShowedBefore) return;

    dispatch(addBanner({ name: 'warBanner', ...BannersList.warBanner }));
  }, []);

  if (isShowedBefore) return null;

  if (bannerList.length > 0 && bannerList[0].name !== 'warBanner') return null;

  const closeHandler = () => {
    toggleShowBanner(true);
    storage.set('war_banner', true);
    dispatch(deleteBanner({ name: 'warBanner' }));
  };

  return (
    <div style={{ '--marginAnim': '-51px' }} className="suspicious-alert suspicious-alert--animate suspicious-alert--blue-bg suspicious-alert--black-text suspicious-alert--extra-padding line-height-20">
      <div className="suspicious-alert__close-block"><button className="btn btn-empty btn-close" onClick={closeHandler}><Icon className="svg-icon" iconName="close" /></button></div>
      <div className="suspicious-alert-text-container row column center_center">
        <h2 className="mb8"><strong>Dear Livingston freelancers!</strong></h2>
        <p className="suspicious-alert-text-container__text">As you may know some of our colleagues are based in Ukraine. Our thoughts and prayers are with them at this hard moment.</p>
        <p className="suspicious-alert-text-container__text">You should know that Livingston Research is an international company registered in UK and EU and as such our ability to function, operate and pay you is not affected by the terrible war in Ukraine.</p>
        <p className="suspicious-alert-text-container__text">For those of you based in Ukraine, please be safe. We will keep paying as usual.</p>
        <p className="suspicious-alert-text-container__text">For the rest of you - We work as usual.</p>
      </div>
    </div>
  );
};

export default WarBanner;
