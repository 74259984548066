import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import shortid from 'shortid';
import moment from 'moment-timezone';
import ReactDomServer from 'react-dom/server';

import utils from 'utils';
import createAmazonUpload from 'utils/createAmazonUpload';
import classNames from 'utils/classNames';

import UploadButton from 'components/UploadButton';
import Icon from 'components/Icon';

import modalList from 'constants/modalList';

import { closeModalComponent } from 'store/modals/actions';

import FileItem from './FileItem';
import ConfirmModal from './ConfirmModal';

import './index.scss';

const EXTRA_MODALS_CONTENT = {
  deleteFiles: {
    header: ReactDomServer.renderToString(<div className="row vertical column_gap_8">
      <Icon styles={{ width: 24, height: 24, fill: '#FC694F' }} iconName="warning-amber" />
      <div style={{ color: '#FC694F' }}>Are you sure?</div>
    </div>),
    text: 'This action can’t be undone. All files will be deleted.',
    btnsText: ['I’m sure, delete', 'Keep uploading'],
  },
  submitFiles: {
    header: ReactDomServer.renderToString(<span>Are you sure you want to submit?</span>),
    text: 'This action cannot be undone. Please make sure ALL required final files are uploaded.',
    subText: 'Upon submitting the order, files will go under plagiarism check (if applicable).',
    btnsText: ['Keep uploading', 'I’m sure, submit', 'Submitting ...'],
  },
  submitFilesPartialDelivery: {
    header: ReactDomServer.renderToString(<span>Are you sure you want to submit the files?</span>),
    text: 'This action cannot be undone. Please make sure only completed final files are uploaded.',
    subText: 'Upon submitting the files, files will go under plagiarism check (if applicable).',
    btnsText: ['Keep uploading', 'I’m sure, submit files', 'Submitting ...'],
  },
};

const UploadFilesModal = ({
  header, text, group,
  hidePlagText, order,
  isRevision, uploadOrderFiles,
  uploadOrderFilesCallback,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState({});
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useState(false);
  const [showConfirmFilesModal, toggleShowConfirmFilesModal] = useState(false);
  const [filesSubmitting, toggleShowFilesSubmitting] = useState(false);

  const filesValues = useMemo(() => Object.values(files), [files]);

  const successUploadedFilesCounter = useMemo(() => (filesValues.filter(file => file.completed) || []).length, [filesValues]);
  const someFileLoading = useMemo(() => (filesValues.some(file => !file.completed && !file.error)), [filesValues]);

  const { number: orderNumber } = order;

  const isOrderWithPartialDeliveryTag = useMemo(() => !isRevision && utils.isOrderWithPartialDeliveryTag(order), [order, isRevision]);

  const stopFileUpload = () => {
    const notUploadFile = filesValues.filter(file => !file.completed && !file.error);

    if (notUploadFile.length > 0) {
      notUploadFile.forEach(file => file.abort());
    }
  };

  const onFileUploadProgress = (fileLocation, percent) => {
    setFiles(__prevData => ({ ...__prevData, [fileLocation]: { ...__prevData[fileLocation], progress: percent } }));
  };

  const onFileUploadComplete = (fileLocation, etag) => {
    setFiles(__prevData => ({ ...__prevData, [fileLocation]: { ...__prevData[fileLocation], completed: true, ETag: etag } }));
  };

  const onFileUploadError = (fileLocation) => {
    setFiles(__prevData => ({ ...__prevData, [fileLocation]: { ...__prevData[fileLocation], error: true } }));
  };

  const onCloseModal = () => {
    if (successUploadedFilesCounter === 0 && !someFileLoading) {
      dispatch(closeModalComponent(modalList.UploadFiles.name));
      return;
    }
    toggleShowConfirmDeleteModal(true);
  };

  const onChange = (event, __files, options = {}) => {
    const fileList = __files || event.target.files;
    for (let i = 0; i < fileList.length; i++) {
      const { name, size } = fileList[i];
      const createUpload = createAmazonUpload(
        orderNumber,
        fileList[i],
        onFileUploadProgress,
        onFileUploadComplete,
        onFileUploadError,
      );
      const fileItem = {
        id: createUpload.location,
        _id: shortid.generate(),
        location: createUpload.location,
        name,
        abort: createUpload.abort,
        completed: false,
        progress: 0,
        error: null,
        size,
        owner_role: 'writer',
        created_at: moment().utc(),
        nativeFile: fileList[i],
        ...options,
      };
      setFiles(__prevData => ({ ...__prevData, [createUpload.location]: fileItem }));
    }
  };

  const onDeleteFile = (_, fileLocation) => {
    const file = files[fileLocation] || {};
    if (!file.completed && !file.error) {
      file.abort();
    }

    const prevData = { ...files };
    delete prevData[fileLocation];

    setFiles(prevData);
  };

  const onReload = (e) => {
    e.stopPropagation();
    let fileLocation = null;
    const { target: { tagName } } = e;

    if (tagName === 'svg') {
      const { parentNode: { name } } = e.target;
      fileLocation = name;
    } else {
      const { name } = e.target;
      fileLocation = name;
    }

    const file = files[fileLocation] || {};
    const { nativeFile, created_at: createdAt } = file;
    onDeleteFile(null, fileLocation);
    onChange(null, [nativeFile], { created_at: createdAt });
  };

  const onCancel = fn => () => {
    fn(__prevValue => !__prevValue);
  };

  const onDeleteFiles = () => {
    stopFileUpload();
    dispatch(closeModalComponent(modalList.UploadFiles.name));
  };

  const onConfirmSubmit = () => {
    toggleShowConfirmFilesModal(true);
  };

  const onSubmitFiles = () => {
    toggleShowFilesSubmitting(true);
    stopFileUpload();
    const completedFiles = filesValues
      .filter(file => file.completed)
      .map(({
        name, location, size, ETag,
      }) => ({
        name,
        location,
        size,
        ETag,
      }));

    uploadOrderFiles(completedFiles, group)
      .then(() => {
        uploadOrderFilesCallback && uploadOrderFilesCallback();
      })
      .finally(() => {
        toggleShowFilesSubmitting(false);
        dispatch(closeModalComponent(modalList.UploadFiles.name));
      });
  };

  const renderFileList = () => {
    if (Object.keys(files).length === 0) return null;

    return (<div className="upload-files-modal-file-list row column row_gap_12 mb16">
      {filesValues
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .map(file => <FileItem key={file.id} file={file} onDeleteFile={onDeleteFile} onReload={onReload} />)}
    </div>
    );
  };

  const renderContent = () => {
    if (showConfirmDeleteModal) {
      return (
        <ConfirmModal {...EXTRA_MODALS_CONTENT.deleteFiles} onCancel={onDeleteFiles} onConfirm={onCancel(toggleShowConfirmDeleteModal)} />
      );
    }

    if (showConfirmFilesModal) {
      const confirmProps = isOrderWithPartialDeliveryTag ?
        EXTRA_MODALS_CONTENT.submitFilesPartialDelivery : EXTRA_MODALS_CONTENT.submitFiles;
      return (
        <ConfirmModal {...confirmProps} filesSubmitting={filesSubmitting} onCancel={onCancel(toggleShowConfirmFilesModal)} onConfirm={onSubmitFiles} />
      );
    }

    const renderSubmitBtn = () => {
      let btnText = 'Submit order';

      if (isOrderWithPartialDeliveryTag) {
        btnText = 'Submit files';
      }

      if (isRevision) {
        btnText = 'Submit revision';
      }

      return (
        <button
          type="button"
          className="btn btn-bright btn-block"
          onClick={onConfirmSubmit}
          disabled={successUploadedFilesCounter === 0}
        >
          {btnText}
        </button>
      );
    };

    return (
      <>
        <h3 className="upload-files-modal__header mb24">{header}</h3>
        {isOrderWithPartialDeliveryTag && <p className="upload-files-modal__text mb16">Note: this order requires partial delivery, so you will be able to upload more final files later.</p>}
        <p className="upload-files-modal__text mb16">{text}</p>
        {isOrderWithPartialDeliveryTag && <p className="upload-files-modal__text mb16">If uploaded files are irrelevant or incomplete, disciplinary action will be taken.</p>}
        <div className="row space-between mb12">
          <div className="upload-files-modal__file-counter upload-files-modal__grey-text">{successUploadedFilesCounter} files total</div>
          <UploadButton className="btn btn-bright" multiple onChange={onChange}>Upload files</UploadButton>
        </div>
        {renderFileList()}
        {!hidePlagText && <p className="fsMd upload-files-modal__grey-text mb24">Upon submitting the order, files will go under plagiarism check (if applicable).</p>}
        <div className="row column_gap_12 mt24">
          <button type="button" className="btn btn-light btn-block" onClick={onCloseModal}>Close</button>
          {renderSubmitBtn()}
        </div>
      </>
    );
  };

  return (
    <div className={classNames(
      'upload-files-modal',
      { 'upload-files-modal--submitting': filesSubmitting },
    )}
    >
      {renderContent()}
    </div>
  );
};

export default UploadFilesModal;
