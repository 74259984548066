import moment from 'moment-timezone';
import {
  INBOX_UPDATES_REQUEST,
  INBOX_UPDATES_RECEIVE,
  INBOX_UPDATES_ACTION_REQUEST,
  INBOX_UPDATES_ACTION_RECEIVE,
  INBOX_UPDATES_NEXT_REQUEST,
  INBOX_UPDATES_NEXT_RECEIVE,
  INBOX_UPDATES_SET_VIEW_RECEIVE,
  INBOX_UPDATES_SET_VIEW_REQUEST,
} from '../actions';

const inboxUpdates = (state = {
  isFetching: false,
  didInvalidate: true,
  sorting: { field: 'publish_at', isAsc: true },
  filter: {},
  total: null,
  limit: 25,
  page: 1,
}, action) => {
  switch (action.type) {
    case INBOX_UPDATES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case INBOX_UPDATES_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetching: false,
        updatedAt: moment(),
        didInvalidate: false,
        filter: action.data.filter,
        sorting: action.data.sorting,
        page: action.data.page,
        items: action.data.items,
      });
    case INBOX_UPDATES_ACTION_REQUEST:
      return {
        ...state,
        isFetchingAction: true,
      };
    case INBOX_UPDATES_ACTION_RECEIVE:
      return {
        ...state,
        ...action.data,
        isFetchingAction: false,
      };
    case INBOX_UPDATES_NEXT_REQUEST:
      return Object.assign({}, state, {
        didInvalidate: true,
        isFetching: true,
      });
    case INBOX_UPDATES_NEXT_RECEIVE:
      return Object.assign({}, state, {
        ...state,
        page: action.data.page,
        items: [...state.items, ...action.data.items],
        isFetching: false,
      });
    case INBOX_UPDATES_SET_VIEW_REQUEST:
      return Object.assign({}, state, {
        ...state.inboxUpdates,
        didInvalidate: true,
        isFetching: true,
      });
    case INBOX_UPDATES_SET_VIEW_RECEIVE:
      const newItems = state.items.map((item) => {
        if (item.id === action.data) {
          item.seen = true;
        }
        return item;
      });

      return Object.assign({}, state, {
        items: newItems,
        didInvalidate: false,
        isFetching: false,
      });
    default:
      return state;
  }
};

export default inboxUpdates;
