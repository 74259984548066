import React from 'react';

import Tooltip from 'components/Tooltip';

import './index.scss';

const DeselectControls = ({ selectedFiles = [], downloadFetching, onDownloadAll, clearSelect }) => {
  const tooltipDownload = selectedFiles.length === 1 ? 'download 1 file' : `download ${selectedFiles.length} files`;
  if (downloadFetching) {
    return (
      <div className="checked-tools row vertical">
        Loading ...
      </div>
    );
  }
  return (
    <div className="checked-tools row vertical">
      <div className="checked-tools__deselect" onClick={clearSelect}>Deselect all</div>
      <Tooltip content={tooltipDownload}>
        <i
          onClick={onDownloadAll}
          className="checked-tools__icon-download"
        />
      </Tooltip>
    </div>
  );
};

export default DeselectControls;

