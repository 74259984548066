import React, { Component } from 'react';

import Radio from 'components/Radio';

class OrderTabQAItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: null,
    };

    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    const {
      dataRow, setTitle, dateSection, title, setGradingSum, sumTitle,
    } = this.props;
    const arrDataRow = {};

    dataRow && dataRow.forEach((item) => {
      arrDataRow[item.split(':')[0]] = item.split(':');
    });

    const arrDataContentSection = Object.values(dateSection);

    const resSumOf = arrDataContentSection.reduce((sumOf, current) => {
      if (!!arrDataRow[current.id] && parseInt(arrDataRow[current.id][2], 10) !== 4) {
        return sumOf + parseInt(current.value[0], 10);
      }

      return sumOf;
    }, 0);

    const resSumOut = arrDataContentSection.reduce((sumOut, current) => {
      const currntID = current.id;

      if (arrDataRow[currntID]) {
        const numColumn = parseInt(arrDataRow[currntID][2], 10);

        if (numColumn >= 0 && numColumn < 3) {
          return sumOut + parseInt(current.value[numColumn], 10);
        }

        return sumOut;
      }

      return sumOut;
    }, 0);

    setTitle(title, `${resSumOut} out of ${resSumOf}`);
    setGradingSum(sumTitle, resSumOf);

    this.setState({
      formData: arrDataRow,
    });

    this.checkRatingString(arrDataRow);
  }


  renderRow(tableRow) {
    const { id } = tableRow;
    const { formData } = this.state;

    if (formData[id]) {
      return (
        <tr key={id}>
          <td>
            <span dangerouslySetInnerHTML={{ __html: tableRow.name }} />
            <div className="tooltip">
              <a className="tooltip-trigger"> </a>
              <div className="tooltip-content" dangerouslySetInnerHTML={{ __html: tableRow.tooltip }} />
            </div>
          </td>
          {tableRow.value.map((itemValue, index) =>
            (<td key={itemValue}>
              <Radio name={id} disabled checked={parseInt(formData[id][2], 10) === index}>{itemValue}</Radio>
            </td>))}
          <td><Radio name={id} disabled checked={parseInt(formData[id][2], 10) === 3}>0</Radio></td>
          <td><Radio name={id} disabled checked={parseInt(formData[id][2], 10) === 4} /></td>
        </tr>
      );
    }

    return (
      <tr key={id}>
        <td>
          <span dangerouslySetInnerHTML={{ __html: tableRow.name }} />
          <div className="tooltip">
            <a className="tooltip-trigger"> </a>
            <div className="tooltip-content" dangerouslySetInnerHTML={{ __html: tableRow.tooltip }} />
          </div>
        </td>

        {tableRow.value.map((itemValue, index) =>
          (<td key={itemValue}>
            <Radio name={id} disabled>{itemValue}</Radio>
          </td>))}

        <td><Radio name={id} disabled>0</Radio></td>
        <td><Radio name={id} disabled /></td>
      </tr>
    );
  }

  checkRatingString(formData) {
    const { setGradingValue } = this.props;

    const isGradingLow =
            (!!formData.essay1 && formData.essay1[2] === '3') ||
            (!!formData.essay2 && formData.essay2[2] === '3') ||
            (!!formData.essay3 && formData.essay3[2] === '3') ||
            (!!formData.essay4 && formData.essay4[2] === '3');

    setGradingValue(isGradingLow);
  }

  render() {
    const { dateSection } = this.props;
    const { formData } = this.state;

    if (formData) {
      return (
        <table>
          <tbody>
            <tr>
              <th>Criterion</th>
              <th>Yes</th>
              <th>Mostly</th>
              <th>Poorly</th>
              <th>No</th>
              <th>N/A</th>
            </tr>
            {Object.values(dateSection).map(this.renderRow)}
          </tbody>
        </table>
      );
    }

    return null;
  }
}

export default OrderTabQAItem;
