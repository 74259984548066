import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Radio from 'components/Radio';
import Tooltip from 'components/Tooltip';

import classNames from 'utils/classNames';
import storage from 'utils/localStorageHandler';

import questionIcon from 'img/icons/question-copy.svg';

import './index.scss';

const MajorSubjectsForm = ({ onClose }) => {
  const selectedSubjects = storage.get('pref-subjects');

  const [majorSubject, setMajorSubject] = useState(selectedSubjects);
  const [loading, toggleLoading] = useState(false);

  const orderSubjectGroups = useSelector(state => state.settings.orderSubjectGroups);

  const subjectList = useMemo(() => Object.keys(orderSubjectGroups).sort().filter(s => s.toLowerCase() !== 'other') || [], [orderSubjectGroups]);
  const subjects1 = useMemo(() => subjectList.slice(0, Math.ceil(subjectList.length / 2)), [subjectList]);
  const subjects2 = useMemo(() => subjectList.slice(Math.ceil(subjectList.length / 2)), [subjectList]);
  const subjectGroupContents = useMemo(() => subjectList.reduce((result, item) => ({ ...result, [item]: orderSubjectGroups[item].join('\n') }), {}), [subjectList]);

  const isDisabled = !majorSubject || majorSubject === '' || loading;
  const btnClass = classNames('btn btn-bright btn-big', { disabled: isDisabled });
  const onRadioChange = useCallback(
    (event) => {
      const { target } = event;
      setMajorSubject(target.name);
    },
    [setMajorSubject],
  );
  const onSubmitForm = useCallback(
    () => {
      if (isDisabled) return;
      toggleLoading(true);
      storage.set('pref-subjects', majorSubject);
      onClose();
    },
    [majorSubject],
  );

  const renderRadioSubject = subject => (
    <div className="row subjects-modal__items" key={subject}>
      <Radio name={subject} checked={majorSubject === subject} className="mb20 fsL" onChange={onRadioChange}>{subject}</Radio>
      <div className="subjects-info-box ml8">
        <Tooltip content={subjectGroupContents[subject]} className="subjects-modal__wrap-tooltip">
          <img className="info-icon" src={questionIcon} alt="" />
        </Tooltip>
      </div>
    </div>
  );

  return (
    <div className="ta-center subjects-modal">
      <div className="subjects-modal__steps">Step 1 out of 2</div>
      <h2 className="title mb15">Select your primary preferred subject</h2>
      <p className="subjects-modal__descriptions fsMd mb30">The subject, you would like to complete the majority of order in</p>
      <div className="row">
        <div className="col-1">
          {subjects1.map(renderRadioSubject)}
        </div>
        <div className="col-1">
          {subjects2.map(renderRadioSubject)}
        </div>
      </div>
      <div className="subjects-modal__footer row left">
        <button className={btnClass} onClick={onSubmitForm}>Submit</button>
      </div>
    </div>
  );
};

export default MajorSubjectsForm;
