import React from 'react';

const DetailsContent = ({ groupItem }) => (
  <div className="order-tab-content detail-info">
    {
      groupItem.map(item => item)
    }
  </div>
);

export default DetailsContent;
