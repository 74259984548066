import { Component } from 'react';
import queryString from 'query-string';

import { withRouter, withRouterMatch } from 'hocs';

import { setToken } from './../utils/tokenStorage';
import storage from './../utils/localStorageHandler';
import { DATA_DISABLED_REQUEST_PM_SETUP } from 'constants/localStorage';

class LoginPage extends Component {
  componentDidMount() {
    const { match, navigate } = this.props;
    const { token } = match.params;
    setToken(`Bearer ${token}`);
    const params = queryString.parse(window.location.search);
    const redirectUrl = params.redirect_url;
    let path = '/';
    if (redirectUrl) {
      const url = new URL(Buffer.from(redirectUrl, 'base64').toString());
      path = url.pathname + url.search;
    }
    storage.remove('dnd-mode');
    storage.remove('userViewedCount');
    storage.remove('userViewedCountChange');
    storage.remove(DATA_DISABLED_REQUEST_PM_SETUP);
    setTimeout(() => {
      navigate(path, { replace: true });
    }, 1);
  }

  render() {
    return null;
  }
}

export default withRouterMatch(withRouter(LoginPage));
