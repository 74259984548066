import React, { useEffect, useState } from 'react';
import QRCode from "react-qr-code";
import { useDispatch } from 'react-redux';
import { urls } from 'config';
import authFetch from 'utils/authFetch';

import { userSetField } from 'store/user/actions';

import Icon from './Icon';
import Modal from './Modal';

const FinancesRequestToPassModal = ({ toggleModal }) => {
  const [showInputCode, setShowInputCode] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [secretUri, setSecretUri] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const saveEnrolled = (field, value) => dispatch(userSetField(field, value));

  useEffect(() => {
    const data = { action: 'otp_get_secret' };

    authFetch(urls.totp2fa, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((res) => {
        setSecretUri(res.secret_uri);
      })
      .catch((error) => {
        setError(true);
        console.error(error);
      });
  }, []);

  const onSubmit = () => {
    setLoading(true);
    const data = { action: 'otp_enroll', code: verifyCode };

    authFetch(urls.totp2fa, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((res) => {
        saveEnrolled('otp_status', res.otp_status);
        setShowSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        console.error(error);
      });
  };

  const handleChangeCode = (e) => {
    const { value } = e.target;
    const newValue = value.replace(/\D/g, '');
    setVerifyCode(newValue);
  };

  const toggleInput = () => setShowInputCode(!showInputCode);

  if (showSuccess) {
    return (
      <Modal className="modal-request-to-pass" onClose={toggleModal} small>
        <h4 className="title mb20">😀 Success!</h4>
        <p className="line-height-1-5 mb20">
          You have successfully set up Two-Factor Authentication (2FA) for your account.
        </p>

        <p className="line-height-1-5 mb20">
          For every fund withdrawal, you will be prompted to enter a unique one time code as shown in your authenticator app.
        </p>

        <p>Consider setting up a backup method in the authenticator app to to retrieve your codes in case of a lost device, as you won't be able to reset 2FA on the platform.</p>

        <div className="btn-group">
          <button type="button" className="btn btn-bright btn-sm" onClick={toggleModal}>Go It</button>
        </div>
        <button className="btn btn-close" type="button" onClick={toggleModal}><Icon className="svg-icon" iconName="close" /></button>
      </Modal>
    );
  }

  return (
    <Modal className="modal-request-to-pass" onClose={toggleModal} wide>
      <h4 className="title mb20">Set up Two-Factor Authentication</h4>
      <p className="line-height-1-5 mb20">
        To ensure the security of your funds and transactions, we require <strong>Two-Factor<br />
          Authentication (2FA)</strong> before creating a withdrawal request. 2FA adds an extra<br />
        layer of protection by verifying your identity through two distinct<br />
        authentication methods, so that even if your password is compromised, you<br />
        will stay in control of your funds.
      </p>

      <h5 className="fsL mb20"><strong>Setting up the Two-Factor Authentication</strong></h5>
      <ul className="line-height-1-5 mb20 list_dark">
        <li>
          <strong>Download an Authenticator App on Your Mobile Device.</strong><br />
          We recommend Google Authenticator for a seamless experience, however<br />
          you are free to pick another service. Other options include Authy,<br />
          Microsoft Authenticator, or LastPass Authenticator. This is not an<br />
          exhaustive list, you can use any app that supports Time-based One-Time<br />
          Passwords (TOTP).<br />
          If you do not have a mobile device, Authy authenticator has a desktop app,<br />
          and some other authenticators have browser extensions.
        </li>
        <li>
          <strong>Connect Your Account.</strong><br />
          Scan the QR code below with your authenticator app. This is a one-time<br />
          setup process.
        </li>
      </ul>

      <p className="line-height-1-5 mb20">
        Once connected, you’ll be prompted to enter a one time-code as shown in your<br />
        authenticator app <strong>each time you create a withdrawal request.</strong>
      </p>

      {!showInputCode &&
        <>
          {secretUri &&
            <div className="qr-wrap mb10">
              <QRCode value={secretUri} />
            </div>
          }
          <div className="center-content mb20 ta-center">
            <a className="qr-link" href={secretUri} target='_blanc'>You can also click this link </a>
          </div>
        </>
      }
      {showInputCode &&
        <div className="modal-request-to-pass__code-wrap">
          <h5 className="modal-request-to-pass__code-wrap__title mb15">Please enter the code <br />
            as shown in your authenticator app below
          </h5>
          <input autoFocus className="modal-request-to-pass__code-wrap__input" value={verifyCode} onChange={handleChangeCode} maxLength="6" />
          {hasError &&
            <p className="modal-request-to-pass__code-wrap__error">Incorrect code. Try again.</p>
          }
          {isLoading &&
            <div className="preloading-circle" />
          }
        </div>
      }


      <div className="btn-group">
        {showInputCode &&
          <>
            <button type="button" className="btn btn-light btn-sm" onClick={toggleInput}>Go back</button>
            <button type="button" className="btn btn-bright btn-sm" onClick={onSubmit} disabled={isLoading}>Submit</button>
          </>
        }
        {!showInputCode &&
          <button type="button" className="btn btn-bright btn-sm" onClick={toggleInput} disabled={isLoading}>Proceed</button>
        }
      </div>
      <button className="btn btn-close" type="button" onClick={toggleModal}><Icon className="svg-icon" iconName="close" /></button>
    </Modal>
  );
};

export default FinancesRequestToPassModal;
