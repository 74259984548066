import { createSelector } from 'reselect';

import { getSubjects } from '../user/selectors';

const getSettingsSubjects = state => state.settings.orderSubjectGroups;
export const getDataListOfFiles = state => state.settings.dataListOfFixes;
export const getTemplates = state => state.settings.templates;
export const getDiffBetweenServerClientTime = state => state.settings.getDiffBetweenServerClientTime;

export const getSelectedSubjects = createSelector(
  getSubjects,
  getSettingsSubjects,
  (subjects, orderSubjectGroups) => {
    const subjectList = Object.keys(orderSubjectGroups).sort();
    return subjects.filter(subject => subjectList.indexOf(subject) > -1);
  },
);

export const getMappedUserSubjects = createSelector(
  getSubjects,
  getSettingsSubjects,
  (subjects, orderSubjectGroups) => subjects.reduce((prev, next) => [...prev, ...orderSubjectGroups[next]], []),
);
