import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { unstable_batchedUpdates } from 'react-dom';

import { urls } from 'config';
import utils from 'utils';

import removeExtraLineBreaks from 'utils/removeExtraLineBreaks';
import authFetch from 'utils/authFetch';

import { ticketFilesUploadFetch, ticketsActionFetch, ticketsFilesRemoveRequest, ticketsFetchIfNeeded, ticketUpdateActionFetch, ticketsFilesUploadReceive } from 'store/tickets/actions';
import { addAlert } from 'store/alerts/actions';
import { ordersDetailsFetch } from 'store/orders/actions';
import { getOrdersProgressSorted } from 'store/ordersProgress/selectors';

import { getProfile } from 'store/user/selectors';
import { getTicketSettings } from 'store/ticketsSettings/selectors';
import { getTickets, getTicket, getNewTicketFiles } from 'store/tickets/selectors';
import { getOrderDetailsList } from 'store/orders/selectors';

import { ISSUE_TYPE, TICKET_STATUS_MAPPING, REPORT_PROCESSING_TIME, REPORT_SUB_TYPE } from 'components/WriterTicketing/constants/writerTicketing';

import Modal from 'components/Modal';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import WriterIssueAnswer from './WriterIssueAnswer';
import ModalReasonSelector from './ModalReasonSelector';
import WriterIssueRequestNew from './WriterIssueRequestNew';
import WriterIssueRequestView from './WriterIssueRequestView';

import {
  TYPE_ISSUE,
  DEFAULT_ROLE,
  SUPPORT_ISSUE_TYPE,
  SUPPORT_QA_ISSUE_TYPE,
  SUB_TYPE_MAPPER,
  FILTERED_TYPES,
  FILTERED_WITHOUT_FINES,
  QA_MAX_SCORE,
  CLIENT_MAX_SCORE,
  DEFAULT_FORM_DATA,
  FILTERED_REVISION_FINES_TYPES,
} from './constants/common';

function setDefaultDataFrom() {
  const {
    ticket, ticketFromMessage, orderId, orderNumber, orderTitle = '', typeIssue: initialTypeIssue, dispatch,
    subTypeIssue: initialSubTypeIssue,
  } = this;
  let newData = null;

  if (ticket) {
    const stateOrderNumber = ticket.info.order_info ? ticket.info.order_info.number : null;
    const stateOrderId = ticket.info.order_info ? ticket.info.order_info.id : null;
    const stateOrderTitle = ticket.info.order_info ? ticket.info.order_info.title : '';
    const message = ticket.info.issue_details || '';
    const typeIssue = ticket.info.issue_type || '';
    const subTypeIssue = ticket.info.issue_subtype || '';
    const rating = ticket.info.mark || null;
    const tags = ticket.info.tags || [];
    let initState = (ticket && ticket.aasm_state) || null;

    if (initState === 'canceled' && (ticket.comment || ticket.description)) {
      initState = 'closed';
    }

    const status = initState && TICKET_STATUS_MAPPING[initState];
    const otherText = ticket.info.other_text || '';

    newData = {
      stateOrderNumber: orderNumber || stateOrderNumber,
      stateOrderId: orderId || stateOrderId,
      stateOrderTitle: orderTitle || stateOrderTitle,
      message,
      typeIssue,
      subTypeIssue,
      rating,
      tags,
      showTags: !!tags.length,
      status,
      otherText,
      isShowOtherText: tags.indexOf('Other') >= 0 && rating <= 3,
      showIssueError: false,
      issueErrorText: '',
      isNTOrder: utils.isNTorder({ number: orderNumber }),
    };
  }

  if (ticketFromMessage) {
    ticketFromMessage.files.map(f => dispatch(ticketsFilesUploadReceive(f._id, [{ ...f, type: 'file_ticket', owner_role: 'writer' }])));
    newData = {
      message: ticketFromMessage.message,
      messageId: ticketFromMessage._id,
      orderId,
      orderNumber,
      orderTitle,
      isNTOrder: utils.isNTorder({ number: orderNumber }),
      isSelected: true,
    };
  }

  if (orderId || orderNumber || orderTitle) {
    newData = {
      stateOrderNumber: orderNumber,
      stateOrderId: orderId,
      stateOrderTitle: orderTitle,
      typeIssue: initialTypeIssue,
      isNTOrder: utils.isNTorder({ number: orderNumber }),
      isSelected: true,
      subTypeIssue: initialSubTypeIssue,
    };
  }

  if (newData) return newData;

  return DEFAULT_FORM_DATA;
}

const WriterTicketingIssue = (props) => {
  const {
    ticketId, orderId, onClose, onSuccess,
    callFromOrderContent,
  } = props;
  const { onShowNotice, isConvertMessage, orderDetails } = props;

  const dispatch = useDispatch();

  const profile = useSelector(getProfile) || {};
  const stateTicket = useSelector(getTicket) || {};
  const tickets = useSelector(getTickets) || [];
  const ticketsSettings = useSelector(getTicketSettings) || {};
  const newTicketFiles = useSelector(getNewTicketFiles) || {};
  const allStoreOrders = useSelector(getOrderDetailsList) || [];

  const ticket = ticketId ? stateTicket[ticketId] : null;

  const [dataForm, setDataForm] = useState(setDefaultDataFrom.bind({ ...props, ticket, dispatch }));
  const [loading, setLoading] = useState(false);
  const [allMyOrder, setAllMyOrder] = useState([]);
  const [isAllMyOrdersLoading, toggleAllMyOrdersLoading] = useState(false);
  const [openReasonSelector, setOpenReasonSelector] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');

  const updateDataForm = (newData = {}) => {
    setDataForm(__dataForm => ({ ...__dataForm, ...newData }));
  };

  const { name: writerName, _id: writerId, tags = [] } = profile;

  const {
    stateOrderNumber, message, typeIssue, showIssueError, subTypeIssue, issueErrorText, otherText, stateOrderId, ordersTickets, stateOrderTitle,
  } = dataForm;
  const { fill_behaviour: fillBehaviour, nt_behaviour: ntBehaviour } = ticketsSettings;

  const isUserCantSendIssue = useMemo(() => tags.includes('quarantine'), [tags]);
  const isLoading = useMemo(() => ((tickets || []).isFetching || newTicketFiles.isFetching || ticketsSettings.isFetching || profile.isFetching || isAllMyOrdersLoading || false), [tickets, newTicketFiles, ticketsSettings, profile, isAllMyOrdersLoading]);
  const currentOrder = useMemo(() => orderDetails || (stateOrderNumber ? allMyOrder.filter(order => order.number === stateOrderNumber)[0] : null), [allMyOrder, stateOrderNumber, orderDetails]);
  const isCompleteDayLater = useMemo(() => currentOrder && currentOrder.writer_completed && moment().diff(moment(currentOrder.writer_completed), 'hours'), [currentOrder]);
  const isCanceledOrder = useMemo(() => currentOrder && !currentOrder.assigned_by_me, [currentOrder]);
  const isCompletedOrder = useMemo(() => currentOrder && currentOrder.writer_completed, [currentOrder]);
  const storeOrderDetails = useMemo(() => stateOrderId && allStoreOrders.find(order => order._id === stateOrderId), [stateOrderId, allStoreOrders]);
  
  const fileList = useMemo(() => newTicketFiles.files || [], [newTicketFiles]);
  const orderRelateKey = useMemo(() => (stateOrderNumber ? 'ORDER_RELATED' : 'NOT_ORDER_RELATED'), [stateOrderNumber]);
  const hasProgressOrder = useSelector(state => getOrdersProgressSorted(state).length > 0);

  const isDisabledReset = !message && !stateOrderNumber && !typeIssue && !subTypeIssue && !fileList.length;
  const isDisableSubmit = (!message || !typeIssue || showIssueError || loading) || (ISSUE_TYPE[orderRelateKey][typeIssue] || []).length > 0 && !subTypeIssue;

  const hasFines = useMemo(() => (currentOrder && (currentOrder.last_writer_fine_at || isCanceledOrder)) || false, [currentOrder, isCanceledOrder]);
  const deProfile = useMemo(() => profile && profile.profile_type === 'D', [profile]);

  const onChangeMessage = (fieldName, value) => {
    updateDataForm({ message: value });
  };

  const onChangeOtherText = (fieldName, value) => {
    updateDataForm({ otherText: value });
  };

  const onChangeOrder = ([number, id, title, assignedByMe], isSelected) => {
    if (number && assignedByMe === 'true') {
      dispatch(ordersDetailsFetch(number));
    }
    updateDataForm({
      ...dataForm,
      stateOrderNumber: number || null,
      stateOrderId: id || null,
      typeIssue: '',
      subTypeIssue: '',
      stateOrderTitle: title || '',
      showIssueError: false,
      issueErrorText: '',
      isNTOrder: number ? utils.isNTorder({ number }) : false,
      isSelected,
    });
    setSelectedReason('');
  };

  const onChangeType = (value) => {
    let subTypeIssueNew = '';
    if ((ISSUE_TYPE[orderRelateKey][value] || []).length <= 0) {
      subTypeIssueNew = value;
    }

    updateDataForm({
      ...dataForm,
      typeIssue: value,
      subTypeIssue: subTypeIssueNew,
      showIssueError: false,
      issueErrorText: '',
    });
    setSelectedReason('');
  };

  const onChangeSubType = (value) => {
    updateDataForm({
      ...dataForm,
      subTypeIssue: value,
      showIssueError: false,
      issueErrorText: '',
    });
    setSelectedReason('');
  };

  const onSetRating = (value) => {
    const { tags } = dataForm;
    updateDataForm({
      ...dataForm,
      rating: value,
      showTags: value <= 3 || false,
      tags: value > 3 ? [] : dataForm.tags,
      isShowOtherText: tags.indexOf('Other') >= 0 && value <= 3,
    });
  };

  const onTagSelect = (value) => {
    const { tags, rating } = dataForm;
    const newTags = tags.indexOf(value) >= 0 ? tags.filter(t => t !== value) : [...tags, value];
    const isShowOtherText = newTags.indexOf('Other') >= 0 && rating <= 3;
    updateDataForm({
      ...dataForm,
      tags: newTags,
      isShowOtherText,
    });
  };

  const removeFile = (file, group) => dispatch(ticketsFilesRemoveRequest(file, group));
  const uploadFile = (file, group) => dispatch(ticketFilesUploadFetch(file, group, writerId));

  const deleteFiles = () => {
    if (fileList.length) {
      fileList.forEach(file => removeFile(file._id));
    }
  };

  const onCancel = () => {
    deleteFiles();
    onClose();
  };

  const onCancelTicket = (e) => {
    const { ticket: dataTicketId } = e.target.dataset;
    const data = {
      aasm_state: 'canceled',
    };
    dispatch(ticketUpdateActionFetch(dataTicketId, data));
    dispatch(addAlert(<p className="success-container">Your ticket is canceled.</p>));
    onClose();
  };

  const onReset = () => {
    deleteFiles();
    updateDataForm({
      ...dataForm,
      stateOrderNumber: null,
      orderId: null,
      message: '',
      typeIssue: '',
      subTypeIssue: '',
      otherText: '',
      showIssueError: false,
      issueErrorText: '',
      isNTOrder: false,
      toggleReset: !dataForm.toggleReset,
    });
    setSelectedReason('');
  };

  const getBehaviour = () => {
    const { isNTOrder } = dataForm;

    if (isNTOrder) {
      return !loading && ntBehaviour && ntBehaviour[TYPE_ISSUE] ? ntBehaviour[TYPE_ISSUE][SUB_TYPE_MAPPER[subTypeIssue] || subTypeIssue] : null;
    }

    const behaviour = !loading && fillBehaviour && fillBehaviour[TYPE_ISSUE] ? fillBehaviour[TYPE_ISSUE][SUB_TYPE_MAPPER[subTypeIssue] || subTypeIssue] : null;
    return behaviour;
  };

  const getIssuePriority = () => {
    const behaviour = getBehaviour() || {};
    return behaviour.priority || 'low';
  };

  const getAvailableDeadline = () => {
    const behaviour = getBehaviour();

    const hours = behaviour ? parseInt(behaviour.deadline, 10) : 1;
    const deadline = moment().startOf('minute');
    const unix = ((behaviour && behaviour.deadline) || '').replace(/[^a-zA-Z]/g, '').toLocaleLowerCase() || 'h';
    deadline.add(hours, unix === 'h' ? 'hours' : 'minutes');
    return deadline;
  };

  const getAvailableRole = () => {
    let role = null;
    let behaviour = null;

    behaviour = getBehaviour();
    role = !behaviour ? null : behaviour.assignee;
    return role || DEFAULT_ROLE;
  };

  const onOpenReasonSelector = () => {
    setOpenReasonSelector(!openReasonSelector);
  };

  const onChangeReason = (reason) => {
    setSelectedReason(reason);
  };

  const onSubmit = () => {
    const { messageId, isNTOrder } = dataForm;
    const isFrozenAccount = typeIssue === REPORT_SUB_TYPE.I_WANT_TO_FREEZE_MY_ACCOUNT;
    const isDeletingAccount = typeIssue === REPORT_SUB_TYPE.I_WANT_TO_DELETE_MY_ACCOUNT;

    if ((isFrozenAccount || isDeletingAccount) && !selectedReason) {
      return onOpenReasonSelector();
    }

    setLoading(true);

    const availableRole = getAvailableRole();
    const deadline = getAvailableDeadline();
    const priority = getIssuePriority();

    const subtype = SUB_TYPE_MAPPER[subTypeIssue] || subTypeIssue || typeIssue;
    const { team = '' } = REPORT_PROCESSING_TIME[subTypeIssue];

    const data = {
      ticket_type: 'issue_resolution',
      subtype,
      priority,
      parent_object_type: 'writer',
      parent_object_id: writerId,
      reference: writerName,
      deadline,
      assignee_group: { name: availableRole },
      info: {
        message_id: messageId,
        files: (newTicketFiles.files || []),
        issue_type: typeIssue,
        issue_subtype: subTypeIssue,
        issue_details: removeExtraLineBreaks(message),
        team,
        reason: selectedReason,
      },
    };

    if (deProfile) {
      data.de = deProfile;
    }

    if (isFrozenAccount || isDeletingAccount) {
      data.aasm_state = 'closed';
    }

    if (stateOrderNumber) {
      const orderInfo = {
        number: stateOrderNumber,
        id: stateOrderId,
        title: stateOrderTitle,
      };
      data.info.order_info = orderInfo;
    }

    if (isNTOrder && (subtype.toLocaleLowerCase().startsWith(SUPPORT_ISSUE_TYPE) || subtype.toLocaleLowerCase().includes(SUPPORT_ISSUE_TYPE) || subtype.toLocaleLowerCase().includes(SUPPORT_QA_ISSUE_TYPE))) {
      data.assignee_email = 'support@lrtutors.com';
    }

    dispatch(ticketsActionFetch(data, isConvertMessage))
      .then(() => {
        dispatch(ticketsFetchIfNeeded());
        deleteFiles();
        onClose();

        if (onSuccess) {
          onSuccess();
        }
        if (onShowNotice) {
          dispatch(addAlert(<p className="success-container">Thank you for your feedback. We will review the issue and get back to your ASAP.</p>));
        }
      })
      .catch(({ errors = [] }) => {
        updateDataForm({
          ...dataForm,
          showIssueError: true,
          issueErrorText: errors.reduce((prev, next) => {
            prev += ` ${next},`;
            return prev;
          }, '').slice(0, -1),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmitReview = () => {
    const { rating, tags } = dataForm;
    const data = {
      info: {
        ...ticket.info,
        mark: rating,
        tags,
        other_text: tags.includes('Other') >= 0 ? otherText : '',
      },
    };
    dispatch(ticketUpdateActionFetch(ticketId, data, onClose()));
    onClose();
    if (onShowNotice) {
      dispatch(addAlert(<p className="success-container">Thank you for your feedback.</p>));
    }
  };

  const filterForSubTypes = (type, subtypes) => {
    const filteredTypes = !hasFines ? { ...FILTERED_TYPES, ...FILTERED_WITHOUT_FINES } : FILTERED_TYPES;
    const hasFilterType = Object.keys(FILTERED_TYPES).includes(type);
    const hasRevisionFilterType = Object.keys(FILTERED_REVISION_FINES_TYPES).includes(type);

    if (loading) return subtypes;

    if (!Object.keys(filteredTypes).includes(type)) return subtypes;

    if (!Object.keys(FILTERED_REVISION_FINES_TYPES).includes(type)) return subtypes;

    const { mark, qa_report_score: qaScore, revision_decline_fine_at: revisionDeclineFineAt } = orderDetails || storeOrderDetails || allMyOrder.find(o => o.id === stateOrderId) || {};

    let orderTicketsResult = null;

    if (!stateOrderNumber) return subtypes;

    if (!ordersTickets || !ordersTickets[stateOrderId]) {
      setLoading(true);
      authFetch(urls.tickets(utils.getTicketsQueryString(1, null, {
        parent_object_id: [writerId], by_ticket_type: ['issue_resolution'], search: stateOrderNumber, order_info_id: stateOrderId,
      })))
        .then((__orderTickets) => {
          orderTicketsResult = __orderTickets.tickets;
          orderTicketsResult = { ...orderTicketsResult, [stateOrderId]: { tickets: __orderTickets.tickets, orderTicketsByType: __orderTickets.canceled_by_operator_subtypes } };
          updateDataForm({
            ...dataForm,
            ordersTickets: orderTicketsResult,
          });
        })
        .catch((error) => { console.error(error); })
        .finally(() => {
          setLoading(false);
        });
    } else {
      orderTicketsResult = ordersTickets[stateOrderId];
    }

    if (!orderTicketsResult) return subtypes;

    const { orderTicketsByType = [] } = dataForm.ordersTickets[stateOrderId];


    // filter by exist tickets
    if (orderTicketsByType.length > 0 && (hasFilterType || hasRevisionFilterType)) {
      subtypes = subtypes.filter((subT) => {
        const filteredSubTypes = FILTERED_TYPES[type] || [];
        const filteredRevisionSubTypes = FILTERED_REVISION_FINES_TYPES[type] || [];
        if (orderTicketsByType.includes(subT) && (filteredSubTypes.includes(subT) || filteredRevisionSubTypes.includes(subT))) return false;
        return true;
      });
    }
    // filter by mark or qaScore
    if ((!mark || mark > 3 || !qaScore || qaScore > 3) && hasFilterType) {
      const __subtypes = ((!mark || mark > CLIENT_MAX_SCORE) && subtypes.filter(subT => subT !== FILTERED_TYPES[type][0])) || subtypes;
      subtypes = ((!qaScore || qaScore > QA_MAX_SCORE) && __subtypes.filter(subT => subT !== FILTERED_TYPES[type][1])) || __subtypes;
    }
    // filter by revision auto fine
    if (!revisionDeclineFineAt) {
      subtypes = subtypes.filter((subT) => {
        const filteredRevisionSubTypes = FILTERED_REVISION_FINES_TYPES[type];
        if (filteredRevisionSubTypes.includes(subT)) return false;
        return true;
      });
    }

    if (!hasFines) {
      subtypes = subtypes.filter((subT) => {
        const filteredSubTypes = FILTERED_WITHOUT_FINES[type];
        return !filteredSubTypes.includes(subT);
      });
    }
    return subtypes;
  };

  const propsData = {
    dataForm,
    allMyOrder,
    isConvertMessage,
    newTicketFiles,
    writerId,
    onChangeOrder,
    onChangeType,
    onChangeSubType,
    onChangeMessage,
    uploadFile,
    removeFile,
    filterForSubTypes,
    loading: loading || isLoading,
    isCompleteDayLater,
    orderRelateKey,
    isCanceledOrder,
  };

  const issueTypeList = Object.keys(ISSUE_TYPE[orderRelateKey]) || [];
  const subTypesIssue = filterForSubTypes(typeIssue, ISSUE_TYPE[orderRelateKey][typeIssue] || []);
  // subTypesIssue = filterForSubTypesRevision(typeIssue, subTypesIssue);

  useEffect(() => {
    if (callFromOrderContent) return;

    toggleAllMyOrdersLoading(true);
    authFetch(`${urls.orders}?folder=retro_my&fields=_id+number+title+assigned_by_me+writer_completed`)
      .then((data) => {
        const { results = [] } = data;
        unstable_batchedUpdates(() => {
          setAllMyOrder(results);
          if (!results.length) {
            onChangeOrder([null, null, 'Not order-related'], true);
          }
        });
      })
      .catch(() => {
        console.error('Error fetching retro_my');
      })
      .finally(() => {
        toggleAllMyOrdersLoading(false);
      });
  }, []);

  return (
    <>
      {openReasonSelector &&
        <Modal onClose={onOpenReasonSelector} wide>
          <ModalReasonSelector onClose={onOpenReasonSelector} onSubmit={onOpenReasonSelector} onChangeReason={onChangeReason} reason={selectedReason} typeIssue={typeIssue} />
        </Modal>
      }

      <ModalHeader status={dataForm.status} ticket={ticket} orderNumber={stateOrderNumber} onClose={onClose} />

      {!ticket &&
        <WriterIssueRequestNew
          {...propsData}
          isCompletedOrder={isCompletedOrder}
          hasProgressOrder={hasProgressOrder}
          isUserCantSendIssue={isUserCantSendIssue}
          isAllMyOrdersLoading={isAllMyOrdersLoading}
          callFromOrderContent={callFromOrderContent}
          issueTypeList={issueTypeList}
          subTypesIssue={subTypesIssue}
          changeReason={setOpenReasonSelector}
          selectedReason={selectedReason}
        />
      }

      {ticket &&
        <>
          <WriterIssueRequestView {...propsData} ticket={ticket} profile={profile} />
          <WriterIssueAnswer
            dataForm={dataForm}
            ticket={ticket}
            otherText={otherText}
            fileList={fileList}
            onChangeOtherText={onChangeOtherText}
            onSetRating={onSetRating}
            onSubmitReview={onSubmitReview}
            onTagSelect={onTagSelect}
          />
        </>
      }

      <ModalFooter
        dataForm={dataForm}
        showIssueError={showIssueError}
        issueErrorText={issueErrorText}
        isDisabledReset={isDisabledReset}
        isConvertMessage={isConvertMessage}
        orderId={orderId}
        isDisableSubmit={isDisableSubmit}
        onCancel={onCancel}
        onSubmit={onSubmit}
        onReset={onReset}
        ticket={ticket}
        onCancelTicket={onCancelTicket}
      />
    </>
  );
};

export default WriterTicketingIssue;
