import React from 'react';
import classNames from '../utils/classNames';

const ButtonList = (props) => {
  const {
    chooseValue,
    list,
    onChange,
    className,
    setOther,
    otherName,
    field,
    activeIndex,
  } = props;

  const isActiveOther = chooseValue.length && chooseValue.indexOf(otherName) > -1;
  const index = activeIndex || 1;
  return (
    <div className="btn-list">
      {list.map((item) => {
        const isActive = chooseValue && (String(chooseValue) === String(item[1])) && !isActiveOther;
        const text = isActive && activeIndex ? item[activeIndex] : item[0];
        
        return (
          <button
            className={classNames('btn-list__item', className, { active: isActive })}
            onClick={() => onChange(item[1], field, false)}
            key={item[index]}
          >
            {text}
          </button>
        );
      })
      }
      {setOther &&
        <button
          className={classNames('btn-list__item', className, { active: isActiveOther })}
          onClick={() => onChange(otherName, field, true)}
          key={otherName}
        >
          {otherName}
        </button>
      }
    </div>
  );
};

export default ButtonList;
