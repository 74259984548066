import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { userWarningSetViewedFetch } from './../../store/user/actions';
import ActiveWarningsTableRow from './ActiveWarningsTableRow';

class ActiveWarningsTable extends Component {
  constructor(props) {
    super(props);

    this.onUpdateWarning = this.onUpdateWarning.bind(this);
  }

  onUpdateWarning(tag) {
    this.props.update(tag);
  }

  render() {
    const { warningsIsFetching, activeWarningsIsFetching, warnings } = this.props;
    warnings.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));
    if (warningsIsFetching || activeWarningsIsFetching) return null;
    return (
      <div>
        <div className='active-warnings-table-header'>
          <div className='active-warnings-table-header-type'>Type</div>
          <div className='active-warnings-table-header-description'>Description</div>
          <div className='active-warnings-table-header-created'>Created</div>
          <div className='active-warnings-table-header-closed'>Closed</div>
        </div>
        {
          warnings.map((warning) => {
            if (warning.weight) {
              return <ActiveWarningsTableRow key={warning._id} warning={warning} seen={warning.seen} update={this.onUpdateWarning} />
            }
          })
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const stateWarnings = (state.user && state.user.activeWarnings) || null;
  const activeWarningsIsFetching = (stateWarnings && stateWarnings.isFetching) || false;
  const warningsIsFetching = (state.user && state.user.warnings && state.user.warnings.isFetching) || false;
  const warnings = (stateWarnings && stateWarnings.results && stateWarnings.results.items) || [];
  // console.log(state.user.warnings);
  return { warningsIsFetching, warnings, activeWarningsIsFetching };
}

const mapDispatchToProps = (dispatch) => {
  return {
    update: (tag) => {
      dispatch(userWarningSetViewedFetch(tag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveWarningsTable);
