import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';

import { userSetSubjectsFetch } from 'store/user/actions';

import Dropdown from 'components/Dropdown';
import Select from 'components/Select';


import './styles.scss';

const SUBJECTS_DROP_LABELS = ['Primary', 'Additional #1', 'Additional #2'];

const MySubjectGroupsForm = ({
  subjectList1, subjectList2, subjectList3, selectedSubjects, isEditable,
}) => {
  const dispatch = useDispatch();

  const onChange = useCallback(
    (value, index) => {
      if (selectedSubjects.includes(value)) return;

      dispatch(userSetSubjectsFetch(selectedSubjects.map((subject, __index) => {
        if (__index === index) return value;
        return subject;
      })));
    },
    [dispatch, selectedSubjects],
  );

  const renderDropdown = (subjectGroup, index) => (
    <div key={SUBJECTS_DROP_LABELS[index]}>
      <h2 className="subjects-group-form__dropdown-title fsXSm mb8">{ SUBJECTS_DROP_LABELS[index] }</h2>
      <Dropdown disabled={!isEditable} text={selectedSubjects[index]} closeAlways up extraDropdownClass="subjects-group-form__dropdown">
        <Select className="menu menu-selectable" value={selectedSubjects[index]} onChange={value => onChange(value, index)}>
          {subjectGroup.map(subject => <li className="menu-item subjects-group-form__dropdown_items" key={subject} value={subject}>{subject}</li>)}
        </Select>
      </Dropdown>
    </div>
  );

  return (
    <div className="subjects-box subjects-group-form">
      <p><span>Please select three subject categories that you are ready to write about.</span> Our team leaders will recommend you orders according to your preferences.</p>
      <div className="row">
        {[subjectList1, subjectList2, subjectList3].map((subjectList, index) => (
          <div className="col-1 subjects-group-form__col" key={selectedSubjects[index]}>
            {renderDropdown(subjectList, index)}
          </div>
          ))}
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  const { orderSubjectGroups } = state.settings;
  const subjectList = Object.keys(orderSubjectGroups).filter(s => s.toLowerCase() !== 'other').sort();

  const selectedSubjects = (state.user.subjects || []).filter(subject => subjectList.indexOf(subject) > -1);

  const subjectList1 = subjectList.filter(subject => subject !== selectedSubjects[1] && subject !== selectedSubjects[2]);
  const subjectList2 = subjectList.filter(subject => subject !== selectedSubjects[0] && subject !== selectedSubjects[2]);
  const subjectList3 = subjectList.filter(subject => subject !== selectedSubjects[0] && subject !== selectedSubjects[1]);


  const isLoading = state.user.isFetchingSubjects;

  return {
    subjectList1,
    subjectList2,
    subjectList3,
    selectedSubjects,
    isLoading,
  };
};


export default connect(mapStateToProps)(MySubjectGroupsForm);
