import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'components/Modal';
import Icon from 'components/Icon';

import { deleteAllModalComponent } from 'store/modals/actions';
import { getModalList } from 'store/modals/selectors';
import classNames from 'utils/classNames';

import './styles/index.scss';

const ModalContainer = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { modalList: modals, modalOptions } = useSelector(getModalList);
  const modalList = useMemo(() => modals.sort((a, b) => a.priority - b.priority) || [], [modals]);
  const deleteModal = () => dispatch(deleteAllModalComponent());

  const [isError, setError] = useState(false);
  const [modalHeader, setModalHeader] = useState(modalList[0]?.type?.displayName || '');

  const updateHeader = useCallback(
    (name) => {
      setModalHeader(name);
    },
    [setModalHeader],
  );

  useEffect(() => {
    updateHeader(modalList[0]?.type?.displayName || '');
  }, [modalList]);

  if (modalList.length <= 0) return null;

  if (isMobile) {
    const modal = modalList[0];
    const { component, props = {}, modalProps = {} } = modal;

    return (
      <div className={classNames('modal-reduce-container', { 'modal-reduce-container--overflow': modalOptions.overFlowHidden })}>
        {!component &&
          <div className="modal-reduce-container__header">
            {!isError &&
              <div onClick={deleteModal} className="modal-reduce-container__header__back">
                <Icon className="svg-icon" iconName="back" />
              </div>
            }
            <div className="modal-reduce-container__header__name">{modalHeader}</div>
          </div>
        }
        {
          component ? <div>{React.cloneElement(component, props)}</div>
          :
          <div>{React.cloneElement(modal, { setError, updateHeader })}</div>
        }
      </div>
    );
  }

  const { component, props = {}, modalProps = {} } = modalList[0];

  return <Modal {...modalProps}>{React.cloneElement(component, props)}</Modal>;
};

export default ModalContainer;

