/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import InputWithSearch from 'components/shared/InputWithSearch';

import { NOT_ORDER } from 'components/WriterTicketing/constants/writerTicketing';


const DropdownOrders = ({
  onChangeOrder, orderList, loading, isDisabled, orderText, toggleReset,
}) => {
  const renderListValues = (orders, itemClass, onSelect, withSearch) => orders.reduce((prev, order, idx) => {
    const {
      number, _id: orderId, title, assigned_by_me: assignedByMe = false,
    } = order;
    const element = (<li
      onClick={e => onSelect(`${number}: ${title}`, e)}
      className={itemClass}
      data-value={[number, orderId, title, assignedByMe]}
      key={orderId}
    >
      {`${number}: ${title}`}
    </li>);
    if (!withSearch && idx === 0) {
      prev.push(<li key={0} onClick={e => onSelect(NOT_ORDER, e)} data-value={[null, null, null]} className={itemClass}>{NOT_ORDER}</li>, element);
    } else {
      prev.push(element);
    }
    return prev;
  }, []);

  const filterFunction = (value, orders) => orders.filter(({ number, title }) => {
    const numberLC = number.toLocaleLowerCase();
    const titleLC = title.toLocaleLowerCase();
    const valueLC = value.toLocaleLowerCase();
    return numberLC.includes(valueLC) || titleLC.includes(valueLC) || `${numberLC}: ${titleLC}`.includes(valueLC);
  });

  const onSelectHandler = (value, e) => {
    if (!value) {
      onChangeOrder([null, null, null, 'false'], false);
      return;
    }

    const { target: { dataset = {} } } = e;
    let orderDetails = ['null'];
    if (Boolean(dataset.markjs)) {
      const parent = e.target.parentElement;

      if (parent) {
        orderDetails = ((parent || {}).dataset.value || '').split(',');
      }
    } else {
      orderDetails = (dataset.value || '').split(',');
    }

    if (orderDetails.includes('null')) {
      onChangeOrder([null, null, null, 'false'], true);
      return;
    }
    onChangeOrder(orderDetails, true);
  };

  return (
    <>
      <h3 className="filter-name">Is this issue related to one of your orders?</h3>
      <InputWithSearch
        inputPlaceholder="Select from the list"
        searchValues={orderList}
        renderListValues={renderListValues}
        filterFunction={filterFunction}
        maxItems={50}
        isLoading={loading}
        onSelectHandler={onSelectHandler}
        extraItem="Not order-related"
        isDisabled={isDisabled}
        inputValue={orderText}
        toggleReset={toggleReset}
      />
    </>
  );
};

export default DropdownOrders;
