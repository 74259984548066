import React from 'react';

import Radio from 'components/Radio';
import StarIcon from 'components/StarIcon';

const QACategoryItem = ({ dataSection, dataRow }) => {
  const renderRow = (tableRow) => {
    const { id, tooltip } = tableRow;
    const currentValue = dataRow && dataRow[id];
    if (currentValue === null) return null;

    return (
      <tr id={id} key={id}>
        <td>
          <span dangerouslySetInnerHTML={{ __html: tableRow.name }} />
          {tooltip &&
            <div className="tooltip">
              <a className="tooltip-trigger"></a>
              <div className="tooltip-content" dangerouslySetInnerHTML={{ __html: tableRow.tooltip }} />
            </div>
          }
        </td>
        {Object.values(tableRow.score).map((itemValue, idx) =>
        (<td key={`${id}_${itemValue}_${idx}`}>
          <Radio
            disabled
            name={id}
            value={itemValue || 0}
            checked={currentValue === itemValue}
          />
        </td>))}
      </tr>
    );
  };

  return (
    <table>
      <tbody>
        <tr>
          <th width="26%">Criterion</th>
          <th>
            <div className="stras_wrap"><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></div>
            <span>Excellent</span>
          </th>
          <th>
            <div className="stras_wrap"><StarIcon /><StarIcon /><StarIcon /><StarIcon /></div>
            <span>Quite Good</span>
          </th>
          <th>
            <div className="stras_wrap"><StarIcon /><StarIcon /><StarIcon /></div>
            <span>Average</span>
          </th>
          <th>
            <div className="stras_wrap"><StarIcon /><StarIcon /></div>
            <span>Poor</span>
          </th>
          <th>
            <div className="stras_wrap"><StarIcon /></div>
            <span>Unacceptable</span>
          </th>
        </tr>
        {Object.values(dataSection.data).map(renderRow)}
      </tbody>
    </table>
  );
};

export default QACategoryItem;
