import { TAGS_BAD, TAGS_GOOD, TAGS_RESOLVED, NOTICES_NOTIFICATION_LIMIT } from 'components/WriterGroup/constants';

const apiHost = process.env.REACT_APP_BUILD === 'prod'
  ? 'https://account.lrwriters.com'
  : 'https://writer.tn-company.com';

const authSiteHost = process.env.REACT_APP_BUILD === 'prod'
  ? 'https://oauth.dsh-agency.com'
  : 'https://oauth-stage.dsh-agency.com';

const authBackUrl = process.env.REACT_APP_BUILD === 'prod'
  ? 'https://auth-back.dsh-agency.com'
  : 'https://auth-back-stage.dsh-agency.com';

const fileServerUrl = process.env.REACT_APP_BUILD === 'prod'
  ? 'https://nwzc6p9uv6.execute-api.eu-west-1.amazonaws.com/production_v1'
  : 'https://n4bybpe0o7.execute-api.eu-west-1.amazonaws.com/dev';

const apiHostTickets = process.env.REACT_APP_BUILD === 'prod'
  ? 'https://tickettool.dsh-agency.com'
  : 'https://tickettool-stage.dsh-agency.com';

const apiHostA5 = process.env.REACT_APP_BUILD === 'prod'
  ? 'https://api.dsh-agency.com'
  : 'https://api-stage.tn-company.com';

const apiHostTutuor = process.env.REACT_APP_BUILD === 'prod'
  ? 'https://account.lrtutors.com'
  : 'https://stage.lrtutors.com';

const apiHostSocket = process.env.REACT_APP_BUILD === 'prod'
  ? 'wss://mqtt.dsh-agency.com:8084/mqtt'
  : 'wss://mqtt-stage.dsh-agency.com:8084/mqtt';

const apiSentry = 'https://efb6902609ab40448f0e04811ccf6fb8@o255621.ingest.sentry.io/5281134';

const LR_TRACK_URL = process.env.REACT_APP_BUILD === 'prod'
  ? 'https://statistic.dsh-agency.com/'
  : 'https://statistic-stage.dsh-agency.com';

const CLIENT_CHAT_AVATARS_SERVICE = process.env.REACT_APP_BUILD === 'prod'
  ? 'https://d2ixdcswxptrv4.cloudfront.net'
  : 'https://d3h9lt908fi2d6.cloudfront.net';

const clientChatUrl = process.env.REACT_APP_BUILD === 'prod'
  ? 'https://xmpp.nt.dsh-agency.com/nodes/get_items'
  : 'https://xmpp-nt.dsh-agency.com/nodes/get_items';

const LR_TRACK_ID = 'WRITER_TOOL';

const apiUrl = '/api/v3';
const apiUrlV1 = '/api/v1';
const apiUrlV4 = '/api/v4';

const testCallHost = 'https://nerdy-calls.netlify.app';

const urls = {
  apiHostSocket,
  orders: `${apiHost + apiUrl}/orders`,
  updateOrder: orderId => `${apiHost + apiUrl}/orders/${orderId}`,
  orderEstimate: orderId => `${apiHost + apiUrl}/orders/${orderId}/estimates`,
  onChatMessage: orderId => `${apiHost + apiUrl}/orders/${orderId}/on_chat_message`,
  orderDetails: orderNumber => `${apiHost + apiUrl}/order_number/${orderNumber}`,
  settings: `${apiHost + apiUrl}/settings?country_list=1`,
  user: `${apiHost + apiUrl}/profile`,
  finances: `${apiHost + apiUrl}/finances`,
  tasks: `${apiHost + apiUrl}/profile/tasks`,
  warnings: tag => `${apiHost + apiUrl}/profile/notices${tag ? `/${tag}` : ''}`,
  activeWarnings: profileId => `${apiHost + apiUrl}/users/writer/${profileId}/notices/all?status=active&has_weight=1`,
  updates: `${apiHost + apiUrl}/broadcast`,
  notification: `${apiHost + apiUrl}/notification`,
  notices: `${apiHost + apiUrl}/profile/notices_notification?limit=${NOTICES_NOTIFICATION_LIMIT}&sort=-created_at${[...TAGS_BAD, ...TAGS_GOOD, ...TAGS_RESOLVED]
    .map(item => `&event_tag[]=${item}`).join('')}`,
  upload: `${apiHost + apiUrl}/upload`,
  feedbacks: params => `${apiHost + apiUrl}/profile/feedbacks${params}`,
  feedbackUpdate: id => `${apiHost + apiUrl}/profile/feedbacks/${id}`,

  ticketsSettings: `${apiHostTickets + apiUrlV1}/state_data`,
  tickets: params => `${apiHostTickets + apiUrlV1}/tickets${params}`,
  ticket: `${apiHostTickets + apiUrlV1}/tickets`,
  updateTicket: ticketId => `${apiHostTickets + apiUrlV1}/tickets/${ticketId}`,

  writer_feedbacks: (params, profileId) => `${apiHost + apiUrl}/users/writer/${profileId}/writer_feedbacks${params}`,

  inboxUpdates: `${apiHostA5 + apiUrlV4}/commupdates`,

  login: authSiteHost,
  logout: `${authSiteHost}/logout`,
  signIn: `${authBackUrl}/users/sign_in`,
  self: `${authBackUrl}/self`,
  replica_token: id => `${authBackUrl}/users/${id}/replica_token`,

  verify: `${apiHost}/api/veriff/session`,

  fileServerUrl,
  apiSentry,

  checkIBAN: iban => `${apiHost + apiUrl}/settings/bankapi/iban/${iban}`,
  checkSWIFT: swift => `${apiHost + apiUrl}/settings/bankapi/swift/${swift}`,

  clientSessions: (params = {}) => `${apiHost + apiUrl}/live_schedules?${new URLSearchParams(params).toString()}`,
  orderSessions: (orderId, params = {}) => `${apiHost + apiUrl}/orders/${orderId}/live_schedules?${new URLSearchParams(params).toString()}`,
  clientSessionDelete: sessionId => `${apiHost + apiUrl}/live_schedules/${sessionId}`,
  clientChatUrl,
  swSubscribe: `${apiHost + apiUrl}/profile/wpcreds`,
  totp2fa: `${apiHost + apiUrlV4}/profile/totp2fa`,
};

export { urls, LR_TRACK_URL, LR_TRACK_ID, apiHostTutuor, CLIENT_CHAT_AVATARS_SERVICE, testCallHost };
