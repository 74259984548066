import React from 'react';

import Modal from 'components/Modal';

import checkGreen from 'img/icons/check_green.svg';

const ThanksModal = ({ toggleThanksWireTransferModal }) => (
  <Modal notHandleOutside small className="ta-center">
    <img src={checkGreen} alt="" width="52" className="mb20" />
    <h2 className="title mb15">Awesome!</h2>
    <p className="modal-text fsL">Your information has been submitted. You will receive an email about the status of your new payment method within 2-5 business days.</p>
    <button className="btn btn-bright btn-8-4" onClick={toggleThanksWireTransferModal}>Got It</button>
  </Modal>
);

export default ThanksModal;
