import React from 'react';

const doMatch = (text = '') => {
  const reg = /([^"=]{2}|^)((https?|ftp):\/\/\S+[^\s.,> )\];'"!?])/gim;
  const subst = '$1<a href="$2" target="_blank">$2</a>';
  return text.replace(reg, subst);
};

const TextWithLink = ({ isDuplicateAndRevisionOrder, text, title }) => {
  if (!text) return null;

  const replaceBr = (text || '').replace(/\<br\>/g, ' \n ');
  let replaceDiv = replaceBr.replace(/\<div\>/g, ' \n ');
  replaceDiv = replaceDiv.replace(/\<\/div\>/g, ' \n ');
  const splitByN = replaceDiv.split(' \n ') || [];
  const simpleTexts = splitByN.map((Sn = '') => doMatch(Sn));
  // const text = doMatch(props.text.replace(/(<([^>]+)>)/gi, ''));
  // const simpleTexts = text.split(/<a.+?\/a>/);
  // const linkText = text.match(/<a.+?\/a>/g);

  return (
    <div className="mt16 fs15 order_description">
      <strong className="mb4 block">{isDuplicateAndRevisionOrder ? 'Initial order instructions' : title}:</strong>
      {simpleTexts.map((simpleText, i) => (
        <span key={`${simpleText}-${i}`}>
          <span dangerouslySetInnerHTML={{ __html: simpleText }} />
          {/* {linkText && <span dangerouslySetInnerHTML={{ __html: linkText[i] }} />} */}
        </span>
      ))}
    </div>
  );
};

export default TextWithLink;
