import React from 'react';

import IconTag, { iconsLabels } from 'components/shared/IconTag';

const IconTagStory = () => (
  <div className="tags-list">
    {Object.entries(iconsLabels).map(([key]) => (<IconTag key={key} iconName={key} callFromTable />))}
  </div>
);

export default IconTagStory;
