import React from 'react';

import slides from './slides';

const TermsOfUseItems = ({ currentPage = 1 }) => (
  <div className="terms-of-use-container">
    <div className="terms-of-use-container__header">
      Terms of Use
    </div>
    <div className="terms-of-use-container__page-count">Page {currentPage}/{slides.length}</div>
    <div dangerouslySetInnerHTML={{ __html: slides[currentPage - 1] }} />
  </div>
);

export default TermsOfUseItems;
