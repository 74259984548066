const set = (key, data) => {
  const serialized = JSON.stringify(data);
  try {
    localStorage.setItem(key, serialized);
  } catch (e) { }
};

const get = (key) => {
  try {
    const serialized = localStorage.getItem(key);
    if (serialized === null) {
      return serialized;
    }
    const data = JSON.parse(serialized);
    return data;
  } catch (e) {
    return null;
  }
};

const remove = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) { }
};

export default { set, get, remove };
