import React from 'react';
import { useDispatch } from 'react-redux';

import { addModalComponent } from 'store/modals/actions';

import modalList from 'constants/modalList';

import TemplateModal from 'components/TemplateModal';


const Instructions = ({
  order, profile, onClose, onSubmit,
}) => {
  const {
    writer_chat_message_at: writerChatMessageAt,
    writer_completed: writerCompleted,
  } = order;

  const dispatch = useDispatch();

  const onRequestClarifications = () => {
    const dataModal = modalList.Templates;

    dispatch(addModalComponent({
      component: <TemplateModal
        onSubmit={text => onSubmit(text, 'tutorSentToClientInstructionsClarification')}
        onClose={onClose}
        templateIndex={6}
      />,
      ...dataModal,
    }));
  };


  return (
    <div>
      <div className="step-container__title">
        Check all the available order details, and if needed, clarify questions you have with the client. Otherwise, confirm that everything is clear.
      </div>
      <div className="step-container__tips">Use if you have questions</div>
      <button className="btn btn-light mb8" type="button" disabled={!writerChatMessageAt || Boolean(writerCompleted)} onClick={onRequestClarifications}>Request clarifications</button>
      <div className="step-container__tips">Obligatory to accept the order</div>
    </div>
  );
};

export default Instructions;
