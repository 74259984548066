import React, { useState, useCallback, useEffect, Fragment, useRef } from 'react';
import { pdfjs } from 'react-pdf';

import { usePrevious, useMobile } from 'hooks';

import utils from 'utils';

import classNames from 'utils/classNames';
import isImage from 'utils/isImage';
import { downloadFile } from 'utils/downloadFile';

import ImageZoom from 'components/ImageZoom';

import { AVAILABLE_FORMATS } from './constants';

import { Iframe, SideBar, PDF, SideBarMobile } from './components';

import './styles/index.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const baseURL = 'https://view.officeapps.live.com/op/embed.aspx?src=';

let timerId = null;

const FilesPreview = ({
  docs = [], downloadAll, activeIndex = 0,
  updateHeader, checkDownloadFetching,
}) => {
  const [previewItem, setPreview] = useState(docs[activeIndex] || {});
  const [isError, setError] = useState(false);
  const [checkedDocs, updateCheckedDocs] = useState([]);
  const prevActiveIndex = usePrevious(activeIndex);
  const isMounted = useRef(true);

  const { real_location: urlSelected, name, id: selectedID } = previewItem;

  const extension = utils.getFileExtension(name).toLocaleLowerCase();
  const isFileImage = isImage(name);
  const [isLoading, setLoader] = useState(!isFileImage);

  const { isMobile } = useMobile(760);

  const prevDocs = usePrevious(docs);

  const startNewTimer = () => {
    timerId = setTimeout(() => {
      setLoader(false);
      setError(true);
    }, 7000);
  };

  const clearTimer = () => {
    clearTimeout(timerId);
    timerId = null;
  };

  const downloadFileHandler = () => {
    downloadFile(urlSelected, name);
  };

  useEffect(() => {
    if (prevDocs && docs && JSON.stringify(prevDocs) !== JSON.stringify(docs)) {
      let newIndex = prevActiveIndex !== undefined ? prevActiveIndex : activeIndex;

      if (docs.length !== prevDocs.length) {
        if (docs.length < prevDocs.length) {
          newIndex = 0;
        } else {
          newIndex = docs.length - 1;
        }
      }
      const file = docs[newIndex];

      if (!file || docs.length === 0) {
        updateHeader('');
        setPreview({});
        return;
      }

      updateHeader(file.name);
      setPreview(docs[newIndex]);
    }
  }, [prevDocs, docs, activeIndex, updateHeader, prevActiveIndex]);

  useEffect(() => {
    if (isLoading && !timerId && !isFileImage) {
      startNewTimer();
    }
  }, [isLoading, isFileImage]);

  useEffect(() => {
    updateHeader(name);
    return () => {
      clearTimer();
      isMounted.current = false;
    };
  }, []);

  const setPreviewCallback = useCallback(
    (index) => {
      let file = docs[index];

      if (!file || !file.id) {
        file = docs[0];
      }

      if (file.id === previewItem.id) return;

      const __isFileImage = isImage(file.name);

      updateHeader(file.name);
      setPreview(file);
      clearTimer();

      if (isLoading) {
        setLoader(false);
        setError(false);

        setTimeout(() => {
          if (!__isFileImage) {
            setLoader(true);
            startNewTimer();
          }
        }, 1);
      } else if (!__isFileImage) {
        setLoader(true);
        setError(false);
      }
    },
    [setPreview, setLoader, setError, isLoading, updateHeader, previewItem],
  );

  const hideLoader = useCallback(
    (isWithError) => {
      setLoader(false);

      if (isWithError) {
        setError(true);
      }

      if (timerId) {
        clearTimer();
      }
    },
    [setLoader],
  );

  const refreshPreviewItem = useCallback(
    () => {
      clearTimer();
      setError(false);
      const prevPreview = { ...previewItem };
      setPreview({});
      setTimeout(() => {
        setPreview(prevPreview);
        setLoader(true);
        startNewTimer();
      }, 1);
    },
    [setError, setPreview, previewItem],
  );

  const downloadAllHandler = useCallback(
    () => {
      if (!downloadAll) return;

      downloadAll(checkedDocs);
    },
    [downloadAll, checkedDocs],
  );

  if (!urlSelected) return null;

  const isUnFormatFile = !isFileImage && !AVAILABLE_FORMATS.includes(extension);
  const iframe = `<iframe src=${baseURL}${urlSelected} frameBorder="0" height="100%" width="100%" allowFullScreen="" scrolling="auto"></iframe>`;

  const renderContent = () => {
    if (isFileImage && !isMobile) {
      return (
        <ImageZoom imgProps={{ src: urlSelected, alt: name }} />
      );
    }

    if (isFileImage && isMobile) {
      return (
        <Fragment>
          <img src={urlSelected} alt={name} />
        </Fragment>
      );
    }

    if (isUnFormatFile) {
      return (
        <div className="state_container">
          <div className="state_inner_container">
            <i className="unFormat_icon" />
            <div className="state_container_text">Preview can not be generated for this format.</div>
            <button onClick={downloadFileHandler} className="btn btn-bright btn-md2 mt30">
              Download file
            </button>
          </div>
        </div>
      );
    }

    return (
      <Fragment>
        <div className={classNames('state_container', { state_container_error: isError })}>
          {
            isLoading && !isError && (
              <div className="state_inner_container">
                <div className="loader-files-preview" />
                <div className="state_container_text">Please wait. Preview is uploading...</div>
              </div>
            )
          }
          {
            isError &&
            <div className="state_inner_container">
              <i className="error_icon" />
              <div className="state_container_text">Error occurred while generating preview.</div>
              <button primary onClick={refreshPreviewItem} className="btn btn-bright btn-md2 mt30">Please try again</button>
            </div>
          }
        </div>
        {
          extension === 'pdf' ? (
            <PDF pdf={urlSelected} hideLoader={hideLoader} show={!isLoading && !isError} />
          ) : (
              <Iframe iframe={iframe} hideLoader={hideLoader} showFrame={!isLoading && !isError} />
            )
        }
      </Fragment>
    );
  };

  if (isMobile) {
    return (
      <div className="preview_files_main_container">
        <SideBarMobile docs={docs} selectedID={selectedID} activeIndex={activeIndex} setPreviewCallback={setPreviewCallback} />
        {
          !isLoading && isMobile && (<div aria-hidden="true" className="download-btn" onClick={downloadFileHandler}>Download</div>)
        }
        <div className="preview_files_main_container__mobile-body">
          {
            renderContent()
          }
        </div>
      </div>
    );
  }

  return (
    <div className="preview_files_main_container">
      <SideBar
        docs={docs}
        checkDownloadFetching={checkDownloadFetching}
        downloadAllHandler={downloadAllHandler}
        selectedID={selectedID}
        checkedDocs={checkedDocs}
        setPreviewCallback={setPreviewCallback}
        updateCheckedDocs={updateCheckedDocs}
      />
      <div className="preview_container">
        {
          renderContent()
        }
      </div>
    </div>
  );
};

export default FilesPreview;
