
import {
  ORDERS_SET_RESERVED_RECEIVE,
  ORDERS_SET_RESERVATION_OVER,
} from '../constants';

export const ordersSetReservedReceive = number => ({
  type: ORDERS_SET_RESERVED_RECEIVE,
  number,
});


export const ordersSetReservationOver = number => ({
  type: ORDERS_SET_RESERVATION_OVER,
  number,
});
