
import React from 'react';
import emptyState from 'img/icons/empty-state-ill.svg';

const ExpiredModal = ({ onBtnClick }) => (
  <div className="modal-wrap">
    <div className="modal-container modal-sm ta-center">
      <img className="modal-icon" src={emptyState} alt="" />
      <h2 className="title">The session has expired</h2>
      <p className="modal-text">Please login again to continue working.</p>
      <button type="button" className="btn btn-bright btn-big" onClick={onBtnClick}>Log in</button>
    </div>
  </div>
);

export default ExpiredModal;
