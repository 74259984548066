import classNames from 'utils/classNames';

const DeleteFileIcon = ({
  isDisabled, onClick, className, name,
}) => (
  <button
    className={classNames(
      'btn btn-empty btn-delete-file',
      className,
    )}
    type="button"
    onClick={(e) => {
      e.stopPropagation();
      onClick(e, name);
    }}
    disabled={isDisabled}
    name={name}
  >
    <svg className="delete-file">
      <g>
        <circle cx="8" cy="8" r="8" />
        <path fill="#9CA9B5" fillRule="nonzero" d="M8.667 8L11 5.667 10.333 5 8 7.333 5.667 5 5 5.667 7.333 8 5 10.333l.667.667L8 8.667 10.333 11l.667-.667L8.667 8z" />
      </g>
    </svg>
  </button>
);

export default DeleteFileIcon;
