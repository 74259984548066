import moment from 'moment-timezone';

const getVerificationRequesters = (profile = {}) => {
  const { is_verification_request, is_verification_call_request } = profile;

  const isVerificationCallRequest = moment(is_verification_call_request || '').add(120, 'hours').isAfter(moment()) ? is_verification_call_request : null;
  const isVerificationRequest = moment(is_verification_request || '').add(120, 'hours').isAfter(moment()) ? is_verification_request : null;

  return {
    isVerificationCallRequest,
    isVerificationRequest,
  };
};

export default getVerificationRequesters;

