import {
  ORDERS_ADD_LIST,
} from '../constants';

import { ORDERS_HIDE_IN_LISTS } from './index';

export const ordersAddList = items => ({
  type: ORDERS_ADD_LIST,
  items,
});

export const ordersHideInLists = number => ({
  type: ORDERS_HIDE_IN_LISTS,
  number,
});
