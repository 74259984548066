import React from 'react';

import classNames from 'utils/classNames';

import { TAB_INFO,
  TAB_QA,
  TAB_MESSAGES,
  TAB_REVISION,
  TAB_CLIENT_CHAT,
  TAB_CLIENT_SESSIONS } from 'constants/order';

const getNewNotifications = {
  [TAB_MESSAGES]: (messageList = []) => messageList.some(message => message.recepient_role === 'writer' && (message.status === 'unread')),
  [TAB_CLIENT_CHAT]: (__skip, hasClientChatNotification) => hasClientChatNotification,
};

const TabButton = ({
  messageList, order,
  hasClientChatNotification, tab,
  tabName, text,
  showTabRevision, showTabQA,
  setTab, standalone,
}) => {
  const { no_sensitive: noSensitive = false } = order;

  let className = classNames('btn col-1', { active: tab === tabName });
  const classNew = classNames({
    new: getNewNotifications[tabName] && getNewNotifications[tabName](messageList, hasClientChatNotification),
  });
  const newBtnClassName = classNames({
    standalone,
  });
  const allBtns = showTabRevision && showTabQA;
  const isDisabledTab = noSensitive && tabName === TAB_REVISION;
  className = classNames(className, { 'w-50': allBtns });
  className = classNames(className, { 'btn--no-bottom-border': allBtns && (tabName === TAB_REVISION || tabName === TAB_INFO) });
  className = classNames(className, { 'btn--first': allBtns && (tabName === TAB_REVISION) });
  className = classNames(className, { 'btn--second': allBtns && (tabName === TAB_INFO) });
  className = classNames(className, { 'btn--third': allBtns && (tabName === TAB_MESSAGES) });
  className = classNames(className, { 'btn--last': allBtns && (tabName === TAB_QA) });
  className = classNames(className, { 'btn--right': tabName === TAB_CLIENT_SESSIONS });
  className = classNames(className, { 'disabled-no-cursor': isDisabledTab });

  return (
    <button className={className} onClick={() => !isDisabledTab && setTab(tabName)}>
      <span className={classNames(newBtnClassName, classNew)}>{text}</span>
    </button>
  );
};

export default TabButton;
