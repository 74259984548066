import React from 'react';

import { AddressForm } from '../Forms';

const Location = ({
  data, isStem, isDe, onChangeFields, countryList,
}) => (
  <div className="row person-common-info">
    <div className="col-2 person-common-info-title">Location</div>
    <div className="col-4">
      <AddressForm profile={data} countryList={countryList} isStem={isStem} isDe={isDe} onChangeFields={onChangeFields} />
    </div>
  </div>
);

export default Location;
