import React, { useMemo } from 'react';
import classnames from 'utils/classNames';
import moment from 'moment-timezone';

import DeFirstThreeRow from './DeFirstThreeRow';
import OtherFirstThreeRow from './OtherFirstThreeRow';

const PublicProfile = (props) => {
  const { data, dataLevel, profile, isDe } = props;
  const duration = useMemo(() => Math.ceil(moment().diff(moment(profile.created_at), 'months', true), [profile.created_at]));

  return (
    <div>
      <div className="overlay overlay-tab" />
      <div className="order-tab modal-profile">
        <div className="row">
          <div className={classnames('public_avatar', { female: !data.gender, [data.gender]: data.gender, 'with-avatar': Boolean(data.public_photo) })}>
            {data.public_photo &&
              <img src={data.public_photo} className="container-avatar-img" alt="" />
            }
          </div>

          <div className="person-common-info">

            <div className="order-tab-head">
              <div className="writer-info">
                <div className="row space-between vertical">
                  <span className={dataLevel.iconClassName}>
                    <a href="/progress/activity/warnings" target="_blank">{dataLevel.levelName}-level</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="order-tab-body">
              {isDe && <DeFirstThreeRow data={data} />}
              {!isDe && <OtherFirstThreeRow data={data} />}
              <div className="row row-devider">
                <div className="col-center">
                  <h2 className="order-tab-body__title-tag">Order#</h2>
                  <p className="order-tab-body__desc">
                    {profile.metric_data.completed_orders_count || '-'}
                  </p>
                </div>

                <div className="col-3 col-center">
                  <h2 className="order-tab-body__title-tag">Months on platform</h2>
                  <p className="order-tab-body__desc">{duration || 1}</p>
                </div>

                {/* <div className="col-2 col-center">
                <h2 className="order-tab-body__title-tag">Reviews</h2>
                <p className="order-tab-body__desc">-</p>
              </div>

              <div className="col-1 col-center">
                <h2 className="order-tab-body__title-tag">Score</h2>
                <p className="order-tab-body__desc">
                  <StarRating rate={4} width={60} />
                </p>
              </div> */}
              </div>

              <div className="row row-devider">
                <div className="col-2">
                  <h2 className="order-tab-body__title">Subjects</h2>
                  <p className={classnames('order-tab-body__desc', { danger: profile.subjects.length <= 0 })}>{(profile.subjects).join(', ') || 'Not specified'}</p>
                </div>
              </div>

              <div className="row row-devider">
                <div className="col-2">
                  <h2 className="order-tab-body__title">Overview</h2>
                  <p className={classnames('order-tab-body__desc', { danger: !data.public_overview })}>{data.public_overview || 'Not specified'}</p>
                </div>
              </div>

            </div>

          </div>


        </div>

      </div >
    </div >
  );
};

export default PublicProfile;
