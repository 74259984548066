import { useEffect, useState } from 'react';

let slideCounter = 0;

const useSlider = (images, slideImageContainer) => {
  const [currentImg, setCurrentImg] = useState(images[0]);

  const animateSlide = () => {
    slideImageContainer.current.classList.add('fadeIn');
    setTimeout(() => {
      slideImageContainer.current.classList.remove('fadeIn');
    }, 700);
  };

  useEffect(() => {
    if (images.length === 0) {
      document.body.classList.remove('overflow-hidden');
      slideCounter = 0;
      return;
    }

    document.body.classList.add('overflow-hidden');
    window.scrollTo(0, 0);
    setCurrentImg(images[0]);
  }, [images]);

  const handleSlide = (slide) => {
    setCurrentImg(images[slide - 1]);
    animateSlide();
  };

  const goToPreviousSlide = () => {
    if (slideCounter === 0) {
      handleSlide(images.length);
      slideCounter = images.length - 1;
      return;
    }

    handleSlide(slideCounter);
    slideCounter--;
  };

  const goToNextSlide = () => {
    if (slideCounter === images.length - 1) {
      handleSlide(1);
      slideCounter = 0;
      animateSlide();

      return;
    }

    setCurrentImg(images[slideCounter + 1]);
    slideCounter++;
    animateSlide();
  };

  return { goToPreviousSlide, goToNextSlide, currentImg };
};

export default useSlider;
