export default {
  colorWhite: '#fff',
  colorBlack: '#000',

  colorBlackOpacity25: 'rgba(0, 0, 0, 0.25)',
  colorBlackOpacity07: 'rgba(0, 0, 0, 0.07)',
  colorBlackOpacity04: 'rgba(0, 0, 0, 0.4)',

  colorDark: '#030303',
  colorDarkOpacity: 'rgba(3, 3, 3, .4)',
  colorLight: '#fcfdfd',
  colorExtraLight: '#a7a8ac',

  colorText: '#637280',
  colorTextDark: '#39444d',
  colorTextLightGrey: '#667580',

  color13: '#e3e3e3',
  color12: '#eceff1',
  color11: '#dadee2',
  color10: '#030303',
  color09: '#3b464e',
  color08: '#637280',
  color08Opacity007: 'rgba(99, 114, 128, 0.07)',
  color07: '#9ca9b6',
  color06: '#c0c7ce',
  color05: '#e6e8eb',
  color04: '#f2f3f5',
  color03: '#f7f9fa',
  color02: '#fcfdfd',
  color01: '#f9f9f9',
  color00: '#d7d7d9',

  colorAction11: '#39A6F1',
  colorAction10: '#2a86e1',
  colorAction09: '#389bfe',
  colorAction08: '#8dbdff',
  colorAction07: '#cbe5fb',
  colorAction06: '#d6e9ff',
  colorAction05: '#e1effd',
  colorAction04: '#ebf7ff',
  colorAction03: '#f3f8fd',

  colorMark: '#fff2a6',

  colorBright1_10: '#12ca86',
  colorBrightOpacity008_1_10: 'rgba(18, 202, 134, 0.08)',
  colorBright1_09: '#00af6f',
  colorBrightOpacity1_09: 'rgba(0, 175, 111, .08)',
  colorBrightOpacity20_1_09: 'rgba(0, 175, 111, .2)',
  colorBright1_08: '#f7fff2',
  colorBright_1_07: '#00b06d',
  colorBrightOpacity80_1_07: 'rgba(0, 176, 109, .8)',

  colorBright2_11: '#AA6DD0',
  colorBright2_10: '#fc694f',
  colorBrightOpacity2_10: 'rgba(252, 105, 79, .2)',
  colorBrightOpacity2_10_06: 'rgba(252, 105, 79, .6)',
  colorBrightOpacity055_2_10: 'rgba(252, 105, 79, .05)',
  colorBright2_09: '#ffa65d',

  colorBrightOpacity01_2_09: 'rgba(255, 166, 93, .1)',
  colorBrightOpacity013_2_09: 'rgba(255, 166, 93, 0.13)',
  colorBright2_08: '#ff3c3c',
  colorBright2_07: '#fb4e35',
  colorBright2_06: '#fffae4',
  colorBright2_05: '#FFAA46',
  colorBright3_01: '#ea9e00',
  colorBright3_02: '#e89d00',
};
