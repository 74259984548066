import React, { Component, Fragment } from 'react';

import { withRouter, withRouterMatch } from 'hocs';

import ProposedOrdersModal from 'components/ProposedOrdersModal';

import OrdersTable from './../components/OrdersTable';
import OrderTabPage from './OrderTabPage';

import { ORDERS_TYPE_ALL } from './../store/ordersFind/actions';

const myPath = '/findorders/all';

class FindOrdersAllPage extends Component {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(orderNumber) {
    const { history, match } = this.props;
    const prevOrderNumber = match.params.orderNumber;
    if (prevOrderNumber === orderNumber) {
      history.push(myPath);
      return;
    }
    history.push(`${myPath}/${orderNumber}`);
  }

  render() {
    const { match } = this.props;
    const { orderNumber } = match.params;
    return (
      <Fragment>
        <div className="container">
          <OrdersTable onSelect={this.onSelect} selectedNumber={orderNumber} typeId={ORDERS_TYPE_ALL} />
          {!!orderNumber &&
          <OrderTabPage orderNumber={orderNumber} onSelect={this.onSelect} />
                    }
        </div>
        <ProposedOrdersModal />
      </Fragment>
    );
  }
}

export default withRouterMatch(withRouter(FindOrdersAllPage));
