import React, { Component } from 'react';
import UploadButton from 'components/UploadButton';
import ModalFilesProgress from 'components/order/ModalFilesProgress';
import FinalPaperFileRow from 'components/order/FinalPaperFileRow';

class TicketFileList extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { uploadFile, writerId } = this.props;
    const fileList = event.target.files;
    for (let i = 0; i < fileList.length; i++) {
      uploadFile(fileList[i], 'file_tickets', writerId);
    }
    event.target.value = '';
  }
  

  render() {
    const {
      fileList,
      removeFile,
      uploadFile,
      disabled,
      buttonName,
      isEdit,
    } = this.props;

    if (!fileList.length && isEdit) {
      return (
        <div className="mt20">
          <UploadButton className="btn btn-light" multiple onChange={this.onChange} disabled={disabled}>{buttonName}</UploadButton>
        </div>
      );
    }

    return (
      <div>
        <ModalFilesProgress fileList={fileList} uploadFile={uploadFile} fileInputRef={this.fileInputRef} removeFile={removeFile} />
        <div className="order-tab-content">
          {isEdit &&
            <div className="row space-between vertical mb20">
              <UploadButton className="btn btn-light" multiple onChange={this.onChange} inputRef={(ref) => { this.fileInputRef = ref; }} disabled={disabled}>{buttonName}</UploadButton>
            </div>
          }
          <div>
            {fileList.map(file =>
              (isEdit ? <FinalPaperFileRow file={file} key={file._id} isModal onRemove={removeFile} hideOwner /> : <FinalPaperFileRow file={file} key={file._id} isModal hideOwner />))}
          </div>
        </div>
      </div>
    );
  }
}
export default TicketFileList;
