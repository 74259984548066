export const TYPE_ORDERS = 'all';
export const FILTER = {
  times: 'times',
  subjects: 'subjects',
  subjectGroups: 'subjectGroups',
  types: 'types',
  showOnline: 'showOnline',
  showReserved: 'showReserved',
};

export const OTHER = 'Other';

export const SHORT_ORDER_TYPES = {
  Writing: [
    'Essay',
    'Coursework',
    'Term paper',
    'Research paper',
    'Book report',
    'Movie review',
    'Case study',
    'Presentations',
    'Online writing task',
    'Creating outline',
  ],
  Proofreading: [
    'Proofreading',
    'Paper formatting',
    'Proofreading & formatting',
  ],
  // 'Problem solving',
  Calculations: ['Problems'],
  Quiz: ['Quiz'],
  'Complex orders': ['Project', 'Complex'],
  Other: ['Other', 'Finding sources'],
};
