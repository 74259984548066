import fileConstants from 'constants/files';

const uploadFetchAmazon = (fileId, file, onProgress, xhr) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('key', fileId);
        formData.append('AWSAccessKeyId', fileConstants.UPLOAD_CREDENTIALS.AWSAccessKeyId);
        formData.append('acl', 'public-read');
        formData.append('policy', fileConstants.UPLOAD_CREDENTIALS.policy);
        formData.append('signature', fileConstants.UPLOAD_CREDENTIALS.signature);
        formData.append('file', file);

        if (!xhr) {
            xhr = new XMLHttpRequest();
        }
        const { host } = fileConstants.UPLOAD_CREDENTIALS;
        xhr.open('POST', host);
        xhr.upload.addEventListener('progress', e => {
            if (e.lengthComputable) {
                const percent = Math.round(100 * e.loaded / e.total);
                onProgress(percent);
            }
        });
        xhr.addEventListener('load', () => resolve(host + fileId));
        xhr.addEventListener('error', () => reject());
        // throw new Error('test');
        xhr.send(formData);
    });
};

export default uploadFetchAmazon;
