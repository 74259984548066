import React from 'react';
import formatTime from 'utils/formatTime';

const WriterAccept = ({ acceptAt }) => (
  <div className="order-tab-actions">
    <div className="row space-between vertical">
      <div className="col-1">
        {!!acceptAt &&
          <div className="order-tab-actions-info">
            <span className="text-success">Order accepted:</span> {formatTime(acceptAt, 'dt')}
          </div>
        }
      </div>
      <div className="ta-right col-1">
        <span className="btn btn-accepted btn-md">Accepted</span>
      </div>
    </div>
  </div>
);

export default WriterAccept;
