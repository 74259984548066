import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MessageStatus } from '@connect/connect-ui-js';

import ReservedCounter from 'components/ReservedCounter';

import { theme } from 'components/order/OrderClientChat/theme';

import { EstimationCounter } from 'components/order/EstimationModal/ViewMode';

const EstimationFooter = ({ msgData }) => {
  const { event: { event_params: { estimate_id: estimateId, order_id: orderId } } } = msgData;

  const orders = useSelector(state => state.orders);

  const order = useMemo(() => Object.values(orders).find(o => o._id === orderId) || {}, [orders.length]);

  const { estimates = [] } = order;

  const estimate = useMemo(() => Object.values(estimates).find(e => e._id === estimateId) || {}, [estimates.length]);

  const { expired_at: expireEstimateTime } = estimate;

  return (
    <>
      <div className="row vertical no-wrap" style={{ lineHeight: `${theme.connectUi.typography.capture1.lineHeight}px` }}>
        <div className="mr3 fsXSm">Expires after</div>
        <ReservedCounter time={expireEstimateTime} classes="fsXSm">
          <EstimationCounter />
        </ReservedCounter>
      </div>
      <MessageStatus data={msgData} isRelative />
    </>
  );
};

export default EstimationFooter;
