import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import InfoVerifyContent from 'components/agreement/InfoVerifyContent';

import getVerifyStatusText from 'utils/getVerifyStatusText';

import classNames from 'utils/classNames';

import {
  financesSetFilterTime,
  financesSetFilterType,
  financesSetFilterStatus,
} from './../store/finances/actions';
import { settingsFetchIfNeeded } from './../store/settings/actions';
import { getFinanceTypes, getFinanceStatuses } from './../store/finances/selectors';

import Dropdown from './Dropdown';
import Select from './Select';
import FinancesModal from './FinancesModal';
import FinancesCancelModal from './FinancesCancelModal';
import FinancesRequestToPassModal from './FinancesRequestToPassModal';

class FinancesTableFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isModalInfoVerifyOpen: false,
      isModalRequestToPassOpen: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleWithdrawal = this.toggleWithdrawal.bind(this);
    this.toggleModalRequestToPassOpenModal = this.toggleModalRequestToPassOpenModal.bind(this);
  }

  componentDidMount() {
    const { loadSettings } = this.props;
    loadSettings();
  }

  toggleModal() {
    this.setState(state => Object.assign({}, state, {
      isModalOpen: !state.isModalOpen,
    }));
  }

  toggleModalRequestToPassOpenModal() {
    this.setState(state => Object.assign({}, state, {
      isModalRequestToPassOpen: !state.isModalRequestToPassOpen,
    }));
  }

  toggleWithdrawal() {
    const { profile } = this.props;
    const { last_veriff_session_object: veriffSession = {}, otp_status: otpStatus } = profile;
    if (otpStatus === 'Not Enrolled') {
      this.toggleModalRequestToPassOpenModal();
      return;
    }

    if (veriffSession.status === 'approved' || veriffSession.status === 'not_required') {
      this.toggleModal();
      return;
    }

    this.setState(state => ({ ...state, isModalInfoVerifyOpen: !state.isModalInfoVerifyOpen }));
  }

  renderButtonWithdrawal() {
    const {
      available,
      pending,
      isLoading,
      execution,
      completed,
      allowWithdrawal,
      pendingWithdrawal,
      profile,
    } = this.props;

    const limitedWithdrawal = profile && profile.ref && profile.ref === 'lr-writers.com' ? 55 : 30;
    const { last_veriff_session_object: veriffSession = {} } = profile;

    if (!allowWithdrawal) {
      return (
        <div className="tooltip tooltip-right">
          <button
            className="btn btn-bright tooltip-trigger"
            type="button"
            disabled
          >
            Request withdrawal
          </button>
          {pendingWithdrawal &&
            <div className="tooltip-content">
              Please wait for your pending withdrawal request to be processed before you can create a new one.
            </div>
          }
        </div>
      );
    }

    if (completed > 0) {
      return (
        <button
          className="btn btn-bright tooltip-trigger"
          type="button"
          disabled={isLoading || (available === 0 && pending === 0 && execution === 0) || veriffSession.status === 'declined'}
          onClick={this.toggleWithdrawal}
        >
          Request withdrawal
        </button>
      );
    }

    if (completed <= 0) {
      return (
        <div className="tooltip tooltip-right">
          <button
            className="btn btn-bright tooltip-trigger"
            type="button"
            disabled={isLoading || ((available - execution) < (limitedWithdrawal * 100) || veriffSession.status === 'declined')}
            onClick={this.toggleWithdrawal}
          >
            Request withdrawal
          </button>
          {(available - execution) < (limitedWithdrawal * 100) &&
            <div className="tooltip-content">
              Unfortunately, you need to have at least {`$${limitedWithdrawal}`} on your available balance to request your 1st withdrawal. You need just a few orders done to earn at least ${(limitedWithdrawal - (available / 100).toFixed(2)).toFixed(2)} to add up to your available balance or simply wait for the available balance to increase if you have enough funds pending.
            </div>
          }
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      className,
      filterTimesLong,
      filterTypes,
      filterStatuses,
      filter,
      setFilterTime,
      setFilterType,
      setFilterStatus,
      available,
      pending,
      isLoading,
      execution,
      translate,
      profile,
    } = this.props;
    const { isModalOpen, isModalInfoVerifyOpen, isModalRequestToPassOpen } = this.state;

    const availableClassName = classNames({ 'text-warning': available <= 0 });
    const { last_veriff_session_object: veriffSession = {} } = profile;

    return (
      <div>
        <div className={className}>
          <div className="row vertical space-between minWidth100">
            <div className="row vertical">
              <div className="col-2">
                <Dropdown text={translate(`timeFilter.${filter.time}`)} closeAlways>
                  <Select className="menu menu-selectable" onChange={setFilterTime} value={filter.time}>
                    {filterTimesLong.map(item =>
                      <li key={item} value={item} className="menu-item">{translate(`timeFilter.${item}`)}</li>
                    )}
                  </Select>
                </Dropdown>
              </div>
              <div className="col-2">
                <Dropdown text={translate(`financeTypeFilter.${filter.type}`)} closeAlways>
                  <Select className="menu menu-selectable" onChange={setFilterType} value={filter.type}>
                    {filterTypes.map(item =>
                      <li key={item} value={item} className="menu-item">{translate(`financeTypeFilter.${item}`)}</li>
                    )}
                  </Select>
                </Dropdown>
              </div>
              <div className="col-2">
                <Dropdown text={translate(`financeStatusFilter.${filter.status}`)} closeAlways>
                  <Select className="menu menu-selectable" onChange={setFilterStatus} value={filter.status}>
                    {filterStatuses.map(item =>
                      <li key={item} value={item} className="menu-item">{translate(`financeStatusFilter.${item}`)}</li>
                    )}
                  </Select>
                </Dropdown>
              </div>
              <div className="col-1" />
              <div className="col-3 balance ta-right">{!isLoading && !!pending &&
                <span>Pending balance: <b>${(pending / 100).toFixed(2)}</b></span>
              }</div>
              <div className="col-3 balance ta-center">{!isLoading &&
                <span><span className="no-wrap">Available balance:</span> <b className={availableClassName}>${(available / 100).toFixed(2)}</b></span>
              }</div>
              <div className="col-2">
                {this.renderButtonWithdrawal()}
              </div>

            </div>
            <div className="as-grey-text">Profile status: {getVerifyStatusText(veriffSession.status)}</div>
          </div>
          {
            execution === 0
              ? <FinancesModal isOpen={isModalOpen} toggleModal={this.toggleModal} />
              : <FinancesCancelModal isOpen={isModalOpen} toggleModal={this.toggleModal} />
          }
          {isModalInfoVerifyOpen && <InfoVerifyContent noBtn={veriffSession.status === 'submitted'} modalName={veriffSession.status === 'submitted' ? 'VerificationWithdrawalInProgress' : 'VerificationWithdrawal'} onCloseModal={this.toggleWithdrawal} />}
          {isModalRequestToPassOpen &&
            <FinancesRequestToPassModal toggleModal={this.toggleModalRequestToPassOpenModal} />
          }
        </div>
        <div className="table-note vertical">
          <i className="icon_info" />
          <p>Note: financial transactions can take up to 24 hours to be processed and appear in the list.</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const translate = getTranslate(state.locale);
  const { filterTimesLong } = state.settings;
  const filterTypes = getFinanceTypes(state);
  const filterStatuses = getFinanceStatuses(state);
  const {
    filter,
    available,
    pending,
    execution,
    completed,
    didInvalidate,
    allow_withdrawal: allowWithdrawal,
    pending_withdrawal: pendingWithdrawal,
  } = state.finances;

  const isLoading = state.finances.isFetching || didInvalidate;
  return {
    filter,
    filterTimesLong,
    filterTypes,
    filterStatuses,
    available,
    pending,
    isLoading,
    execution,
    translate,
    completed,
    profile: state.user,
    allowWithdrawal,
    pendingWithdrawal,
  };
};

const mapDispatchToProps = dispatch => ({
  loadSettings: () => dispatch(settingsFetchIfNeeded()),
  setFilterTime: value => dispatch(financesSetFilterTime(value)),
  setFilterType: value => dispatch(financesSetFilterType(value)),
  setFilterStatus: value => dispatch(financesSetFilterStatus(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancesTableFilter);
