import moment from 'moment-timezone';

import { urls } from './../../config';
import authFetch from './../../utils/authFetch';

import { ordersAddList } from './../orders/actions';

import { completedChatNotifications } from './selectors';

export const ORDERS_COMPLETE_REQUEST = 'ORDERS_COMPLETE_REQUEST';
export const ORDERS_COMPLETE_RECEIVE = 'ORDERS_COMPLETE_RECEIVE';

export const ORDERS_COMPLETE_SET_SORTING = 'ORDERS_COMPLETE_SET_SORTING';
export const ORDERS_COMPLETE_SET_FILTER_TIME = 'ORDERS_COMPLETE_SET_FILTER_TIME';
export const ORDERS_COMPLETE_SET_FILTER_SEARCH = 'ORDERS_COMPLETE_SET_FILTER_SEARCH';
export const ORDERS_COMPLETE_RESET_FILTER = 'ORDERS_COMPLETE_RESET_FILTER';
export const ORDERS_COMPLETE_SET_MESSAGE_FILTER = 'ORDERS_COMPLETE_SET_MESSAGE_FILTER';

const ordersCompleteRequest = () => ({
  type: ORDERS_COMPLETE_REQUEST,
});

export const setCompleteMessageFilter = value => ({
  type: ORDERS_COMPLETE_SET_MESSAGE_FILTER,
  value,
});

const ordersCompleteReceive = (numbers, total, limit, page, unreadOrderMessages = 0) => ({
  type: ORDERS_COMPLETE_RECEIVE,
  numbers,
  total,
  limit,
  page,
  unreadOrderMessages,
});

export const ordersCompleteFetch = (page = 1, sort = 'writer_deadline', filter = '') => (dispatch, getState) => {
  dispatch(ordersCompleteRequest());
  return authFetch(`${urls.orders}?folder=completed&page=${page}&sort=${sort}&${filter}`).then((json) => {
    const {
      results = [], total = null, limit = 25, page: pageFromSer = 1, unread_order_messages: unreadOrderMessages = 0,
    } = json || {};

    const chatNotReadOrders = completedChatNotifications(getState()) || [];
    const chatNotReadOrdersNumbers = chatNotReadOrders.map(o => o.number);
    const chatNotReadOrdersWithDetails = chatNotReadOrders.map(chatOrder => getState().orders[chatOrder.number] || {});

    const __results = results.filter(o => !chatNotReadOrdersNumbers.includes(o.number));

    const numbers = [...__results, ...chatNotReadOrdersWithDetails].map(order => order.number);
    const normalizeOrders = results.reduce((prev, next) => {
      prev = { ...prev, [next.number]: { ...next, didInvalidate: true } };
      return prev;
    }, {});

    dispatch(ordersAddList(normalizeOrders));
    dispatch(ordersCompleteReceive(numbers, total, limit, pageFromSer, unreadOrderMessages));
  });
};

const ordersCompleteShouldFetch = (state) => {
  const { ordersComplete } = state;
  if (ordersComplete.isFetching) {
    return false;
  }
  if (ordersComplete.updatedAt && moment().diff(ordersComplete.updatedAt, 'minutes') > 1) {
    return true;
  }
  return ordersComplete.didInvalidate;
};

export const ordersCompleteFetchIfNeeded = page => (dispatch, getState) => {
  if (ordersCompleteShouldFetch(getState())) {
    return dispatch(ordersCompleteFetch(page));
  }
};

export const ordersCompleteSetSorting = value => ({
  type: ORDERS_COMPLETE_SET_SORTING,
  value,
});

export const ordersCompleteSetFilterTime = value => ({
  type: ORDERS_COMPLETE_SET_FILTER_TIME,
  value,
});

export const ordersCompleteSetFilterSearch = value => ({
  type: ORDERS_COMPLETE_SET_FILTER_SEARCH,
  value,
});

export const ordersCompleteResetFilter = () => ({
  type: ORDERS_COMPLETE_RESET_FILTER,
});
