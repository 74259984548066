const timeValidator = (__hours, __minutes, __sumMaxInMinutes, __sumMinInMinutes, minHoursField = 0, maxHoursField = 23, minMinutesField = 0, maxMinutesField = 59) => {
  const min = (__hours * 60) + __minutes;

  if (min < __sumMinInMinutes) {
    return { minutes: __sumMinInMinutes, hours: minHoursField };
  }
  if (min > __sumMaxInMinutes) {
    return { minutes: minMinutesField, hours: maxHoursField };
  }


  if (__minutes < __sumMinInMinutes && min < __sumMinInMinutes) {
    return { minutes: __sumMinInMinutes, hours: __hours };
  }
  if (__minutes > maxMinutesField) {
    return { minutes: maxMinutesField, hours: __hours };
  }

  return { minutes: __minutes, hours: __hours };
};

export default timeValidator;
