import React, { useState, useEffect } from 'react';
import { UnmountClosed } from 'react-collapse';
import { useDispatch } from 'react-redux';

import { trackValues } from 'lrtracker';


import { urls } from 'config';
import authFetch from 'utils/authFetch';

import Tooltip from 'components/Tooltip';

import { ordersDetailsReceive } from 'store/orders/actions';

import classNames from 'utils/classNames';

import './index.scss';

function __defaultOpenState() {
  const { order, defaultOpenState } = this;
  if (typeof defaultOpenState === 'function') {
    return defaultOpenState(order);
  }

  return defaultOpenState;
}

const StepContainer = ({
  header, StepComponent, order, profile, isChecked,
  defaultOpenState, canBeClosed, canBeReopenAfterSubmit = true,
  stepBackendName, stepName, withSubmit, prevStepValidation,
  trackItem, autoOpenStep, setAutoOpenStep, nextWillOpen,
  onClose, onSubmit,
}) => {
  const [isOpen, toggleOpen] = useState(__defaultOpenState.bind({ order, defaultOpenState }) || false);

  const { _id: orderId } = order;

  const dispatch = useDispatch();

  const onToggle = () => {
    if (!canBeClosed) return;

    toggleOpen(!isOpen);
  };

  const renderCheckStatus = () => {
    if (typeof isChecked === 'function') {
      return isChecked(order, profile);
    }

    return isChecked;
  };

  const renderCanBeReopenAfterSubmit = () => {
    if (renderCheckStatus() && !canBeReopenAfterSubmit) return false;

    return true;
  };

  const onConfirm = () => {
    authFetch(urls.updateOrder(orderId), {
      method: 'POST',
      body: JSON.stringify({
        action: 'set_chat_progress_state',
        [stepBackendName]: true,
      }),
    })
      .then((response) => {
        dispatch(ordersDetailsReceive(response.number, response));
        trackValues('stage', trackItem, { context: { stage: 'ClientTutorChat', order_id: orderId } });
        toggleOpen(false);
        setAutoOpenStep('unfold');
        setTimeout(() => {
          setAutoOpenStep(typeof nextWillOpen === 'function' ? nextWillOpen(order) : nextWillOpen);
        }, 100);
      });
  };

  useEffect(() => {
    if (autoOpenStep === 'unfold') {
      toggleOpen(false);
      return;
    }

    if (stepBackendName === autoOpenStep || header === autoOpenStep) {
      toggleOpen(true);
    }
  }, [autoOpenStep, stepBackendName]);

  return (
    <div className="order-tab-collapse step-container">
      <div className="order-tab-collapse-head row vertical space-between" onClick={onToggle}>
        <div className="row vertical">
          <i className={classNames('step-container__check-icon', { 'step-container__check-icon--checked': renderCheckStatus() })} />
          <h3 className={classNames('step-container__header', { 'step-container__header--uncheck': !renderCheckStatus() })}>{header}</h3>
        </div>
        {StepComponent && renderCanBeReopenAfterSubmit() && <i className={classNames('step-container__arrow', { 'step-container__arrow--active': isOpen, 'step-container__arrow--disabled': !canBeClosed })} />}
      </div>
      {StepComponent && renderCanBeReopenAfterSubmit() && (
      <UnmountClosed isOpened={isOpen}>
        <div className="step-container__item">
          {React.cloneElement(StepComponent, {
            order,
            profile,
            nextWillOpen,
            setAutoOpenStep,
            onClose,
            onSubmit,
          })}
          {withSubmit && (
            <>
              {
                renderCheckStatus() ? (
                  <Tooltip className="step-container__tooltip" content="You’ve already confirmed this step"><button className="btn btn-bright-success" type="button" disabled>{`Confirm ${stepName}`}</button></Tooltip>
                ) : (
                  <button className="btn btn-bright-success" disabled={!prevStepValidation(order)} type="button" onClick={onConfirm}>{`Confirm ${stepName}`}</button>
                )
              }
            </>
          )}
        </div>
      </UnmountClosed>
      )}
    </div>
  );
};

export default StepContainer;
