import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userFetchIfNeeded } from 'store/user/actions';

import { StickyContainer, StickyHead, StickyBody } from 'components/Sticky';
import UpToButton from 'components/UpToButton';
import Icon from 'components/Icon';

const renderProgress = (stages) => {
  return (
    <div className="learninglab__stages">
      {stages.map((s, idx) => {
        if (s.percent_correct === 0) {
          return (
            <div key={idx} className="learninglab__stage learninglab__stage--progress">
              <div className="learninglab__fig">
                <Icon className="svg-icon" iconName="icon-rectangle" />
                <span className="learninglab__fig--text">0%</span>
              </div>
              <span className="learninglab__fig--stage">Stage {idx + 1}</span>
            </div>
          );
        }
        if (!s.percent_correct) {
          return (
            <div key={idx} className="learninglab__stage learninglab__stage--notstart">
              <div className="learninglab__fig">
                <Icon className="svg-icon" iconName="icon-rectangle" />
                <span className="learninglab__fig--text">N/A</span>
              </div>
              <span className="learninglab__fig--stage">Stage {idx + 1}</span>
            </div>
          );
        }
        if ((s.percent_correct * 100) < 75) {
          return (
            <div key={idx} className="learninglab__stage learninglab__stage--progress">
              <div className="learninglab__fig">
                <Icon className="svg-icon" iconName="icon-rectangle" />
                <span className="learninglab__fig--text">{parseInt(s.percent_correct * 100, 0)}%</span>
              </div>
              <span className="learninglab__fig--stage">Stage {idx + 1}</span>
            </div>
          );
        }
        if ((s.percent_correct * 100) >= 75) {
          return (
            <div key={idx} className="learninglab__stage learninglab__stage--completed">
              <div className="learninglab__fig">
                <Icon className="svg-icon" iconName="icon-rectangle" />
                <span className="learninglab__fig--text">{parseInt(s.percent_correct * 100, 0)}%</span>
              </div>
              <span className="learninglab__fig--stage">Stage {idx + 1}</span>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

const renderStatus = (status) => {
  switch (status) {
    case 'Completed':
      return <span className="learninglab__badge learninglab__badge--completed">{status}</span>;

    case 'Not started':
      return <span className="learninglab__badge learninglab__badge--not-started">{status}</span>;

    case 'In progress':
      return <span className="learninglab__badge learninglab__badge--in-progress">{status}</span>;

    default:
      return null;
  }
};

class LearningLab extends Component {
  componentDidMount() {
    const { load } = this.props;
    load();
  }

  renderButton(course) {
    const { teachable } = this.props;
    if (course.status === 'Not started') {
      if (course.enroll_link) {
        return (
          <a href={course.enroll_link} type="button" rel="noopener noreferrer" target="_blank" className="btn btn-bright btn-sm btn-learning" >Enroll</a>
        );
      }
      return <span className="btn btn-bright btn-sm btn-learning disabled">Enroll</span>
    }
    const failedQuiz = course.quiz_list.filter(c => c.percent_correct < 0.75);
    const successQuiz = course.quiz_list.filter(c => c.percent_correct >= 0.75);
    const lastFailedQuiz = failedQuiz.length && failedQuiz[failedQuiz.length - 1];

    if (lastFailedQuiz) {
      if (course.retake_link) {
        return (
          <a href={course.retake_link} type="button" rel="noopener noreferrer" target="_blank" className="btn btn-default btn-sm btn-learning" >Retake</a>
        );
      }
      return <span className="btn btn-default btn-sm btn-learning disabled" >Retake</span>
    }

    if (successQuiz.length === course.quiz_list.length && course.first_lecture_id) {
      if (course.retake_link) {
        return (
          <a href={course.retake_link} type="button" rel="noopener noreferrer" target="_blank" className="btn btn-default btn-sm btn-learning" >Retake</a>
        );
      }
      return <span className="btn btn-default btn-sm btn-learning disabled" >Retake</span>
    }

    if (course.continue_link) {
      return (<a href={course.continue_link} type="button" className="btn btn-default btn-sm btn-learning" rel="noopener noreferrer" target="_blank">Continue</a>);
    }
    return <span className="btn btn-default btn-sm btn-learning disabled" >Continue</span>
  }

  render() {
    const { courses } = this.props;
    return (
      <div className="learninglab">
        <div className="learninglab__intro">
          <h1 className="learninglab__title">Welcome to Livingston Research Learning Lab!</h1>
          <p className="learninglab__desc">As a company, we use all means to promote education among people willing to seize any opportunity on their way and learn something new. <br />
            This Learning Lab contains academic writing courses that will help you grow as a professional.  </p>
          <p className="learninglab__desc">Each course costs $19, but <b>we cover all expenses and offer you to use your unique registration details.</b></p>
          <p className="learninglab__desc">Just click on the “Enroll” button and take any course(s) <span className="success-text">FOR FREE</span>.</p>
          <p className="learninglab__desc">Enhance the knowledge, improve your paper quality, and earn more! <span className="emoji" role="img" aria-label="">📚</span></p>
        </div>

        <div className="table">
          <StickyContainer className="sticky-table light">
            <StickyHead className="sticky-table-head" classNameFixed="sticky-table-head-fixed">
              <div className="table-header row">
                <div className="col-4">List of courses</div>
                <div className="col-2 centered">Status</div>
                <div className="col-6 centered">Progress</div>
                <div className="col-2 centered">Final Score</div>
                <div className="col-2 centered">Performanсe</div>
              </div>
            </StickyHead>

            <StickyBody className="sticky-table-body">
              {courses.map(item => (
                <div key={item.id} className="learninglab__row">
                  <div className="row vertical">
                    <div className="col-4">
                      <h3 className="row__title">{item.course_name}</h3>
                      {item.course_details &&
                        <div className="learninglab__detail">{item.course_details}</div>
                      }
                    </div>
                    <div className="col-2 centered">
                      {item.status ? renderStatus(item.status) : ''}
                    </div>
                    <div className="col-6 centered">
                      {item.quiz_list ? renderProgress(item.quiz_list.slice(0, -1)) : ''}
                    </div>
                    <div className="col-2 centered">
                      {item.quiz_list && (item.quiz_list.slice(-1)[0] || {}).percent_correct >= 0 ? <b>{`${parseInt((item.quiz_list.slice(-1)[0] || {}).percent_correct * 100, 0)}%`}</b> : <span className="learninglab__disabled">N/A</span>}
                    </div>
                    <div className="col-2 centered">
                      {this.renderButton(item)}
                    </div>
                  </div>
                </div>
              ))}
            </StickyBody>
          </StickyContainer>
          <UpToButton />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  teachable: (state.user.teachable) || null,
  courses: (state.user.teachable && state.user.teachable.courses) || [],
  teachableСoupon: state.user.teachable_coupon || null,
  profile: state.user,
  isLoading: state.user.isLoading,
  isFetchingAction: state.user.isFetchingAction,
  isFetching: state.user.isFetchingAction || state.user.fetchingFields.length || state.user.isFetchingSubjects,
});

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(userFetchIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LearningLab);
