import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'hocs';

import isValidProfile from 'utils/isValidProfile';

import ProfileInner from './ProfileInner';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.updateOpen();
  }

  componentDidUpdate() {
    this.updateOpen();
  }

  onClose() {
    const { history } = this.props;
    history.push('/findorders/all');
  }

  openRoute() {
    const { location, history } = this.props;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('profile')) {
      return;
    }
    const searchFromParams = searchParams.toString();
    const newSearch = !searchFromParams ? ('?profile') : `?${searchFromParams}&profile`;
    history.push(location.pathname + newSearch);
  }

  updateOpen() {
    const { hasRouteVisible, showAgreementWizard, cannotClose } = this.props;
    if (showAgreementWizard) {
      return;
    }
    if (!cannotClose && !hasRouteVisible) {
      this.openRoute();
    }
  }

  render() {
    const {
      hasRouteVisible, showAgreementWizard, cannotClose, isValid, profile,
    } = this.props;
    if (!hasRouteVisible || showAgreementWizard) {
      return null;
    }

    return (
      <ProfileInner onClose={this.onClose} cannotClose={!cannotClose} isValid={isValid} />
    );
  }
}

export default withRouter(connect((state, ownProps) => {
  const { user } = state;
  const { location } = ownProps;

  const { cannotClose, isValid } = isValidProfile(user);

  const { showAgreementWizard } = state.user;
  const hasRouteVisible = (new URLSearchParams(location.search)).has('profile');

  return {
    hasRouteVisible,
    showAgreementWizard,
    isValid,
    cannotClose,
    profile: user,
  };
})(Profile));
