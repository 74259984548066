import React from 'react';
import { connect } from 'react-redux';

const SuspiciousAlert = (props) => {
  const { visible } = props;
  if (!visible) {
    return null;
  }
  return (
    <div className="suspicious-alert">
      Your account is under review due to suspicious behavior or poor performance.
      <br />
      You temporarily can&apos;t accept orders or request withdrawals.
      <br />
      For more information you can refer to the Issue Resolution Centre or contact the Care team at <a href="mailto:freelancer.care@liv-research.com">freelancer.care@liv-research.com</a>
    </div>
  );
};

const mapStateToProps = state => ({
  visible: state.user.is_suspicious2,
});

export default connect(mapStateToProps)(SuspiciousAlert);
