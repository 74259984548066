import React from 'react';

import ButtonList from 'components/ButtonList';
import Overview from 'components/profile/Overview';
import classNames from 'utils/classNames';

const IssuePrice = ({
  offerTime,
  onChangeOrderSize,
  allPrice,
  textSizeInHour,
  minutes,
  customSizeHour,
  onChangeOrderHour,
  customSizeMin,
  onChangeOrderMin,
  suggestPrice,
  writerPrice,
  offerPrice,
  allHour,
  allMin,
  onCommentChange,
  sizeComment,
  errorPriceComment,
}) => (
  <div>
    <p className="sub-title">We estimated that you will need <b>{textSizeInHour} hours {minutes || '00'} min</b> to complete this order.</p>
    <p className="sub-title">If you think the task requires more time to be completed, please choose how much extra time you need.</p>

    <ButtonList
      chooseValue={offerTime}
      onChange={onChangeOrderSize}
      list={allPrice}
    />

    <div className="price_value">
      <div>
        <span>+</span>
        <div className="input-container">
          <input
            type="number"
            value={customSizeHour}
            name="customSizeHour"
            onChange={onChangeOrderHour}
            placeholder="00"
          />
          <span>hrs</span>
        </div>
        <span>:</span>
        <div className="input-container">
          <input
            type="number"
            value={customSizeMin}
            name="customSizeMin"
            onChange={onChangeOrderMin}
            placeholder="00"
          />
          <span>min</span>
        </div>
        <span>will result in additional</span>
      </div>
      <span className="suggest_price">${suggestPrice}</span>

    </div>
    <div className="total-info">
      <p>Current price: <span>${writerPrice.toFixed(2)}</span></p>
      <p>New suggested order price: <span>${offerPrice}</span></p>
      <p>This order will require to complete: <span>{allHour}h {allMin}min</span></p>
    </div>
    <p className="note">
      Please note that this suggestion concerns order size only and does not imply any changes in the deadline.
    </p>
    {offerPrice.length > 0 &&
      <div className="issue-comment">
        <Overview
          onChangeFields={onCommentChange}
          value={sizeComment}
          fieldName="sizeComment"
          placeholder="What was estimated incorrectly?..."
          cntSymbols={150}
          className={classNames({ 'issue_errors': errorPriceComment })}
        />
        {errorPriceComment &&
          <p className="field_required">This field is required</p>
        }
      </div>
    }
  </div>
);

export default IssuePrice;
