import React, { Component } from 'react';
import moment from 'moment-timezone';

import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

const startYear = 1950;
const endYear = moment().year();

const yearList = [];
const monthList =
  `January
February
March
April
May
June
July
August
September
October
November
December`.split('\n');
let dayList = [];

for (let i = endYear; i >= startYear; i--) {
  yearList.push(i);
}

const getNumberDayOfMonth = (year, month) => {
  dayList = [];
  const formatMonth = month.length > 2 ? moment().month(month).format('MM') : month;
  const date = `${year}-${formatMonth}`;

  const dayOfList = moment(date, 'YYYY-MM').daysInMonth();
  for (let i = 1; i <= dayOfList; i++) {
    if (i > 0 && i < 10) {
      const newVal = `0${i}`;
      dayList.push(newVal);
    } else {
      dayList.push(i);
    }
  }
};

class DateOfBirthDropdown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      day: null,
      month: null,
      year: null,
      years: null,
      dateBirth: null,
    };

    this.onChangeBirth = this.onChangeBirth.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getDateBirth();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.getDateBirth();
    }
  }

  onChangeBirth() {
    const { onChangeFields } = this.props;
    const { month, year, day } = this.state;

    const formatMonth = moment().month(month).format('MM');
    const dateBirth = `${year}-${formatMonth}-${day}`;
    onChangeFields('birth_day', dateBirth);
    this.dropdownRef && this.dropdownRef.setClose();
  }

  onChange(value, name) {
    const { month, year } = this.state;
    this.setState({ [name]: value });
    if (name !== 'day') {
      this.setState({ day: '' });
    }
    if (name === 'month') {
      getNumberDayOfMonth(year, value);
    }
    if (name === 'year' && month) {
      getNumberDayOfMonth(value, month);
    }
  }

  getDateBirth() {
    const { value } = this.props;

    if (!value) return null;

    let dateBirth = '';
    if (value.length === 10) {
      dateBirth = `${value}T00:00:00.000000Z`;
    } else {
      dateBirth = moment(value).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[000Z]') || null;
    }
    const dateBirthYear = moment(dateBirth).utc().format('YYYY');
    const dateBirthMonth = moment(dateBirth).utc().format('MM');
    const dateBirthDay = moment(dateBirth).utc().format('DD');
    const month = moment(dateBirth).utc().format('MMMM');

    this.setState({
      dateBirth: moment(dateBirth).utc().format('ll'),
      day: dateBirthDay || null,
      month: (dateBirthMonth && month) || null,
      year: dateBirthYear || null,
      years: (dateBirth && moment().diff(dateBirth, 'years', false)) || 0,
      monthStr: moment(value).format('MMMM'),
    });

    return getNumberDayOfMonth(dateBirthYear, dateBirthMonth);
  }

  renderDropdown(type) {
    const { year, month } = this.state;
    let isDisabled = false;
    let positionRight = false;
    let buttonClassName = 'lnk-dropdown';
    let list = '';
    const value = this.state[type];

    switch (type) {
      case 'year':
        buttonClassName += ' lnk-dropdown_year';
        list = yearList;
        positionRight = true;
        break;
      case 'month':
        buttonClassName += ' lnk-dropdown_month';
        list = monthList;
        if (!year) {
          buttonClassName += ' lnk-dropdown_disabled';
          isDisabled = true;
        }
        break;
      case 'day':
        buttonClassName += ' lnk-dropdown_day';
        if (!year || !month) {
          buttonClassName += ' lnk-dropdown_disabled';
          isDisabled = true;
        }
        list = dayList;
        break;
      default:
        buttonClassName = '';
    }

    return (
      <Dropdown buttonClassName={buttonClassName} text={value || type} disabled={isDisabled} closeAlways positionRight={positionRight}>
        {!isDisabled &&
          <Select value={value} name={type} onChange={this.onChange} disabled={isDisabled} className="menu menu-selectable menu-selectable-overflow">
            {list.map((item, idx) =>
              (<li key={idx} name={type} value={item} className="menu-item">
                {item}
                <Icon className="svg-icon" iconName="check-empty" />
              </li>))}
          </Select>
        }
      </Dropdown>
    );
  }

  render() {
    const { onChangeFields } = this.props;
    const {
      dateBirth,
      years,
      month,
      year,
      day,
    } = this.state;

    const text = (dateBirth && `${dateBirth} (${years} years)`) || 'Select';
    const isDisabled = !month || !year || !day;

    const isValid = !!dateBirth;
    const errorClassName = !isValid ? 'text-danger' : null;

    return (
      <div className="profile_birth">
        <span className={errorClassName}>Date of birth (Age):</span>
        {!onChangeFields ?
          <span className="profile-noneditable-field">{dateBirth && `${dateBirth} (${years} years)`}</span> :
          <Dropdown buttonClassName="lnk-dropdown" text={text} ref={(ref) => { this.dropdownRef = ref; }}>
            <div className="row profile_birth_row">
              {this.renderDropdown('day')}
              {this.renderDropdown('month')}
              {this.renderDropdown('year')}
            </div>
            <button disabled={isDisabled} className="btn btn-bright btn-block" type="button" onClick={this.onChangeBirth}>Submit</button>

          </Dropdown>
        }
      </div>
    );
  }
}

export default DateOfBirthDropdown;
