import React, { Component } from 'react';

import { withRouterMatch } from 'hocs';

import OrdersCompleteTable from 'components/OrdersCompleteTable';

import OrderCompleteTabPage from './OrderCompleteTabPage';

const myPath = '/myorders/refunded';

class MyOrdersRefundedPage extends Component {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(orderNumber) {
    const { history, match } = this.props;
    const prevOrderNumber = match.params.orderNumber;
    if (prevOrderNumber === orderNumber) {
      history.push(myPath);
      return;
    }
    history.push(`${myPath}/${orderNumber}`);
  }

  render() {
    const { match } = this.props;
    const { orderNumber } = match.params;
    return (
      <div className="container">
        <OrdersCompleteTable onSelect={this.onSelect} selectedNumber={orderNumber} refundedMode />
        {!!orderNumber &&
          <OrderCompleteTabPage orderNumber={orderNumber} onSelect={this.onSelect} />
        }
      </div>
    );
  }
}

export default withRouterMatch(MyOrdersRefundedPage);
