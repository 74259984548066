import React, { Component } from 'react';
import { connect } from 'react-redux';
// import queryString from 'query-string';
// import { UnmountClosed } from 'react-collapse';

import { helpSetSearch, wsSubscribe, wsUnSubscribe } from './../store/help/actions';
import classNames from './../utils/classNames';
import formatTime from './../utils/formatTime';

import { feedbacksFetch, feedbacksSetFilter, getFeedbacksNextAction } from './../store/feedbacks/actions';
import { ISSUE_TYPE, typeIssue } from '../constants/reportForm';

import Icon from './../components/Icon';
import Emoji from './../components/Emoji';
// import Modal from './../components/Modal';
// import WriterTicketingIssue from './../components/WriterTicketing/WriterTicketingIssue';
import { StickyContainer, StickyHead, StickyBody } from './../components/Sticky';

const getStatus = (status) => {
  switch (status) {
    case 'resolved':
      return 'Resolved';
    case 'cancelled':
      return 'Cancelled';
    default:
      return 'Under review';
  }
};

class OrderFeedbackPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenForm: false,
      openFeedbacks: [],
    };

    this.onOpenForm = this.onOpenForm.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  componentDidMount() {
    const { loadFeedbacks } = this.props;
    loadFeedbacks(1, [], []);
  }

  onOpenForm() {
    this.setState({
      isOpenForm: !this.state.isOpenForm,
    });
  }

  toggleCollapse(value) {
    const { openFeedbacks } = this.state;
    let newOpenFeedbacks = [];
    if (openFeedbacks.some(item => item === value)) {
      newOpenFeedbacks = openFeedbacks.filter(item => item !== value);
    } else {
      newOpenFeedbacks = [...openFeedbacks, ...[value]];
    }
    this.setState({ openFeedbacks: newOpenFeedbacks });
  }

  renderNextButton() {
    const { total, feedbacks, loadNextFeedbacks, sorting, filter, page } = this.props;
    if (!feedbacks.length) return null;

    if (!total || feedbacks.length === total) {
      return null;
    }

    const numPage = page + 1;
    const text = `Load next items (${feedbacks.length} / ${total})`;

    return (
      <button
        className="btn btn-show-next"
        onClick={() => { loadNextFeedbacks(numPage, sorting, filter); }}
      >
        {text}
      </button>
    );
  }
  
  renderSuggestion(feedback) {
    const { openFeedbacks } = this.state;
    const isCollapsed = openFeedbacks.indexOf(feedback._id) > -1;
    const isCancel = feedback.status === 'cancelled';
    let suggestions = '';
    let suggestionsTitle = '';

    switch (ISSUE_TYPE[feedback.tag]) {
      case typeIssue.INSTRUCTIONS_NOT_OK:
      case typeIssue.INSTRUCTIONS:
      case typeIssue.TOO_COMPLICATED:
      case typeIssue.SOURCES:
        suggestions = feedback.comment;
        suggestionsTitle = 'Suggested';
        break;
      case typeIssue.DEADLINE:
      case typeIssue.DEADLINE_TOO_SHORT:
        suggestions = formatTime(feedback.suggested_deadline, 'd');
        suggestionsTitle = 'Suggested deadline';
        break;
      case typeIssue.PRICE:
      case typeIssue.ORDER_SIZE:
        suggestions = `$${feedback.suggested_price}`;
        suggestionsTitle = 'Suggested price';
        break;

      default:
        break;
    }

    return (
      <div>
        <h4 className="suggestion-title">{ISSUE_TYPE[feedback.tag]}</h4>
        <div className={classNames('suggestion-wrap', { active: isCollapsed })}>
          <div className="feedback-suggestion">
            <span>{suggestionsTitle}:</span>
            {suggestions}
          </div>
          {!isCollapsed && suggestions.length > 23 &&
            <a onClick={() => this.toggleCollapse(feedback._id)}>…more</a>
          }
          {isCollapsed && suggestions.length > 23 &&
            <a onClick={() => this.toggleCollapse(feedback._id)}>less</a>
          }
          {feedback.solved_description &&
            <div className="solved-block">
              {!isCancel &&
                <h4 className="suggestion-title success-title">What we solved:</h4>
              }
              {isCancel &&
                <h4 className="suggestion-title danger-title">Why the issue was cancelled:</h4>
              }
              <p>{feedback.solved_description}</p>
            </div>
          }
        </div>
      </div>
    );
  }

  renderFeedbackList() {
    const { feedbacks } = this.props;
    const { openFeedbacks } = this.state;
    return (
      feedbacks.map((f, idx) => {
        const orderNumber = f.order_number || 'NA';
        const isActive = f.status === 'new';
        const isCollapsed = openFeedbacks.indexOf(f._id) > -1;
        const className = classNames('table-row row vertical', f.status.toLowerCase(), { 'active-row': isActive, 'is-collapsed': isCollapsed });
        const status = getStatus(f.status);
        const closeAt = (f.closed_at && formatTime(f.closed_at, 'd')) || null;

        return (
          <div key={idx} data-feedback={f._id} className={className} onClick={() => this.toggleCollapse(f._id)}>
            {!isActive &&
              <div><Icon className={classNames('svg-icon', { active: isCollapsed })} iconName="arrow-right-brd" /></div>
            }
            <div className="col-2">{formatTime(f.created_at, 'd')}</div>
            <div className="col-2">{orderNumber}</div>
            <div className="col-4">
              {this.renderSuggestion(f)}
            </div>
            <div className="col-2"><b className={`status status-${f.status.toLowerCase()}`}>{status}</b></div>
            {/* {isActive &&
              <div className="col-2 row vertical">
                <Icon className="svg-icon empty-clock" iconName="empty-clock" /><span className="date-closed-at">{closeAt && `by ${closeAt}`}</span>
              </div>
            } */}
            <div className="col-2 row vertical">
              {!isActive &&
                <span className="date-closed-at">{closeAt}</span>
              }
            </div>
          </div>
        );
      })
    );
  }

  render() {
    const { feedbacks, isLoading } = this.props;

    return (
      <div className="container-feedback">
        <section className="padding-content">
          <h1 className="title">Your feedbacks</h1>
          <p className="mb20">In this tab you can find all orders where you’ve left some feedback. We try to resolve the issue(s) ASAP and get back to you with this order suggested.</p>

          <div className="table">
            <StickyContainer className="sticky-table">
              <StickyHead className="sticky-table-head" classNameFixed="sticky-table-head-fixed">
                <div className="table-header">
                  <div className="row">
                    <div />
                    <div className="col-2">Create at</div>
                    <div className="col-2">Order number</div>
                    <div className="col-4">Issue name</div>
                    <div className="col-2">Status</div>
                    <div className="col-2">Closed at</div>
                  </div>
                </div>
              </StickyHead>
              <StickyBody className="sticky-table-body">
                {!!feedbacks.length &&
                  this.renderFeedbackList()
                }
                {!feedbacks.length && !isLoading &&
                  <div className="page-not-result">
                    <Emoji id="crySad" />
                    <h1>{'Oops, we don\'t have tickets matching this filter set.'}</h1>
                    <p className="notes">Please try again with other filter configuration.</p>
                  </div>
                }
              </StickyBody>
            </StickyContainer>
          </div>
          {this.renderNextButton()}
        </section>
      </div>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const isLoading = state.feedbacks.isFetching;
  const feedbacks = state.feedbacks.results || [];
  const total = state.feedbacks.total || 0;
  const page = parseInt(state.feedbacks.page || 1);
  const filter = state.feedbacks.filter || null;
  const sorting = state.feedbacks.sorting || null;

  return {
    isLoading,
    feedbacks,
    total,
    filter,
    sorting,
    page,
  };
};

const mapDispatchToProps = dispatch => ({
  clearSearch: () => dispatch(helpSetSearch('')),
  loadNextFeedbacks: (page, sorting, filter) => dispatch(getFeedbacksNextAction(page, sorting, filter)),
  loadFeedbacks: (page, sorting, filter) => dispatch(feedbacksFetch(page, sorting, filter)),
  setFilter: (page, sorting, filter) => dispatch(feedbacksSetFilter(page, sorting, filter)),
  wsSubscribe: payload => dispatch(wsSubscribe(payload)),
  wsUnSubscribe: payload => dispatch(wsUnSubscribe(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderFeedbackPage);
