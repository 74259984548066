import React, { Component } from 'react';
import { UnmountClosed } from 'react-collapse';
import classNames from './../../utils/classNames';

class CollapseItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(state => Object.assign({}, state, {
            isOpen: !state.isOpen
        }));
    }

    render() {
        const { title, children, isError } = this.props;
        const { isOpen } = this.state;
        const arrowClassName = classNames('arrow-down', { 'active': isOpen });
        const className = classNames('person-info-item', { 'is-error': isError });
        return (
            <section className={className}>
                <div onClick={this.toggle}>
                    <h3>{title}</h3>
                    <span className={arrowClassName}></span>
                </div>
                <UnmountClosed isOpened={isOpen}>
                    {children}
                </UnmountClosed>
            </section>
        );
    }
}

export default CollapseItem;