
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'utils/classNames';

import StarRating from 'components/StarRating';
import Overview from 'components/profile/Overview';
import FinalPaperFileRow from 'components/order/FinalPaperFileRow';

import WriterTicketingMessage from './WriterTicketingMessage';

import { UNDER_REVIEW } from 'components/WriterTicketing/constants/common';
import { TAGS_RATING, CLASSNAME_TEAM } from 'components/WriterTicketing/constants/writerTicketing';


const WriterIssueAnswer = (props) => {
  const { dataForm, ticket, otherText, fileList } = props;
  const { onChangeOtherText, onSetRating, onSubmitReview, onTagSelect } = props;

  const { rating, showTags, tags: stateTags = [], isShowOtherText } = dataForm;

  const { info } = ticket || {};
  const { tags: ticketTags = [], mark, other_text: ticketOtherText, team = 'support_team' } = info || {};

  let disabledSubmit = (rating === mark && ticketOtherText === otherText) || !rating;

  const isUnderReview = dataForm.status === UNDER_REVIEW;

  if (showTags) {
    ticketTags.some((ticketTag) => {
      if (!stateTags.includes(ticketTag)) {
        disabledSubmit = false;
        return true;
      }
    });
  }

  return (
    <div>
      <div className="answers-tab">
        {ticket.description &&
          <i className={classNames('operator-avatar', CLASSNAME_TEAM[team])} />
        }
        <div className="operator-answers">
          {ticket.description &&
            <div className="answers-wrap">
              <WriterTicketingMessage text={ticket.description} />
            </div>
          }
          {(ticket.files || []).length > 0 &&
            <div className="file-list">
              {ticket.files.map(file => <FinalPaperFileRow file={file} key={file.id} isModal />)}
            </div>
          }
        </div>
      </div>
      <div className="file-list">
        {fileList.map(file => <FinalPaperFileRow file={file} key={file.id} isModal />)}
      </div>

      {!isUnderReview &&
        <div className="rating-block">
          <h3 className="title-medium">Help us improve by rating your experience</h3>
          <StarRating rate={rating} width={150} isEditing onSetMark={onSetRating} />
        </div>
      }
      {showTags &&
        <div className="tags-block">
          <h3 className="title-medium">What went wrong?</h3>
          <ul className="tags-list">
            {(TAGS_RATING).map(tagRating => <li key={tagRating} value={tagRating} className={`tag-item ${stateTags.indexOf(tagRating) >= 0 ? 'active' : ''}`} onClick={() => onTagSelect(tagRating)}>{tagRating}</li>)}
          </ul>
        </div>
      }
      {isShowOtherText &&
        <Overview
          onChangeFields={onChangeOtherText}
          value={otherText}
          fieldName="other_text"
          className="textarea-tags"
          placeholder="Please describe what went wrong"
          hideCount
        />
      }

      {ticket && !isUnderReview &&
        <div className="modal-footer">
          <div className="footer-content">
            {!rating &&
              <span className="notice">Please rate your experience to submit review</span>
            }
            <button type="button" disabled={disabledSubmit} className="btn btn-bright btn-xl" onClick={onSubmitReview}>Submit review</button>
          </div>
        </div>
      }
    </div>
  );
};

export default WriterIssueAnswer;
