import { ORDERS_HIDE_IN_LISTS } from './../orders/actions';

import {
  ADD_TOASTED_NOTIFICATIONS,
  REMOVE_TOASTED_NOTIFICATIONS,
  CLEAR_NEW_ORDERS_NOTIFICATIONS,
  ADD_NEW_NOT_VIEWED_ORDER_NOTIFICATIONS,
  ADD_NEW_TAKEN_ORDER_NOTIFICATIONS,
  REMOVE_TAKEN_ORDER_NOTIFICATIONS,
  REMOVE_SINGLE_NEW_ORDER,
} from './actions';


const ordersNotifications = (state = {
  toasted: [],
  notViewedFindOrders: [],
  takenOrders: [],
}, action) => {
  switch (action.type) {
    case ADD_TOASTED_NOTIFICATIONS:
      return Object.assign({}, state, {
        toasted: [...new Set([...state.toasted, ...action.payload])],
      });
    case ADD_NEW_NOT_VIEWED_ORDER_NOTIFICATIONS:
      return Object.assign({}, state, {
        notViewedFindOrders: [...new Set([...action.payload, ...state.notViewedFindOrders])],
      });
    case ADD_NEW_TAKEN_ORDER_NOTIFICATIONS:
      return Object.assign({}, state, {
        takenOrders: [...new Set([...state.takenOrders, ...action.payload])],
      });
    case REMOVE_TOASTED_NOTIFICATIONS:
      return Object.assign({}, state, {
        toasted: state.toasted.filter(notification => notification !== action.orderNumber),
      });
    case REMOVE_TAKEN_ORDER_NOTIFICATIONS:
      return Object.assign({}, state, {
        takenOrders: state.takenOrders.filter(notification => notification !== action.id),
        notViewedFindOrders: state.notViewedFindOrders.filter(notification => notification !== action.id),
      });
    case ORDERS_HIDE_IN_LISTS:
    case REMOVE_SINGLE_NEW_ORDER:
      return Object.assign({}, state, {
        notViewedFindOrders: state.notViewedFindOrders.filter(notification => notification !== action.number),
        takenOrders: state.takenOrders.filter(notification => notification !== action.id),
      });
    case CLEAR_NEW_ORDERS_NOTIFICATIONS:
      return Object.assign({}, state, {
        notViewedFindOrders: [],
      });
    default:
      return state;
  }
};

export default ordersNotifications;
