import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { urls } from 'config';

import authFetch from 'utils/authFetch';

import { userReceive } from 'store/user/actions';
import { getProfile } from 'store/user/selectors';

import { closeVerifyModal } from 'store/verifyModal/actions';
import { getVerifyData } from 'store/verifyModal/selectors';

import Modal from 'components/Modal';
import Icon from 'components/Icon';

import './index.scss';

const getFirstName = (name = '') => {
  const split_names = name.split(' ');

  if (split_names.length === 1) {
    return split_names[0];
  }

  return split_names.slice(0, -1).join(' ');
};
const getLastName = (name = '') => {
  const split_names = name.split(' ');

  if (split_names.length === 1) {
    return '';
  }

  return split_names.slice(-1).join(' ') || '';
};

const VerifyForm = () => {
  const profile = useSelector(getProfile);
  const { isOpen, verifyStatusHandler } = useSelector(getVerifyData);

  const [firstName, setFirstName] = useState(getFirstName(profile.name));
  const [lastName, setLastName] = useState(getLastName(profile.name));
  const [isLoading, toggleLoading] = useState(false);
  const [isVerifyError, toggleVerifyError] = useState(false);
  const [isVerifyStatusLoading, toggleVerifyStatusLoading] = useState(true);

  const isInValidForm = useMemo(() => firstName.length === 0 || lastName.length === 0 || isLoading || isVerifyError, [firstName, lastName, isLoading]);

  const dispatch = useDispatch();

  const { last_veriff_session_object: veriffSession = {} } = profile;
  const { resubmissions_count: resubmissionsCount } = veriffSession;

  const onFirstNameChange = (e) => {
    const { target } = e;
    setFirstName(target.value.trim());
  };

  const onLastNameChange = (e) => {
    const { target } = e;
    setLastName(target.value.trim());
  };

  const toggleOpenModal = () => {
    dispatch(closeVerifyModal());
  };

  const startVerifyHandler = () => {
    toggleLoading(true);
    const data = {
      person: {
        firstName,
        lastName,
      },
    };

    authFetch(urls.verify, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.url) {
          window.open(response.url, '_blank').focus();
          dispatch(closeVerifyModal());
        } else {
          toggleVerifyError(true);
        }
      })
      .catch((error) => {
        console.error(error);
        toggleVerifyError(true);
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  const toggleVerifyStatusHandler = (status) => {
    if (verifyStatusHandler) {
      verifyStatusHandler(status);
    }
  };

  useEffect(() => {
    if (!isOpen) return;

    if (veriffSession.active_status === 'declined' && veriffSession.crm_status === 'Resubmission') {
      toggleVerifyStatusLoading(false);
      toggleVerifyStatusHandler(false);

      return;
    }

    if (veriffSession.status === 'resubmission_requested' && veriffSession.url) {
      window.open(veriffSession.url, '_blank').focus();
      dispatch(closeVerifyModal());
      toggleVerifyStatusHandler(false);
      return;
    }

    toggleVerifyStatusHandler(true);

    authFetch(urls.verify)
      .then((response) => {
        if (!response || !response.status) {
          toggleVerifyStatusLoading(false);
          return;
        }

        dispatch(userReceive({
          ...profile,
          last_veriff_session_object: response,
        }));

        if ((response.status === 'created' || response.status === 'started') && response.url) {
          window.open(response.url, '_blank').focus();
          dispatch(closeVerifyModal());
          return;
        }

        if (response.status === 'submitted') {
          dispatch(closeVerifyModal());
          return;
        }

        toggleVerifyStatusLoading(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        toggleVerifyStatusHandler(false);
      });
  }, [isOpen]);

  if (!isOpen || isVerifyStatusLoading) return null;

  return (
    <Modal className="verify-form-container" isUpper>
      <button disabled={isLoading} className="btn btn-close modal-close" type="button" onClick={toggleOpenModal}><Icon className="svg-icon" iconName="close" /></button>
      <h2 className="verify-form-container__header">Please fill verification form</h2>
      <form>
        <div className="verify-form-container-form-group">
          <label htmlFor="VF_first_name" className="verify-form-container-form-group__label">First Name</label>
          <input placeholder="First name" id="VF_first_name" className="verify-form-container-form-group__input input-text" value={firstName} onChange={onFirstNameChange} />
        </div>
        <div className="verify-form-container-form-group">
          <label htmlFor="VF_last_name" className="verify-form-container-form-group__label">Last Name</label>
          <input placeholder="Last name" id="VF_last_name" className="verify-form-container-form-group__input input-text" value={lastName} onChange={onLastNameChange} />
        </div>
      </form>
      {isVerifyError && <p className="verify-form-container__description verify-form-container__description--error">Error while verification, please try again later</p>}
      <div className="row flex-end">
        <button
          className="btn btn-bright mt8 verify-form-container__submit"
          onClick={startVerifyHandler}
          disabled={isInValidForm}
        >
          {isLoading ? 'Loading ...' : 'Start Verification' }
        </button>
      </div>
      <p className="verify-form-container__description">The identity verification process will be completed via an<br /> independent third-party software tool by <a title="Veriff" href="https://www.veriff.com/" target="_blank" rel="noreferrer">Veriff</a></p>
      {resubmissionsCount && resubmissionsCount >= 10 && (
        <div className="row column_gap_10 mt8">
          <Icon className="svg-icon" iconName="close-fill-red" styles={{ width: '16px', height: '16px' }} />
          <p className="verify-form-container__resubmissions_count_warning fsMd">
            You've used all your verification resubmission attempts.
            We've reset your status and granted you 10 more attempts to resubmit,
            but please understand this is a one-time exception.
            If you use all of them without successfully verifying your identity,
            we unfortunately will have to disable your freelance account.</p>
        </div>
      )}
    </Modal>
  );
};
export default VerifyForm;
