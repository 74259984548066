import React, { useState, useEffect } from 'react';

import Dropdown from 'components/Dropdown';
import Select from 'components/Select';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

import classNames from 'utils/classNames';

import bank from 'img/icons/bank.svg';

import '../styles/bankInfoSection.scss';

const BankIntInfo = ({ bankInfo, setBankInfo, countryList = [] }) => {
  const [bankAccount, setBankAccount] = useState(bankInfo.interm_bank_account);
  const [bankSWIFT, setBankSWIFT] = useState(bankInfo.interm_bank_swift);
  const [bankName, setBankName] = useState(bankInfo.interm_bank_name);
  const [bankStreetAddress, setBankStreetAddress] = useState(bankInfo.interm_bank_address);

  const onBankAccountChange = (e) => {
    const { target } = e;
    setBankAccount(target.value);
  };

  const onBankSWIFTChange = (e) => {
    const { target } = e;
    setBankSWIFT(target.value);
  };

  const onBankNameChange = (e) => {
    const { target } = e;
    setBankName(target.value);
  };

  const onBankStreetAddressChange = (e) => {
    const { target } = e;
    setBankStreetAddress(target.value);
  };

  const changeParentProps = (key, value) => {
    if (bankInfo[key] === value) return;

    let dopOptions = {};
    if (key === 'interm_bank_account') {
      dopOptions = {
        isIntermBankAccountValid: true,
      };
    }
    if (key === 'interm_bank_swift') {
      dopOptions = {
        isIntermBankSwiftValid: true,
      };
    }
    setBankInfo({
      ...bankInfo, ...{ [key]: value, ...dopOptions },
    });
  };

  useEffect(() => {
    setBankAccount(bankInfo.interm_bank_account);
    setBankSWIFT(bankInfo.interm_bank_swift);
    setBankName(bankInfo.interm_bank_name);
    setBankStreetAddress(bankInfo.interm_bank_address);
  }, [bankInfo]);

  return (
    <div className="bank-info-section">
      <div className="sections-title">
        <img src={bank} alt="bank" className="sections-title__icon" />
        <div className="sections-title__text mr8">Intermediary Bank</div>
        <Tooltip className="multi-string" content="This information is provided on the official site of your bank">
          <Icon className="svg-icon" iconName="question" />
        </Tooltip>
      </div>
      <div className="wire-transfer-form-form-group-column">
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
          <label htmlFor="IntIBAN" className="wire-transfer-form-form-group__label">Bank Account (or IBAN)</label>
          <input id="IntIBAN" className={classNames('wire-transfer-form-form-group__input input-text', { 'is-error-only-border': !bankInfo.isIntermBankAccountValid })} value={bankAccount} onChange={onBankAccountChange} onBlur={() => changeParentProps('interm_bank_account', bankAccount)} />
        </div>
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
          <label htmlFor="IntSWIFT" className="wire-transfer-form-form-group__label">Bank SWIFT</label>
          <input id="IntSWIFT" className={classNames('wire-transfer-form-form-group__input input-text', { 'is-error-only-border': !bankInfo.isIntermBankSwiftValid })} value={bankSWIFT} onChange={onBankSWIFTChange} onBlur={() => changeParentProps('interm_bank_swift', bankSWIFT)} />
        </div>
      </div>
      <div className="wire-transfer-form-form-group-column">
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
          <label htmlFor="IntBankCountry" className="wire-transfer-form-form-group__label">Bank Country</label>
          <Dropdown closeAlways id="IntBankCountry" up text={bankInfo.interm_bank_country} extraDropdownClass="wire-transfer-form-form-group__dropdown">
            <Select className="menu menu-selectable menu-selectable-overflow" value={bankInfo.interm_bank_country} onChange={value => changeParentProps('interm_bank_country', value)}>
              {countryList.map(country => <li className="menu-item wire-transfer-form-form-group__dropdown__item" key={country} value={country}>{country}</li>)}
            </Select>
          </Dropdown>
        </div>
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
          <label htmlFor="IntBankName" className="wire-transfer-form-form-group__label">Bank Name</label>
          <input id="IntBankName" className="wire-transfer-form-form-group__input input-text" value={bankName} onChange={onBankNameChange} onBlur={() => changeParentProps('interm_bank_name', bankName)} />
        </div>
      </div>
      <div className="wire-transfer-form-form-group">
        <label htmlFor="IntStreetAddress" className="wire-transfer-form-form-group__label">Bank Street Address</label>
        <input id="IntStreetAddress" className="wire-transfer-form-form-group__input input-text" value={bankStreetAddress} onChange={onBankStreetAddressChange} onBlur={() => changeParentProps('interm_bank_address', bankStreetAddress)} />
      </div>
    </div>
  );
};

export default BankIntInfo;
