import React from 'react';

const textWithoutWarnings = (
  <div>
    <h3>You're doing not that great!</h3>
    Write more orders, receive good Client scores, and get to a higher group to earn more!
  </div>
);

const getSimpleGroupTooltip = (qcFullGroup, cntActiveWarnings) => {
  let tooltipContent = '';

  switch (qcFullGroup) {
    case 'A+-pro':
      tooltipContent = (
        <div>
          <h3>You're doing just great!</h3> You get <b className="success">+35%</b> to the base order price. <br /> Keep going!
        </div>
      );
      break;

    case 'A+-standard':
      if (!cntActiveWarnings) {
        tooltipContent = textWithoutWarnings;
      } else {
        tooltipContent = (
          <div>
            <h3>You're doing just great!</h3> You have {cntActiveWarnings} active warnings. <br /> Please fix those issues to keep getting +35% to the base order price!
          </div>
        );
      }
      break;

    case 'A+-outsider':
      if (!cntActiveWarnings) {
        tooltipContent = textWithoutWarnings;
      } else {
        tooltipContent = (
          <div>
            <h3>You're doing not good at all!</h3> You have {cntActiveWarnings} active warnings. <br /> Please fix those issues ASAP to keep getting +35% to the base order price!
          </div>
        );
      }
      break;
    case 'A-pro':
      tooltipContent = (
        <div>
          <h3>You're doing just great!</h3> You get <b className="success">+25%</b> to the base order price. <br /> Keep going!
        </div>
      );
      break;

    case 'A-standard':
      tooltipContent = (
        <div>
          <h3>You're doing not that great!</h3>
          <span className="active-warnings">You have {cntActiveWarnings} active warnings.</span> Please fix those issues to keep getting <b className="success">+25%</b> to the base order price!
        </div>
      );
      break;

    case 'A-outsider':
      tooltipContent = (
        <div>
          <h3>You're doing not good at all!</h3>
          <span className="active-warnings">You have {cntActiveWarnings} active warnings.</span> Please fix those issues ASAP to keep getting <b className="success">+25%</b> to the base order price!
        </div>
      );
      break;

    case 'B-pro':
      tooltipContent = (
        <div>
          <h3>You're doing not that great!</h3>
          Keep on doing your best, avoid <span className="active-warnings">warnings ({cntActiveWarnings} active at the moment)</span> and become an A+ or A-level freelancer to start earning more!
        </div>
      );
      break;

    case 'B-standard':
      tooltipContent = (
        <div>
          <h3>You're doing not that great!</h3>
          Keep on doing your best, avoid <span className="active-warnings">warnings ({cntActiveWarnings} active at the moment)</span> and become an A+ or A-level freelancer to start earning more!
        </div>
      );
      break;

    case 'B-outsider':
      tooltipContent = (
        <div>
           <h3>You're doing not that great!</h3>
          Keep on doing your best, avoid <span className="active-warnings">warnings ({cntActiveWarnings} active at the moment)</span> and become an A+ or A-level freelancer to start earning more!
        </div>
      );
      break;

    case 'C-standard':
      if (!cntActiveWarnings) {
        tooltipContent = textWithoutWarnings;
      } else {
        tooltipContent = (
          <div>
            <h3>You're doing not that great!</h3>
            Keep on doing your best, avoid <span className="active-warnings">warnings ({cntActiveWarnings} active at the moment)</span> and become an A+ or A-level freelancer to start earning more!
          </div>
        );
      }
      break;

    case 'C-outsider':
      if (!cntActiveWarnings) {
        tooltipContent = textWithoutWarnings;
      } else {
        tooltipContent = (
          <div>
            <h3>You're doing not good at all!</h3>
            <span className="active-warnings">You have {cntActiveWarnings} active warnings.</span> Please fix those issues ASAP or we will be forced to terminate your account!
          </div>
        );
      }
      break;

    case 'D-standard':
      if (!cntActiveWarnings) {
        tooltipContent = textWithoutWarnings;
      } else {
        tooltipContent = (
          <div>
            <h3>You're doing not that great!</h3>
            Keep on doing your best, avoid <span className="active-warnings">warnings ({cntActiveWarnings} active at the moment)</span> and become an A+ or A-level freelancer to start earning more!
          </div>
        );
      }
      break;

    case 'D-outsider':
      if (!cntActiveWarnings) {
        tooltipContent = textWithoutWarnings;
      } else {
        tooltipContent = (
          <div>
            <h3>You're doing not good at all!</h3>
            <span className="active-warnings">You have {cntActiveWarnings} active warnings.</span> Please fix those issues ASAP or we will be forced to terminate your account!
          </div>
        );
      }
      break;

    case 'F':
      tooltipContent = (
        <div>
          <h3>Clients are not happy with your work at all!</h3>
          Your account is being reviewed for termination. Please contact your Care Manager if you have any questions/concerns.
        </div>
      );
      break;

    default:
      break;
  }

  return tooltipContent;
};

export {
  textWithoutWarnings,
  getSimpleGroupTooltip,
};
