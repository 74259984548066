import React, { Component } from 'react';
import { UnmountClosed } from 'react-collapse';

import {
  STATUS_OPEN,
  STATUS_PROGRESS,
  STATUS_REVISION,
} from 'store/orders/actions';

import Icon from 'components/Icon';

const renderLoginBlock = data => (
  <div className="order-tab-content" key={data.id}>
    <div className="row">
      <div className="col-1">Title</div>
      <div className="col-1">{data.title}</div>
    </div>
    <div className="row">
      <div className="col-1">Link</div>
      <div className="col-1">{data.link}</div>
    </div>
    <div className="row">
      <div className="col-1">Login</div>
      <div className="col-1">{data.login}</div>
    </div>
    <div className="row">
      <div className="col-1">Password</div>
      <div className="col-1">{data.password}</div>
    </div>
  </div>
);

class LoginDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }


  toggle() {
    const { status } = this.props;
    if (status !== STATUS_OPEN) {
      this.setState(state => Object.assign({}, state, {
        isOpen: !state.isOpen,
      }));
    }
  }

  render() {
    const { order, onResize, status } = this.props;
    const { isOpen } = this.state;

    if (!order.has_login_details || (status !== STATUS_PROGRESS && status !== STATUS_OPEN && status !== STATUS_REVISION)) {
      return null;
    }

    const LoginInfo = (order && order.login_details) || [];
    let text = 'Login details will be available upon order acceptance';

    if (status === STATUS_PROGRESS || status === STATUS_REVISION) {
      text = 'Login details';
    }

    return (
      <div className="order-tab-collapse">
        <div className="order-tab-collapse-head" onClick={this.toggle}>
          <Icon className="svg-icon" iconName="icon-block" />
          <span className="order-tab-head-name">{text}</span>
          {isOpen
            ? <span className="arrow-down active"></span>
            : <span className="arrow-down"></span>
          }
        </div>
        <UnmountClosed isOpened={isOpen} className="order-tab-collapse-body order-client-info" onRender={onResize}>
          {LoginInfo.map(login => renderLoginBlock(login))}
        </UnmountClosed>
      </div>
    );
  }
}

export default LoginDetail;
