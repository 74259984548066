import moment from 'moment-timezone';

import { urls } from './../../config';
import authFetch from './../../utils/authFetch';

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
export const SETTINGS_RECEIVE = 'SETTINGS_RECEIVE';
export const SWITCH_DND_MODE = 'SWITCH_DND_MODE';

const settingsRequest = () => ({
  type: SETTINGS_REQUEST,
});

const settingsReceive = (orderSubjects, orderTypes, dataListOfFixes, orderSubjectGroups, countryList, diffBetweenServerClientTime) => ({
  type: SETTINGS_RECEIVE,
  orderSubjects,
  orderTypes,
  dataListOfFixes,
  orderSubjectGroups,
  countryList,
  diffBetweenServerClientTime,
});

const settingsFetch = () => (dispatch) => {
  dispatch(settingsRequest());
  return authFetch(urls.settings)
    .then((json) => {
      const orderSubjects = json.orders_subjects;
      const orderTypes = json.orders_types;
      const orderSubjectGroups = json.subjects_group;
      const countryList = json.country_list;
      const diffBetweenServerClientTime = moment(json.current_date).diff(moment().utc());
      Object.values(orderSubjectGroups).forEach(subjectGroup => subjectGroup.sort());
      // orderSubjectGroups.Other = [];
      const { dataListOfFixes } = json;
      dispatch(settingsReceive(orderSubjects, orderTypes, dataListOfFixes, orderSubjectGroups, countryList, diffBetweenServerClientTime));
      return Promise.resolve();
    })
    .catch(er => console.error(er));
};

const settingsShouldFetch = (state) => {
  const { settings } = state;
  if (settings.isFetching) {
    return false;
  }
  return settings.didInvalidate;
};

export const settingsFetchIfNeeded = () => (dispatch, getState) => {
  if (settingsShouldFetch(getState())) {
    return dispatch(settingsFetch());
  }
  return Promise.resolve();
};

export const switchDNDmode = () => ({
  type: SWITCH_DND_MODE,
});
