import React, { Component } from 'react';
import { UnmountClosed } from 'react-collapse';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import modalList from 'constants/modalList';

import { STATUS_PROGRESS } from 'store/orders/actions';
import { setPlugin } from 'store/plugins/actions';
import { addModalComponent } from 'store/modals/actions';

import Icon from 'components/Icon';
import Emoji from 'components/Emoji';
import FilesPreview from 'components/FilesPreview';

import utils from 'utils';
import classNames from 'utils/classNames';
import getFilePlagLink from 'utils/getFilePlagLink';
import getFileGroupPlagStatus from 'utils/getFileGroupPlagStatus';
import { onDownloadAll as onDownloadAllHandler } from 'utils/downloadFile';

import DeselectControls from 'components/order/OrderFileList/DeselectControls';

import { REPORT_SUB_TYPE, REPORT_TYPE } from 'components/WriterTicketing/constants/writerTicketing';

import UploadFilesModal from 'components/order/UploadFilesModal';
import FinalPaperFileRow from 'components/order/FinalPaperFileRow';

import OrderCompleteButton from './OrderCompleteButton';

class OrderFinalPaperList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      selectedFiles: [],
      downloadFetching: false,
      plagStatus: null,
    };

    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.onDownloadAll = this.onDownloadAll.bind(this);
    this.clearSelect = this.clearSelect.bind(this);
    this.openFilePreviewHandler = this.openFilePreviewHandler.bind(this);
    this.fileRowClick = this.fileRowClick.bind(this);
    this.renderUploadIssueBanner = this.renderUploadIssueBanner.bind(this);
  }

  componentDidMount() {
    const { fileList: fileList = [], order = {} } = this.props;
    const { no_sensitive: noSensitive = false } = order;
    const plagStatus = getFileGroupPlagStatus(fileList);
    this.setState({
      plagStatus,
    });
    if (noSensitive) {
      this.setState({
        isOpen: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { fileList: prevFileList = [] } = prevProps;
    const { fileList: fileList = [] } = this.props;

    if (fileList.length && (fileList.length !== prevFileList.length || JSON.stringify(fileList) !== JSON.stringify(prevFileList))) {
      const plagStatus = getFileGroupPlagStatus(fileList);
      this.setState({
        plagStatus,
      });
    }
  }

  onDownloadAll(e) {
    if (e) e.stopPropagation();

    const { fileList, fileWebhook, orderNumber } = this.props;
    const { selectedFiles } = this.state;
    this.setState({ downloadFetching: true });
    onDownloadAllHandler({
      fileList,
      fileWebhook,
      orderNumber,
      selectedFiles,
    })
      .finally(() => {
        this.setState({ downloadFetching: false });
        this.clearSelect();
      });
  }


  onChange() {
    const { addModal, order, uploadOrderFiles } = this.props;
    const dataModal = modalList.UploadFiles;
    const text = utils.isOrderWithPartialDeliveryTag(order) ?
      "Please upload completed final files required in the order. You won't be able to delete or edit uploaded and submitted files, so pay close attention." :
      "Please upload ALL final files required in the order. You won't be able to upload any additional files after the order is submitted. If uploaded files are irrelevant or incomplete, disciplinary action will be taken.";

    addModal(<UploadFilesModal
      order={order}
      group="paper"
      tags={['final']}
      header="Upload final files"
      text={text}
      uploadOrderFiles={uploadOrderFiles}
    />, dataModal);
  }

  fileRowClick(e, file) {
    e.preventDefault();
    e.stopPropagation();

    this.openFilePreviewHandler(e, file._id);
  }

  clearSelect(e) {
    if (e) e.stopPropagation();

    this.setState({ selectedFiles: [] });
  }

  selectFile(e, id) {
    e.stopPropagation();
    const { selectedFiles } = this.state;

    if (selectedFiles.includes(id)) {
      this.setState({ selectedFiles: selectedFiles.filter(file => file !== id) });
      return;
    }

    this.setState({ selectedFiles: [...selectedFiles, id] });
  }

  toggle() {
    this.setState(state => Object.assign({}, state, {
      isOpen: !state.isOpen,
    }));
  }

  openFilePreviewHandler(e, fileID) {
    const {
      openFilePreview, fileList, orderNumber, fileWebhook, addModalMobile,
    } = this.props;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const sortedList = fileList
      .filter(file => !file.deleted)
      .map(file => ({ ...file, id: file._id }));
    const activeIndex = sortedList.findIndex(file => file.id === fileID);
    const isMobile = utils.detectMob();

    if (isMobile) {
      addModalMobile(<FilesPreview docs={sortedList} activeIndex={activeIndex} />, {
        overFlowHidden: true,
      });
      return;
    }

    openFilePreview({
      plugin: 'FilePreviewPlugin',
      data: {
        docs: sortedList,
        orderNumber,
        activeIndex,
        fileWebhook,
      },
    });
  }

  renderUploadIssueBanner() {
    const {
      order, status, fileList, onOpenIssueForm,
    } = this.props;
    const { completed = false } = order || {};

    const onOpenIssueFormHandler = (e) => {
      onOpenIssueForm(e, {
        typeIssue: REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED,
        subTypeIssue: REPORT_SUB_TYPE.ISSUE_WITH_FINAL_FILES,
      });
    };

    if (status !== STATUS_PROGRESS || !fileList.length || completed) return null;

    return (
      <div className="bg-context bg-info mb15">If you uploaded incorrect or incomplete set of final files, reach out to Support Team and report <a onClick={onOpenIssueFormHandler}><strong>Issue with final files</strong></a> ASAP.</div>
    );
  }

  renderPlagReport() {
    const {
      status,
      fileList,
    } = this.props;

    const { plagStatus } = this.state;

    if (status !== STATUS_PROGRESS || !fileList.length) return null;

    switch (plagStatus) {
      case 'plagiate':
        return (
          <div className="bg-context bg-danger mb15">Plagiarism found. Check plag report(s) for instructions.</div>
        );
      case 'notfound':
        return (
          <div className="bg-context bg-success mb15">
            <Emoji id="like" /> Good job! No plagiarism found. Your order will automatically be completed.
          </div>
        );
      case 'onCheck':
        return (
          <div className="bg-context bg-warning mb15">
            You have uploaded Final paper file(s), please wait for plagiarism check. It could take from 10 min to 2 hours. After plagiarism check you’ll get message about result and further steps.
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      fileList,
      onResize,
      status,
      orderNumber,
      hasParallelOrder,
      plagFileList,
      order,
    } = this.props;
    const {
      isOpen, selectedFiles, downloadFetching,
    } = this.state;

    const {
      no_sensitive: noSensitive = false, jabber_node: jabberNode, tags = [],
    } = order;
    const isMobile = utils.detectMob();

    if (!fileList.length && !noSensitive) {
      if (status !== STATUS_PROGRESS) return null;

      if (isMobile) {
        return (
          <div className="order-report-issue">
            <Icon styles={{ width: '14px', height: '14px' }} iconName="instructions-under-review" />
            <p>Unfortunately, uploading final files is possible only from desktop version of the platform.</p>
          </div>
        );
      }

      return (
        <div className="ta-right mt20">
          {
            !jabberNode && <button className="btn btn-bright" onClick={this.onChange} disabled={hasParallelOrder}>Upload final files</button>
          }
          {' '}
          <OrderCompleteButton orderNumber={orderNumber} />
        </div>
      );
    }
    const sortedList = fileList.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));

    const filesStr = `${sortedList.length} ${sortedList.length === 1 ? 'file' : 'files'}`;

    return (
      <div className="order-tab-collapse">
        <div className={classNames('order-tab-collapse-head row space-between', { 'disabled-no-cursor': noSensitive })} onClick={!noSensitive && this.toggle}>
          <div>
            <Icon className="svg-icon" iconName="clip" />
            <span className="order-tab-head-name">Final papers ({filesStr})</span>
          </div>
          {isOpen
              ? <span className="arrow-down active" />
              : <span className="arrow-down" />
            }
          {
              isOpen && !!selectedFiles.length && <DeselectControls selectedFiles={selectedFiles} onDownloadAll={this.onDownloadAll} downloadFetching={downloadFetching} clearSelect={this.clearSelect} />
            }
        </div>
        <UnmountClosed isOpened={isOpen} className="order-tab-collapse-body" onRender={onResize}>
          <div className="order-tab-content">
            {status === STATUS_PROGRESS &&
              <div className="row space-between vertical mb15">
                <div>{filesStr} uploaded</div>
                <div>
                  {(!tags.includes('submit_order_files') || utils.isOrderWithPartialDeliveryTag(order)) && !isMobile && <button className="btn btn-bright" onClick={this.onChange} disabled={hasParallelOrder}>Upload more</button>}
                  <OrderCompleteButton orderNumber={orderNumber} />
                </div>
              </div>
            }
            {this.renderUploadIssueBanner()}
            {this.renderPlagReport()}
            <div>
              {sortedList.map(file =>
                (<FinalPaperFileRow
                  selectedFiles={selectedFiles}
                  selectFile={this.selectFile}
                  file={file}
                  plagFileHref={getFilePlagLink(file, plagFileList)}
                  key={file._id}
                  openFilePreviewHandler={this.openFilePreviewHandler}
                  fileRowClick={this.fileRowClick}
                />))}
            </div>
          </div>
          {sortedList.length > 0 &&
            <div className="row flex-end mb10">
              <button
                className={classNames('btn btn-light btn-md btn-download-all', { hide: !!selectedFiles.length })}
                onClick={this.onDownloadAll}
                disabled={downloadFetching}
              >
                {downloadFetching ? 'Loading...' : 'Download all'}
              </button>
              <div className="clear" />
            </div>
          }
        </UnmountClosed>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openFilePreview: data => dispatch(setPlugin(data)),
  addModal: (component, modalOptions = {}) => dispatch(addModalComponent({ component, ...modalOptions })),
  addModalMobile: (component, modalOptions) => dispatch(addModalComponent(component, modalOptions)),
});

export default connect(null, mapDispatchToProps)(OrderFinalPaperList);
