import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import without from 'lodash/without';

import { userSetShowProposed } from 'store/user/actions';

import { isNewbie } from 'utils/groups';

import classNames from './../../utils/classNames';
import storage from './../../utils/localStorageHandler';
import Icon from './../Icon';
import OrderTableSearch, { OrderTableSearchBtn } from './../OrderTableSearch';
import { useFirstRender } from 'hooks';

import {
  getOrdersFilteredSorted,
  getOrdersFilteredSortedCounts,
  // getOrdersTypesWithCount,
  // getOrdersDeadlineWithCount,
  // getOrdersSubjectsByGroupWithCount,
  // getOrdersSubjectsWithCount,
  getAllOrdersByInitialFilters,
} from './../../store/ordersFind/selectors';

import {
  resetAllFilters,
  ordersSetFilterTime,
  ordersSetFilterStep,
  ordersFindSetFilterType,
  setFilterReservedByOther,
  ordersFindSetFilterOnline,
  ordersFindSetFilterSubject,
  ordersFindSetFilterSubjectGroup,
  ordersSetFilterSearch,
} from './../../store/ordersFind/actions';

import Dropdown from './../Dropdown';
import Checkbox from './../Checkbox';
import Switch from './../Switch';

import { OTHER, FILTER, TYPE_ORDERS } from './sharedConstants';
import { mapShortOrderTypes, getCntPreferSubj } from './helpers';
import DeadlinesFilter from './DeadlinesFilter';
import TypesFilter from './TypesFilter';
import SubjectGroupsFilter from './SubjectGroupsFilter';

import './styles/index.scss';


const OrdersFilter = (props) => {
  const {
    filter,
    isLoading,
    orderList,
    orderTimes,
    orderTypes,
    preferSubj,
    orderSubjects,
    orderSubjectGroups,
    orderSubjectGroupsCount,
    cntPreferSubj,
    resetFilters,
    setFilterType,
    setFilterTime,
    setFilterStep,
    setFilterSearch,
    setFilterOnline,
    setFilterSubject,
    setFilterReserved,
    isSearch,
    onSearchChange,
    initialOrderListLength,
    orderFilteredListCnt,
    isFilterOn,
    openProposed,
    group,
  } = props;

  const {
    time,
    subject,
    type,
    subject_group,
    show_online,
    show_reserved,
    steps,
  } = filter;

  const [cntOrders, setCountOrders] = useState(0);
  const [searchValue, onSearchValChange] = useState('');
  const [isPreferSubj, setPreferSubj] = useState(false);
  const [stepFilter, setStepFilter] = useState(steps);

  const [stateFilter, setFilter] = useState({
    times: time || [],
    subjects: subject || [],
    types: type || [],
    subjectGroups: subject_group || [],
    showOnline: !!show_online,
    showReserved: !!show_reserved,
    steps: steps || [],
  });

  // count mapping types
  let countTypes = 0;
  Object.keys(orderTypes).map((item) => {
    const typeItem = orderTypes[item];
    if (stateFilter.types.indexOf(typeItem.values[0]) > -1) {
      countTypes += 1;
    }
  });

  // define name button filter
  const btnTimeText = stateFilter.times.length ? `Deadlines • ${stateFilter.times.length}` : 'All deadlines';
  const btnSubjText = filter.subject.length ? `Subjects • ${filter.subject.length}` : 'All Subjects';
  const btnTypeText = countTypes ? `Task types • ${countTypes}` : 'All task types';

  const isNewbieGroup = isNewbie(group);

  const onResetFilters = () => {
    resetFilters();
    const data = {
      times: [],
      subjects: [],
      types: [],
      subjectGroups: [],
      showOnline: true,
      showReserved: false,
      steps: [],
    };
    storage.set('filter', data);
    setFilter(data);
    setStepFilter([]);
    setCountOrders(0);
    setPreferSubj(false);
  };

  const getStepFilter = (field, arr) => {
    let arSteps = stepFilter;
    if (arr.length > 0) {
      if ((stepFilter).indexOf(field) < 0) {
        arSteps.push(field);
      }
    }
    if (arr.length === 0) {
      if ((stepFilter).indexOf(field) > -1) {
        arSteps = arSteps.filter(s => s !== field);
      }
    }
    setFilterStep(arSteps);
    return arSteps;
  };

  const onFilterOrders = () => {
    let arSteps = stateFilter.steps;
    if (stateFilter.steps.indexOf(FILTER.subjects) === -1) {
      arSteps = [...stateFilter.steps, FILTER.subjects];
    }
    const data = {
      ...stateFilter,
      steps: arSteps,
    };
    setFilter(data);
    storage.set('filter', data);
    getStepFilter(FILTER.subjects, data[FILTER.subjects]);
    setFilterSubject(stateFilter[FILTER.subjects]);
  };

  const getCntOrders = (dataFilter) => {
    let cntOrdersSubj = 0;

    if (dataFilter.subjects.length > 0) {
      dataFilter.subjects.map(subj => cntOrdersSubj += (orderSubjects[subj] && orderSubjects[subj].count) || 0);
    } else {
      dataFilter.subjectGroups.map(subj => cntOrdersSubj += orderSubjectGroupsCount[subj].count);
    }
    return cntOrdersSubj;
  };

  const resetFilterOrders = () => {
    const arSteps = stateFilter.steps.filter(s => s !== FILTER.subjects);
    const data = {
      ...stateFilter,
      subjects: [],
      subjectGroups: [],
      steps: arSteps,
    };
    setFilter(data);
    storage.set('filter', data);
    getStepFilter(FILTER.subjects, data[FILTER.subjects]);
    setFilterSubject([]);
  };

  const onResetItemFilter = (field) => {
    let arSteps = [];
    let newData = {};
    arSteps = stepFilter.filter(s => s !== field);

    switch (field) {
      case FILTER.times:
        newData = { ...stateFilter, [FILTER.times]: [] };
        setFilterTime([]);
        break;
      case FILTER.subjects:
        newData = { ...stateFilter, [FILTER.subjectGroups]: [], [FILTER.subjects]: [] };
        resetFilterOrders();
        break;
      case FILTER.types:
        newData = { ...stateFilter, [FILTER.types]: [] };
        setFilterType([]);
        break;
      default:
        break;
    }

    newData = { ...newData, steps: arSteps };
    getStepFilter(FILTER[field], newData[FILTER[field]]);
    setStepFilter(arSteps);
    setFilter(newData);
    storage.set('filter', newData);
  };

  const onChangeFilter = (field, value) => {
    let data = {};
    let arSteps = [];

    if (isPreferSubj) {
      setPreferSubj(false);
    }

    switch (field) {
      case FILTER.subjectGroups:
        if (stateFilter[field].indexOf(value) > -1) {
          let sbj = stateFilter.subjects;

          stateFilter.subjects.map((s) => {
            if (orderSubjectGroups[value].indexOf(s) > -1) {
              sbj = sbj.filter(sb => sb !== s);
            }
          });
          data = {
            subjects: sbj,
            [field]: stateFilter[field].filter(item => item !== value),
          };
        } else {
          let sbj = [];
          orderSubjectGroups[value].map((s) => {
            if (orderSubjects[s].count > 0) {
              sbj = [...sbj, s];
            }
          });
          data = {
            subjects: [...stateFilter.subjects, ...sbj],
            [field]: [...stateFilter[field], value],
          };
        }
        break;

      case FILTER.times:
        if (stateFilter[field].indexOf(value) > -1) {
          data = {
            [field]: stateFilter[field].filter(item => item[0] !== value[0]),
          };
        } else {
          data = {
            [field]: [...stateFilter[field], value],
          };
        }
        break;
      case FILTER.types:
        if (stateFilter[field].indexOf(value[0]) > -1) {
          data = {
            [field]: without(stateFilter[field], ...value),
          };
        } else {
          data = {
            [field]: [...stateFilter[field], ...value],
          };
        }
        break;
      case FILTER.subjects:
        if (stateFilter[field].indexOf(value) > -1) {
          data = {
            [field]: stateFilter[field].filter(item => item !== value),
          };
        } else {
          data = {
            [field]: [...stateFilter[field], value],
          };
        }
        break;
      case FILTER.showOnline:
      case FILTER.showReserved:
        data = {
          [field]: value,
        };
        break;
      default:
        break;
    }

    if (field === FILTER.subjects) {
      let hasSubjects = false;
      stateFilter.subjectGroups.forEach((sg) => {
        hasSubjects = orderSubjectGroups[sg].some(subj => data.subjects.indexOf(subj) > -1);
        if (!hasSubjects) {
          data.subjectGroups = stateFilter.subjectGroups.filter(s => s !== sg);
        }
      });
    }

    if (field !== FILTER.subjectGroups && field !== FILTER.subjects) {
      arSteps = getStepFilter(field, data[field]);
      data.steps = arSteps;
    }

    data = {
      ...stateFilter,
      ...data,
    };

    setStepFilter(data.steps);
    if (field !== FILTER.subjectGroups && field !== FILTER.subjects) {
      storage.set('filter', data);
    }
    setFilter(data);

    switch (field) {
      case FILTER.types:
        setFilterType(data.types);
        break;
      case FILTER.times:
        setFilterTime(data.times);
        break;
      case FILTER.showOnline:
        setFilterOnline(value);
        break;
      case FILTER.showReserved:
        setFilterReserved(value);
        break;
      default:
        break;
    }
  };

  const onSetPreferSubj = (set) => {
    const subjectsGroup = set ? preferSubj : [];
    let subjectsList = [];
    if (set) {
      preferSubj.map(s => subjectsList = [...orderSubjectGroups[s], ...subjectsList]);
    }
    const data = {
      ...stateFilter,
      subjectGroups: subjectsGroup,
      subjects: subjectsList,
    };

    setFilter(data);
    setPreferSubj(set);
  };

  const onInputChange = (event) => {
    const { target } = event;
    onSearchValChange(target.value);
    onSearchChange(target.value);
  };

  const onSearchSubmit = (event) => {
    event.preventDefault();
    setFilterSearch(searchValue.trim());
  };

  const onToggleSearchClick = () => {
    const newIsSearch = !isSearch;
    if (!newIsSearch) {
      setFilterSearch('');
      onSearchValChange('');
    }
    onSearchChange(newIsSearch);
  };

  const onOpenOrderWheel = () => {
    openProposed();
  };

  const isFirstRender = useFirstRender();

  const checkAndResetFilter = () => {
    if (stateFilter.steps.length === 0 || searchValue) return;

    const arSteps = stateFilter.steps;
    let newData = stateFilter;

    arSteps.slice(1).forEach((s) => {
      if (s === FILTER.subjects) {
        newData = {
          ...newData,
          [s]: [],
          subjectGroups: [],
        };
      } else {
        newData = {
          ...newData,
          [s]: [],
          subjectGroups: [],
        };
      }
      switch (s) {
        case FILTER.types:
          setFilterType(newData.types);
          break;
        case FILTER.times:
          setFilterTime(newData.times);
          break;
        case FILTER.subjects:
          setFilterSubject(newData[FILTER.subjects]);
          break;
        default:
          break;
      }
    });

    newData = { ...newData, steps: arSteps.slice(0, 1) };
    setStepFilter([arSteps[0]]);
    setFilter(newData);
    storage.set('filter', newData);
  };

  useEffect(() => {
    setCountOrders(getCntOrders(stateFilter));
  }, [stateFilter.subjects, orderSubjects]);

  useEffect(() => {
    if (!isFirstRender && orderFilteredListCnt === 0) checkAndResetFilter();
  }, [orderFilteredListCnt]);

  useEffect(() => () => setFilterSearch(''), []);

  if (isSearch) {
    return (
      <div className="table-filter">
        <OrderTableSearch
          onSubmit={e => onSearchSubmit(e)}
          value={searchValue}
          onChange={e => onInputChange(e)}
          onToggle={() => onToggleSearchClick()}
        />
      </div>
    );
  }

  // const isCheckedOther = stateFilter.subjectGroups.indexOf(orderSubjectGroupsCount[OTHER].name) > -1;
  // let isAllIncludeOther = false;
  // if (isCheckedOther) {
  //   isAllIncludeOther = true;
  //   orderSubjectGroups[orderSubjectGroupsCount[OTHER].name].forEach((sb) => {
  //     if (stateFilter.subjects.indexOf(sb) === -1) {
  //       isAllIncludeOther = false;
  //     }
  //   });
  // }

  return (
    <div>
      {!isLoading &&
        <div className="filter-orders">
          <div className="filter-container">
            {
              orderList && orderList.length > 0 && !isNewbieGroup &&
              <div className="filter-item">
                <button disabled={window.location.href.includes('NT')} className="btm-filter btm-filter--fill-btn" onClick={onOpenOrderWheel}>Find me an order</button>
              </div>
            }
            <div className="filter-item">
              <OrderTableSearchBtn onClick={() => onToggleSearchClick()} className="btm-filter" />
            </div>
            <div className="filter-item-division" />
            <div className="tooltip tooltip__icon tooltip-table tooltip-right tooltip-bottom">
              <Icon
                className="tooltip-trigger"
                iconName="close-fill-grey"
                styles={{
                  width: '16px', height: '16px', marginRight: '6px', cursor: 'pointer',
                }}
              />
              <div className="tooltip-content">
                <strong className="mb4 fsXSm block" style={{ color: '#637280' }}>Order Filtering</strong>
                <p>Note, that filters do not apply to pinned orders</p>
              </div>
            </div>
            <div className="filter-item">
              <Dropdown disabled={isLoading} text={btnTimeText} hideArrow buttonClassName={stateFilter.times.length > 0 ? 'btm-filter active' : 'btm-filter'}>
                <ul className="container-item">
                  <DeadlinesFilter orderTimes={orderTimes} stateFilter={stateFilter} onChangeFilter={onChangeFilter} />
                </ul>
              </Dropdown>
              {stateFilter.times.length > 0 &&
                <button className="btn btn-empty btn-reset" onClick={() => onResetItemFilter(FILTER.times)}><Icon className="svg-icon" iconName="icon-reset" /></button>
              }
            </div>

            <div className="filter-item">
              <Dropdown id="subjects" key="subjects" disabled={isLoading} text={btnSubjText} hideArrow buttonClassName={filter.subject.length > 0 ? 'btm-filter active' : 'btm-filter'}>
                <div className="filter-item__subj">
                  <div className="subj-col">
                    <h3 className="title-filter__sm">Quick filters</h3>
                    <button
                      className={classNames('btm-filter', { active: isPreferSubj })}
                      onClick={() => onSetPreferSubj(!isPreferSubj)}
                    >
                      My preferred subjects • <b>({cntPreferSubj})</b>
                    </button>
                  </div>
                  <div className="subj-col">
                    <h3 className="title-filter">Subject categories</h3>
                    <ul>
                      <SubjectGroupsFilter orderSubjectGroupsCount={orderSubjectGroupsCount} stateFilter={stateFilter} orderSubjectGroups={orderSubjectGroups} onChangeFilter={onChangeFilter} />
                      {/* <li key={`group${OTHER}`}>
                    <Checkbox
                      name={OTHER}
                      disabled={orderSubjectGroupsCount[OTHER].count === 0}
                      checked={stateFilter.subjectGroups.indexOf(orderSubjectGroupsCount[OTHER].name) > -1}
                      onChange={() => onChangeFilter(FILTER.subjectGroups, orderSubjectGroupsCount[OTHER].name)}
                      minus={isCheckedOther && !isAllIncludeOther}
                    >
                      {orderSubjectGroupsCount[OTHER].name}
                      <span>({orderSubjectGroupsCount[OTHER].count || 0})</span>
                    </Checkbox>
                  </li> */}
                    </ul>
                  </div>
                  <div className="subj-col subj-col__last">
                    <h3 className="title-filter">Subjects</h3>
                    {stateFilter.subjectGroups.length > 0 &&
                      <ul>
                        {stateFilter.subjectGroups.map((sg) => {
                          if (!orderSubjectGroupsCount[sg] || orderSubjectGroupsCount[sg].count === 0) {
                            return false;
                          }
                          return (
                            <div className="subj-list" key={`${sg}`}>
                              <h5 className="title-category">{sg}</h5>
                              {orderSubjectGroups[sg].map((s) => {
                                const item = orderSubjects[s];

                                return (
                                  <li key={`${item.name}`}>
                                    <Checkbox
                                      data-dropdown="keep"
                                      name={item.name}
                                      disabled={item.count === 0}
                                      checked={stateFilter.subjects.indexOf(item.name) > -1}
                                      onChange={() => onChangeFilter(FILTER.subjects, item.name)}
                                    >
                                      {item.name}
                                      <span> ({item.count || 0})</span>
                                    </Checkbox>
                                  </li>
                                );
                              })}
                            </div>
                          );
                        })}
                      </ul>
                    }
                    {stateFilter.subjectGroups.length <= 0 &&
                      <div className="container-item no-result">
                        <Icon className="svg-icon" iconName="icon-sad" />
                        <h5 className="title">No subjects</h5>
                        <p className="desc">Please choose at least one subject category оn the left</p>
                      </div>
                    }
                  </div>
                </div>
                <div className="dropdown-footer">
                  <button
                    className="btn btn-bright"
                    data-dropdown="close"
                    onClick={() => onFilterOrders()}
                    disabled={stateFilter[FILTER.subjects].length <= 0 || cntOrders === 0}
                  >
                    Show {cntOrders} orders
                  </button>
                </div>
              </Dropdown>
              {filter.subject.length > 0 &&
                <button className="btn btn-empty btn-reset" onClick={() => onResetItemFilter(FILTER.subjects)}><Icon className="svg-icon" iconName="icon-reset" /></button>
              }
            </div>

            <div className="filter-item">
              <Dropdown disabled={isLoading} text={btnTypeText} hideArrow buttonClassName={stateFilter.types.length > 0 ? 'btm-filter active' : 'btm-filter'}>
                <ul className="container-item">
                  <TypesFilter orderTypes={orderTypes} stateFilter={stateFilter} onChangeFilter={onChangeFilter} />
                </ul>
              </Dropdown>
              {stateFilter.types.length > 0 &&
                <button className="btn btn-empty btn-reset" onClick={() => onResetItemFilter(FILTER.types)}><Icon className="svg-icon" iconName="icon-reset" /></button>
              }
            </div>

            {orderList.length > 0 && isFilterOn &&
              <div className="filter-item">
                <button type="button" data-dropdown="keep" className="btn btn-empty" onClick={() => onResetFilters()}>Reset filters and show <b className="cnt"> {initialOrderListLength}</b> available orders</button>
              </div>
            }

            {orderList.length > 0 && !isFilterOn &&
              <div className="filter-item">
                <p className="total">Total <b className="cnt"> {initialOrderListLength}</b> orders available</p>
              </div>
            }
          </div>
          <div className="filter-container">
            <Switch
              id="showOnline"
              className={classNames('switch-filter', { active: stateFilter.showOnline })}
              name="online"
              isOn={stateFilter.showOnline}
              handleToggle={() => onChangeFilter('showOnline', !stateFilter.showOnline)}
            >
              <span className="switch-filter__name">Show on-line orders</span>
            </Switch>
            <Switch
              id="showReserved"
              className={classNames('switch-filter', { active: stateFilter.showReserved })}
              name="online"
              isOn={stateFilter.showReserved}
              handleToggle={() => onChangeFilter('showReserved', !stateFilter.showReserved)}
            >
              <span className="switch-filter__name">Show reserved</span>
            </Switch>
          </div>
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const isLoading = state.ordersFind.isFetching || state.settings.isFetching;
  const orderList = getOrdersFilteredSorted[TYPE_ORDERS](state);
  // const allOrders = state.orders;
  const orderListCounts = getOrdersFilteredSortedCounts[TYPE_ORDERS](state);
  // console.log('orderList: ', orderList);
  // console.log('state.ordersFilteredFind: ', state.ordersFilteredFind);
  const initialOrderListLength = getAllOrdersByInitialFilters(state).length;

  const orderFilteredListCnt = state.ordersFilteredFind.numbers && state.ordersFilteredFind.numbers.length;
  // console.log('orderFilteredListCnt: ', orderFilteredListCnt);

  const filter = state.ordersFind.filter.all;
  const isFilterOn = !filter.show_online || filter.show_reserved || filter.steps.length > 0;
  const preferSubj = (state.user && state.user.subjects) || [];
  const group = (state.user && state.user.qc_group.toLowerCase());
  const { orderSubjectGroups } = state.settings;
  // let otherSubjects = [];
  // orderList.filter(o => { if (!o.is_pinned && !o.reserved_by_me && o.is_subject_other) { otherSubjects = [...otherSubjects, o.subject] } });
  // Object.values(allOrders).filter(o => { if (!o.is_pinned && !o.reserved_by_me && o.is_subject_other) { otherSubjects = [...otherSubjects, o.subject] } });
  // console.log('orderList: ', orderList.filter(o => !o.is_pinned && !o.reserved_by_me));
  // console.log('otherSubjects: ', otherSubjects);
  // orderSubjectGroups.Other = otherSubjects;

  // console.log('otherSubjects: ', otherSubjects);

  const orderTimes = (orderListCounts && orderListCounts.times) || [];
  const orderTypesNames = (orderListCounts && orderListCounts.types) || null;
  const orderTypes = mapShortOrderTypes(orderTypesNames) || {};

  const orderSubjectGroupsCount = (orderListCounts && orderListCounts.subjectGroups) || [];
  const orderSubjects = (orderListCounts && orderListCounts.subjects) || [];

  const cntPreferSubj = getCntPreferSubj(preferSubj, orderSubjectGroupsCount);

  return {
    filter,
    isLoading,
    orderList,
    orderTimes,
    orderTypes,
    preferSubj,
    orderSubjects,
    cntPreferSubj,
    orderSubjectGroups,
    orderSubjectGroupsCount,
    orderFilteredListCnt,
    initialOrderListLength,
    isFilterOn,
    group,
  };
};

const mapDispatchToProps = dispatch => ({
  setFilterStep: value => dispatch(ordersSetFilterStep(TYPE_ORDERS, value)),
  setFilterTime: value => dispatch(ordersSetFilterTime(TYPE_ORDERS, value)),
  setFilterSubjectGroup: value => dispatch(ordersFindSetFilterSubjectGroup(TYPE_ORDERS, value)),
  setFilterSubject: value => dispatch(ordersFindSetFilterSubject(TYPE_ORDERS, value)),
  setFilterType: value => dispatch(ordersFindSetFilterType(TYPE_ORDERS, value)),
  setFilterOnline: value => dispatch(ordersFindSetFilterOnline(TYPE_ORDERS, value)),
  setFilterReserved: value => dispatch(setFilterReservedByOther(TYPE_ORDERS, value)),
  resetFilters: () => dispatch(resetAllFilters(TYPE_ORDERS)),
  setFilterSearch: value => dispatch(ordersSetFilterSearch(TYPE_ORDERS, value)),
  openProposed: () => dispatch(userSetShowProposed(true, 'lucky_btn')),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersFilter);
