import React, { useMemo } from 'react';

import { isNewbie, isBeginner } from 'utils/groups';
import classnames from 'utils/classNames';

import Tooltip from 'components/Tooltip';

import { getSimpleGroupTooltip } from '../utils';

const GroupLevel = ({ user }) => {
  const {
    is_group_override: isGroupOverride,
    qc_group: qcGroup = '', qc_full_group: qcFullGroup = [],
    activeWarnings,
  } = user;

  const cntActiveWarnings = activeWarnings && activeWarnings.results && activeWarnings.results.total;

  const group = qcGroup.toLowerCase();
  const { isNewbieGroup, isBeginnerGroup, isSimpleGroup } = useMemo(() => ({
    isNewbieGroup: isNewbie(group),
    isBeginnerGroup: isBeginner(group),
    isSimpleGroup: !isNewbie(group) && !isBeginner(group),
  }), [group]);

  const isOutsider = useMemo(() => qcFullGroup.includes('outsider'), [qcFullGroup]);

  let groupElement = '';
  let tooltipContent = '';

  const tooltipContentOutsider = (
    <div>
      <span className="active-warnings">You have {cntActiveWarnings} active warnings.</span> Please fix those issues ASAP or we will be forced to terminate your account!
    </div>
  );


  if (isBeginnerGroup) {
    groupElement = (<div className={classnames('writer-level-ungroup', 'writer-level-beginner', { 'writer-level-beginner-outsider': isOutsider })}>Beginner</div>);
    if (isOutsider) {
      tooltipContent = tooltipContentOutsider;
    }
  }

  if (isNewbieGroup) {
    groupElement = (<div className={classnames('writer-level-ungroup', 'writer-level-newbie', { 'writer-level-newbie-outsider': isOutsider })}>Newbie</div>);
    if (isOutsider) {
      tooltipContent = tooltipContentOutsider;
    }
  }

  if (isSimpleGroup) {
    groupElement = (
      <div className="writer-level-row">
        {group === 'a' &&
          <span className={classnames('writer-level-item', 'writer-level-item-a')} />
        }
        {group.indexOf('a+') === 0 &&
          <span className={classnames('writer-level-item', 'writer-level-item-a-plus')} />
        }
        {group.indexOf('b') === 0 &&
          <span className={classnames('writer-level-item', 'writer-level-item-b')} />
        }
        {group.indexOf('c') === 0 &&
          <span className={classnames('writer-level-item', 'writer-level-item-c')} />
        }
        {group.indexOf('d') === 0 &&
          <span className={classnames('writer-level-item', 'writer-level-item-d')} />
        }
        {group.indexOf('f') === 0 &&
          <span className={classnames('writer-level-item', 'writer-level-item-f')} />
        }
      </div>
    );
    tooltipContent = getSimpleGroupTooltip(qcFullGroup, cntActiveWarnings);
  }

  if (isGroupOverride) {
    return groupElement;
  }

  return (
    <div className="tooltip-header">
      <Tooltip content={tooltipContent} positionDown>
        {groupElement}
      </Tooltip>
    </div>
  );
};

export default GroupLevel;
