import imgAttach from './attach.svg';
import imgAttention from './warning.svg';
import imgBgEmpty from './bgEmpty.svg';
import imgChevronBottom from './down.svg';
import imgSend from './send.svg';
import imgMic from './mic.svg';
//import imgMicWhite from './micWhite.png';
import imgReload from './reload.svg';
import imgSeen from './seen.svg';
import imgSent from './sent.svg';
import imgTime from './time.svg';
import imgClose from './close.svg';
//import imgPlay from './play.png';
//import imgPause from './pause.png';
import imgTrash from './trash.svg';

const imageSet = {
  imgAttach,
  imgAttention,
  imgBgEmpty,
  imgChevronBottom,
  imgMic,
  // imgMicWhite,
  imgReload,
  imgSeen,
  imgSend,
  imgSent,
  imgTime,
  imgClose,
  //imgPlay,
  //imgPause,
  imgTrash,
};

export default imageSet;
