const triggerWebNotification = ({ title, text, link = '/' }) => {
  if (document.visibilityState !== 'hidden') return;

  if (Notification.permission !== 'granted') return;

  if (!title) return;

  const notification = new Notification(`${title}`, { body: text, icon: '/favicons/favicon.ico' });

  notification.onclick = (event) => {
    event.preventDefault();
    window.open(link, '_blank');
    notification.close();
  };
};

export default triggerWebNotification;

