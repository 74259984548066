import React, { useState } from 'react';

import Radio from 'components/Radio';

import TextArea from 'components/shared/TextArea';

const REJECT_REASONS = [
  'Client did not answer my question',
  'Client did not accept my suggestions',
  'Client request did not match the description',
  'I accidentally joined chat',
  'Client asked me to cancel',
  'Other reason',
];

const ChatReject = ({ onReject, onClose }) => {
  const [selectedReason, setSelectedReason] = useState('');
  const [otherText, setOtherText] = useState('');

  const onSetReason = (e) => {
    const { target: { value } } = e;
    setSelectedReason(value);
  };

  return (
    <div>
      <h2 className="fsXL"><strong>State the reason why you are rejecting from chat:</strong></h2>
      <div className="mt15">
        {REJECT_REASONS.map(reason => (
          <Radio className="mb8" key={reason} name="chatRejectReasons" value={reason} checked={selectedReason === reason} onChange={onSetReason}>
            {reason}
          </Radio>
            ))}
      </div>
      {
        selectedReason === 'Other reason' && <TextArea withCounter maxValue={50} noResize extraClasses="mt8" onUpdateCallback={setOtherText} placeholder="State the reason" />
      }
      <div className="row flex-end column_gap_12 mt20">
        <button className="btn btn-error btn-md2" disabled={!selectedReason || (selectedReason === 'Other reason' && !otherText)} type="button" onClick={() => onReject(selectedReason, otherText)}>Reject the chat</button>
        <button className="btn btn-bright btn-md2" type="button" onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default ChatReject;
