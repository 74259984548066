import ReactDomServer from 'react-dom/server';
import moment from 'moment';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from 'hooks';

import { getProfile, getPmRequestDisabled, getAgreementInfo, getPmStartRequested } from 'store/user/selectors';
import { closeModalComponent, addModalComponent } from 'store/modals/actions';

import Icon from 'components/Icon';
import InfoComponent from 'components/agreement/InfoModal/InfoComponent';

import modalList from 'constants/modalList';
import { DATA_DISABLED_REQUEST_PM_SETUP } from 'constants/localStorage';
import formatTime from 'utils/formatTime';


const RequestPMSetupNotifications = () => {
  const dispatch = useDispatch();
  const { lsValue: dataDisabledRequestPmSetupModal, setItem } = useLocalStorage(DATA_DISABLED_REQUEST_PM_SETUP);
  const profile = useSelector(getProfile) || {};
  const pmRequestDisabled = useSelector(getPmRequestDisabled);
  const pmStartRequested = useSelector(getPmStartRequested);
  const showAgreement = useSelector(getAgreementInfo);

  const dataModal = modalList.RequestPMSetupNotifications;

  const onCloseModal = () => {
    dispatch(closeModalComponent(dataModal.name));
    setItem(DATA_DISABLED_REQUEST_PM_SETUP, moment(pmRequestDisabled).toISOString());
  };

  const getCustomHtml = () => {
    const icon = ReactDomServer.renderToString(<Icon styles={{ width: 16, height: 16 }} iconName="instructions-under-review" />);

    const modalText = [
      '<div class="info-modal-container__header text-modal">Hello!</div>',
      '<div class="info-modal-container__text text-modal">',
      '<div class="mt10">Unfortunately, we decided to terminate our cooperation due to performance issues.</div>',
      '<div class="mt10">We could not withdraw available funds on your balance, since you have no payment method.</div>',
      '<div class="mt10">Hence, you have <strong>14</strong> days to set up payment method of your choice, and request withdrawal.</div>',
      `<div class="mt10">On <strong>${formatTime(pmRequestDisabled, 'dDay')}</strong> your account will be permanently disabled.</div>`,
      '<div class="mt10 info-modal-container__sm-text-light row">',
      `<div class="mr10">${icon}</div>`,
      '<p>You can add payment method not later than 5 days before the withdrawal request deadline (each month during 5-10 and 20-25 payment method modification is not available).</p>',
      '</div>',
      '</div>',
      '</div>',
    ];
    return modalText.join('');
  };

  useEffect(() => {
    if (!profile || !profile._id) return;
    if (showAgreement) return;
    if (!pmStartRequested || (moment(pmRequestDisabled).toISOString() === dataDisabledRequestPmSetupModal)) return;

    const component = (<InfoComponent
      modalName={dataModal.name}
      btnText="Got it"
      onNextClick={onCloseModal}
      customHtml={{ html: getCustomHtml() }}
    />);
    dispatch(addModalComponent({ component, ...dataModal }));
  }, [pmRequestDisabled, showAgreement]);

  return null;
};

export default RequestPMSetupNotifications;
