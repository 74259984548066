import React from 'react';
// import styles from './star-icon.styl';

export default () => {
  const fillColor = '#ffa525';
  const emptyColor = '#e6e8eb';
  const stroke = 'none';

  return (
    <div className="star-container">
      <svg viewBox="0 0 80 80">
        <linearGradient id="partGradient">
          <stop stopOpacity="1" offset="100%" stopColor={fillColor} />
          <stop stopOpacity="1" offset="0%" stopColor={emptyColor} />
        </linearGradient>
        <g fill="url(#partGradient)" stroke={stroke} strokeWidth="2">
          <path d="
            M 40.000 60.000
            L 63.511 72.361
            L 59.021 46.180
            L 78.042 27.639
            L 51.756 23.820
            L 40.000 0.000
            L 28.244 23.820
            L 1.958 27.639
            L 20.979 46.180
            L 16.489 72.361
            L 40.000 60.000
            "
          />
        </g>
      </svg>
    </div>
  );
};
