import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import classNames from 'utils/classNames';

import { ordersCompleteSetFilterTime, ordersCompleteSetFilterSearch, setCompleteMessageFilter } from 'store/ordersComplete/actions';
import { ordersRefundedSetFilterTime, ordersRefundedSetFilterSearch, setRefundedMessageFilter } from 'store/ordersRefunded/actions';

import Switch from './Switch';
import Dropdown from './Dropdown';
import Select from './Select';
import OrderTableSearch, { OrderTableSearchBtn } from './OrderTableSearch';

class OrdersCompleteTableFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
    };

    this.onToggleSearchClick = this.onToggleSearchClick.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    const { setFilterSearch } = this.props;
    setFilterSearch('');
  }

  onSearchSubmit(event) {
    event.preventDefault();
    const { searchValue } = this.state;
    const { setFilterSearch } = this.props;
    setFilterSearch(searchValue.trim());
  }

  onToggleSearchClick() {
    const { setFilterSearch, isSearch, onSearchChange } = this.props;
    this.setState((state) => {
      const newIsSearch = !isSearch;
      if (!newIsSearch) {
        setFilterSearch('');
      }
      onSearchChange(newIsSearch);
    });
  }

  onInputChange(event) {
    const { target } = event;
    this.setState(state => Object.assign({}, state, {
      [target.name]: target.value,
    }));
  }

  render() {
    const {
      translate,
      className,
      filter,
      filterTimesLong,
      setFilterTime,
      isSearch,
      refundedMode,
      setMessageFilter,
      new_messages,
    } = this.props;
    const { searchValue } = this.state;

    if (isSearch) {
      return (
        <div className={className}>
          <OrderTableSearch
            onSubmit={this.onSearchSubmit}
            value={searchValue}
            onChange={this.onInputChange}
            onToggle={this.onToggleSearchClick}
          />
        </div>
      );
    }

    return (
      <div className={classNames(className, 'row space-between')}>
        <div className="row">
          <div className="col-1">
            <OrderTableSearchBtn onClick={this.onToggleSearchClick} />
          </div>
          {
            !refundedMode && (
              <div className="col-3">
                <Dropdown text={translate(`timeFilter.${filter.time}`)} closeAlways>
                  <Select className="menu menu-selectable" onChange={setFilterTime} value={filter.time}>
                    {filterTimesLong.map(item =>
                      <li key={item} value={item} className="menu-item">{translate(`timeFilter.${item}`)}</li>)}
                  </Select>
                </Dropdown>
              </div>
            )
          }
          <div className="col-12" />
          <div className="col-12" />
        </div>
        {
          !isSearch && (
          <div className="row">
            <Switch
              className={classNames('switch-filter', { 'switch-filter--active': new_messages })}
              name="online"
              isOn={new_messages}
              handleToggle={() => setMessageFilter(!new_messages)}
            >
              <div className="switch-filter__name">Show orders with unread messages</div>
            </Switch>
          </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { refundedMode } = ownProps;
  const translate = getTranslate(state.locale);
  const { filterTimesLong } = state.settings;
  const { new_messages } = refundedMode ? state.ordersRefunded.filter : state.ordersComplete.filter;
  return { filterTimesLong, translate, new_messages };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { refundedMode } = ownProps;
  return {
    setFilterTime: value => dispatch(refundedMode ? ordersRefundedSetFilterTime(value) : ordersCompleteSetFilterTime(value)),
    setMessageFilter: value => dispatch(refundedMode ? setRefundedMessageFilter(value) : setCompleteMessageFilter(value)),
    setFilterSearch: value => dispatch(refundedMode ? ordersRefundedSetFilterSearch(value) : ordersCompleteSetFilterSearch(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersCompleteTableFilter);
