import helpData from './data';
import { HELP_SET_SEARCH } from './actions';

helpData.search = '';
const help = (state = helpData, action) => {
    switch (action.type) {
        case HELP_SET_SEARCH:
            return Object.assign({}, state, {
                search: action.value
            });

        default:
            return state;
    }
};

export default help;