import {
  ORDERS_FILES_UPLOAD_REQUEST,
  ORDERS_FILES_UPLOAD_PROGRESS,
  ORDERS_FILES_UPLOAD_COMPLETE,
  ORDERS_FILES_UPLOAD_ERROR,
  ORDERS_FILES_REMOVE_REQUEST,
} from '../constants';

const file = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_FILES_UPLOAD_REQUEST:
      return {
        _id: action.fileId,
        name: action.fileName,
        group: action.group,
        progress: 0,
        location: action.fileName,
        isFetching: true,
        isUploading: true,
        abort: action.abort,
      };
    case ORDERS_FILES_UPLOAD_PROGRESS:
      return Object.assign({}, state, {
        progress: action.progress,
        isUploading: true,
        isFetching: true,
      });
    case ORDERS_FILES_UPLOAD_COMPLETE:
      return Object.assign({}, state, {
        progress: 100,
        isUploading: false,
        isFetching: true,
      });
    case ORDERS_FILES_REMOVE_REQUEST:
      return Object.assign({}, state, {
        isFetchingRemove: true,
      });
    case ORDERS_FILES_UPLOAD_ERROR:
      return Object.assign({}, state, {
        error: true,
      });
    default:
      return state;
  }
};

export default file;
