import React from 'react';

import ProtectedRoute from 'components/ProtectedRoute';

const ProtectedByDevModeRoute = ({ children }) => (
  <ProtectedRoute isValid={process.env.REACT_APP_BUILD !== 'prod'}>{children}</ProtectedRoute>
);


export default ProtectedByDevModeRoute;
