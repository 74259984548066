import merge from 'lodash/merge';

import utils from 'utils';

import {
  ORDERS_ADD_LIST,
  ORDERS_DETAILS_INVALIDATE,
  ORDERS_DETAILS_REQUEST,
  ORDERS_DETAILS_RECEIVE,
  ORDERS_DETAILS_NA_ERROR,
  ORDERS_DETAILS_STATUS_ERROR,
  ORDERS_DETAILS_ERROR,

  ORDERS_SET_FAVORITE_REQUEST,
  ORDERS_SET_FAVORITE_RECEIVE,
  ORDERS_SET_VIEWED_REQUEST,
  ORDERS_SET_VIEWED_RECEIVE,
  ORDERS_SET_STATUS_REQUEST,
  ORDERS_SET_STATUS_RECEIVE,
  ORDERS_SET_NEW_ORDER_RECEIVE,
  ORDERS_SET_RESERVED_RECEIVE,
  ORDERS_SET_RESERVATION_OVER,

  ORDERS_FILES_REQUEST,
  ORDERS_FILES_RECEIVE,
  ORDERS_FILES_UPLOAD_REQUEST,
  ORDERS_FILES_UPLOAD_PROGRESS,
  ORDERS_FILES_UPLOAD_COMPLETE,
  ORDERS_FILES_UPLOAD_RECEIVE,
  ORDERS_FILES_UPLOAD_ERROR,
  ORDERS_FILES_REMOVE_REQUEST,
  ORDERS_FILES_REMOVE_RECEIVE,

  ORDERS_MESSAGES_REQUEST,
  ORDERS_MESSAGES_RECEIVE,
  ORDERS_MESSAGES_SET_READ_REQUEST,
  ORDERS_MESSAGES_SET_READ_RECEIVE,
  ORDERS_MESSAGES_ADD_REQUEST,
  ORDERS_MESSAGES_ADD_RECEIVE,

  ORDERS_MESSAGES_ADD_ATTACH_RECEIVE,

  ORDERS_MESSAGES_ADD_CONFIRM_REQUEST,
  ORDERS_MESSAGES_ADD_CONFIRM_RECEIVE,
  ORDERS_MESSAGES_DELETE_CONFIRM_REQUEST,
  ORDERS_MESSAGES_DELETE_CONFIRM_RECEIVE,
  ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE,

  ORDERS_REPORT_REQUEST,
  ORDERS_REPORT_RECEIVE,
  ORDERS_DDL_EXTENSION_REQUEST,
  ORDERS_DDL_EXTENSION_RECEIVE,
  ORDERS_DDL_EXTENSION_ERROR,

  ORDERS_FIELD_UPDATE,
} from '../constants';

import { ORDERS_HIDE_IN_LISTS } from '../actions';

import order from './order';

const orders = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_ADD_LIST:
      return merge(state, action.items);
    case ORDERS_DETAILS_INVALIDATE:
    case ORDERS_DETAILS_REQUEST:
    case ORDERS_DETAILS_RECEIVE:
    case ORDERS_DETAILS_ERROR:
    case ORDERS_DETAILS_NA_ERROR:
    case ORDERS_DETAILS_STATUS_ERROR:
    case ORDERS_SET_FAVORITE_REQUEST:
    case ORDERS_SET_FAVORITE_RECEIVE:
    case ORDERS_SET_VIEWED_REQUEST:
    case ORDERS_SET_VIEWED_RECEIVE:
    case ORDERS_SET_NEW_ORDER_RECEIVE:
    case ORDERS_SET_RESERVED_RECEIVE:
    case ORDERS_SET_STATUS_REQUEST:
    case ORDERS_SET_STATUS_RECEIVE:
    case ORDERS_SET_RESERVATION_OVER:
    case ORDERS_FILES_REQUEST:
    case ORDERS_FILES_RECEIVE:
    case ORDERS_FILES_UPLOAD_REQUEST:
    case ORDERS_FILES_UPLOAD_PROGRESS:
    case ORDERS_FILES_UPLOAD_COMPLETE:
    case ORDERS_FILES_UPLOAD_RECEIVE:
    case ORDERS_FILES_UPLOAD_ERROR:
    case ORDERS_FILES_REMOVE_REQUEST:
    case ORDERS_FILES_REMOVE_RECEIVE:
    case ORDERS_MESSAGES_REQUEST:
    case ORDERS_MESSAGES_RECEIVE:
    case ORDERS_MESSAGES_SET_READ_REQUEST:
    case ORDERS_MESSAGES_SET_READ_RECEIVE:
    case ORDERS_MESSAGES_ADD_REQUEST:
    case ORDERS_MESSAGES_ADD_RECEIVE:
    case ORDERS_MESSAGES_ADD_CONFIRM_REQUEST:
    case ORDERS_MESSAGES_ADD_CONFIRM_RECEIVE:
    case ORDERS_MESSAGES_DELETE_CONFIRM_REQUEST:
    case ORDERS_MESSAGES_DELETE_CONFIRM_RECEIVE:
    case ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE:
    case ORDERS_MESSAGES_ADD_ATTACH_RECEIVE:
    case ORDERS_REPORT_REQUEST:
    case ORDERS_REPORT_RECEIVE:
    case ORDERS_DDL_EXTENSION_REQUEST:
    case ORDERS_DDL_EXTENSION_RECEIVE:
    case ORDERS_DDL_EXTENSION_ERROR:
    case ORDERS_FIELD_UPDATE:
      return Object.assign({}, state, {
        [action.number]: order(state[action.number], action),
      });
    case ORDERS_HIDE_IN_LISTS:
      if (Array.isArray(action.number)) {
        return Object.assign({}, action.number.reduce((prev, next) => utils.immutablyRemoveKey(next, prev), state));
      }
      return Object.assign({}, utils.immutablyRemoveKey(action.number, state));
    default:
      return state;
  }
};

export default orders;
