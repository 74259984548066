import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  NavLink,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import LevelDescription from '../components/progress/LevelDescription';
import StrictTooltip from './../components/StrictTooltip';
import { userWarningsFetchIfNeeded, userActiveWarningFetchIfNeeded } from './../store/user/actions';
import { newbieOrdersForLevelUp, beginnerScoresForLevelUp } from './../constants';

import SubNav from './../components/SubNav';
import classNames from './../utils/classNames';
import { groupsWriters } from './../constants/writerGroups';

import ProgressWarningsPage from './ProgressWarningsPage';
import ProgressScoresPage from './ProgressScoresPage';

class ProgressGroupActivityPage extends Component {

  componentWillMount() {
    const { profileId } = this.props;
    this.props.load();
    if (profileId) {
      this.props.loadActiveWarnings(profileId);
    }
  }

  render() {
    const { cntActiveWarnings } = this.props;
    const {
      hasNewBonuses,
      hasUnreadWarnings,
      user,
    } = this.props;
    const benefitsClassName = classNames({ 'new new--usd': hasNewBonuses });
    const warningsClassName = classNames({ 'new new--warnings': hasUnreadWarnings });

    const completedOrders = user.metric_data.completed_orders_count || 0;
    const cntCompleteOrderForLevelUpNewbie = completedOrders >= newbieOrdersForLevelUp ? 0 : newbieOrdersForLevelUp - completedOrders;

    const scoresOrders = user.metric_data.scores_count || 0;
    const cntScoresOrderForLevelUpNewbie = scoresOrders < beginnerScoresForLevelUp ? beginnerScoresForLevelUp - scoresOrders : 0;

    const group = user.qc_group.toLowerCase();
    const isGroup = group.indexOf('new') === -1 && group.indexOf('beg') === -1;

    const newbieTooltip = (
      <span>
        You are a newbie now.<br />
        <b>Complete {cntCompleteOrderForLevelUpNewbie} order(s) to<br /> unlock your next group!</b>
      </span>
    );

    const beginnerTooltip = (
      <span>
        Congratulations! <br /> You are a beginner now. <br />
        <b>Receive {cntScoresOrderForLevelUpNewbie} client score(s) to <br />
          unlock your next group!</b>
      </span>
    );

    const keysGroup = Object.keys(groupsWriters);
    return (
      <div className="container">
        <div className="row progress-container">
          <aside className="left-sidebar content-item">
            {keysGroup.map(g => (
              <LevelDescription
                key={g}
                active={isGroup && group === g}
                group={g}
                writer={user}
                cntWarnings={cntActiveWarnings}
              />
            ))}
            {!user.is_group_override && group.indexOf('new') > -1 && cntCompleteOrderForLevelUpNewbie > 0 &&
              <div className="warning-content-newbie-tooltip">
                <StrictTooltip
                  content={newbieTooltip}
                  isGreen
                  positionLeft
                />
              </div>
            }
            {!user.is_group_override && group.indexOf('beg') > -1 && cntScoresOrderForLevelUpNewbie > 0 &&
              <div className="warning-content-newbie-tooltip">
                <StrictTooltip
                  content={beginnerTooltip}
                  isYellow
                  positionLeft
                />
              </div>
            }
          </aside>

          <section className="right-content container-border">
            <SubNav sub>
              <NavLink className={benefitsClassName} to="scores">Scores</NavLink>
              <NavLink className={warningsClassName} to="warnings">Warnings</NavLink>
            </SubNav>
            <Routes>
              <Route path="scores" element={<ProgressScoresPage />}>
                <Route path=":orderNumber" element={<ProgressScoresPage />} />
              </Route>
              <Route path="warnings" element={<ProgressWarningsPage />} />
              <Route path="*" element={<Navigate to="scores" replace />} />
            </Routes>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  const cntActiveWarnings = user.activeWarnings && user.activeWarnings.results && user.activeWarnings.results.total;
  return { user, cntActiveWarnings, profileId: user._id };
};

const mapDispatchToProps = dispatch => ({
  loadActiveWarnings: profileId => dispatch(userActiveWarningFetchIfNeeded(profileId)),
  load: () => dispatch(userWarningsFetchIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressGroupActivityPage);
