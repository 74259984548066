
import React, { createContext, useState } from 'react';

export const CalcHeightContext = createContext();

export const CalcHeightContextProvider = ({ children }) => {
  const [chatHeight, setChatHeight] = useState('0px');
  const [suggestedStepHeight, setSuggestedStepHeight] = useState('0px');
  const [standAloneContainerHeight, setStandAloneContainerHeight] = useState('100%');

  return (
    <CalcHeightContext.Provider value={{
      chatHeight,
      setChatHeight,
      suggestedStepHeight,
      setSuggestedStepHeight,
      standAloneContainerHeight,
      setStandAloneContainerHeight,
    }}
    >
      {children}
    </CalcHeightContext.Provider>
  );
};
