import React, { Component } from 'react';

import FinalPaperFileRow from 'components/order/FinalPaperFileRow';

import { dataContentSectionContent, dataContentSectionStructure, dataContentSectionLanguage, dataContentSectionFormat } from './dataQACategory';

import OrderTabQAItem from './OrderTabQAItem';
import QACollapseItem from './QACollapseItem';

class OrderTabQA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titleContent: null,
      titleStructure: null,
      titleLanguage: null,
      titleFormat: null,
    };

    this.setTitle = this.setTitle.bind(this);
  }

  setTitle(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    const { order, setGradingValue, setGradingSum } = this.props;
    const {
      titleContent, titleStructure, titleLanguage, titleFormat,
    } = this.state;
    const pregraderComments = order.pregrader_recommendations;

    const fileList = order.files.items;
    const graderFiles = Object.values(fileList).filter(file => file.type === 'file_pregrader_comment').filter(file => !file.deleted);

    return (
      <div className="grade-box">

        {pregraderComments &&
        <div className="grade-box-head">
          <div className="grade-comment">
            <h4>Comments</h4>
            <p>{pregraderComments}</p>
          </div>
        </div>
                }

        {!!graderFiles.length &&
        <div className="upload-file-box">
          {graderFiles.map(file => <FinalPaperFileRow file={file} key={file._id} />)}
        </div>
                }

        {order.pregrader_report_form_data &&
        <div>
          <div className="person-info-box">
            <QACollapseItem title="Content" titleCounters={titleContent}>
              <OrderTabQAItem
                dateSection={dataContentSectionContent}
                dataRow={order.pregrader_report_form_data}
                setTitle={this.setTitle}
                sumTitle="sumTitleContent"
                setGradingSum={setGradingSum}
                setGradingValue={setGradingValue}
                title="titleContent"
              />
            </QACollapseItem>
          </div>
          <div className="person-info-box">
            <QACollapseItem title="Structure" titleCounters={titleStructure}>
              <OrderTabQAItem
                dateSection={dataContentSectionStructure}
                dataRow={order.pregrader_report_form_data}
                setTitle={this.setTitle}
                sumTitle="sumTitleStructure"
                setGradingSum={setGradingSum}
                setGradingValue={setGradingValue}
                title="titleStructure"
              />
            </QACollapseItem>
          </div>
          <div className="person-info-box">
            <QACollapseItem title="Language" titleCounters={titleLanguage}>
              <OrderTabQAItem
                dateSection={dataContentSectionLanguage}
                dataRow={order.pregrader_report_form_data}
                setTitle={this.setTitle}
                sumTitle="sumTitleLanguage"
                setGradingSum={setGradingSum}
                setGradingValue={setGradingValue}
                title="titleLanguage"
              />
            </QACollapseItem>
          </div>
          <div className="person-info-box">
            <QACollapseItem title="Format" titleCounters={titleFormat}>
              <OrderTabQAItem
                dateSection={dataContentSectionFormat}
                dataRow={order.pregrader_report_form_data}
                setTitle={this.setTitle}
                setGradingSum={setGradingSum}
                sumTitle="sumTitleFormat"
                title="titleFormat"
                setGradingValue={setGradingValue}
              />
            </QACollapseItem>
          </div>
        </div>
                }
      </div>
    );
  }
}

export default OrderTabQA;
