import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import utils from 'utils';

import classnames from 'utils/classNames';

import { GROUPS } from 'constants/order';

import {
  STATUS_OPEN,
  STATUS_PROGRESS,
  STATUS_REVISION,
  STATUS_COMPLETE,
} from 'store/orders/actions';

import { addImagesToPreview } from 'store/previewImages/actions';

import Icon from 'components/Icon';

import ClientChatPreview from 'components/ClientChatPreview';
import OrderFileList from 'components/order/OrderFileList';
import TextWithLink from 'components/TextWithLink';

import LoginDetail from '../components/LoginDetail';
import OrderComment from '../components/OrderComment';

import TopicForm from './TopicForm';
import OrderDraftPaperList from './OrderDraftPaperList';
import OrderFinalPaperList from './OrderFinalPaperList';
import OrderOpenActions from './OrderOpenActions';
import OrderDetailInfo from './OrderDetailInfo';
import RevisionOrderInfo from './RevisionOrderInfo';

const ORDER_DETAILS_DATA = [
  {
    field: 'writer_details_summary',
    title: 'Task summary',
  },
  {
    field: 'writer_details_example',
    title: 'Task example',
  },
  {
    field: 'writer_details_clarification',
    title: 'Deadline clarification',
  },
];


const OrderTabInfo = (props) => {
  const {
    order,
    status,
    onResize,
    fileItems,
    fileWebhook,
    setFilesStickyRef,
    setOtherFilesStickyRef,
    isStatusLoading,
    setReserved,
    setRejected,
    setAccepted,
    uploadFile,
    removeFile,
    mobile,
    setJoinChat,
    setRejectJoinChat,
    profile,
    onOpenIssueForm,
    uploadOrderFiles,
  } = props;

  const { no_sensitive: noSensitive = false } = order;

  const orderDetailsRef = useRef(null);

  const dispatch = useDispatch();

  const fileList = Object.values(fileItems).filter(file => !file.deleted);
  const taskFiles = fileList.filter(file => file.group === 'task' || file.type === 'client_revision_request_file');
  const otherFiles = fileList.filter(file => file.group === 'other' && file.type !== 'file_qareport_comment' && file.type !== 'client_revision_request_file');

  const finalFiles = fileList.filter(file => file.group === 'paper' && file.type !== 'file_draft_paper');
  const plagFiles = fileList.filter(file => file.group === 'plagreport');
  const draftFiles = fileList.filter(file => file.type === 'file_draft_paper');
  const draftNeeded = order.tags.indexOf('draft_needed') > -1;
  const hasParallelOrder = order.has_paid_parallels;
  const isDuplicateAndRevisionOrder = utils.isDuplicateAndRevisionOrder(order);

  useEffect(() => {
    const orderDetailsClickHandler = ({ target }) => {
      const { tagName = '', src, parentElement } = target;

      if (tagName.toLocaleLowerCase() === 'img') {
        const allImgs = parentElement.getElementsByTagName('img') || [];
        const images = [...allImgs].reduce((prev, next) => {
          const { src: __src } = next;
          if (!prev.includes(__src)) {
            prev.push(__src);
          }

          return prev;
        }, [src]);
        dispatch(addImagesToPreview(images));
      }
    };
    if (!orderDetailsRef || !orderDetailsRef.current) return;

    orderDetailsRef.current.addEventListener('click', orderDetailsClickHandler);

    return () => {
      if (!orderDetailsRef || !orderDetailsRef.current) return;

      orderDetailsRef.current.removeEventListener('click', orderDetailsClickHandler);
    };
  }, [orderDetailsRef]);

  const renderOrderDetails = ({ field, title }) => {
    if (noSensitive || isDuplicateAndRevisionOrder) return null;

    const orderValue = order[field];

    if (!orderValue) return null;

    return (
      <div className="order-tab-detail">
        <TextWithLink text={orderValue} title={title} />
      </div>
    );
  };

  return (
    <div>
      {order && order.jabber_preview_params &&
        <ClientChatPreview order={order} />
      }
      {status === STATUS_REVISION && <RevisionOrderInfo order={order} mobile={mobile} profile={profile} />}
      <OrderDetailInfo order={order} mobile={mobile} groups={GROUPS} />
      <LoginDetail order={order} onResize={onResize} status={status} />
      {!!order.comment &&
        <OrderComment icon="comments" title="Comment from operator" onResize={onResize}>
          {order.comment}
        </OrderComment>
      }
      {!!taskFiles.length &&
        <OrderFileList
          fileList={taskFiles}
          fileWebhook={fileWebhook}
          orderNumber={order.number}
          onResize={onResize}
          setStickyRef={setFilesStickyRef}
          icon="clip"
          title={isDuplicateAndRevisionOrder ? 'Initial order files' : 'Order files'}
          isDownloadableAll
          order={order}
          withCategories
        />
      }
      {!!otherFiles.length && status !== STATUS_OPEN &&
        <OrderFileList
          fileList={otherFiles}
          fileWebhook={fileWebhook}
          orderNumber={order.number}
          onResize={onResize}
          setStickyRef={setOtherFilesStickyRef}
          icon="clip"
          title={isDuplicateAndRevisionOrder ? 'Initial other files' : 'Other files'}
          order={order}
        />
      }

      {status !== STATUS_OPEN &&
        <div className="order-report-issue">
          <Icon className="svg-icon" iconName="question" />
          <p>If you have any questions or clarifications regarding the order instructions, missing files or details, please <a onClick={onOpenIssueForm}>report an issue ASAP</a>. Otherwise, you will be responsible for a failure to meet the initial instructions</p>
        </div>
      }

      {!isDuplicateAndRevisionOrder && (
        <OrderOpenActions
          order={order}
          isStatusLoading={isStatusLoading}
          setReserved={setReserved}
          setRejected={setRejected}
          setAccepted={setAccepted}
          setJoinChat={setJoinChat}
          setRejectJoinChat={setRejectJoinChat}
          status={status}
        />
      )}
      <div className={classnames('upload-btns', { row: !draftFiles.length && !finalFiles.length })}>
        {(status === STATUS_PROGRESS || status === STATUS_REVISION || status === STATUS_COMPLETE) && draftNeeded && !isDuplicateAndRevisionOrder &&
          <OrderDraftPaperList
            order={order}
            fileList={draftFiles}
            onResize={onResize}
            uploadFile={uploadFile}
            removeFile={removeFile}
            status={status}
            hasParallelOrder={hasParallelOrder}
            orderNumber={order.number}
          />
        }
        {(status === STATUS_PROGRESS || status === STATUS_REVISION || status === STATUS_COMPLETE) && !isDuplicateAndRevisionOrder &&
          <OrderFinalPaperList
            order={order}
            fileList={finalFiles}
            plagFileList={plagFiles}
            onResize={onResize}
            status={status}
            orderNumber={order.number}
            hasParallelOrder={hasParallelOrder}
            onOpenIssueForm={onOpenIssueForm}
            uploadOrderFiles={uploadOrderFiles}
          />
        }
      </div>
      {
        !noSensitive && (
          <TopicForm order={order} />
        )
      }

      {ORDER_DETAILS_DATA.map(detail => renderOrderDetails(detail))}

      {
        !noSensitive && (
          <div className="order-tab-detail" ref={orderDetailsRef}>
            <TextWithLink text={order.details} isDuplicateAndRevisionOrder={isDuplicateAndRevisionOrder} title="Full order description" />
          </div>
        )
      }

      {!noSensitive && !!order.additional_comment && status !== STATUS_OPEN && status !== STATUS_COMPLETE &&
        <div className="additional-comment">
          <h3 className="comment-title">Additional order comments for Assigned Writer</h3>
          <p>{order.additional_comment}</p>
        </div>
      }
    </div>
  );
};

export default OrderTabInfo;
