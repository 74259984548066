const QA_TEMPLATE = {
  writing: {
    content: 0.00001,
    format: 0.00001,
    language: 0.00001,
    plagiarism_free: 0.00001,
    sources: 0.00001,
    structure: 0.00001,
    word_count: 0.00001,
  },
  calculations: {
    final_answer: 0.00001,
    explanation: 0.00001,
    formulas: 0.00001,
    language: 0.00001,
    format: 0.00001,
    plagiarism: 0.00001,
  },
  presentation: {
    content: 0.00001,
    plagiarism: 0.00001,
    visual: 0.00001,
    language: 0.00001,
    format: 0.00001,
    slide_count: 0.00001,
    sources: 0.00001,
  },
  editing: {
    content: 0.00001,
    language: 0.00001,
    structure: 0.00001,
    sources: 0.00001,
    format: 0.00001,
    plagiarism: 0.00001,
  },
  proofreading: {
    language: 0.00001,
    format: 0.00001,
  },
  quiz: {
    correct_answers: 0.00001,
  },
  'Revision by new Freelancer': {
    final_answer: 0.00001,
    Explanation: 0.00001,
    Formulas: 0.00001,
    Language: 0.00001,
    Format: 0.00001,
    Content: 0.00001,
    Visual: 0.00001,
    Sources: 0.00001,
    'Slide/word count': 0.00001,
    Structure: 0.00001,
    plagiarism: 0.00001,
  },
};

export default QA_TEMPLATE;
