import { urls } from 'config';
import { addModalComponent } from 'store/modals/actions';

import RestrictedAccessModal from 'components/RestrictedAccessModal';
import modalList from 'constants/modalList';

import { getToken, clearToken } from './tokenStorage';

const authFetch = (url, init, options = { withWrRoll: true }) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (options.withWrRoll) headers['X-Role'] = 'writer';

  const token = getToken();
  if (token) {
    headers.Authorization = token;
  }
  const params = Object.assign({
    headers,
  }, init);

  if (options.withWrRoll) params.credentials = 'include';

  return fetch(url, params).then((r) => {
    if (r.ok) {
      return r.json();
    }
    if (r.status === 401) {
      clearToken();
      const path = `?redirect_url=${Buffer.from(window.location.href).toString('base64')}`;
      window.location = `${urls.login}${path}`;
      return Promise.reject({ status: r.status });
    }

    if (r.status === 403) {
      r.json().then((errorJson) => {
        const { error_message: errorMessage = '' } = errorJson || {};
        window.global_dispatch(addModalComponent({ component: <RestrictedAccessModal texts={errorMessage} />, ...modalList.RestrictedAccess }));
      });
    }

    if (r.status === 405) {
      return Promise.reject({ status: r.status });
    }
    if (r.status === 422) {
      return r.json().then((errorJson) => { errorJson.status = r.status; throw errorJson; });
    }
    throw new Error(`${r.status} ${r.statusText}`);
  });
};

export default authFetch;
