import React, { useMemo, useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';

import { useStateCallback } from 'hooks';
import { urls } from 'config';
import authFetch from 'utils/authFetch';

import formatTime from 'utils/formatTime';
import classNames from 'utils/classNames';
import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';
import convertTimeToGMT from 'utils/convertTimeToGMT';
import timeValidator from 'utils/timeValidator';

import { sessionPost, sessionUpdate } from 'store/clientSessions/actions';

import Calendar from 'components/Calendar';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import InputsSizeTime from 'components/shared/InputsSizeTime';

import 'components/order/EstimationModal/input-block.scss';

const TOOLTIP_TEXT = {
  link: 'Please paste a valid <br/>Google Meets link to <br/>proceed.',
  session_start: 'Please set date <br/>and start time.',
};

const CreateSession = ({ session = {}, toggleModal, order }) => {
  const writer = useSelector(state => state.user);
  const { client_info: { timezone_str: clientTimeZone } } = order;
  const { timezone: writerTimezone, timezone_str: timezoneStr } = writer;

  const [isLoading, toggleLoading] = useState(false);
  const [newSession, updateNewSession] = useState(() => ({ ...{ schedule_at: '', duration: '15', link: '' }, ...session }));
  const [isSomeThingsChanged, setSomeThingsChanged] = useState(false);
  const [withToolTip, setTooltip] = useState(true);
  const [withToolTipText, setTooltipText] = useState('');

  const dispatch = useDispatch();
  const { schedule_at: scheduleAt = '', duration = '', link = '' } = newSession;

  const { 0: hourDur, 1: minutesDur } = convertMinsToHrsMins(duration, true);

  const [durationHours, setEstimateHours] = useStateCallback(hourDur);
  const [durationMin, setEstimateMin] = useStateCallback(minutesDur);

  const linkRef = useRef(null);
  const calendarRef = useRef(null);

  const date = useMemo(() => (scheduleAt ? formatTime(moment(scheduleAt), 'dt') : 'Add date and start time'), [scheduleAt]);
  const isValidForm = useMemo(() => !Object.values(newSession).some(item => typeof item !== 'boolean' && !item) && isSomeThingsChanged, [newSession, isSomeThingsChanged]);
  const clientTimezoneStr = useMemo(() => convertTimeToGMT(clientTimeZone), [clientTimeZone]);
  const writerTimezoneStr = useMemo(() => convertTimeToGMT(timezoneStr), [timezoneStr]);

  const dateValidator = __date => moment(__date).isSameOrAfter(moment(), 'day');

  const onDurationHours = (__value) => {
    const { minutes, hours } = timeValidator(Number(__value), durationMin, (24 * 60), 15, 0, 24, 0, 59);
    const newSizeInMinutes = (hours * 60) + Number(minutes);
    setEstimateHours(hours, () => {
      updateNewSession(__session => ({ ...__session, duration: newSizeInMinutes }));
    });
    setEstimateMin(minutes, () => {
      updateNewSession(__session => ({ ...__session, duration: newSizeInMinutes }));
    });

    setSomeThingsChanged(newSizeInMinutes !== session.duration);
  };

  const onDurationMin = (__value) => {
    const { minutes, hours } = timeValidator(durationHours, Number(__value), (24 * 60), 15, 0, 24, 0, 59);
    const newSizeInMinutes = (hours * 60) + Number(minutes);

    setEstimateMin(minutes, () => {
      updateNewSession(__session => ({ ...__session, duration: newSizeInMinutes }));
    });
    setSomeThingsChanged(newSizeInMinutes !== session.duration);
  };

  const setTooltipData = (__field) => {
    if (__field) {
      setTooltip(true);
      setTooltipText(TOOLTIP_TEXT[__field]);
    } else {
      setTooltip(false);
      setTooltipText('');
    }
  };

  const onDateSubmit = (value, field) => {
    const isBefore = value.isBefore(moment().subtract(6, 'minutes'));

    if (isBefore) {
      updateNewSession(__session => ({ ...__session, schedule_at: '' }));
      return;
    }

    updateNewSession(__session => ({ ...__session, schedule_at: value.toISOString() }));
    calendarRef.current.setClose();
    setSomeThingsChanged(value.toISOString() !== session.schedule_at);
  };

  const clearLink = () => {
    linkRef.current.value = '';
    updateNewSession(__session => ({ ...__session, link: '' }));
  };

  const onGoogleMeetLink = (e) => {
    const { target: { value = '' } } = e;

    const valueLinkEnd = value.split('meet.google.com/')[1];

    if (!valueLinkEnd) {
      setTooltipData('link');
      clearLink();
      return;
    }

    const linkEnd = valueLinkEnd.split(' ');

    if (!linkEnd) {
      setTooltipData('link');
      clearLink();
      return;
    }

    const parsedLink = `https://meet.google.com/${linkEnd[0]}`;

    linkRef.current.value = parsedLink;

    updateNewSession(__session => ({ ...__session, link: parsedLink }));
    setTooltip(false);
    setTooltipText('');
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isValidForm || isLoading) return;

    toggleLoading(true);

    const action = newSession._id ? sessionUpdate : sessionPost;

    dispatch(action({ ...newSession, ...{ order_id: order._id, duration: Number(newSession.duration), is_updated: !!newSession._id } }))
      .then(() => {
        toggleModal();
      })
      .catch(error => console.error(error))
      .finally(() => {
        toggleLoading(true);
      });

    authFetch(urls.updateOrder(order._id), {
      method: 'POST',
      body: JSON.stringify({
        action: 'set_chat_progress_state',
        accept: true,
      }),
    });
  };

  useEffect(() => {
    if (!scheduleAt) {
      return setTooltipData('session_start');
    }
    if (!link) {
      return setTooltipData('link');
    }
    return setTooltipData();
  }, [scheduleAt, link]);

  useEffect(() => {
    linkRef.current.value = link;
  }, []);

  return (
    <>
      <button className="btn btn-close modal-close" type="button" onClick={toggleModal}><Icon className="svg-icon" iconName="close" /></button>
      <h2 className="session-details-modal--title">Schedule a tutoring session</h2>

      <form className="session-details-modal-form">
        <div className="row mb15 vertical">
          <div className="session-details-modal-form__subtitle">Call will last</div>
          <div className="session-details-modal-form__range">
            <InputsSizeTime
              hours={durationHours}
              minutes={durationMin}
              onHours={onDurationHours}
              onMinutes={onDurationMin}
            />
          </div>
        </div>

        <div className="session-details-modal-form__note">
          <i className="session-details-modal-form__note__icon" />
          <p className="session-details-modal-form__note__text">By default is chosen the soonest possible time to start a call.</p>
        </div>

        <div className="session-details-modal-form__subtitle mb10">Session will start at:</div>
        <div className="row mb8 vertical session-details-modal-form__border">
          <div className="session-details-modal-form__field">
            Your time and date {writerTimezoneStr}
          </div>
          <div className="session-details-modal-form__value">
            <div className={classNames('session-details-modal-form-calendar session-details-modal-form__item', { 'session-details-modal-form__item--no-value': !scheduleAt })}>
              <Dropdown buttonClassName="lnk-dropdown" text={date} hideArrow isLink ref={calendarRef}>
                <Calendar onSubmit={onDateSubmit} dateValidator={dateValidator} chosenDate={scheduleAt ? moment(scheduleAt) : moment()} name="by_created_at" ntOptions={{ clientTimeZone, writerTimezone }} />
              </Dropdown>
            </div>
          </div>
        </div>

        {scheduleAt &&
          <div className="row mb12 vertical session-details-modal-form__border">
            <div className="session-details-modal-form__field">
              Student's time and date {clientTimezoneStr}
            </div>
            <div className="session-details-modal-form__value">
              {formatTime(moment(scheduleAt).tz(clientTimeZone), 'dt')}
            </div>
          </div>
        }


        <div className="session-details-modal-form__label">Please paste Google Meets link here:</div>

        <div className="session-details-modal-form row vertical column_gap_12 mb15">
          <input
            className="input-text session-details-modal-form__meet-input"
            type="text"
            placeholder="Add Google Meet link"
            onBlur={onGoogleMeetLink}
            ref={linkRef}
            tabIndex="-1"
          />
          <a href="https://meet.google.com/" target="_blank" rel="noopener noreferrer">Create a new link</a>
        </div>

        <div className="session-details-modal-form__btn-wrap">
          {
            withToolTip
              ? (<Tooltip className="btn-tooltip" isHtml content={withToolTipText}><button className={classNames('btn btn-bright tn-block', { 'disabled-only-opacity': !isValidForm || isLoading })} onClick={onSubmitForm}>Send</button></Tooltip>)
              : (<button className={classNames('btn btn-bright tn-block', { 'disabled-only-opacity': !isValidForm || isLoading })} onClick={onSubmitForm}>Send</button>)
          }
        </div>
      </form>
    </>
  );
};

export default CreateSession;
