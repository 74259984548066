
const createAudioNotification = () => {
  const sound = document.createElement('audio');
  sound.id = 'audio_nt_notice';
  sound.controls = 'controls';
  sound.src = '/media/nt_new_message.ogg';
  sound.type = 'audio/mpeg';
  document.querySelector('#root').appendChild(sound);
  const noticeSym = Symbol('audio_nt_notice');
  window[noticeSym] = document.querySelector('#audio_nt_notice');
};

export default createAudioNotification;
