import React, { useState, useEffect } from 'react';

const useMobile = (props = {}) => {
  const { width = 1023 } = props;
  const mediaQuery =
    typeof window !== 'undefined' &&
    window.matchMedia(`(max-width: ${width}px)`);
  const [isMobile, setIsMobile] = useState(mediaQuery && mediaQuery.matches);
  const userAgent =
    typeof window !== 'undefined' && navigator.userAgent.toLowerCase();
  const isAndroid = /android/.test(userAgent);
  const isIos = /ipad|iphone|ipod/.test(userAgent);
  useEffect(
    () => {
      const detectMobile = (e) => {
        setIsMobile(e.matches);
      };
      mediaQuery.addListener(detectMobile);
      detectMobile(mediaQuery);
      return () => {
        mediaQuery.removeListener(detectMobile);
      };
    },
    [mediaQuery],
  );
  return { isMobile, isAndroid, isIos };
};

export default useMobile;
