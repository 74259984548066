import React from 'react';
import Icon from 'components/Icon';
import { STATUS_TICKET } from 'components/WriterTicketing/constants/writerTicketing';

const ModalHeader = ({ status, ticket, orderNumber, onClose }) => {

  const renderTitleText = () => {
    const { info = null } = ticket || {};
    const { resolve_action: resolveAction = {} } = info || {};
    if (!resolveAction.action) return (<span className={`title-status ${STATUS_TICKET[status]}`}>{status}</span>);

    if (resolveAction.action === 'no_penalties') return (<span className={`title-status ${STATUS_TICKET[status]}`}>Rejected without penalties</span>);

    return (<span className={`title-status ${STATUS_TICKET[status]}`}>Penalties applied</span>);
  };

  const renderTitle = () => {
    if (!status) {
      return (
        <h1 className="title">
          Report an issue
        </h1>
      );
    }

    return (
      <h1 className="title">
        {renderTitleText()}
        {orderNumber &&
          <span className="subtitle">({orderNumber})</span>
        }
      </h1>
    );
  };

  return (
    <div className="modal-header">
      {ticket &&
        <button className="btn btn-close" type="button" onClick={() => onClose()}><Icon className="svg-icon" iconName="close" /></button>
      }
      {renderTitle()}
    </div>
  );
};

export default ModalHeader;
