import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import classnames from 'classnames';

const QACollapseItem = ({ title, children, isError }) => {

  const [isOpen, setToggleOpen] = useState(false);

  const onToggleOpen = () => {
    setToggleOpen(!isOpen);
  };

  const arrowClassName = classnames('arrow-down', { active: isOpen });
  const className = classnames('person-info-item', { 'is-error': isError }, { active: isOpen });

  return (
    <section className={className}>
      <div onClick={onToggleOpen}>
        <h3>{title}</h3>
        <span className={arrowClassName} />
      </div>
      <Collapse isOpened={isOpen}>
        {children}
      </Collapse>
    </section>
  );
};

export default QACollapseItem;
