import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { usePrevious } from 'hooks';

import { ordersFindFetchIfNeeded } from 'store/ordersFind/actions';
import { setFilteredOrderList } from 'store/ordersFilteredFind/actions';

import { getOrdersFilteredSorted } from 'store/ordersFind/selectors';
import { getProfile } from 'store/user/selectors';

import { StickyContainer, StickyHead, StickyBody } from 'components/Sticky';

import OrdersTableHeader from './OrdersTableHeader';
import OrdersTableBody from './OrdersTableBody';

const OrdersTable = ({ typeId, selectedNumber, onSelect }) => {
  const [isSearch, toggleIsSearch] = useState(false);

  const orderList = useSelector(state => getOrdersFilteredSorted[typeId](state)) || [];
  const { numbers = [], sorting, isFetching: isLoading } = useSelector(state => (state.ordersFind || {}));
  const profile = useSelector(getProfile) || {};
  const prevOrderList = usePrevious(orderList);

  const dispatch = useDispatch();

  useEffect(() => {
    if (prevOrderList && prevOrderList.length !== orderList.length) {
      dispatch(setFilteredOrderList(orderList, typeId));
    }
  }, [prevOrderList, orderList]);

  useEffect(() => {
    dispatch(ordersFindFetchIfNeeded());
  }, []);

  return (
    <div className="table orders-table">
      <StickyContainer className="sticky-table" isLoading={isLoading}>
        <StickyHead className="sticky-table-head" classNameFixed="sticky-table-head-fixed">
          <OrdersTableHeader
            profile={profile}
            isSearch={isSearch}
            onSearchChange={toggleIsSearch}
            sorting={sorting}
            typeId={typeId}
          />
        </StickyHead>
        <StickyBody className="sticky-table-body">
          <OrdersTableBody
            profile={profile}
            isSearch={isSearch}
            typeId={typeId}
            isLoading={isLoading}
            selectedNumber={selectedNumber}
            onSelect={onSelect}
            orderList={orderList}
            allOrders={numbers}
          />
        </StickyBody>
      </StickyContainer>
    </div>
  );
};

export default OrdersTable;
