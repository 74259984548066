import React, { useMemo } from 'react';
import classnames from 'utils/classNames';

const OtherFirstThreeRow = ({ data }) => {
  const ellipsisStreet = useMemo(() => ((data.address?.street || '').length < 4 ? (data.address?.street || '') : data.address.street.slice(0, 3) + new Array(data.address.street.length - 3).fill('*').join('')), [data.address?.street]);
  const ellipsisBuildingNumber = useMemo(() => `${new Array((data.address?.building_number || '').length).fill('*').join('')}`, [data.address?.building_number]);
  return (
    <>
      <div className="row row-devider">
        <div className="col-2">
          <h2 className="order-tab-body__title">Name</h2>
          {data.public_name && data.public_initial &&
          <p className="order-tab-body__desc ellipsis">
            {`${data.public_name} ${data.public_initial}`}
          </p>
        }
          {(!data.public_name || !data.public_initial) &&
          <p className="order-tab-body__desc ellipsis danger">Not specified</p>
        }
        </div>
      </div>
      <div className="row row-devider">
        <div className="col-2">
          <h2 className="order-tab-body__title">Country</h2>
          <p className={classnames('order-tab-body__desc ellipsis ellipsis-narrow', { danger: !data.address?.country })}>{data.address?.country || 'Not specified'}</p>
        </div>
        <div className="col-2">
          <h2 className="order-tab-body__title">City</h2>
          <p className={classnames('order-tab-body__desc ellipsis ellipsis-narrow', { danger: !data.address?.city })}>{data.address?.city || 'Not specified'}</p>
        </div>
        <div className="col-2">
          <h2 className="order-tab-body__title">Street</h2>
          <p className={classnames('order-tab-body__desc ellipsis ellipsis-narrow', { danger: !data.address?.street })}>{ellipsisStreet || 'Not specified'}</p>
        </div>
        <div className="col-2">
          <h2 className="order-tab-body__title">Building number</h2>
          <p className={classnames('order-tab-body__desc ellipsis ellipsis-narrow', { danger: !data.address?.building_number })}>{ellipsisBuildingNumber || 'Not specified'}</p>
        </div>
      </div>
      <div className="row row-devider">
        <div className="col-2 col2-100-110">
          <h2 className="order-tab-body__title">Major</h2>
          <p className={classnames('order-tab-body__desc ellipsis ellipsis-wide', { danger: !data.major })}>{data.major || 'Not specified'}</p>
        </div>
        <div className="col-2 width0">
          <h2 className="order-tab-body__title">Degree</h2>
          <p className={classnames('order-tab-body__desc ellipsis', { danger: !data.public_degree })}>{data.public_degree || 'Not specified'}</p>
        </div>
      </div>
    </>
  );
};

export default OtherFirstThreeRow;
