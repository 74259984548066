import React from 'react';

import classNames from './../../utils/classNames';

const WarningTableSortingHead = (props) => {
  const {
    sorting,
    setSorting,
    field,
    children,
  } = props;
  const sortingIcon = field !== sorting.field
    ? <span className="full-arrow full-arrow-down" />
    : <span className={classNames('full-arrow', { 'full-arrow-down': !sorting.isAsc }, { 'full-arrow-up': sorting.isAsc })} />
  return (
    <a onClick={() => setSorting(field)}>{children} {sortingIcon}</a>
  );
};

export default WarningTableSortingHead;
