import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { urls } from 'config';

import authFetch from 'utils/authFetch';
import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';
import classNames from 'utils/classNames';

import { ordersDetailsFetch } from 'store/orders/actions';

import Modal from 'components/Modal';
import Icon from 'components/Icon';

import ReservedCounter from 'components/ReservedCounter';

import timeConstants from 'constants/time';

export const EstimationCounter = ({ diff, format, classes }) => {
  const { ONE_MINUTE, ONE_HOUR } = timeConstants;
  let formatView = '';

  const diffDuration = moment.duration(diff);
  const months = diffDuration.months();
  const days = diffDuration.days();
  const diffDay = days > 0 && months <= 0 ? `${days} day(s) ` : months > 0 ? `${diffDuration.asDays() | 0} day(s)` : '';

  if (days > 0) {
    formatView = 'HH [hour(s)]';
  }

  if (days === 0 && diff > ONE_HOUR) {
    formatView = format || 'HH [hour(s)] mm [minutes]';
  }

  if (days === 0 && diff < ONE_HOUR && diff > ONE_MINUTE) {
    formatView = 'mm [minute(s)] ss [seconds]';
  }

  if (days === 0 && diff < ONE_HOUR && diff < ONE_MINUTE) {
    formatView = 'ss [seconds]';
  }

  const text = diff <= 0 ? '0:00' : `${diffDay} ${months <= 0 ? moment.utc(diff).format(formatView) : ''}`;
  const className = classNames(classes, { 'text-warning': diff <= ONE_MINUTE });
  return (
    <div className={classNames('centered', className)}>{text}</div>
  );
};

const ViewMode = ({
  order, estimate, onEditModeClickCallback,
}) => {
  const {
    size = '', writer_price: writerPrice, _id: orderId, number: orderNumber,
  } = order;
  const {
    size: estimateSize, expired_at: expireEstimateTime, price: estimatePrice, comment, _id: idEst,
  } = estimate;

  const [isOpen, toggleOpen] = useState(false);

  const dispatch = useDispatch();

  const onToggleOpen = (withFetch) => {
    if (withFetch === true) {
      setTimeout(() => {
        dispatch(ordersDetailsFetch(orderNumber));
      }, 1);
    }

    toggleOpen(!isOpen);
  };


  const [__size, type] = size.split(' ');

  const { 0: hours, 1: minutes } = convertMinsToHrsMins(__size, true);

  const [__sizeEs, __type] = estimateSize.split(' ');

  const { 0: hoursEst, 1: minutesEs } = convertMinsToHrsMins(__sizeEs, true);

  const onCancelEstimate = () => {
    authFetch(urls.orderEstimate(orderId), {
      method: 'POST',
      body: JSON.stringify({
        action: 'cancel',
        _id: idEst,
      }),
    })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => onToggleOpen(true));
  };

  const onEditModeClick = () => {
    onToggleOpen();
    onEditModeClickCallback();
  };

  const onCountDownOver = () => {
    dispatch(ordersDetailsFetch(orderNumber));
  };


  return (
    <>
      <div className="pos-relative mt20">
        <button className="pos-relative btn" onClick={onToggleOpen}>View my estimate</button>
        <div className="estimation-order-countdown">
          Valid for next:
          <ReservedCounter time={expireEstimateTime} onOver={onCountDownOver}>
            <EstimationCounter />
          </ReservedCounter>
        </div>
      </div>
      {
        isOpen && (
          <Modal>
            <div className="estimation-modal-container">
              <button className="btn btn-close modal-close" type="button" onClick={onToggleOpen}><Icon className="svg-icon" iconName="close" /></button>
              <h2 className="centered estimation-modal-container__header">View my estimate</h2>
              <div className="info-block">
                <div className="info-block-size">
                  <div className="info-block-size__label">Current order size:</div>
                  <div className="info-block-size__value">{`${hours} hour(s) ${minutes} min`}</div>
                </div>
                <div className="info-block__hint">{`My current estimated earnings: $${writerPrice}`}</div>
              </div>
              <div className="info-block">
                <div className="info-block-size">
                  <div className="info-block-size__label">Time i need to complete the order:</div>
                  <div className="info-block-size__value">{`${hoursEst} hour(s) ${minutesEs} min`}</div>
                </div>
                <div className="info-block__hint">{`Earnings if the student accepts my new estimate: $${estimatePrice}`}</div>
              </div>
              <div className="info-block">
                <div className="info-block-size__label">Comments:</div>
                <p className="overflow-wrap pre-wrap">{comment}</p>
              </div>
              <div className="info-block">
                <div className="info-block-size__label">Time left for student to accept this estimate:</div>
                <ReservedCounter time={expireEstimateTime}>
                  <EstimationCounter format="HH [hour(s)] mm [minutes] ss [seconds]" />
                </ReservedCounter>
              </div>
              <div className="estimation-btns-block">
                <button className="estimation-modal-btn btn mb12" onClick={onCancelEstimate}>Cancel this order estimate</button>
                <button className="estimation-modal-btn btn mb12" onClick={onEditModeClick}>Edit details to provide a new estimate</button>
                <button className="estimation-modal-btn btn mb12" onClick={onToggleOpen}>Close and return to the order details</button>
              </div>
            </div>
          </Modal>
        )
      }
    </>
  );
};

export default ViewMode;
