import { urls } from 'config';
import authFetch from 'utils/authFetch';

import { ordersDetailsReceive, orderDetailsStatusError, orderDetailsError } from 'store/orders/actions';

export const ORDER_SINGLE_REQUEST = 'ORDER_SINGLE_REQUEST';
export const ORDER_SINGLE_RECEIVE = 'ORDER_SINGLE_RECEIVE';
export const ORDER_SINGLE_ERROR = 'ORDER_SINGLE_ERROR';

const orderSingleRequest = () => ({
  type: ORDER_SINGLE_REQUEST,
});

const orderSingleReceive = number => ({
  type: ORDER_SINGLE_RECEIVE,
  number,
});

const orderSingleError = () => ({
  type: ORDER_SINGLE_ERROR,
});

export const orderSingleFetch = id => (dispatch, getState) => {
  dispatch(orderSingleRequest());
  return new Promise((resolve, reject) => {
    authFetch(`${urls.orders}/${id}`).then((json) => {
      dispatch(ordersDetailsReceive(json.number, json));
      dispatch(orderSingleReceive(json.number));
      resolve(json);
    }).catch((error) => {
      dispatch(orderSingleError());
      reject(error);
    });
  });
};

export const orderSingleUpdate = (number, data) => (dispatch, getState) => {
  const id = getState().orders[number]._id;
  return authFetch(`${urls.orders}/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  })
    .then(response => dispatch(ordersDetailsReceive(response.number, response)))
    .catch((error) => {
      console.log(error);
      if (error.status === 405 || error.status === 422) {
        dispatch(orderDetailsStatusError(number, error.error_message));
        return;
      }
      dispatch(orderDetailsError(number));
    });
};
