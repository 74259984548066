import React from 'react';
import classnames from 'utils/classNames';

const DeFirstThreeRow = ({ data }) => (
  <>
    <div className="row row-devider">
      <div className="col-2">
        <h2 className="order-tab-body__title">Name</h2>

        {data.public_name && data.public_initial &&
          <p className="order-tab-body__desc ellipsis">
            {`${data.public_name} ${data.public_initial}`}
          </p>
                  }
        {(!data.public_name || !data.public_initial) &&
          <p className="order-tab-body__desc ellipsis danger">Not specified</p>
                  }
      </div>

      <div className="col-2">
        <h2 className="order-tab-body__title">Country</h2>
        <p className={classnames('order-tab-body__desc ellipsis', { danger: !data.country })}>{data.country || 'Not specified'}</p>
      </div>

      <div className="col-2">
        <h2 className="order-tab-body__title">Degree</h2>
        <p className={classnames('order-tab-body__desc ellipsis', { danger: !data.public_degree })}>{data.public_degree || 'Not specified'}</p>
      </div>
    </div>
    <div className="row row-devider">
      <div className="col-2">
        <h2 className="order-tab-body__title">Major</h2>
        <p className={classnames('order-tab-body__desc ellipsis ellipsis-wide', { danger: !data.major })}>{data.major || 'Not specified'}</p>
      </div>
    </div>
  </>
);

export default DeFirstThreeRow;
