import React from 'react';

import classNames from './../utils/classNames';

const Nav = props => {
    const className = classNames('nav', { 'nav-sub': props.sub, 'nav-sub--top': (props.sub && props.subTop) });
    return (
        <nav className={className}>
            <div className="container">
                <ul>
                    {React.Children.map(props.children, (child, idx) => <li key={idx}>{child}</li>)}
                </ul>
            </div>
        </nav>
    );
};

export default Nav;
