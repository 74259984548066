import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useSlider } from 'hooks';

import ImageZoom from 'components/ImageZoom';

import Icon from 'components/Icon';

import { getPreviewImages } from 'store/previewImages/selectors';
import { clearPreview } from 'store/previewImages/actions';

import './index.scss';

const ImagePreview = () => {
  const previewImages = useSelector(getPreviewImages);

  const dispatch = useDispatch();

  const slideImageContainer = useRef(null);
  const { goToPreviousSlide, goToNextSlide, currentImg } = useSlider(previewImages, slideImageContainer);

  if (previewImages.length === 0) return null;

  const clearPreviewHandler = () => {
    dispatch(clearPreview());
  };

  return (
    <div className="preview-slider">
      <button className="btn btn-close" type="button" onClick={clearPreviewHandler}>
        <Icon className="svg-icon" iconName="close" />
      </button>
      {
        previewImages.length > 1 && (
        <button onClick={goToPreviousSlide} className="preview-slider__btn">
          <i className="preview-slider__btn__left" />
        </button>
        )
      }
      <div ref={slideImageContainer} className="preview-slider__img-container">
        <ImageZoom imgProps={{ src: currentImg || previewImages[0] }} toolsClass="preview-slider__zoom-tools" />
      </div>
      {
        previewImages.length > 1 && (
        <button onClick={goToNextSlide} className="preview-slider__btn">
          <i className="preview-slider__btn__right" />
        </button>
        )
      }
    </div>
  );
};

export default ImagePreview;
