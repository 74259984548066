/* eslint-disable consistent-return */
import shortid from 'shortid';

import { urls } from 'config';

import authFetch from 'utils/authFetch';
import uploadFetchAmazon from 'utils/uploadFetchAmazon';
import uploadUtils from 'utils/uploadFetch';

import { orderDetailsError, ordersDetailsReceive } from './details';

import {
  ORDERS_FILES_REQUEST,
  ORDERS_FILES_RECEIVE,

  ORDERS_FILES_UPLOAD_REQUEST,
  ORDERS_FILES_UPLOAD_PROGRESS,
  ORDERS_FILES_UPLOAD_COMPLETE,
  ORDERS_FILES_UPLOAD_RECEIVE,
  ORDERS_FILES_UPLOAD_ERROR,

  ORDERS_FILES_REMOVE_REQUEST,
  ORDERS_FILES_REMOVE_RECEIVE,
} from '../constants';

const getFileTypeByGroup = (group) => {
  let type = '';

  switch (group) {
    case 'message':
      type = 'msgs';
      break;
    case 'file_draft_paper':
      type = group;
      break;
    default:
      type = 'final_draft';
      break;
  }

  return type;
};

const mapFilesRequest = (json) => {
  const files = json.results.reduce((_files, file) => {
    _files[file._id] = file;
    return _files;
  }, {});
  const webhook = json.wh;

  return { files, webhook };
};


const ordersFilesRequest = number => ({
  type: ORDERS_FILES_REQUEST,
  number,
});

const ordersFilesReceive = (number, files, webhook) => ({
  type: ORDERS_FILES_RECEIVE,
  number,
  files,
  webhook,
});

const ordersFilesUploadRequest = (number, fileId, fileName, group, abort) => ({
  type: ORDERS_FILES_UPLOAD_REQUEST,
  number,
  fileId,
  fileName,
  group,
  abort,
});

const ordersFilesUploadProgress = (number, fileId, progress) => ({
  type: ORDERS_FILES_UPLOAD_PROGRESS,
  number,
  fileId,
  progress,
});

const ordersFilesUploadComplete = (number, fileId) => ({
  type: ORDERS_FILES_UPLOAD_COMPLETE,
  number,
  fileId,
});

const ordersFilesUploadReceive = (number, fileId, data) => ({
  type: ORDERS_FILES_UPLOAD_RECEIVE,
  number,
  fileId,
  data,
});

const ordersFilesUploadError = (number, fileId) => ({
  type: ORDERS_FILES_UPLOAD_ERROR,
  number,
  fileId,
});

const ordersFilesRemoveRequest = (number, fileId) => ({
  type: ORDERS_FILES_REMOVE_REQUEST,
  number,
  fileId,
});

const ordersFilesRemoveReceive = (number, fileId) => ({
  type: ORDERS_FILES_REMOVE_RECEIVE,
  number,
  fileId,
});

const ordersFilesFetch = (number, orderId) => (dispatch) => {
  dispatch(ordersFilesRequest(number));
  return authFetch(`${urls.orders}/${orderId}/files`).then((json) => {
    const { files, webhook } = mapFilesRequest(json);
    dispatch(ordersFilesReceive(number, files, webhook));
  }).catch((error) => {
    console.log(error);
    dispatch(orderDetailsError(number));
  });
};

const ordersFilesShouldFetch = (number, state) => {
  const order = state.orders[number];
  if (!order || !order.files) return true;

  if (order.files.isFetching) {
    return false;
  }
  return order.files.didInvalidate;
};


export const ordersFilesFetchIfNeeded = (number, orderId) => (dispatch, getState) => {
  if (ordersFilesShouldFetch(number, getState())) {
    return dispatch(ordersFilesFetch(number, orderId));
  }
};

export const orderFileUploadFetch = (number, file, group, fileId, tags = []) => (dispatch, getState) => {
  fileId = fileId || `wt502fy0/${number}/${shortid.generate()}/${uploadUtils.recursiveDecodeURIComponent(file.name)}`;
  const xhr = new XMLHttpRequest();
  dispatch(ordersFilesUploadRequest(number, fileId, file.name, group, () => xhr.abort()));
  return uploadFetchAmazon(fileId, file, percent => dispatch(ordersFilesUploadProgress(number, fileId, percent)), xhr).then((fileUrl) => {
    dispatch(ordersFilesUploadComplete(number, fileId));
    const order = getState().orders[number] || {};
    const { _id: orderId } = order;
    if (!orderId) {
      console.error('Order is not found');
      return;
    }
    const type = getFileTypeByGroup(group);

    return authFetch(`${urls.orders}/${orderId}/files`, {
      method: 'POST',
      body: JSON.stringify({
        file: file.name,
        location: fileUrl,
        size: file.size,
        type,
        tags,
      }),
    });
  }).then((json) => {
    dispatch(ordersFilesUploadReceive(number, fileId, json));
  }).catch((error) => {
    console.log(error);
    dispatch(ordersFilesUploadError(number, fileId));
  });
};

export const orderFilesUploadFetch = (number, files = [], group, tags = []) => (dispatch, getState) => {
  const order = getState().orders[number] || {};
  const { _id: orderId } = order;

  if (!orderId) {
    console.error('Order is not found');
    return;
  }

  const type = getFileTypeByGroup(group);

  dispatch(ordersFilesRequest(number));

  return authFetch(`${urls.orders}/${orderId}/files`, {
    method: 'POST',
    body: JSON.stringify({
      order_action: 'submit_order_files',
      files: files.map(file => ({ ...file, ...{ type, tags } })),
    }),
  }).then((json) => {
    const { files: __files, webhook } = mapFilesRequest(json);
    const allFiles = { ...__files, ...(order.files.items || {}) };
    dispatch(ordersDetailsReceive(number, {
      ...order,
      tags: [...(order.tags || []), 'submit_order_files'],
      files: { ...order.files, items: allFiles, webhook },
    }));
  }).catch((error) => {
    console.log(error);
    dispatch(orderDetailsError(number));
  });
};

export const ordersFilesRemoveFetch = (number, fileId) => (dispatch, getState) => {
  const order = getState().orders[number] || {};
  const { _id: orderId } = order;

  if (!orderId) {
    console.error('Order is not found');
    return;
  }
  const file = order.files.items[fileId];
  if (file.isUploading) {
    file.abort();
    dispatch(ordersFilesRemoveReceive(number, fileId));
    return;
  }
  dispatch(ordersFilesRemoveRequest(number, fileId));
  return authFetch(`${urls.orders}/${orderId}/files/${fileId}`, {
    method: 'POST',
    body: JSON.stringify({ action: 'remove' }),
  }).catch((error) => {
    console.log(error);
    dispatch(ordersFilesRemoveReceive(number, fileId));
  }).then(() => dispatch(ordersFilesRemoveReceive(number, fileId)));
};
