import React from 'react';
import { useDispatch } from 'react-redux';

import {
  ChatMessageBox,
} from '@connect/connect-ui-js';
import { useMessageBox, useConnectContext } from '@connect/connect-xmpp';

import { AudioButton } from '@connect/connect-audio-message-plugin';

import { trackValues } from 'lrtracker';

import { urls } from 'config';

import modalList from 'constants/modalList';

import classNames from 'utils/classNames';
import authFetch from 'utils/authFetch';

import { closeModalComponent, addModalComponent } from 'store/modals/actions';
import { STATUS_OPEN } from 'store/orders/actions';

import TemplateModal from 'components/TemplateModal';
import { getStatus } from 'components/order/utils';

import { theme } from 'components/order/OrderClientChat/theme';


import './index.scss';

const MessageBox = ({
  standalone = false, mobile = false, profile, order = {}, parentRef,
}) => {
  const dispatch = useDispatch();

  const {
    jabber_node: node,
    jabber_server: to,
    writer_chat_message_at: writerChatMessageAt,
    _id: orderId,
  } = order;
  const { public_name: tutorName, jabber_jid: username } = profile;

  const clientChatConfig = {
    username,
    node,
    to,
  };
  const {
    message,
    setMessage,
    uploadFiles,
    submit,
    data,
    reset,
  } = useMessageBox(clientChatConfig);

  const { send } = useConnectContext();

  const goToBottom = () => {
    setTimeout(() => {
      if (standalone) {
        document.documentElement.scrollTo(0, document.documentElement.scrollHeight + 50);
        return;
      }

      parentRef.scrollTo(0, parentRef.scrollHeight);
    }, 100);
  };

  const onAttachPress = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      file.path = URL.createObjectURL(file);
    });

    uploadFiles(files);
    parentRef && goToBottom();
  };

  const onUploadAudio = (__data = {}) => {
    const { audioData, file, path } = __data;
    file.audioData = audioData;
    file.path = path;
    file.uri = path;
    uploadFiles([file]);
    parentRef && goToBottom();
  };

  const onSubmitHandler = (...args) => {
    submit(...args);
    parentRef && goToBottom();
  };

  const onSubmitTemplate = (text = '') => {
    dispatch(closeModalComponent(modalList.Templates.name));
    const trimmedMessage = text.trim();

    if (!trimmedMessage) return;

    authFetch(urls.onChatMessage(orderId), {
      method: 'POST',
      body: '',
    }).then(() => {
      trackValues('stage', 'tutorIntroductionCompleted', { context: { stage: 'ClientTutorChat', order_id: orderId } });
      setTimeout(() => {
        send({ variables: clientChatConfig, message: trimmedMessage.replaceAll('<br />', ' ') });
      }, 1000);
    });
    authFetch(urls.updateOrder(orderId), {
      method: 'POST',
      body: JSON.stringify({
        action: 'set_chat_progress_state',
        introduction: true,
      }),
    });
  };

  const onFocus = () => {
    if (writerChatMessageAt) return;

    const dataModal = modalList.Templates;

    dispatch(addModalComponent({
      component: <TemplateModal
        textReplaceHandler={text => text.replace('[TutorName]', tutorName)}
        onSubmit={onSubmitTemplate}
        onClose={() => dispatch(closeModalComponent(modalList.Templates.name))}
      />,
      ...dataModal,
    }));
  };

  const renderAudioRecord = ({ onUploadAudio: _onUploadAudio, ...rest }) => (
    <AudioButton theme={theme} onUploadAudio={_onUploadAudio(onUploadAudio)} {...rest} />
  );

  return (
    <div className={classNames('client-chat-message-box', { 'client-chat-message-box--standalone': standalone && !mobile })}>
      <ChatMessageBox
        value={message}
        setValue={setMessage}
        onSubmit={onSubmitHandler}
        onReset={reset}
        onFocus={onFocus}
        data={data}
        onAttachPress={onAttachPress}
        onUploadAudio={onUploadAudio}
        isTutorWriteMsg={Boolean(writerChatMessageAt) || getStatus(order) !== STATUS_OPEN}
        renderAudioRecord={renderAudioRecord}
      />
    </div>
  );
};

export default MessageBox;
