import moment from 'moment-timezone';

import utils from 'utils';
import { getToken } from 'utils/tokenStorage';

import { urls as configUrls } from 'config';

const downloadFile = (url, name) => {
  window.open(url, '_blank');
};

const onDownloadAll = ({
  fileList, fileWebhook, orderNumber = 'archive', selectedFiles,
}) => {
  const urls = [];
  const filesIds = [];
  fileList.filter((_file) => { if (selectedFiles.length) return selectedFiles.includes(_file._id); return _file; }).forEach((file) => {
    urls.push(file.real_location);
    filesIds.push(file._id);
  });
  const filesUrl = `${configUrls.fileServerUrl}/api/v1/files/download_all`;
  const token = getToken();
  const archiveTitle = `${orderNumber}_${moment().format('DMYY_HHmm')}`;
  const isMobile = utils.detectMob();
  
  let windowReference = null;

  if (isMobile) windowReference = window.open();

  return fetch(filesUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    method: 'POST',
    body: JSON.stringify({
      urls,
      webhook_url: fileWebhook,
      file_name: archiveTitle,
      info: { ids: filesIds },
    }),
  })
    .then((res) => { if (res.status === 201) return res.json(); })
    .then((res) => {
      if (res && res.link) {
        if (isMobile) {
          windowReference.location = `${configUrls.fileServerUrl}${res.link}`;
        } else {
          window.open(`${configUrls.fileServerUrl}${res.link}`, '_blank');
        }
      }
    })
    .catch((error) => {
      console.log(`There has been a problem with your fetch operation: ' ${error.message}`);
    });
};

export { onDownloadAll, downloadFile };
