import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import Modal from 'components/Modal';
import Icon from 'components/Icon';

import { userSetShowProposed, userActionFetch } from 'store/user/actions';
import { deleteAllModalComponent } from 'store/modals/actions';

import getOrdersProposed from 'store/ordersProposed/selectors';

import storage from 'utils/localStorageHandler';
import utils from 'utils';
import { isNewbie } from 'utils/groups';

import ProposedOrder from './ProposedOrder';
import ProposedModalBefore from './ProposedModalBefore';

import './styles/index.scss';

const MAX_ORDERS = 6;
const ProposedOrdersModal = ({ isMobile = false, openPlace = null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const user = useSelector(state => state.user);
  const memoizedGetOrdersProposed = useMemo(() => state => getOrdersProposed(state), [
    getOrdersProposed,
  ]);
  const orderList = useSelector(state => memoizedGetOrdersProposed(state));
  const [page, setPage] = useState(0);
  const [isShowBeforeModal, showBeforeModal] = useState(false);
  const [isSendingEvent, setSendingEvent] = useState(false);
  const [selectOrder, setSelectOrder] = useState(params.orderNumber);

  const { showAgreementWizard, showProposed = false, proposedOpenPlace = null } = user;
  const __orderList = orderList.slice(page * MAX_ORDERS, MAX_ORDERS + (page * MAX_ORDERS));
  const group = (user && user.qc_group.toLowerCase());
  const isDe = utils.isDe(user);
  const isNewbieGroup = isNewbie(group);

  const onClose = useCallback(
    () => {
      dispatch(userSetShowProposed(!showProposed));
    },
    [showProposed],
  );

  const localStorageUpdated = () => {
    if (isDe || isNewbieGroup) return;
    const userViewedCount = storage.get('userViewedCount') || 0;
    showBeforeModal(userViewedCount >= 3);
  };

  const showOrderDetails = (orderNumber, id) => {
    if (selectOrder === orderNumber) return;
    if (isMobile) {
      navigate(`/order/${id}`);
      dispatch(deleteAllModalComponent());
      return;
    }
    navigate(`/findorders/all/${orderNumber}`);
  };

  const changePage = useCallback(() => {
    setPage(page + 1), [page];
    setSendingEvent(false);
  });

  useEffect(() => {
    if (!showProposed) {
      setSendingEvent(false);
      setPage(0);
    }
  }, [showProposed]);

  useEffect(() => {
    if (isDe || isNewbieGroup) return;

    if (__orderList.length && (showProposed || isMobile) && !isSendingEvent) {
      setSendingEvent(true);
      let actionData = {
        action: page === 0 ? 'order_wheel_open' : 'order_wheel_refresh',
        orders_id: __orderList.map(o => o._id),
      };

      if (proposedOpenPlace || openPlace) {
        actionData = { ...actionData, created_from: proposedOpenPlace || openPlace };
      }
      dispatch(userActionFetch(actionData));
    }
  }, [__orderList, page, showProposed, isSendingEvent, proposedOpenPlace, openPlace]);

  useEffect(() => {
    setSelectOrder(params.orderNumber || null);
  }, [params]);

  useEffect(() => {
    window.addEventListener('storage', localStorageUpdated);
    return () => {
      window.removeEventListener('storage', localStorageUpdated);
    };
  }, []);

  const MappedComponent = ({ order }) => (
    <ProposedOrder user={user} order={order} onSelect={showOrderDetails} isSelect={selectOrder === order.number} />
  );
  if (showAgreementWizard) return null;

  if (isDe || isNewbieGroup) return null;

  if (!orderList.length) return null;

  // if (isShowBeforeModal) {
  //   return (
  //     <ProposedModalBefore inIsShowBeforeModal onClose={() => showBeforeModal(false)} />
  //   );
  // }

  if (!showProposed && !isMobile) return null;

  if (isMobile) {
    return (
      <div className="proposed-modal">
        <h2 className="proposed-modal__title">Your personal assistant is here! <span className="proposed-modal__hi" /></h2>
        <p className="proposed-modal__desc">
        I've picked up the best orders matching your preferences. <br />
        Be the first to accept them!
      </p>
        <div className="proposed-modal-list">
          {__orderList.map(order => <MappedComponent key={order._id} order={order} />)}
        </div>
        <div className="proposed-modal__static-block">
          <button className="btn btn-default btn-block" disabled={page === Math.floor(orderList.length / MAX_ORDERS)} onClick={changePage}>{page === Math.floor(orderList.length / MAX_ORDERS) ? 'That’s it so far!' : 'Find other orders!'}</button>
        </div>
      </div>
    );
  }

  return (
    <Modal className="modal-lg proposed-modal" wrapperClassName="proposed-modal-wrap">
      <button className="btn btn-close" onClick={onClose}><Icon className="svg-icon" iconName="close" /></button>
      <h2 className="proposed-modal__title">Your personal assistant is here! <span className="proposed-modal__hi" /></h2>
      <p className="proposed-modal__desc">
        I've picked up the best orders matching your preferences. <br />
        Be the first to accept them!
      </p>
      <div className="proposed-modal-list">
        {__orderList.map(order => <MappedComponent key={order._id} order={order} />)}
      </div>
      <button className="btn btn-default btn-extra btn-transparent" disabled={page === Math.floor((orderList.length - 1) / MAX_ORDERS)} onClick={changePage}>{page === Math.floor((orderList.length - 1) / MAX_ORDERS) ? 'That’s it so far!' : 'Find other orders!'}</button>
    </Modal>
  );
};

ProposedOrdersModal.displayName = 'Find me an order';

export default ProposedOrdersModal;
