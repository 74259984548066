import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';

import { closeModalComponent, addModalComponent } from 'store/modals/actions';

import modalList from 'constants/modalList';

import utils from 'utils';
import isValidProfile from 'utils/isValidProfile';

import { userActionFetch } from 'store/user/actions';
import { getProfile, getAgreementInfo } from 'store/user/selectors';

import WriterPollComponent from './WriterPollComponent';

import './style/index.scss';

const POLL_DAYS = [4, 5, 6];
const POLL_ROLES = ['Freelancer', 'Group', 'US'];

const WriterPoll = ({ children }) => {
  let renderComponent = null;

  const profile = useSelector(getProfile);
  const showAgreement = useSelector(getAgreementInfo);

  const __isValidProfile = isValidProfile(profile);

  const simpleValidator = useRef(new SimpleReactValidator());

  const [, updateState] = useState();
  const [pollData, setPollData] = useState({
    weekCapacity: { value: '', dirty: false },
    monthCapacity: { value: '', dirty: false },
    nextWeekCapacity: { value: '', dirty: false },
  });
  const forceUpdate = useCallback(() => updateState({}), []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (children) return;
    if (!renderComponent) return;

    const dataModal = modalList.PredictedCapacityWriterPoll;
    dispatch(addModalComponent({ component: renderComponent, ...dataModal }));
  }, []);

  if (showAgreement || !__isValidProfile) return null;

  const {
    view_status: viewStatus,
    profile_capacity_week: profileCapacityWeek,
    profile_capacity_month: profileCapacityMonth,
    metric_data: metricData,
    date,
    isFetchingAction,
  } = profile;

  const momentDate = moment(date);

  const nowDayWeek = momentDate.isoWeekday();

  if (!POLL_DAYS.includes(nowDayWeek)) return null;

  const nowDayMonth = momentDate.format('DD');

  if (nowDayMonth === '01' && (nowDayWeek === 5 || nowDayWeek === 6)) return null;

  if (nowDayMonth === '02' && nowDayWeek === 6) return null;

  if (!POLL_ROLES.includes(viewStatus)) return null;

  const {
    completed_orders_count: completedOrdersCount,
  } = metricData;

  if (!completedOrdersCount) return null;

  if (
    (typeof (profileCapacityWeek) !== 'undefined' && profileCapacityWeek !== null) ||
  (typeof (profileCapacityMonth) !== 'undefined' && profileCapacityMonth !== null)
  ) return null;


  const nowMonth = momentDate.month();
  const nextWeekMonth = momentDate.add(7, 'day').month();

  const isWeekPoll = nowMonth === nextWeekMonth;

  if (isWeekPoll) return null;

  const saveWeeklyPoll = weekCapacity => dispatch(userActionFetch({ action: 'set_capacity', capacity_week: weekCapacity }));
  const saveMonthPoll = (weekCapacity, monthCapacity) => dispatch(userActionFetch({ action: 'set_capacity', capacity_month: monthCapacity, capacity_week: weekCapacity }));

  const onChangePage = ({ target }) => {
    const value = target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
    const _pollData = { ...pollData };
    _pollData[target.name] = { value, dirty: true };
    setPollData(_pollData);
  };
  const onSubmit = () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      const _pollData = { ...pollData };
      Object.keys(_pollData).forEach((pd) => {
        _pollData[pd] = { ..._pollData[pd], dirty: true };
      });
      setPollData(_pollData);
      forceUpdate();
      return;
    }

    if (isWeekPoll) saveWeeklyPoll(parseInt(pollData.weekCapacity.value, 0));
    else saveMonthPoll(parseInt(pollData.nextWeekCapacity.value, 0), parseInt(pollData.monthCapacity.value, 0));
    dispatch(closeModalComponent('PredictedCapacityWriterPoll'));
  };

  if (isWeekPoll) {
    simpleValidator.current.message('weekCapacity', pollData.weekCapacity.value, 'required');
  } else {
    simpleValidator.current.message('monthCapacity', pollData.monthCapacity.value, 'required');
    simpleValidator.current.message('nextWeekCapacity', pollData.nextWeekCapacity.value, 'required');
  }

  const isMobile = utils.detectMob();

  if (children) {
    return (<WriterPollComponent
      isFetchingAction={isFetchingAction}
      isWeekPoll={isWeekPoll}
      simpleValidator={simpleValidator}
      isMobile={isMobile}
      onChangePage={onChangePage}
      pollData={pollData}
      metricData={metricData}
      onSubmit={onSubmit}
    />);
  }

  renderComponent = (<WriterPoll>
    <WriterPollComponent
      isFetchingAction={isFetchingAction}
      isWeekPoll={isWeekPoll}
      simpleValidator={simpleValidator}
      isMobile={isMobile}
      onChangePage={onChangePage}
      pollData={pollData}
      metricData={metricData}
      onSubmit={onSubmit}
    />
  </WriterPoll>);

  return null;
};

export default WriterPoll;
