import React from 'react';

import { ProfileDropdown, ProfileInput, TimezoneDropdown } from '../Controls';

const AddressForm = ({ profile, countryList, isStem, isDe, onChangeFields }) => {
  const {
    timezone_str: timezoneStr, address = {}, public_city, country: rootCountry,
  } = profile;
  const { country, city, street, building_number: buildingNumber } = address;
  return (
    <ul>
      <li className="person-info__item">
        <ProfileDropdown
          value={isDe ? rootCountry : country}
          fieldName={isDe ? 'country' : 'address:country'}
          list={countryList}
          onChangeFields={!isStem && onChangeFields}
          title="Country registration"
        />
      </li>
      <li className="person-info__item">
        <ProfileInput
          value={isDe ? public_city : city}
          fieldName={isDe ? 'public_city' : 'address:city'}
          onChangeFields={!isStem && onChangeFields}
          title="City"
          placeholder="Enter your city"
        />
      </li>
      {!isDe &&
      <>
        <li className="person-info__item">
          <ProfileInput
            value={street}
            fieldName="address:street"
            onChangeFields={!isStem && onChangeFields}
            title="Street"
            placeholder="Enter your street"
            maxLength="150"
          />
        </li>
        <li className="person-info__item">
          <ProfileInput
            value={buildingNumber}
            fieldName="address:building_number"
            onChangeFields={!isStem && onChangeFields}
            title="Building Number"
            placeholder="Enter your building number"
            maxLength="150"
          />
        </li>
      </>
      }
      <li className="person-info__item">
        <TimezoneDropdown
          value={timezoneStr}
          onChangeFields={!isStem && onChangeFields}
        />
      </li>
    </ul>
  );
};

export default AddressForm;
