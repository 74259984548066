import React, { Component } from 'react';
import moment from 'moment-timezone';
import stable from 'stable';

import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

const zoneList = stable(moment.tz.names().filter(z => z.indexOf('/') >= 0 && z.indexOf('Etc/') !== 0).map((zoneName) => {
  const zone = moment.tz.zone(zoneName);
  return {
    name: zone.name,
    offset: zone.utcOffset(0),
    offsetText: moment.tz(zone.name).format('Z'),
  };
}), (a, b) => a.offset - b.offset);

class TimezoneDropdown extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    const fieldName = 'timezone_str';
    const { onChangeFields } = this.props;
    onChangeFields(fieldName, value);
  }

  render() {
    const { value, onChangeFields } = this.props;
    const text = value || 'Select';
    return (
      <div>
        Time Zone (GMT + hrs):
        {!onChangeFields ?
          <span className="profile-noneditable-field">{value}</span> :
          <Dropdown buttonClassName="lnk-dropdown" text={text} closeAlways positionRight>
            {/* <Select value={value} onChange={save} className="menu menu-selectable menu-selectable-overflow"> */}
            <Select value={value} onChange={this.onChange} className="menu menu-selectable menu-selectable-overflow">
              {zoneList.map(tz => (
                <li key={tz.name} value={tz.name} className="menu-item">
                  {tz.name} {tz.offsetText}
                  <Icon className="svg-icon" iconName="check-empty" />
                </li>
              ))}
            </Select>
          </Dropdown>
        }
      </div>
    );
  }
}

export default TimezoneDropdown;
