const getFillRating = (graderRating) => {
  let fill = '#e6e8eb';

  if (graderRating > 0 && graderRating < 3) {
    fill = '#fc694f';
  }
  if (graderRating >= 3 && graderRating <= 3.9) {
    fill = '#ffa525';
  }

  if (graderRating >= 4) {
    fill = '#12ca86';
  }
  return fill;
};

const getRatingString = (graderRating) => {
  let graderRatingStr = '(1)';

  if (graderRating <= 0) return null;

  if (graderRating >= 1) {
    graderRatingStr = `(${graderRating})`;
  }

  return graderRatingStr;
};

const getFines = (listFixes, listFines, writerPrice) => {
  if (!listFixes && !listFines) {
    const sumFines = 0;
    return sumFines;
  }
  const resSumFines = listFixes.reduce(
    (sumFines, item) => sumFines + listFines[item],
    0,
  );
  const sumFines = Number((resSumFines / 100) * writerPrice).toFixed(2);
  return sumFines;
};


export {
  getFillRating,
  getRatingString,
  getFines,
};
