import React from 'react';

import ButtonList from 'components/ButtonList';
import Range from 'components/Range';

const IssueDeadline = ({
  chooseValue,
  onChangeSubIssue,
  arDeadlineList,
  setOtherDeadline,
  deadlinesCustom,
  setDeadline,
  deadlineRange,
  endDeadlineRange,
  startDeadlineRange,
}) => (
  <div>
    <p className="sub-title">Choose how much extra time you need to complete the order.</p>
    <ButtonList
      field="deadline"
      chooseValue={chooseValue}
      onChange={onChangeSubIssue}
      list={arDeadlineList}
      setOther={setOtherDeadline}
      otherName={deadlinesCustom}
      activeIndex={2}
    />

    <Range
      min={startDeadlineRange}
      max={endDeadlineRange}
      value={deadlineRange}
      onChange={setDeadline}
      step={2}
    />
  </div>
);

export default IssueDeadline;
