export const ADD_ALERT = 'ADD_ALERT';
export const DELETE_ALERT = 'DELETE_ALERT';

export const addAlert = component => ({
  type: ADD_ALERT,
  payload: component,
});

export const deleteAlert = component => ({
  type: DELETE_ALERT,
  payload: component,
});
