import React from 'react';
import { connect } from 'react-redux';
import {
  NavLink,
  Outlet,
} from 'react-router-dom';

import Icon from './../components/Icon';
import { helpSetSearch } from './../store/help/actions';

const HelpFaqPage = (props) => {
  const {
    faqCats,
    searchValue,
    setSearch,
    clearSearch,
  } = props;

  return (
    <div className="container">
      <div className="row help-container">
        <aside className="left-sidebar content-item">
          <div className="search-wrap">
            <Icon className="svg-icon" iconName="search" />
            <input className="input-text" name="searchValue" type="text" placeholder="Search for answers" onChange={setSearch} value={searchValue} />
            <button className="btn btn-close" type="button" onClick={clearSearch}><Icon className="svg-icon" iconName="close" /></button>
          </div>
          <ul className="nav-left faq-nav">
            {faqCats.map((cat) => {
              const subCats = Object.values(cat.subCategories);
              return (
                <li key={cat.id}>
                  <span className="faq-nav-parent">
                    <span className={`faq-icon ${cat.icon}`} />
                    <span className="faq-nav-parent-title">{cat.text}</span>
                  </span>
                  <ul className="sub-nav-left">
                    {subCats.map(subCat => (
                      <li key={subCat.id}>
                        <NavLink
                          onClick={searchValue ? clearSearch : null}
                          to={subCat.id}
                        >
                          {subCat.text}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>

        </aside>

        <section className="container-border right-content padding-content">
          <Outlet />
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const faqCats = Object.values(state.help.faqCats);
  const searchValue = state.help.search;
  return {
    faqCats,
    searchValue,
    profile: state.user,
  };
};

const mapDispatchToProps = dispatch => ({
  setSearch: event => dispatch(helpSetSearch(event.target.value)),
  clearSearch: () => dispatch(helpSetSearch('')),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpFaqPage);
