import { useState } from 'react';

import classNames from 'utils/classNames';

import WisePKCurrencyBtns from 'components/profile/Forms/WisePKCurrencyBtns';

const Body = ({ onConfirmCurrency }) => {
  const [currencyValue, setCurrencyValue] = useState(null);

  const onSetPKCurrency = ({ target }) => {
    const { value } = target;
    setCurrencyValue(value);
  };

  return (
    <div>
      <div className="info-modal-container__header text-modal">
        Clarification Needed
      </div>
      <div className="info-modal-container__text text-modal">
        <div>
          To process your next payout, our Finance Department requires additional information about your Wise account. Please select whether you use a USD or PKR account for Wise withdrawals.
        </div>
        <div className="mt18">
          <WisePKCurrencyBtns
            onSetPKCurrency={onSetPKCurrency}
            PKSelectedCurrency={currencyValue}
            isModal
          />
        </div>
        <p className="fsMd mt10" style={{ color: '#6C7680' }}>You can update your choice in your Payment Info settings later if needed.</p>
      </div>
      <div className="row flex-end">
        <button
          disabled={!currencyValue}
          onClick={() => onConfirmCurrency(currencyValue)}
          className={classNames(
            'btn btn-bright btn-next',
            { 'btn-disabled': !currencyValue },
          )}
        >
          Confirm selection
        </button>
      </div>
    </div>
  );
};

export default Body;

