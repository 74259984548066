import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Card } from '@connect/connect-ui-js';

import classNames from 'utils/classNames';
import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';

import EstimationFooter from './EstimationFooter';

import './styles/index.scss';

const Estimations = ({ msgData, getPic }) => {
  const { event: { event_name: eventName = '', event_params: { estimate_id: estimateId, order_id: orderId, auto_confirm: autoConfirm } } } = msgData;

  const orders = useSelector(state => state.orders);

  const order = useMemo(() => Object.values(orders).find(o => o._id === orderId) || {}, [orders.length]);

  const { estimates = [] } = order;

  const estimate = useMemo(() => Object.values(estimates).find(e => e._id === estimateId) || {}, [estimates.length]);

  const { additional_size: additionalSize = '', size: totalOrderSize = '', status } = estimate;

  const additionalSizeInMin = useMemo(() => Number(additionalSize.split(' minutes')[0]), [additionalSize]);
  const totalOrderSizeInMin = useMemo(() => Number(totalOrderSize.split(' minutes')[0]), [totalOrderSize]);

  if (!status) return <div>No status in {eventName}</div>;

  const renderHeader = () => {
    if (status === 'active') {
      return 'New task estimate sent';
    }

    return `Task estimate ${status}`;
  };

  return (
    <Card data={msgData} getPic={getPic}>
      <Card.Main>
        <div className={classNames('card-estimate-container', `card-estimate-container--${status}`)}>
          <div className="row vertical">
            <i className={classNames('card-estimate-container__header-icon', `card-estimate-container__header-icon--${status}`)} />
            <h3 className="card-estimate-container__header">{renderHeader()}</h3>
          </div>
          <h4 className="card-estimate-container__title">{autoConfirm ? 'Time requested' : 'Additional time requested'}:</h4>
          <p className="card-estimate-container__size">{convertMinsToHrsMins(autoConfirm ? totalOrderSizeInMin : additionalSizeInMin)}</p>
          <h4 className="card-estimate-container__title">{status === 'accepted' ? 'Total order size:' : 'Total order size if accepted:'}</h4>
          <p className="card-estimate-container__size">{convertMinsToHrsMins(totalOrderSizeInMin)}</p>
          {status === 'active' && <div className="card-estimate-container__active-btn">Pending approval...</div>}
        </div>
      </Card.Main>
      <Card.Footer data={msgData}>
        {status === 'active' && <EstimationFooter msgData={msgData} />}
      </Card.Footer>
    </Card>
  );
};

export default Estimations;
