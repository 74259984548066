import fileConstants from 'constants/files';

const getFilePlagLink = (file = {}, flagFiles = []) => {
  const {
    plagreport_id: plagreportId, plag_level: plagLevel = 0,
    override_pgch: overridePgch, plag_status: plagStatus = null,
  } = file;

  if (plagStatus !== 'found') return null;
  if (!plagreportId) return null;
  if (overridePgch) return null;
  if (!flagFiles || !flagFiles.length) return null;
  if (plagLevel < fileConstants.MAX_PLAG_PERCENT) return null;

  const findPlagFile = flagFiles.find(plagFile => plagFile._id === plagreportId);

  if (!findPlagFile) return null;

  return findPlagFile.location;
};

export default getFilePlagLink;
