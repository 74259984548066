import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

import utils from 'utils';
import formatTime from 'utils/formatTime';
import classNames from 'utils/classNames';
import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';

import tooLate from 'img/icons/too-late-ill.svg';

import Modal from 'components/Modal';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import {
  ordersSetDdlExtensionFetch,
  STATUS_REVISION,
  STATUS_COMPLETE,
} from 'store/orders/actions';

import timeConstants from 'constants/time';

import EstimationModal from 'components/order/EstimationModal';

import { orderPrice, getOrderType, renderOrderSize } from 'components/order/utils';

import OrderReject from './OrderReject';

const SliderWithTooltip = createSliderWithTooltip(Slider);

const millisecondsToDurationString = (milliseconds, simplify) => {
  const duration = moment.duration(milliseconds);
  const minutes = duration.minutes();
  const hours = duration.hours();
  const days = Math.floor(duration.asDays());
  if (!days && simplify) {
    return `${hours}h ${minutes}m`;
  }
  return `${days}d ${hours}h ${minutes}m`;
};

const getFormatDate = (date) => {
  const newTime = millisecondsToDurationString(date, true);
  return newTime;
};

const refreshPage = () => window.location.reload(true);

const getIsMissed = momentDeadline => momentDeadline.diff(moment()) < 0;

let clientTimezoneInterval = null;

class OrderInfo extends Component {
  constructor(props) {
    super(props);

    const {
      order = {},
    } = this.props;
    const { client_info: { timezone_str: clientTimezone = '' } } = order;

    this.state = {
      isOpen: false,
      newDeadline: 0,
      clientTzTime: `${formatTime(moment().tz(clientTimezone), 'dt')} (${clientTimezone})`,
      isNTorder: utils.isNTorder(order),
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.setDeadline = this.setDeadline.bind(this);
    this.getSliderMarks = this.getSliderMarks.bind(this);
    this.getNewDdl = this.getNewDdl.bind(this);
    this.getNewPrice = this.getNewPrice.bind(this);
    this.onChangeDeadline = this.onChangeDeadline.bind(this);
  }

  componentDidMount() {
    const { order = {} } = this.props;
    const { isNTorder } = this.state;
    const { client_info: { timezone_str: clientTimezone = '' } } = order;

    if (!isNTorder) return;

    clientTimezoneInterval = setInterval(() => {
      this.setState({
        clientTzTime: `${formatTime(moment().tz(clientTimezone), 'dt')} (${clientTimezone})`,
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(clientTimezoneInterval);
  }

  onChangeDeadline() {
    const { newDeadline } = this.state;
    const { wdd_extension_cost } = this.props.order;
    this.props.updateDDl({
      minutes: newDeadline / (60 * 1000),
      cost: wdd_extension_cost,
    });
    this.setState({
      newDeadline: 0,
    });
    this.toggleModal();
  }

  setDeadline(value) {
    this.setState({
      newDeadline: value,
    });
  }


  getSliderMarks() {
    const { wdd_extension_available } = this.props.order;
    const marks = {
      0: '0',
      [wdd_extension_available * 60 * 1000]: getFormatDate(wdd_extension_available * 60 * 1000),
    };
    return marks;
  }

  getNewDdl() {
    const { order, status } = this.props;
    const { newDeadline } = this.state;
    const momentDeadline = moment(status === STATUS_REVISION ? order.revision_deadline : order.writer_deadline);
    const newDdl = momentDeadline.add(newDeadline, 'ms');
    const isMissed = newDdl.diff(moment()) < 0;
    const date = isMissed ? moment().valueOf() - newDdl.valueOf() : newDdl.valueOf() - moment().valueOf();
    return `${getFormatDate(date)} ${isMissed ? 'ago' : 'from now'}`;
  }

  getNewPrice() {
    const { ONE_MINUTE } = timeConstants;
    const { writer_price, wdd_extension_cost } = this.props.order;
    const { newDeadline } = this.state;
    const payback = (((newDeadline / ONE_MINUTE) / 15) * wdd_extension_cost);
    const newPrice = Math.round((writer_price - payback) * 100) / 100;
    return `New price: $${newPrice > 0.01 ? newPrice : 0.01}`;
  }

  getMomentDeadline() {
    const { order, status } = this.props;
    return moment(status === STATUS_REVISION ? order.revision_deadline : order.writer_deadline);
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }


  renderDuplicateAndRevisionText() {
    const { order, mobile } = this.props;
    const isDuplicateAndRevisionOrder = utils.isDuplicateAndRevisionOrder(order);

    if (!isDuplicateAndRevisionOrder) return null;

    const questionContainer = classNames('tooltip-container', { 'tooltip tooltip__icon': mobile });

    return (
      <li>
        This is a revision order
        <div className={questionContainer}>
          <Icon className="svg-icon tooltip-trigger tooltip-trigger-size question" iconName="icon-blue-info" />
          <p className="tooltip-content size-order-tooltip order-tooltip">You are required to follow only instructions provided in Revision details section. Accepting the order, you will also be responsible for any consecutive revisions of the final file(s) that may be requested by client, related or unrelated to revision comments currently provided. Initial order details are provided for reference, please review them before working on revision.</p>
        </div>
      </li>
    );
  }

  renderWddExtensionAvailable(callForRevision) {
    const { order, mobile, profile } = this.props;

    if (mobile) return null;

    const {
      isOpen, newDeadline,
    } = this.state;

    const momentDeadline = this.getMomentDeadline();
    const isStem = utils.isStem(profile);
    const isMissed = getIsMissed(momentDeadline);
    const { wdd_extension_available, assigned_by_me } = order;
    const stepRange = 15 * 60 * 1000; // 15 min
    const maxDeadlineRange = wdd_extension_available * 60 * 1000;

    if (wdd_extension_available > 0 && assigned_by_me && !isStem && !isMissed) {
      return (
        <div>
          <a onClick={this.toggleModal}>Extend my DDL</a>
          {isOpen &&
            <Modal className="extend-ddl" small>
              <div className="extend-ddl-icon" />
              <h2 className="centered extend-ddl-title">Warning!</h2>
              <p className="extend-ddl-info">Please note that requesting an extension has its drawback and is not a desired behavior. <b>Please use this option in case of emergency only.</b> The price for this order will be decreased depending on extension requested.</p>
              <div className="extend-ddl-new-ddl">New deadline: {this.getNewDdl()}</div>
              <SliderWithTooltip
                className="extend-ddl-slider"
                min={0}
                max={maxDeadlineRange}
                step={stepRange}
                value={newDeadline}
                marks={this.getSliderMarks()}
                tipFormatter={getFormatDate}
                onChange={this.setDeadline}
              />
              <div className="extend-ddl-new-price">{this.getNewPrice()}</div>
              <div className="extend-ddl-notice">On the right you can see the max extension we can offer you for this order. In case it’s still not enough and you are having trouble with this order, please message support.</div>
              <button onClick={this.onChangeDeadline} type="button" className="btn btn-notification extend-ddl-btn btn-block">Change deadline</button>
              <button onClick={this.toggleModal} type="button" className="btn extend-ddl-btn btn-block">Cancel</button>
            </Modal>
          }
        </div>
      );
    }

    if ((wdd_extension_available <= 0 || isMissed) && assigned_by_me && !isStem) {
      return (
        <div>
          <span className="extend-ddl-not-possible">Extension not possible </span>
          <Tooltip className={classNames('size-order-tooltip order-tooltip', { 'order-tooltip--right': !callForRevision })} content="Please try to complete the order on time. Late submission leads to penalties.">
            <Icon className="svg-icon extend-ddl-tooltip-trigger" iconName="question" />
          </Tooltip>
        </div>
      );
    }

    return null;
  }

  renderCompleted() {
    const { status } = this.props;

    const isComplete = status === STATUS_COMPLETE;

    if (isComplete) {
      return (
        <li>Completed</li>
      );
    }

    const momentDeadline = this.getMomentDeadline();
    const isMissed = getIsMissed(momentDeadline);

    return (
      <li>{momentDeadline.fromNow(true)} {isMissed ? 'ago' : 'from now'}</li>
    );
  }

  renderStudentTime(callFromLeft) {
    const {
      clientTzTime, isNTorder,
    } = this.state;

    if (!isNTorder) return null;

    if (callFromLeft) {
      return (
        <li>Student’s current time: {clientTzTime}</li>
      );
    }

    return (
      <div className="row left">Student’s current time: {clientTzTime}</div>
    );
  }

  renderColumnLeft() {
    const {
      order, status, setRejectAccepted, onSelect, error,
    } = this.props;

    const orderSubType = getOrderType(order);

    const isOrderRevision = utils.isOrderOnRevision(order);

    const { allow_request_reject: allowRequestReject, subject } = order;

    const momentDeadline = this.getMomentDeadline();
    const isComplete = status === STATUS_COMPLETE;
    const isMissed = getIsMissed(momentDeadline);
    const deadlineClassName = classNames({ error: isMissed && !isComplete });

    if (isOrderRevision && !isComplete) {
      return (
        <>
          <li className={deadlineClassName}><b>Final DDL: </b>{formatTime(momentDeadline, 'dt')}</li>
          <li>{momentDeadline.fromNow(true)} {isMissed ? 'ago' : 'from now'}</li>
          <li>
            {this.renderWddExtensionAvailable(true)}
            {allowRequestReject && (
              <div>
                <OrderReject order={order} setRejectAccepted={setRejectAccepted} onSelect={onSelect} />
              </div>
            )}
            {error && (
              <Modal className="modal-sm ta-center">
                <img className="modal-icon" src={tooLate} alt="" />
                <h2 className="title mb30">Sorry, for this order DDL extension <br />not available so much.</h2>
                <button type="button" className="btn btn-bright btn-big" onClick={refreshPage}>Resresh page</button>
              </Modal>
            )}
          </li>
          {this.renderStudentTime(true)}
          {this.renderDuplicateAndRevisionText()}
        </>
      );
    }

    return (
      <>
        <li>{orderSubType.join(' + ')}</li>
        {renderOrderSize(this.props)}
        <li>{subject}</li>
        {this.renderDuplicateAndRevisionText()}
      </>
    );
  }

  renderColumnRight() {
    const {
      order, status, profile, setRejectAccepted, onSelect, error,
    } = this.props;
    const { is_underpaid: isUnderpaid = false } = order;

    const isOrderRevision = utils.isOrderOnRevision(order);
    const isComplete = status === STATUS_COMPLETE;

    const isUnderpaidTooltip =
      'This is your final price, but we are still <br />' +
      'waiting for client to make the <br />' +
      'payment.Please do not start working <br />' +
      'on the order until it\'s fully paid. <br />';

    if (isOrderRevision && !isComplete) {
      return (
        <>
          {isUnderpaid && (
            <li>
              <Tooltip className="ta-left" isHtml content={isUnderpaidTooltip}>
                <p className="row vertical"><b>Waiting for payment</b><Icon className="svg-icon ml8" iconName="icon-exclamation" /></p>
              </Tooltip>
            </li>
          )}
        </>
      );
    }

    const { draft_deadline: draftDeadline } = order;
    const momentDeadline = this.getMomentDeadline();
    const isMissed = getIsMissed(momentDeadline);
    const deadlineClassName = classNames({ error: isMissed && !isComplete });
    const dateDraftDeadline = moment(draftDeadline || false);
    const isMissedDraft = dateDraftDeadline.diff(moment()) < 0;

    const { allow_request_reject: allowRequestReject } = order;

    return (
      <>
        <li>
          <span className="order-cost">
            {orderPrice({ order, profile })}
          </span>
        </li>
        {isUnderpaid && (
          <li>
            <Tooltip className="ta-left" isHtml content={isUnderpaidTooltip}>
              <p className="row vertical"><b>Waiting for payment</b><Icon className="svg-icon ml8" iconName="icon-exclamation" /></p>
            </Tooltip>
          </li>
        )
        }
        {
          draftDeadline && (
            <>
              <li className={deadlineClassName}><b>Draft DDL: </b>{formatTime(dateDraftDeadline, 'dt')}</li>
              <li>{dateDraftDeadline.fromNow(true)} {isMissedDraft ? 'ago' : 'from now'}</li>
            </>
          )
        }
        <li className={deadlineClassName}><b>Final DDL: </b>{formatTime(momentDeadline, 'dt')}</li>
        {this.renderCompleted()}
        <li>
          {this.renderWddExtensionAvailable()}
          {allowRequestReject && (
            <div>
              <OrderReject extraTooltipClass="order-tooltip--right" order={order} setRejectAccepted={setRejectAccepted} onSelect={onSelect} />
            </div>
          )}
          {error && (
            <Modal className="modal-sm ta-center">
              <img className="modal-icon" src={tooLate} alt="" />
              <h2 className="title mb30">Sorry, for this order DDL extension <br />not available so much.</h2>
              <button type="button" className="btn btn-bright btn-big" onClick={refreshPage}>Resresh page</button>
            </Modal>
          )}
        </li>
      </>
    );
  }

  render() {
    const { order } = this.props;

    const isOrderRevision = utils.isOrderOnRevision(order);

    return (
      <>
        <div className="row">
          <ul className="order-info col-2">
            {this.renderColumnLeft()}
          </ul>
          <ul className="order-info col-1 ta-right">
            {this.renderColumnRight()}
          </ul>
        </div>
        {!isOrderRevision && this.renderStudentTime()}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const orderNumber = ownProps.order.number;
  const isDe = state.user && state.user.profile_type === 'D';
  return {
    templates: state.settings.templates,
    error: state.orders[orderNumber].ddlExtensionError,
    profile: state.user,
    isDe,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const orderNumber = ownProps.order.number;
  return {
    updateDDl: params => dispatch(ordersSetDdlExtensionFetch(orderNumber, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfo);
