import React, { useRef, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import { orderSingleUpdate } from 'store/orderSingle/actions';

import classNames from 'utils/classNames';

import ReservedCounter from 'components/ReservedCounter';
import Icon from 'components/Icon';

import './index.scss';

const TopicForm = ({ order = {} }) => {
  const formRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [isTimeOver, setTimeOver] = useState(false);
  const dispatch = useDispatch();

  const {
    writer_deadline: writerDeadline, topic_field: topic, writer_accept_at: writerAcceptAt, number, topic_field_writer: topicFieldWriter,
  } = order;


  if (!writerAcceptAt) return null;

  const diffFromNowToAccept = moment.utc().diff(writerAcceptAt, 'minutes');
  const diffFromNowToWDD = moment.utc(writerDeadline).diff(moment.utc(), 'minutes');

  const counterTime = isTimeOver || diffFromNowToWDD < 30 ? 0 : 30 - diffFromNowToAccept;

  const setTopicCounterOver = () => {
    setTimeOver(true);
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const { current: { inputValue } } = formRef;
    if (!inputValue) return;

    setLoading(true);

    try {
      await dispatch(orderSingleUpdate(number, { topic_field_writer: inputValue }));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    formRef.current.inputValue = e.target.value.trim();
  };

  if (topicFieldWriter) {
    return (
      <div className="mt20 row vertical order-topic-form">
        <div className="fs15 mr12 order-topic-form__submit-label">Topic:</div>
        <div>{topicFieldWriter}</div>
      </div>
    );
  }

  if (!topic) return null;

  if (topic !== 'To be chosen by Writer' && topic !== 'To be confirmed with Client') return null;


  return (
    <div className="order-topic-form mt20 pb20 order-tab-collapse">
      <div className="order-topic-form-timer-container row vertical">
        <Icon className="svg-icon order-topic-form-timer-container__icon" iconName="icon-time" />
        <div className="mr8 fs15 ml8">Topic definition time:</div>
        {
          counterTime > 0 ? (
            <ReservedCounter time={moment().add(counterTime, 'minutes').toISOString()} onOver={setTopicCounterOver} />
          ) : (
            <div className="order-topic-form-timer-container__no-time-label">ASAP</div>
          )
        }
      </div>
      <form ref={formRef} className={classNames('order-topic-form-form mt30 row vertical space-between ', { 'order-topic-form-form--loading': isLoading })} onSubmit={handleSubmitForm}>
        <input onChange={handleInputChange} className="input-text" type="text" placeholder="Write the topic you’ve chosen" />
        <button className="btn btn-default transparent btn-md">Submit</button>
      </form>
    </div>
  );
};

export default TopicForm;
