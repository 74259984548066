import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useConnectContext } from '@connect/connect-xmpp';

import { trackClicks } from 'lrtracker';

import { ordersSetStatusAcceptedFetch } from 'store/orders/actions';

import CreateSession from 'components/order/OrderClientSessions/CreateSession';
import AcceptConfirm from 'components/order/modals/AcceptConfirm';
import TemplateModal from 'components/TemplateModal';

import { closeModalComponent, addModalComponent } from 'store/modals/actions';

import modalList from 'constants/modalList';

const Accept = ({ order, profile }) => {
  const {
    chat_progress_state: chatProgressState = {},
    writer_chat_message_at: writerChatMessageAt,
    jabber_node: node, jabber_server: to,
    writer_accept_at: writerAcceptAt,
    number: orderNumber,
    _id: orderId,
  } = order;

  const { jabber_jid: username } = profile;

  const dispatch = useDispatch();

  const clientChatConfig = {
    username,
    node,
    to,
  };

  const { send } = useConnectContext();

  const isAllStepsConfirmed = useMemo(() => {
    const { instructions, price, output } = chatProgressState;
    const _isAllStepsConfirmed = [instructions, price, output].every(step => step);

    return Boolean(writerChatMessageAt) && _isAllStepsConfirmed;
  }, [chatProgressState, writerChatMessageAt]);

  const onSubmit = (text = '') => {
    dispatch(closeModalComponent(modalList.Templates.name));
    const trimmedMessage = text.trim();

    if (!trimmedMessage) return;

    send({ variables: clientChatConfig, message: trimmedMessage.replaceAll('<br />', ' ') });
  };

  const onClose = (modalName = modalList.Templates.name) => {
    dispatch(closeModalComponent(modalName));
  };

  const onAskAboutPreferredTime = () => {
    const dataModal = modalList.Templates;

    dispatch(addModalComponent({
      component: <TemplateModal
        onSubmit={onSubmit}
        onClose={() => onClose()}
        templateIndex={9}
      />,
      ...dataModal,
    }));
  };

  const onScheduleSession = () => {
    const dataModal = modalList.ScheduleSession;
    const component = <CreateSession session={{}} order={order} toggleModal={() => onClose(modalList.ScheduleSession.name)} />;
    dispatch(addModalComponent({ component, ...dataModal }));
  };

  const onAcceptNTWithEvent = () => {
    const setAccepted = () => {
      dispatch(ordersSetStatusAcceptedFetch(orderNumber));
      onClose(modalList.AcceptConfirm.name);
    };

    trackClicks(setAccepted, 'orderAcceptBtn', {
      context: {
        type: 'progressPanel',
        stage: 'ClientTutorChat',
        order_id: orderId,
        useragent: navigator.userAgent,
      },
    })();
  };

  const onAcceptConfirm = () => {
    const dataModal = modalList.AcceptConfirm;
    dispatch(addModalComponent({
      component: <AcceptConfirm
        onConfirm={onAcceptNTWithEvent}
        onClose={() => onClose(modalList.AcceptConfirm.name)}
        order={order}
        profile={profile}
      />,
      ...dataModal,
    }));
  };

  const renderAcceptBtn = () => {
    if (writerAcceptAt) return <div className="step-container__green-text">You've already accepted this order.</div>;

    return (<button className="btn btn-bright" type="button" disabled={!isAllStepsConfirmed} onClick={onAcceptConfirm}>Accept the order</button>);
  };

  return (
    <div>
      <div className="step-container__title">
        Feel free to schedule an online tutoring session with Client anytime, before of after order acceptance.
      </div>
      <div className="step-container__tips">Use if applicable</div>
      <button className="btn btn-light mb8" type="button" disabled={!writerChatMessageAt} onClick={onAskAboutPreferredTime}>Ask about preferred time</button>
      <div className="step-container__tips">Use if applicable</div>
      <button className="btn btn-light mb12" type="button" disabled={!writerChatMessageAt} onClick={onScheduleSession}>Schedule a tutoring session</button>
      {renderAcceptBtn()}
    </div>
  );
};

export default Accept;
