import React from 'react';
import Icon from 'components/Icon';

const OrderTimeOver = ({ withIcon = false, textOver = 'Time is over', onClick }) => {
  return (
    <div className="order-tab-actions">
      {withIcon &&
        <Icon className="svg-icon warning" iconName="clock" />
      }
      <span className="text-warning">{textOver}</span> <a onClick={onClick}>Got it.</a>
    </div>
  );
};

export default OrderTimeOver;
