import { v4 as uuidv4 } from 'uuid';
import { connect } from 'mqtt';
import match from 'mqtt-match';
import * as Sentry from '@sentry/browser';

import { getTokenExact } from '../utils/tokenStorage';

import { urls } from '../config';

const getParams = () => ({
  username: 'operator',
  password: getTokenExact(),
  clientId: uuidv4(),
  keepalive: 30,
});

let socket = null;

const socketMiddleware = (reconnectActions = []) => {
  /* topics object:
   {
     topic:  onMessageActions: [actions], onSubscribeFetch: [actions]
   }
  */
  const topics = {};
  const onMessage = store => (topic, message) => {
    let payload = null;
    try {
      payload = JSON.parse(message.toString());
    } catch (e) {
      Sentry.captureException(`cant parse payload from topic ${topic} ${e}`);
      return;
    }

    if (!payload) return;

    const subsTopic = Object.keys(topics).filter(it => match(it, topic))[0];

    if (!subsTopic) return;

    const actions = topics[subsTopic].onMessageActions || [];
    actions.forEach((action) => {
      store.dispatch(action(payload));
    });
  };

  const onReconnect = store => () => {
    reconnectActions.forEach(it => store.dispatch(it()));
  };

  const onSubscribe = store => (err, granted) => {
    if (!err) {
      granted.forEach((it) => {
        const { topic = '' } = it;
        const topicData = topics[topic];
        if (!topicData) return;
        const fetchActions = topics[topic].onSubscribeFetch || [];
        fetchActions.forEach((fetchAction) => {
          store.dispatch(fetchAction());
        });
      });
    }
  };

  return store => next => (action) => {
    const { type = '', payload = {} } = action;
    const { topic = '', onMessageActions = [], onSubscribeFetch = [] } = payload;
    switch (type) {
      case 'WS_CONNECT':
        if (socket) return;

        socket = connect(urls.apiHostSocket, getParams());
        socket.on('connect', () => {
          if (!Object.keys(topics).length) return;
          Object.keys(topics).forEach((it) => {
            socket.subscribe(it, null, onSubscribe(store));
          });
        });
        socket.on('reconnect', onReconnect(store));
        socket.on('error', (error) => {
          console.error(error);
        });
        socket.on('message', onMessage(store));
        break;
      case 'WS_SUBSCRIBE':
        topics[topic] = { onMessageActions, onSubscribeFetch };
        if (socket && socket.connected) {
          socket.subscribe(topic, null, onSubscribe(store));
        }

        break;
      case 'WS_UNSUBSCRIBE':
        if (socket !== null && topic) {
          socket.unsubscribe(topic);
          delete topics[topic];
        }
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware;
