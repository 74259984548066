import React from 'react';

import { Card } from '@connect/connect-ui-js';

import './index.scss';

const RevisionRequested = ({ msgData, getPic, showTab }) => (
  <Card data={msgData} getPic={getPic}>
    <Card.Main>
      <div className="revision-requested-card">
        <div className="row vertical">
          <i className="revision-requested-card__icon" />
          <h3 className="revision-requested-card__header">Revision requested</h3>
        </div>
        <p className="revision-requested-card__text">The student has requested a revision of the task. You have <strong>24 hours</strong> to complete it!</p>
        <button className="btn btn-100-4 btn-bright revision-requested-card__btn" onClick={() => showTab('TAB_REVISION')}>View revision details</button>
      </div>
    </Card.Main>
    <Card.Footer data={msgData} />
  </Card>
);

export default RevisionRequested;
