import React from 'react';
import { createRoot } from 'react-dom/client';
import { addTranslation } from 'react-localize-redux';
import * as Sentry from '@sentry/browser';
import 'babel-polyfill';
import 'url-search-params-polyfill';

import createAudioNotification from 'utils/createAudioNotification';

import { urls } from './config';
import configureStore from './store/store';
import translations from './global.locale.json';
import serviceWorker from './registerServiceWorker';

if (process.env.REACT_APP_BUILD) {
  Sentry.init({
    dsn: urls.apiSentry,
    environment: process.env.REACT_APP_BUILD,
    ignoreErrors: [
      /Non-Error exception captured/i,
      /Non-Error promise rejection captured/i,
    ],
    debug: false,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (
        error &&
        error.message &&
        ((error.message.match(/Failed to fetch/i)) || error.message.match(/Load failed/i))
      ) {
        return null;
      }

      if (error && error.status && (error.status === 403 || error.status === 401)) {
        return null;
      }

      return event;
    },
  });
}

const store = configureStore({
  locale: {
    languages: [
      {
        code: 'en',
        active: true,
      },
    ],
    options: {},
  },
});
store.dispatch(addTranslation(translations));

const App = require(`./App.${process.env.NODE_PLAT}`).default;

const container = document.getElementById('root');
const root = createRoot(container);

window.root = container;
window.page_title = document.title;

serviceWorker.register();

root.render(<App store={store} />);

setTimeout(() => {
  createAudioNotification();
}, 10);
