import React, { Component } from 'react';

import classNames from './../utils/classNames';

import Icon from './Icon';

const contains = (parentNode, childNode) => {
  if (parentNode.contains) {
    return parentNode.contains(childNode);
  }

  return parentNode.compareDocumentPosition(childNode) % 16;
};

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  componentWillUnmount() {
    window.root.removeEventListener('click', this.handleDocumentClick);
  }

  onButtonClick() {
    const { onButtonClick } = this.props;
    const { isOpen } = this.state;
    if (onButtonClick) {
      if (!onButtonClick()) {
        return;
      }
    }

    if (isOpen) {
      this.setClose();
    } else {
      this.setOpen();
    }
    // this.setState(state => Object.assign({}, state, {
    //   isOpen: !state.isOpen,
    // }));
  }

  setOpen() {
    window.root.addEventListener('click', this.handleDocumentClick);
    this.setState(state => Object.assign({}, state, {
      isOpen: true,
    }));
  }

  setClose() {
    this.setState(state => Object.assign({}, state, {
      isOpen: false,
    }));
    window.root.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick(event) {
    if (event.target === this.button || contains(this.button, event.target)) {
      return;
    }

    const ddAttr = event.target.getAttribute('data-dropdown');
    if (ddAttr === 'keep') {
      return;
    }
    if (ddAttr === 'close') {
      this.setClose();
      return;
    }
    const { closeAlways, keepOpen } = this.props;
    if (keepOpen) {
      return;
    }
    if (closeAlways || !contains(this.element, event.target)) {
      if (this.props.onHideEvent && this.state.isOpen) {
        this.props.onHideEvent();
      }
      this.setClose();
    }
  }

  render() {
    const {
      text,
      children,
      fluid,
      up,
      narrow,
      buttonClassName,
      icon,
      buttonId,
      title,
      hideArrow,
      disabled,
      positionRight,
      extraDropdownClass,
    } = this.props;
    const { isOpen } = this.state;
    const btnClassName = buttonClassName || 'btn btn-block';

    const className = classNames('dropdown', extraDropdownClass, {
      'is-open': isOpen,
      'dropdown-fluid': fluid,
      'dropdown-up': up,
      'dropdown-narrow': narrow,
      'dropdown-right': positionRight,
    });


    return (
      <div className={className} ref={element => this.element = element}>
        <button type="button" className={btnClassName} onClick={this.onButtonClick} ref={element => this.button = element} id={buttonId} title={title} disabled={disabled}>
          {!!icon && <Icon className="svg-icon" iconName={icon} />} {text}
          {!hideArrow &&
            <span className="arrow-down dropdown-arrow" />
          }
        </button>
        {isOpen &&
          <div className="dropdown-content">
            {children}
          </div>
        }
      </div>
    );
  }
}

export default Dropdown;
