
import React from 'react';

import Tooltip from 'components/Tooltip';
import ButtonAcceptNT from './ButtonAcceptNT';
import { renderNTAcceptTooltip } from './utils';

const ButtonAcceptDisabledNT = ({ onAcceptConfirm, isDisabled = false }) => (
  <Tooltip className="top-btn-tooltip" isHtml content={renderNTAcceptTooltip()}>
    <ButtonAcceptNT isDisabled={isDisabled} onAcceptConfirm={onAcceptConfirm} />
  </Tooltip>
);

export default ButtonAcceptDisabledNT;
