import { createSelector } from 'reselect';

const getSearch = state => state.help.search;
const getFaqList = state => Object.values(state.help.faqList);
const getGuideList = state => Object.values(state.help.guideList);

export const getGuideListFiltered = createSelector(
  [getSearch, getGuideList],
  (search, guideList) => {
    if (!search) {
      return guideList;
    }
    const searchLower = search.toLowerCase();
    return guideList.filter((guideItem) => {
      const cleanTitle = guideItem.title;
      const title = cleanTitle.replace(/<[^>]+>/g, '');
      return title.toLowerCase().indexOf(searchLower) > -1;
    });
  },
);

export const getFaqListFiltered = createSelector(
  [getSearch, getFaqList],
  (search, faqList) => {
    if (!search) {
      return faqList;
    }
    const searchLower = search.toLowerCase();

    return faqList.filter((faqItem) => {
      const title = faqItem.title.replace(/<[^>]+>/g, '');
      const text = faqItem.text.replace(/<[^>]+>/g, '');

      return title.toLowerCase().indexOf(searchLower) > -1 || text.indexOf(searchLower) > -1;
    });
  },
);
