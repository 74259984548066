/* eslint-disable consistent-return */
import { urls } from 'config';

import authFetch from 'utils/authFetch';

import { notificationSetMessageRead } from 'store/notification/actions';

import {
  ORDERS_MESSAGES_REQUEST,
  ORDERS_MESSAGES_RECEIVE,

  ORDERS_MESSAGES_SET_READ_REQUEST,
  ORDERS_MESSAGES_SET_READ_RECEIVE,

  ORDERS_MESSAGES_ADD_CONFIRM_REQUEST,
  ORDERS_MESSAGES_ADD_CONFIRM_RECEIVE,

  ORDERS_MESSAGES_DELETE_CONFIRM_REQUEST,
  ORDERS_MESSAGES_DELETE_CONFIRM_RECEIVE,

  ORDERS_MESSAGES_ADD_REQUEST,
  ORDERS_MESSAGES_ADD_RECEIVE,

  ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE,
} from '../constants';

import {
  orderDetailsNaError,
  orderDetailsError,
} from './details';

const ordersMessagesRequest = number => ({
  type: ORDERS_MESSAGES_REQUEST,
  number,
});

const ordersMessagesReceive = (number, messages, webhook) => ({
  type: ORDERS_MESSAGES_RECEIVE,
  number,
  messages,
  webhook,
});

const ordersMessagesSetReadRequest = (number, messageId) => ({
  type: ORDERS_MESSAGES_SET_READ_REQUEST,
  number,
  messageId,
});

const ordersMessagesSetReadReceive = (number, messageId, message) => ({
  type: ORDERS_MESSAGES_SET_READ_RECEIVE,
  number,
  messageId,
  message,
});

const ordersMessagesAddConfirmRequest = (number, message) => ({
  type: ORDERS_MESSAGES_ADD_CONFIRM_REQUEST,
  number,
  message,
});

const ordersMessagesAddConfirmReceive = (number, to, message, files) => ({
  type: ORDERS_MESSAGES_ADD_CONFIRM_RECEIVE,
  number,
  message,
  to,
  files,
});

const ordersMessagesDeleteConfirmRequest = (number, messages) => ({
  type: ORDERS_MESSAGES_DELETE_CONFIRM_REQUEST,
  number,
  messages,
});

const ordersMessagesDeleteConfirmReceive = (number, messages) => ({
  type: ORDERS_MESSAGES_DELETE_CONFIRM_RECEIVE,
  number,
  messages,
});

const ordersMessagesAddRequest = number => ({
  type: ORDERS_MESSAGES_ADD_REQUEST,
  number,
});

const ordersMessagesAddReceive = (number, message) => ({
  type: ORDERS_MESSAGES_ADD_RECEIVE,
  number,
  message,
});

const messageConvertToTicketRequest = (number, messageId, ticketId) => ({
  type: ORDERS_MESSAGES_SET_READ_REQUEST,
  number,
  messageId,
  ticketId,
});

const messageConvertToTicketReceive = (number, messageId, ticketId, message) => ({
  type: ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE,
  number,
  ticketId,
  messageId,
  message,
});

const ordersMessagesShouldFetch = (number, state) => {
  const order = state.orders[number];

  if (!order || !order.messages) return true;

  if (order.messages.isFetching) {
    return false;
  }
  return order.messages.didInvalidate;
};

export const ordersMessagesFetch = (number, orderId) => (dispatch) => {
  dispatch(ordersMessagesRequest(number));

  return authFetch(`${urls.orders}/${orderId}/messages`).then((json) => {
    const messages = json.results.reduce((result, item) => {
      result[item._id] = item;
      return result;
    }, {});
    const webhook = json.wh;
    dispatch(ordersMessagesReceive(number, messages, webhook));
  }).catch((error) => {
    console.log(error);
    if (error.status === 405 || error.status === 422) {
      dispatch(orderDetailsNaError(number));
      return;
    }
    dispatch(orderDetailsError(number));
  });
};

export const ordersMessagesFetchIfNeeded = (number, orderId) => (dispatch, getState) => {
  if (ordersMessagesShouldFetch(number, getState())) {
    return dispatch(ordersMessagesFetch(number, orderId));
  }
};

export const ordersMessagesSetReadFetch = (number, messageId, message = 'read_confirm') => (dispatch, getState) => {
  const order = getState().orders[number] || {};
  const { _id: orderId } = order;

  if (!orderId) {
    console.error('Order is not found');
    return;
  }
  dispatch(ordersMessagesSetReadRequest(number, messageId));
  return authFetch(`${urls.orders}/${orderId}/messages/${messageId}`, {
    method: 'PATCH',
    body: JSON.stringify({ status: message }),
  }).then((data) => {
    dispatch(ordersMessagesSetReadReceive(number, messageId, data));
    dispatch(notificationSetMessageRead(messageId));
  }).catch((error) => {
    console.log(error);
    dispatch(orderDetailsError(number));
  });
};

export const ordersMessagesAddConfirmFetch = (number, to, message, files = []) => (dispatch) => {
  dispatch(ordersMessagesAddConfirmRequest(number, message));
  return dispatch(ordersMessagesAddConfirmReceive(number, to, message, files));
};

export const ordersMessagesDeleteConfirmFetch = (number, messages) => (dispatch) => {
  dispatch(ordersMessagesDeleteConfirmRequest(number, messages));
  return dispatch(ordersMessagesDeleteConfirmReceive(number, messages));
};

export const ordersMessagesAddFetch = (number, to, message, files) => (dispatch, getState) => {
  const order = getState().orders[number] || {};
  const { _id: orderId } = order;

  if (!orderId) {
    console.error('Order is not found');
    return;
  }

  dispatch(ordersMessagesAddRequest(number));

  return authFetch(`${urls.orders}/${orderId}/messages`, {
    method: 'POST',
    body: JSON.stringify({ message, to_user: to, files }),
  }).then(data => dispatch(ordersMessagesAddReceive(number, data))).catch((error) => {
    console.log(error);
    dispatch(orderDetailsError(number));
  });
};

export const ordersMessagesAddAttachFetch = (number, to, files, message) => (dispatch, getState) => {
  const order = getState().orders[number] || {};
  const { _id: orderId } = order;

  if (!orderId) {
    console.error('Order is not found');
    return;
  }

  dispatch(ordersMessagesAddRequest(number));
  const fileList = files.map(file => ({
    file: file.name,
    location: file.id,
    size: file.size,
    type: 'msgs',
  }));
  return dispatch(ordersMessagesAddFetch(number, to, message, fileList));
};

export const messageConvertToTicketFetch = (number, messageId, ticketId) => (dispatch, getState) => {
  const order = getState().orders[number] || {};
  const { _id: orderId } = order;

  if (!orderId) {
    console.error('Order id not found');
    return;
  }

  dispatch(messageConvertToTicketRequest(number, messageId, ticketId));

  return authFetch(`${urls.orders}/${orderId}/messages/${messageId}`, {
    method: 'PATCH',
    body: JSON.stringify({ ticket_id: ticketId }),
  }).then((data) => {
    dispatch(messageConvertToTicketReceive(number, messageId, ticketId, data));
  }).catch((error) => {
    console.log(error);
    dispatch(orderDetailsError(number));
  });
};
