import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { trackValues } from 'lrtracker';
import { useConnectContext } from '@connect/connect-xmpp';

import { closeModalComponent, addModalComponent } from 'store/modals/actions';
import { ordersDetailsReceive, ordersDetailsFetch } from 'store/orders/actions';

import { urls } from 'config';

import authFetch from 'utils/authFetch';

import modalList from 'constants/modalList';

import ReservedCounter from 'components/ReservedCounter';
import TemplateModal from 'components/TemplateModal';

const Introduce = ({
  order, profile, nextWillOpen, setAutoOpenStep, onClose,
}) => {
  const {
    join_chat_at: joinChatAt,
    jabber_node: node, jabber_server: to,
    number,
    _id: orderId,
  } = order;
  const { public_name: tutorName, jabber_jid: username } = profile;

  const dispatch = useDispatch();

  const clientChatConfig = {
    username,
    node,
    to,
  };

  const { send } = useConnectContext();

  const onSubmit = (text = '') => {
    dispatch(closeModalComponent(modalList.Templates.name));
    const trimmedMessage = text.trim();

    if (!trimmedMessage) return;

    authFetch(urls.onChatMessage(orderId), {
      method: 'POST',
      body: '',
    }).then(() => {
      trackValues('stage', 'tutorIntroductionCompleted', { context: { stage: 'ClientTutorChat', order_id: orderId } });
      setTimeout(() => {
        send({ variables: clientChatConfig, message: trimmedMessage.replaceAll('<br />', ' ') });
      }, 1000);
    });
    authFetch(urls.updateOrder(orderId), {
      method: 'POST',
      body: JSON.stringify({
        action: 'set_chat_progress_state',
        introduction: true,
      }),
    })
      .then((response) => {
        dispatch(ordersDetailsReceive(response.number, response));
        setAutoOpenStep('unfold');
        setTimeout(() => {
          setAutoOpenStep(nextWillOpen);
        }, 100);
      });
  };

  const onTemplateShow = () => {
    const dataModal = modalList.Templates;

    dispatch(addModalComponent({
      component: <TemplateModal
        textReplaceHandler={text => text.replace('[TutorName]', tutorName)}
        onSubmit={onSubmit}
        onClose={onClose}
      />,
      ...dataModal,
    }));
  };

  const onWaitMsgOver = () => {
    dispatch(ordersDetailsFetch(number));
    dispatch(closeModalComponent(modalList.Templates.name));
  };

  return (
    <div className="step-container">
      <div className="step-container__title">
        Introduce yourself to the client within 5 minutes after you joined chat, or you will be rejected from this chat.
      </div>
      <button className="btn btn-light mb8" type="button" onClick={onTemplateShow}>Send introduction template</button>
      <div className="row">
        <div className="mr3 no-wrap fsXSm success-text">Time left to send introduction:</div>
        <ReservedCounter textClass="fsXSm" time={moment(joinChatAt).add(5, 'minutes')} onOver={onWaitMsgOver} />
      </div>
    </div>
  );
};

export default Introduce;
