import { IS_SHOWED_BROWSER_NOTIFICATIONS_MODAL } from 'constants/localStorage';


const TOKEN_KEY = 'token';

let memoryToken = null;

export const setToken = (token) => {
  memoryToken = token;
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
  if (!memoryToken) {
    memoryToken = localStorage.getItem(TOKEN_KEY);
  }
  return memoryToken;
};

export const getTokenExact = () => (getToken() || '').replace(/^(Bearer\s)/, '');

export const getTokenExpire = () => {
  const tokenPart = getTokenExact().split('.', 2)[1];
  const tokenDecoded = JSON.parse(atob(tokenPart));
  return tokenDecoded.exp;
};

export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  memoryToken = null;
  localStorage.removeItem(IS_SHOWED_BROWSER_NOTIFICATIONS_MODAL);
};
