import { createSelector } from 'reselect';

import { sortByOrderNumber, getSorterByField } from './../orderSorters';
import { getOrderIdsWithUnreadMessages } from './../notification/selectors';

const stable = require('stable');

const getOrderList = state => state.ordersRevision.numbers.map(number => state.orders[number]);
const getSorting = state => state.ordersRevision.sorting;
const getFilter = state => state.ordersRevision.filter;
const getChatNotifications = state => state.clientChatNotifications.notifications;

const filterBySearch = (filterSearch, orderList) => (!filterSearch ? orderList : orderList.filter(item => item.number.toLowerCase().indexOf(filterSearch.toLowerCase()) > -1));

const ordersFiltererSorter = (filter, sorting, unreadOrderIds, orderList, chatNotifications) => {
  let orders = orderList;
  if (filter.search) {
    orders = filterBySearch(filter.search, orderList);
  }
  if (filter.new_messages) {
    orders = orderList.filter(order => unreadOrderIds.includes(order._id) || (order.jabber_node && chatNotifications.find(o => o.node === order.jabber_node && o.count > 0)));
  }
  const ordersSortedByNumber = stable(orders, sortByOrderNumber);
  const sorter = getSorterByField(sorting.field, sorting.isAsc, unreadOrderIds);
  return stable(ordersSortedByNumber, sorter);
};

export const getUnreadRevisionOrdersMessages = state => state.ordersRevision.unreadOrderMessages > 0;

export const getOrdersRevisionSorted = createSelector([getFilter, getSorting, getOrderIdsWithUnreadMessages, getOrderList, getChatNotifications], ordersFiltererSorter);
