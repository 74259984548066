import React, { useCallback, useState, useEffect } from 'react';

import FilesPreview from 'components/FilesPreview';

import { onDownloadAll } from 'utils/downloadFile';

const FilePreviewPlugin = ({ id, data, updateHeader }) => {
  const [checkDownloadFetching, setCheckDownloadFetching] = useState(false);

  const updateHeaderHandler = useCallback(
    (name) => {
      updateHeader(`${data.orderNumber}__${name}`);
    },
    [updateHeader],
  );

  const downloadAllHandler = useCallback(
    (selectedFiles) => {
      setCheckDownloadFetching(true);
      const { docs, orderNumber, fileWebhook } = data;
      onDownloadAll({
        fileList: docs,
        orderNumber,
        selectedFiles,
        fileWebhook,
      })
        .finally(() => {
          setCheckDownloadFetching(false);
        });
    },
    [data, setCheckDownloadFetching],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.setProperty('overflow', 'hidden');
    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);


  return (
    <FilesPreview {...data} downloadAll={downloadAllHandler} updateHeader={updateHeaderHandler} checkDownloadFetching={checkDownloadFetching} />
  );
};

export default FilePreviewPlugin;
