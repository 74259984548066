import {
  USER_REQUEST,
  USER_RECEIVE,
  USER_RECEIVE_SOCKET_UPDATE,
  USER_INVALIDATE,
  USER_FEEDBACKS_REQUEST,
  USER_FEEDBACKS_RECEIVE,
  USER_TASKS_REQUEST,
  USER_TASKS_RECEIVE,
  USER_WARNING_REQUEST,
  USER_WARNING_RECEIVE,
  USER_WARNINGS_REQUEST,
  USER_WARNINGS_RECEIVE,
  USER_ACTIVE_WARNINGS_REQUEST,
  USER_ACTIVE_WARNINGS_RECEIVE,
  USER_WARNING_SET_VIEWED_REQUEST,
  USER_WARNING_SET_VIEWED_RECEIVE,
  USER_SET_FEEDBACK_REQUEST,
  USER_SET_FEEDBACK_RECEIVE,
  USER_SET_AVAILABLE_REQUEST,
  USER_SET_AVAILABLE_RECEIVE,
  USER_SET_SUBJECTS_REQUEST,
  USER_SET_SUBJECTS_RECEIVE,
  USER_SET_FIELD_REQUEST,
  USER_SET_FIELD_RECEIVE,
  USER_SET_FIELDS_REQUEST,
  USER_SET_FIELDS_RECEIVE,
  USER_SET_FIELD,
  USER_FILE_UPLOAD_REQUEST,
  USER_FILE_UPLOAD_PROGRESS,
  USER_FILE_UPLOAD_RECEIVE,
  USER_ACTION_REQUEST,
  USER_ACTION_RECEIVE,
  USER_SET_SHOW_PROPOSED,
  USER_SET_FIELD_RECEIVE_PAYMENT_ERROR,
  USER_CLEAR_PAYMENT_ERROR,
} from './actions';

import moment from 'moment-timezone';

const activeWarningsReducer = (initial, current) => initial + current.active;

const tasks = (state = {}, action) => {
  switch (action.type) {
    case USER_TASKS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case USER_TASKS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.tasks,
      });
    default:
      return state;
  }
};

const feedbacks = (state = {}, action) => {
  switch (action.type) {
    case USER_FEEDBACKS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case USER_FEEDBACKS_RECEIVE:
      if (action.page > 1) {
        return Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          ...action.feedbacks,
          results: [...state.results, ...action.feedbacks.results],
        });
      }
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        ...action.feedbacks,
      });
    case USER_SET_FEEDBACK_RECEIVE:
      const newFeedbacks = [];
      state.results.map((i) => {
        if (i._id === action.data._id) {
          newFeedbacks.push(action.data);
        } else {
          newFeedbacks.push(i);
        }
      });
      return Object.assign({}, state, {
        results: newFeedbacks,
      });
    default:
      return state;
  }
};

const warning = (state = {}, action) => {
  switch (action.type) {
    case USER_WARNING_REQUEST:
    case USER_WARNING_SET_VIEWED_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case USER_WARNING_RECEIVE:
    case USER_WARNING_SET_VIEWED_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetching: false,
      });
    default:
      return state;
  }
}

const warnings = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVE_WARNINGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case USER_ACTIVE_WARNINGS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.warnings,
        activeCount: action.warnings.reduce(activeWarningsReducer, 0),
      });
    case USER_WARNINGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case USER_WARNINGS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.warnings,
        activeCount: action.warnings.reduce(activeWarningsReducer, 0),
      });
    case USER_WARNING_REQUEST:
    case USER_WARNING_RECEIVE:
    case USER_WARNING_SET_VIEWED_REQUEST:
      return Object.assign({}, state, {
        [action.warningTag]: warning(state[action.warningTag], action),
      });
    case USER_WARNING_SET_VIEWED_REQUEST:
    case USER_WARNING_SET_VIEWED_RECEIVE:
      return Object.assign({}, state, {
        items: state.items.map(item =>
          item.tag === action.warningTag ?
            Object.assign({}, item, {
              not_seen: 0,
            }) :
            item),
        [action.warningTag]: warning(state[action.warningTag], action),
      })
    default:
      return state;
  }
}

const activeWarnings = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVE_WARNINGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case USER_ACTIVE_WARNINGS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        results: action.data,
      });
    default:
      return state;
  }
}

const file = (state = {}, action) => {
  switch (action.type) {
    case USER_FILE_UPLOAD_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        progress: 0,
        name: action.fileName
      });
    case USER_FILE_UPLOAD_PROGRESS:
      return Object.assign({}, state, {
        isFetching: true,
        progress: action.progress
      });
    case USER_FILE_UPLOAD_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetching: false,
        progress: null
      });
    default:
      return state;
  }
};

const userInner = (state, action) => {
  switch (action.type) {
    case USER_INVALIDATE:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case USER_REQUEST:
      return Object.assign({}, state || {}, {
        isFetching: true,
        didInvalidate: false
      });
    case USER_RECEIVE:
      moment.tz.setDefault(action.user.timezone_str);
      return Object.assign({}, state, action.user, {
        isFetching: false,
        didInvalidate: false,
        showAgreementWizard: !action.user.profile_tag_policies_agree || !action.user.profile_tag_terms_agree
      });
    case USER_RECEIVE_SOCKET_UPDATE:
      moment.tz.setDefault(action.user.timezone_str);
      return Object.assign({}, state, action.user, {
        public_name: action.user.public_name || '',
        public_initial: action.user.public_initial || '',
        public_photo: action.user.public_photo || '',
        public_overview: action.user.public_overview || '',
      });
    case USER_TASKS_REQUEST:
    case USER_TASKS_RECEIVE:
      return Object.assign({}, state, {
        tasks: tasks(state.tasks, action)
      });
    case USER_FEEDBACKS_REQUEST:
    case USER_FEEDBACKS_RECEIVE:
    case USER_SET_FEEDBACK_RECEIVE:
      return Object.assign({}, state, {
        feedbacks: feedbacks(state.feedbacks, action),
      });
    case USER_WARNINGS_REQUEST:
    case USER_WARNINGS_RECEIVE:
    case USER_WARNING_REQUEST:
    case USER_WARNING_RECEIVE:
    case USER_WARNING_SET_VIEWED_REQUEST:
    case USER_WARNING_SET_VIEWED_RECEIVE:
      return Object.assign({}, state, {
        warnings: warnings(state.warnings, action)
      });
    case USER_ACTIVE_WARNINGS_REQUEST:
    case USER_ACTIVE_WARNINGS_RECEIVE:
      return Object.assign({}, state, {
        activeWarnings: activeWarnings(state.activeWarnings, action),
      });
    case USER_SET_AVAILABLE_REQUEST:
      return Object.assign({}, state, {
        isFetchingAvailable: true
      });
    case USER_SET_AVAILABLE_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetchingAvailable: false
      });
    case USER_SET_FEEDBACK_REQUEST:
      return Object.assign({}, state, {
        isFetchingFeedback: true
      });
    case USER_SET_FEEDBACK_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetchingFeedback: false,
      });
    case USER_SET_SUBJECTS_REQUEST:
      return Object.assign({}, state, {
        subjects: action.subjects,
        isFetchingSubjects: true
      });
    case USER_SET_SUBJECTS_RECEIVE:

      return Object.assign({}, state, action.data, {
        isFetchingSubjects: false
      });
    case USER_SET_FIELD_REQUEST:
      return Object.assign({}, state, {
        [action.name]: action.value,
        fetchingFields: [...state.fetchingFields, action.name]
      });
    case USER_SET_FIELD_RECEIVE:
      return Object.assign({}, state, action.data, {
        fetchingFields: state.fetchingFields.filter(field => field !== action.name)
      });
    case USER_SET_FIELD_RECEIVE_PAYMENT_ERROR:
      return Object.assign({}, state, {
        isPayMethodError: true,
        fetchingFields: state.fetchingFields.filter(field => field !== action.name),
        [action.name]: action.value,
      });
    case USER_CLEAR_PAYMENT_ERROR:
      return Object.assign({}, state, {
        isPayMethodError: false,
    });
    case USER_SET_FIELDS_REQUEST:
      return Object.assign({}, state, {
        ...action.data,
        fetchingFields: [...state.fetchingFields, Object.keys(action.data)]
      });
    case USER_SET_FIELDS_RECEIVE:
      const keys = Object.keys(action.data);
      return Object.assign({}, state, action.json, {
        fetchingFields: state.fetchingFields.filter(field => keys.indexOf(field) === -1)
      });
    case USER_SET_FIELD:
      return Object.assign({}, state, {
        [action.name]: action.value
      });
    case USER_FILE_UPLOAD_REQUEST:
    case USER_FILE_UPLOAD_PROGRESS:
    case USER_FILE_UPLOAD_RECEIVE:
      return Object.assign({}, state, {
        [action.fileType]: file(state[action.fileType], action)
      });
    case USER_ACTION_REQUEST:
      return {
        ...state,
        isFetchingAction: true
      };
    case USER_ACTION_RECEIVE:
      return {
        ...state,
        ...action.data,
        isFetchingAction: false
      };
    default:
      return state;
  }
};

const user = (state = {
  didInvalidate: true,
  fetchingFields: [],
  isFetchingAction: false,
}, action) => {
  switch (action.type) {
    case USER_SET_SHOW_PROPOSED:
      return {
        ...state,
        showProposed: action.value,
        proposedOpenPlace: action.openPlace,
      };
    default:
      {
        const newState = userInner(state, action);
        newState.showProposed = state.showProposed;
        return newState;
      }
  }
};

export default user;
