import { createSelector } from 'reselect';
import moment from 'moment-timezone';

const stable = require('stable');

const getFinanceList = state => state.finances.items ? Object.values(state.finances.items) : [];

const getFilterTime = state => state.finances.filter.time;
const getFilterType = state => state.finances.filter.type;
const getFilterStatus = state => state.finances.filter.status;

const getFinancesByTimeFilter = createSelector(
    [getFilterTime, getFinanceList],
    (filter, financeList) => {
        const stamp = moment();
        switch (filter) {
            case 'thisMonth': {
                stamp.startOf('month');
                break;
            }
            case 'lastMonth': {
                stamp.startOf('month').subtract(1, 'month');
                break;
            }
            case 'thisYear': {
                stamp.startOf('year');
                break;
            }
            case 'lastYear': {
                stamp.startOf('year');
                return financeList.filter(item => moment(item.created_at).isSameOrBefore(stamp));
            }
            default:
                return financeList;
        }
        return financeList.filter(item => moment(item.created_at).isSameOrAfter(stamp));
    }
);

const typeChecker = (finance, filter) => {
    const { target, amount } = finance;
    switch (filter) {
        case 'withdrawal':
            return target === filter;
        case 'payment':
            return target === 'order';
        case 'bonus':
            return target === 'bonus' && amount >= 0;
        case 'fine':
            return target === 'bonus' && amount < 0;
        default:
            return true;
    }
};
const getFinancesByTypeFilter = createSelector(
    [getFilterType, getFinancesByTimeFilter],
    (filter, financeList) => filter === 'all' ? financeList : financeList.filter(finance => typeChecker(finance, filter))
);

const getFinancesByStatusFilter = createSelector(
    [getFilterStatus, getFinancesByTypeFilter],
    (filter, financeList) => filter === 'all' ? financeList : financeList.filter(finance => finance.status === filter)
);

export const getFinancesByFilterSorted = createSelector(
    [getFinancesByStatusFilter],
    financeList => stable(financeList, (a, b) => moment(b.created_at).diff(moment(a.created_at)))
);

const typeGetter = finance => {
    const { target, amount } = finance;
    switch (target) {
        case 'withdrawal':
            return target;
        case 'order':
            return 'payment'
        case 'bonus':
            return amount < 0 ? 'fine' : 'bonus';
        default:
            return 'other';
    }
};
export const getFinanceTypes = createSelector(
    [getFinanceList],
    financeList => [...financeList.reduce((result, item) => result.add(typeGetter(item)), new Set().add('all'))]
);

export const getFinanceStatuses = createSelector(
    [getFinanceList],
    financeList => [...financeList.reduce((result, item) => result.add(item.status), new Set().add('all'))]
);

