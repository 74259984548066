const ONE_WEEK = 604800000;
const ONE_DAY = 86400000;
const ONE_HOUR = 3600000;
const ONE_MINUTE = 60000;
const ONE_SECOND = 1000;
const TIME_BEFORE_LOGOUT = 900000; // millisecs, 15min

export default {
	ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_SECOND, ONE_WEEK, TIME_BEFORE_LOGOUT,
};
