import React, { Component } from 'react';
import moment from 'moment-timezone';
import shortid from 'shortid';
import classNames from './../../utils/classNames';

import Icon from './../Icon';
import Emoji, { emojiList } from './../Emoji';
import Dropdown from './../Dropdown';
import Select from './../Select';
import UploadButton from './../UploadButton';
import Modal from './../Modal';
import WriterTicketingIssue from './../WriterTicketing/WriterTicketingIssue';

import createAmazonUpload from './../../utils/createAmazonUpload';
import storage from './../../utils/localStorageHandler';

const STORAGE_KEY = 'MESSAGE_BOX';

class OrderMessageBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: storage.get(STORAGE_KEY) || '',
      files: {},
      isOpenForm: false,
    };

    this.onMessageChange = this.onMessageChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.setEmoji = this.setEmoji.bind(this);
    this.onFileUploadProgress = this.onFileUploadProgress.bind(this);
    this.onFileUploadComplete = this.onFileUploadComplete.bind(this);
    this.onFileUploadError = this.onFileUploadError.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.onOpenForm = this.onOpenForm.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isLoadingAdd } = this.props;
    if (prevProps.isLoadingAdd && !isLoadingAdd) {
      this.setState({
        message: '',
        files: {},
      });
      storage.remove(STORAGE_KEY);
    }
  }

  onOpenForm() {
    this.setState({
      isOpenForm: !this.state.isOpenForm,
    });
  }

  onFileUploadProgress(fileId, progress) {
    this.updateFile(fileId, { progress });
  }

  onFileUploadComplete(fileId) {
    this.updateFile(fileId, { progress: 100, completed: true });
  }

  onFileUploadError(fileId) {
    this.updateFile(fileId, { progress: 100, error: 'Upload errror' });
  }

  onFileUploadAbort(fileId) {
    const { files } = this.state;
    files[fileId].abort();
    const newFiles = { ...files };
    delete newFiles[fileId];
    this.setState({ files: newFiles });
  }

  onMessageChange(event) {
    const { target } = event;
    const message = target.value;
    this.setState({ message });
    storage.set(STORAGE_KEY, message);
  }

  onFileChange(event) {
    const { files } = this.state;
    const { orderNumber } = this.props;
    const inputFiles = event.target.files;
    const stateFiles = { ...files };
    for (let i = 0; i < inputFiles.length; i++) {
      const createUpload = createAmazonUpload(
        orderNumber,
        inputFiles[i],
        this.onFileUploadProgress,
        this.onFileUploadComplete,
        this.onFileUploadError,
      );
      const fileItem = {
        created_at: moment().utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        id: createUpload.location,
        _id: shortid.generate(),
        location: createUpload.location,
        name: inputFiles[i].name,
        abort: createUpload.abort,
        completed: false,
        progress: 0,
        error: null,
        size: inputFiles[i].size,
        owner_role: 'writer',
      };
      stateFiles[fileItem.id] = fileItem;
    }
    this.setState({ files: stateFiles });
    event.target.value = null;
  }

  setEmoji(value) {
    this.setState(state => Object.assign({}, state, {
      message: state.message + value,
    }));
  }

  sendMessage(value) {
    const { message, files } = this.state;
    const { addMessage, addMessageAttach, addMessageConfirm, isLoadingAdd } = this.props;
    const fileList = Object.values(files);
    const allUploaded = fileList.every(item => item.completed);

    if (!allUploaded) {
      return;
    }
    const messageTrim = message.trim();
    const cntMessageWords = messageTrim.split(/\s+/);
    if ((!messageTrim && !fileList.length) || isLoadingAdd) {
      return;
    }
    if (value === 'client' || cntMessageWords.length < 4) {
      !fileList.length ? addMessage(value, messageTrim) : addMessageAttach(value, fileList, messageTrim);
    } else {
      !fileList.length ? addMessageConfirm(value, messageTrim) : addMessageConfirm(value, messageTrim, fileList);
      this.setState({
        files: [],
        message: '',
      });
      // storage.set(STORAGE_KEY, '');
    }
  }

  updateFile(fileId, data) {
    const { files } = this.state;
    const newFiles = { ...files };
    newFiles[fileId] = { ...newFiles[fileId], ...data };
    this.setState({ files: newFiles });
  }

  renderFile(file) {
    return (
      <div className="row upload-file-box" key={file.id}>
        <div className="upload-file-item">
          <a target="_blank" rel="noopener noreferrer">
            <span>{file.name} {file.error}</span>
            {!file.completed &&
              <i style={{ width: `${file.progress}%` }} />
            }
          </a>
          <button className="btn btn-empty btn-delete-file" type="button" onClick={() => this.onFileUploadAbort(file.id)}>
            <svg className="delete-file">
              <g>
                <circle cx="8" cy="8" r="8" />
                <path fill="#9CA9B5" fillRule="nonzero" d="M8.667 8L11 5.667 10.333 5 8 7.333 5.667 5 5 5.667 7.333 8 5 10.333l.667.667L8 8.667 10.333 11l.667-.667L8.667 8z" />
              </g>
            </svg>
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { message, files, isOpenForm } = this.state;
    const { isLoadingAdd, orderNumber, isDisabled, orderId, orderTitle, order } = this.props;
    const fileList = Object.values(files);
    const isANOrder = orderNumber && !!orderNumber.match(/^an/i);

    return (
      <div className="static-bottom">
        {isOpenForm &&
          <div className="container-ticketing">
            <Modal onClose={this.onOpenForm} wide notHandleOutside>
              <WriterTicketingIssue onClose={this.onOpenForm} orderNumber={orderNumber} orderId={orderId} orderTitle={orderTitle} orderDetails={order} callFromOrderContent />
            </Modal>
          </div>
        }
        <div className="message-box-panel">
          <p>Let us know about any issue you've faced while woking on this order.</p>
          <button className="btn-link" onClick={this.onOpenForm}>Click here to report</button>
        </div>

        <div className={classNames("send-message", { "disabled": isDisabled })}>
          <div className="row vertical">
            <div className="col-8">
              <div>
                <div className="row vertical no-wrap">
                  <div className="col-0 send-message-btn">
                    <UploadButton className="btn-clip" onChange={this.onFileChange} multiple>
                      <Icon className="svg-icon" iconName="clip" />
                    </UploadButton>
                  </div>
                  <div className="message-wrap col-6">
                    <div className="message-copy">{message}{'\n'}</div>
                    <textarea placeholder={isDisabled ? 'Report an issue above if you have any' : 'Type a message'} value={message} onChange={this.onMessageChange} disabled={isLoadingAdd} />
                  </div>
                </div>
              </div>
              <div className="upload-files">
                {fileList.map(this.renderFile)}
              </div>
            </div>
            <div className="col-4 row vertical">
              <div className="btns-wrap col-1">
                <Dropdown buttonClassName="btn-smile row vertical" up icon="smile" btn btn-bright>
                  <Select className="dropdown-content-smile" onChange={this.setEmoji}>
                    <a className="smile-item" value={emojiList.smile}><Emoji id="smile" /></a>
                    <a className="smile-item" value={emojiList[':)']}><Emoji id=":)" /></a>
                    <a className="smile-item" value={emojiList[':(']}><Emoji id=":(" /></a>
                    <a className="smile-item" value={emojiList[':D']}><Emoji id=":D" /></a>
                    <a className="smile-item" value={emojiList.cool}><Emoji id="cool" /></a>
                    <a className="smile-item" value={emojiList.hearteyes}><Emoji id="hearteyes" /></a>
                    <a className="smile-item" value={emojiList.like}><Emoji id="like" /></a>
                    <a className="smile-item" value={emojiList.unamused}><Emoji id="unamused" /></a>
                    <a className="smile-item" value={emojiList.cwl}><Emoji id="cwl" /></a>
                    <a className="smile-item" value={emojiList[':O']}><Emoji id=":O" /></a>
                    <a className="smile-item" value={emojiList[';)']}><Emoji id=";)" /></a>
                    <a className="smile-item" value={emojiList[';(']}><Emoji id=";(" /></a>
                    <a className="smile-item" value={emojiList['(:|']}><Emoji id="(:|" /></a>
                    <a className="smile-item" value={emojiList[':|']}><Emoji id=":|" /></a>
                    <a className="smile-item" value={emojiList[':P']}><Emoji id=":P" /></a>
                    <a className="smile-item" value={emojiList[':$']}><Emoji id=":$" /></a>
                    <a className="smile-item" value={emojiList[':^)']}><Emoji id=":^)" /></a>
                    <a className="smile-item" value={emojiList['|-)']}><Emoji id="|-)" /></a>
                    <a className="smile-item" value={emojiList['|-(']}><Emoji id="|-(" /></a>
                    <a className="smile-item" value={emojiList.yawn}><Emoji id="yawn" /></a>
                    <a className="smile-item" value={emojiList.puke}><Emoji id="puke" /></a>
                    <a className="smile-item" value={emojiList.rainbowsmile}><Emoji id="rainbowsmile" /></a>
                    <a className="smile-item" value={emojiList[':x']}><Emoji id=":x" /></a>
                    <a className="smile-item" value={emojiList.devil}><Emoji id="devil" /></a>
                    <a className="smile-item" value={emojiList.envy}><Emoji id="envy" /></a>
                    <a className="smile-item" value={emojiList.makeup}><Emoji id="makeup" /></a>
                    <a className="smile-item" value={emojiList.think}><Emoji id="think" /></a>
                    <a className="smile-item" value={emojiList.smirk}><Emoji id="smirk" /></a>
                    <a className="smile-item" value={emojiList.nod}><Emoji id="nod" /></a>
                    <a className="smile-item" value={emojiList.donttalk}><Emoji id="donttalk" /></a>
                    <a className="smile-item" value={emojiList.idea}><Emoji id="idea" /></a>
                  </Select>
                </Dropdown>
                <Dropdown up text="Send" closeAlways buttonClassName="btn btn-bright" buttonId="orderMessageSendBtn">
                  <Select className="menu menu-selectable" onChange={this.sendMessage}>
                    <li value="operator" className="menu-item">Operator</li>
                    {!isANOrder &&
                      <li value="client" className="menu-item">Client</li>
                    }
                    {/* <li value="grader" className="menu-item">Grader</li> */}
                  </Select>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderMessageBox;
