import React from 'react';
import moment from 'moment-timezone';

import classNames from 'utils/classNames';
import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';

import Icon from 'components/Icon';


import { DateTime } from 'luxon';

import {
  STATUS_OPEN,
  STATUS_PROGRESS,
  STATUS_REVISION,
  STATUS_COMPLETE,
  STATUS_DELETED,
} from 'store/orders/actions';

import utils from 'utils';

const getStatus = (order = {}) => {
  if (order.writer_status === 'client_paid') {
    return STATUS_OPEN;
  }
  if (order.system_revision_status === 'accepted') {
    return STATUS_REVISION;
  }
  if (order.writer_status === 'writer_accept') {
    return STATUS_PROGRESS;
  }
  if (order.writer_status === 'writer_completed') {
    return STATUS_COMPLETE;
  }
  return STATUS_DELETED;
};

const orderPrice = ({ order, profile }) => {
  const {
    writer_price, size,
    is_complex = false, extras = [],
    let_tutor_decide: letTutorDecide,
    writer_per_hour_price: writerPerHourPrice,
  } = order;

  let orderSizes = '';
  let orderSizesForMinutes = '';
  const isHaveAcceptedEstimate = utils.isHaveAcceptedEstimate(order);

  if (letTutorDecide && !isHaveAcceptedEstimate) {
    return `$${writerPerHourPrice} / hour`;
  }

  if (is_complex && extras.length > 0) {
    orderSizes = extras.filter(s => s.size && s.payment_status === 'Paid');
    orderSizes = orderSizes.map(s => s.size);
    orderSizesForMinutes = orderSizes.join(' /');
    orderSizes = orderSizes.join('   ·   ');
  }
  const isStem = utils.isStem(profile);

  return isStem ?
    utils.getMinutesSize(orderSizesForMinutes || size) :
    `$${writer_price.toFixed(2)}`;
};

const getGroupDate = (time) => {
  const localTime = DateTime.fromMillis(time).toLocal();
  const curLocalTime = DateTime.now().toLocal();
  const diff = curLocalTime.diff(localTime, 'days').days;
  const roundedDiff = Math.round(diff);

  switch (true) {
    case roundedDiff < 1: {
      return 'Today';
    }
    case roundedDiff < 2: {
      return 'Yesterday';
    }
    case roundedDiff < 7: {
      return localTime.weekdayShort; // day
    }
    default: {
      return localTime.toFormat('MMM dd'); // month & day
    }
  }
};

const sameDay = (curMessageTime, prevMessageTime) =>
  DateTime.fromMillis(curMessageTime).toLocal().startOf('day') <=
  DateTime.fromMillis(prevMessageTime).toLocal().startOf('day');

const isTypesStagesOnlineDashboard = (order) => {
  const { is_complex: isComplex, extras, type_stages: typeStages } = order;

  if (!typeStages) return false;

  if (isComplex && extras && extras.length > 0) {
    extras.some((extra) => {
      const { type_stages: __typeStages = [] } = extra;

      return __typeStages.some(stage => stage === 'Submit to online Dashboard');
    });
  }

  return (typeStages || []).some(stage => stage === 'Submit to online Dashboard');
};

const getOrderType = (order = {}) => {
  const orderSubType = [];
  if (order.is_complex && order.extras && order.extras.length > 0) {
    order.extras.forEach((o) => {
      const stages = o.type_stages && o.type_stages[o.type_stages.length - 1];
      orderSubType.push(stages || o.type);
    });
  } else {
    orderSubType.push(order.type);
  }

  return orderSubType;
};

const renderOrderSize = ({
  order, mobile, isDe, callFromOrderDetails = false,
}) => {
  const { let_tutor_decide: letTutorDecide } = order;
  const isDuplicateAndRevisionOrder = utils.isDuplicateAndRevisionOrder(order);


  const isHaveAcceptedEstimate = utils.isHaveAcceptedEstimate(order);
  const questionContainer = classNames('tooltip-container', { 'tooltip tooltip__icon': mobile });
  let orderSizes = '';
  let orderSizeMH = null;
  const orderSubType = [];
  if (order.is_complex && order.extras && order.extras.length > 0) {
    // orderSizes = order.extras.filter(s => s.size && s.payment_status === 'Paid');
    orderSizes = order.extras.filter(s => s.size);
    orderSizes = orderSizes.map((s) => {
      const [__size, __type] = s.size.split(' ');
      if (__type === 'minutes' && !isDe) {
        return convertMinsToHrsMins(__size);
      }
      return s.size;
    });
    orderSizes = orderSizes.join('   +   ');
    order.extras.forEach((o) => {
      const stages = o.type_stages && o.type_stages[o.type_stages.length - 1];
      orderSubType.push(stages || o.type);
    });
  } else {
    orderSubType.push(order.type);
  }

  if (order.size && !isDe) {
    const [size, type] = order.size.split(' ');
    if (type === 'minutes') {
      orderSizeMH = convertMinsToHrsMins(size);
    }
  }
  const viewSize = orderSizes || orderSizeMH || order.size;

  const extraOrderTooltipClasses = callFromOrderDetails ? 'order-tooltip--right' : '';

  if (letTutorDecide && !isHaveAcceptedEstimate) {
    return (
      <li className={classNames({ 'size-order': !callFromOrderDetails })}>
        <div
          className={classNames({ 'row vertical': !callFromOrderDetails })}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div>
            <strong>
              Your estimate is required
            </strong>
          </div>
          <div className={questionContainer}>
            <Icon className="svg-icon tooltip-trigger tooltip-trigger-size" iconName="question" />
            <div className={classNames('tooltip-content output-format-tooltip output-format-tooltip--estimate', { 'output-format-tooltip--right': callFromOrderDetails })}>
              <div className="mb2">Check task details and send your estimate - how much time do you need to complete this task.</div>
              <ul>
                <li className="row">check task instructions;</li>
                <li className="row">join chat;</li>
                <li className="row">send your estimate to client.</li>
              </ul>
              <div className="mb2">After you provide your estimate student can:</div>
              <ul>
                <li className="row">agree with your estimate and make the payment. In that case you will be able to accept and complete the order;</li>
                <li className="row">decline your estimate.</li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    );
  }

  const renderSizeTooltip = () => {
    if (isDuplicateAndRevisionOrder) {
      return (
        <p className={classNames('tooltip-content size-order-tooltip order-tooltip', extraOrderTooltipClasses)}>This is the amount of work client paid for you to review the initial order and revise final files from it. Our standard order size conversion formula is not applicable for such orders. Please check Revision instructions section for exact guidelines on what needs to be done.</p>
      );
    }

    return (
      <ul className={classNames('tooltip-content size-order-tooltip order-tooltip', extraOrderTooltipClasses)}>
        <li>1 double-spaced page = 300 words</li>
        <li>1 slide = 150 words</li>
        <li>60 minutes - 300 words</li>
      </ul>
    );
  };

  return (
    <li className="size-order">
      {isDuplicateAndRevisionOrder ? (<strong>{viewSize}</strong>) : viewSize}
      <div className={questionContainer}>
        <Icon className="svg-icon tooltip-trigger tooltip-trigger-size" iconName="question" />
        {renderSizeTooltip()}
      </div>
    </li>
  );
};

const getSourceText = (sources = 0, sourcesText = null, outside = null) => {
  if (!sourcesText) return '';

  let __sourcesText = sourcesText;

  if (outside) {
    __sourcesText = sourcesText.replace('outside', `${outside} outside`);
  }

  const sourcesTextArr = __sourcesText.split(' ') || [];
  if (sourcesTextArr.includes('any')) {
    return `Use any ${sources > 0 ? sources : ''} ${sourcesTextArr.slice(1, sourcesTextArr.length).join(' ') || ''}`;
  }
  return `Use ${sources > 0 ? sources : ''} ${__sourcesText || ''}`;
};

const renderTopicField = (topic, mobile) => {
  if (topic === 'To be chosen by Writer') {
    return 'To be chosen by Freelancer';
  }

  const questionContainer = classNames('tooltip-container', { 'tooltip tooltip__icon': mobile });

  if (topic === 'To be confirmed with Client') {
    return (
      <>
        <strong>To be confirmed with Client</strong>
        <div className={questionContainer}>
          <Icon className="svg-icon tooltip-trigger tooltip-trigger-size ml5" iconName="question" />
          <p className="tooltip-content size-order-tooltip order-tooltip order-tooltip--right">You need to send the topic you've chosen to operator, so we can confirm it with the client.</p>
        </div>
      </>
    );
  }

  return (
    <>
      <span className="no-strong">Specified:</span> <strong>{topic}</strong>
      <div className={questionContainer}>
        <Icon className="svg-icon tooltip-trigger tooltip-trigger-size ml5" iconName="question" />
        <p className="tooltip-content size-order-tooltip order-tooltip order-tooltip--right">You need to use the topic already chosen by the client.</p>
      </div>
    </>
  );
};

const renderTypeStage = (mobile) => {
  const questionContainer = classNames('tooltip-container', { 'tooltip tooltip__icon': mobile });
  return (
    <>
      <strong>Required</strong>
      <div className={questionContainer}>
        <Icon className="svg-icon tooltip-trigger tooltip-trigger-size ml5" iconName="question" />
        <p className="tooltip-content size-order-tooltip order-tooltip order-tooltip--right">Use login details provided upon acceptance to submit the task(s) on client's dashboard</p>
      </div>
    </>
  );
};

const getItems = ({
  item, order = {}, renderTemplate, profile,
  mobile, isDe,
}) => {
  const {
    sources, sources_text: sourcesText,
    format_auto: formatAuto = false,
    english_type: englishType,
    level_list: levelList,
    abstract,
    speaker_notes: speakerNotes,
    file_format: fileFormat,
    topic_field: topicField,
    subject,
    outside,
    written_explanations: writtenExplanations,
  } = order;
  const isNTorder = utils.isNTorder(order);

  switch (item) {
    case 'format':
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">{ formatAuto ? 'Suggested formatting style' : 'Formatting style' }</div>
          <div className="col-1 upper-letter">{renderTemplate()}</div>
        </div>
      );
    case 'source':
      if (sources === undefined || !sourcesText) return null;
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Sources</div>
          <div className="col-1 upper-letter">{getSourceText(sources, sourcesText, outside)}</div>
        </div>
      );
    case 'language':
      if (!englishType || englishType === 'not specified') return null;
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Language type</div>
          <div className="col-1 upper-letter">{englishType}</div>
        </div>
      );
    case 'complexity':
      if (!levelList || !levelList.length) return null;
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Complexity</div>
          <div className="col-1 upper-letter">{levelList.join(', ')}</div>
        </div>
      );
    case 'abstract':
      if (!abstract) return null;
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Abstract</div>
          <div className="col-1 upper-letter">{`${abstract} words`}</div>
        </div>
      );
    case 'speakerNotes':
      if (!speakerNotes) return null;
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Speaker notes</div>
          <div className="col-1 upper-letter">{`${speakerNotes} words per slide`}</div>
        </div>
      );
    case 'fileFormat':
      if (!fileFormat || fileFormat === 'not specified' || isNTorder) return null;
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">File format</div>
          <div className="col-1 upper-letter">{fileFormat}</div>
        </div>
      );
    case 'topic': {
      if (!topicField) return null;
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Topic</div>
          <div className="col-1 upper-letter">{renderTopicField(topicField, mobile)}</div>
        </div>
      );
    }
    case 'typesStage': {
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Submission to online dashboard</div>
          <div className="col-1 upper-letter">{renderTypeStage(mobile)}</div>
        </div>
      );
    }
    case 'orderType':
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Type of work</div>
          <div className="col-1 upper-letter">{getOrderType(order).join(' + ')}</div>
        </div>
      );
    case 'orderSize': {
      const size = renderOrderSize({
        order, profile, mobile, isDe, callFromOrderDetails: true,
      });

      if (size === null) return null;

      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Order size</div>
          <div className="col-1 upper-letter">{size}</div>
        </div>
      );
    }
    case 'orderSubject':
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Subject</div>
          <div className="col-1 upper-letter">{subject}</div>
        </div>
      );
    case 'orderPrice':
      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Price</div>
          <div className="col-1 upper-letter">{orderPrice({ order, profile })}</div>
        </div>
      );
    case 'writtenExplanations': {
      if (isNTorder || !writtenExplanations) return null;

      return (
        <div className="row vertical" key={item}>
          <div className="col-1">Written explanations</div>
          <div className="col-1 upper-letter">Required</div>
        </div>
      );
    }
    default:
      return null;
  }
};

const isRevisionOrderExpired = (order) => {
  const { revision_deadline: revisionDeadline } = order;

  return moment().isAfter(moment(revisionDeadline));
};

export {
  sameDay,
  getStatus,
  orderPrice,
  getGroupDate,
  getOrderType,
  renderOrderSize,
  getItems,
  isRevisionOrderExpired,
  isTypesStagesOnlineDashboard,
};
