import React, { useMemo, useState } from 'react';

import Icon from 'components/Icon';

import TextArea from 'components/shared/TextArea';

import templates from './templates';

import './index.scss';

const TemplateModal = ({
  templateIndex = undefined, onSubmit, onClose, textReplaceHandler,
}) => {
  const template = useMemo(() => {
    const __template = templates(templateIndex);
    if (textReplaceHandler) {
      return textReplaceHandler(__template);
    }

    return __template;
  }, []);

  const [text, setText] = useState(template);


  const onSubmitHandler = () => {
    if (!text) return;

    onSubmit(text);
  };

  return (
    <div className="template-modal">
      <button className="btn btn-close" type="button" onClick={onClose}><Icon className="svg-icon" iconName="close" /></button>
      <h2 className="template-modal__header fsL mb16">Use this template or adjust if needed</h2>
      <TextArea initialText={text} onUpdateCallback={setText} extraClasses="template-modal__text" minHeight="90px" />
      <div className="row flex-end">
        <button onClick={onSubmitHandler} disabled={!text} className="btn btn-bright btn-md2">Send to client</button>
      </div>
    </div>
  );
};

export default TemplateModal;

