import React, { useCallback, useEffect } from 'react';

import { usePrevious } from 'hooks';

import classNames from 'utils/classNames';
import { downloadFile } from 'utils/downloadFile';

import utils from 'utils';

import isImage from 'utils/isImage';
import Tooltip from 'components/Tooltip';


import { AVAILABLE_FORMATS } from '../../constants';

import './styles/index.scss';

const SideBar = ({
  docs, selectedID, checkedDocs,
  setPreviewCallback, updateCheckedDocs,
  downloadAllHandler, checkDownloadFetching,
}) => {
  const clickHandler = useCallback((index) => {
    setPreviewCallback(index);
  }, [setPreviewCallback]);

  const clickCheckHandler = useCallback((e, id) => {
    e.stopPropagation();
    updateCheckedDocs((items) => {
      if (items.includes(id)) return items.filter(cD => cD !== id);
      return [...items, id];
    });
  }, [updateCheckedDocs]);
  const deselectAllHandler = useCallback(
    () => {
      updateCheckedDocs([]);
    },
    [updateCheckedDocs],
  );

  const onDownload = useCallback(
    (e, url, name) => {
      e.stopPropagation();
      downloadFile(url, name || 'file');
    },
    [],
  );

  const prevCheckDownloadFetching = usePrevious(checkDownloadFetching);

  useEffect(() => {
    if (prevCheckDownloadFetching === true && prevCheckDownloadFetching !== checkDownloadFetching) {
      updateCheckedDocs([]);
    }
  }, [checkDownloadFetching, prevCheckDownloadFetching, updateCheckedDocs]);

  const tooltip = checkedDocs.length === 1 ? 'download 1 file' : `download ${checkedDocs.length} files`;
  return (
    <div className="side_bar">
      <div className="side_bar__bar_controls">
        {
          !checkedDocs.length ? (
            <div className="default-text">Files Preview</div>
          ) : (
            <div className="side_bar_bar_btns">
              <div className="side_bar_bar_btns__deselect_text" onClick={deselectAllHandler}>Deselect all</div>
              <div className="side_bar_bar_btns_group">
                {
                    !checkDownloadFetching && (
                      <Tooltip content={tooltip} positionDown>
                        <i className="side_bar_bar_btns_group__icon side_bar_bar_btns_group__icon--download_all" onClick={downloadAllHandler} />
                      </Tooltip>
                    )
                  }
                {
                    checkDownloadFetching &&
                    <span>Loading ...</span>
                  }
              </div>
            </div>
            )
        }
      </div>
      <div className="previews_scroll_container">
        <div className="mini_previews_container">
          {docs.map(({ name, real_location: url, id }, index) => {
            const extension = utils.getFileExtension(name).toLocaleLowerCase();
            const isFileImage = isImage(name);
            const isUnFormatFile = !isFileImage && !AVAILABLE_FORMATS.includes(extension);
            return (
              <div className={classNames('mini_preview_container', { 'mini_preview_container--active': selectedID === id })} key={id} onClick={() => clickHandler(index)}>
                <input readOnly type="checkbox" className="mini_preview_container__checkbox" checked={checkedDocs.includes(id)} onClick={e => clickCheckHandler(e, id)} />
                {
                  isFileImage ? (
                    <div className="mini_preview_container_item_img_wrapper">
                      <i className="mini_preview_container_item" style={{ backgroundImage: `url(${url})`, backgroundSize: 'cover' }} />
                    </div>
                  ) : (
                    <i className={classNames('mini_preview_container_item', `mini_preview_container_item__${isUnFormatFile ? 'unknown' : extension}`)} />
                    )
                }
                {
                  !checkedDocs.length && (
                    <button onClick={e => onDownload(e, url, name)} className="mini_preview_container__download-btn">
                      <i className="mini_preview_container__download-btn--icon" />
                    </button>
                  )
                }
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
