import React from 'react';
import { connect } from 'react-redux';
import alarm from './../img/icons/alarm.svg';

import Modal from './Modal';
import Icon from './Icon';

import { financesCancelFetch } from './../store/finances/actions';

const FinancesCancelModal = props => {
    const { toggleModal, isOpen, isLoading, cancelWithdrawal } = props;
    if (!isOpen) {
        return null;
    }

    return (
        <Modal onClose={toggleModal} small className="ta-center">
            <img className="modal-icon" src={alarm} alt="" />
            <h2 className="title">You already have pending withdrawal request</h2>
            <p className="modal-text">A new one will cancel current pending <br /> withdrawal request. Proceed?</p>
            <button type="button" className="btn btn-bright btn-big" onClick={cancelWithdrawal}>Make new request</button>
            {isLoading &&
                <div className="preloading preloading-box"><p>Loading</p></div>
            }
            <button className="btn btn-close" type="button" onClick={toggleModal}><Icon className="svg-icon" iconName="close" /></button>
        </Modal>
    );
};

const mapStateToProps = state => {
    const isLoading = state.finances.isFetchingWithdrawal || state.finances.isFetching;
    return { isLoading };
};

const mapDispatchToProps = dispatch => {
    return {
        cancelWithdrawal: () => dispatch(financesCancelFetch())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancesCancelModal);
