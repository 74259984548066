import React, { useState, useCallback, useEffect } from 'react';

import classNames from 'utils/classNames';

import warningIcon from 'img/icons/warning-icon.svg';

import Modal from 'components/Modal';
import ReservedCounter from 'components/ReservedCounter';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

const OrderRejectModal = ({
  isPenaltyReject, rejectCallback, onClose, writerAccPlusTenMinutes, order,
}) => {
  const [rejectReason, onChangeRejectReason] = useState('');
  const [rejectMessage, onChangeRejectMessage] = useState('');
  const [isSending, toggleSend] = useState(false);
  useEffect(() => () => {
    toggleSend(false);
  }, []);
  useEffect(() => {
    if (order.isFetchingStatus) {
      toggleSend(true);
      return;
    }

    if (!order.isFetchingStatus && isSending) {
      if (!order.errorMessage && !order.error) {
        onClose(null, true);
      }
      toggleSend(false);
    }
  }, [order.isFetchingStatus, isSending]);
  const onChangeRejectMessageCall = useCallback(
    (event) => {
      const { target } = event;
      const message = target.value;
      onChangeRejectMessage(message);
    },
    [rejectMessage],
  );
  const onOrderRejectClick = useCallback(
    () => {
      rejectCallback({
        reject_reason: rejectReason,
        comment: rejectMessage,
        free: !isPenaltyReject,
      });
    },
    [rejectReason, rejectMessage],
  );
  const renderHeader = () => {
    if (isPenaltyReject) {
      return (
        <div className="reject-btn-container-modal__penalty-header">
          <img src={warningIcon} alt="warning" />
          <div className="penalty-header-text">warning!</div>
        </div>
      );
    }
    return (
      <div className="reject-btn-container-modal__no-penalty-header">
        <div>
          We are sorry that this order didn't meet your expectations&nbsp;<span className="emoji" role="img" aria-label="">😭</span>
        </div>
        <div className="reject-btn-container-modal__no-penalty-header--title">
          Please specify the reason why you are rejecting it, <br /> so we could fix the issue!
        </div>
      </div>
    );
  };
  const renderTitle = () => {
    if (isPenaltyReject) {
      return (
        <div className="modal-text">
          Refusal to work on an order with no good reason typically leads to a 100% fine. Our Support team will review this case to decide on penalties. Selecting an option below and submitting the request, you confirm that you are aware of possible penalties.
        </div>
      );
    }
    return null;
  };
  const renderBtnBlock = () => {
    let btns = [
      'Subjects don’t match',
      'Volume of the task isn’t reflected correctly',
      'Instructions at LR are not relevant',
      'Login details are incorrect',
      'I have issues with VPN',
      'Order accepted by mistake',
    ];
    if (isPenaltyReject) {
      btns = [
        'Order is too difficult',
        'Client changed the instructions',
        'No clarifications from Client for a long time',
        'I don’t want to work on this order',
      ];
    }
    return btns.map(item =>
      (<button
        className={classNames('btn btn-block', { active: item === rejectReason }, isPenaltyReject ? 'btn-penalty' : 'btn-light')}
        key={item}
        onClick={() => onChangeRejectReason(item)}
      >{item}</button>));
  };
  return (
    <Modal isDisabled={isSending} className="modal-sm reject-btn-container-modal" onClose={onClose}>
      {renderHeader()}
      {renderTitle()}
      <div className="reject-btn-container-modal__btn-block">
        {renderBtnBlock()}
      </div>
      {
        !isPenaltyReject &&
        <textarea
          className="reject-btn-container-modal__reject-message"
          placeholder="Type your comment here"
          value={rejectMessage}
          onChange={onChangeRejectMessageCall}
        />
      }
      <div className="reject-btn-container-modal__actions-block">
        {
          !isPenaltyReject &&
          <div className="reject-btn-container-modal__timer">
            <ReservedCounter time={writerAccPlusTenMinutes} onOver={onClose} />
            <Tooltip className="multi-string" content="Please, take your decision before time runs out or you may get fine">
              <Icon className="svg-icon" iconName="question" />
            </Tooltip>
          </div>
        }
        <button className="btn btn-error btn-block" disabled={!rejectReason} onClick={onOrderRejectClick}>
          <strong>Reject the order</strong>
        </button>
        <button className="btn btn-light btn-block" onClick={onClose}>
          <strong>Cancel</strong>
        </button>
      </div>
    </Modal>
  );
};

export default OrderRejectModal;
