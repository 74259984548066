import React, { Component } from 'react';
import Mark from 'mark.js';

class MarkableText extends Component {
  createMarkup() {
    const { html } = this.props;
    return { __html: html };
  }

  doMark() {
    const { mark, options = {} } = this.props;

    if (!this.markInstance) {
      this.markInstance = new Mark(this.containerRef);
    }
    this.markInstance.unmark();
    this.markInstance.mark(mark, options);
  }

  componentDidMount() {
    this.doMark();
  }

  componentDidUpdate() {
    this.doMark();
  }

  render() {
    const { onClick } = this.props;

    return (
      <div onClick={onClick || null} className="markable-text" dangerouslySetInnerHTML={this.createMarkup()} ref={ref => this.containerRef = ref} />
    );
  }
}

export default MarkableText;
