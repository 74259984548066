const NOT_ORDER = 'Not order-related';

const NOT_ORDER_RELATED = 'NOT_ORDER_RELATED';
const ORDER_RELATED = 'ORDER_RELATED';

const TEAMS = {
  QA_MANAGERS: 'QA Managers',
  CARE_TEAM: 'Care team',
  SUPPORT_TEAM: 'Support team',
};

const CLASSNAME_TEAM = {
  [TEAMS.QA_MANAGERS]: 'qa_managers',
  [TEAMS.CARE_TEAM]: 'care_team',
  [TEAMS.SUPPORT_TEAM]: 'support_team',
};

const PERIODS = {
  SEVEN_DAYS: '7 calendar days',
  THREE_FIVE_DAYS: '3-5 business days',
  UP_TO_30_MIN: 'up to 30 minutes',
}

const REPORT_TYPE = {
  IVE_FACED_A_FINANCE_RELATED_ISSUE: 'I\'ve faced a finance-related issue',
  IVE_FACED_AN_ISSUE_WHEN_INTERACTING_WITH_INTERFACES: 'I\'ve faced an issue when interacting with interfaces',
  IVE_FACED_AN_ISSUE_WHEN_SELECTING_AN_ORDER: 'I\'ve faced an issue when selecting an order',
  IVE_FACED_AN_ISSUE_WHEN_WORKING_ON_AN_ORDER: 'I\'ve faced an issue when working on an order',
  IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED: 'I\'ve faced an issue when order was already completed',
  IVE_FACED_AN_ISSUE_WHEN_COMMUNICATING_WITH_THE_SUPPORT_TEAM: 'I\'ve faced an issue when communicating with the Support Team',
}
const REPORT_SUB_TYPE = {
  I_DO_NOT_AGREE_WITH_CLIENTS_SCORE: 'I do not agree with Client\'s Score',
  UNFAIR_QA_SCORE: 'Unfair QA score',
  I_CANT_SEE_ANY_ORDERS: 'I can\'t see any orders',
  I_WANT_TO_CHANGE_CONTACT_DETAILS: 'I want to change contact details',
  I_WANT_TO_DELETE_MY_ACCOUNT: 'I want to delete my account',
  I_WANT_TO_FREEZE_MY_ACCOUNT: 'I want to freeze my account',
  MY_ACCOUNT_IS_UNDER_REVIEW: 'My account is under review',
  UNFAIR_FINE: 'Unfair fine',
  // CANCELLED_WITHDRAWAL: 'Cancelled withdrawal',
  // I_DID_NOT_ECEIVE_MY_PAYMENT_TO_CARD: 'I didn\'t receive my payment (to card)',
  PAYMENT_BONUS_NOT_RECEIVED: 'Payment/bonus not received',
  I_HAVE_A_SUGGESTION_IN_REGARDS_TO_LR_PLATFORM: 'I have a suggestion in regards to LR platform',
  I_WANT_TO_REQUEST_FEEDBACK_WITH_MY_CARE_MANAGER: 'I want to request feedback with my Care manager',
  OPERATOR_WASNT_HELPFUL: 'Operator wasn\'t helpful',
  LACK_OF_FILES_INSTRUCTIONS_ARE_NOT_CLEAR: 'Lack of files/instructions are not clear',
  SYSTEM_BUG: 'System bug',
  CANT_FIND_MY_ORDER_IN_PROGRESS: 'Can\'t find my order in progress',
  UNFAIR_WARNING: 'Unfair warning',
  ISSUE_WITH_DASHBOARD_LOGIN_DETAILS: 'Issue with dashboard login details',
  CANT_SUBMIT_AN_ORDER_IN_PROGRESS: 'Can\'t submit an order in progress',
  CLARIFICATIONS_NEEDED: 'Clarifications needed',
  UNJUSTIFIED_REVISION: 'Unjustified revision',
  ISSUE_WITH_FINAL_FILES: 'Issue with the final files',
  I_NEED_TO_ACCESS_ORDER_DETAILS: 'I need to access order details',
  // SUPPORT_TEAM_IGNORED_ME: 'Support team ignored me',
  // SUPPORT_TEAM_MISGUIDED_ME: 'Support team misguided me',
  // SUPPORT_TEAM_WAS_RUDE: 'Support team was rude',
  PAYMENT_DETAILS_VERIFICATION_FAILED: 'Payment details verification failed',
  WIRE_TRANSFER_RELATED_ISSUE: 'Wire transfer related issues',
  I_CANT_UPDATE_MY_PUBLIC_PROFILE: 'I can\'t update my public profile',
  I_WANT_TO_CHANGE_MY_PAYMENT_METHOD: 'I want to change my payment method',
  REVISION_REJECT_UNFAIR_FINE: 'Revision reject - unfair fine',
};

const REPORT_PROCESSING_TIME = {
  [REPORT_SUB_TYPE.I_DO_NOT_AGREE_WITH_CLIENTS_SCORE]: {
    team: TEAMS.QA_MANAGERS,
    time: PERIODS.SEVEN_DAYS,
  },
  [REPORT_SUB_TYPE.UNFAIR_QA_SCORE]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.I_CANT_SEE_ANY_ORDERS]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.I_WANT_TO_CHANGE_CONTACT_DETAILS]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.I_WANT_TO_DELETE_MY_ACCOUNT]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.I_WANT_TO_FREEZE_MY_ACCOUNT]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.MY_ACCOUNT_IS_UNDER_REVIEW]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.UNFAIR_FINE]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  // [REPORT_SUB_TYPE.CANCELLED_WITHDRAWAL]: {
  //   team: TEAMS.CARE_TEAM,
  //   time: PERIODS.THREE_FIVE_DAYS,
  // },
  // [REPORT_SUB_TYPE.I_DID_NOT_ECEIVE_MY_PAYMENT_TO_CARD]: {
  //   team: TEAMS.CARE_TEAM,
  //   time: PERIODS.THREE_FIVE_DAYS,
  // },
  [REPORT_SUB_TYPE.PAYMENT_BONUS_NOT_RECEIVED]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.I_HAVE_A_SUGGESTION_IN_REGARDS_TO_LR_PLATFORM]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.I_WANT_TO_REQUEST_FEEDBACK_WITH_MY_CARE_MANAGER]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.OPERATOR_WASNT_HELPFUL]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.LACK_OF_FILES_INSTRUCTIONS_ARE_NOT_CLEAR]: {
    team: TEAMS.SUPPORT_TEAM,
    time: PERIODS.UP_TO_30_MIN,
  },
  [REPORT_SUB_TYPE.SYSTEM_BUG]: {
    team: TEAMS.SUPPORT_TEAM,
    time: PERIODS.UP_TO_30_MIN,
  },
  [REPORT_SUB_TYPE.CANT_FIND_MY_ORDER_IN_PROGRESS]: {
    team: TEAMS.SUPPORT_TEAM,
    time: PERIODS.UP_TO_30_MIN,
  },
  [REPORT_SUB_TYPE.UNFAIR_WARNING]: {
    team: TEAMS.SUPPORT_TEAM,
    time: PERIODS.UP_TO_30_MIN,
  },
  [REPORT_SUB_TYPE.ISSUE_WITH_DASHBOARD_LOGIN_DETAILS]: {
    team: TEAMS.SUPPORT_TEAM,
    time: PERIODS.UP_TO_30_MIN,
  },
  [REPORT_SUB_TYPE.CANT_SUBMIT_AN_ORDER_IN_PROGRESS]: {
    team: TEAMS.SUPPORT_TEAM,
    time: PERIODS.UP_TO_30_MIN,
  },
  [REPORT_SUB_TYPE.CLARIFICATIONS_NEEDED]: {
    team: TEAMS.SUPPORT_TEAM,
    time: PERIODS.UP_TO_30_MIN,
  },
  [REPORT_SUB_TYPE.UNJUSTIFIED_REVISION]: {
    team: TEAMS.SUPPORT_TEAM,
    time: PERIODS.UP_TO_30_MIN,
  },
  [REPORT_SUB_TYPE.ISSUE_WITH_FINAL_FILES]: {
    team: TEAMS.SUPPORT_TEAM,
    time: PERIODS.UP_TO_30_MIN,
  },
  [REPORT_SUB_TYPE.I_NEED_TO_ACCESS_ORDER_DETAILS]: {
    team: TEAMS.SUPPORT_TEAM,
    time: PERIODS.UP_TO_30_MIN,
  },
  // [REPORT_SUB_TYPE.SUPPORT_TEAM_IGNORED_ME]: {
  //   team: TEAMS.CARE_TEAM,
  //   time: PERIODS.THREE_FIVE_DAYS,
  // },
  // [REPORT_SUB_TYPE.SUPPORT_TEAM_MISGUIDED_ME]: {
  //   team: TEAMS.CARE_TEAM,
  //   time: PERIODS.THREE_FIVE_DAYS,
  // },
  // [REPORT_SUB_TYPE.SUPPORT_TEAM_WAS_RUDE]: {
  //   team: TEAMS.CARE_TEAM,
  //   time: PERIODS.THREE_FIVE_DAYS,
  // },
  [REPORT_SUB_TYPE.PAYMENT_DETAILS_VERIFICATION_FAILED]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.WIRE_TRANSFER_RELATED_ISSUE]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.I_CANT_UPDATE_MY_PUBLIC_PROFILE]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.I_WANT_TO_CHANGE_MY_PAYMENT_METHOD]: {
    team: TEAMS.CARE_TEAM,
    time: PERIODS.THREE_FIVE_DAYS,
  },
  [REPORT_SUB_TYPE.REVISION_REJECT_UNFAIR_FINE]: {
    team: TEAMS.QA_MANAGERS,
    time: PERIODS.SEVEN_DAYS,
  },
};

const ISSUE_TYPE = {
  NOT_ORDER_RELATED: {
    [REPORT_TYPE.IVE_FACED_A_FINANCE_RELATED_ISSUE]: [
      REPORT_SUB_TYPE.PAYMENT_BONUS_NOT_RECEIVED,
      // REPORT_SUB_TYPE.CANCELLED_WITHDRAWAL,
      REPORT_SUB_TYPE.PAYMENT_DETAILS_VERIFICATION_FAILED,
      REPORT_SUB_TYPE.ISSUE_WITH_DASHBOARD_LOGIN_DETAILS,
      REPORT_SUB_TYPE.SYSTEM_BUG,
      REPORT_SUB_TYPE.WIRE_TRANSFER_RELATED_ISSUE,
    ],
    [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_INTERACTING_WITH_INTERFACES]: [
      REPORT_SUB_TYPE.ISSUE_WITH_DASHBOARD_LOGIN_DETAILS,
      REPORT_SUB_TYPE.SYSTEM_BUG,
      REPORT_SUB_TYPE.ISSUE_WITH_FINAL_FILES,
    ],
    [REPORT_SUB_TYPE.I_WANT_TO_DELETE_MY_ACCOUNT]: [],
    [REPORT_SUB_TYPE.I_WANT_TO_REQUEST_FEEDBACK_WITH_MY_CARE_MANAGER]: [],
    [REPORT_SUB_TYPE.I_CANT_UPDATE_MY_PUBLIC_PROFILE]: [],
    [REPORT_SUB_TYPE.I_WANT_TO_CHANGE_CONTACT_DETAILS]: [],
    [REPORT_SUB_TYPE.I_WANT_TO_FREEZE_MY_ACCOUNT]: [],
    [REPORT_SUB_TYPE.I_WANT_TO_CHANGE_MY_PAYMENT_METHOD]: [],
    [REPORT_SUB_TYPE.I_CANT_SEE_ANY_ORDERS]: [],
    [REPORT_SUB_TYPE.I_HAVE_A_SUGGESTION_IN_REGARDS_TO_LR_PLATFORM]: [],
    // [REPORT_SUB_TYPE.I_DID_NOT_ECEIVE_MY_PAYMENT_TO_CARD]: [],
    [REPORT_SUB_TYPE.MY_ACCOUNT_IS_UNDER_REVIEW]: [],
    [REPORT_SUB_TYPE.OPERATOR_WASNT_HELPFUL]: [],
  },
  ORDER_RELATED: {
    [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_SELECTING_AN_ORDER]: [
      REPORT_SUB_TYPE.LACK_OF_FILES_INSTRUCTIONS_ARE_NOT_CLEAR,
    ],
    [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_WORKING_ON_AN_ORDER]: [
      REPORT_SUB_TYPE.CANT_FIND_MY_ORDER_IN_PROGRESS,
      REPORT_SUB_TYPE.CLARIFICATIONS_NEEDED,
      REPORT_SUB_TYPE.LACK_OF_FILES_INSTRUCTIONS_ARE_NOT_CLEAR,
      REPORT_SUB_TYPE.CANT_SUBMIT_AN_ORDER_IN_PROGRESS,
      REPORT_SUB_TYPE.UNFAIR_WARNING,
      REPORT_SUB_TYPE.UNFAIR_FINE,
      REPORT_SUB_TYPE.OPERATOR_WASNT_HELPFUL,
      REPORT_SUB_TYPE.ISSUE_WITH_DASHBOARD_LOGIN_DETAILS,
      REPORT_SUB_TYPE.SYSTEM_BUG,
      REPORT_SUB_TYPE.REVISION_REJECT_UNFAIR_FINE,
    ],
    [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED]: [
      REPORT_SUB_TYPE.UNFAIR_QA_SCORE,
      REPORT_SUB_TYPE.I_DO_NOT_AGREE_WITH_CLIENTS_SCORE,
      REPORT_SUB_TYPE.UNFAIR_WARNING,
      REPORT_SUB_TYPE.UNFAIR_FINE,
      REPORT_SUB_TYPE.PAYMENT_BONUS_NOT_RECEIVED,
      REPORT_SUB_TYPE.UNJUSTIFIED_REVISION,
      REPORT_SUB_TYPE.OPERATOR_WASNT_HELPFUL,
      REPORT_SUB_TYPE.ISSUE_WITH_DASHBOARD_LOGIN_DETAILS,
      REPORT_SUB_TYPE.SYSTEM_BUG,
      REPORT_SUB_TYPE.ISSUE_WITH_FINAL_FILES,
      REPORT_SUB_TYPE.I_NEED_TO_ACCESS_ORDER_DETAILS,
      REPORT_SUB_TYPE.REVISION_REJECT_UNFAIR_FINE,
    ],
    [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_COMMUNICATING_WITH_THE_SUPPORT_TEAM]: [
      REPORT_SUB_TYPE.OPERATOR_WASNT_HELPFUL,
      // REPORT_SUB_TYPE.SUPPORT_TEAM_IGNORED_ME,
      // REPORT_SUB_TYPE.SUPPORT_TEAM_MISGUIDED_ME,
      // REPORT_SUB_TYPE.SUPPORT_TEAM_WAS_RUDE,
    ],
    [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_INTERACTING_WITH_INTERFACES]: [
      REPORT_SUB_TYPE.ISSUE_WITH_DASHBOARD_LOGIN_DETAILS,
      REPORT_SUB_TYPE.SYSTEM_BUG,
    ],
  },
  ORDER_CANCELED: {
    [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_WORKING_ON_AN_ORDER]: [
      REPORT_SUB_TYPE.CANT_FIND_MY_ORDER_IN_PROGRESS,
      REPORT_SUB_TYPE.UNFAIR_WARNING,
      REPORT_SUB_TYPE.OPERATOR_WASNT_HELPFUL,
      REPORT_SUB_TYPE.SYSTEM_BUG,
    ],
    [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED]: [
      REPORT_SUB_TYPE.UNFAIR_WARNING,
      REPORT_SUB_TYPE.UNFAIR_FINE,
      REPORT_SUB_TYPE.OPERATOR_WASNT_HELPFUL,
      REPORT_SUB_TYPE.SYSTEM_BUG,
    ],
    [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_COMMUNICATING_WITH_THE_SUPPORT_TEAM]: [
      REPORT_SUB_TYPE.OPERATOR_WASNT_HELPFUL,
    ],
  },
};

const TAGS_RATING = [
  'Resolution is not fair',
  'Issue was misunderstood',
  'Resolution took too long',
  'Not helpful',
  'Other',
];

const TICKET_STATUS_MAPPING = {
  active: 'Under review',
  snoozed: 'Under review',
  canceled: 'Cancelled',
  closed: 'Reviewed',
};

const TICKET_STATUS_FILTER_MAPPING = {
  under_review: 'Under review',
  canceled: 'Cancelled',
  reviewed: 'Reviewed',
};

const TICKETING_STATUS = [
  {
    name: 'Reviewed',
    status: 'reviewed',
  },
  {
    name: 'Under review',
    status: 'under_review',
  },
  {
    name: 'Cancelled',
    status: 'canceled',
  },
];

const STATUS_TICKET = {
  'Under review': 'under-review',
  'Resolved': 'resolved',
  'Cancelled': 'cancelled',
};

const INITIAL_PARAMS_URL = {
  filter: {
    by_ticket_type: ['issue_resolution'],
  },
  sorting: {
    created_at: 'desc',
  },
};

const SERVICE_REPORTS_TYPES = [
  'Wire transfer verification',
  'New payment info (Skrill)',
  'New payment info (Payoneer)',
  'New Payoneer link',
  'Profile verification',
  'Location Updated',
  'Review penalties',
  'Review NT penalties',
];

const WARNING_TEXT = {
  [REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED]: {
    [REPORT_SUB_TYPE.I_DO_NOT_AGREE_WITH_CLIENTS_SCORE]: {
      text: 'Low client score can be hidden in your account only if there is evidence that the score was left by the client due to reasons unrelated to your work quality, and/or if you did not have all the required information to deliver good quality. \n\r\n\rInvestigation is completed after your dispute, and is done by carefully reviewing all order history and messages from the client. \n\r\n\rOnce investigation is performed, the decision is final and can be reconsidered only if we have any new information / actions from the client.'
    },
  },
  [REPORT_SUB_TYPE.I_WANT_TO_DELETE_MY_ACCOUNT]: {
    text: 'Note: you currently have funds on your balance. Before reporting this type of issue, please make sure you’ve created a withdrawal request for any funds you wish to receive, as you won’t be able to do so once your account is deleted.'
  },
  [REPORT_SUB_TYPE.I_WANT_TO_FREEZE_MY_ACCOUNT]: {
    text: 'Low client score can be hidden in your account only if there is evidence that the score was left by the client due to reasons unrelated to your work quality, and/or if you did not have all the required information to deliver good quality. \n\r\n\rInvestigation is completed after your dispute, and is done by carefully reviewing all order history and messages from the client. \n\r\n\rOnce investigation is performed, the decision is final and can be reconsidered only if we have any new information / actions from the client.'
  },
};

export {
  NOT_ORDER,
  NOT_ORDER_RELATED,
  ORDER_RELATED,
  ISSUE_TYPE,
  STATUS_TICKET,
  TAGS_RATING,
  TICKET_STATUS_MAPPING,
  INITIAL_PARAMS_URL,
  TICKETING_STATUS,
  REPORT_PROCESSING_TIME,
  CLASSNAME_TEAM,
  REPORT_SUB_TYPE,
  REPORT_TYPE,
  TICKET_STATUS_FILTER_MAPPING,
  SERVICE_REPORTS_TYPES,
  WARNING_TEXT,
};
