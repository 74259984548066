import React from 'react';

const emojiList = {
  like: '👍',
  smile: '😃',
  smileLight: '🙂',
  ':(': '😟',
  ':D': '😆',
  cool: '😎',
  hearteyes: '😍',
  noWords: '\u{1F610}',
  sad: '\u{1F61F}',
  cry: '\u{1F62D}',
  verySad: '\u{1F61E}',
  crySad: '\u{1F62D}',
  ':)': '\u{1F60A}',
  unamused: '\u{1F612}',
  cwl: '\u{1F605}',
  ':O': '\u{1F632}',
  ';)': '\u{1F609}',
  ';(': '\u{1F622}',
  '(:|': '\u{1F613}',
  ':|': '\u{1F611}',
  ':P': '\u{1F61B}',
  ':$': '\u{1F61C}',
  kiss: '\u{1F619}',
  ':^)': '\u{1F629}',
  '|-)': '\u{1F634}',
  '|-(': '\u{1F62A}',
  yawn: '\u{1F62B}',
  angry: '\u{1F620}',
  wasntme: '\u{1F633}',
  mm: '\u{1F60F}',
  rainbowsmile: '\u{1F308}',
  ':x': '\u{1F637}',
  devil: '\u{1F608}',
  angel: '\u{1F607}',
  makeup: '\u{1F61A}',
  idea: '\u{1F4A1}',
  // 'hot': '\u{1F4A1}',
  hot: '🌶️',
  // 'new': '\u{1F4A1}',
  new: '🆕',
  // 'like': '\u{1F4A1}',
  like2: '👍',
  // 'other': '\u{1F4A1}',
  other: '❇️',

  hi: '👋',

  inlove: '😛', // сердечки вокруг головы (inlove)
  puke: '😫', // тошнит (puke)
  doh: '😫', // ну конечно (doh)
  envy: '😇', // завидую (envy)
  nerd: '😏', // умник (nerd)
  think: '🤔', // думаю (think)
  happy: '😚', // счастливый (happy)
  smirk: '😏', // ухмылка (smirk)
  nod: '😔', // кивок (nod)
  shake: '😚', // мотание головой (shake)
  waiting: '😚', // заждался (waiting)
  donttalk: '🤐', // не разговариваю с тобой (donttalk)
  talk: '😚', // болтаю (talk)
};

const Emoji = ({ id, classname = '' }) => (
  <span className={`emoji ${classname}`} role="img" aria-label="">
    {emojiList[id]}
  </span>
);

export default Emoji;
export { emojiList };
