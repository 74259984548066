import React, { useState } from 'react';

import { urls } from 'config';

import { TAB_INFO,
  TAB_QA,
  TAB_MESSAGES,
  TAB_REVISION,
  TAB_CLIENT_CHAT,
  TAB_CLIENT_SESSIONS } from 'constants/order';

import { STATUS_COMPLETE } from 'store/orders/actions';

import Modal from 'components/Modal';
import Icon from 'components/Icon';

import classNames from 'utils/classNames';
import authFetch from 'utils/authFetch';
import { getStatus } from 'components/order/utils';

import OrderClientChat from 'components/order/OrderClientChat';
import OrderSuggestedSteps from 'components/order/OrderSuggestedSteps';
import OrderClientSessions from 'components/order/OrderClientSessions';
import WriterTicketingIssue from 'components/WriterTicketing/WriterTicketingIssue';
import { REPORT_TYPE } from 'components/WriterTicketing/constants/writerTicketing';

import OrderInfo from '../OrderInfo';
import TabButton from '../TabButton';

import ScoringRubric from './ScoringRubric';
import OrderTabQA from './OrderTabQA';
import OrderTabMessages from './OrderTabMessages';
import OrderTabInfo from './OrderTabInfo';
import OrderTabRevision from './OrderTabRevision';

const TabContent = ({
  tab,
  order,
  fileItems,
  fileWebhook,
  isStatusLoading,
  setReserved,
  setRejected,
  setAccepted,
  setRevisionCompleted,
  setMessageRead,
  messageList,
  messageWebhook,
  uploadFile,
  uploadOrderFiles,
  removeFile,
  deleteMessageConfirm,
  addMessage,
  addMessageAttach,
  setRejectAccepted,
  onSelect,
  mobile,
  standalone,
  status,
  hasClientChatNotification,
  filesStickyRef,
  otherFilesStickyRef,
  containerRef,
  onResize,
  setTab,
  showTabRevision,
  showTabQA,
  user,
  setJoinChat,
  setRejectJoinChat,
}) => {
  const [isGradingLow, setGradingValue] = useState(false);
  const [sumTitlesMap, updateSumTitlesMap] = useState({
    sumTitleFormat: 0,
    sumTitleLanguage: 0,
    sumTitleStructure: 0,
    sumTitleContent: 0,
  });
  const [isIssueOpenForm, setOpenIssueForm] = useState(false);
  const [stateOrder, onSetOrder] = useState(order);
  const [issueDetails, setIssueDetails] = useState(null);
  const [showNotice, onShowNotice] = useState(false);

  const isOrderComplete = getStatus(stateOrder || order) === STATUS_COMPLETE;

  const {
    sumTitleFormat, sumTitleLanguage, sumTitleStructure, sumTitleContent,
  } = sumTitlesMap;

  const avgOutSum = sumTitleFormat + sumTitleLanguage + sumTitleStructure + sumTitleContent;

  const getRatingString = () => {
    const { pregrader_assessing_avg: pregraderRating } = order;

    let pregraderRatingStr = `${pregraderRating}`;

    if (pregraderRating < 55) {
      pregraderRatingStr = `${pregraderRating}`;
    }


    if (pregraderRating >= 55 && pregraderRating <= 59) {
      pregraderRatingStr = `${pregraderRating} (F)`;
    }


    if (pregraderRating >= 60 && pregraderRating <= 69) {
      pregraderRatingStr = `${pregraderRating} (D)`;
    }


    if (pregraderRating >= 70 && pregraderRating <= 79) {
      pregraderRatingStr = `${pregraderRating} (C)`;
    }


    if (pregraderRating >= 80 && pregraderRating <= 89) {
      pregraderRatingStr = `${pregraderRating} (B)`;
    }


    if (pregraderRating >= 90 && pregraderRating <= 100) {
      pregraderRatingStr = `${pregraderRating} (A)`;
    }

    return pregraderRatingStr;
  };

  const getIssueDetails = () => {
    if (issueDetails) {
      const { typeIssue = '', subTypeIssue = '' } = issueDetails;
      return {
        typeIssue,
        subTypeIssue,
      };
    }

    return {
      typeIssue: isOrderComplete ? REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED : REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_WORKING_ON_AN_ORDER,
    };
  };

  const setGradingSum = (title, value) => {
    updateSumTitlesMap(__prevValue => ({ ...__prevValue, [title]: value }));
  };

  const onOpenIssueForm = (e, __issueDetails) => {
    if (!isIssueOpenForm) {
      authFetch(urls.orderDetails(order.number))
        .then((__order) => {
          onSetOrder(__order);
          setOpenIssueForm(true);

          if (__issueDetails) {
            setIssueDetails((__prevData = {}) => ({ ...__prevData, ...__issueDetails }));
          }
        })
        .catch((error) => { console.error(error); });
    } else {
      setOpenIssueForm(false);
    }
  };

  const onSubmitTicket = () => {
    onShowNotice(true);
    setTimeout(() => {
      onShowNotice(false);
    }, 7000);
  };

  const {
    number: orderNumber, _id: orderId,
    title: orderTitle, qa_report_score: qaReportScore,
    pregrader_assessing_avg: pregraderAssessingAvg,
  } = order;

  return (
    <>
      {tab === TAB_REVISION &&
        <OrderTabRevision
          order={order}
          status={status}
          fileItems={fileItems}
          fileWebhook={fileWebhook}
          uploadFile={uploadFile}
          uploadOrderFiles={uploadOrderFiles}
          removeFile={removeFile}
          setFilesStickyRef={filesStickyRef}
          onResize={onResize}
          setRevisionCompleted={setRevisionCompleted}
          isStatusLoading={isStatusLoading}
          setReserved={setReserved}
          setRejected={setRejected}
          setAccepted={setAccepted}
          setJoinChat={setJoinChat}
          setRejectJoinChat={setRejectJoinChat}
          onOpenIssueForm={onOpenIssueForm}
        />
      }
      {tab === TAB_INFO &&
        <OrderTabInfo
          order={order}
          status={status}
          uploadFile={uploadFile}
          removeFile={removeFile}
          uploadOrderFiles={uploadOrderFiles}
          fileItems={fileItems}
          fileWebhook={fileWebhook}
          onResize={onResize}
          setFilesStickyRef={filesStickyRef}
          setOtherFilesStickyRef={otherFilesStickyRef}
          isStatusLoading={isStatusLoading}
          setReserved={setReserved}
          setRejected={setRejected}
          setAccepted={setAccepted}
          mobile={mobile}
          setJoinChat={setJoinChat}
          setRejectJoinChat={setRejectJoinChat}
          profile={user}
          onOpenIssueForm={onOpenIssueForm}
        />
      }
      {tab === TAB_MESSAGES &&
        <OrderTabMessages
          fileItems={fileItems}
          messageList={messageList}
          messageWebhook={messageWebhook}
          setRead={setMessageRead}
          orderNumber={orderNumber}
          orderId={orderId}
          orderTitle={orderTitle}
          order={order}
          deleteMessageConfirm={deleteMessageConfirm}
          addMessage={addMessage}
          addMessageAttach={addMessageAttach}
        />
      }
      {
        tab === TAB_CLIENT_CHAT &&
        <div className="row order-info-chat-container">
          <OrderSuggestedSteps
            order={order}
            standalone={standalone}
            mobile={mobile}
            profile={user}
          />
          <OrderClientChat
            parentRef={containerRef}
            showTab={setTab}
            order={order}
            standalone={standalone}
            mobile={mobile}
            user={user}
          />
        </div>
      }
      {
        tab === TAB_CLIENT_SESSIONS &&
        <OrderClientSessions order={order} mobile={mobile} status={status} />
      }
      {tab === TAB_QA &&
        <div>
          <h1>{orderTitle}</h1>
          <div className="order-info-wrap">
            <OrderInfo
              order={order}
              status={status}
              setRejectAccepted={setRejectAccepted}
              onSelect={onSelect}
              mobile={mobile}
            />
          </div>
          <div className={classNames('btn-toggle-group row vertical', { 'btn-toggle-group--mobile': mobile })}>
            {
                [
                  { tabName: TAB_REVISION, text: 'Revision details', viewFactor: showTabRevision },
                  { tabName: TAB_INFO, text: 'Order Info', viewFactor: true },
                  { tabName: TAB_MESSAGES, text: 'Operator messages', viewFactor: true },
                  { tabName: TAB_QA, text: 'QA Report', viewFactor: showTabQA },
                ].map(({ tabName, text, viewFactor }) => (viewFactor && <TabButton
                  key={tabName}
                  tab={tab}
                  tabName={tabName}
                  text={text}
                  order={order}
                  messageList={messageList}
                  hasClientChatNotification={hasClientChatNotification}
                  showTabRevision={showTabRevision}
                  showTabQA={showTabQA}
                  setTab={setTab}
                  standalone={standalone}
                />))
            }
          </div>

          {qaReportScore &&
            <ScoringRubric order={order} />
          }

          {!!pregraderAssessingAvg && !qaReportScore &&
            <div>
              <div className="row grade-info">
                {isGradingLow &&
                  <div className="col-6">
                    <h3>Your grade: <b>55 out of {avgOutSum}</b></h3>
                  </div>
                }
                {!isGradingLow &&
                  <div className="col-6">
                    <h3>Your grade: <b>{getRatingString()}</b></h3>
                  </div>
                }
                <div className="col-2 ta-right">
                  <a href="https://drive.google.com/file/d/1CKhVACGxGjrn67NzzZ8tnzOFDFKuOq94/view" target="_blank" rel="noopener noreferrer">Scoring rubric</a>
                </div>
              </div>
              <OrderTabQA order={order} setGradingValue={setGradingValue} setGradingSum={setGradingSum} />
            </div>
          }
        </div>
      }
      {isIssueOpenForm &&
        <div className="container-ticketing">
          <Modal onClose={onOpenIssueForm} wide notHandleOutside>
            <WriterTicketingIssue
              onClose={onOpenIssueForm}
              orderId={order._id}
              orderNumber={order.number}
              orderTitle={order.title}
              onSuccess={onSubmitTicket}
              orderDetails={stateOrder}
              callFromOrderContent
              {...getIssueDetails()}
            />
          </Modal>
        </div>
      }
      {showNotice &&
      <div className="ticketing-success-wrap">
        <p className="ticketing-success">
          <Icon className="svg-icon success" iconName="check-fill" />
          Thank you for your feedback. We will review the issue and get back to your ASAP.
        </p>
      </div>
      }
    </>
  );
};

export default TabContent;
