import {
  ORDERS_SET_NEW_ORDER_RECEIVE,
} from '../constants';

export const ORDERS_HIDE_IN_LISTS = 'ORDERS_HIDE_IN_LISTS';

export const STATUS_OPEN = 'open';
export const STATUS_PROGRESS = 'progress';
export const STATUS_REVISION = 'revision';
export const STATUS_COMPLETE = 'complete';
export const STATUS_DELETED = 'deleted';

export { ordersSetFavoriteFetch } from './favorite';

export {
  ordersSetViewedFetch,
  ordersSetViewedFetchIfNeeded,
} from './viewed';

export {
  orderDetailsNaError,
  ordersDetailsInvalidate,
  ordersDetailsReceive,
  orderDetailsStatusError,
  ordersDetailsFetch,
  ordersDetailsFetchIfNeeded,
  orderDetailsError,
  updateOrderField,
} from './details';

export {
  ordersSetStatusReservedFetch,
  ordersSetStatusRejectedFetch,
  ordersSetStatusAcceptedFetch,
  ordersSetStatusJoinChatFetch,
  ordersSetStatusRejectJoinChatFetch,
  ordersSetStatusRevisionCompletedFetch,
  ordersSetStatusCompletedFetch,
  ordersSetStatusCheckFetch,
  ordersSetStatusRejectedAcceptedFetch,
  ordersSetStatusRevisionConfirm,
} from './status';

export {
  ordersAddList,
  ordersHideInLists,
} from './listActions';

export {
  ordersFilesFetchIfNeeded,
  orderFileUploadFetch,
  ordersFilesRemoveFetch,
  orderFilesUploadFetch,
} from './files';

export {
  ordersSetReservedReceive,
  ordersSetReservationOver,
} from './reservation';

export {
  ordersMessagesFetch,
  ordersMessagesFetchIfNeeded,
  ordersMessagesSetReadFetch,
  ordersMessagesAddConfirmFetch,
  ordersMessagesDeleteConfirmFetch,
  ordersMessagesAddFetch,
  ordersMessagesAddAttachFetch,
  messageConvertToTicketFetch,
} from './messages';

export {
  ordersFeedbackFetch,
} from './report';

export {
  ordersSetDdlExtensionFetch,
} from './ddlExtension';


export const ordersSetNewOrderReceive = number => ({
  type: ORDERS_SET_NEW_ORDER_RECEIVE,
  number,
});
