import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { UnmountClosed } from 'react-collapse';

import Icon from 'components/Icon';

import classNames from 'utils/classNames';

import { getTemplates } from 'store/settings/selectors';

import { getItems, isTypesStagesOnlineDashboard } from 'components/order/utils';

import DetailsContent from './DetailsContent';

const OrderDetailInfo = ({ order, groups = [], mobile }) => {
  const templates = useSelector(getTemplates);
  const [isOpenOrderDetails, toggleIsOpenOrderDetails] = useState(false);

  const { client_info: clientInfo = null } = order;

  const renderTemplate = useCallback(() => {
    const { format } = order;

    if (!format) {
      const questionContainer = classNames('tooltip-container tooltip-high-trigger tooltip-template', { 'tooltip tooltip__icon': mobile });
      const link = templates.mla.location;
      return (
        <div>
          <span className="mr5">No template</span>
          <div className={questionContainer}>
            <Icon className="svg-icon" iconName="question" />
            <p className="tooltip-content">
              If the required formatting style is not mentioned in the orders instructions, use <a rel="external noreferrer" href={link} target="_blank">MLA</a>
            </p>
          </div>
        </div>
      );
    }
    const template = templates[format.toLowerCase()];
    if (!template) {
      return format;
    }
    return (
      <a href={template.location} rel="noopener noreferrer" target="_blank">{format}</a>
    );
  }, [order, templates]);

  const groupItem = useMemo(() => {
    const _groups = [...groups];

    const abstractIndex = _groups.indexOf('__abstract');
    if (abstractIndex >= 0) {
      if (order.format === 'APA' && order.pages > 1) {
        _groups.splice(abstractIndex, 1, 'abstract');
      }
    }

    return _groups.map(group => getItems({ item: group, order, renderTemplate }));
  }, [groups]);

  const isOrderOnlineDashboard = useMemo(() => isTypesStagesOnlineDashboard(order), [order]);

  const onToggleOrderDetails = () => {
    toggleIsOpenOrderDetails(__prevValue => !__prevValue);
  };

  const isNoRender = useMemo(() => {
    if (!order || !clientInfo) return true;

    if (groupItem.every(it => it === null)) return true;

    if (!groupItem.some(it => it !== null) && (!clientInfo ||
      Object.values(clientInfo).every(it => !it || (it && !Object.keys(it).length)))) return true;
    return false;
  }, [order, clientInfo, groupItem]);

  const groupItemUpdated = useMemo(() => {
    if (!isOrderOnlineDashboard) return groupItem;

    return [...groupItem, getItems({ item: 'typesStage', order, renderTemplate })];
  }, [groupItem, isOrderOnlineDashboard]);

  if (isNoRender) return null;

  return (
    <div className="order-tab-collapse">
      <div className="order-tab-collapse-head row space-between" onClick={onToggleOrderDetails}>
        <div className="row vertical">
          <Icon className="svg-icon" iconName="icon-detail" />
          <span className="order-tab-head-name"><b>More order details</b></span>
        </div>
        <span className={classNames('arrow-down', { active: isOpenOrderDetails })} />
      </div>
      <UnmountClosed isOpened={isOpenOrderDetails} className="order-tab-collapse-body" hasNestedCollapse>
        <DetailsContent groupItem={groupItemUpdated} clientInfo={clientInfo} />
      </UnmountClosed>
    </div>
  );
};

export default OrderDetailInfo;
