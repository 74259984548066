import React, { Fragment } from 'react';
import Checkbox from 'components/Checkbox';
import { FILTER } from './sharedConstants';

const DeadlinesFilter = ({ orderTimes, stateFilter, onChangeFilter }) => (
  <Fragment>
    {orderTimes.map((item) => {
          const isChecked = stateFilter.times.filter(t => t[0] === item.value[0]).length > 0 || false;
            return (
              <li key={item.name}>
                <Checkbox name={item.name} disabled={item.count === 0} checked={isChecked} onChange={() => onChangeFilter(FILTER.times, item.value)}>
                  {item.name}
                  <span> ({item.count || 0})</span>
                </Checkbox>
              </li>
            );
        })}
  </Fragment>
);

export default DeadlinesFilter;
