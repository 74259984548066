import { useEffect, useRef, useState } from 'react';
import Croppie from 'croppie';

import classnames from 'utils/classNames';
import Modal from 'components/Modal';

const croppieOptions = {
  showZoomer: true,
  enableOrientation: true,
  mouseWheelZoom: 'ctrl',
  viewport: {
    width: 220,
    height: 220,
    type: 'circle',
  },
  customClass: 'cropper',
};

const CroppieImage = ({
  gender, value, onChangeFields, mobile, fieldName,
}) => {
  const avatarRef = useRef();
  const inputFileRef = useRef();
  const [isModalAvatarVisible, toggleIsModalAvatarVisible] = useState();
  const croppRef = useRef({
    cropper: null,
    dropzone: null,
    uploadedImage: null,
    imageType: null,
  });

  const onCloseModal = () => {
    toggleIsModalAvatarVisible(false);
  };

  const onCropperBack = () => {
    croppRef.current.uploadedImage = null;
    onCloseModal();
  };

  const onSaveClick = (image) => {
    onChangeFields(fieldName, image);
  };

  const onCropperSave = () => {
    croppRef.current.cropper.result('base64').then((image) => {
      onSaveClick(image);
      croppRef.current.uploadedImage = null;
      onCloseModal();
    });
  };

  const handleFileSelect = (e) => {
    const { target: { files = [] } } = e;
    const file = files[0];

    if (!file) return;

    croppRef.current.imageType = file.type;
    inputFileRef.current.value = null;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      croppRef.current.cropper.bind({ url: reader.result });
      croppRef.current.uploadedImage = reader.result;
      toggleIsModalAvatarVisible(true);
    };
  };

  const initCropper = () => {
    croppRef.current.cropper = new Croppie(avatarRef.current, croppieOptions);
  };

  const onSelectImage = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    initCropper();
  }, []);

  return (
    <div id="container" className="container-avatar">
      <div className={classnames('dropzone', {
        [gender]: gender,
        danger: !value,
        hide_zone: !onChangeFields,
      })}
      >
        <img src={value} className="container-avatar-img" alt="" />
        <div className="btn-change" onClick={onSelectImage}>
          {!!onChangeFields && (
            <>
              <span>Change</span>
              <input className="btn-change__input_file" ref={inputFileRef} type="file" accept="image/*" onChange={handleFileSelect} />
            </>
          )
          }
        </div>
      </div>

      <div className={classnames('cropper-container', { hide: !isModalAvatarVisible })}>
        <Modal className="avatar-container" mobile={mobile}>
          <h2>Change profile picture</h2>
          <div ref={avatarRef} />
          <div className="cropper-container__button-wrap">
            <button type="button" className="btn btn-light btn-sm" onClick={onCropperBack}>Cancel</button>
            <button type="button" className="btn btn-bright btn-sm btn--save" onClick={onCropperSave}>Save</button >
          </div>
        </Modal>
      </div>
    </div >
  );
};

export default CroppieImage;
