import moment from 'moment-timezone';

export const sortByOrderNumber = (a, b) => {
  if (a.number < b.number) {
    return -1;
  } else if (a.number > b.number) {
    return 1;
  }
  return 0;
};

export const getSorterByField = (field, isAsc, unreadOrderIds) => {
  switch (field) {
    case 'writer_deadline':
    case 'payoutDateComputed':
      return (a, b) => {
        let diff = moment(a.writer_deadline).startOf('second').diff(moment(b.writer_deadline).startOf('second'));
        if (!isAsc) {
          diff *= -1;
        }
        return diff;
      };
    case 'messagesComputed':
      return (a, b) => {
        const hasA = unreadOrderIds.indexOf(a._id) > -1;
        const hasB = unreadOrderIds.indexOf(b._id) > -1;
        if (hasA === hasB) {
          return 0;
        }
        return !isAsc ? hasA < hasB : hasA > hasB;
      };
    case 'recommended':
      return (a, b) => {
        const recommendedA = a.is_recommended;
        const recommendedB = b.is_recommended;
        if (recommendedA && !recommendedB) {
          return -1;
        }
        if (!recommendedA && recommendedB) {
          return 1;
        }
        const suggestedA = a.is_suggested;
        const suggestedB = b.is_suggested;
        if (suggestedA && !suggestedB) {
          return -1;
        }
        if (!suggestedA && suggestedB) {
          return 1;
        }
        return 0;
      };
    default:
      return (a, b) => {
        if (a[field] < b[field]) {
          return isAsc ? -1 : 1;
        } else if (a[field] > b[field]) {
          return isAsc ? 1 : -1;
        }
        return 0;
      };
  }
};
