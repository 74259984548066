import {
  INBOX_UPDATES_DETAIL_REQUEST,
  INBOX_UPDATES_DETAIL_RECEIVE,
} from '../actions';

const inboxUpdateDetail = (state = {
  items: {
    isFetching: false,
    didInvalidate: true,
  },
}, action) => {
  switch (action.type) {
    case INBOX_UPDATES_DETAIL_REQUEST:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.id]: null,
          isFetching: true,
          didInvalidate: true,
        }),
      });
    case INBOX_UPDATES_DETAIL_RECEIVE:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.data.id]: {
            ...action.data,
          },
          isFetching: false,
          didInvalidate: false,
        }),
      });
    default:
      return state;
  }
};

export default inboxUpdateDetail;
