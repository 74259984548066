import React from 'react';
import {
  useParams,
} from 'react-router-dom';

const withRouterMatch = (Component) => {
  const ComponentWithMatchRouterProp = (props) => {
    const params = useParams();
    const __props = {
      ...props,
      ...{
        match: { params },
      },
    };
    return (
      <Component
        {...__props}
      />
    );
  };

  return ComponentWithMatchRouterProp;
};

export default withRouterMatch;
