import React from 'react';
import { toast } from 'react-toastify';

import { ordersAddList } from './../orders/actions';

import OrdersNotifications from '../../components/OrdersNotifications';

import { MAX_TOAST_NUMBERS } from '../../constants';

import { getMappedUserSubjects } from '../settings/selectors';

import { getToastOrderNotifications, getTakenOrderNotifications } from './selectors';
import { removeToastNotifications, removeTakenNotification } from './actions';

export const checkOnToast = (state) => {
  if (window.location.pathname.match('findorders/all')) return false;
  if (state.settings.DNDmode) return false;

  return true;
};

export const addToast = (order, state, newMsg) => {
  const notifications = getToastOrderNotifications(state);
  const { number } = order;

  if (notifications.includes(number)) return;

  if (notifications.length >= MAX_TOAST_NUMBERS + 1) removeToastNotifications(notifications[0]);

  return toast(<OrdersNotifications order={order} newMsg={newMsg} />, {
    toastId: order.number,
    autoClose: false,
    hideProgressBar: true,
  });
};

export const addNewOrders = (dispatch, orders, state) => {
  const takenOrders = getTakenOrderNotifications(state);
  takenOrders.forEach((taken) => {
    if (orders[taken]) dispatch(removeTakenNotification(taken));
  });
  dispatch(ordersAddList(orders));
};

export const isOnTopTable = () => {
  const tableHeader = window.$headRef;
  if (!tableHeader) return true;

  return !tableHeader.classList.contains('sticky-table-head-fixed');
};

export const buildData = (data, id, ordersFind, allOrders, action) => {
  const deleteOrders = [];
  const updateOrders = {};
  const newOrders = [];

  const { isFetching = false, filter = {} } = ordersFind;
  const { show_reserved = false } = filter.all;
  const numbers = Object.keys(allOrders);


  data.forEach(({ available_for = {}, order = {} }) => {
    const {
      reserved_by_me = false,
      number = '',
      assigned_by_me = false,
      reserved = false,
      writer = '',
      _id: orderId,
    } = order;
    const writers = Object.keys(available_for || {});
    const orderForThisUser = writers.includes(id);
    const isFindOrder = numbers.includes(number);
    const nowOrder = allOrders[number] || {};
    const additionOptions = isFindOrder ? {} : { didInvalidate: true };
    const recommendOptions = { is_recommended: false, recomended_by_client: false, is_suggested: false };
    const _order = {
      ...recommendOptions, ...order, ...{ is_viewed: false, is_socket_update: true }, ...(available_for[id] || {}), ...additionOptions,
    };

    const { reserved_by_me: updatedReserved_by_me, assigned_by_me: updatedAssignedByMe } = _order;

    if (!orderForThisUser && writer !== id && isFindOrder) {
      deleteOrders.push(number);
    }

    if (!orderForThisUser) return;

    // if (updatedReserved_by_me || updatedAssignedByMe) return;
    if (updatedReserved_by_me) return;

    if (isFindOrder && !reserved && nowOrder.reserved === true) {
      updateOrders[_order.number] = _order;
    }

    if (action === 'update' && nowOrder.current_writer_in_chat && !_order.current_writer_in_chat) {
      _order.jabber_node = null;
      _order.jabber_server = null;
    }

    if (window.location.pathname.includes(number) && action !== 'update') return;
    if (window.location.pathname.includes(orderId) && action !== 'update') return;

    if (reserved && isFindOrder && nowOrder.reserved === false && !show_reserved && !nowOrder.is_pinned) {
      _order.reserved = false;
      _order.ready_reserved = true;
      deleteOrders.push(number);
    }

    if (!isFetching && !isFindOrder) {
      _order.is_new_order = true;
      newOrders.push(_order);
    }

    updateOrders[_order.number] = _order;
  });

  return { deleteOrders, updateOrders, newOrders };
};

export const getSubjects = (store, newOrders) => {
  const selectedSubjects = getMappedUserSubjects(store) || [];
  const mySubjects = [];
  const otherSubjects = [];
  newOrders.forEach((order) => {
    if (selectedSubjects.includes(order.subject)) {
      mySubjects.push(order);
    } else {
      otherSubjects.push(order);
    }
  });
  return { mySubjects, otherSubjects };
};
