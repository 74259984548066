/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { urls } from 'config';

import authFetch from 'utils/authFetch';

import {
  ORDERS_REPORT_REQUEST,
  ORDERS_REPORT_RECEIVE,
} from '../constants';

import { orderDetailsError } from './details';


const ordersReportRequest = number => ({
  type: ORDERS_REPORT_REQUEST,
  number,
});

const ordersReportReceive = number => ({
  type: ORDERS_REPORT_RECEIVE,
  number,
});

export const ordersFeedbackFetch = (number, data = null) => (dispatch, getState) => {
  const order = getState().orders[number] || {};
  const { _id: orderId } = order;

  if (!orderId) {
    console.error('Order is not found');
    return;
  }

  dispatch(ordersReportRequest(number));
  return authFetch(`${urls.orders}/${orderId}`, {
    method: 'POST',
    body: JSON.stringify({ action: 'writer_order_feedback', ...data }),
  }).then(() => dispatch(ordersReportReceive(number))).catch((error) => {
    console.log(error);
    dispatch(orderDetailsError(number));
  });
};
