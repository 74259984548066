import React, { useEffect, useState } from 'react';
import ReactDomServer from 'react-dom/server';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UnmountClosed } from 'react-collapse';
import moment from 'moment-timezone';

import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';
import formatTime from 'utils/formatTime';
import authFetch from 'utils/authFetch';

import { MSG_MAP } from 'components/order/OrderClientChatCards/constants';
import { urls, testCallHost } from 'config';

import './styles/index.scss';

const EVENTS_FOR_RENDER = [
  'crm:live_session',
  'crm:order_estimated',
  'onboard:added_task_instructions',
]

const ClientChatListItem = ({ order }) => {
  const { client } = order;

  const [loading, setIsLoading] = useState(false);
  const [chatItems, setChatItems] = useState([]);
  const [chatItemLast, setChatItemLast] = useState(null);
  const [itemTotal, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const addChatData = (result) => {
    let chatList = [];

    if (result.items.length > 0) {
      result.items.map((m) => {
        const parsedMessage = JSON.parse(m.json);
        const { files, message, event } = parsedMessage;
        if (files || message || event) {
          chatList = [...chatList, { id: m.id, ...parsedMessage }];
        }
      });

      chatList.sort((a, b) => a.time - b.time);
    }

    setChatItemLast(result.last);
    setTotal(result.total);
    setChatItems(__chatItems => [...chatList, ...__chatItems]);
  };

  const getFetchParams = () => {
    const { jabber_preview_params: { server, node } } = order;

    const params = {
      server,
      node,
      limit: 10,
      before: chatItemLast,
    };

    return {
      method: 'POST',
      body: JSON.stringify(params),
    };
  };

  const onFetchData = () => {
    setIsLoading(true);

    const params = getFetchParams();

    authFetch(urls.clientChatUrl, params, { withWrRoll: false }).then((result) => {
      addChatData(result);
      setIsLoading(false);
    });
  };


  useEffect(() => {
    onFetchData();
  }, []);

  useEffect(() => {
    setHasMore(chatItems.length !== itemTotal);
  }, [chatItems.length]);

  const getAuthor = (author) => {
    if (author === 'smstool') return 'Bot:';
    if (author.indexOf(client) === 0) {
      return 'Client:';
    }
    return 'Tutor:';
  };

  const getSessionStatus = (scheduleAt, duration) => {
    const nowMoment = moment();
    const isAfterNow = nowMoment.isAfter(moment(scheduleAt));
    const isSessionOver = nowMoment.isAfter(moment(scheduleAt).add(duration + 60, 'minutes'));

    if (isSessionOver) return 'isOver';
    if (isAfterNow) return 'live';

    return 'counter';
  };

  const getSessionText = (eventName, eventParams) => {
    // const { scheduled_at, duration, t_token } = eventParams;
    const { scheduled_at, duration, link } = eventParams;

    if (eventName === 'crm:live_session_scheduled') {
      return `Live session scheduled at ${formatTime(moment(scheduled_at), 'dt')} \n duration - ${duration} minutes`;
    }

    if (eventName === 'crm:live_session_rescheduled') {
      return `Live session rescheduled at ${formatTime(moment(scheduled_at), 'dt')} \n duration - ${duration} minutes`;
    }

    if (eventName === 'crm:live_session_canceled') {
      return `Live session scheduled for ${formatTime(moment(scheduled_at), 'dt')} \n was canceled`;
    }

    if (eventName === 'crm:live_session_upcoming') {
      const status = getSessionStatus(scheduled_at, duration);

      // const link = `${testCallHost}?${t_token}`;

      switch (status) {
        case 'counter':
          return `Live session \n <a href=${link} target="_blank" rel="noopener noreferrer">Join after less 10 minutes</a>`;
        case 'live':
          return `Live session \n <a href=${link} target="_blank" rel="noopener noreferrer">Join Now</a>`;
        default:
          return 'Live session is over';
      }
    }

    return eventName;
  };

  const getOrderEstimates = (eventParams) => {
    const { estimate_id: estimateId } = eventParams;
    const { estimates = [] } = order;

    const estimate = estimates.find(es => es._id === estimateId);

    if (!estimate) return null;

    const { status, size, additional_size: additionalSize } = estimate;

    return `Task estimate ${status} \n Additional time requested: ${convertMinsToHrsMins(parseInt(additionalSize, 0))} \n Total order size: ${convertMinsToHrsMins(parseInt(size, 0))}`;
  };

  const renderFiles = (files) => {
    if (!files || files.length === 0) return null;

    return (
      <div className="chat__files">
        {files.map(({ url, location, id, name }) => <div key={id || url || location || name}><a className="location" href={url || location} target="_blank" rel="noreferrer">{name}</a></div>)}
      </div>
    );
  };

  const renderOnBoardInstructionsText = (eventParams) => {
    const { details, files = [] } = eventParams;

    return (
      ReactDomServer.renderToString(
        <div>
          <p>{details}</p>
          {renderFiles(files)}
        </div>
      )
    );
  }

  const renderEventText = (message) => {
    const { event: { event_name: eventName = '', event_params: eventParams = {} } } = message;
    const { freelancer_id: freelancerId } = eventParams;
    const { writer: writerId } = order;

    if (eventName === 'crm:order_estimated') {
      return getOrderEstimates(eventParams) || eventName;
    }

    if (eventName.startsWith('crm:live_session')) {
      return getSessionText(eventName, eventParams);
    }

    if (eventName === 'onboard:added_task_instructions') {
      return renderOnBoardInstructionsText(eventParams);
    }

    const isMyEvent = freelancerId === writerId;
    const eventText = MSG_MAP[eventName];

    if (Array.isArray(eventText)) {
      return isMyEvent ? eventText[0] : eventText[1];
    }
    return eventText || eventName;
  };

  const renderMessage = (m) => {
    const time = formatTime(moment(m.time), 'dt');
    const author = getAuthor(m.from);
    const { event = {} } = m;
    const { event_name: eventName = '' } = event;

    // if (eventName && !EVENTS_FOR_RENDER.includes(eventName)) return null;

    return (
      <div key={m.time} className="chat">
        <h3 className="chat__author">{author}</h3>
        <div className="chat__message">
          {m.message && <p>{m.message}</p>}

          {m.event &&
            <p className="chat__events" dangerouslySetInnerHTML={{ __html: renderEventText(m) }} />
          }

          <p className="chat__time">{time}</p>
        </div>
        {m.files && renderFiles(m.files)}
      </div>
    );
  };

  return (
    <div className="order-tab-content">
      {chatItems.length > 0 &&
        <div
          id="scrollableDiv"
          style={{
            height: 300,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
            width: '100%',
            padding: '10px 0',
          }}
        >
          <InfiniteScroll
            dataLength={chatItems.length}
            next={onFetchData}
            inverse
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            {chatItems.map(item => renderMessage(item))}
          </InfiniteScroll>
        </div>
      }
      {chatItems.length === 0 && !loading &&
        <div className="chat__space">
          <span className="emoji" role="img" aria-label="">🙁 No data found.</span>
        </div>
      }
    </div>
  );
};

const ClientChatPreview = ({ order }) => {
  const [isOpen, setOpen] = useState(false);

  const onToggle = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <div className="order-tab-collapse">
        <div className="order-tab-collapse-head" onClick={onToggle}>
          <span className="order-tab-head-name">Client chat preview</span>
          {isOpen
            ? <span className="arrow-down active" />
            : <span className="arrow-down" />
          }
        </div>

        <UnmountClosed isOpened={isOpen}>
          <ClientChatListItem order={order} />
        </UnmountClosed>
      </div>
    </>
  );
};

export default ClientChatPreview;
