import React, { Component } from 'react';

import Dropdown from 'components/Dropdown';

class ProfileInput extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  onChange(event) {
    const { onChangeFields, fieldName } = this.props;
    const { value } = event.target;
    onChangeFields(fieldName, value);
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      this.dropdownRef && this.dropdownRef.setClose();
    }
  }

  render() {
    const {
      title,
      value,
      placeholder,
      fieldName,
      onChangeFields,
      withValidation = true,
      maxLength = 100,
    } = this.props;
    const errorClassName = (!value && withValidation) ? 'text-danger' : null;
    const text = value || 'Enter';
    return (
      <div>
        <span className={errorClassName}>{title}:</span>
        {!onChangeFields ?
          <span className="profile-noneditable-field">{value}</span> :
          <Dropdown buttonClassName="lnk-dropdown" text={text} ref={(ref) => { this.dropdownRef = ref; }} narrow>
            <input className="input-text" type="text" id={fieldName} maxLength={maxLength} name={fieldName} onChange={this.onChange} onKeyPress={this.handleKeyPress} autoFocus value={value} placeholder={placeholder} />
          </Dropdown>
        }
      </div>
    );
  }
}

export default ProfileInput;
