import { toast } from 'react-toastify';

import utils from 'utils';

import { ordersFindUpdate } from './../ordersFind/actions';
import { getNewOrders } from './../ordersFind/selectors';
import { ordersHideInLists, ordersSetNewOrderReceive } from './../orders/actions';

import { MAX_TOAST_NUMBERS } from '../../constants';


import { addToast, addNewOrders, isOnTopTable, checkOnToast, buildData, getSubjects } from './helpers';
import { getNewNotViewedOrderNotifications, getToastOrderNotifications } from './selectors';

export const ADD_TOASTED_NOTIFICATIONS = 'ADD_TOASTED_NOTIFICATIONS';
export const ADD_NEW_ORDER_NOTIFICATIONS = 'ADD_NEW_ORDER_NOTIFICATIONS';
export const ADD_NEW_NOT_VIEWED_ORDER_NOTIFICATIONS = 'ADD_NEW_NOT_VIEWED_ORDER_NOTIFICATIONS';
export const ADD_NEW_TAKEN_ORDER_NOTIFICATIONS = 'ADD_NEW_TAKEN_ORDER_NOTIFICATIONS';

export const REMOVE_TOASTED_NOTIFICATIONS = 'REMOVE_TOASTED_NOTIFICATIONS';
export const REMOVE_TAKEN_ORDER_NOTIFICATIONS = 'REMOVE_TAKEN_ORDER_NOTIFICATIONS';

export const CLEAR_NEW_ORDERS_NOTIFICATIONS = 'CLEAR_NEW_ORDERS_NOTIFICATIONS';
export const REMOVE_SINGLE_NEW_ORDER = 'REMOVE_SINGLE_NEW_ORDER';

export const setNewOrdersToTable = () => (dispatch, getState) => {
  const notViewedOrders = getNewNotViewedOrderNotifications(getState()) || [];
  const { orders = {} } = getState();
  dispatch({
    type: CLEAR_NEW_ORDERS_NOTIFICATIONS,
  });
  dispatch(ordersFindUpdate(notViewedOrders.filter(nw => orders[nw])));
};

export const clearNewOrdersNotifications = (fromFetch = false) => (dispatch, getState) => {
  const { ordersFind } = getState();

  if (!ordersFind.numbers.length) return;

  const orders = getNewOrders(getState());

  if (orders.length === 0) return;

  dispatch({
    type: CLEAR_NEW_ORDERS_NOTIFICATIONS,
  });
  if (!fromFetch) {
    orders.forEach((order) => {
      dispatch(ordersSetNewOrderReceive(order.number));
    });
  }
};

export const removeToastNotifications = (orderNumber) => {
  if (toast.isActive(orderNumber)) toast.dismiss(orderNumber);

  return {
    type: REMOVE_TOASTED_NOTIFICATIONS,
    orderNumber,
  };
};

export const removeTakenNotification = id => ({
  type: REMOVE_TAKEN_ORDER_NOTIFICATIONS,
  id,
});

export const addOrderNotifications = ({ orders, action }) => (dispatch, getState) => {
  const {
    user = {}, ordersFind, orders: stateOrders, orderSingle,
  } = getState();
  const { _id: id, group = {}, metric_data = {} } = user;
  const { current_orders_count = 0 } = metric_data;
  const { max_order_count = Infinity } = group;
  const isMobile = utils.detectMob();

  if (current_orders_count >= max_order_count) return null;

  const { deleteOrders = [], updateOrders = {}, newOrders = [] } = buildData(orders, id, ordersFind, stateOrders, action);

  const onTopTable = isOnTopTable();
  if (Object.keys(updateOrders).length > 0) {
    addNewOrders(dispatch, updateOrders, getState());
  }

  if (deleteOrders.length > 0) {
    const { pathname } = window.location;
    const isFindordersAllTabOrOrderPage = pathname.match('findorders/all') || deleteOrders.includes(orderSingle.number);
    if (isFindordersAllTabOrOrderPage && deleteOrders.length < 10) {
      dispatch({
        type: ADD_NEW_TAKEN_ORDER_NOTIFICATIONS,
        payload: deleteOrders,
      });
    } else {
      dispatch(ordersHideInLists(deleteOrders));
    }
  }

  if (newOrders.length > 0) {
    const isToast = checkOnToast(getState());
    const notifications = getToastOrderNotifications(getState());

    let { mySubjects = [], otherSubjects = [] } = getSubjects(getState(), newOrders);
    const mySubjectsLength = mySubjects.length;
    if (isToast && mySubjectsLength > 0 && !isMobile) {
      if (mySubjectsLength >= MAX_TOAST_NUMBERS) {
        otherSubjects = [...otherSubjects, ...mySubjects.splice(MAX_TOAST_NUMBERS, mySubjectsLength + 1)];
        notifications.forEach(it => removeToastNotifications(it));
      } else if (mySubjectsLength > 1 && notifications.length + mySubjectsLength > MAX_TOAST_NUMBERS) {
        const extraToasts = MAX_TOAST_NUMBERS - mySubjectsLength;
        notifications.splice(0, notifications.length - extraToasts).forEach(it => removeToastNotifications(it));
      }
      mySubjects.forEach((ms) => {
        addToast(ms, getState());
        dispatch({
          type: ADD_TOASTED_NOTIFICATIONS,
          payload: [ms.number],
        });
      });
    }

    if (onTopTable) {
      setTimeout(() => {
        dispatch(ordersFindUpdate(newOrders.map(o => o.number)));
      }, 10);
    }

    if (onTopTable && !isToast) return null;

    dispatch({
      type: ADD_NEW_NOT_VIEWED_ORDER_NOTIFICATIONS,
      payload: isToast ? otherSubjects.map(order => order.number) : newOrders.map(order => order.number),
    });
  }
};
