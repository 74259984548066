import { urls } from 'config';

import authFetch from 'utils/authFetch';

export const SESSIONS_REQUEST = 'SESSIONS_REQUEST';
export const SESSIONS_RECEIVE = 'SESSIONS_RECEIVE';
export const SESSIONS_ORDER_RECEIVE = 'SESSIONS_ORDER_RECEIVE';
export const SESSIONS_ADD = 'SESSIONS_ADD';
export const SESSIONS_DELETE = 'SESSIONS_DELETE';
export const SESSIONS_UPDATE = 'SESSIONS_UPDATE';

const sessionsRequest = () => ({
  type: SESSIONS_REQUEST,
});

const sessionsReceive = session => ({
  type: SESSIONS_RECEIVE,
  session,
});

const sessionsOrderReceive = (sessions, orderId) => ({
  type: SESSIONS_ORDER_RECEIVE,
  sessions,
  orderId,
});

const sessionAdd = session => ({
  type: SESSIONS_ADD,
  session,
});

const sessionDeleteRed = (sessionId, orderId) => ({
  type: SESSIONS_DELETE,
  sessionId,
  orderId,
});

const sessionUpdateRed = session => ({
  type: SESSIONS_UPDATE,
  session,
});

export const sessionsFetch = (params = { all: true, deleted: true }) => (dispatch) => {
  dispatch(sessionsRequest());
  return authFetch(urls.clientSessions(params))
    .then((json) => {
      const { results = [] } = json;

      const normalizeSessions = results.reduce((prev, session) => {
        const { order_id: orderId } = session;

        if (!orderId) return prev;

        const orderSession = prev[orderId] || [];

        prev[orderId] = [...orderSession, session];

        return prev;
      }, {});
      dispatch(sessionsReceive(normalizeSessions));
    })
    .catch(er => console.error(er));
};

export const orderSessions = (orderId, params = { all: true, deleted: true }) => (dispatch) => {
  dispatch(sessionsRequest());
  return authFetch(urls.orderSessions(orderId, params)).then((json) => {
    const { results = [] } = json;
    dispatch(sessionsOrderReceive(results, orderId));
  });
};

export const sessionPost = data => (dispatch) => {
  dispatch(sessionsRequest());
  return authFetch(urls.clientSessions({ all: true }), {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((json) => {
    const { results = [] } = json;
    const { 0: session = {} } = results;
    dispatch(sessionAdd(session));
  });
};

export const sessionDelete = (sessionId, orderId) => (dispatch) => {
  dispatch(sessionsRequest());
  return authFetch(urls.clientSessionDelete(sessionId), {
    method: 'PATCH',
    body: JSON.stringify({
      deleted: true,
    }),
  }).then((json) => {
    dispatch(sessionUpdateRed({ ...json, deleted: true }));
  });
};

export const sessionUpdate = data => (dispatch) => {
  const { _id: sessionId } = data;
  dispatch(sessionsRequest());
  return authFetch(urls.clientSessionDelete(sessionId), {
    method: 'PATCH',
    body: JSON.stringify(data),
  }).then((json) => {
    dispatch(sessionUpdateRed(json));
  });
};
