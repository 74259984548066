import shortid from 'shortid';

import fileConstants from 'constants/files';

const cropUnsafeSymbols = (string) => {
  const regexp = /[^0-9a-zA-Z!\-_.*'()\ ]/g;
  return string.replace(regexp, '').replace(/ +/g, ' ');
};

const recursiveDecodeURIComponent = (uriComponent) => {
  try {
    const decodedURIComponent = decodeURIComponent(uriComponent);
    if (decodedURIComponent === uriComponent) {
      return cropUnsafeSymbols(decodedURIComponent);
    }
    return recursiveDecodeURIComponent(decodedURIComponent);
  } catch (e) {
    return cropUnsafeSymbols(uriComponent);
  }
};

const createAmazonUpload = (number, file, onProgress, onComplete, onError) => {
  const { host } = fileConstants.UPLOAD_CREDENTIALS;
  const fileId = `${fileConstants.UPLOAD_CREDENTIALS.key}/${number}/${shortid.generate()}/${recursiveDecodeURIComponent(file.name)}`;
  const fileLocation = host + fileId;

  const formData = new FormData();
  formData.append('key', fileId);
  formData.append('AWSAccessKeyId', fileConstants.UPLOAD_CREDENTIALS.AWSAccessKeyId);
  formData.append('acl', 'public-read');
  formData.append('policy', fileConstants.UPLOAD_CREDENTIALS.policy);
  formData.append('signature', fileConstants.UPLOAD_CREDENTIALS.signature);
  formData.append('file', file);
  const xhr = new XMLHttpRequest();
  xhr.open('POST', host);
  xhr.upload.addEventListener('progress', (e) => {
    if (e.lengthComputable) {
      const percent = Math.round(100 * e.loaded / e.total);
      onProgress(fileLocation, percent);
    }
  });
  xhr.addEventListener('load', () => {
    const headers = xhr.getAllResponseHeaders().toLowerCase();
    const etagValues = (headers || '').split('etag: ');
    let etag = '';
    if (etagValues[1]) {
      etag = etagValues[1].replace(/["']/g, '').replace(/[\r]/g, '').replace(/[\n]/g, '');
    }
    onComplete(fileLocation, etag);
  });
  xhr.addEventListener('error', () => onError(fileLocation));
  xhr.send(formData);


  return {
    location: fileLocation,
    abort: () => xhr.abort(),
  };
};

export default createAmazonUpload;
