import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { urls } from 'config';
import authFetch from 'utils/authFetch';
import utils from '../../utils';

import { closeModalComponent, addModalComponent } from 'store/modals/actions';

import Icon from 'components/Icon';
import Emoji from 'components/Emoji';
import modalList from 'constants/modalList';
import storage from 'utils/localStorageHandler';

import './styles/inbox_update_modal.scss';

const InboxUpdatesModalContainer = ({ closeHandler, handleOpenUpdates, count }) => {
  useEffect(() => {
    storage.set('unreadUpdates', true);
  }, []);

  return (
    <div className="inbox_update_modal">
      <h1 className="inbox_update_modal__title">
        <Emoji className="inbox_update_modal__icon" id="smileLight" />
        <span>New updates in your inbox!</span>
      </h1>
      <p>It's important to read all global platform updates in a timely manner, as they contain heads up about new features or policies changes, organizational or finance-related announcements, and much more.</p>
      <p>You have {count} unread update(s) in your Inbox. Check them out!</p>
      <button className="btn btn-close" type="button" onClick={closeHandler}><Icon className="svg-icon" iconName="close" /></button>
      <div className="ta-right"><button className="btn btn-bright btn-md2" onClick={handleOpenUpdates}>Go to Inbox</button></div>
    </div>
  );
};


const InboxUpdatesModal = () => {
  const location = useLocation();
  const keyUnreadUpdates = storage.get('unreadUpdates');
  if (location.pathname.includes('/inbox/updates') || keyUnreadUpdates) return null;

  const [showModal, setShowModal] = useState(false);
  const [countUnread, setCountUnread] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataModal = modalList.InboxUpdatesModalContainer;

  useEffect(() => {
    const params = utils.getTicketsQueryString(0, {}, { not_seen: true });
    const url = `${urls.inboxUpdates}${params}`;
    authFetch(url).then((json) => {
      if (json.items.length > 0) {
        setShowModal(true);
        setCountUnread(json.items.length);
      }
    });
  }, []);

  const closeHandler = () => {
    dispatch(closeModalComponent(dataModal.name));
  };

  const handleOpenUpdates = () => {
    setShowModal(false);
    closeHandler();
    // window.location.href = '/inbox/updates';
    navigate('/inbox/updates');
  };


  useEffect(() => {
    if (showModal) {
      const component = <InboxUpdatesModalContainer closeHandler={closeHandler} handleOpenUpdates={handleOpenUpdates} count={countUnread} />;
      dispatch(addModalComponent({ component, ...dataModal }));
    }
  }, [showModal]);

  return null;
};

export default InboxUpdatesModal;
