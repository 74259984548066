import React from 'react';
import classNames from './../utils/classNames';

const Select = (props) => {
  const {
    children,
    disabled,
    className,
    value,
    onChange,
  } = props;

  const childrenCloned = React.Children.map(children, child => child && React.cloneElement(child, {
    onClick: event => !child.props.disabled && onChange(child.props.value, child.props.name),
    className: classNames(child.props.className, { active: child.props.value === value }),
  }));

  return (
    <ul className={className}>
      {childrenCloned}
    </ul>
  );
};

export default Select;
