
import React, { useMemo } from 'react';

import DropdownOrders from './DropdownOrders';
import TicketFileList from './TicketFileList';
import DropdownTypeIssue from './DropdownTypeIssue';
import DropdownSubTypeIssue from './DropdownSubTypeIssue';
import WriterTicketingMessage from './WriterTicketingMessage';

import { NOT_ORDER } from 'components/WriterTicketing/constants/writerTicketing';


const WriterIssueRequestView = (props) => {
  const { onChangeOrder, onChangeType, onChangeSubType, uploadFile, removeFile } = props;
  const { dataForm, allMyOrder, isConvertMessage, newTicketFiles, writerId, loading, isCompleteDayLater, ticket, profile, subTypesIssue = [], issueTypeList = [] } = props;

  const fileList = useMemo(() => (ticket ? (ticket.info.files || []) : (newTicketFiles.files || [])), [ticket, newTicketFiles]);

  const { message, stateOrderNumber, typeIssue, subTypeIssue, stateOrderTitle, isLoadingTickets, orderId } = dataForm;
  const orderText = stateOrderNumber ? `${stateOrderNumber}: ${stateOrderTitle}` : NOT_ORDER;


  return (
    <>
      <div className="filter-wrap">
        <DropdownOrders loading={loading} orderText={orderText} isDisabled={ticket || isConvertMessage || orderId} orderNumber={stateOrderNumber} onChangeOrder={onChangeOrder} orderList={allMyOrder} />
      </div>

      <div className="filter-wrap">
        <DropdownTypeIssue typeIssue={typeIssue} onChangeType={onChangeType} issueTypeList={issueTypeList} isDisabled={ticket} />

        {isLoadingTickets && <div className="dropdown">Loading subtypes ....</div>}
        {subTypesIssue.length > 0 && !isLoadingTickets &&
          <DropdownSubTypeIssue isDisabled={!typeIssue || ticket} typeIssue={typeIssue} subTypeIssue={subTypeIssue} onChangeSubType={onChangeSubType} subTypesIssue={subTypesIssue} isCompleteDayLater={isCompleteDayLater} />
        }
      </div>

      <div className="writer-wrap">
        <div className="request-wrap">
          <WriterTicketingMessage text={message} />
        </div>
        <div className="writer-avatar">
          <img src={profile.public_photo} alt="" width="40" />
        </div>
      </div>

      <div className="filter-wrap">
        <TicketFileList
          fileList={fileList}
          uploadFile={uploadFile}
          removeFile={removeFile}
          buttonName="Attach file(s)"
          disabled={loading}
          writerId={writerId}
          isEdit={false}
        />
      </div>
    </>
  );
};

export default WriterIssueRequestView;
