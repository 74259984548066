
import { urls } from 'config';

import utils from 'utils';

import authFetch from 'utils/authFetch';

import {
  ORDERS_DETAILS_INVALIDATE,
  ORDERS_DETAILS_REQUEST,
  ORDERS_DETAILS_RECEIVE,
  ORDERS_DETAILS_NA_ERROR,
  ORDERS_DETAILS_STATUS_ERROR,
  ORDERS_DETAILS_ERROR,

  ORDERS_FIELD_UPDATE,
} from '../constants';

const ordersDetailsRequest = number => ({
  type: ORDERS_DETAILS_REQUEST,
  number,
});

export const orderDetailsNaError = number => ({
  type: ORDERS_DETAILS_NA_ERROR,
  number,
});

export const ordersDetailsInvalidate = number => ({
  type: ORDERS_DETAILS_INVALIDATE,
  number,
});

export const orderDetailsError = number => ({
  type: ORDERS_DETAILS_ERROR,
  number,
});

export const ordersDetailsReceive = (number, details, user, settings) => ({
  type: ORDERS_DETAILS_RECEIVE,
  number,
  details,
  user,
  settings,
});

export const orderDetailsStatusError = (number, message) => ({
  type: ORDERS_DETAILS_STATUS_ERROR,
  number,
  message,
});

export const updateOrderField = (number, payload = {}) => ({
  type: ORDERS_FIELD_UPDATE,
  number,
  ...payload,
});

const ordersDetailsShouldFetch = (number, state) => {
  const order = state.orders[number];
  const isNTorder = utils.isNTorder(order);

  if (!order) return true;

  if (order.isFetching) {
    return false;
  }
  return order.didInvalidate || isNTorder;
};

export const ordersDetailsFetch = (number, options = {}) => (dispatch, getState) => {
  const { user, settings, orders = {} } = getState();
  const { withId = false, quite = false } = options;
  const prevOrder = withId ? (Object.values(orders) || []).find(o => o._id === number) : orders[number];
  const { current_writer_in_chat: prevCurrentWriterInChat } = prevOrder || {};

  if (!quite) {
    dispatch(ordersDetailsRequest(number));
  }

  return authFetch(withId ? `${urls.orders}/${number}` : urls.orderDetails(number))
    .then((json) => {
      let updatedJson = { ...json, withDetails: true };

      if (prevCurrentWriterInChat && !updatedJson.current_writer_in_chat) {
        updatedJson = { ...updatedJson, ...{ jabber_node: null, jabber_server: null } };
      }

      return dispatch(ordersDetailsReceive(json.number, updatedJson, user, settings));
    })
    .catch((error) => {
      console.log(error);
      if (error.status === 405 || error.status === 422) {
        dispatch(orderDetailsNaError(number));
        return;
      }
      dispatch(orderDetailsError(number));
    });
};

export const ordersDetailsFetchIfNeeded = number => (dispatch, getState) => {
  if (ordersDetailsShouldFetch(number, getState())) {
    return dispatch(ordersDetailsFetch(number));
  }
  return Promise.resolve({ noFetch: true });
};
