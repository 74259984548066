import { urls } from 'config';

import authFetch from 'utils/authFetch';

import {
  ORDERS_SET_VIEWED_REQUEST,
  ORDERS_SET_VIEWED_RECEIVE,
} from '../constants';

const ordersSetViewedRequest = number => ({
  type: ORDERS_SET_VIEWED_REQUEST,
  number,
});

const ordersSetViewedReceive = number => ({
  type: ORDERS_SET_VIEWED_RECEIVE,
  number,
});

const ordersSetViewedShouldFetch = (number, state) => {
  const order = state.orders[number];

  if (!order) return true;

  if (order.isFetchingViewed) return false;

  return !order.is_viewed;
};

export const ordersSetViewedFetch = (number, orderId) => (dispatch) => {
  dispatch(ordersSetViewedRequest(number));
  return authFetch(`${urls.orders}/${orderId}`, {
    method: 'POST',
    body: JSON.stringify({ action: 'view' }),
  }).then(() => dispatch(ordersSetViewedReceive(number)));
};

export const ordersSetViewedFetchIfNeeded = (number, orderId) => (dispatch, getState) => {
  if (ordersSetViewedShouldFetch(number, getState())) {
    return dispatch(ordersSetViewedFetch(number, orderId));
  }

  return new Promise((resolve) => { resolve(); });
};
