import {
  ADD_IMAGES,
  CLEAR_PREVIEW,
} from './actions';

const previewImages = (state = {
  images: [],
}, action) => {
  switch (action.type) {
    case ADD_IMAGES:
      return {
        ...state,
        images: action.payload,
      };
    case CLEAR_PREVIEW:
      return {
        ...state,
        images: [],
      };
    default:
      return state;
  }
};

export default previewImages;
