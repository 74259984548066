import {
  ADD_ALERT,
  DELETE_ALERT,
} from './actions';

const alerts = (state = {
  alertList: [],
}, action) => {
  switch (action.type) {
    case ADD_ALERT:
      return {
        ...state,
        alertList: [...state.alertList, action.payload],
      };
    case DELETE_ALERT:
      return {
        ...state,
        alertList: state.alertList.slice(1),
      };
    default:
      return state;
  }
};

export default alerts;
