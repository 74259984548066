import React, { Component } from 'react';
import WarningCollapse from './WarningCollapse';
import questionIcon from './../../img/icons/question-copy.svg';
import utils from './../../utils';

const warningReducer = (initial, current) => initial + current.count;

export default class Warnings extends Component {
  render() {
    const warnings = this.props.warnings || [];
    const allWarnings = warnings.reduce(warningReducer, 0);
    const warningsArray = warnings.map((warning) => {
      const percent = allWarnings > 0 ?
        Math.round((warning.count / allWarnings) * 100) :
        0;
      const warningWithPercent = Object.assign({}, warning);
      warningWithPercent.percent = percent;
      return warningWithPercent;
    });
    warningsArray.sort((a, b) => b.percent - a.percent);
    return (
      <div className="warning-collapces">
        <div className="warning-collapces-header">
          <div className="warning-collapces-header-type">
            Warnings
            {!utils.isStem(this.props.profile) &&
              <a className="warning-collapces-learnmore" href="/help/faq/qualityControl ">
                Learn more
                <img className="warning-collapces-learnmore-icon" src={questionIcon} alt="" />
              </a>
            }
          </div>
          <div className="warning-collapces-header-rate">Rate</div>
          <div className="warning-collapces-header-count">Active/All</div>
        </div>
        {warningsArray.map((warning) => {
          if (warning.base_weight) {
            return (<WarningCollapse key={warning.tag} tag={warning.tag} warning={warning} />)
          }
        })
        }
      </div>
    );
  }
}
