import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { urls } from 'config';

import { useStateCallback } from 'hooks';

import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';
import classNames from 'utils/classNames';
import authFetch from 'utils/authFetch';

import Icon from 'components/Icon';
import Checkbox from 'components/Checkbox';
import Tooltip from 'components/Tooltip';
import InputsSizeTime from 'components/shared/InputsSizeTime';

const MAX_COMMENT_LENGTH = 300;

const TOOLTIP_TEXT = {
  price: 'The new suggested <br/>order size has to to be <br/>bigger than the <br/>current one.',
  size: 'The new suggested <br/>order size has to be at <br/>least 1 hour',
  comment: 'Please add reasoning <br/>behind your estimate <br/>to submit.',
};

const CreateEstimateModal = ({ order = {}, toggleOpen }) => {
  const {
    size = '', writer_price: writerPrice, _id: orderId,
    let_tutor_decide: letTutorDecide,
    output_formats: outputFormats,
    writer_per_hour_price: pricePerHour,
    estimates,
  } = order;

  const activeEstimation = useMemo(() => estimates.find(item => item.status === 'active'), [estimates]);
  const shortViewMode = letTutorDecide || activeEstimation;
  // const shortViewMode = true;

  const [isLoading, toggleLoading] = useState(false);
  const [calcWriterPrice, setCalcWriterPrice] = useState(writerPrice);
  const [commentCount, setCommentCount] = useState(MAX_COMMENT_LENGTH);

  const [__size, type] = size.split(' ');
  const { 0: hours, 1: minutes } = convertMinsToHrsMins(__size, true);

  const hasVideoCall = useMemo(() => outputFormats.includes('Video call'), [outputFormats]);

  const [sessionHours, setSessionHours] = useStateCallback('0');
  const [sessionMin, setSessionMin] = useStateCallback('0');
  const [estimateHours, setEstimateHours] = useStateCallback('0');
  const [estimateMin, setEstimateMin] = useStateCallback('0');
  const [estimateComment, setEstimateComment] = useState('');
  const [isSomeThingsChanged, setSomeThingsChanged] = useState(false);
  const [videoCall, setVideoCall] = useState(hasVideoCall);
  const [suggestedOrderSize, setSuggestedOrderSize] = useState('0 hrs 00 min');
  const [withToolTip, setTooltip] = useState(true);
  const [withToolTipText, setTooltipText] = useState('');
  const [sumSize, setSumSize] = useState(0);


  const getCommentFromMinutes = () => {
    if (!isSomeThingsChanged) return '';

    if (videoCall && (!!sessionHours || !!sessionMin)) {
      let hoursText = parseInt(estimateHours, 0) + parseInt(sessionHours, 0);
      let minsText = parseInt(estimateMin, 0) + parseInt(sessionMin, 0);
      if (minsText >= 60) {
        const min = (parseInt(estimateHours, 0) * 60) + (parseInt(sessionHours, 0) * 60) + parseInt(estimateMin, 0) + parseInt(sessionMin, 0);
        const { 0: hoursEst, 1: minutesEst } = convertMinsToHrsMins(min, true);
        hoursText = Number(hoursEst);
        minsText = Number(minutesEst);
      }
      return `This task requires ${hoursText} hour(s) ${minsText} mins of time to be completed. That includes: ${Number(estimateHours)} hour(s) ${Number(estimateMin)} mins, for task completing/preparation and ${Number(sessionHours)} hour(s) ${Number(sessionMin)} mins for a tutoring session. I will be glad to start working on your task if you agree this quote.`;
    }
    return `This task requires ${Number(estimateHours)} hour(s) ${Number(estimateMin)} mins of time to be completed. That includes: ${Number(estimateHours)} hour(s) ${Number(estimateMin)} mins, for task completing/preparation. I will be glad to start working on your task if you agree to this quote.`;
  };

  const setTooltipData = (__field) => {
    if (__field) {
      setTooltip(true);
      setTooltipText(TOOLTIP_TEXT[__field]);
    } else {
      setTooltip(false);
      setTooltipText('');
    }
  };

  const onSessionHours = (__value) => {
    setSessionHours(__value, (newValue) => {
      const min = ((parseInt(newValue, 0) + parseInt(estimateHours, 0)) * 60) + parseInt((estimateMin), 0) + parseInt((sessionMin), 0);
      reCalcPrice(min);
    });
  };

  const onSessionMin = (__value) => {
    setSessionMin(__value, (newValue) => {
      const min = ((parseInt(estimateHours, 0) + parseInt(sessionHours, 0)) * 60) + parseInt((estimateMin), 0) + parseInt((newValue), 0);
      reCalcPrice(min);
    });
  };

  const onEstimateHours = (__value) => {
    setEstimateHours(__value, (newValue) => {
      const min = ((parseInt(newValue, 0) + parseInt(sessionHours, 0)) * 60) + parseInt((estimateMin), 0) + parseInt((sessionMin), 0);
      reCalcPrice(min);
    });
  };

  const onEstimateMin = (__value) => {
    setEstimateMin(__value, (newValue) => {
      const min = ((parseInt(estimateHours, 0) + parseInt(sessionHours, 0)) * 60) + parseInt((newValue), 0) + parseInt((sessionMin), 0);
      reCalcPrice(min);
    });
  };

  const reCalcPrice = (__min) => {
    toggleLoading(true);
    const { 0: hoursEst, 1: minutesEst } = convertMinsToHrsMins(__min, true);
    const hoursText = !Number(hoursEst) ? '0' : hoursEst;
    const minutesText = !Number(minutesEst) ? '00' : minutesEst;
    setSuggestedOrderSize(`${hoursText} hrs ${minutesText} min`);
    setSomeThingsChanged(__min !== 0);

    authFetch(urls.orderEstimate(orderId), {
      method: 'POST',
      body: JSON.stringify({ action: 'calculate', size: `${__min || 0} minutes` }),
    })
      .then((data) => {
        const { price } = data;
        setCalcWriterPrice(price);
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  const onEstimateComment = ({ target }) => {
    const { value } = target;

    const comment = value.slice(0, MAX_COMMENT_LENGTH);

    setEstimateComment(comment);

    if (!value) {
      setCommentCount(MAX_COMMENT_LENGTH);
      return;
    }
    setTooltipData(null);

    setCommentCount(() => (MAX_COMMENT_LENGTH - comment.length));
  };

  const onCreateNewEstimate = () => {
    toggleLoading(true);

    const newSizeInMinutes = (parseInt(estimateHours, 0) * 60) + parseInt((estimateMin), 0) + (parseInt(sessionHours, 0) * 60) + parseInt((sessionMin), 0);
    const data = {
      action: 'create',
      size: `${newSizeInMinutes} minutes`,
      comment: estimateComment,
    };

    authFetch(urls.orderEstimate(orderId), {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        toggleOpen(true);
      })
      .catch((error) => {
        console.error(error);
        toggleLoading(false);
      });
  };

  // useEffect(() => {
  //   reCalcPrice();
  // }, []);

  useEffect(() => {
    const messaageTextarea = getCommentFromMinutes();
    onEstimateComment({ target: { value: messaageTextarea } });
  }, [sessionHours, sessionMin, estimateHours, estimateMin, isSomeThingsChanged]);

  useEffect(() => {
    if (!videoCall) {
      setSessionHours('0');
      setSessionMin('0');
      const min = ((parseInt(estimateHours, 0) * 60) + parseInt((estimateMin), 0));
      reCalcPrice(min);
      if (min === 0) {
        setSuggestedOrderSize('0 hrs 00 min');
      } else {
        const { 0: hoursEst, 1: minutesEst } = convertMinsToHrsMins(min, true);
        const hoursText = !Number(hoursEst) ? '0' : hoursEst;
        const minutesText = !Number(minutesEst) ? '00' : minutesEst;
        setSuggestedOrderSize(`${hoursText} hrs ${minutesText} min`);
      }
    }
  }, [videoCall]);

  useEffect(() => {
    const calcSumSize = (parseInt(estimateHours, 0) * 60) + parseInt((estimateMin), 0) + (parseInt(sessionHours, 0) * 60) + parseInt((sessionMin), 0)
    setSumSize(calcSumSize);
    if (writerPrice >= calcWriterPrice && !shortViewMode) {
      setTooltipData('price');
    } else if (shortViewMode && calcSumSize < 60) {
      setTooltipData('size');
    } else if (!estimateComment) {
      setTooltipData('comment');
    } else {
      setTooltipData(null);
    }
  }, [sessionHours, sessionMin, estimateHours, estimateMin, estimateComment, calcWriterPrice]);

  const isInValidForm = useMemo(() => !isSomeThingsChanged || !estimateComment || (writerPrice >= calcWriterPrice && !shortViewMode) || (shortViewMode && sumSize < 60), [estimateComment, writerPrice, calcWriterPrice, isSomeThingsChanged]);

  return (
    <>
      <div className={classNames('estimation-modal-container', { 'estimation-modal-container--loading': isLoading })}>
        <button className="btn btn-close modal-close" type="button" onClick={toggleOpen}><Icon className="svg-icon" iconName="close" /></button>
        <h2 className="estimation-modal-container__header">Create a new estimate</h2>

        {!shortViewMode &&
          <>
            <div className="row mb16">
              <div className="col-8 estimation-modal-container__name">
                Current order size:
              </div>
              <div className="col-4 estimation-modal-container__value">
                <b>{hours} hrs {('0' + minutes).slice(-2)} min</b>
              </div>
            </div>

            <div className="row mb20">
              <div className="col-8 estimation-modal-container__name">
                Current order price:
              </div>
              <div className="col-4 estimation-modal-container__value">
                <b>${writerPrice.toFixed(2)}</b>
              </div>
            </div>
            <h4 className="estimation-modal-container__subtitle">New estimated order size:</h4>
          </>
        }

        <Checkbox disabled={hasVideoCall} name="videoCall" checked={videoCall} onChange={e => setVideoCall(!videoCall)}>
          Include tutoring session
        </Checkbox>

        <div className="row mb20">
          <div className="col-8">
            <InputsSizeTime
              hours={estimateHours}
              minutes={estimateMin}
              onHours={onEstimateHours}
              onMinutes={onEstimateMin}
              title="Time needed for task <br />completion/preparation:"
            />
          </div>
          <div className="col-8">
            <InputsSizeTime
              hours={sessionHours}
              minutes={sessionMin}
              onHours={onSessionHours}
              onMinutes={onSessionMin}
              title="Time needed for tutoring<br />session:"
              disabledHour={!videoCall}
              disabledMinutes={!videoCall}
            />
          </div>
        </div>

        {shortViewMode &&
          <>
            <div className="col-8 estimation-modal-container__name mb12 estimation-modal-container__border">
              Total order size: <b>{suggestedOrderSize}</b>
            </div>
            <div className="row mb12">
              <div className="col-8 estimation-modal-container__name">
                Pay rate:
              </div>
              <div className="col-4 estimation-modal-container__value">
                ${pricePerHour}/hour
              </div>
            </div>
            <div className="row mb12">
              <div className="col-8 estimation-modal-container__name">
                Order price:
              </div>
              <div className="col-4 estimation-modal-container__value">
                ${calcWriterPrice.toFixed(2)}
              </div>
            </div>
          </>
        }

        {!shortViewMode &&
          <>
            <div className="row mb12">
              <div className="col-8 estimation-modal-container__name">
                New suggested order size:
              </div>
              <div className="col-4 estimation-modal-container__value">
                <b>{suggestedOrderSize}</b>
              </div>
            </div>

            <div className="row mb14">
              <div className="col-8 estimation-modal-container__name">
                New suggested order price:
              </div>
              <div className="col-4 estimation-modal-container__value">
                <b>${calcWriterPrice.toFixed(2)}</b>
              </div>
            </div>
          </>
        }


        <div className="estimation-modal-container__noties-wrap">
          <i className="icon" />
          <p>Please note that this suggestion concerns order size only and does not imply any changes in the deadline.</p>
        </div>

        <div className="comment-block">
          <div className="estimation-modal-container__label mb10">
            You can use an adjustable template to explain reasoning behind your estimate. It will be sent together with your estimate.
          </div>
          <textarea className={classNames('comment-block-comment', { 'comment-block-comment--with-text': !!estimateComment })} placeholder="Type something..." value={estimateComment} onChange={onEstimateComment} />
          <div className="row flex-end comment-block__comment-size-wrap">
            <p className="comment-block__comment-size">{commentCount}</p>
          </div>
        </div>
      </div>

      <div className="estimation-modal-container__bt-wrap">
        {
          withToolTip
            ? (<Tooltip className="ta-left" isHtml content={withToolTipText}><button className={classNames('estimation-modal-btn estimation-modal-btn--create-btn', { 'estimation-modal-btn--loading': isLoading || isInValidForm })} onClick={onCreateNewEstimate}>Submit</button></Tooltip>)
            : (<button className={classNames('estimation-modal-btn estimation-modal-btn--create-btn', { 'estimation-modal-btn--loading': isLoading || isInValidForm })} onClick={onCreateNewEstimate}>Submit</button>)
        }
      </div>
    </>
  );
};

export default CreateEstimateModal;
