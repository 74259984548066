import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Icon from './Icon';
import { deleteAlert } from '../store/alerts/actions';

const ShowAlert = () => {
  const alerts = useSelector(state => state.alerts.alertList);
  const dispatch = useDispatch();

  let timeOutId = null;
  useEffect(() => {
    if (alerts.length > 0) {
      timeOutId = setTimeout(() => dispatch(deleteAlert()), 3000);
    }
    return () => clearTimeout(timeOutId);
  }, [alerts]);

  if (alerts.length <= 0) return null;

  return (
    <div className="success-wrap">
      <div className="success-content">
        <Icon className="svg-icon success" iconName="check-fill" />
        <div>{alerts.map(a => <div key={uuidv4()}>{a}</div>)}</div>
      </div>
    </div>
  );
};

export default ShowAlert;

