
import React from 'react';

const ButtonRejectChat = ({ onRejectChat }) => {
  return (
    <button className="btn btn-error btn-md2 mb15 ml10" type="button" onClick={onRejectChat}>Reject from chat</button>
  );
};

export default ButtonRejectChat;
