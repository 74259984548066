import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyProgressTable from './../components/MyProgressTable';
import Tooltip from './../components/Tooltip';
import WriterGroupLevel from './../components/WriterGroupLevel';
import utils from './../utils';
import { isNewbie, isBeginner } from './../utils/groups';


/* eslint-disable react/prefer-stateless-function */
class ProgressBenefitsPage extends Component {
  render() {
    const {
      user,
      isLoading,
      cntBonusesTask,
      amountBonuses,
      history,
    } = this.props;
    if (utils.isStem(user)) history.push('/');

    if (isLoading) {
      return null;
    }
    const group = user.qc_group.toLowerCase();
    const isNewbieGroup = isNewbie(group);
    const isBeginnerGroup = isBeginner(group);
    // const isSimpleGroup = isGroup(group);
    // const activeWarnigns = user.warnings.activeCount;

    const levelName = isNewbieGroup ? 'Newbie' : isBeginnerGroup ? 'Beginner' : group.substring(0, 2);

    return (
      <div className="container">
        <div className="row progress-container">
          <aside className="left-sidebar content-item">
            <div className="sidebar-border">
              <WriterGroupLevel user={user} />
            </div>

            <div className="sidebar-border">
              <h3>My performance:</h3>
              <div className="row writer-group-head vertical">
                <div className="col-4">
                  <b>Completed</b>
                </div>
                <div className="col-2 text-success">Pages</div>
                <div className="col-2 text-success">Orders</div>
              </div>

              <div className="row writer-group-row">
                <div className="col-4">This month:</div>
                <div className="col-2">{user.metric_data.completed_orders_size_pages_month}</div>
                <div className="col-2">{user.metric_data.completed_orders_count_month}</div>
              </div>

              <div className="row writer-group-row">
                <div className="col-4">Last month:</div>
                <div className="col-2">{user.metric_data.last_completed_orders_size_pages_month}</div>
                <div className="col-2">{user.metric_data.last_completed_orders_count_month}</div>
              </div>

              <div className="row writer-group-row">
                <div className="col-4"><b>For all time:</b></div>
                <div className="col-2">{user.metric_data.completed_orders_size_pages}</div>
                <div className="col-2">{user.metric_data.completed_orders_count}</div>
              </div>

              {levelName !== 'a+' &&
                <div className="benefit-banner">
                  <h4>Earn more!</h4>
                  <p>
                    Achieve at least <span className="text-success">4.7</span><br />
                    average client’s score<br />
                    (based on 10+scores) <br />
                    and start earning<br />
                    <span className="text-success">35% more</span> on every<br />
                    order!
                  </p>
                </div>
              }

              {cntBonusesTask > 2 &&
                <Tooltip className="corner_tooltip" content="We summarize possible rewards for monetary tasks you have in progress. Please check My Tasks section on the right.">
                  <div className="sidebar-border bonuses-dashboard">
                    <b>Complete {cntBonusesTask} tasks to get extra ${amountBonuses}</b> directly to your available balance. <br /> Hurry up!
                  </div>
                </Tooltip>
              }
            </div>
          </aside>

          <section className="right-content container-border">
            <h1 className="title">My tasks</h1>
            <MyProgressTable />
          </section>

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, notification } = state;
  const isLoading = !user._id;
  const cntBonusesTask = notification.cntRewardTaskInprogress;
  const amountBonuses = notification.amountRewardTaskInprogress;

  return {
    user,
    isLoading,
    cntBonusesTask,
    amountBonuses,
  };
};

export default connect(mapStateToProps)(ProgressBenefitsPage);
