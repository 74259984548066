import getFileExtensions from './getFileExtensions';

const getCroppedFileName = (fileName, maxLength) => {
  if (fileName.length < maxLength) return fileName;

  const ext = getFileExtensions(fileName);

  const croppedFileName = fileName.slice(0, maxLength - 3 - ext.length);

  return `${croppedFileName}...${ext}`;
};

export default getCroppedFileName;
