import { v4 as uuidv4 } from 'uuid';

export const SET_PLUGINS = 'SET_PLUGINS';
export const CLOSE_PLUGINS = 'CLOSE_PLUGINS';

export const setPlugin = (pluginData) => {
  if (!pluginData.id) {
    pluginData = { ...pluginData, id: uuidv4() };
  }
  return {
    type: SET_PLUGINS,
    payload: pluginData,
  };
};

export const closePlugin = pluginId => ({
  type: CLOSE_PLUGINS,
  payload: pluginId,
});
