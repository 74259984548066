import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  NavLink,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import { withRouter } from 'hocs';

import { getUnreadCompetedOrdersMessages } from 'store/ordersComplete/selectors';
import { getUnreadRefundOrdersMessages } from 'store/ordersRefunded/selectors';
import { ordersProgressFetchIfNeeded } from 'store/ordersProgress/actions';
import { ordersRevisionFetchIfNeeded } from 'store/ordersRevision/actions';
import { ordersCompleteFetchIfNeeded } from 'store/ordersComplete/actions';
import { ordersRefundedFetch } from 'store/ordersRefunded/actions';

import { ordersFindFetchIfNeeded } from 'store/ordersFind/actions';

import {
  getHasUnreadMessagesOrdersProgress,
  getHasUnreadChatMessagesOrdersRevision,
  getHasUnreadChatMessagesOrdersComplete,
  getHasUnreadMessagesOrdersRevision,
} from 'store/notification/selectors';

import classNames from 'utils/classNames';

import Nav from 'components/Nav';

import MyOrdersProgressPage from './MyOrdersProgressPage';
import MyOrdersRevisionsPage from './MyOrdersRevisionsPage';
import MyOrdersCompletePage from './MyOrdersCompletePage';
import MyOrdersRefundedPage from './MyOrdersRefundedPage';

class MyOrdersPage extends Component {
  componentDidMount() {
    const { loadOrders } = this.props;
    loadOrders();
  }

  render() {
    const {
      hasUnreadProgress,
      hasUnreadRevision,
      hasUnreadComplete,
      hasUnreadRefund,
    } = this.props;
    const progressClassName = classNames({ new: hasUnreadProgress });
    const revisionClassName = classNames({ new: hasUnreadRevision });
    const completeClassName = classNames({ new: hasUnreadComplete });
    const refundClassName = classNames({ new: hasUnreadRefund });

    return (
      <div>
        <Nav sub>
          <NavLink className={progressClassName} to="inprogress">In Progress</NavLink>
          <NavLink className={revisionClassName} to="revisions">Revisions</NavLink>
          <NavLink className={completeClassName} to="completed">Completed</NavLink>
          <NavLink className={refundClassName} to="refunded">Refunded</NavLink>
        </Nav>
        <Routes>
          <Route path="inprogress" element={<MyOrdersProgressPage {...this.props} />}>
            <Route path=":orderNumber" element={<MyOrdersProgressPage {...this.props} />} />
          </Route>
          <Route path="revisions" element={<MyOrdersRevisionsPage {...this.props} />}>
            <Route path=":orderNumber" element={<MyOrdersRevisionsPage {...this.props} />} />
          </Route>
          <Route path="completed" element={<MyOrdersCompletePage {...this.props} />}>
            <Route path=":orderNumber" element={<MyOrdersCompletePage {...this.props} />} />
          </Route>
          <Route path="refunded" element={<MyOrdersRefundedPage {...this.props} />} >
            <Route path=":orderNumber" element={<MyOrdersRefundedPage {...this.props} />} />
          </Route>
          <Route path="*" element={<Navigate to="inprogress" replace />} />
        </Routes>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const hasUnreadProgress = getHasUnreadMessagesOrdersProgress(state);
  const hasUnreadRevision = getHasUnreadMessagesOrdersRevision(state) || getHasUnreadChatMessagesOrdersRevision(state);
  const hasUnreadComplete = getUnreadCompetedOrdersMessages(state) || getHasUnreadChatMessagesOrdersComplete(state);
  const hasUnreadRefund = getUnreadRefundOrdersMessages(state);
  return { hasUnreadProgress, hasUnreadRevision, hasUnreadComplete, hasUnreadRefund };
};

const mapDispatchToProps = dispatch => ({
  loadOrders: () => {
    dispatch(ordersFindFetchIfNeeded());
    dispatch(ordersProgressFetchIfNeeded());
    dispatch(ordersRevisionFetchIfNeeded());
    dispatch(ordersCompleteFetchIfNeeded());
    dispatch(ordersRefundedFetch());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyOrdersPage));
