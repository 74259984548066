import Estimations from './Estimations';
import LiveSession from './LiveSession';
import CardMessage from './CardMessage';
import OrderCompleted from './OrderCompleted';
import ChatClosed from './ChatClosed';
import RateRequested from './RateRequested';
import TaskInstructions from './TaskInstructions';

import { MSG_MAP } from './constants';

const CARD_MSG = Object.keys(MSG_MAP).reduce((prev, next) => { prev[next] = CardMessage; return prev; }, {});

export default {
  'crm:order_estimated': Estimations,
  'crm:live_session_scheduled': LiveSession,
  'crm:live_session_rescheduled': LiveSession,
  'crm:live_session_canceled': LiveSession,
  'crm:live_session_upcoming': LiveSession,
  'crm:order_completed': OrderCompleted,
  'crm:order_closed': ChatClosed,
  'crm:rate_requested': RateRequested,
  'onboard:added_task_instructions': TaskInstructions,
  ...CARD_MSG,
};
