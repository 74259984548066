import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActiveWarnings from './../components/progress/ActiveWarnings';
import { userWarningsFetchIfNeeded } from './../store/user/actions';
import Warnings from './../components/progress/Warnings';


class ProgressWarningsPage extends Component {
  componentWillMount() {
    this.props.load();
  }

  render() {
    const { user } = this.props;
    const warningsItems = user.warnings.items;
    // const newbieTooltip = (
    //   <span>
    //     You are a newbie now.<br />
    //     <b>Complete {newbieOrdersForLevelUp - completedOrders} orders to<br />
    //       unlock your next group!</b>
    //   </span>
    // );
    // const beginnerTooltip = (
    //   <span>
    //     Congratulations!<br />
    //     You are a beginner now.<br />
    //     <b>Complete {beginnerOrdersForLevelUp - completedOrders} orders to<br />
    //       unlock your next group!</b>
    //   </span>
    // );

    return (
      <div className="warning-content">
        <p className="description">
          Warnings are an additional indicator of your activity on the platform. They help us monitor disciplinary issues from your side, which might influence clients' experience and result in low client scores. <br/>
          Every time there is any undesired activity from your side like <b>1) missed deadlines, 2) rejected or not submitted final papers and revisions, 3) submitted plagiarized or poorly written papers, a warning will be automatically generated.</b>
        </p>
        <p className="description">
          High number of warnings will prevent you from enjoying the benefits of your Writer group defined by Average Client Score. <br/>
          <b>Here are a couple of simple rules:</b>
        </p>
        <ul className="ul-list">
          <li>you are eligible for all the benefits of your group only if you have not more than 1 active warning at a single point of time; </li>
          <li>if you get 4 and more active warnings or violate the system, we will consider terminating your account.</li>
        </ul>
        <p className="description">Any active warning lives for 10 orders after you get it. Below you can see all the warnings that influence the benefits of your group right now (if any) and the number of orders you have to complete to close those warnings. Additionally, you can check the history of all closed warnings. </p>
        <ActiveWarnings warnings={warningsItems} />
        <Warnings warnings={warningsItems} profile={user} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  const warningsIsFetching = user.warnings.isFetching;

  return { user, warningsIsFetching };
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(userWarningsFetchIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressWarningsPage);
