import numWord from './numWord';

const convertMinsToHrsMins = (minutes, noTitle = false, customTitles = null) => {
  if (minutes <= 0) return '0 minutes';

  const h = Math.floor(minutes / 60);
  const m = minutes % 60;

  if (noTitle) return [h, m];

  const hTitle = customTitles ? customTitles[0] : `${numWord(h, ['hour', 'hours', 'hours'])}`;
  const mTitle = customTitles ? customTitles[1] : `${numWord(m, ['minute', 'minutes', 'minutes'])}`;

  if (m === 0) return `${h} ${hTitle}`;
  if (h === 0) return `${m} ${mTitle}`;

  return `${h} ${hTitle} ${m} ${mTitle}`;
};

export default convertMinsToHrsMins;
