import React from 'react';

import { genderList } from 'constants';

import { ProfileInput, ProfileDropdown, DateOfBirthDropdown } from '../Controls';

const About = ({ data, isStem, onChangeFields }) => {
  const {
    public_name, public_initial, gender, birth_day,
  } = data;
  return (
    <div className="row person-common-info">
      <div className="col-2 person-common-info-title">About</div>
      <div className="col-4">
        <ul>
          <li className="person-info__item">
            <ProfileInput
              value={public_name}
              fieldName="public_name"
              onChangeFields={!isStem && onChangeFields}
              title="Public name"
              placeholder="Public first name (one word)"
            />
          </li>
          <li className="person-info__item">
            <ProfileInput
              value={public_initial}
              fieldName="public_initial"
              onChangeFields={!isStem && onChangeFields}
              title="Public initial"
              placeholder="Enter your public initial"
            />
          </li>
          <li className="person-info__item">
            <ProfileDropdown
              value={gender}
              fieldName="gender"
              list={genderList}
              onChangeFields={!isStem && onChangeFields}
              title="Gender"
            />
          </li>
          <li className="person-info__item">
            <DateOfBirthDropdown
              onChangeFields={!isStem && onChangeFields}
              value={birth_day}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default About;
