const getPreferredHelp = (order, upperCase = false) => {
  const { output_formats: helpFormats = [], other_output_format: otherOutputFormat = '' } = order;

  return helpFormats
    .reduce((prev, next = '') => {
      if (next === 'Decide later' || next === 'No live session' || next === 'Live session') return prev;

      if ((next === 'Video call' || next === 'Tutoring session') && !prev.includes(upperCase ? 'Tutoring session' : 'tutoring session')) {
        prev.push(upperCase ? 'Tutoring session' : 'tutoring session');
        return prev;
      } else if ((next === 'Video call' || next === 'Tutoring session') && prev.includes(upperCase ? 'Tutoring session' : 'tutoring session')) {
        return prev;
      }

      if (next === 'Other' && otherOutputFormat) {
        prev.push(`${upperCase ? 'Other' : 'other'} - ${otherOutputFormat}`);
        return prev;
      }

      prev.push(!upperCase ? next.toLocaleLowerCase() : next);

      return prev;
    }, []);
};

export default getPreferredHelp;
