import React from 'react';

import { useMobile } from 'hooks';

import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';

import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';

const FinalPaperFileRow = (props) => {
  const {
    file, onRemove, fileRowClick, isModal, isError, plagFileHref, selectedFiles = [], selectFile, hideOwner,
  } = props;

  const { override_pgch: isOverride = false, plag_status: plagStatus = null } = file;
  const isFileOnCheck = plagStatus === 'submit' || plagStatus === 'error';
  const isFileWithOutPlag = plagStatus === 'notfound';

  const { isMobile } = useMobile({ width: 768 });

  const handlers = {};

  if (fileRowClick) {
    handlers.onClick = e => fileRowClick(e, file);
  }

  if (!file.isFetching) {
    return (
      <div className="pos-relative">
        {!isMobile && fileRowClick && <Checkbox checked={selectedFiles.includes(file._id)} onChange={e => selectFile(e, file._id)} />}
        <div className="row upload-file-item vertical space-between" {...handlers}>
          <div className="row vertical upload-file-item__file-name">
            <div className="row vertical">
              {!!plagFileHref && !isOverride &&
                <Icon className="svg-icon" iconName="plagreport" />
              }
              {!plagFileHref && !isOverride && isFileWithOutPlag &&
                <i className="plagreport-success" />
              }
              {!plagFileHref && !isOverride && isFileOnCheck &&
                <i className="plagreport-in-progress" />
              }
              <a
                href={file.location || file.url}
                target="_blank"
                onClick={(e) => {
                if (fileRowClick) fileRowClick(e, file);
                else e.stopPropagation();
              }}
                rel="noopener noreferrer"
              >
                <span className={classNames({ 'no-plag': !plagFileHref })}>{file.name}</span>
              </a>
              {!!onRemove && file.owner_role === 'writer' &&
                <button
                  className="btn btn-empty btn-delete-file"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(file._id);
                  }}
                  disabled={file.isFetchingRemove}
                >
                  <svg className="delete-file">
                    <g>
                      <circle cx="8" cy="8" r="8" />
                      <path fill="#9CA9B5" fillRule="nonzero" d="M8.667 8L11 5.667 10.333 5 8 7.333 5.667 5 5 5.667 7.333 8 5 10.333l.667.667L8 8.667 10.333 11l.667-.667L8.667 8z" />
                    </g>
                  </svg>
                </button>
              }
              {/* {openFilePreviewHandler && !isMobile && <i className={classNames('open-preview-icon ml8', { 'no-plag': !plagFileHref })} onClick={e => openFilePreviewHandler(e, file._id)} />} */}
            </div>
            {!!plagFileHref && !isOverride &&
              <div className="col-2 ta-right"><a onClick={(e) => { e.stopPropagation(); }} href={plagFileHref} target="_blank" rel="noopener noreferrer" className="badge plag-report">Plag report</a></div>
            }
          </div>
          {file.owner_role && !hideOwner &&
            <div className={plagFileHref ? 'ta-right order-file-date' : 'col-2 ta-right order-file-date'}>
              {file.owner_role} on {formatTime(file.created_at, 'dt')}
            </div>
          }
        </div>
      </div>
    );
  }

  if (isModal) {
    return (
      <div>
        <div className="row vertical modal-file-item">
          <div className="col-file-name">
            <span className="upload-file-progress">
              <i className="file-name-progress">{file.name}</i>
            </span>
          </div>
          {!isError &&
            <div className="row vertical">
              <div className="file-upload-info">
                <div className="file-progress">
                  <i className="bg-progress" style={{ width: `${file.progress}%` }} />
                </div>
                <span className="file-progress-percent"><b>{file.progress}</b> %</span>
              </div>
              <div className="col-2">
                <a onClick={(e) => { e.stopPropagation(); onRemove(file._id); }}>Cancel</a>
              </div>
            </div>
          }
          {isError &&
            <div className="row vertical space-between">
              <div className="text-danger file-upload-info">uploading error</div>
              <a onClick={(e) => { e.stopPropagation(); onRemove(file._id); }}>Close</a>
            </div>
          }

        </div>
      </div>
    );
  }
  return (
    <div className="row">
      <div className="col-1">
        <span className="upload-file-progress">
          <i className="file-name-progress">{file.name}</i>
          <i className="bg-progress" style={{ width: `${file.progress}%` }} />
        </span>
      </div>
    </div>
  );
};

export default FinalPaperFileRow;
