import classNames from 'utils/classNames';
import getCroppedFileName from 'utils/getCroppedFileName';
import formatBytes from 'utils/formatBytes';

import CircularProgressBar from 'components/CircularProgressBar';
import DeleteFileIcon from 'components/Icon/DeleteFileIcon';
import Icon from 'components/Icon';

const FileItem = ({ file, onDeleteFile, onReload }) => {
  const {
    completed, size, error, progress, name,
    location,
  } = file;

  const renderFileName = () => {
    const croppedFileName = getCroppedFileName(name, 38);

    if (!completed) {
      return (<div className={classNames(
        'upload-files-modal-file-item__name',
        { 'upload-files-modal-file-item__name--error': error },
        { 'upload-files-modal-file-item__name--uploading': progress >= 0 },
      )}
      >{croppedFileName}</div>);
    }

    return <a className="upload-files-modal-file-item__name" href={location} target="_blank" rel="noreferrer">{croppedFileName}</a>;
  };

  const renderFileStatus = () => {
    if (error) {
      return (<div className={classNames(
        'upload-files-modal-file-item__size',
        { 'upload-files-modal-file-item__size--error': error },
      )}
      >Error</div>);
    }

    if (!completed) return <CircularProgressBar percentage={progress} />;

    return <div className="upload-files-modal-file-item__size">{formatBytes(size, 1)}</div>;
  };

  return (
    <div className="upload-files-modal-file-item row space-between vertical">
      <div className="row column_gap_8 vertical">
        {renderFileName()}
        <DeleteFileIcon className="mt2" name={location} onClick={onDeleteFile} />
        {error && (
          <button
            className="btn btn-empty mt2"
            type="button"
            onClick={onReload}
            name={location}
          >
            <Icon iconName="reload" className="reload" />
          </button>
        )}
      </div>
      {renderFileStatus()}
    </div>
  );
};

export default FileItem;
