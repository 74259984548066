import React from 'react';
import { connect } from 'react-redux';

import Dropdown from './../Dropdown';
import Icon from './../Icon';

const OrderFooter = (props) => {
  const {
    id, templates, standalone, order = {},
  } = props;
  const { no_sensitive: noSensitive = false } = order;
  return (
    <div className="row vertical footer-container">
      {!noSensitive && (
        <div className="col-1">
          <Dropdown text="Get templates" up>
            <ul className="menu menu-selectable">
              {templates.map(template =>
                    (<li className="menu-item" key={template.id}>
                      <a
                        href={template.location}
                        rel="noopener noreferrer"
                        target="_blank"
                      >{template.text}</a>
                    </li>))}
            </ul>
          </Dropdown>
        </div>
            )}
      <div className="col-1 ta-right">
        {!!id && !standalone &&
        <a className="lnk lnk-new-window" href={`/order/${id}`} rel="noopener noreferrer" target="_blank">
          <Icon className="svg-icon" iconName="open-new-window" />
          Open in new window
        </a>
                }
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { orderNumber } = ownProps;
  const order = state.orders[orderNumber];
  const templates = Object.values(state.settings.templates);

  return {
    id: !order ? null : order._id,
    templates,
  };
};
export default connect(mapStateToProps)(OrderFooter);
