import React, { Component } from 'react';
import ClassNames from './../utils/classNames';

export default class StrictTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    }
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({ isOpen: false });
  }
  
  render() {
    const { content, isGreen, isYellow, positionLeft, positionDown, className } = this.props;
    const { isOpen } = this.state;
    const contentClassNames = ClassNames('sctict-tooltip-content', {
      'position-down': positionDown,
      'position-left': positionLeft,
      'strict-tooltip-green': isGreen,
      'strict-tooltip-yellow': isYellow,
      [className]: className,
    });
    return (
      <div className="tooltip-container">
        {isOpen &&
          <span className={contentClassNames}>
            {content}
            <span onClick={this.onClose} className='ctrict-tooltip-close' />
          </span>
        }
      </div>
    );
  }
};
