import React from 'react';
import { connect } from 'react-redux';

import { withRouterMatch } from 'hocs';

import { getGuideListFiltered } from './../store/help/selectors';
import MarkableText from './MarkableText';
import utils from './../utils';

const HelpTabGuides = (props) => {
  const {
    guideItemTitle,
    search,
    paymentMethodsCategory,
    category,
    profile,
    history,
  } = props;
  let { guideList } = props;
  const isStem = utils.isStem(profile);
  if (isStem && category === paymentMethodsCategory) {
    history.push('/help/guides');
  }
  if (isStem) {
    guideList = guideList.filter(item => item.category !== paymentMethodsCategory);
  }
  return (
    <section className="right-content padding-content">
      {search ?
        <h1 className="title title-search"><span>Search results for:</span> {search}</h1> :
        <h1 className="title">{guideItemTitle}</h1>
      }
      <ul className="guide-list">
        {guideList.map(guideItem => (
          <li key={guideItem.title}>
            <a className={`guide-item file-icon ${guideItem.icon}`} href={guideItem.url} target="_blanc">
              <MarkableText html={guideItem.title} mark={search} />
              <span className="btn btn-light btn-small custom-hover">DOWNLOAD</span>
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { category } = ownProps.match.params;
  const { search, paymentMethodsCategory } = state.help;
  const guideList = getGuideListFiltered(state);
  const catGuideList = !search ? guideList.filter(item => item.category === category) : guideList;
  const guideItemTitle = state.help.guidesCats[category].text;
  return {
    guideList: catGuideList,
    guideItemTitle,
    search,
    category,
    paymentMethodsCategory,
    profile: state.user,
  };
};

export default withRouterMatch(connect(mapStateToProps)(HelpTabGuides));
