import React from 'react';

const DetailsContent = ({ groupItem, clientInfo }) => (
  <div className="order-tab-content detail-info">
    {
      groupItem.map(item => item)
    }
    {clientInfo.country &&
      <div className="row">
        <div className="col-1">Country of origin</div>
        <div className="col-1">{clientInfo.country}</div>
      </div>
            }
    {clientInfo.education_country &&
      <div className="row">
        <div className="col-1">Country of education</div>
        <div className="col-1">{clientInfo.education_country}</div>
      </div>
            }
    {clientInfo.gender &&
      <div className="row">
        <div className="col-1">Gender</div>
        <div className="col-1">{clientInfo.gender}</div>
      </div>
            }
    {clientInfo.university &&
      <div className="row">
        <div className="col-1">University</div>
        <div className="col-1">{clientInfo.university}</div>
      </div>
            }
    {clientInfo.major &&
      <div className="row">
        <div className="col-1">Major</div>
        <div className="col-1">{clientInfo.major}</div>
      </div>
            }
    {clientInfo.course &&
      <div className="row">
        <div className="col-1">Order course</div>
        <div className="col-1">{clientInfo.course}</div>
      </div>
            }
  </div>
);

export default DetailsContent;
