import React, { useEffect, useMemo } from 'react';

import { withRouter } from 'hocs';
import utils from 'utils';

const ENABLED_MOBILE_PAGE = [
  'findorders',
  'myorders',
  'finances',
  'profile',
];

const GotoMobileNotification = ({ location = {} }) => {
  const pathName = useMemo(() => location.pathname, [location]);
  const isMobileEquivalent = ENABLED_MOBILE_PAGE.some(it => pathName.match(it));
  useEffect(() => {
    if (!isMobileEquivalent) return;
    utils.updateCookie('DESKTOP', '', 0);
    setTimeout(() => {
      window.location.reload(true);
    }, 100);
  }, [pathName, isMobileEquivalent]);
  return null;
};

export default withRouter(GotoMobileNotification);
