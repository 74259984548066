import moment from 'moment-timezone';
import { urls } from './../../config';
import authFetch from './../../utils/authFetch';
import { notificationSetFinanceRead } from './../notification/actions';

export const FINANCES_REQUEST = 'FINANCES_REQUEST';
export const FINANCES_RECEIVE = 'FINANCES_RECEIVE';

export const FINANCES_SET_FILTER_TIME = 'FINANCES_SET_FILTER_TIME';
export const FINANCES_SET_FILTER_TYPE = 'FINANCES_SET_FILTER_TYPE';
export const FINANCES_SET_FILTER_STATUS = 'FINANCES_SET_FILTER_STATUS';

export const FINANCE_SET_VIEWED_REQUEST = 'FINANCE_SET_VIEWED_REQUEST';
export const FINANCE_SET_VIEWED_RECEIVE = 'FINANCE_SET_VIEWED_RECEIVE';

export const FINANCES_WITHDRAWAL_REQUEST = 'FINANCES_WITHDRAWAL_REQUEST';
export const FINANCES_WITHDRAWAL_RECEIVE = 'FINANCES_WITHDRAWAL_RECEIVE';
export const FINANCES_WITHDRAWAL_ERROR = 'FINANCES_WITHDRAWAL_ERROR';

export const FINANCES_CANCEL_REQUEST = 'FINANCES_CANCEL_REQUEST';
export const FINANCES_CANCEL_RECEIVE = 'FINANCES_CANCEL_RECEIVE';

export const RESET_FINANCES_FILTERS = 'RESET_FINANCES_FILTERS';


// FINANCES
const financesRequest = () => ({
  type: FINANCES_REQUEST,
});

const financesReceive =
  (items, available, pending, execution, minWithdrawal, nextWithdrawal, withdrawalTo, fee, feePerc, completed, allow_withdrawal, pending_withdrawal) => ({
    type: FINANCES_RECEIVE,
    items,
    available,
    pending,
    execution,
    minWithdrawal,
    withdrawalTo,
    nextWithdrawal,
    fee,
    feePerc,
    completed,
    allow_withdrawal,
    pending_withdrawal,
  });

const financesFetch = () => (dispatch) => {
  dispatch(financesRequest());
  return authFetch(urls.finances).then((json) => {
    const items = json.results.reduce((result, item) => {
      result[item._id] = item;
      return result;
    }, {});
    const {
      available, pending, next_withdrawal_on, TRANSACTION_MIN_AMOUNT, payment_details, execution, fee, fee_perc, completed, pending_withdrawal, allow_withdrawal,
    } = json;
    dispatch(financesReceive(items, available, pending, execution, TRANSACTION_MIN_AMOUNT, next_withdrawal_on, payment_details, fee, fee_perc, completed, allow_withdrawal, pending_withdrawal));
  });
};

const financesShouldFetch = (state) => {
  const { finances } = state;
  if (finances.isFetching) {
    return false;
  }
  if (finances.updatedAt && moment().diff(finances.updatedAt, 'minutes') > 1) {
    return true;
  }
  return finances.didInvalidate;
};

export const financesFetchIfNeeded = () => (dispatch, getState) => {
  if (financesShouldFetch(getState())) {
    return dispatch(financesFetch());
  }
};

// FILTERS
export const financesSetFilterTime = value => ({
  type: FINANCES_SET_FILTER_TIME,
  value,
});

export const financesSetFilterType = value => ({
  type: FINANCES_SET_FILTER_TYPE,
  value,
});

export const financesSetFilterStatus = value => ({
  type: FINANCES_SET_FILTER_STATUS,
  value,
});

// FINANCE
const financeSetViewedRequest = id => ({
  type: FINANCE_SET_VIEWED_REQUEST,
  id,
});

const financeSetViewedReceive = id => ({
  type: FINANCE_SET_VIEWED_RECEIVE,
  id,
});

export const financeSetViewedFetch = id => (dispatch) => {
  dispatch(financeSetViewedRequest(id));
  return authFetch(`${urls.finances}/${id}`, {
    method: 'POST',
    body: JSON.stringify({ action: 'view' }),
  }).then(() => {
    dispatch(notificationSetFinanceRead(id));
    dispatch(financeSetViewedReceive(id));
  });
};

// WITHDRAWAL
const financesWithdrawalRequest = () => ({
  type: FINANCES_WITHDRAWAL_REQUEST,
});

const financesWithdrawalReceive = () => ({
  type: FINANCES_WITHDRAWAL_RECEIVE,
});

const financesWithdrawalError = message => ({
  type: FINANCES_WITHDRAWAL_ERROR,
  message,
});

export const financesWithdrawalFetch = (value, otpCode) => (dispatch) => {
  dispatch(financesWithdrawalRequest());
  return authFetch(urls.finances, {
    method: 'POST',
    body: JSON.stringify({
      action: 'create',
      amount: value,
      otp_code: otpCode,
    }),
  }).then(() => dispatch(financesWithdrawalReceive())).catch(error => dispatch(financesWithdrawalError(error.error_message)));
};

// CANCEL

const financesCancelRequest = () => ({
  type: FINANCES_CANCEL_REQUEST,
});

const financesCancelReceive = () => ({
  type: FINANCES_CANCEL_RECEIVE,
});

export const financesCancelFetch = () => (dispatch) => {
  dispatch(financesCancelRequest());
  return authFetch(urls.finances, {
    method: 'POST',
    body: JSON.stringify({ action: 'cancel' }),
  }).then(() => dispatch(financesCancelReceive()));
};

// RESET

export const financesResetAllFilters = () => ({
  type: RESET_FINANCES_FILTERS,
});
