import React, { Component } from 'react';

export default class Star extends Component {
  constructor(props) {
    super(props);

    this.onStarMouseOver = this.onStarMouseOver.bind(this);
    this.onStarMouseOut = this.onStarMouseOut.bind(this);
  }
  onStarMouseOver() {
    const { rate, onStarOver, isEditing } = this.props;
    if (!isEditing) return;
    onStarOver(rate);
  }

  onStarMouseOut() {
    const { onStarOut, isEditing } = this.props;
    if (!isEditing) return;
    onStarOut();
  }
  render() {
    const { rate, onClick, filled, isEditing, instanceKey, customFill } = this.props;
    let fillColor = '#000';
    let emptyColor = '#fff';
    let stroke = 'none';

    if (isEditing) {
      fillColor = '#dfeeff';
      emptyColor = '#fff';
      stroke = '#3099ff';
    } else if (customFill) {
      fillColor = customFill;
      emptyColor = '#e6e8eb';
      stroke = 'none';
    } else {
      fillColor = '#ffa525';
      emptyColor = '#e6e8eb';
      stroke = 'none';
    }
    return (
      <div
        className="star-container"
        onClick={() => isEditing ? onClick(rate) : null}
        onMouseOver={this.onStarMouseOver}
        onMouseOut={this.onStarMouseOut}
      >
        <svg viewBox="0 0 80 80">
          <linearGradient id={`partGradient-${rate}-${instanceKey}`}>
            <stop stopOpacity="1" offset={`${filled * 100}%`} stopColor={fillColor} />
            <stop stopOpacity="1" offset="0%" stopColor={emptyColor} />
          </linearGradient>
          <g fill={`url(#partGradient-${rate}-${instanceKey})`} stroke={stroke} strokeWidth="2">
            <path d="
              M 40.000 60.000
              L 63.511 72.361
              L 59.021 46.180
              L 78.042 27.639
              L 51.756 23.820
              L 40.000 0.000
              L 28.244 23.820
              L 1.958 27.639
              L 20.979 46.180
              L 16.489 72.361
              L 40.000 60.000
              "
            />
          </g>
        </svg>
      </div>
    );
  }
}
