const ConfirmModal = ({
  header, text, subText, btnsText = [],
  onCancel, onConfirm, filesSubmitting,
}) => (
  <div className="confirm-modal">
    <h3 className="confirm-modal__header" dangerouslySetInnerHTML={{ __html: header }} />
    <p className="confirm-modal__text">{text}</p>
    {subText && <p className="confirm-modal__sub-text">{subText}</p>}
    <div className="row column_gap_12 mt24">
      <button type="button" className="btn btn-light btn-block" onClick={onCancel}>{btnsText[0]}</button>
      <button type="button" className="btn btn-bright btn-block" onClick={onConfirm}>{filesSubmitting ? btnsText[2] : btnsText[1]}</button>
    </div>
  </div>
);

export default ConfirmModal;
