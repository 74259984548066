import React from 'react';

const PollInfoBlock = ({ metricData, isWeekPoll }) => {
  const {
    completed_orders_size_pages_7days: lastWeekPages,
    completed_orders_size_pages_month: thisMothPages,
  } = metricData;

  if (isWeekPoll) {
    if (!lastWeekPages) return null;

    return (
      <div className="poll-info-block">
        <div className="poll-info-block__title">Your current <i className="poll-modal__icon poll-modal__icon--paper" /> results:</div>
        {lastWeekPages > 0 && <div className="poll-info-block__result">During the last 7 days<br />you completed <span>{lastWeekPages} pages</span></div>}
      </div>
    );
  }

  if (!lastWeekPages && !thisMothPages) return null;

  return (
    <div className="poll-info-block">
      <div className="poll-info-block__title">Your current <i className="poll-modal__icon poll-modal__icon--paper" /> results:</div>
      {thisMothPages > 0 && <div className="poll-info-block__result">Last month you completed <span>{thisMothPages} pages</span></div>}
      {lastWeekPages > 0 && <div className="poll-info-block__result">During the last 7 days <span>{lastWeekPages} pages</span></div>}
    </div>
  );
};

export default PollInfoBlock;
