import React, { Fragment } from 'react';

const TaskStatus = ({ task }) => (
  <Fragment>
    {task.status && task.status === 'done' ? 'Completed' : ''}
    {task.status && task.status === 'in-progress' ? 'In progress' : ''}
  </Fragment>
);

export default TaskStatus;
