import React, { Fragment } from 'react';
import Checkbox from 'components/Checkbox';

const TypesFilter = ({ orderTypes, stateFilter, onChangeFilter }) => {
  return (
    <Fragment>
      {Object.keys(orderTypes).map((item) => {
        const typeItem = orderTypes[item];
        const isChecked = stateFilter.types.indexOf(typeItem.values[0]) > -1;
        return (
          <li key={typeItem.name}>
            <Checkbox name={typeItem.name} disabled={typeItem.count === 0} checked={isChecked} onChange={() => onChangeFilter('types', typeItem.values, isChecked)}>
              {typeItem.name}
              <span> ({typeItem.count || 0})</span>
            </Checkbox>
          </li>
        );
      })}
    </Fragment>
  );
};

export default TypesFilter;
