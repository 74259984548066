import React from 'react';

import classNames from './../utils/classNames';

const Checkbox = props => {
  const { name, checked, onChange, disabled, children, className, minus, value } = props;
  const classNameCheckbox = classNames(className, 'checkbox', { 'disabled': disabled });
  return (
    <label className={classNameCheckbox}>
      <input type="checkbox" name={name} checked={checked} onChange={onChange} disabled={disabled} data-dropdown="keep" data-value={value} />
      {!minus &&
        <i className="checkbox-icon"></i>
      }
      {minus &&
        <i className="checkbox-icon minus"></i>
      }
      <span className="checkbox-label">{children}</span>
    </label>
  );
};

export default Checkbox;
