import fileConstants from 'constants/files';

const getFileGroupPlagStatus = (files = []) => {
  let isSomeFileHaveSubmitStatus = false;
  let fileOverrideCount = 0;
  let isSomeFileHavePlag = false;
  let isSomeFileNoPlag = false;

  files.forEach((file = {}) => {
    if (file.override_pgch) {
      fileOverrideCount += 1;
      return;
    }

    if (file.plag_status && file.plag_status === 'found' && file.plag_level >= fileConstants.MAX_PLAG_PERCENT) {
      isSomeFileHavePlag = true;
      return;
    }

    if (file.plag_status && file.plag_status === 'notfound') {
      isSomeFileNoPlag = true;
      return;
    }

    if (file.plag_status && (file.plag_status === 'submit' || file.plag_status === 'error')) {
      isSomeFileHaveSubmitStatus = true;
    }
  });


  if (isSomeFileHaveSubmitStatus) return 'onCheck';
  if (isSomeFileHavePlag) return 'plagiate';
  if ((fileOverrideCount === files.length || isSomeFileNoPlag)) return 'notfound';

  return null;
};

export default getFileGroupPlagStatus;
