
import React, { Component } from 'react';
import moment from 'moment-timezone';
import debounce from 'debounce';

import Icon from '../components/Icon';
import Calendar from '../components/Calendar';
import Dropdown from '../components/Dropdown';
import Select from '../components/Select';
import InputWithSearch from '../components/shared/InputWithSearch';

import {
  ISSUE_TYPE,
  FEEDBACK_STATUS_MAPPING,
  FEEDBACK_STATUS,
} from '../constants/reportForm';

const PAGE = 1;

class OrderFeedbackFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      filter: {},
      sorting: {},
      showFilter: true,
      page: PAGE,
    };

    this.onSetFilter = this.onSetFilter.bind(this);
    this.onSetFilterSearch = this.onSetFilterSearch.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.debounceSave = debounce(this.debounceSave, 1000);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.filter && this.props.filter) {
      const { sorting, filter, page } = this.props;
      this.setState({
        sorting,
        filter,
        page,
      });
    }
  }

  onChangeSearch(e) {
    const value = e.target.value.trim();

    this.setState({
      search: value,
      showFilter: !value,
    });
    this.debounceSave(value, this.onSetFilterSearch);
  }

  debounceSave(value, method) {
    method(value);
  }

  onSetFilterSearch(value) {
    const { setFilter, sorting } = this.props;
    const { page } = this.state;

    const newFilter = { search: value };
    this.setState({
      search: value,
      filter: {},
    });
    setFilter(page, sorting, newFilter);
  }

  onSetFilter(value, name) {
    const { setFilter, filter, sorting } = this.props;
    this.dropdownRef && this.dropdownRef.setClose();
    let newValue = value;

    if (name === 'by_created_at') {
      newValue = moment(value).format('YYYY-MM-DD');
    }
    const newFilter = { ...filter, [name]: newValue };

    this.setState({
      filter: newFilter,
      [name]: newValue,
    });
    setFilter(1, sorting, newFilter);
  }

  clearSearch() {
    const { setFilter, sorting, filter, page } = this.props;
    const newFilter = { ...filter, search: '' };
    this.setState({
      filter: {},
      search: '',
      showFilter: true,
    });
    setFilter(page, sorting, newFilter);
  }

  resetFilter() {
    const { setFilter, sorting } = this.props;
    const filter = {};
    this.setState({
      filter,
      search: '',
    });
    setFilter(1, sorting, filter);
  }

  render() {
    const { filter, search, showFilter } = this.state;
    const date = filter.by_created_at ? moment(filter.by_created_at).format('MMM D, YYYY') : 'Select creation date';

    return (
      <aside className="left-sidebar content-item">
        <InputWithSearch
          className="search-wrap"
          onSearch={() => this.onSetFilter(search, 'search')}
          inputPlaceholder="Find issue by order number"
          onInputChange={this.onChangeSearch}
          inputValue={search}
          onClearSearch={this.clearSearch}
        />
        {showFilter &&
          <div>
            <h2 className="title-filter">Filter Reports</h2>

            <div className="filter-wrap filter-period">
              <h3 className="filter-name">By creation date</h3>
              <Dropdown text={date} hideArrow icon="calendar" ref={ref => this.dropdownRef = ref}>
                <Calendar onSubmit={this.onSetFilter} chosenDate={moment(filter.by_created_at)} hideTime name="by_created_at" />
              </Dropdown>
            </div>

            <div className="filter-wrap">
              <h3 className="filter-name">By status</h3>
              <Dropdown text={FEEDBACK_STATUS_MAPPING[filter.by_state] || 'Show all'} closeAlways>
                <Select className="menu menu-selectable" onChange={this.onSetFilter}>
                  {FEEDBACK_STATUS.map(item =>
                    <li key={item.name} name="by_state" value={item.status} className={`menu-item ${(filter.by_state || []).indexOf(item.status) > -1 ? 'active' : ''}`}>{item.name}</li>)}
                </Select>
              </Dropdown>
            </div>
            <div className="reset-filter">
              <a onClick={this.resetFilter}><Icon className="svg-icon" iconName="close" /> Reset all filters</a>
            </div>
          </div>
        }
      </aside>
    );
  }
}

export default OrderFeedbackFilter;
