import React from 'react';

import classNames from 'utils/classNames';

import PollInfoBlock from './PollInfoBlock';
import PollWelcomeBlock from './PollWelcomeBlock';


const WriterPollComponent = ({
  isFetchingAction, isWeekPoll, simpleValidator, isMobile, onChangePage, pollData, metricData, onSubmit,
}) => (
  <div className={classNames('poll-modal', { 'poll-modal--loading': isFetchingAction })}>
    <PollWelcomeBlock isWeekPoll={isWeekPoll} />
    {isWeekPoll ?
        (
          <div className="poll-modal__input-container">
            <div className="input-el input-el--single">
              <input
                className={classNames('input-text', { 'is-error-only-border': !simpleValidator.current.fields.weekCapacity && pollData.weekCapacity.dirty })}
                type={isMobile ? 'tel' : 'text'}
                name="weekCapacity"
                maxLength="3"
                onChange={onChangePage}
                value={pollData.weekCapacity.value}
                placeholder="000"
                autoComplete="off"
              />
            </div>
          </div>
        ) : (
          <>
            <div className="poll-modal__input-container">
              <div className="input-label">next month:</div>
              <div className="input-el">
                <input
                  className={classNames('input-text', { 'is-error-only-border': !simpleValidator.current.fields.monthCapacity && pollData.monthCapacity.dirty })}
                  type={isMobile ? 'tel' : 'text'}
                  name="monthCapacity"
                  maxLength="3"
                  onChange={onChangePage}
                  value={pollData.monthCapacity.value}
                  placeholder="000"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="poll-modal__input-container">
              <div className="input-label">during the next<br />7 days:</div>
              <div className="input-el">
                <input
                  className={classNames('input-text', { 'is-error-only-border': !simpleValidator.current.fields.nextWeekCapacity && pollData.nextWeekCapacity.dirty })}
                  type={isMobile ? 'tel' : 'text'}
                  name="nextWeekCapacity"
                  maxLength="3"
                  onChange={onChangePage}
                  value={pollData.nextWeekCapacity.value}
                  placeholder="000"
                  autoComplete="off"
                />
              </div>
            </div>
          </>
        )
      }
    <PollInfoBlock metricData={metricData} isWeekPoll={isWeekPoll} />
    <div className="poll-footer-block">
      <div className="poll-footer-block__title">We appreciate your honesty!</div>
      <div className="poll-footer-block__text">Your answer does not influence the number of available orders that you see in any way.</div>
    </div>
    <button type="button" className="btn btn-block" onClick={onSubmit}>Submit</button>
  </div>
);

export default WriterPollComponent;
