
import React from 'react';
import incredible from 'img/icons/incredible@2x.png';
import './styles/index.scss';

const ReactivatingWarm = () => (
  <div className="frozen-container">
    <img className="frozen-icon" src={incredible} alt="" />
    <h2 className="frozen-title">Incredible!</h2>
    <p className="frozen-text frozen-text-bold">
      It's cool that you are ready to resume your work on the platform!
    </p>
    <p className="frozen-text">
      Your Personal Freelancer Care Manager will consider your request. In case of a positive decision, your account will be reactivated within 48 hours.
      <br />
      Stay tuned!
    </p>
    <p className="frozen-footer">
      <span className="frozen-footer-help">Feel free to ask us any questions at</span>
      <br />
      <a href="mailto:freelancer.care@liv-research.com">freelancer.care@liv-research.com</a>
    </p>
  </div>
);

export default ReactivatingWarm;
