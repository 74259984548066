import React, { Component } from 'react';

import Icon from './Icon';

class UpToButton extends Component {
    constructor(props) {
        super(props);

        this.handleMove = this.handleMove.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    handleMove() {
        if (!this.buttonRef || !this.containerRef) {
            return;
        }
        const isScroll = !!window.scrollY;

        const containerRef = this.containerRef;
        containerRef.style.display = isScroll ? 'block' : 'none';
        if (!isScroll) {
            return;
        }

        const { bottom } = containerRef.getBoundingClientRect();
        const isFixed = bottom > document.documentElement.clientHeight;
        this.buttonRef.style.position = isFixed ? 'fixed' : 'absolute';
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleMove);
        window.addEventListener('scroll', this.handleMove);
        this.handleMove();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleMove);
        window.removeEventListener('scroll', this.handleMove);
    }

    componentDidUpdate() {
        this.handleMove();
    }

    onClick() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.handleMove();
    }

    render() {
        return (
            <div className="upto-wrap" ref={ref => this.containerRef = ref}>
                <div className="upto-wrap-inner">
                    <button className="btn btn-upto" type="button" ref={ref => this.buttonRef = ref} onClick={this.onClick}>
                        <Icon className="svg-icon" iconName="arrow-up" />
                    </button>
                </div>
            </div>
        );
    }
}

export default UpToButton;
