import { createSelector } from 'reselect';
import moment from 'moment-timezone';

const stable = require('stable');

const getUpdateList = state => state.updates.items ? Object.values(state.updates.items) : [];

export const getUpdateListSorted = createSelector(
    [getUpdateList],
    updateList => stable(updateList, (a, b) => moment(b.created_at).diff(moment(a.created_at)))
);
