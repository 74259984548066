const MAX_PLAG_PERCENT = 10;

const FILES_SUB_CATEGORIES = [
  {
    name: 'Instructions',
    id: 'instructions',
  },
  {
    name: 'Sources',
    id: 'sources',
  },
  {
    name: 'Grading rubric',
    id: 'grading_rubric',
  },
  {
    name: 'Readings',
    id: 'readings',
  },
  {
    name: 'Additional materials',
    id: 'additional_materials',
  },
];

const FILES_DUPLICATE_REVISION_SUB_CATEGORIES = [
  {
    name: 'Final files to be revised',
    id: 'final_files_revised',
  },
  ...FILES_SUB_CATEGORIES,
];

const UPLOAD_CREDENTIALS = {
  AWSAccessKeyId: 'AKIAQ2ZTVN6TIAHGEFDK',
  policy: 'CnsgImV4cGlyYXRpb24iOiAiMjAzMy0xMi0wMVQxMjowMDowMC4wMDBaIiwgCiAiY29uZGl0aW9ucyI6IFsgCiB7ImJ1Y2tldCI6ICJnMHl1MGw0cHhqIiB9LCAKIFsic3RhcnRzLXdpdGgiLCAiJGtleSIsICJ3dDUwMmZ5MC8iXSwgCiBbInN0YXJ0cy13aXRoIiwgIkNvbnRlbnQtVHlwZSIsICIiXSwgCiB7ImFjbCI6ICJwdWJsaWMtcmVhZCIgfQogIF0KIH0K',
  signature: 'S6OPT2ssX3+Z6mKDzTg5GjCNddc=',
  key: 'wt502fy0',
  bucket: 'g0yu0l4pxj',
  host: 'https://g0yu0l4pxj.s3.amazonaws.com/',
};

export default {
  MAX_PLAG_PERCENT,
  FILES_SUB_CATEGORIES,
  FILES_DUPLICATE_REVISION_SUB_CATEGORIES,
  UPLOAD_CREDENTIALS,
};
