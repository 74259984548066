import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import utils from 'utils';

import { getOrdersFilteredSorted } from 'store/ordersFind/selectors';

import { ORDERS_TYPE_ALL } from 'store/ordersFind/actions';
import { setNewOrdersToTable } from 'store/ordersNotifications/actions';

const OrderTableNotifications = ({ notViewedNewOrders, addNewOrdersToTable, pinnedOrders }) => {
  let timeoutId = null;
  const setTimer = () => {
    timeoutId = setTimeout(() => {
      addNewOrdersToTable();
    }, 1700);
  };
  const handleMove = () => {
    const tableHeader = window.$headRef;
    if (!tableHeader) return;

    if (!tableHeader.classList.contains('sticky-table-head-fixed')) {
      if (timeoutId) return;
      setTimer();
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleMove);
    handleMove();

    return () => {
      window.removeEventListener('scroll', handleMove);
    };
  }, []);
  const onClick = () => {
    const isMobile = utils.detectMob();

    let top = 0;

    if (isMobile) {
      if (pinnedOrders.length > 1) {
        top = (pinnedOrders.length - 1) * 123;
      }
    }
    window.scrollTo({ top, left: 0, behavior: 'smooth' });
    setTimer();
  };
  return (
    <div className="order-table-notification" onClick={onClick}>{notViewedNewOrders.length} new orders available</div>
  );
};

const pinnedOrders = createSelector(
  getOrdersFilteredSorted[ORDERS_TYPE_ALL],
  orderList => orderList.filter(order => order.is_pinned),
);

const mapStateToProps = (state) => {
  return {
    pinnedOrders: pinnedOrders(state),
  };
};

const mapDispatchToProps = dispatch => ({
  addNewOrdersToTable: () => dispatch(setNewOrdersToTable()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTableNotifications);
