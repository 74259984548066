import React from 'react';

import classNames from './../utils/classNames';

const TableSortingHead = props => {
    const { sorting, setSorting, field, children } = props;
    const sortingIcon = field !== sorting.field
        ? <span className='full-arrow'></span>
        : <span className={classNames('full-arrow', { 'full-arrow-down': !sorting.isAsc }, { 'full-arrow-up': sorting.isAsc })}></span>
    return (
        <a onClick={() => setSorting(field)}>{children} {sortingIcon}</a>
    );
};

export default TableSortingHead;
