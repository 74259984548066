import React from 'react';

import Select from 'components/Select';
import Tooltip from 'components/Tooltip';
import Dropdown from 'components/Dropdown';

import { DISABLED_TYPE } from './constants/common';

const DropdownSubTypeIssue = ({ typeIssue, subTypeIssue, onChangeSubType, subTypesIssue, isCompleteDayLater, isDisabled = false }) => {

  return (
    <Dropdown className="fix_width" text={subTypeIssue || '— Select one —'} closeAlways disabled={isDisabled} >
      <Select className="menu menu-selectable" value={subTypeIssue} onChange={onChangeSubType}>
        {subTypesIssue.map((item) => {
          const isDisabledItem = DISABLED_TYPE[typeIssue] && (DISABLED_TYPE[typeIssue] || []).includes(item);

          if (isDisabledItem && isCompleteDayLater < 24) {
            const tooltipContent = `<b>Note:</b> financial transactions can take up to 24 hours to be processed. <br/><br/> Please report this issue if your payment/bonus is not added in <b>${24 - isCompleteDayLater}</b> hours`;
            return (
              <li key={item} value={item} disabled className="row vertical tooltip_issue_subtype">
                <span className="menu-item disabled">
                  {item}
                </span>
                <Tooltip content={tooltipContent} positionDown positionRight isHtml>
                  <i className="icon_info" />
                </Tooltip>
              </li>
            );
          }
          return <li key={item} value={item} className="menu-item">{item}</li>;
        })
        }
      </Select>
    </Dropdown>
  );
};

export default DropdownSubTypeIssue;
