import React from 'react';
import { Range as RcRange } from 'rc-slider';

const Range = props => (
  <div className="slider">
    <RcRange
      min={props.min}
      max={props.max}
      value={props.value}
      onChange={props.onChange}
      step={props.step}
      tooltip={props.tooltip}
      tipFormatter={props.tipFormatter}
    />
  </div>
);

export default Range;
