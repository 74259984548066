import React from 'react';
import { connect } from 'react-redux';
import {
  NavLink,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import LearningLabPage from 'pages/LearningLabPage';

import Nav from 'components/Nav';
import HelpTabFaq from 'components/HelpTabFaq';
import HelpTabGuides from 'components/HelpTabGuides';

import HelpFaqPage from './HelpFaqPage';
import HelpGuidesPage from './HelpGuidesPage';

const HelpPage = ({ faqCats, guidesCats }) => {
  const firstSubCategories = Object.values(faqCats[0].subCategories);
  const defaultFaqRedirect = firstSubCategories[0].id;

  return (
    <div>
      <div>
        <Nav sub>
          <NavLink to="faq">FAQ</NavLink>
          <NavLink to="guides">Guides</NavLink>
          <NavLink className="learninglab" to="learninglab">Learning Lab <i className="nav-emoji">🎓</i></NavLink>
        </Nav>
        <Routes>
          <Route path="faq/*" element={<HelpFaqPage />}>
            <Route path=":category" element={<HelpTabFaq />} />
            <Route path="*" element={<Navigate to={defaultFaqRedirect} replace />} />
          </Route>
          <Route path="guides/*" element={<HelpGuidesPage />}>
            <Route path=":category" element={<HelpTabGuides />} />
            <Route path="*" element={<Navigate to={guidesCats[0].id} replace />} />
          </Route>
          <Route path="learninglab" element={<LearningLabPage />} />
          <Route path="issues" element={<Navigate to="../../issues" replace />} />
          <Route path="*" element={<Navigate to="faq" replace />} />
        </Routes>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  faqCats: Object.values(state.help.faqCats || {}),
  guidesCats: Object.values(state.help.guidesCats || {}),
});

export default connect(mapStateToProps)(HelpPage);
