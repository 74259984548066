import React, { useCallback } from 'react';

import classNames from 'utils/classNames';

import './styles/index.scss';

const InputsSizeTime = ({
  hours,
  minutes,
  onHours,
  onMinutes,
  title,
  disabledHour = false,
  disabledMinutes = false,
  labelHour = 'hrs',
  labelMinutes = 'min',
}) => {

  const formatValue = useCallback(
    __value => __value.replace(/[^+\d]/g, '').replace(/^0/, '').slice(-2) || '0',
    [],
  );

  const onChangeHours = (e) => {
    const value = formatValue(e.target.value);
    onHours(value);
  };

  const onChangeMinutes = (e) => {
    const value = formatValue(e.target.value);
    onMinutes(value);
  };


  return (
    <div className="inputs-time-container">
      {title &&
        <div className="row mb10">
          <div className={classNames('inputs-time-container__title', { 'inputs-time-container__title--disabled': disabledHour || disabledMinutes })} dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      }
      <div className="row">
        <div className="col-6 inputs-time-container__input-wrap">
          <div className={classNames('inputs-time-container__input-container', { disabled: disabledHour })}>
            <input
              disabled={disabledHour}
              value={('0' + hours).slice(-2)}
              onChange={onChangeHours}
              placeholder="00"
            />
            <span>{labelHour}</span>
          </div>
          <span>:</span>
          <div className="col-6 inputs-time-container__input-wrap">
            <div className={classNames('inputs-time-container__input-container', { disabled: disabledMinutes })}>
              <input
                disabled={disabledMinutes}
                value={('0' + minutes).slice(-2)}
                onChange={onChangeMinutes}
                placeholder="00"
              />
              <span>{labelMinutes}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputsSizeTime;
