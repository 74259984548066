import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import utils from 'utils';

import classNames from 'utils/classNames';

import { addAlert } from 'store/alerts/actions';
import { STATUS_REVISION } from 'store/orders/actions';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import ReservedCounter from 'components/ReservedCounter';
import { getStatus } from 'components/order/utils';

import OrderRejectModal from './OrderRejectModal';


const TEN_MINUTES = 600;
const THREE_HOURS = 10800;

const OrderReject = ({ order, setRejectAccepted, onSelect, extraTooltipClass = '' }) => {
  const { jabber_node: jabberNode, _id: orderId } = order;

  const isNTorder = utils.isNTorder(order);
  const isOrderOnRevision = getStatus(order) === STATUS_REVISION;

  const [isOpen, toggleModal] = useState(false);
  const [isPenaltyReject, togglePenaltyStatus] = useState(isNTorder);
  const [writerAccPlusTenMinutes, setIntervalTime] = useState('');

  const orderRejectRef = useRef(null);

  useEffect(() => {
    if (isNTorder) return;

    if (!order.allow_request_reject_free && !jabberNode) {
      togglePenaltyStatus(true);
      return;
    }

    const diffNowWAccept = moment(moment.utc()).diff(order.writer_accept_at, 'seconds');

    if (diffNowWAccept > (jabberNode ? THREE_HOURS : TEN_MINUTES)) {
      togglePenaltyStatus(true);
      return;
    }

    togglePenaltyStatus(false);
    setIntervalTime(moment(order.writer_accept_at).add(jabberNode ? THREE_HOURS : TEN_MINUTES, 'seconds').utc());
  }, [orderId, isNTorder]);

  const dispatch = useDispatch();

  const onCloseModalHandler = useCallback(
    (params, closeAfterFetch) => {
      toggleModal(false);
      if (closeAfterFetch) {
        onSelect(order.number);
        const alertText = isPenaltyReject ? 'The order was rejected. Our Support team will review the case shortly.' : 'The order was rejected. No fine will be applied.';
        dispatch(addAlert(<div className="success-container success-reject-order-container">{alertText}</div>));
      }
    },
    [order],
  );

  const toggleModalHandler = useCallback(
    () => {
      if (jabberNode && !isPenaltyReject) {
        setRejectAccepted({ free: true });
        return;
      }

      toggleModal(!isOpen);
    },
    [isOpen, isPenaltyReject],
  );

  const togglePenaltyStatusHandler = useCallback(
    () => {
      togglePenaltyStatus(!isPenaltyReject);
    },
    [isPenaltyReject],
  );

  const containerClassName = classNames('reject-btn-container', { 'reject-btn-container--penalty': isPenaltyReject });
  const tooltipIconClassName = classNames('svg-icon', { 'question--red': isPenaltyReject });

  return (
    <div className={containerClassName} ref={orderRejectRef}>
      {
        !isOrderOnRevision && (
          <>
            <a onClick={toggleModalHandler}>Reject the order</a>
            <Tooltip className={classNames('multi-string size-order-tooltip order-tooltip', extraTooltipClass)} content="Please note that if you reject from working on this order, a fine and/or a warning might be applied.">
              <Icon className={tooltipIconClassName} iconName="question" />
            </Tooltip>
          </>
        )
      }
      {
          !isNTorder && !isPenaltyReject && writerAccPlusTenMinutes &&
          <div className="reject-btn-container__timer">
            <ReservedCounter
              time={writerAccPlusTenMinutes}
              onOver={togglePenaltyStatusHandler}
            />
            <Tooltip className={classNames('multi-string size-order-tooltip order-tooltip', extraTooltipClass)} content="Please, take your decision before time runs out or you may get fine">
              <Icon className="svg-icon" iconName="question" />
            </Tooltip>
          </div>
        }
      {isOpen &&
        <OrderRejectModal
          onClose={onCloseModalHandler}
          rejectCallback={setRejectAccepted}
          isPenaltyReject={isPenaltyReject}
          writerAccPlusTenMinutes={writerAccPlusTenMinutes}
          order={order}
        />
      }
    </div>
  );
};

export default OrderReject;
