import React, { useState } from 'react';

import { trackValues } from 'lrtracker';

import Icon from 'components/Icon';

import localStorageHandler from 'utils/localStorageHandler';

import TermsOfUseItems from './TermsOfUseItems';
import TermsOfUseButtons from './TermsOfUseButtons';

import './styles.scss';

const TermsOfUse = ({
  closeModal, isNewWriter, agreeTermsOfUse,
}) => {
  const [currentPage, setCurrentPage] = useState(() => {
    if (isNewWriter) return localStorageHandler.get('termsOfUsePage') || 1;

    return 1;
  });

  const goToNextPage = () => {
    setCurrentPage(__prevPage => __prevPage + 1);

    if (isNewWriter) {
      localStorageHandler.set('termsOfUsePage', currentPage + 1);
      trackValues('termsOfUse', 'next_step', { context: { page_number: currentPage + 1 } });
    }
  };

  const goToPrevPage = () => {
    setCurrentPage(__prevPage => __prevPage - 1);

    if (isNewWriter) {
      localStorageHandler.set('termsOfUsePage', currentPage - 1);
      trackValues('termsOfUse', 'prev_step', { context: { page_number: currentPage - 1 } });
    }
  };

  const agreeTermsOfUseHandler = () => {
    trackValues('termsOfUse', 'TRUE', { context: {} });
    agreeTermsOfUse();
  };

  return (
    <>
      {!isNewWriter && <button className="btn btn-close close-terms-of-use-icon" type="button" onClick={closeModal}><Icon className="svg-icon" iconName="close" /></button> }
      <TermsOfUseItems currentPage={currentPage} />
      <TermsOfUseButtons currentPage={currentPage} isNewWriter={isNewWriter} agreeTermsOfUse={agreeTermsOfUseHandler} closeTermsOfUse={closeModal} goToNextPage={goToNextPage} goToPrevPage={goToPrevPage} />
    </>
  );
};

export default TermsOfUse;
