import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'hocs';

import { sessionsFetch } from 'store/clientSessions/actions';

import { userFetchIfNeeded, userWarningsFetchIfNeeded, userFetch } from 'store/user/actions';
import { wsConnect, wsSubscribe } from 'store/help/actions';
import { noticesFetchIfNeeded } from 'store/notices/actions';
import { ticketsSettingsFetchIfNeeded } from 'store/ticketsSettings/actions';
import { settingsFetchIfNeeded } from 'store/settings/actions';
import { notificationFetchIfNeeded } from 'store/notification/actions';

import { ordersProgressFetchIfNeeded } from 'store/ordersProgress/actions';
import { ordersCompleteFetchIfNeeded } from 'store/ordersComplete/actions';
import { ordersRevisionFetchIfNeeded } from 'store/ordersRevision/actions';
import { ordersFindFetchIfNeeded } from 'store/ordersFind/actions';

import { setToken } from 'utils/tokenStorage';

import { orderTopic, writerNoticesTopic, writerProfileTopic, ordersEventsTopic } from 'constants/wsTopics';

class DataPreload extends Component {
  componentDidMount() {
    this.subscribe = false;
    setInterval(() => {
      this.props.pingServer();
    }, 600000);
    const { loadData, location, navigate } = this.props;
    const idx = location.search.indexOf('?t=');
    if (idx !== 0) {
      loadData();
      return;
    }
    const token = location.search.substr(idx + 3).replace('Bearer%20', 'Bearer ');
    setToken(token);
    loadData();
    navigate(location.pathname);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.loadNotifications();
    }
    const { user } = this.props;

    if ((user && user._id) && (!this.subscribe)) {
      this.props.wsSubscribe({ ...writerNoticesTopic, ...{ topic: `writer_notices/${user._id}` } });
      this.props.wsSubscribe({ ...writerProfileTopic, ...{ topic: `writers/${user._id}/profile/update/#` } });

      this.props.wsSubscribe(orderTopic);
      this.props.wsSubscribe({ ...ordersEventsTopic, topic: `writers/${user._id}/orders_events/#` });
      this.subscribe = true;

      window.hj && window.hj('identify', user._id, {
        has_glassdoor_tag: (user.tags || []).includes('glassdoor'),
      });
    }
  }

  render() {
    const { isLoading, id } = this.props;

    return (
      <div id={id}>
        {!isLoading && this.props.children}
        {!!isLoading &&
          <div className="preloading">
            <p>Loading</p>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const isLoading =
    store.notices.isFetching ||
    store.user.isFetching ||
    store.user.didInvalidate ||
    store.settings.didInvalidate ||
    store.settings.isFetching;
  return { isLoading, user: { ...(store.user || {}) } };
};

const mapDispatchToProps = (dispatch) => {
  window.global_dispatch = dispatch;
  return {
    loadData: () => {
      const promises = [
        dispatch(noticesFetchIfNeeded()),
        dispatch(userFetchIfNeeded()),
        dispatch(notificationFetchIfNeeded()),
        dispatch(settingsFetchIfNeeded()),
        dispatch(ticketsSettingsFetchIfNeeded()),
        dispatch(userWarningsFetchIfNeeded()),
        dispatch(sessionsFetch()),
      ];
      Promise.all(promises).then(() => {
        dispatch(ordersFindFetchIfNeeded())
          .then(() => {
            dispatch(wsConnect());
            dispatch(ordersProgressFetchIfNeeded());
            dispatch(ordersCompleteFetchIfNeeded());
            dispatch(ordersRevisionFetchIfNeeded());
          });
      });
    },
    loadNotifications: () => dispatch(notificationFetchIfNeeded()),
    wsSubscribe: payload => dispatch(wsSubscribe(payload)),
    pingServer: () => dispatch(userFetch(true)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataPreload));
