import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter, withRouterMatch } from 'hocs';

import OrderCompleteTabPage from './OrderCompleteTabPage';
import OrderRevisionTabPage from './OrderRevisionTabPage';
import StarRating from './../components/StarRating';
import formatTime from './../utils/formatTime';

import { userFeedbacksFetchIfNeeded, userSetFeedbackFetch, userFeedbacksLoadNext } from './../store/user/actions';

const myPath = '/progress/activity/scores';

class ProgressScoresPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRevision: false,
    }

    this.onSelect = this.onSelect.bind(this);
    this.markAsSeen = this.markAsSeen.bind(this);
  }

  componentDidMount() {
    const { loadFeedbacks } = this.props;
    loadFeedbacks();
  }

  onSelect(orderNumber, isRevision) {
    const { history, match } = this.props;
    const prevOrderNumber = match.params.orderNumber;
    this.setState({
      isRevision,
    });
    if (prevOrderNumber === orderNumber) {
      history.push(myPath);
      return;
    }
    history.push(`${myPath}/${orderNumber}`);
  }

  markAsSeen(id) {
    const { markAsSeen } = this.props;
    markAsSeen(id);
    return;
  }

  renderNextButton() {
    const { feedbacks, feedbacksList, loadNextFeebacks } = this.props;
    if (!feedbacksList.length) return null;
    const { total, page } = feedbacks;

    if (!total || feedbacksList.length === total) {
      return null;
    }

    const numPage = page + 1;
    const text = `Load next items (${feedbacksList.length} / ${total})`;

    return (
      <button
        className="btn btn-show-next"
        onClick={() => { loadNextFeebacks(numPage); }}
      >
        {text}
      </button>
    );
  }

  render() {
    const { match, feedbacksList } = this.props;
    const { isRevision } = this.state;
    const { orderNumber } = match.params;

    return (
      <div className="scores-content">
        <p className="description">Client Score is the main indicator for us that shows client's satisfaction by your work.  We want to be fully transparent, and that is the reason why Average Client Score is the main and only parameter that defines your group on the platform. </p>
        <h3>Benchmarks are the following:</h3>
        <ul className="desc-levels">
          <li className="level-a-plus">
            <span>A+ level</span>
            <i>from 4.7 to 5.0</i>
          </li>
          <li className="level-a">
            <span>A-level</span>
            <i>from 4.5 to 4.6</i>
          </li>
          <li className="level-b">
            <span>B-level</span>
            <i>from 4.0 to 4.4</i>
          </li>
          <li className="level-c">
            <span>C-level</span>
            <i>from 3.5 to 3.9</i>
          </li>
          <li className="level-d">
            <span>D-level</span>
            <i>from 3.0 to 3.4</i>
          </li>
          <li className="level-f">
            <span>F-level</span>
            <i>any score below 2.9</i>
          </li>
        </ul>

        <p className="description">Average Client Score is defined based on the last 30 scores you’ve received from your clients. For us to calculate your group, you need to have at least 5 client scores, and you need at least 10 client scores to join A+ group specifically. You will remain in the Beginner group until you collect your first 5 scores.</p>
        <p className="description">High Client Scores will let you earn more on our platform, <b>as A+ level and A-level freelancers will get <span className="text-success">+35</span>, <span className="text-success">+25%</span> to the regular order price</b> respectively. There is a progress bar on the left where you can find a description of all the benefits that your current group brings you, as well as descriptions for other groups. Below you can find your history of scores where any new ones will be marked, so that you can keep track of them.</p>
        <p className="description"><b>The rule is simple - keep up the good job, make your Clients happy with your work and earn more!</b></p>

        <h4>Scores from clients</h4>

        <div className="scores-table">
          <div className="table-header">
            <div className="row">
              <div className="col-2">Order</div>
              <div className="col-1">Score</div>
              <div className="col-4">Comment from client</div>
              <div className="col-2">Created</div>
              <div className="col-2">Action</div>
            </div>
          </div>

          <div className="sticky-table-body">

            {feedbacksList.map((f, idx) => {
              const oNumber = f.order_number;
              const isSeen = f.writer_seen;
              const isRevision = f.order_on_revision;

              return (
                <div key={`${f._id}_${idx}`} className="table-row row vertical">
                  <div className="col-2">
                    <span className="scores-lnk" onClick={() => this.onSelect(oNumber, isRevision)}>{oNumber}</span>
                  </div>
                  <div className="col-1"><StarRating rate={f.mark} width={60} /></div>
                  <div className="col-4 scores-comment" title={f.comment}>{f.comment}</div>
                  <div className="col-2">{formatTime(f.created_at, 'dt')}</div>
                  <div className="col-2">
                    {f.client_score_hide &&
                      <span className="scores-view cancelled">Cancelled</span>
                    }
                    {isSeen && !f.client_score_hide &&
                      <span className="scores-view active">Seen</span>
                    }
                    {!isSeen && !f.client_score_hide &&
                      <span className="scores-view" onClick={() => this.markAsSeen(f._id)}>Mark as seen</span>
                    }
                  </div>
                </div>
              )
            })}
          </div>
          {this.renderNextButton()}
        </div>
        {!!orderNumber &&
          <OrderCompleteTabPage orderNumber={orderNumber} onSelect={this.onSelect} />
        }
        {!!orderNumber && isRevision &&
          <OrderRevisionTabPage orderNumber={orderNumber} onSelect={this.onSelect} />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  const isLoading = !user.feedbacks || user.feedbacks.didInvalidate || user.feedbacks.isFetching || user.feedbacks.isFetchingFeedback;
  const feedbacksList = isLoading ? [] : user.feedbacks.results || [];
  const { feedbacks } = user;

  return {
    user,
    isLoading,
    feedbacks,
    feedbacksList,
  };
};

const mapDispatchToProps = dispatch => ({
  loadFeedbacks: () => {
    dispatch(userFeedbacksFetchIfNeeded());
  },
  loadNextFeebacks: (page) => {
    dispatch(userFeedbacksLoadNext(page));
  },
  markAsSeen: (id) => {
    dispatch(userSetFeedbackFetch(id));
  },
});

export default withRouterMatch(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgressScoresPage)));
