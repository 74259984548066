const TAGS_BAD = [
  'notify_mark_bad',
  'notify_issue_cancelled',
  'notify_issue_closed',
  'notify_notice_bad',
  'notify_writer_order_feedback_cancelled',
  'notify_veriff_declined',
  'notify_veriff_resubmission',
  'notify_group_down',
];

const TAGS_GOOD = [
  'notify_mark_good',
  'notify_mark_update',
  'notify_mark_hide',
  'notify_writer_order_feedback_resolved',
  'notify_veriff_approved',
  'notify_group_up',
];

const TAGS_RESOLVED = [
  'notify_issue_resolved',
  'notify_revision_accept',
  'notify_rdd_changed',
];

const PATH_PROGRESS_ACTIVITY = '/progress/activity';

const PATH_SCORES = `${PATH_PROGRESS_ACTIVITY}/scores`;
const PATH_WARNINGS = `${PATH_PROGRESS_ACTIVITY}/warnings`;

const PATH_TICKETS = '/issues';

const PATH_TICKETS_ORDER_FEEDBACK = `${PATH_TICKETS}/order-feedback`;
const PATH_TICKETS_REPORTED_ISSUES = `${PATH_TICKETS}/reported-issues`;

const PATH_ORDER = '/order';

const NOTICES_NOTIFICATION_LIMIT = 20;

export {
  TAGS_BAD,
  TAGS_GOOD,
  TAGS_RESOLVED,

  PATH_SCORES,
  PATH_WARNINGS,

  PATH_TICKETS,
  PATH_TICKETS_ORDER_FEEDBACK,
  PATH_TICKETS_REPORTED_ISSUES,

  PATH_ORDER,

  NOTICES_NOTIFICATION_LIMIT,
};
