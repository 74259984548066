import { CLIENT_CHAT_NOTICES_RECEIVE } from './actions';

const clientChatNotifications = (state = {
  notifications: [],
  hasNotification: false,
}, action) => {
  switch (action.type) {
    case CLIENT_CHAT_NOTICES_RECEIVE:
      return { ...state, notifications: action.data.nodes, hasNotification: action.data.hasNotification };
    default:
      return state;
  }
};

export default clientChatNotifications;
