import React from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const onHistoryPush = (path, options = {}) => {
      navigate(path, options);
    };

    const __props = {
      ...props,
      ...{
        location,
        navigate,
        history: {
          push: onHistoryPush,
          replace: path => onHistoryPush(path, { replace: true }),
          goBack: () => navigate(-1),
        },
      },
    };
    return (
      <Component
        {...__props}
      />
    );
  };

  return ComponentWithRouterProp;
};

export default withRouter;
