import shortid from 'shortid';

import {
  ORDERS_DETAILS_INVALIDATE,

  ORDERS_MESSAGES_REQUEST,
  ORDERS_MESSAGES_RECEIVE,
  ORDERS_MESSAGES_SET_READ_REQUEST,
  ORDERS_MESSAGES_SET_READ_RECEIVE,
  ORDERS_MESSAGES_ADD_REQUEST,
  ORDERS_MESSAGES_ADD_RECEIVE,

  ORDERS_MESSAGES_ADD_ATTACH_RECEIVE,

  ORDERS_MESSAGES_ADD_CONFIRM_REQUEST,
  ORDERS_MESSAGES_ADD_CONFIRM_RECEIVE,
  ORDERS_MESSAGES_DELETE_CONFIRM_REQUEST,
  ORDERS_MESSAGES_DELETE_CONFIRM_RECEIVE,
  ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE,
} from '../constants';

import message from './message';

const messages = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_DETAILS_INVALIDATE:
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    case ORDERS_MESSAGES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case ORDERS_MESSAGES_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.messages,
        webhook: action.webhook,
      });
    case ORDERS_MESSAGES_SET_READ_REQUEST:
    case ORDERS_MESSAGES_SET_READ_RECEIVE:
    case ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.messageId]: message(state.items[action.messageId], action),
        }),
      });
    case ORDERS_MESSAGES_ADD_REQUEST:
      return Object.assign({}, state, {
        isFetchingAdd: true,
      });
    case ORDERS_MESSAGES_ADD_RECEIVE:
      return Object.assign({}, state, {
        isFetchingAdd: false,
        items: Object.assign({}, state.items, {
          [action.message._id]: action.message,
        }),
      });
    case ORDERS_MESSAGES_DELETE_CONFIRM_REQUEST:
      return Object.assign({}, state, {
        isFetchingDelete: true,
      });
    case ORDERS_MESSAGES_DELETE_CONFIRM_RECEIVE: {
      const newItemsMessage = state.items;
      delete newItemsMessage[action.messages._id];
      return Object.assign({}, state, {
        isFetchingDelete: false,
        items: newItemsMessage,
      });
    }
    case ORDERS_MESSAGES_ADD_CONFIRM_REQUEST:
      return Object.assign({}, state, {
        isFetchingAdd: true,
      });
    case ORDERS_MESSAGES_ADD_CONFIRM_RECEIVE: {
      const shortId = shortid.generate();
      return Object.assign({}, state, {
        isFetchingAdd: false,
        items: Object.assign({}, state.items, {
          [shortId]: {
            message: action.message,
            confirm_type: true,
            _id: shortId,
            sender_role: 'system_confirm',
            files: action.files || [],
            recepient_role: action.to,
          },
        }),
      });
    }
    case ORDERS_MESSAGES_ADD_ATTACH_RECEIVE: {
      const newItems = Object.assign({}, state.items);
      delete newItems[action.fileId];
      return Object.assign({}, state, {
        isFetchingAdd: false,
        items: newItems,
      });
    }
    default:
      return state;
  }
};

export default messages;
