import { clearToken } from './tokenStorage';
import storage from './../utils/localStorageHandler';
import { urls } from '../config';

const logout = () => {
  clearToken();
  storage.remove('filter');
  storage.remove('sort');
  storage.remove('userViewedCount');
  storage.remove('userViewedCountChange');
  storage.remove('infoVerifyInfoModalShow');
  storage.remove('unreadUpdates');
  window.location.href = urls.logout;
};

export default logout;
