import React from 'react';

import classNames from 'utils/classNames';

import './styles/filter_tags.scss';

const TAGS = ['interface', 'policies', 'finance', 'opportunities', 'administrative'];

const FilterTags = ({ activeTags = [], onClick }) => {
  return (
    <div className="container_filter_tags">
      <div className="container_filter_tags__title">Tags</div>
      {TAGS.map((tag) => {
        const isActive = activeTags.includes(tag);
        return (
          <div key={tag}>
            <button className={classNames('container_filter_tags__item', { 'container_filter_tags__item--active': isActive })} onClick={() => onClick(tag)}>
              {tag}
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default FilterTags;
