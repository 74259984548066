import React, { useState } from 'react';

import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

import user from 'img/icons/user.svg';

import '../styles/personalSection.scss';

const PersonalInfo = ({ personalInfo, setPersonalInfo, countryList = [] }) => {
  const [fullName, setFullName] = useState(personalInfo.full_name);

  const onNameChange = (e) => {
    const { target } = e;
    setFullName(target.value);
  };

  const changeParentProps = (key, value) => {
    setPersonalInfo({
      ...personalInfo, [key]: value,
    });
  };

  return (
    <div className="personal-info-section">
      <div className="row space-between">
        <div className="sections-title">
          <img src={user} alt="user" className="sections-title__icon" />
          <div className="sections-title__text">Basic information</div>
        </div>
        <div className="personal-info-section__warning-text">All fields are required</div>
      </div>
      <div className="wire-transfer-form-form-group">
        <label htmlFor="WTFullname" className="wire-transfer-form-form-group__label">Full Name</label>
        <input id="WTFullname" className="wire-transfer-form-form-group__input input-text" value={fullName} onChange={onNameChange} onBlur={() => changeParentProps('full_name', fullName)} />
      </div>
      <div className="wire-transfer-form-form-group-column">
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
          <label htmlFor="WTDefaultCurrency" className="wire-transfer-form-form-group__label">Default Currency</label>
          <Dropdown closeAlways id="WTDefaultCurrency" disabled text="USD - U.S. dollar" extraDropdownClass="wire-transfer-form-form-group__dropdown" />
        </div>
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item">
          <label htmlFor="WTCountryResident" className="wire-transfer-form-form-group__label">Country of residency</label>
          <Dropdown closeAlways id="WTCountryResident" text={personalInfo.country_of_residency} extraDropdownClass="wire-transfer-form-form-group__dropdown">
            <Select className="menu menu-selectable menu-selectable-overflow" value={personalInfo.country_of_residency} onChange={value => changeParentProps('country_of_residency', value)}>
              {countryList.map(country => <li className="menu-item wire-transfer-form-form-group__dropdown__item" key={country} value={country}>{country}</li>)}
            </Select>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
