import React, { useState } from 'react';

import address from 'img/icons/address.svg';

import '../styles/personalSection.scss';

const AddressInfo = ({ addressInfo, setAddressInfo }) => {
  const [beneficiaryAddress, setBeneficiaryAddress] = useState(addressInfo.beneficiary_address);
  const [city, setCity] = useState(addressInfo.city);
  const [province, setProvince] = useState(addressInfo.province);
  const [postalCode, setPostalCode] = useState(addressInfo.postal_code);

  const onBeneficiaryAddressChange = (e) => {
    const { target } = e;
    setBeneficiaryAddress(target.value);
  };

  const onCityChange = (e) => {
    const { target } = e;
    setCity(target.value);
  };

  const onProvinceChange = (e) => {
    const { target } = e;
    setProvince(target.value);
  };

  const onPostalCodeChange = (e) => {
    const { target } = e;
    setPostalCode(target.value);
  };

  const changeParentProps = (key, value) => {
    setAddressInfo({
      ...addressInfo, [key]: value,
    });
  };

  return (
    <div className="personal-info-section">
      <div className="sections-title">
        <img src={address} alt="address" className="sections-title__icon" />
        <div className="sections-title__text">Address and Additional Information</div>
      </div>
      <div className="wire-transfer-form-form-group">
        <label htmlFor="BeneficiaryAddress" className="wire-transfer-form-form-group__label">Beneficiary Street Address</label>
        <input id="BeneficiaryAddress" maxLength="140" className="wire-transfer-form-form-group__input input-text wire-transfer-form-form-group__input--with-count" value={beneficiaryAddress} onChange={onBeneficiaryAddressChange} onBlur={() => changeParentProps('beneficiary_address', beneficiaryAddress)} />
        <span className="wire-transfer-form-form-group__input-count">{ 140 - beneficiaryAddress.length }</span>
      </div>
      <div className="wire-transfer-form-form-group-column">
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item-3">
          <label htmlFor="city" className="wire-transfer-form-form-group__label">City</label>
          <input id="city" maxLength="30" className="wire-transfer-form-form-group__input input-text wire-transfer-form-form-group__input--with-count" value={city} onChange={onCityChange} onBlur={() => changeParentProps('city', city)} />
          <span className="wire-transfer-form-form-group__input-count">{ 30 - city.length }</span>
        </div>
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item-3">
          <label htmlFor="province" className="wire-transfer-form-form-group__label">Province or State</label>
          <input id="province" className="wire-transfer-form-form-group__input input-text" value={province} onChange={onProvinceChange} onBlur={() => changeParentProps('province', province)} />
        </div>
        <div className="wire-transfer-form-form-group wire-transfer-form-form-group--column-item-3">
          <label htmlFor="postalCode" className="wire-transfer-form-form-group__label">Postal or Zip Code</label>
          <input id="postalCode" className="wire-transfer-form-form-group__input input-text" value={postalCode} onChange={onPostalCodeChange} onBlur={() => changeParentProps('postal_code', postalCode)} />
        </div>
      </div>
    </div>
  );
};

export default AddressInfo;
