
import React, { useState } from 'react';

const WriterTicketingMessage = ({ text }) => {
  if (!text) return null;

  const [showAll, setShow] = useState(false);

  const onShowMore = () => {
    setShow(!showAll);
  };

  const shortText = text.substring(0, 220);
  const textLink = showAll ? 'Show less' : 'Show more';
  const textAnswer = showAll ? text : shortText;

  return (
    <>
      {shortText === text
        ? <span className="writer_ticketing_text">{text}</span>
        : <><span className="writer_ticketing_text">{textAnswer}</span><a className="toggle-show" onClick={onShowMore}>{textLink}</a></>
      }
    </>
  );
};

export default WriterTicketingMessage;
