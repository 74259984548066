import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Icon from 'components/Icon';

import { closePlugin } from 'store/plugins/actions';

import plugins from 'plugins';

import './index.scss';

const PluginWrapper = () => {
  const pluginsList = useSelector(state => state.plugins.pluginsList);

  const [pluginHeader, setPluginHeader] = useState('');

  const dispatch = useDispatch();

  const updateHeader = useCallback(
    (name) => {
      setPluginHeader(name);
    },
    [setPluginHeader],
  );

  const closePluginHandler = useCallback(
    (id) => {
      dispatch(closePlugin(id));
    },
    [dispatch],
  );

  if (!pluginsList.length) return null;

  return (
    <div className="plugin-wrapper">
      {
        pluginsList.map(({ plugin, id, data }) => (<div key={id}>
          <div className="plugin-wrapper__header row space-between">
            <div className="plugin-wrapper__header--name">{pluginHeader}</div>
            <div className="plugin-wrapper__header--controls row">
              <div className="close" onClick={() => closePluginHandler(id)}>
                <Icon className="svg-icon" iconName="close" />
              </div>
            </div>
          </div>
          {React.cloneElement(plugins[plugin], { id, data, updateHeader })}
        </div>))
      }
    </div>
  );
};

export default PluginWrapper;
