import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'utils/classNames';

import { ordersRevisionSetFilterSearch, setRevisionMessageFilter, resetRevisionMessageFilter } from 'store/ordersRevision/actions';

import OrderTableSearch, { OrderTableSearchBtn } from './OrderTableSearch';
import Switch from './Switch';

class OrdersRevisionTableFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
    };

    this.onToggleSearchClick = this.onToggleSearchClick.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    const { setFilterSearch } = this.props;
    setFilterSearch('');
  }

  componentWillUnmount() {
    const {
      filter = {}, resetFilters,
    } = this.props;
    const { new_messages: newMessages } = filter;

    if (newMessages) {
      resetFilters();
    }
  }

  onSearchSubmit(event) {
    event.preventDefault();
    const { searchValue } = this.state;
    const { setFilterSearch } = this.props;
    setFilterSearch(searchValue.trim());
  }

  onToggleSearchClick() {
    const { setFilterSearch, isSearch, onSearchChange } = this.props;
    this.setState((state) => {
      const newIsSearch = !isSearch;
      if (!newIsSearch) {
        setFilterSearch('');
      }
      onSearchChange(newIsSearch);
    });
  }

  onInputChange(event) {
    const { target } = event;
    this.setState(state => Object.assign({}, state, {
      [target.name]: target.value,
    }));
  }

  render() {
    const {
      className,
      isSearch,
      filter,
      setMessageFilter,
    } = this.props;
    const { searchValue } = this.state;

    if (isSearch) {
      return (
        <div className={className}>
          <OrderTableSearch
            onSubmit={this.onSearchSubmit}
            value={searchValue}
            onChange={this.onInputChange}
            onToggle={this.onToggleSearchClick}
          />
        </div>
      );
    }

    return (
      <div className={classNames(className, 'row space-between')}>
        <div className="row">
          <div className="col-1">
            <OrderTableSearchBtn onClick={this.onToggleSearchClick} />
          </div>
          <div className="col-3" />
          <div className="col-12" />
          <div className="col-12" />
        </div>
        <div className="row">
          <Switch
            className={classNames('switch-filter', { 'switch-filter--active': filter.new_messages })}
            name="online"
            isOn={filter.new_messages}
            handleToggle={() => setMessageFilter(!filter.new_messages)}
          >
            <div className="switch-filter__name">Show orders with unread messages</div>
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filter } = state.ordersRevision;
  return { filter };
};

const mapDispatchToProps = dispatch => ({
  setFilterSearch: value => dispatch(ordersRevisionSetFilterSearch(value)),
  setMessageFilter: value => dispatch(setRevisionMessageFilter(value)),
  resetFilters: () => dispatch(resetRevisionMessageFilter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersRevisionTableFilter);
