import React from 'react';

import colors from './colors';
import './index.scss';

const Colors = () => (
  <div className="colors-container">
    {Object.entries(colors).map(([key, value]) => <div className="row" key={key}><div className="colors-container__name">{key} :</div> <div className="colors-container__color" style={{ backgroundColor: value }} /><div className="ml12">{value}</div></div>)}
  </div>
);

export default Colors;
