import React, { Component } from 'react';
import moment from 'moment-timezone';

import { urls as configUrls } from 'config';

import utils from 'utils';

import storage from 'utils/localStorageHandler';
import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';
import { getToken } from 'utils/tokenStorage';

import { isRevisionOrderExpired } from 'components/order/utils';

import WriterTicketingIssue from 'components/WriterTicketing/WriterTicketingIssue';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import DottedDropdown from 'components/DottedDropdown';

const STORAGE_KEY = 'MESSAGE_BOX';

const UserInfo = (props) => {
  const { message, order } = props;
  const sender = message.sender_role;
  switch (sender) {
    case 'writer':
      return (
        <div className="chat-user-info">
          <h4 className="chat-user-name"><span>Me</span></h4>
        </div>
      );
    // case 'grader':
    // case 'pregrader':
    //   return (
    //     <div className="chat-user-info">
    //       <i className="chat-user-type type-grader" />
    //       <h4 className="chat-user-name">Grader</h4>
    //     </div>
    //   );
    case 'grader':
    case 'pregrader':
    case 'operator':
      return (
        <div className="chat-user-info">
          <i className="chat-user-type type-operator" />
          <h4 className="chat-user-name">Operator</h4>
        </div>
      );
    case 'client':
      return (
        <div className="chat-user-info">
          <i className="chat-user-type type-client" />
          <h4 className="chat-user-name">Client</h4>
        </div>
      );
    case 'system_confirm':
      return (
        <div className="chat-user-info">
          <h4 className="chat-system-name">System message (Visible only to you)</h4>
        </div>
      );
    default:
      return (
        <div className="chat-user-info">
          <i className="chat-user-type type-system" />
          <h4 className="chat-user-name">System</h4>
        </div>
      );
  }
};

const ConfirmButton = (props) => {
  const { message, setRead, order } = props;
  const isMe = message.sender_role === 'writer';
  const disabled = message.isFetchingRead;
  if (isMe) {
    return null;
  }

  if (message.confirm_status === 'need_confirm') {
    const { has_paid_revision_parallels: hasPaidRevisionParallels = false, revision_accept_at: revisionAcceptAt } = order;
    const isConfirmRevisionDisabled = isRevisionOrderExpired(order) || hasPaidRevisionParallels;
    const isDisabledByRevision = isConfirmRevisionDisabled && message.tag === 'half_revision';

    if (message.tag === 'half_revision' && revisionAcceptAt && moment(message.created_at).isBefore(moment(revisionAcceptAt))) return null;

    const renderConfirmBtn = () => {
      if (message.status === 'read') {
        return (
          <div className="col-1 text-success">
            <Icon className="svg-icon" iconName="check" />
            Read
          </div>
        );
      }

      if (isDisabledByRevision) {
        return (
          <div className="tooltip tooltip-top">
            <button className="btn btn-empty btn-confirm tooltip-trigger" disabled={disabled} onClick={() => setRead(message._id, 'read')}>Mark as read</button>
            <div className="tooltip-content">Unfortunately, you've run out of time to confirm working on revision. Your case will be reviewed by the Order Support Team.</div>
          </div>
        );
      }

      return <button className="btn btn-empty btn-confirm" disabled={disabled} onClick={() => setRead(message._id)}>Confirm</button>;
    };

    return (
      <div className="row column_gap_10">
        {renderConfirmBtn()}
      </div>
    );
  }
  if (message.status === 'unread') {
    return (
      <button className="btn btn-empty btn-confirm" disabled={disabled} onClick={() => setRead(message._id)}>Mark as read</button>
    );
  }
  if (message.confirm_status === 'confirmed') {
    return (
      <div className="col-1 text-success">
        <Icon className="svg-icon" iconName="check" />
        Confirmed
      </div>
    );
  }
  if (message.status === 'read') {
    return (
      <div className="col-1 text-success">
        <Icon className="svg-icon" iconName="check" />
        Read
      </div>
    );
  }
  return null;
};

const FileBox = (props) => {
  const { file } = props;
  if (!file || file.deleted) {
    return null;
  }
  const fileUrl = file.location;
  const parts = fileUrl.split('/').pop().split('.');
  const ext = parts.length > 1 ? parts.pop() : '';
  const className = ext === 'pdf' || ext === 'docx' || ext === 'pptx'
    ? `${ext}-icon file-icon upload-file`
    : 'file-icon paper-icon upload-file';

  return (
    <div className={className}><a href={file.location} target="_blank" rel="noopener noreferrer">{file.name}</a></div>
  );
};

class MessageRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      donwloadFetching: false,
      isOpenForm: false,
      showMessageAction: false,
      isConvertMessage: false,
    };

    this.onDownloadAll = this.onDownloadAll.bind(this);
    this.onOpenForm = this.onOpenForm.bind(this);
    this.onOpenMessageAction = this.onOpenMessageAction.bind(this);
    this.onConvertMessage = this.onConvertMessage.bind(this);
    this.onSubmitTicket = this.onSubmitTicket.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  componentDidUpdate() {
    const el = document.getElementsByClassName('chat-item--system');
    if (el.length) {
      el[0].scrollIntoView(false);
    }
  }

  onDownloadAll() {
    const { message, webhook, orderNumber } = this.props;
    const urls = [];
    const filesIds = [];
    message.files.forEach((file) => {
      if (!file.deleted) {
        urls.push(file.real_location);
        filesIds.push(file._id);
      }
    });
    const filesUrl = `${configUrls.fileServerUrl}/api/v1/files/download_all`;
    const token = getToken();
    const archiveTitle = `${orderNumber}_thread_${moment().format('DMYY_HHmm')}`;
    this.setState({ donwloadFetching: true });
    fetch(filesUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'POST',
      body: JSON.stringify({
        urls,
        webhook_url: webhook,
        file_name: archiveTitle,
        info: { ids: filesIds },
      }),
    })
      .then((res) => { if (res.status === 201) return res.json(); })
      .then((res) => {
        this.setState({ donwloadFetching: false });
        if (res && res.link) {
          window.open(`${configUrls.fileServerUrl}${res.link}`, '_blank');
        }
      })
      .catch((error) => {
        this.setState({ donwloadFetching: false });
        console.log(`There has been a problem with your fetch operation: ' ${error.message}`);
      });
  }

  onSubmitTicket() {
    const { message, orderNumber, deleteMessageConfirm } = this.props;
    const { isConvertMessage } = this.state;
    storage.set(STORAGE_KEY, '');

    if (!isConvertMessage) {
      deleteMessageConfirm(orderNumber, message);
      return;
    }

    this.setState({
      isConvertMessage: false,
    });
  }

  onOpenForm() {
    this.setState({
      isOpenForm: !this.state.isOpenForm,
    });
  }

  onOpenMessageAction() {
    this.setState({
      showMessageAction: !this.state.showMessageAction,
    });
  }

  onConvertMessage() {
    this.setState({
      isConvertMessage: true,
    });
    this.onOpenForm();
  }

  sendMessage() {
    const {
      message, addMessage, addMessageAttach, orderNumber, deleteMessageConfirm,
    } = this.props;
    const fileList = Object.values(message.files);
    const allUploaded = fileList.every(item => item.completed);
    const toUser = message.recepient_role || '';
    const sendMessage = message.message || '';

    if (!allUploaded) {
      return;
    }
    deleteMessageConfirm(orderNumber, message);
    !fileList.length ? addMessage(toUser, sendMessage) : addMessageAttach(toUser, fileList, sendMessage);
  }

  render() {
    const {
      message, setRead, orderId, orderNumber, orderTitle, order,
    } = this.props;
    const { donwloadFetching, isOpenForm, isConvertMessage } = this.state;
    const isMe = message.sender_role === 'writer';
    const className = classNames('chat-item', { 'chat-item-in': !isMe, disabled: message.ticket_id });
    const isUnRead = (message.status === 'unread');
    const showAcceptAndRequest = !(order.tags || []).includes('msg_plagiarism_found_unconfirmed') && message.tag === 'plagiarism_found' && isUnRead;

    if (message.confirm_type && !isOpenForm) {
      return (
        <div className="chat-item chat-item--system">
          <UserInfo message={message} />
          {!!message.message &&
            <div className="chat-message chat-message--system">
              <p className="chat-system-message">{message.message}</p>
              {!!message.files && !!message.files.length && message.files.map(file =>
                <FileBox file={file} key={file._id} />)
              }
              <div className="btn-group">
                <button className="btn btn-sent-issue" onClick={this.onOpenForm}>
                  I have an issue
                </button>
                <button className="btn btn-sent-reg-message" onClick={this.sendMessage}>
                  Send as a regular message
                </button>
              </div>
            </div>
          }
        </div>
      );
    }

    return (
      <div>
        {isOpenForm &&
          <div className="container-ticketing">
            <Modal onClose={this.onOpenForm} wide notHandleOutside>
              <WriterTicketingIssue
                onClose={this.onOpenForm}
                ticketFromMessage={message}
                orderId={orderId}
                orderNumber={orderNumber}
                orderTitle={orderTitle}
                orderDetails={order}
                onSuccess={this.onSubmitTicket}
                isConvertMessage={isConvertMessage}
                callFromOrderContent
              />
            </Modal>
          </div>
        }
        {!message.confirm_type &&
          <div className={className}>
            <UserInfo message={message} />
            <div className="chat-message-wrap">
              {!message.ticket_id && isMe && message.message.split(/\s+/).length >= 4 &&
                <DottedDropdown>
                  <div className="menu-message-action" onClick={this.onConvertMessage}>
                    Report this message as an issue
                  </div>
                </DottedDropdown>
              }
              <div className="chat-message">
                {!!message.message &&
                  <p className="chat-message-text">{message.message}</p>
                }
                {!!message.files && !!message.files.length && message.files.map(file =>
                  <FileBox file={file} key={file._id} />)
                }
                {(message.files || []).length > 1 && !isMe &&
                  <div>
                    <button
                      className="btn btn-light btn-md btn-download-all"
                      onClick={this.onDownloadAll}
                      disabled={donwloadFetching}
                    >
                      {donwloadFetching ? 'Loading...' : 'Download all'}
                    </button>
                    <div className="clear" />
                  </div>
                }
              </div>
              <div className="row space-between vertical">
                {!isMe && !showAcceptAndRequest &&
                  <div className="col-1">
                    <ConfirmButton message={message} setRead={setRead} order={order} />
                  </div>
                }
                {message.sender_role === 'system' && showAcceptAndRequest &&
                  <div className="col-2 btn-confirm--wrap">
                    <button className="btn btn-empty btn-confirm" onClick={() => setRead(message._id, 'request_review')}>Request review</button>
                    <button className="btn btn-empty btn-confirm" onClick={() => setRead(message._id, 'accept_revision')}>Accept revision</button>
                  </div>
                }
                <div className="col-1">
                  {message.ticket_id && <span className="notice">The message has been converted to an <a className="issue-link" href="/issues" target="_blank">issue</a></span>}
                  <span className="chat-time">{formatTime(message.created_at, 'dt')} {isMe &&
                    <span>({message.recepient_role})</span>
                  }</span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const OrderTabMessages = (props) => {
  const {
    messageList, setRead, messageWebhook, orderNumber, orderId, orderTitle, deleteMessageConfirm, addMessage, addMessageAttach, order,
  } = props;

  return (
    <div className="chat-box">
      <div className="chat-item-list">
        {messageList.filter(message => message.status !== 'hidden').map(message =>
        (<MessageRow
          key={message._id}
          message={message}
          setRead={setRead}
          webhook={messageWebhook}
          orderNumber={orderNumber}
          orderId={orderId}
          orderTitle={orderTitle}
          deleteMessageConfirm={deleteMessageConfirm}
          addMessage={addMessage}
          addMessageAttach={addMessageAttach}
          order={order}
        />))}
      </div>
    </div>
  );
};

export default OrderTabMessages;
