import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UnmountClosed } from 'react-collapse';

import { financeSetViewedFetch } from './../store/finances/actions';

import formatTime from './../utils/formatTime';

import Icon from './../components/Icon';
import classNames from './../utils/classNames';
import amountToString from './../utils/amountToString';
import displayFinanceTarget from './../utils/displayFinanceTarget';
import PayloadDropDown from './PayloadDropDown';


class FinancesTableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState(state => Object.assign({}, state, {
      isOpen: !state.isOpen,
    }));
  }

  render() {
    const { finance, className, setViewed } = this.props;
    const { isOpen } = this.state;
    const payload = finance.payload;
    const unread = finance.target === 'bonus' && finance.mark === 'unread';
    const rowClassName = classNames(className, { 'not-read': unread }, { 'is-success': finance.amount > 0 });
    const collapseLinkClassName = classNames('lnk-collapse', { active: isOpen });
    return (
      <div className={rowClassName}>
        <div className="row vertical">
          <div className="col-1"><Icon className="svg-icon" iconName="combined-shape" /></div>
          <div className="col-6">{finance.status_display}</div>
          <div className="col-4">{amountToString(finance.amount)}</div>
          <div className="col-4">{displayFinanceTarget(finance)}</div>
          <div className="col-12 finance-row-title">{!payload ? finance.title : <a className={collapseLinkClassName} onClick={this.onClick}>{finance.title}</a>}</div>
          <div className="col-4">{formatTime(finance.created_at, 'd')}</div>
          <div className="col-4">{unread &&
          <button type="button" className="btn btn-light btn-small custom-hover" disabled={finance.isFetchingViewed} onClick={setViewed}>Mark as read</button>
                    }</div>
        </div>
        {!!payload &&
        <UnmountClosed isOpened={isOpen}>
          <div className="row">
            <div className="col-1" />
            <div className="col-6" />
            <div className="col-4" />
            <div className="col-4" />
            <PayloadDropDown payload={payload} className="col-12" />
            <div className="col-4" />
            <div className="col-4" />
          </div>
        </UnmountClosed>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const finance = state.finances.items[ownProps.id];
  return { finance };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setViewed: () => dispatch(financeSetViewedFetch(ownProps.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancesTableRow);
