import { useState, useMemo } from 'react';
import { Collapse } from 'react-collapse';
import { Card, mapFileList, FileItem, DeletedMessage } from '@connect/connect-ui-js';

import isImgUrl from 'utils/isImgUrl';

import './index.scss';

const MAX_DETAILS_LENGTH = 98;
const MAX_FILES_LENGTH = 2;

const TaskInstructions = ({ msgData, order, getPic }) => {
  const { event: { event_params: { details = '', files = [] } } } = msgData;
  const detailsLength = details.length;

  const [showMoreBtn, toggleShowMoreBtn] = useState(false);
  const [showMoreFilesBtn, toggleShowMoreFilesBtn] = useState(false);

  const transformText = useMemo(() => {
    if (detailsLength < MAX_DETAILS_LENGTH) return details;

    if (showMoreBtn) return details;

    return `${details.slice(0, MAX_DETAILS_LENGTH - 3)}...`;
  }, [details, showMoreBtn]);

  const mappedFilesList = useMemo(() => mapFileList(files), [files]);
  const transformFilesList = useMemo(() => {
    if (mappedFilesList.length <= MAX_FILES_LENGTH) return mappedFilesList;

    if (showMoreFilesBtn) return mappedFilesList;

    return mappedFilesList.slice(0, MAX_FILES_LENGTH);
  }, [mappedFilesList, showMoreFilesBtn]);

  const onToggleShowMoreBtn = () => {
    toggleShowMoreBtn(!showMoreBtn);
  };

  const onToggleShowMoreFilesBtn = () => {
    toggleShowMoreFilesBtn(!showMoreFilesBtn);
  };

  const onOpenFile = (location) => {
    window.open(location, '_blank');
  };

  const renderTransformText = () => {
    if (detailsLength === 0) return null;

    return (
      <Collapse isOpened>
        <p>{transformText}</p>
      </Collapse>
    );
  };

  const renderTransformBtns = (onClickHandler, optionsNotView, prefix, variable) => {
    if (optionsNotView) return null;

    const btnText = variable ? `Show less ${prefix}` : `Show more ${prefix}`;

    return (
      <button
        onClick={onClickHandler}
        className="btn-link mt8 task-instructions-card__transform-btns"
      >
        {btnText}
      </button>
    );
  };

  const renderFile = (file) => {
    const { iconImg, name, location, id } = file;
    const isImg = isImgUrl(location);

    return (
      <div
        className="task-instructions-card-file row column row_gap_8"
        onClick={() => onOpenFile(location)}
        key={id || name}
      >
        <FileItem.FileIcon src={isImg ? location : iconImg} />
        <FileItem.Text maxWidth="103px" maxWidthText="38%" title={name} isSelfSent={false} text={name} />
      </div>
    );
  };

  const renderFiles = () => {
    if (transformFilesList.length === 0) return null;

    return (
      <div>
        <Collapse isOpened>
          <div className="row row_column_gap_8 task-instructions-card-files-container mt24">
            {transformFilesList.map(file => renderFile(file))}
          </div>
        </Collapse>
        {renderTransformBtns(onToggleShowMoreFilesBtn, transformFilesList.length < MAX_FILES_LENGTH, 'files', showMoreFilesBtn)}
      </div>
    );
  };

  if (!details && mappedFilesList.length === 0) {
    return (
      <DeletedMessage
        data={{ isSelfSent: false }}
      />
    );
  }


  return (
    <Card data={msgData} getPic={getPic}>
      <Card.Main>
        <div className="task-instructions-card">
          <h3 className="task-instructions-card__header mb24">Task instructions were added</h3>
          {renderTransformText()}
          {renderTransformBtns(onToggleShowMoreBtn, detailsLength < MAX_DETAILS_LENGTH, 'text', showMoreBtn)}
          {renderFiles()}
        </div>
      </Card.Main>
      <Card.Footer data={msgData} />
    </Card>
  );
};


export default TaskInstructions;
