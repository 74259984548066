import React, { Component } from 'react';


const StickyHead = () => null;
const StickyBody = props => <div className={props.className}>{props.children}</div>;

class StickyContainer extends Component {
  constructor(props) {
    super(props);

    this.handleMove = () => {
      if (!window.$headRef || !this.placeholderRef) {
        return;
      }
      const headRef = window.$headRef;
      this.placeholderRef.style.height = `${headRef.offsetHeight}px`;

      const {
        top, left, width, bottom,
      } = this.placeholderRef.getBoundingClientRect();
      const { isBottom } = this.props;
      const isFixed = !isBottom ? (top < 0) : (bottom > document.documentElement.clientHeight);

      const headStyle = headRef.style;
      if (isFixed) {
        headRef.classList.add(this.headClassNameFixed);
        headStyle.position = 'fixed';
        headStyle.left = `${left}px`;
        headStyle.right = '';
        headStyle.width = `${width}px`;
      } else {
        headRef.classList.remove(this.headClassNameFixed);
        headStyle.position = 'absolute';
        headStyle.left = '0';
        headStyle.right = '0';
        headStyle.width = '';
      }
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleMove);
    window.addEventListener('scroll', this.handleMove);
    this.handleMove();
  }

  componentDidUpdate(prevProps) {
    const { isLoading } = this.props;
    const { isLoading: prevIsLoadingProps } = prevProps;

    if (prevIsLoadingProps && !isLoading) {
      this.handleMove();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleMove);
    window.removeEventListener('scroll', this.handleMove);
  }

  render() {
    const { className, isBottom } = this.props;
    const style = { position: 'relative' };

    const childrenArray = React.Children.toArray(this.props.children);

    const headProps = childrenArray.find(child => child.type === StickyHead).props;
    const headChildren = headProps.children;
    const headClassName = headProps.className;
    this.headClassNameFixed = headProps.classNameFixed;
    const headStyle = {
      position: 'absolute',
      left: 0,
      right: 0,
    };
    if (!isBottom) {
      headStyle.top = 0;
    } else {
      headStyle.bottom = 0;
    }

    const body = childrenArray.find(child => child.type === StickyBody);

    return !isBottom ? (
      <div className={className} style={style}>
        <div ref={ref => this.placeholderRef = ref} />
        {body}
        <div className={headClassName} style={headStyle} ref={ref => window.$headRef = ref}>{headChildren}</div>
      </div>
    ) : (
      <div className={className} style={style}>
        {body}
        <div className={headClassName} style={headStyle} ref={ref => window.$headRef = ref}>{headChildren}</div>
        <div ref={ref => this.placeholderRef = ref} />
      </div>
    );
  }
}

export { StickyContainer, StickyHead, StickyBody };
