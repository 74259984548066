import moment from 'moment-timezone';
import {
    NOTIFICATION_REQUEST,
    NOTIFICATION_RECEIVE,
    NOTIFICATION_SET_MESSAGE_READ,
    NOTIFICATION_SET_FINANCE_READ,
    NOTIFICATION_SET_UPDATE_READ
} from './actions';

const notification = (state = {
    isFetching: false,
    didInvalidate: true,
    unreadMessageItems: {},
    unreadFinanceItems: {},
    unreadUpdateItems: {}
}, action) => {
    switch (action.type) {
        case NOTIFICATION_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case NOTIFICATION_RECEIVE:
            return Object.assign({}, state, action.data, {
                isFetching: false,
                updatedAt: moment(),
                didInvalidate: false
            });
        case NOTIFICATION_SET_MESSAGE_READ:
            const newMessageItems = Object.assign({}, state.unreadMessageItems);
            delete newMessageItems[action.messageId]
            return Object.assign({}, state, {
                unreadMessageItems: newMessageItems
            });
        case NOTIFICATION_SET_FINANCE_READ:
            const newFinanceItems = Object.assign({}, state.unreadFinanceItems);
            delete newFinanceItems[action.financeId]
            return Object.assign({}, state, {
                unreadFinanceItems: newFinanceItems
            });
        case NOTIFICATION_SET_UPDATE_READ:
            const newUpdateItems = Object.assign({}, state.unreadUpdateItems);
            delete newUpdateItems[action.messageId]
            return Object.assign({}, state, {
                unreadUpdateItems: newUpdateItems
            });
        default:
            return state;
    }
};

export default notification;