
import React from 'react';

const SubjectsModal = ({ onClick }) => (
  <div className="center-content">
    <h2 className="title fsXL row center_center mb20">
      Hi there <i className="modal-hi-icon ml10" />!
    </h2>
    <p className="fsL line-height-1-5 mb20"><strong>Update your preferred subjects</strong> to receive notifications & suggestions<br />
      about the orders of your interest. There are only two steps<br />
      that require <strong>less than 1 minute of your time!</strong></p>
    <button type="button" className="btn btn-bright btn-big" onClick={onClick}>Start</button>
  </div>
);

export default SubjectsModal;
