import React, { Component } from 'react';
import { UnmountClosed } from 'react-collapse';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import Icon from 'components/Icon';
import Checkbox from 'components/Checkbox';
import { StickyContainer, StickyHead, StickyBody } from 'components/Sticky';
import FilesPreview from 'components/FilesPreview';

import fileConstants from 'constants/files';

import utils from 'utils';

import formatTime from 'utils/formatTime';
import classNames from 'utils/classNames';
import { onDownloadAll as onDownloadAllHandler } from 'utils/downloadFile';

import { setPlugin } from 'store/plugins/actions';
import { addModalComponent } from 'store/modals/actions';

import SubCategory from './SubCategory';
import DeselectControls from './DeselectControls';

const filesMaxLength = 5;

class OrderFileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isSubOpen: false,
      downloadFetching: false,
      selectedFiles: [],
      openedCategories: [],
    };

    this.toggle = this.toggle.bind(this);
    this.toggleSub = this.toggleSub.bind(this);
    this.onDownloadAll = this.onDownloadAll.bind(this);
    this.renderCollapsedWrapper = this.renderCollapsedWrapper.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderFileRow = this.renderFileRow.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.clearSelect = this.clearSelect.bind(this);
    this.setOpenedCategories = this.setOpenedCategories.bind(this);
    this.openFilePreviewHandler = this.openFilePreviewHandler.bind(this);
    this.fileRowClick = this.fileRowClick.bind(this);
  }

  componentDidMount() {
    const { order = {} } = this.props;
    const { no_sensitive: noSensitive = false } = order;
    if (noSensitive) {
      this.setState({
        isOpen: false,
      });
    }
  }

  onDownloadAll(e) {
    if (e) e.stopPropagation();

    const { fileList, fileWebhook, orderNumber } = this.props;
    const { selectedFiles } = this.state;
    this.setState({ downloadFetching: true });
    onDownloadAllHandler({
      fileList,
      fileWebhook,
      orderNumber,
      selectedFiles,
    })
      .finally(() => {
        this.setState({ downloadFetching: false });
        this.clearSelect();
      });
  }

  setOpenedCategories(id) {
    const { openedCategories } = this.state;
    const isOpen = openedCategories.includes(id);

    this.setState({ openedCategories: isOpen ? openedCategories.filter(category => category !== id) : [...openedCategories, id] });
  }

  fileRowClick(e, file) {
    e.preventDefault();
    e.stopPropagation();
    this.openFilePreviewHandler(e, file._id);
  }

  selectFile(e, id) {
    e.stopPropagation();
    const { selectedFiles } = this.state;

    if (selectedFiles.includes(id)) {
      this.setState({ selectedFiles: selectedFiles.filter(file => file !== id) });
      return;
    }

    this.setState({ selectedFiles: [...selectedFiles, id] });
  }

  clearSelect(e) {
    if (e) e.stopPropagation();

    this.setState({ selectedFiles: [] });
  }

  toggle() {
    this.setState(state => Object.assign({}, state, {
      isOpen: !state.isOpen,
    }));
  }

  openFilePreviewHandler(e, fileID) {
    const {
      openFilePreview, fileList, orderNumber, fileWebhook, addModal,
    } = this.props;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const sortedList = fileList
      .filter(file => !file.deleted)
      .map(file => ({ ...file, id: file._id }));
    const activeIndex = sortedList.findIndex(file => file.id === fileID);
    const isMobile = utils.detectMob();

    if (isMobile) {
      addModal(<FilesPreview docs={sortedList} activeIndex={activeIndex} />, {
        overFlowHidden: true,
      });
      return;
    }

    openFilePreview({
      plugin: 'FilePreviewPlugin',
      data: {
        docs: sortedList,
        orderNumber,
        activeIndex,
        fileWebhook,
      },
    });
  }


  toggleSub() {
    this.setState(state => Object.assign({}, state, {
      isSubOpen: !state.isSubOpen,
    }));
  }
  /* eslint-disable class-methods-use-this */
  renderFileRow(file, idx) {
    const { selectedFiles } = this.state;

    const isMobile = utils.detectMob();

    return (
      <div className="pos-relative" key={file._id}>
        {!isMobile && <Checkbox checked={selectedFiles.includes(file._id)} onChange={e => this.selectFile(e, file._id)} />}
        <a className="row order-file" href={file.location} onClick={e => this.fileRowClick(e, file)}>
          <div className="col-1 order-file-name">
            {file.name}
            {/* {!isMobile && <i className="open-preview-icon" onClick={e => this.openFilePreviewHandler(e, file._id)} />} */}
          </div>
          <div className="col-1 order-file-date ta-right">{file.owner_role} on {formatTime(file.created_at, 'dt')}</div>
        </a>
      </div>
    );
  }

  renderCollapsedWrapper(children, sortedList) {
    const { isOpen, downloadFetching, selectedFiles } = this.state;
    const { onResize } = this.props;

    return (
      <UnmountClosed isOpened={isOpen} className="order-tab-collapse-body order-file-list" onRender={onResize}>
        <div className="order-tab-content">
          {children}
          {sortedList.length > 0 &&
          <div>
            <button
              className={classNames('btn btn-light btn-md btn-download-all', { hide: !!selectedFiles.length })}
              onClick={this.onDownloadAll}
              disabled={downloadFetching}
            >
              {downloadFetching ? 'Loading...' : 'Download all'}
            </button>
            <div className="clear" />
          </div>
        }
        </div>
      </UnmountClosed>
    );
  }

  renderSubCategories(sortedList) {
    const { isRevisionRelatedFiles } = this.props;
    const { openedCategories } = this.state;

    const { FILES_SUB_CATEGORIES, FILES_DUPLICATE_REVISION_SUB_CATEGORIES } = fileConstants;

    return (isRevisionRelatedFiles ? FILES_DUPLICATE_REVISION_SUB_CATEGORIES : FILES_SUB_CATEGORIES).map((category) => {
      const subcategoryFiles = sortedList.filter(file => file.subtype === category.id || (category.id === 'instructions' && !file.subtype));
      if (!subcategoryFiles.length) return null;

      return (
        <SubCategory key={category.id} category={category} subcategoryFiles={subcategoryFiles} openedCategories={openedCategories} toggleCategory={this.setOpenedCategories} renderFileRow={this.renderFileRow} />
      );
    });
  }

  renderContent(sortedList) {
    const {
      onResize,
      setStickyRef,
      isDe,
      withCategories = false,
    } = this.props;
    const {
      isOpen, isSubOpen, downloadFetching,
    } = this.state;

    if ((withCategories) && !isDe) {
      return this.renderCollapsedWrapper(
        this.renderSubCategories(sortedList),
        sortedList,
      );
    }

    if (sortedList.length <= filesMaxLength) {
      return this.renderCollapsedWrapper(
        sortedList.map(this.renderFileRow),
        sortedList,
      );
    }

    return (
      <StickyContainer isBottom ref={setStickyRef}>
        <StickyBody>
          <UnmountClosed isOpened={isOpen} className="order-tab-collapse-body order-file-list" onRender={onResize} hasNestedCollapse>
            <div className="order-tab-content">
              {sortedList.slice(0, filesMaxLength).map(this.renderFileRow)}
              <UnmountClosed isOpened={isSubOpen} onRender={onResize}>
                {sortedList.slice(filesMaxLength).map(this.renderFileRow)}
              </UnmountClosed>
              {sortedList.length > 1 &&
              <div>
                <button
                  className="btn btn-light btn-md btn-download-all"
                  onClick={this.onDownloadAll}
                  disabled={downloadFetching}
                >
                  {downloadFetching ? 'Loading...' : 'Download all'}
                </button>
                <div className="clear" />
              </div>
            }
            </div>
          </UnmountClosed>
        </StickyBody>
        <StickyHead classNameFixed="order-tab-collapse-footer-fixed">
          {isOpen &&
          <div className="order-tab-collapse-footer" onClick={this.toggleSub}>
            {!isSubOpen
              ? <div className="btn btn-show-all">Show all {sortedList.length} files <i className="arrow-down-xs" /></div>
              : <div className="btn btn-show-all active">Show less <i className="arrow-down-xs" /></div>
            }
          </div>
        }
        </StickyHead>
      </StickyContainer>
    );
  }

  render() {
    const {
      icon,
      fileList,
      title,
      order = {},
    } = this.props;
    const { isOpen, selectedFiles, downloadFetching } = this.state;
    const { no_sensitive: noSensitive = false } = order;

    if ((!fileList || !fileList.length) && !noSensitive) return null;

    const sortedList = fileList.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));

    return (
      <div className="order-tab-collapse">
        <div className={classNames('order-tab-collapse-head row space-between', { 'disabled-no-cursor': noSensitive })} onClick={!noSensitive && this.toggle}>
          <div className="row vertical">
            <Icon className="svg-icon" iconName={icon} />
            <span className="order-tab-head-name">{title} ({sortedList.length} {sortedList.length === 1 ? 'file' : 'files'})</span>
          </div>
          {
            isOpen && !!selectedFiles.length && <DeselectControls selectedFiles={selectedFiles} onDownloadAll={this.onDownloadAll} downloadFetching={downloadFetching} clearSelect={this.clearSelect} />
          }
          <span className={classNames('arrow-down', { active: isOpen })} />
        </div>
        {this.renderContent(sortedList)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isDe: state.user && (state.user.profile_type === 'D'),
});

const mapDispatchToProps = dispatch => ({
  openFilePreview: data => dispatch(setPlugin(data)),
  addModal: (component, modalOptions) => dispatch(addModalComponent(component, modalOptions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderFileList);
