import moment from 'moment-timezone';

const pagesToMinutes = pages => pages * 60;
const wordsToMinutes = words => Math.ceil(words / 5);
const slidesToMinutes = slides => slides * 30;
const sourcesToMinutes = sources => sources * 6;
const calcPrice = (price, percent) => (price + (price * (percent / 100))).toFixed(1);

const getMinutes = (size) => {
  // let fullSize = size.split(' ');
  const fullSize = size.match(/([\d ]*)(\w*)/);
  // if (fullSize.length < 2) {
  //   fullSize = [fullSize[1].slice(0, -1), fullSize[1].slice(-1)];
  // }

  let minutes = 0;
  const sizeLabel = fullSize[2];

  switch (sizeLabel) {
    case 'p':
    case 'page':
    case 'pages':
      minutes = pagesToMinutes(fullSize[1]);
      break;
    case 'w':
    case 'word':
    case 'words':
      minutes = wordsToMinutes(fullSize[1]);
      break;
    case 's':
    case 'slide':
    case 'slides':
      minutes = slidesToMinutes(fullSize[1]);
      break;
    case 'src':
    case 'source':
    case 'sources':
      minutes = sourcesToMinutes(fullSize[1]);
      break;
    default:
      minutes = Number(fullSize[1]);
      break;
  }
  return minutes;
};

const checkOrderTags = (order, user, orderSubjectGroups) => {
  if (!order) return null;

  if (user.view_status === 'Stem') return order;
  const mySubjects = user.subjects || [];
  const myTimeZone = user.timezone_str || null;

  const momentDeadLine = moment(order.writer_deadline);
  const hoursDiff = momentDeadLine.diff(moment(), 'hours');

  if ((order.tags || []).includes('first_order_paid')) {
    order.tag_is_vip = true;
  }

  if (hoursDiff < 24) {
    order.tag_is_hot = true;
  }

  if ((order.custom_tags || []).includes('instructions_ok')) {
    order.tag_is_updated = true;
  }
  if ((order.custom_tags || []).includes('prioritize_my_task')) {
    order.tag_feature = true;
  }
  if ((order.custom_tags || []).includes('prioritized')) {
    order.is_prioritized = true;
  }
  if ((order.tags || []).includes('draft_needed')) {
    order.tag_draft = true;
  }
  if (order.recomended_by_client) {
    order.tag_trust = true;
  }
  if (myTimeZone) {
    const profileTz = myTimeZone;
    const momentNow = moment.tz(profileTz);
    const momentPaid = moment.tz(order.client_paid_at, profileTz);
    const hoursDiffPaid = momentNow.diff(momentPaid, 'hours', true);

    if (hoursDiffPaid <= 2 && hoursDiffPaid > 0) {
      order.tag_is_new = true;
    }
  }

  if (mySubjects.length) {
    const mySubjectItems = user.isDe
      ? mySubjects
      : mySubjects.reduce((result, item) => [...result, ...orderSubjectGroups[item]], []);
    if (mySubjectItems.some(subject => subject.toLowerCase() === (order.subject || '').toLowerCase())) {
      order.tag_is_subjects = true;
    }
  }
  return order;
};

const newWritersDate = process.env.REACT_APP_BUILD === 'prod' ? '2021-09-09T00:00:00.000Z' : '2021-09-06T00:00:00.000Z';

export default {
  isStem: (profile = {}) => profile.view_status === 'Stem',
  isDe: (profile = {}) => profile.profile_type === 'D',
  isTutor: (profile = {}) => profile.is_tutor || false,
  isNewWriter: (profile = {}) => moment(profile.created_at || '').isSameOrAfter(newWritersDate) || false,
  isNTorder: (order = {}) => order.number && order.number.startsWith('NT'),
  isGHorder: (order = {}) => order.number && order.number.startsWith('LX'),
  isDuplicateOrder: (order = {}) => order.is_duplicate,
  isDuplicateAndRevisionOrder: (order = {}) => order.is_duplicate && order.is_revision_duplicate,
  isHaveAcceptedEstimate: (order = {}) => (order.estimates || []).some(item => item.status === 'accepted'),
  isOrderWithTips: (order = {}) => order.client_left_tips_past,
  isOrderHalfRevision: (order = {}) => (order.tags || []).includes('half_revision'),
  isOrderHalfRevisionConfirmed: (order = {}) => (order.tags || []).includes('half_revision_confirmed'),
  isOrderWithPartialDeliveryTag: (order = {}) => (order.custom_tags || []).includes('partial_delivery'),
  getTutorCourse: (profile = {}) => {
    const { teachable = {} } = profile;
    const { courses = [] } = teachable;
    // 1926827 - course_id for LR Tutors - Introduction to Tutoring
    return courses.find(course => course.course_id === 1926827);
  },
  getMinutesSize: (allSize) => {
    let minutesSize = 0;
    const sizes = allSize.split(' /');
    sizes.forEach((size) => { minutesSize += getMinutes(size); });
    return `${minutesSize} mins`;
  },
  isOrderNoFeedBack: (order) => {
    if (!order) return false;

    const nowDate = moment().utc();
    const availableFrom = moment(order.available_from);
    const ddl = moment(order.final_deadline);
    const percent20DdlAvailableFrom = (ddl.valueOf() - availableFrom.valueOf()) * 0.2;
    const diffNowAvailableFrom = nowDate.valueOf() - availableFrom.valueOf();

    return (diffNowAvailableFrom > percent20DdlAvailableFrom) && !order.has_feedbacks;
  },
  getFileExtension(fileName = '') {
    return fileName.split('.').pop();
  },
  deleteNbsp: str => str.replace(/ +/g, ' ').replace(/^\s+/g, ''),
  arrayEqual: (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i += 1) {
      if (!arr2.includes(arr1[i])) return false;
    }
    return true;
  },
  checkOrderTags,

  getTicketsQueryString(page, sorting, filter, isDe) {
    const params = [];

    if (page !== undefined) params.push(`page=${page}`);

    if (sorting) {
      Object.keys(sorting).forEach((key) => {
        const value = sorting[key];
        if (value) params.push(`${value}=${encodeURIComponent(key)}`);
      });
    }
    if (filter) {
      Object.keys(filter).forEach((key) => {
        const value = filter[key];
        if (Array.isArray(value)) {
          value.forEach((v) => {
            !!v && params.push(`${key}[]=${encodeURIComponent(v)}`);
          });
          return;
        }
        !!value && params.push(`${key}=${encodeURIComponent(value)}`);
      });
    }
    if (isDe) {
      params.push('by_de=true');
    }

    return `?${params.join('&')}`;
  },
  updateCookie(cname, cvalue, exMins) {
    const d = new Date();
    d.setTime(d.getTime() + (exMins * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  },
  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) return parts.pop().split(';').shift();

    return '';
  },
  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem));
  },
  calcPrice,
  immutablyRemoveKey: (key, { [key]: _, ...rest }) => rest,
  isOrderOnRevision: (order = {}) => order.system_revision_status === 'accepted' || order.system_revision_status === 'completed',
};
