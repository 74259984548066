import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { userSetFieldFetch } from 'store/user/actions';

import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';
import Select from 'components/Select';


const SubjectsTypeDropdown = (props) => {
  const {
    value, save, translate, isEditable,
  } = props;
  return (
    <span>
      {isEditable ?
        <Dropdown buttonClassName="lnk-dropdown" text={translate(`subjectsTypeFiler.${value}`)} closeAlways>
          <Select value={value} onChange={save} className="menu menu-selectable">
            <li key="all" value="all" className="menu-item">
              {translate('subjectsTypeFiler.all')}
              <Icon className="svg-icon" iconName="check-empty" />
            </li>
            <li key="my" value="my" className="menu-item">
              {translate('subjectsTypeFiler.my')}
              <Icon className="svg-icon" iconName="check-empty" />
            </li>
          </Select>
        </Dropdown> :
        <span className="profile-noneditable-field">{translate(`subjectsTypeFiler.${value}`)}</span>
            }
    </span>
  );
};

const mapStateToProps = (state, props) => ({
  translate: getTranslate(state.locale),
  value: state.user[props.name] || 'my',
});
const mapDispatchToProps = (dispatch, props) => ({
  save: value => dispatch(userSetFieldFetch(props.name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubjectsTypeDropdown);
