import { addOrderNotifications } from 'store/ordersNotifications/actions';
import { ordersFindFetchIfNeeded } from 'store/ordersFind/actions';
import { addNotice, noticesFetchExtraNeeded } from 'store/notices/actions';
import { ticketUpdate } from 'store/tickets/actions';
import { userReceiveSocketUpdate, userFetchIfNeeded } from 'store/user/actions';

import orderEventsParser from 'utils/orderEventsParser';

export const orderTopic = {
  topic: 'writers/en/liveorders/#',
  onMessageActions: [addOrderNotifications],
  onSubscribeFetch: [ordersFindFetchIfNeeded],
};

export const writerNoticesTopic = {
  topic: 'writer_notices/{hereWRid}',
  onMessageActions: [addNotice],
  onSubscribeFetch: [noticesFetchExtraNeeded],
};

export const issueResolutionTopic = {
  topic: 'issue_resolution_tickets/#',
  onMessageActions: [ticketUpdate],
};

export const writerProfileTopic = {
  topic: 'writers/{hereWRid}/profile/update/#',
  onMessageActions: [userReceiveSocketUpdate],
  onSubscribeFetch: [userFetchIfNeeded],
};

export const ordersEventsTopic = {
  topic: 'writers/{writer_id}/orders_events/#',
  onMessageActions: [orderEventsParser],
};
