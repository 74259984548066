import React from 'react';

const UserAvg = ({ user }) => {
  const { metric_data: metricData } = user;
  const { client_last_avg: clientLastAvg } = metricData || {};

  if (!clientLastAvg) return null;

  return (<span className="client-avg">{clientLastAvg}</span>);
};

export default UserAvg;
