const changeFavicon = (src) => {
  const oldLink = document.querySelector("link[rel~='favicon']");
  const newLink = document.createElement('link');
  newLink.rel = 'shortcut icon favicon';
  newLink.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(newLink);
};

export default changeFavicon;
