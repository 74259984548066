import React, { useState } from 'react';

import Checkbox from 'components/Checkbox';
import Radio from 'components/Radio';
import TextArea from 'components/shared/TextArea';
import InputWithSearch from 'components/shared/InputWithSearch';

const CommonComponents = () => {
  const [isCheckBoxChecked, toggleCheckBox] = useState({
    simpleCheckbox: false,
    simpleCheckboxWithMinus: false,
  });

  const [selectedRadio, setSelectedRadio] = useState('1');

  const onCheckBoxChecked = (e) => {
    const { target: { name } } = e;
    toggleCheckBox(__prevValue => ({ ...__prevValue, [name]: !__prevValue[name] }));
  };

  const onRadioChecked = (e) => {
    const { target: { name } } = e;
    setSelectedRadio(name);
  };

  return (
    <div>
      <div className="mb4">
        <InputWithSearch needSearch={false} inputPlaceholder="simple input" />
      </div>
      <div className="mb4">
        <InputWithSearch isDisabled inputValue="I am disabled" />
      </div>
      <div className="mb4">
        <InputWithSearch inputValue="I am with error" isError needSearch={false} />
      </div>
      <div className="mb4">
        <InputWithSearch inputValue="I am with error too" isErrorOnlyBorder needSearch={false} />
      </div>
      <div className="mb8">
        <InputWithSearch inputOnFocusPlaceholder="Search by letters" searchValues={['Ye to misery wisdom plenty polite to as', 'On no twenty spring of in esteem spirit likely estate', 'Barton did feebly change man she afford square add']} inputPlaceholder="I am with search function" />
      </div>
      <div className="mb4">
        <Checkbox
          name="simpleCheckbox"
          checked={isCheckBoxChecked.simpleCheckbox}
          onChange={onCheckBoxChecked}
        >Common checkbox</Checkbox>
      </div>
      <div className="mb4">
        <Checkbox
          disabled
        >Common checkbox disabled</Checkbox>
      </div>
      <div className="mb4">
        <Checkbox
          disabled
          checked
        >Common checkbox checked disabled</Checkbox>
      </div>
      <div className="mb4">
        <Checkbox
          name="simpleCheckboxWithMinus"
          checked={isCheckBoxChecked.simpleCheckboxWithMinus}
          onChange={onCheckBoxChecked}
          minus
        >Common checkbox with minus</Checkbox>
      </div>
      <div className="mb8">
        {['1', '2', '3', '4'].map(number => <Radio disabled={number === '4'} className="mb4" onChange={onRadioChecked} key={number} name={number} checked={selectedRadio === number} >{number}</Radio>)}
      </div>
      <div className="mb4">
        <TextArea placeholder="I am only vertical resize" />
      </div>
      <div className="mb4">
        <TextArea withWidthBothResize placeholder="I am full resize" />
      </div>
      <div className="mb8">
        <TextArea withCounter maxValue={100} withWidthBothResize placeholder="I am with counter" />
      </div>
    </div>
  );
};

export default CommonComponents;
