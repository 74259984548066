import React from 'react';

import classNames from 'utils/classNames';

import './styles/notification_tags.scss';

const TAGS = {
  interface: '#39A6F1',
  policies: '#12CA86',
  finance: '#637280',
  opportunities: '#AA6DD0',
  administrative: '#E89D00',
};

const IconTag = ({ fill }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">,
    <path d="M11.489 5.75496L6.239 0.504959C6.029 0.294959 5.73734 0.166626 5.4165 0.166626H1.33317C0.691504 0.166626 0.166504 0.691626 0.166504 1.33329V5.41663C0.166504 5.73746 0.294837 6.02913 0.510671 6.24496L5.76067 11.495C5.97067 11.705 6.26234 11.8333 6.58317 11.8333C6.904 11.8333 7.19567 11.705 7.40567 11.4891L11.489 7.40579C11.7048 7.19579 11.8332 6.90413 11.8332 6.58329C11.8332 6.26246 11.699 5.96496 11.489 5.75496ZM6.58317 10.6725L1.33317 5.41663V1.33329H5.4165V1.32746L10.6665 6.57746L6.58317 10.6725Z" fill={fill} />
    <path d="M2.7915 3.66663C3.27475 3.66663 3.6665 3.27488 3.6665 2.79163C3.6665 2.30838 3.27475 1.91663 2.7915 1.91663C2.30825 1.91663 1.9165 2.30838 1.9165 2.79163C1.9165 3.27488 2.30825 3.66663 2.7915 3.66663Z" fill={fill} />
  </svg>
);

const NotificationTags = ({ activeTags = [] }) => {
  return (
    <div className="container_tags">
      {activeTags.map((tag) => {
        const isActive = activeTags.includes(tag);
        const buttonStyle = { border: `1px solid ${TAGS[tag]}` };
        const textStyle = { color: `${TAGS[tag]}` };
        const iconFill = TAGS[tag];

        return (
          <button key={tag} style={buttonStyle} className={classNames('container_tags__item', { 'container_tags__item--active': isActive })}>
            <IconTag className="svg-icon" iconName="tag" fill={iconFill} />
            <span style={textStyle}>{tag}</span>
          </button>
        );
      })}
    </div>
  );
}

export default NotificationTags;
