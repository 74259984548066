import React, { useEffect, useState } from 'react';
import { UnmountClosed } from 'react-collapse';

import classNames from 'utils/classNames';

import { Colors, StoryIcons, StoryButtons, TooltipStory, CommonComponents, IconTagStory } from './stories';


import './index.scss';

const STORIES = [
  {
    title: 'Colors',
    openProps: 'isColorsOpened',
    Component: () => <Colors />,
  },
  {
    title: 'Icons',
    openProps: 'isIconsOpened',
    Component: ({ allIcons }) => <StoryIcons allIcons={allIcons} />,
  },
  {
    title: 'Buttons',
    openProps: 'isButtonsOpened',
    Component: () => <StoryButtons />,
  },
  {
    title: 'Tooltips',
    openProps: 'isTooltipsOpened',
    Component: () => <TooltipStory />,
  },
  {
    title: 'Common components',
    openProps: 'isCommonComponentsOpened',
    Component: () => <CommonComponents />,
  },
  {
    title: 'Order tags',
    openProps: 'isOrderTagsOpened',
    Component: () => <IconTagStory />,
  },
];

const CUSTOM_ICONS_STYLES = {
  'active-notification': { fill: '#ccc' },
  'combined-shape': { fill: 'rgba(252, 105, 79, 0.2)', stroke: '#fc694f' },
  'icon-time': { stroke: '#ccc' },
  attention: { stroke: '#fff' },
  'attention-blue': { stroke: '#fff' },
  'icon-reset': { fill: '#ccc' },
};

const StoryBook = () => {
  const [openedSections, toggleOpenedSections] = useState({
    isColorsOpened: false,
    isIconsOpened: false,
    isButtonsOpened: false,
    isTooltipsOpened: false,
    isCommonComponentsOpened: false,
    isOrderTagsOpened: false,
  });

  const [allIcons, setAllIcons] = useState([]);

  const onToggleOpenedSections = (openProps) => {
    toggleOpenedSections(__prevValue => ({ ...__prevValue, [openProps]: !__prevValue[openProps] }));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const iconPortal = document.querySelector('#writer-icon-portal');

      if (!iconPortal) return;

      setAllIcons(Array.from(iconPortal.querySelectorAll('symbol'))
        .map(symbol => ({
          name: symbol.id,
          styles: CUSTOM_ICONS_STYLES[symbol.id] || {},
        })));
    }, 10);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="story-container">
      {
          STORIES.map(({ title, openProps, Component }) => (
            <div className="order-tab-collapse" key={title}>
              <div data-title={title} className="order-tab-collapse-head row space-between" onClick={() => onToggleOpenedSections(openProps)}>
                <div className="order-tab-head-name"><b>{title}</b></div>
                <span className={classNames('arrow-down', { active: openedSections[openProps] })} />
              </div>
              <UnmountClosed isOpened={openedSections[openProps]} className="order-tab-collapse-body" hasNestedCollapse>
                <Component allIcons={allIcons} />
              </UnmountClosed>
            </div>
          ))
      }
    </div>
  );
};

export default StoryBook;
