import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActiveWarningsTable from './ActiveWarningsTable';

class ActiveWarnings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeWarnings: [],
    };
  }
  componentWillMount() {
    this.getActiveWarnings(this.props);
  }

  componentWillReceiveProps(props) {
    this.getActiveWarnings(props);
  }

  getActiveWarnings(props) {
    const { warnings } = props;
    if (!warnings) return null;

    const activeWarnings = warnings.map((warning) => {
      if (warning.tag !== 'qc_other') {
        let { comment } = warning;
        const commentArray = comment.split(';');
        comment = commentArray[1] ? commentArray[1] : comment;
        return Object.assign({}, warning, { comment });
      }
      return warning;
    });
    this.setState({ activeWarnings });
    return true;
  }

  render() {
    const { activeWarnings } = this.state;
    return (
      <div>
        <h2 className="active-warnings-title">Active Warnings</h2>
        <ActiveWarningsTable warnings={activeWarnings} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const stateWarnings = (state.user && state.user.activeWarnings) || null;
  const warnings = (stateWarnings && stateWarnings.results && stateWarnings.results.items) || null;

  if (!warnings) {
    return {
      warnings: [],
    };
  }

  return {
    warnings,
  };
};

export default connect(mapStateToProps)(ActiveWarnings);
