import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { userWarningFetchIfNeeded } from './../../store/user/actions';
import WarningsTable from './WarningsTable';

import classNames from '../../utils/classNames';

const sort = (a, b, isAsc) => {
  if (isAsc && a > b) return 1;
  if (isAsc && a < b) return -1;
  if (!isAsc && a > b) return -1;
  if (!isAsc && a < b) return 1;
  return 0;
};

class WarningCollapse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      sorting: {
        field: 'closed_at',
        isAsc: true,
      },
    };

    this.toggle = this.toggle.bind(this);
    this.setSorting = this.setSorting.bind(this);
    this.itemsSorting = this.itemsSorting.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen) {
      this.props.loadTag(this.props.tag);
    }
  }


  setSorting(field) {
    const isAsc = this.state.sorting.field === field ? !this.state.sorting.isAsc : true;
    this.setState({
      sorting: {
        field,
        isAsc,
      },
    });
  }

  toggle() {
    this.setState(state => Object.assign({}, state, {
      isOpen: !state.isOpen,
    }));
  }

  itemsSorting(a, b) {
    const { field, isAsc } = this.state.sorting;
    if (field === 'order') {
      const aOrderNumber = (a.order_info && a.order_info.number) || '';
      const bOrderNumber = (b.order_info && b.order_info.number) || '';
      return sort(aOrderNumber, bOrderNumber, isAsc);
    }
    if (field === 'created_at') {
      return sort(moment(a.created_at), moment(b.created_at), isAsc);
    }
    if (field === 'closed_at') {
      const aSorting = a.closed_at || a.orders_live;
      const bSorting = b.closed_at || b.orders_live;
      return sort(moment(aSorting), moment(bSorting), isAsc);
    }
    if (field === 'status') {
      return sort(a.status, b.status, isAsc);
    }
    return isAsc;
  }

  render() {
    const { isOpen, sorting } = this.state;
    const { title, active, count, percent } = this.props.warning;
    const { loading, items } = this.props;
    const arrowClassName = classNames('arrow_down', {
      'active': isOpen,
      'fade': percent === 0,
    });
    const sortedItems = items.sort(this.itemsSorting);
    return (
      <section
        className={classNames('row_info', {
          'active': isOpen
        })}
      >
        <div onClick={this.toggle} className='row_info-title'>
          <h5>{title}</h5>
          <span className={classNames(
            'percent', {
            'fade': percent === 0
          }
          )}>{`${percent}%`}</span>
          <span className='cnt'>
            <span className={classNames(
              'active_cnt', {
              'fade': active === 0
            }
            )}>{active}</span>/
            <span className={classNames({ 'fade': count === 0 })}>{count}</span>
          </span>
          <span className={arrowClassName} />
        </div>
        {isOpen && loading &&
          <p>Loading...</p>
        }
        {isOpen && !loading &&
          <WarningsTable warnings={sortedItems} sorting={sorting} setSorting={this.setSorting} />
        }
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { tag } = ownProps.warning;
  const warning = state.user.warnings[tag];

  if (!warning || warning.isFetching) {
    return {
      items: [],
      loading: true,
    };
  }

  return {
    loading: warning.isFetching,
    items: warning.items,
    page: warning.page,
    total: warning.total,
    limit: warning.limit,
  };
};


const mapDispatchToProps = dispatch => ({
  loadTag: tag => dispatch(userWarningFetchIfNeeded(tag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WarningCollapse);
