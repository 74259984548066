import {
  TICKETS_SETTINGS_REQUEST,
  TICKETS_SETTINGS_RECEIVE,
} from './actions';

const ticketsSettings = (state = {
  isFetching: false,
  didInvalidate: true,
}, action) => {
  switch (action.type) {
    case TICKETS_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case TICKETS_SETTINGS_RECEIVE:
      return Object.assign({}, state, action.data, {
        isFetching: false,
        didInvalidate: false,
      });
    default:
      return state;
  }
};

export default ticketsSettings;
