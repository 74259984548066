import {
  ADD_VERIFY_MODAL,
  CLOSE_VERIFY_MODAL,
} from './actions';

const verifyModal = (state = {
  isOpen: false,
  verifyStatusHandler: null,
}, action) => {
  switch (action.type) {
    case ADD_VERIFY_MODAL:
      return {
        ...state,
        ...{
          isOpen: true,
          verifyStatusHandler: action.verifyStatusHandler,
        },
      };
    case CLOSE_VERIFY_MODAL:
      return {
        ...state,
        ...{
          isOpen: false,
          verifyStatusHandler: null,
        },
      };
    default:
      return state;
  }
};

export default verifyModal;
