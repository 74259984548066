import React, { Component, Fragment } from 'react';

import { withRouterMatch } from 'hocs';

import ProposedOrdersModal from 'components/ProposedOrdersModal';
import OrdersProgressTable from './../components/OrdersProgressTable';
import OrderProgressTabPage from './OrderProgressTabPage';

const myPath = '/myorders/inprogress';

class MyOrdersProgressPage extends Component {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(orderNumber) {
    const { history, match } = this.props;
    const prevOrderNumber = match.params.orderNumber;
    if (prevOrderNumber === orderNumber) {
      history.push(myPath);
      return;
    }
    history.push(`${myPath}/${orderNumber}`);
  }

  render() {
    const { match } = this.props;
    const { orderNumber } = match.params;
    return (
      <Fragment>
        <div className="container">
          <OrdersProgressTable onSelect={this.onSelect} selectedNumber={orderNumber} />
          {!!orderNumber &&
          <OrderProgressTabPage orderNumber={orderNumber} onSelect={this.onSelect} />
                    }
        </div>
        <ProposedOrdersModal />
      </Fragment>
    );
  }
}

export default withRouterMatch(MyOrdersProgressPage);
