import React from 'react';

import ButtonList from 'components/ButtonList';
import Overview from 'components/profile/Overview';
import classNames from 'utils/classNames';

const IssueInstructions = ({
  instructions,
  onChangeSubIssue,
  instructionsList,
  onCommentChange,
  instructionComment,
  errorInstructionComment,
}) => (
  <div>
    <ButtonList
      chooseValue={instructions}
      onChange={onChangeSubIssue}
      list={instructionsList}
      field="instructions"
    />
    {instructions.length > 0 &&
      <div className="issue-comment">
        <Overview
          onChangeFields={onCommentChange}
          value={instructionComment}
          fieldName="instructionComment"
          placeholder="Please let us know what part of the instructions or attachments is missing"
          cntSymbols={150}
          className={classNames({ 'issue_error': errorInstructionComment })}
        />
        {errorInstructionComment &&
          <p className="field_required">This field is required</p>
        }
      </div>
    }
  </div>
);

export default IssueInstructions;
