const RUBICS_SWITCH_STATUS = {
  writing: {
    plagiarism_free: {
      active: true,
    },
    content: {
      active: null,
    },
    sources: {
      active: true,
    },
    word_count: {
      active: true,
    },
    structure: {
      active: true,
    },
    language: {
      active: null,
    },
    format: {
      active: true,
    },
  },
  calculations: {
    final_answer: {
      active: null,
    },
    explanation: {
      active: true,
    },
    formulas: {
      active: true,
    },
    language: {
      active: true,
    },
    format: {
      active: true,
    },
    plagiarism: {
      active: true,
    },
  },
  presentation: {
    content: {
      active: null,
    },
    plagiarism: {
      active: true,
    },
    visual: {
      active: true,
    },
    language: {
      active: true,
    },
    format: {
      active: true,
    },
    slide_count: {
      active: true,
    },
    sources: {
      active: true,
    },
  },
  editing: {
    content: {
      active: null,
    },
    language: {
      active: true,
    },
    structure: {
      active: true,
    },
    sources: {
      active: true,
    },
    format: {
      active: true,
    },
    plagiarism: {
      active: true,
    },
  },
  proofreading: {
    language: {
      active: null,
    },
    format: {
      active: true,
    },
  },
  quiz: {
    correct_answers: {
      active: null,
    },
  },
  'Revision by new Freelancer': {
    final_answer: {
      active: true,
    },
    Explanation: {
      active: true,
    },
    Formulas: {
      active: true,
    },
    Language: {
      active: true,
    },
    Format: {
      active: true,
    },
    Content: {
      active: true,
    },
    Visual: {
      active: true,
    },
    Sources: {
      active: true,
    },
    'Slide/word count': {
      active: true,
    },
    Structure: {
      active: true,
    },
    plagiarism: {
      active: true,
    },
  },
};

export default RUBICS_SWITCH_STATUS;
