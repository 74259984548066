import {
  ORDERS_MESSAGES_SET_READ_REQUEST,
  ORDERS_MESSAGES_SET_READ_RECEIVE,
  ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE,
} from '../constants';

const message = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_MESSAGES_SET_READ_REQUEST:
      return Object.assign({}, state, {
        isFetchingRead: true,
      });
    case ORDERS_MESSAGES_SET_READ_RECEIVE:
      return Object.assign({}, state, action.message, {
        isFetchingRead: false,
      });
    case ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE:
      return Object.assign({}, state, action.message, {
        ticket_id: action.ticketId,
      });
    default:
      return state;
  }
};

export default message;
