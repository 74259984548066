import React from 'react';

import Emoji from 'components/Emoji';

const textVariants = {
  '/findorders/all': {
    withFilters: [
      'Oops, we don\'t have orders matching this filter set.',
      'Please try again with other filter configuration.',
    ],
    noFilters: [
      'Oops, we don’t have any available orders at the moment.',
      'Please check again later. ',
    ],
  },
  '/myorders/inprogress': [
    'Oops, there are no orders\n in this section. ',
    'Only orders in work are shown here.',
  ],
  '/myorders/revisions': [
    'Oops, there are no orders\n in this section. ',
    'Only orders that are currently on revision are shown here.',
  ],
  '/myorders/completed': [
    'Oops, there are no orders\n in this section. ',
    'Only completed orders are shown here.',
  ],
  '/myorders/refunded': [
    'Oops, there are no orders\n in this section. ',
    'Only refunded orders are shown here.',
  ],
};

const NoOrders = ({ isSearch, path = '/findorders/all', option = 'withFilters' }) => (isSearch ? (
  <div className="page-not-result">
    <Emoji id="verySad" />
    <h1>Oops, this order is not found!</h1>
    <p className="notes">Please check the order number or ask the Support team for help.</p>
  </div>
) : (
  <div className="page-not-result">
    <Emoji id="crySad" />
    <h1>
      {(Array.isArray(textVariants[path]) ? textVariants[path] : textVariants[path][option] || [])[0].split('\n').map((item, i) => <div key={i}>{item}</div>)}
    </h1>
    <p className="notes">{(Array.isArray(textVariants[path]) ? textVariants[path] : textVariants[path][option] || [])[1]}</p>
  </div>
)
);

export default NoOrders;
