
import React from 'react';
import { REPORT_PROCESSING_TIME, CLASSNAME_TEAM } from 'components/WriterTicketing/constants/writerTicketing';
import classNames from 'utils/classNames';

const WriterTicketingProcessingTime = ({ type }) => {
  if (!type) {
    return (
      <div className="processing_time">
        <div className="processing_time__header row">
          <div className="processing_time__header__col col-6">Responsible team</div>
          <div className="processing_time__header__col col-6">Avg. processing time</div>
        </div>
      </div>
    );
  }

  const { team = '', time = '' } = REPORT_PROCESSING_TIME[type];
  const classNameTeam = classNames('processing_time__body__col col-6', CLASSNAME_TEAM[team]);
  const classNameTime = classNames('processing_time__body__col col-6', CLASSNAME_TEAM[team]);

  return (
    <div className="processing_time">
      <div className="processing_time__header row">
        <div className="processing_time__header__col col-6">Responsible team</div>
        <div className="processing_time__header__col col-6">Avg. processing time</div>
      </div>

      <div className="processing_time__body row">
        <div className={classNameTeam}><i className="icon" />{team}</div>
        <div className={classNameTime}><i className="icon_time" />{time}</div>
      </div>
    </div>
  );
};

export default WriterTicketingProcessingTime;
