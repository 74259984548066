const LAST_WS_SUBSCRIPTION = 'lastWsSubscription';

const IS_SHOWED_BROWSER_NOTIFICATIONS_MODAL = 'isShowedBrowserNotificationsModal';
const DATA_DISABLED_REQUEST_PM_SETUP = 'dataDisabledRequestPmSetupModal';

export {
  LAST_WS_SUBSCRIPTION,
  DATA_DISABLED_REQUEST_PM_SETUP,
  IS_SHOWED_BROWSER_NOTIFICATIONS_MODAL,
};

