import React from 'react';
import {
  NavLink,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { connect } from 'react-redux';

import Nav from './../components/Nav';
import classNames from './../utils/classNames';

import InboxUpdatesPage from './InboxUpdatesPage';
import InboxUpdatesDetailPage from './InboxUpdatesDetailPage';
import { getHasUnreadInboxItems } from './../store/notification/selectors';


const InboxPage = (props) => {
  const { hasNewInboxItems } = props;
  const updateClassName = classNames({ new: hasNewInboxItems });
  return (
    <div>
      <Nav sub>
        <NavLink className={updateClassName} to="updates">Updates</NavLink>
      </Nav>
      <Routes>
        <Route path="updates" element={<InboxUpdatesPage />} />
        <Route path="updates/:id" element={<InboxUpdatesDetailPage />} />
        <Route path="*" element={<Navigate to="updates" replace />} />
      </Routes>
    </div>
  );
};


const mapStateToProps = (state) => {
  const hasNewInboxItems = getHasUnreadInboxItems(state);
  return {
    hasNewInboxItems,
    profile: state.user,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InboxPage);
