import { useEffect } from 'react';
import getTracker from '@verdaccio/lrtrack-js';
import { LR_TRACK_URL, LR_TRACK_ID } from './config';

const IS_PROD = process.env.REACT_APP_BUILD === 'prod';

const tracker = getTracker({
  trackerId: LR_TRACK_ID,
  baseUrl: LR_TRACK_URL,
  isDebug: !IS_PROD,
});

// track values, clicks export example
const useTrackValues = tracker.metrics.getUseTrackValues({ useEffect });
const trackClicks = tracker.metrics.trackClicks;
const trackValues = tracker.metrics.trackValues;
const setCid = cid => tracker.config.setCid(cid);

const storageGetItem = (key) =>
  tracker.utils.storageGetItem(tracker.utils.getStorageKey(key));
const storageSetItem = (key, payload) =>
  tracker.utils.storageSetItem(tracker.utils.getStorageKey(key), payload);

export {
  tracker as default,
  useTrackValues,
  trackClicks,
  trackValues,
  storageGetItem,
  storageSetItem,
  setCid,
};
