import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { withRouter, withPathNoRender } from 'hocs';

import {
  getHasUnreadMessages,
  getHasUnreadInboxItems,
  getHasUnreadFinances,
  getHasCntBonusesTask,
  getHasUnreadWarnings,
} from 'store/notification/selectors';

import { getToken } from 'utils/tokenStorage';

import { getToastOrderNotifications } from 'store/ordersNotifications/selectors';

import { switchDNDmode } from 'store/settings/actions';
import { addVerifyModal } from 'store/verifyModal/actions';

import classNames from 'utils/classNames';
import storage from 'utils/localStorageHandler';
import utils from 'utils';
import logout from 'utils/logout';
import { apiHostTutuor, urls } from 'config';

import Nav from 'components/Nav';
import Icon from 'components/Icon';
import AvailabilityDropdown from 'components/AvailabilityDropdown';
import WriterGroup from 'components/WriterGroup';
import Dropdown from 'components/Dropdown';
import Tooltip from 'components/Tooltip';

import { toastConfig } from 'constants';
import VERIFY_STATUSES from 'constants/verifyStatuses';

import dndOff from '../../img/icons/dnd_off.png';
import dndOn from '../../img/icons/dnd_on.png';

import './styles/menu.scss';

const getWindowFreeSpaceWidth = () => (window.innerWidth - 1200) / 2;
const MAX_FREE_SPACE = 307;
const NOT_WIDE_CLASS = 'orders-notifications-container--wide';

class MainNav extends Component {
  constructor(props) {
    super(props);

    this.onProfileClick = this.onProfileClick.bind(this);
    this.onSwitchClick = this.onSwitchClick.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.onSwitchDndMode = this.onSwitchDndMode.bind(this);
    this.toggleVerifyModalHandler = this.toggleVerifyModalHandler.bind(this);
    this.verifyStatusHandler = this.verifyStatusHandler.bind(this);

    this.state = {
      dndMode: storage.get('dnd-mode'),
      isTutuor: false,
      tutorData: null,
      isLoadingVerifyStatus: false,
    };
    this.modToastConfig = {
      ...toastConfig,
      ...{ className: classNames('orders-notifications-container', { 'orders-notifications-container--wide': getWindowFreeSpaceWidth() < MAX_FREE_SPACE }) },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    fetch(urls.self, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: getToken(),
      },
    })
      .then(response => response.json())
      .then((data) => {
        if (data && (data.role_names.indexOf('tutor') > -1 || data.replicas_json.filter(r => r.role_names.indexOf('tutor') > -1).length > 0)) {
          this.setState({
            isTutuor: true,
            tutorData: data,
          });
        }
      })
      .catch((error) => {
        console.log(`There has been a problem with your fetch operation: ' ${error.message}`);
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onProfileClick(event) {
    event.preventDefault();
    const { location, history } = this.props;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('profile')) {
      return;
    }
    const searchFromParams = searchParams.toString();
    const newSearch = !searchFromParams ? ('?profile') : `?${searchFromParams}&profile`;
    history.push(location.pathname + newSearch);
  }

  onSwitchClick(event) {
    event.preventDefault();
    const { tutorData } = this.state;

    if (tutorData.role_names.indexOf('tutor') > -1) {
      window.open(`${apiHostTutuor}`);
    } else {
      fetch(urls.replica_token(tutorData.replicas_json.filter(r => r.role_names.indexOf('tutor') > -1)[0].id), {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: getToken(),
        },
      })
        .then(response => response.json())
        .then((data) => {
          if (data && data.token) {
            window.open(`${apiHostTutuor}/login/${data.token}`);
          }
        })
        .catch((error) => {
          console.log(`There has been a problem with your fetch operation: ' ${error.message}`);
        });
    }
  }

  onSwitchDndMode() {
    const { dndMode } = this.state;
    this.setState({ dndMode: !dndMode });
    storage.set('dnd-mode', !dndMode);
    this.props.switchDNDmode();
  }

  handleResize() {
    if (!this.toastify) {
      this.toastify = document.querySelector('.Toastify');
      this.toastify.classList.add('Toastify--fixed');
    }
    const windowFreeSpaceWidth = getWindowFreeSpaceWidth();
    this.toastify.style.right = `${windowFreeSpaceWidth}px`;
    const { toastedOrderNotifications } = this.props;
    if (!toastedOrderNotifications.length) return;

    if (!this.ordersNotificationsContainer) this.ordersNotificationsContainer = document.querySelector('.orders-notifications-container');
    if (!this.ordersNotificationsContainer) return;
    const oNCClassList = this.ordersNotificationsContainer.classList;

    if (oNCClassList.contains(NOT_WIDE_CLASS) && windowFreeSpaceWidth < MAX_FREE_SPACE) return;

    if (windowFreeSpaceWidth < MAX_FREE_SPACE) oNCClassList.add(NOT_WIDE_CLASS);
    if (windowFreeSpaceWidth > MAX_FREE_SPACE) oNCClassList.remove(NOT_WIDE_CLASS);
  }

  toggleVerifyModalHandler() {
    const { profile, openVerifyModal } = this.props;
    const { last_veriff_session_object: veriffSession = {} } = profile;

    if (veriffSession.status === 'resubmission_requested' && veriffSession.url) {
      window.open(veriffSession.url, '_blank').focus();
      return;
    }

    openVerifyModal({
      verifyStatusHandler: this.verifyStatusHandler.bind(this),
    });
  }

  verifyStatusHandler(status) {
    this.setState({ isLoadingVerifyStatus: status });
  }

  getTooltipText() {
    return 'This form confirms that you are \n not a US resident, and \n therefore not obliged to pay \n any tax withheld by the US.';
  };

  render() {
    const {
      hasNewMessges,
      hasNewInboxItems,
      hasNewFinances,
      hasNewBonuses,
      hasUnreadWarnings,
      profile,
      location,
    } = this.props;
    const { dndMode, isLoadingVerifyStatus } = this.state;
    const ordersClassName = classNames({ new: hasNewMessges });
    const inboxClassName = classNames({ new: hasNewInboxItems });
    const financesClassName = classNames({ new: hasNewFinances });
    const progressClassName = classNames({
      new: hasNewBonuses || hasUnreadWarnings,
      'new--usd': hasNewBonuses,
      'new--warnings': hasUnreadWarnings,
    });
    const isStem = utils.isStem(profile);

    const { last_veriff_session_object: veriffSession = {} } = profile;

    let veriffSessionStatus = veriffSession.status;

    if (veriffSession.active_status === 'declined' && veriffSession.crm_status === 'Resubmission') {
      veriffSessionStatus = 'resubmission_requested';
    }

    const verifyStatus = VERIFY_STATUSES[veriffSessionStatus] || VERIFY_STATUSES.default;

    return (
      <div>
        <Nav>
          <NavLink to="/findorders">Find Orders</NavLink>
          <NavLink className={ordersClassName} to="/myorders">My Orders</NavLink>
          {!isStem &&
            <NavLink className={inboxClassName} to="/inbox">Inbox</NavLink>
          }
          <NavLink className={progressClassName} to="/progress">My Progress</NavLink>
          {!isStem &&
            <NavLink className={financesClassName} to="/finances">Finances</NavLink>
          }
          <NavLink to="/issues">Issue Resolution</NavLink>
          <NavLink to="/help">Help</NavLink>
        </Nav>
        <div className="personal-info container">
          <div className="personal-box">
            <button
              className="btn btn-empty btn-30square mr10"
              onClick={this.onSwitchDndMode}
            >
              <img src={dndMode ? dndOn : dndOff} alt="DND mode" title="switch do not disturb mode" />
            </button>
            <WriterGroup />
            {
              !(veriffSession.status === 'not_required' || veriffSession.status === 'approved') && (
                <button
                  className={classNames('btn mr10', verifyStatus.class || 'btn-bright')}
                  onClick={this.toggleVerifyModalHandler}
                  disabled={verifyStatus.disabled || isLoadingVerifyStatus || false}
                >
                  {isLoadingVerifyStatus ? 'Loading ...' : verifyStatus.text}
                </button>
              )
            }

            {/* {veriffSession.status === 'approved' && !profile.w8_signed &&
              <Tooltip className="w8_form" content={this.getTooltipText()} positionDown light>
                <a href={profile.w8_url} target="_blank" className={classNames('btn mr10  btn-bright')} rel="noreferrer">
                  Sign W-8 Form
                </a>
              </Tooltip>
            } */}

            <Dropdown closeAlways icon="user" className="" btnClassName="">
              <ul className="menu menu-selectable">
                <li className="menu-item">
                  <a onClick={this.onProfileClick}>
                    <Icon className="svg-icon" iconName="settings" />
                    Profile settings
                  </a>
                </li>
                <li className="menu-item">
                  <AvailabilityDropdown />
                </li>
                <li className="menu-item">
                  <a onClick={logout}>
                    <Icon className="svg-icon" iconName="logout" />
                    Logout
                  </a>
                </li>
              </ul>
            </Dropdown>
            <ToastContainer {...this.modToastConfig} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const hasNewMessges = getHasUnreadMessages(state);
  const hasNewInboxItems = getHasUnreadInboxItems(state);
  const hasNewFinances = getHasUnreadFinances(state);
  const hasNewBonuses = getHasCntBonusesTask(state);
  const hasUnreadWarnings = getHasUnreadWarnings(state);
  const toastedOrderNotifications = getToastOrderNotifications(state);

  return {
    hasNewMessges,
    hasNewInboxItems,
    hasNewFinances,
    hasNewBonuses,
    hasUnreadWarnings,
    profile: state.user,
    toastedOrderNotifications,
  };
};

const mapDispatchToProps = dispatch => ({
  switchDNDmode: () => dispatch(switchDNDmode()),
  openVerifyModal: data => dispatch(addVerifyModal(data)),
});

export default withPathNoRender(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNav)), 'order/');
