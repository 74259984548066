import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import utils from 'utils';

import classNames from 'utils/classNames';

import { ORDERS_TYPE_RESERVED, ordersFindSetSorting, ORDERS_TYPE_ALL } from 'store/ordersFind/actions';
import { clearNewOrdersNotifications } from 'store/ordersNotifications/actions';

import { getNewNotViewedOrderNotifications } from 'store/ordersNotifications/selectors';

import OrdersFilter from 'components/OrdersFilter';
import TableSortingHead from 'components/TableSortingHead';
import OrderTableNotifications from 'components/OrderTableNotifications';

import OrdersTableFilter from './OrdersTableFilter';

const OrdersTableHeader = ({
  profile, isSearch, onSearchChange, sorting, typeId,
}) => {
  const newNotViewedOrderNotifications = useSelector(getNewNotViewedOrderNotifications);

  const notViewedNewOrders = typeId === ORDERS_TYPE_ALL ? newNotViewedOrderNotifications : [];
  const { profile_type: profileType, qc_full_group: qcFullGroup } = profile;
  const isDe = profileType === 'D';
  const isAPlusPro = qcFullGroup === 'A+-pro';

  const isReserved = typeId === ORDERS_TYPE_RESERVED;
  const isStem = utils.isStem(profile);

  const dispatch = useDispatch();

  const setSorting = (field) => {
    dispatch(clearNewOrdersNotifications());
    dispatch(ordersFindSetSorting(field));
  };

  return (
    <>
      {
        isDe ?
          <OrdersTableFilter isSearch={isSearch} onSearchChange={onSearchChange} className="table-filter" typeId={typeId} />
          :
          <OrdersFilter isSearch={isSearch} onSearchChange={onSearchChange} />
      }
      <div className="table-header">
        <div className="row">
          <div className="col-1 small-col" />
          <div className={classNames({ 'col-4': !isAPlusPro && !isStem, 'col-5': isAPlusPro || isStem })}>Order Number</div>
          <div className="col-6">Subject</div>
          <div className="col-3">Type of work</div>
          <div className="col-4">
            <TableSortingHead
              sorting={sorting}
              setSorting={setSorting}
              field={isStem ? 'pages' : 'writer_price'}
            >
              You’ll earn
            </TableSortingHead>
          </div>
          {!isAPlusPro && !isStem &&
            <div className="col-3">
              A+ level earns
            </div>
              }
          <div className="col-2">
            <TableSortingHead
              sorting={sorting}
              setSorting={setSorting}
              field="pages"
            >
              Size
            </TableSortingHead>
          </div>
          <div className="col-5">
            <TableSortingHead
              sorting={sorting}
              setSorting={setSorting}
              field="writer_deadline"
            >
              Deadline / time left
            </TableSortingHead>
          </div>
          {(!isStem || (isStem && isReserved)) &&
            <div className="col-4">{isReserved ? 'Reserved time left' : 'Additional info'}</div>
          }
        </div>
      </div>
      {notViewedNewOrders.length > 0 && <OrderTableNotifications notViewedNewOrders={notViewedNewOrders} />}
    </>
  );
};

export default OrdersTableHeader;
