import React, { useState } from 'react';

import classNames from 'utils/classNames';

import './index.scss';

const TextArea = ({
  initialText = '', onUpdateCallback, placeholder = '', noResize = false,
  extraClasses = '', withCounter = false, maxValue = +Infinity, minHeight = 'auto',
  withWidthBothResize = false,
}) => {
  const [text, setText] = useState(initialText);

  const onChangeValue = (e) => {
    const { target: { value } } = e;
    setText(value);

    if (!onUpdateCallback) return;

    onUpdateCallback(value);
  };

  return (
    <div className="common-textarea-wrapper">
      <textarea
        className={
          classNames(
            'common-textarea',
            { 'common-textarea--no-resize': noResize },
            { 'common-textarea--both-resize': withWidthBothResize },
            (Array.isArray(extraClasses) ? extraClasses.join(' ') : extraClasses),
          )
        }
        value={text}
        placeholder={placeholder}
        onChange={onChangeValue}
        maxLength={maxValue}
        style={{ minHeight }}
      />
      {withCounter && (
        <div
          className={
            classNames(
              'common-textarea-counter',
              { 'common-textarea-counter--red': text.length === maxValue },
            )
          }
        >
            {maxValue - text.length}
        </div>
      )}
    </div>
  );
};

export default TextArea;
