import moment from 'moment-timezone';
import {
  ORDERS_PROGRESS_REQUEST,
  ORDERS_PROGRESS_RECEIVE,

  ORDERS_PROGRESS_SET_SORTING,
  ORDERS_PROGRESS_SET_FILTER_SEARCH,
  ORDERS_PROGRESS_SET_MESSAGE_FILTER,
  ORDERS_PROGRESS_RESET_FILTER,

  ORDERS_HIDE_FROM_TABLE,
} from './actions';

import { ORDERS_HIDE_IN_LISTS } from './../orders/actions';

const filter = (state, action) => {
  switch (action.type) {
    case ORDERS_PROGRESS_SET_FILTER_SEARCH:
      return Object.assign({}, state, {
        search: action.value,
      });
    case ORDERS_PROGRESS_SET_MESSAGE_FILTER:
      return Object.assign({}, state, {
        new_messages: action.value,
      });
    case ORDERS_PROGRESS_RESET_FILTER:
      return { search: '', new_messages: false };
    default:
      return state;
  }
};

const sorting = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_PROGRESS_SET_SORTING:
      return Object.assign({}, state, {
        field: action.value,
        isAsc: state.field === action.value ? !state.isAsc : true,
      });
    default:
      return state;
  }
};

const ordersProgress = (state = {
  isFetching: false,
  didInvalidate: true,
  numbers: [],
  sorting: { field: 'writer_deadline', isAsc: true },
  filter: { search: '', new_messages: false },
}, action) => {
  switch (action.type) {
    case ORDERS_HIDE_IN_LISTS:
    case ORDERS_HIDE_FROM_TABLE:
      return Object.assign({}, state, {
        numbers: state.numbers.filter(number => number !== action.number),
      });
    case ORDERS_PROGRESS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case ORDERS_PROGRESS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        updatedAt: moment(),
        numbers: action.numbers,
      });
    case ORDERS_PROGRESS_SET_SORTING:
      return Object.assign({}, state, {
        sorting: sorting(state.sorting, action),
      });
    case ORDERS_PROGRESS_SET_FILTER_SEARCH:
    case ORDERS_PROGRESS_SET_MESSAGE_FILTER:
    case ORDERS_PROGRESS_RESET_FILTER:
      return Object.assign({}, state, {
        filter: filter(state.filter, action),
      });
    default:
      return state;
  }
};

export default ordersProgress;
