import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  NavLink,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import { withRouter } from 'hocs';

import {
  getHasCntBonusesTask,
  getHasUnreadWarnings,
} from './../store/notification/selectors';
import Nav from './../components/Nav';
import classNames from './../utils/classNames';

import ProgressBenefitsPage from './ProgressBenefitsPage';
import ProgressGroupActivityPage from './ProgressGroupActivityPage';

/* eslint-disable react/prefer-stateless-function */
class ProgressPage extends Component {
  render() {
    const {
      hasNewBonuses,
      hasUnreadWarnings,
    } = this.props;
    const benefitsClassName = classNames({ 'new new--usd': hasNewBonuses });
    const warningsClassName = classNames({ 'new new--warnings': hasUnreadWarnings });
    return (
      <div>
        <div>
          <Nav sub>
            <NavLink className={benefitsClassName} to="benefits">Benefits</NavLink>
            <NavLink className={warningsClassName} to="activity">Group activity</NavLink>
          </Nav>
          <Routes>
            <Route path="benefits" element={<ProgressBenefitsPage />} />
            <Route path="activity/*" element={<ProgressGroupActivityPage />} />
            <Route path="*" element={<Navigate to="benefits" replace />} />
          </Routes>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, notification } = state;
  const isLoading = !user._id;
  const cntBonusesTask = notification.cntRewardTaskInprogress;
  const amountBonuses = notification.amountRewardTaskInprogress;
  const hasNewBonuses = getHasCntBonusesTask(state);
  const hasUnreadWarnings = getHasUnreadWarnings(state);

  return {
    user,
    isLoading,
    cntBonusesTask,
    amountBonuses,
    hasNewBonuses,
    hasUnreadWarnings,
  };
};

export default withRouter(connect(mapStateToProps)(ProgressPage));
