import React from 'react';

import { getGroupName } from 'utils/groups';

import ButtonAccept from './ButtonAccept';
import ButtonAcceptNT from './ButtonAcceptNT';
import ButtonRejectChat from './ButtonRejectChat';
import ButtonReserveOrder from './ButtonReserveOrder';
import ButtonAcceptDisabled from './ButtonAcceptDisabled';
import ButtonJoinChatTooltip from './ButtonJoinChatTooltip';
import ButtonAcceptDisabledNT from './ButtonAcceptDisabledNT';
import { renderDescriptionReserve, renderDescriptionTag } from './utils';

import { MAX_PROGRESS } from './constants';

const OrderAction = ({ order, user, isNTorder, canReserve, isStatusLoading, isDisabledJoinChat, isDisabledReserve, currentWriterInChat, isDisabledAccept, withToolTipDisabled, cannotReserveByGroupMaxOrderCount, groupMaxOrderCount, onSetReserved, onRejectChat, renderJoinChatTooltip, onJoinChatWithEvent, onAcceptConfirm, onAcceptWithEvent }) => {

  const renderNTButtons = () => (
    <>
      {currentWriterInChat ? <ButtonRejectChat onRejectChat={onRejectChat} /> : <ButtonJoinChatTooltip onJoinChatWithEvent={onJoinChatWithEvent} isDisabledJoinChat={isDisabledJoinChat} order={order} user={user} />}
      {isDisabledAccept ? <ButtonAcceptDisabledNT isDisabled onAcceptConfirm={onAcceptConfirm} /> : <ButtonAcceptNT onAcceptConfirm={onAcceptConfirm} />}
    </>
  );

  const renderButtons = () => (
    withToolTipDisabled ? <ButtonAcceptDisabled isDisabled={isDisabledAccept || cannotReserveByGroupMaxOrderCount} onAcceptWithEvent={onAcceptWithEvent()} /> : <ButtonAccept isDisabled={isDisabledAccept || cannotReserveByGroupMaxOrderCount} onAcceptWithEvent={onAcceptWithEvent} />
  );


  return (
    <div>
      <div className="order-tab-actions row vertical space-between">
        <div className="col-4 ta-right">
          <ButtonReserveOrder isDisabled={isStatusLoading || isDisabledReserve || cannotReserveByGroupMaxOrderCount} setReserved={onSetReserved} />
          {isNTorder ? renderNTButtons() : renderButtons()}
        </div>
      </div>

      <div className="row vertical space-between">
        {renderDescriptionTag(order.custom_tags)}
        {!cannotReserveByGroupMaxOrderCount &&
          renderDescriptionReserve(canReserve, isNTorder)
        }
        {cannotReserveByGroupMaxOrderCount &&
          <div className="bg-context-wrap">
            <p className="bg-context bg-warning">{getGroupName(user.group_name)} cannot have more than {groupMaxOrderCount || MAX_PROGRESS} orders in progress at the same time.</p>
          </div>
        }
      </div>
    </div>
  );
};

export default OrderAction;
