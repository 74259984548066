import moment from 'moment-timezone';
import { urls } from './../../config';
import authFetch from './../../utils/authFetch';

export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST';
export const NOTIFICATION_RECEIVE = 'NOTIFICATION_RECEIVE';
export const NOTIFICATION_SET_MESSAGE_READ = 'NOTIFICATION_SET_MESSAGE_READ';
export const NOTIFICATION_SET_FINANCE_READ = 'NOTIFICATION_SET_FINANCE_READ';
export const NOTIFICATION_SET_UPDATE_READ = 'NOTIFICATION_SET_UPDATE_READ';

const notificationRequest = () => ({
  type: NOTIFICATION_REQUEST,
});

const notificationReceive = data => ({
  type: NOTIFICATION_RECEIVE,
  data,
});

const notificationFetch = () => (dispatch) => {
  dispatch(notificationRequest());
  return authFetch(urls.notification)
    .then((json) => {
      const data = {
        unreadMessageItems: (json.messages_list || []).reduce((result, item) => {
          result[item._id] = item;
          return result;
        }, {}),
        unreadFinanceItems: (json.finances_list || []).reduce((result, item) => {
          result[item._id] = item;
          return result;
        }, {}),
        unreadUpdateItems: json.updates || 0,
        cntRewardTaskInprogress: json.tasks_inprogress_reward_cnt || 0,
        amountRewardTaskInprogress: json.tasks_inprogress_reward_amount || 0,
        cntTaskInprogress: json.tasks_inprogress || 0,
        unreadWarnings: json.notices || 0,
      };
      dispatch(notificationReceive(data));
      return Promise.resolve();
    })
    .catch(er => console.error(er));
};

const notificationShouldFetch = (state) => {
  const { notification } = state;
  if (notification.isFetching) {
    return false;
  }
  if (notification.updatedAt && moment().diff(notification.updatedAt, 'seconds') > 30) {
    return true;
  }
  return notification.didInvalidate;
};

export const notificationFetchIfNeeded = () => (dispatch, getState) => {
  if (!notificationShouldFetch(getState())) {
    return Promise.resolve();
  }
  return dispatch(notificationFetch());
};

export const notificationSetMessageRead = messageId => ({
  type: NOTIFICATION_SET_MESSAGE_READ,
  messageId,
});

export const notificationSetFinanceRead = messageId => ({
  type: NOTIFICATION_SET_FINANCE_READ,
  messageId,
});

export const notificationSetUpdateRead = messageId => ({
  type: NOTIFICATION_SET_UPDATE_READ,
  messageId,
});
