import moment from 'moment-timezone';

const formatTime = (value, formatType, isCustom) => {
  if (!value) {
    return '';
  }
  const momentObj = moment.isMoment(value) ? value : moment(value);

  if (isCustom) {
    return momentObj.format(formatType);
  }
  switch (formatType) {
    case 'slash':
      return momentObj.format('MM/DD/YY, h:mm A');
    case 'slash_short':
      return momentObj.format('MM/DD');
    case 'short':
      return momentObj.format('DD.MM.YY, h:mm A');
    case 'd':
      return momentObj.format('ll');
    case 'dy':
      return momentObj.format('MMM D, YYYY');
    case 'dt':
      return momentObj.format('MMM D, h:mm A');
    case 'dty':
      return momentObj.format('DD MMM YY, hh:mm');
    case 't':
      return momentObj.format('h:mm A');
    case 'dDay':
      return momentObj.format('D MMMM');
    case 'dDayName':
      return momentObj.format('dddd, D MMMM');
    case 'fullmonth':
      return momentObj.format('MMMM D, h:mm A');
    case 'dtnl':
      return momentObj.format('MMM D, [\r\n] h:mm A');
    case 'ydt':
      return momentObj.format('MMM D, YYYY / h:mm A');
    case 'ydtc':
      return momentObj.format('MMM D, YYYY [\r\n] h:mm A');
    case 'tt':
      return momentObj.format('h:mm A');
    case 'ago':
      return `${momentObj.format('ll / h:mm A')} (${momentObj.fromNow()})`;
    case 'agot':
      return `${momentObj.format('ll / h:mm A')}`;
    case 'ago1':
      return momentObj.fromNow();
    case 'ago1nl':
      return momentObj.fromNow().format('MMM D, [\r\n] h:mm A');
    default:
      return momentObj.format('lll');
  }
};

export default formatTime;
