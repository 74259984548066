import ReactDomServer from 'react-dom/server';
import { useDispatch } from 'react-redux';

import { closeModalComponent } from 'store/modals/actions';

import Icon from 'components/Icon';

import InfoComponent from 'components/agreement/InfoModal/InfoComponent';
import modalList from 'constants/modalList';

const RestrictedAccessModal = ({ texts }) => {
  const dispatch = useDispatch();

  const getCustomHtml = () => {
    const icon = ReactDomServer.renderToString(<Icon styles={{ width: 24, height: 24, fill: '#FC694F' }} iconName="warning-amber" />);
    let errorText = '';

    if (Array.isArray(texts)) {
      errorText = texts.map(t => `<div class="mt10">${t}</div>`).join('');
    } else {
      errorText = `<div class="mt10">${texts || 'Some error'}</div>`;
    }

    const errorModalText = [
      '<div class="row column_gap_10 vertical mb24">',
      `${icon}`,
      '<div class="info-modal-container__header info-modal-container__header--error mb0">Restricted access</div>',
      '</div>',
      '<div class="info-modal-container__text">',
      `${errorText}`,
      '</div>',
    ];
    return errorModalText.join('');
  };

  const closeModal = () => {
    dispatch(closeModalComponent(modalList.RestrictedAccess.name));
  };

  return (
    <InfoComponent customHtml={{ html: getCustomHtml() }} btnText="Got it" onNextClick={closeModal} />
  );
};

export default RestrictedAccessModal;
