import React from 'react';

import { Card } from '@connect/connect-ui-js';

import './index.scss';

const ChatClosed = ({ msgData, getPic }) => (
  <Card data={msgData} getPic={getPic}>
    <Card.Main>
      <div className="chat-closed-card">
        <div className="row vertical">
          <i className="chat-closed-card__header-icon" />
          <h3 className="chat-closed-card__header">Chat closed</h3>
        </div>
        <p className="chat-closed-card__title">Thank you for completing this task!</p>
      </div>
    </Card.Main>
    <Card.Footer data={msgData} />
  </Card>
);

export default ChatClosed;
