import React, { Component } from 'react';

import classNames from './../utils/classNames';

const noScrollClassName = 'modal-no-scroll';
class Modal extends Component {
  constructor(props) {
    super(props);

    this.onWrapClick = this.onWrapClick.bind(this);
  }

  componentDidMount() {
    if (this.props.mobile) return;

    document.body.classList.add(noScrollClassName);
    document.documentElement.style.overflowY = 'hidden';
  }

  componentWillUnmount() {
    document.body.classList.remove(noScrollClassName);
    document.documentElement.style.overflowY = 'auto';
  }

  onWrapClick() {
    const { onClose, notHandleOutside, isDisabled = false } = this.props;

    if (isDisabled) return;

    if (!onClose || notHandleOutside) {
      return;
    }
    onClose();
  }

  onModalClick(event) {
    event.stopPropagation();
  }

  render() {
    const {
      wide, small, children, className, isUpper, isDisabled = false, wrapperClassName = null, innerRef,
    } = this.props;
    const customContainerClass = Array.isArray(className) ? className.join(' ') : className;
    const containerClassName = classNames('modal-container', { 'modal-wide': wide }, { 'modal-sm': small }, customContainerClass);
    const wrapClass = classNames('modal-wrap', { 'wrap-upper': isUpper, disabled: isDisabled });
    let Wrapper = null;
    if (wrapperClassName) {
      Wrapper = () => (
        <div className={wrapperClassName} ref={innerRef}>
          <div className={containerClassName} onClick={this.onModalClick}>
            {children}
          </div>
        </div>);
    }

    return (
      <div className={wrapClass} onClick={this.onWrapClick}>
        {
          wrapperClassName ?
            <Wrapper /> :
            <div className={containerClassName} onClick={this.onModalClick} ref={innerRef}>
              {children}
            </div>
        }

      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (<Modal
  innerRef={ref}
  {...props}
/>));
