import React, { useMemo } from 'react';
import moment from 'moment-timezone';

import { Card } from '@connect/connect-ui-js';

import RevisionRequested from '../RevisionRequested';

import './index.scss';

const OrderCompleted = ({
  msgData, getPic, order, showTab,
}) => {
  const { has_revision_request, writer_revision_complete_at = '' } = order;

  const { time } = msgData;

  const isRevisionOver = useMemo(() => moment(time).isSameOrAfter(writer_revision_complete_at), [writer_revision_complete_at]);

  if (has_revision_request || (writer_revision_complete_at && !isRevisionOver)) {
    return (<RevisionRequested msgData={msgData} getPic={getPic} showTab={showTab} />);
  }

  return (
    <Card data={msgData} getPic={getPic}>
      <Card.Main>
        <div className="order-completed-card">
          <div className="row vertical">
            <i className="order-completed-card__header-icon" />
            <h3 className="order-completed-card__header">You completed the task!</h3>
          </div>
          <p className="order-completed-card__text">The chat will be open for the next <strong>24 hours</strong> and the student can ask additional questions about current request.</p>
          <p className="order-completed-card__text">The student can request a revision of this task if he is not happy with the result.</p>
        </div>
      </Card.Main>
      <Card.Footer data={msgData} />
    </Card>
  );
};

export default OrderCompleted;
