import React, { Component } from 'react';
import { UnmountClosed } from 'react-collapse';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import Icon from 'components/Icon';
import Emoji from 'components/Emoji';
import FilesPreview from 'components/FilesPreview';

import modalList from 'constants/modalList';

import { STATUS_REVISION, ordersDetailsFetch, ordersSetStatusRevisionConfirm } from 'store/orders/actions';
import { setPlugin } from 'store/plugins/actions';
import { addModalComponent } from 'store/modals/actions';

import utils from 'utils';
import { onDownloadAll as onDownloadAllHandler } from 'utils/downloadFile';
import classNames from 'utils/classNames';
import getFilePlagLink from 'utils/getFilePlagLink';
import getFileGroupPlagStatus from 'utils/getFileGroupPlagStatus';

import { isRevisionOrderExpired as isRevisionOrderExpiredMethod } from 'components/order/utils';
import UploadFilesModal from 'components/order/UploadFilesModal';

import { REPORT_SUB_TYPE, REPORT_TYPE } from 'components/WriterTicketing/constants/writerTicketing';

import DeselectControls from 'components/order/OrderFileList/DeselectControls';
import Tooltip from 'components/Tooltip';

import FinalPaperFileRow from 'components/order/FinalPaperFileRow';

class OrderRevisionPaperList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      selectedFiles: [],
      downloadFetching: false,
      plagStatus: null,
      disableRevisionCompete: false,
      isOrderHalfRevision: false,
      isRevisionOrderExpired: false,
    };

    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.onDownloadAll = this.onDownloadAll.bind(this);
    this.clearSelect = this.clearSelect.bind(this);
    this.openFilePreviewHandler = this.openFilePreviewHandler.bind(this);
    this.fileRowClick = this.fileRowClick.bind(this);
    this.renderPlagReport = this.renderPlagReport.bind(this);
    this.setRevisionCompletedHandler = this.setRevisionCompletedHandler.bind(this);
    this.setRevisionCompletedHandler = this.setRevisionCompletedHandler.bind(this);
    this.onHalfRevisionConfirm = this.onHalfRevisionConfirm.bind(this);
    this.renderUploadIssueBanner = this.renderUploadIssueBanner.bind(this);
  }

  componentDidMount() {
    const { fileList: fileList = [] } = this.props;
    const plagStatus = getFileGroupPlagStatus(fileList);

    this.setState({
      plagStatus,
      ...this.getRevisionOrderRestrictions(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { fileList: prevFileList = [], isStatusLoading: prevIsStatusLoading } = prevProps;
    const { disableRevisionCompete } = prevState;
    const { fileList: fileList = [], isStatusLoading } = this.props;

    if (fileList.length && (fileList.length !== prevFileList.length || JSON.stringify(fileList) !== JSON.stringify(prevFileList))) {
      const plagStatus = getFileGroupPlagStatus(fileList);
      this.setState({
        plagStatus,
      });
    }

    if (disableRevisionCompete && prevIsStatusLoading && !isStatusLoading) {
      this.setState({
        disableRevisionCompete: false,
      });
    }

    if (prevProps.order !== this.props.order) {
      this.setState({
        ...this.getRevisionOrderRestrictions(),
      });
    }
  }

  onDownloadAll(e) {
    if (e) e.stopPropagation();

    const { fileList, fileWebhook, orderNumber } = this.props;
    const { selectedFiles } = this.state;
    this.setState({ downloadFetching: true });
    onDownloadAllHandler({
      fileList,
      fileWebhook,
      orderNumber,
      selectedFiles,
    })
      .finally(() => {
        this.setState({ downloadFetching: false });
        this.clearSelect();
      });
  }

  onChange() {
    const {
      addModal, order, ordersDetailsGet, uploadOrderFiles,
    } = this.props;
    const { completed = false, number } = order || {};
    const dataModal = modalList.UploadFiles;

    addModal(<UploadFilesModal
      order={order}
      group="paper"
      tags={['revision']}
      header="Upload revised files"
      hidePlagText={completed}
      isRevision
      text="Please upload ALL revised files required in the order. You DO NOT need to upload duplicates of final files, if there were no changes made to them as a part of revision. You won't be able to upload any additional files after the revision is submitted. If uploaded revised files are irrelevant or incomplete, disciplinary action will be taken."
      uploadOrderFiles={uploadOrderFiles}
      uploadOrderFilesCallback={() => {
        ordersDetailsGet(number);
      }}
    />, dataModal);
  }

  onHalfRevisionConfirm() {
    const { onOrdersSetStatusRevisionConfirm, order } = this.props;
    const { number } = order;

    onOrdersSetStatusRevisionConfirm(number);
  }

  getRevisionOrderRestrictions() {
    const { order } = this.props;
    const isOrderHalfRevision = utils.isOrderHalfRevision(order);
    const isOrderHalfRevisionConfirmed = utils.isOrderHalfRevisionConfirmed(order);

    return {
      isOrderHalfRevision,
      isRevisionOrderExpired: isRevisionOrderExpiredMethod(order),
      isOrderHalfRevisionConfirmed,
    };
  }

  fileRowClick(e, file) {
    e.preventDefault();
    e.stopPropagation();
    this.openFilePreviewHandler(e, file._id);
  }

  clearSelect(e) {
    if (e) e.stopPropagation();

    this.setState({ selectedFiles: [] });
  }

  selectFile(e, id) {
    e.stopPropagation();
    const { selectedFiles } = this.state;

    if (selectedFiles.includes(id)) {
      this.setState({ selectedFiles: selectedFiles.filter(file => file !== id) });
      return;
    }

    this.setState({ selectedFiles: [...selectedFiles, id] });
  }

  toggle() {
    this.setState(state => Object.assign({}, state, {
      isOpen: !state.isOpen,
    }));
  }

  openFilePreviewHandler(e, fileID) {
    const {
      openFilePreview, fileList, orderNumber, fileWebhook, addModalMobile,
    } = this.props;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const sortedList = fileList
      .filter(file => !file.deleted)
      .map(file => ({ ...file, id: file._id }));

    const activeIndex = sortedList.findIndex(file => file.id === fileID);
    const isMobile = utils.detectMob();

    if (isMobile) {
      addModalMobile(<FilesPreview docs={sortedList} activeIndex={activeIndex} />, {
        overFlowHidden: true,
      });
      return;
    }

    openFilePreview({
      plugin: 'FilePreviewPlugin',
      data: {
        docs: sortedList,
        orderNumber,
        activeIndex,
        fileWebhook,
      },
    });
  }

  setRevisionCompletedHandler() {
    const { disableRevisionCompete } = this.state;
    const { setRevisionCompleted } = this.props;

    if (disableRevisionCompete) return;

    this.setState({ disableRevisionCompete: true });

    setRevisionCompleted();
  }

  renderUploadIssueBanner() {
    const {
      order, status, fileList, onOpenIssueForm,
    } = this.props;
    const { completed = false } = order || {};

    const onOpenIssueFormHandler = (e) => {
      onOpenIssueForm(e, {
        typeIssue: REPORT_TYPE.IVE_FACED_AN_ISSUE_WHEN_ORDER_WAS_ALREADY_COMPLETED,
        subTypeIssue: REPORT_SUB_TYPE.ISSUE_WITH_FINAL_FILES,
      });
    };

    if (status !== STATUS_REVISION || !fileList.length || completed) return null;

    return (
      <div className="bg-context bg-info mb15">If you uploaded incorrect or incomplete set of final files, reach out to Support Team and report <a onClick={onOpenIssueFormHandler}><strong>Issue with final files</strong></a> ASAP.</div>
    );
  }

  renderPlagReport() {
    const {
      fileList,
      order,
      status,
    } = this.props;

    const { plagStatus } = this.state;
    const { completed = false } = order;

    if (!fileList.length || status !== STATUS_REVISION || completed) return null;

    switch (plagStatus) {
      case 'plagiate':
        return (
          <div className="bg-context bg-danger mb15">Plagiarism found. Check plag report(s) for instructions.</div>
        );
      case 'notfound':
        return (
          <div className="bg-context bg-success mb15">
            <Emoji id="like" /> Good job! No plagiarism found. Your order will automatically be completed.
          </div>
        );
      case 'onCheck':
        return (
          <div className="bg-context bg-warning mb15">
            You have uploaded Final paper file(s), please wait for plagiarism check. It could take from 10 min to 2 hours. After plagiarism check you’ll get message about result and further steps.
          </div>
        );
      default:
        return null;
    }
  }

  renderConfirmRevisionBtn() {
    const { isRevisionOrderExpired, isOrderHalfRevisionConfirmed, isOrderHalfRevision } = this.state;

    const { isStatusLoading, order } = this.props;

    const {
      has_paid_revision_parallels: hasPaidRevisionParallels = false,
    } = order;

    if (!isOrderHalfRevision) return null;

    if (isOrderHalfRevisionConfirmed) {
      return (<div className="inline-block mr10 as-green-text">Working on revision confirmed</div>);
    }

    const isConfirmRevisionDisabled = isRevisionOrderExpired || hasPaidRevisionParallels;
    const tooltip = isConfirmRevisionDisabled ? 'Unfortunately, you\'ve run out of time to confirm working on revision. Your case will be reviewed by the Order Support Team.' : 'Please, confirm working on revision. Inactivity may lead to fine and/or disciplinary action.';

    return (
      <Tooltip
        className="top-btn-tooltip order-tooltip order-tooltip--left order-tooltip--w160"
        content={tooltip}
      >
        <button
          onClick={this.onHalfRevisionConfirm}
          disabled={isConfirmRevisionDisabled || isStatusLoading}
          className="btn btn-light mr10"
        >
          Confirm working on revision
        </button>
      </Tooltip>
    );
  }

  render() {
    const {
      fileList, onResize, status, order, plagFileList,
      isStatusLoading,
    } = this.props;
    const {
      isOpen, selectedFiles, downloadFetching,
      isRevisionOrderExpired, isOrderHalfRevision,
      disableRevisionCompete,
    } = this.state;
    const {
      jabber_node: jabberNode, has_paid_revision_parallels: hasPaidRevisionParallels = false,
    } = order;
    const isNTorder = utils.isNTorder(order);
    const isMobile = utils.detectMob();

    if (!fileList.length && !isNTorder) {
      if (status !== STATUS_REVISION) return null;

      if (isMobile) {
        return (
          <div className="order-report-issue">
            <Icon styles={{ width: '14px', height: '14px' }} iconName="instructions-under-review" />
            <p>Unfortunately, uploading revised files is possible only from desktop version of the platform.</p>
          </div>
        );
      }

      const isConfirmRevisionDisabled = isRevisionOrderExpired || hasPaidRevisionParallels;

      return (
        <div className="ta-right mt20">
          {this.renderConfirmRevisionBtn()}
          <button className="btn btn-bright" onClick={this.onChange}>Upload revised files</button>
          {isConfirmRevisionDisabled && isOrderHalfRevision && <div className="mt15"><strong>You've run out of time to confirm working of revision</strong></div>}
        </div>
      );
    }

    const sortedList = fileList.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));
    const filesStr = `${sortedList.length} ${sortedList.length === 1 ? 'file' : 'files'}`;

    if (jabberNode && status !== STATUS_REVISION) return null;

    if (jabberNode && status === STATUS_REVISION) {
      return (
        <>
          <div className="order-tab-content row vertical flex-end mt20">
            {isNTorder && this.renderConfirmRevisionBtn()}
            <button className="btn btn-bright" disabled={isStatusLoading || disableRevisionCompete} onClick={this.setRevisionCompletedHandler} type="button">Complete revision</button>
          </div>
        </>
      );
    }

    return (
      <div className="order-tab-collapse">
        <div className={classNames('order-tab-collapse-head row space-between')} onClick={this.toggle}>
          <div>
            <Icon className="svg-icon" iconName="clip" />
            <span className="order-tab-head-name">Revised papers ({filesStr})</span>
          </div>
          {isOpen
              ? <span className="arrow-down active" />
              : <span className="arrow-down" />
            }
          {
              isOpen && !!selectedFiles.length && <DeselectControls selectedFiles={selectedFiles} onDownloadAll={this.onDownloadAll} downloadFetching={downloadFetching} clearSelect={this.clearSelect} />
            }
        </div>
        <UnmountClosed isOpened={isOpen} className="order-tab-collapse-body" onRender={onResize}>
          <div className="order-tab-content">
            {status === STATUS_REVISION &&
              <div className="row space-between vertical mb10">
                <div>
                  {filesStr} uploaded
                </div>
              </div>
            }
            {this.renderUploadIssueBanner()}
            {this.renderPlagReport()}
            <div>
              {sortedList.map(file =>
              (<FinalPaperFileRow
                selectedFiles={selectedFiles}
                selectFile={this.selectFile}
                file={file}
                key={file._id}
                openFilePreviewHandler={this.openFilePreviewHandler}
                fileRowClick={this.fileRowClick}
                plagFileHref={getFilePlagLink(file, plagFileList)}
              />))}
            </div>
          </div>
          {sortedList.length > 1 &&
            <div className="row flex-end mb10">
              <button
                className={classNames('btn btn-light btn-md btn-download-all', { hide: !!selectedFiles.length })}
                onClick={this.onDownloadAll}
                disabled={downloadFetching}
              >
                {downloadFetching ? 'Loading...' : 'Download all'}
              </button>
              <div className="clear" />
            </div>
          }
        </UnmountClosed>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openFilePreview: data => dispatch(setPlugin(data)),
  addModal: (component, modalOptions = {}) => dispatch(addModalComponent({ component, ...modalOptions })),
  addModalMobile: (component, modalOptions) => dispatch(addModalComponent(component, modalOptions)),
  ordersDetailsGet: number => dispatch(ordersDetailsFetch(number)),
  onOrdersSetStatusRevisionConfirm: number => dispatch(ordersSetStatusRevisionConfirm(number)),
});

export default connect(null, mapDispatchToProps)(OrderRevisionPaperList);
