export const ORDER_TYPE_RUBICS = {
  writing: {
    dataInstructionsFollowed: {
      id: 'dataInstructionsFollowed',
      name: 'Instructions followed',
      data: {
        plagiarism_free: {
          id: 'plagiarism_free',
          name: 'Plagiarism free',
          tooltip: 'The information that is not common knowledge is cited. Original writing is minimum 70%. Direct quotes do not exceed 10% of the required word count.',
          score: [0.0001, 0.0002, 0.0003, 0.0004, 0],
          score_view: [0, 0, 0, 0, 0],
        },
        content: {
          id: 'content',
          name: 'Content',
          tooltip: 'The paper meets the Client’s requirements and does not contain any irrelevant or generic content.',
          score: [1.5, 1.2, 1.05, 0.9, 0],
          score_view: [1.5, 1.2, 1.05, 0.9, 0],
        },
        sources: {
          id: 'sources',
          name: 'Sources',
          tooltip: 'The required by the Client, relevant (published within the last 5-7 years) and reliable sources are properly incorporated.',
          score: [1, 0.8, 0.7, 0.6, 0],
          score_view: [1, 0.8, 0.7, 0.6, 0],
        },
        word_count: {
          id: 'word_count',
          name: 'Word count',
          tooltip: 'It is counted from the introduction to conclusion. Title and reference pages, as well as footnotes are excluded.',
          score: [0.5, 0.4, 0.35, 0.3, 0],
          score_view: [0.5, 0.4, 0.35, 0.3, 0],
        },
      },
    },
    dataLanguageAndFormat: {
      name: 'Language and format',
      id: 'dataLanguageAndFormat',
      data: {
        structure: {
          id: 'structure',
          name: 'Structure',
          tooltip: 'Thesis statement serves as a roadmap of the paper. Each paragraph is devoted to one idea. The paper is coherent and cohesive.',
          score: [0.5, 0.4, 0.35, 0.3, 0],
          score_view: [0.5, 0.4, 0.35, 0.3, 0],
        },
        language: {
          id: 'language',
          name: 'Language',
          tooltip: 'Sentence structure and word choice are correct. No proofreading issues.',
          score: [1, 0.8, 0.7, 0.6, 0],
          score_view: [1, 0.8, 0.7, 0.6, 0],
        },
        format: {
          id: 'format',
          name: 'Format',
          tooltip: 'Adherence to one required Format.',
          score: [0.5, 0.4, 0.35, 0.3, 0],
          score_view: [0.5, 0.4, 0.35, 0.3, 0],
        },
      },
    },
  },

  calculations: {
    calculations: {
      id: 'calculations',
      name: 'Calculations, problem solving',
      data: {
        final_answer: {
          id: 'final_answer',
          name: 'Final answer',
          tooltip: null,
          score: [1.500, 1.200, 1.050, 0.900, 0],
          score_view: [1.500, 1.200, 1.050, 0.900, 0],
        },
        explanation: {
          id: 'explanation',
          name: 'Explanation',
          tooltip: null,
          score: [1.000, 0.800, 0.700, 0.600, 0],
          score_view: [1.000, 0.800, 0.700, 0.600, 0],
        },
        formulas: {
          id: 'formulas',
          name: 'Formulas',
          tooltip: null,
          score: [1.000, 0.800, 0.700, 0.600, 0],
          score_view: [1.000, 0.800, 0.700, 0.600, 0],
        },
        language: {
          id: 'language',
          name: 'Language',
          tooltip: null,
          score: [1.000, 0.800, 0.700, 0.600, 0],
          score_view: [1.000, 0.800, 0.700, 0.600, 0],
        },
        format: {
          id: 'format',
          name: 'Format',
          tooltip: null,
          score: [0.500, 0.400, 0.350, 0.300, 0],
          score_view: [0.500, 0.400, 0.350, 0.300, 0],
        },
        plagiarism: {
          id: 'plagiarism',
          name: 'Plagiarism',
          tooltip: null,
          score: [0.0001, 0.0002, 0.0003, 0.0004, 0],
          score_view: [0, 0, 0, 0, 0],
        },
      },
    },
  },

  presentation: {
    presentation: {
      id: 'presentation',
      name: 'Presentation',
      data: {
        content: {
          id: 'content',
          name: 'Content',
          tooltip: null,
          score: [1.500, 1.200, 1.050, 0.900, 0],
          score_view: [1.500, 1.200, 1.050, 0.900, 0],
        },
        plagiarism: {
          id: 'plagiarism',
          name: 'Plagiarism',
          tooltip: null,
          score: [0.0001, 0.0002, 0.0003, 0.0004, 0],
          score_view: [0, 0, 0, 0, 0],
        },
        visual: {
          id: 'visual',
          name: 'Visual',
          tooltip: null,
          score: [1.000, 0.800, 0.700, 0.600, 0],
          score_view: [1.000, 0.800, 0.700, 0.600, 0],
        },
        language: {
          id: 'language',
          name: 'Language',
          tooltip: null,
          score: [1.000, 0.800, 0.700, 0.600, 0],
          score_view: [1.000, 0.800, 0.700, 0.600, 0],
        },
        format: {
          id: 'format',
          name: 'Format',
          tooltip: null,
          score: [0.500, 0.400, 0.350, 0.300, 0],
          score_view: [0.500, 0.400, 0.350, 0.300, 0],
        },
        slide_count: {
          id: 'slide_count',
          name: 'Slide count',
          tooltip: null,
          score: [0.500, 0.400, 0.350, 0.300, 0],
          score_view: [0.500, 0.400, 0.350, 0.300, 0],
        },
        sources: {
          id: 'sources',
          name: 'Sources',
          tooltip: null,
          score: [0.500, 0.400, 0.350, 0.300, 0],
          score_view: [0.500, 0.400, 0.350, 0.300, 0],
        },
      },
    },
  },

  editing: {
    editing: {
      id: 'editing',
      name: 'Editing',
      data: {
        content: {
          id: 'content',
          name: 'Content',
          tooltip: null,
          score: [1.500, 1.200, 1.050, 0.900, 0],
          score_view: [1.500, 1.200, 1.050, 0.900, 0],
        },
        language: {
          id: 'language',
          name: 'Language',
          tooltip: null,
          score: [1.000, 0.800, 0.700, 0.600, 0],
          score_view: [1.000, 0.800, 0.700, 0.600, 0],
        },
        structure: {
          id: 'structure',
          name: 'Structure',
          tooltip: null,
          score: [1.000, 0.800, 0.700, 0.600, 0],
          score_view: [1.000, 0.800, 0.700, 0.600, 0],
        },
        sources: {
          id: 'sources',
          name: 'Sources',
          tooltip: null,
          score: [1.000, 0.800, 0.700, 0.600, 0],
          score_view: [1.000, 0.800, 0.700, 0.600, 0],
        },
        format: {
          id: 'format',
          name: 'Format',
          tooltip: null,
          score: [0.500, 0.400, 0.350, 0.300, 0],
          score_view: [0.500, 0.400, 0.350, 0.300, 0],
        },
        plagiarism: {
          id: 'plagiarism',
          name: 'Plagiarism',
          tooltip: null,
          score: [0.0001, 0.0002, 0.0003, 0.0004, 0],
          score_view: [0, 0, 0, 0, 0],
        },
      },
    },
  },

  proofreading: {
    proofreading: {
      id: 'proofreading',
      name: 'Proofreading',
      data: {
        language: {
          id: 'language',
          name: 'Language',
          tooltip: null,
          score: [4.000, 3.200, 2.800, 2.400, 0],
          score_view: [4.000, 3.200, 2.800, 2.400, 0],
        },
        format: {
          id: 'format',
          name: 'Format',
          tooltip: null,
          score: [1.000, 0.800, 0.700, 0.600, 0],
          score_view: [1.000, 0.800, 0.700, 0.600, 0],
        },
      },
    },
  },

  quiz: {
    quiz: {
      id: 'quiz',
      name: 'Quiz',
      data: {
        correct_answers: {
          id: 'correct_answers',
          name: 'Correct Answers',
          tooltip: null,
          score: [5.000, 4.000, 3.000, 2.000, 0],
          score_view: [5.000, 4.000, 3.000, 2.000, 0],
        },
      },
    },
  },
};

export const ORDER_TYPE_RUBICS_REVISION_ORDER = {
  'Revision by new Freelancer': {
    'Revision by new Freelancer': {
      id: 'Revision by new Freelancer',
      name: 'Revision by new Freelancer',
      data: {
        final_answer: {
          id: 'final_answer',
          name: 'Final answer',
          tooltip: null,
          score: [1.000, 0.900, 0.850, 0.800, 0],
          score_view: [1.000, 0.900, 0.850, 0.800, 0],
        },
        Explanation: {
          id: 'Explanation',
          name: 'Explanation',
          tooltip: null,
          score: [0.500, 0.400, 0.350, 0.300, 0],
          score_view: [0.500, 0.400, 0.350, 0.300, 0],
        },
        Formulas: {
          id: 'Formulas',
          name: 'Formulas',
          tooltip: null,
          score: [0.500, 0.400, 0.350, 0.300, 0],
          score_view: [0.500, 0.400, 0.350, 0.300, 0],
        },
        Language: {
          id: 'Language',
          name: 'Language',
          tooltip: null,
          score: [0.500, 0.400, 0.350, 0.300, 0],
          score_view: [0.500, 0.400, 0.350, 0.300, 0],
        },
        Format: {
          id: 'Format',
          name: 'Format',
          tooltip: null,
          score: [0.200, 0.100, 0.05, 0.0004, 0],
          score_view: [0.200, 0.100, 0.05, 0, 0],
        },
        Content: {
          id: 'Content',
          name: 'Content',
          tooltip: null,
          score: [1.000, 0.900, 0.850, 0.800, 0],
          score_view: [1.000, 0.900, 0.850, 0.800, 0],
        },
        Visual: {
          id: 'Visual',
          name: 'Visual',
          tooltip: null,
          score: [0.200, 0.100, 0.05, 0.0004, 0],
          score_view: [0.200, 0.100, 0.05, 0, 0],
        },
        Sources: {
          id: 'Sources',
          name: 'Sources',
          tooltip: null,
          score: [0.300, 0.200, 0.15, 0.1, 0],
          score_view: [0.300, 0.200, 0.15, 0.1, 0],
        },
        'Slide/word count': {
          id: 'Slide/word count',
          name: 'Slide/word count',
          tooltip: null,
          score: [0.500, 0.400, 0.350, 0.300, 0],
          score_view: [0.500, 0.400, 0.350, 0.300, 0],
        },
        Structure: {
          id: 'Structure',
          name: 'Structure',
          tooltip: null,
          score: [0.300, 0.200, 0.15, 0.1, 0],
          score_view: [0.300, 0.200, 0.15, 0.1, 0],
        },
        plagiarism: {
          id: 'plagiarism',
          name: 'Plagiarism',
          tooltip: null,
          score: [0.0001, 0.0002, 0.0003, 0.0004, 0],
          score_view: [0, 0, 0, 0, 0],
        },
      },
    },
  },
};

// export default { ORDER_TYPE_RUBICS, ORDER_TYPE_RUBICS_REVISION_ORDER };
// export default ORDER_TYPE_RUBICS;

// score: [0.0001, 0.0002, 0.0003, 0.0004, 0]
// score_view: [0, 0, 0, 0, 0]
