import { createSelector } from 'reselect';

import { sortByOrderNumber } from './../orderSorters';

const stable = require('stable');
const getOrderList = state => [
  ...state.ordersRevision.numbers.map(number => { return { number, id: state.orders[number]?._id, title: state.orders[number]?.title, mark: state.orders[number]?.mark, qa_report_score: state.orders[number]?.qa_report_score, last_writer_fine_at: state.orders[number]?.last_writer_fine_at, writer_completed: state.orders[number]?.writer_completed } }),
  ...state.ordersProgress.numbers.map(number => { return { number, id: state.orders[number]?._id, title: state.orders[number]?.title, last_writer_fine_at: state.orders[number]?.last_writer_fine_at, writer_completed: state.orders[number]?.writer_completed } }),
  ...state.ordersComplete.numbers.map(number => { return { number, id: state.orders[number]?._id, title: state.orders[number]?.title, mark: state.orders[number]?.mark, qa_report_score: state.orders[number]?.qa_report_score, last_writer_fine_at: state.orders[number]?.last_writer_fine_at, writer_completed: state.orders[number]?.writer_completed } }),
];

const ordersFiltererSorter = (orderList) => {
  let orders = orderList;
  const ordersSortedByNumber = stable(orders, sortByOrderNumber);
  return ordersSortedByNumber;
};

export const getAllMyOrdersSorted = createSelector([getOrderList], ordersFiltererSorter);

export const getTicket = state => state.ticket;
export const getTickets = state => state.tickets.tickets;
export const getNewTicketFiles = state => state.newTicketFiles;
