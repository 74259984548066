const amountToString = (value) => {
  const isNegative = value < 0;
  if (isNegative) {
    value *= -1;
  }
  value = (value / 100).toFixed(2);
  return isNegative ? `($${value})` : `$${value}`;
};

export default amountToString;
