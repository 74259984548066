import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'debounce';

import { userFileUploadFetch, userSetFieldsFetch } from 'store/user/actions';

import Icon from 'components/Icon';
import UploadButton from 'components/UploadButton';

class PersonalInfoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passport_series: '',
      passport_number: '',
      residence_address: '',
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onPassportFileChange = this.onPassportFileChange.bind(this);
    this.onAddressFileChange = this.onAddressFileChange.bind(this);
    this.debounceSave = debounce(this.debounceSave, 1000);
  }

  componentDidMount() {
    const { passport_series, passport_number, residence_address } = this.props;
    this.setState({
      passport_series,
      passport_number,
      residence_address,
    });
  }

  onPassportFileChange(event) {
    const { uploadFile } = this.props;
    const fileList = event.target.files;
    if (!fileList.length) {
      return;
    }
    uploadFile('passport_copy', fileList[0]);
  }

  onAddressFileChange(event) {
    const { uploadFile } = this.props;
    const fileList = event.target.files;
    if (!fileList.length) {
      return;
    }
    uploadFile('residence_copy', fileList[0]);
  }

  onInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    const { save } = this.props;
    const data = {
      ...this.state,
      [name]: value,
    };

    this.debounceSave(data, save);
  }

  debounceSave(data, method) {
    method(data);
  }

  render() {
    const { passport_series, passport_number, residence_address } = this.state;
    const {
      passportCopy,
      passportVerified,
      residenceCopy,
      residenceVerified,
      changeLocked,
    } = this.props;

    return (
      <div>
        <div className="row vertical person-detail-info">
          <div className="col-5 row">
            <div className="col-2">
              <h4>Passport series:</h4>
              <input type="text" name="passport_series" className="input-text" value={passport_series} onChange={this.onInputChange} disabled={passportVerified} />
            </div>
            <div className="col-2">
              <h4>Passport number:</h4>
              <input type="text" name="passport_number" className="input-text" value={passport_number} onChange={this.onInputChange} disabled={passportVerified} />
            </div>
          </div>
          <div className="col-2 btn-box">
            <UploadButton className="btn btn-bright" onChange={this.onPassportFileChange} disabled={changeLocked && passportVerified}>
              <Icon className="svg-icon" iconName="clip" />Upload file
            </UploadButton>
          </div>
          <div className="col-2 status-box">
            {passportVerified &&
              <span><Icon className="svg-icon success" iconName="check-fill" /><span>Verified</span></span>
            }
            {!passportVerified && !!passportCopy &&
              <span><Icon className="svg-icon" iconName="watch" /><span>In progress</span></span>
            }
          </div>
        </div>

        {!!passportCopy &&
          <div className="upload-file-box">
            <div className="upload-file-item">
              <a target="_blank" rel="noopener noreferrer" href={!passportCopy.isFetching && passportCopy.location}><span>{passportCopy.name}</span></a>
            </div>
          </div>
        }

        <div className="row vertical person-detail-info">
          <div className="col-5">
            <h4>Residence address:</h4>
            <input type="text" name="residence_address" className="input-text" value={residence_address} disabled={residenceVerified} onChange={this.onInputChange} />
          </div>
          <div className="col-2 btn-box">
            <UploadButton className="btn btn-bright" onChange={this.onAddressFileChange} disabled={changeLocked && residenceVerified}>
              <Icon className="svg-icon" iconName="clip" />Upload file
            </UploadButton>
          </div>
          <div className="col-2 status-box">
            {residenceVerified &&
              <span><Icon className="svg-icon success" iconName="check-fill" /><span>Verified</span></span>
            }
            {!residenceVerified && !!residenceCopy &&
              <span><Icon className="svg-icon" iconName="watch" /><span>In progress</span></span>
            }
          </div>
        </div>
        {!!residenceCopy &&
          <div className="upload-file-box">
            <div className="upload-file-item">
              <a target="_blank" rel="noopener noreferrer" href={!residenceCopy.isFetching && residenceCopy.location}><span>{residenceCopy.name}</span></a>
            </div>
          </div>
        }
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    passport_series: user.passport_series,
    passport_number: user.passport_number,
    passportCopy: user.passport_copy,
    passportVerified: user.passport_verified,
    residence_address: user.residence_address,
    residenceCopy: user.residence_copy,
    residenceVerified: user.profile_residence_verified,
    changeLocked: user.payment_change_locked,
  };
};

const mapDispatchToProps = dispatch => ({
  uploadFile: (fileType, file) => dispatch(userFileUploadFetch(fileType, file)),
  save: data => dispatch(userSetFieldsFetch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoForm);
