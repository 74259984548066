import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addModalComponent, closeModalComponent } from 'store/modals/actions';
import modalList from 'constants/modalList';
import { getOrderSessions, isFetching } from 'store/clientSessions/selectors';
import { sessionDelete } from 'store/clientSessions/actions';

import classNames from 'utils/classNames';

import CreateSession from './CreateSession';
import Session from './Session';

import './styles/index.scss';

const OrderClientSessions = ({ order = {}, mobile }) => {
  const writer = useSelector(state => state.user);
  const {
    _id: orderId,
    client_info: { timezone_str: clientTimeZone },
    writer_status: writerStatus,
    writer_chat_message_at: writerChatMessageAt,
  } = order;
  const { timezone: writerTimezone, _id: writerId } = writer;
  const clientSessions = useSelector(state => getOrderSessions(orderId)(state) || []).filter(session => !session.deleted && session.created_by === writerId);
  const isLoading = useSelector(isFetching);
  const dispatch = useDispatch();

  const [isOpenModal, toggleModal] = useState(false);
  const [currentSession, setCurrentSession] = useState({});

  const onToggleModal = (session) => {
    toggleModal(value => !value);

    if (!session || !session.link) {
      setCurrentSession({});
      return;
    }

    setCurrentSession({ ...session });
  };

  const onDelete = (sessionId) => {
    dispatch(sessionDelete(sessionId, orderId));
  };

  useEffect(() => {
    const dataModal = modalList.ScheduleSession;
    if (!isOpenModal) {
      return dispatch(closeModalComponent(dataModal.name));
    }
    const component = <CreateSession session={currentSession} order={order} toggleModal={onToggleModal} />;
    return dispatch(addModalComponent({ component, ...dataModal }));
  }, [isOpenModal]);

  return (
    <div className={classNames('client-sessions-container', { 'client-sessions-container--loading': isLoading })}>
      <button disabled={!writerChatMessageAt} className="btn btn-bright" onClick={onToggleModal}>Schedule a session</button>
      <ul className="client-sessions-list">
        {clientSessions.length > 0 && <h2 className="client-sessions-list__title">List of scheduled sessions:</h2>}
        {
          clientSessions.map(session => <Session mobile={mobile} writerTimezone={writerTimezone} clientTimeZone={clientTimeZone} key={session._id} session={session} onDelete={onDelete} toggleModal={onToggleModal} />)
        }
      </ul>
    </div>
  );
};

export default OrderClientSessions;
