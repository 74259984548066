import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import modalList from 'constants/modalList';

import { getModalList } from 'store/modals/selectors';

import { closeModalComponent, addModalComponent } from 'store/modals/actions';

import InfoComponent from './InfoComponent';

import './index.scss';


const InfoModal = (props) => {
  const { modalName, closeModal, customHtml = {} } = props;
  const dispatch = useDispatch();
  const { modalName: __modalName } = customHtml || {};
  const dataModal = modalList[__modalName || modalName];

  const { modalList: storeModalList } = useSelector(getModalList);

  const closeHandler = (e) => {
    closeModal && closeModal(e);

    if (!dataModal) return;

    dispatch(closeModalComponent(dataModal.name));
  };

  useEffect(() => {
    if (!dataModal) return;

    if (storeModalList.find(modal => modal.name === dataModal.name)) return;

    const component = <InfoComponent closeHandler={closeHandler} {...props} />;
    dispatch(addModalComponent({ component, ...dataModal }));
  }, []);

  if (!dataModal) {
    return <InfoComponent closeHandler={closeHandler} {...props} />;
  }

  return null;
};

export default InfoModal;
