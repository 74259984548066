import QA_TEMPLATE from './qa_template';
import RUBICS_SCORE from './rubrics_score';
import dataCriterion from './data_criterion';
import { ORDER_TYPE_RUBICS, ORDER_TYPE_RUBICS_REVISION_ORDER } from './order_type_rubrics';
import RUBICS_SWITCH_STATUS from './rubics_switch_status';

const notNullCriterion = [
  'dataInstructionsFollowed',
];

const criticalCriteria = {
  calculations: ['final_answer', 'plagiarism'],
  presentation: ['plagiarism', 'content', 'slide_count', 'sources'],
  writing: ['plagiarism_free', 'content', 'word_count', 'sources'],
  editing: ['sources', 'content', 'plagiarism'],
  proofreading: ['language'],
  quiz: ['correct_answers'],
  'Revision by new Freelancer': ['final_answer', 'Content', 'Slide/word count', 'Plagiarism'],
};

const reviseStatus = {
  revised_new: {
    id: 'revised_new',
    name: 'Revision new',
    action: 'qa_revised_new',
  },
  revised_initial: {
    id: 'revised_initial',
    name: 'Revision Initial',
    action: 'qa_revised_initial',
  },
};

const rewriteStatus = {
  rewrited_new: {
    id: 'rewrited_new',
    name: 'Rewrite new',
    action: 'qa_rewrited_new',
  },
  rewrited_initial: {
    id: 'rewrited_initial',
    name: 'Rewrite Initial',
    action: 'qa_rewrited_initial',
  },
};

const TYPE_RUBRICS = {
  Writing: 'writing',
  Other: 'writing',
  Complex: 'writing',
  Calculations: 'calculations',
  Presentation: 'presentation',
  'Proofreading & Editing': 'editing',
  Proofreading: 'proofreading',
  Editing: 'editing',
  Quiz: 'quiz',
};

const TYPE_RUBRICS_WITH_REVISION_ORDER = {
  ...TYPE_RUBRICS,
  'Revision by new Freelancer': 'Revision by new Freelancer',
};

const ALL_RUBRICS = [
  'writing',
  'calculations',
  'presentation',
  'proofreading',
  'editing',
  'quiz',
];

const ALL_RUBRICS_WITH_REVISION_ORDER = [...ALL_RUBRICS, 'Revision by new Freelancer'];

const TYPES = {
  qa_revised_new: 'qa_revised_new',
  qa_revised_initial: 'qa_revised_initial',
  qa_rewrited_initial: 'qa_rewrited_initial',
  qa_rewrited_new: 'qa_rewrited_new',
};

const ORDER_TYPE_RUBICS_WITH_REVISION_ORDER = { ...ORDER_TYPE_RUBICS, ...ORDER_TYPE_RUBICS_REVISION_ORDER }

export {
  TYPES,
  TYPE_RUBRICS,
  TYPE_RUBRICS_WITH_REVISION_ORDER,
  ALL_RUBRICS,
  ALL_RUBRICS_WITH_REVISION_ORDER,
  QA_TEMPLATE,
  reviseStatus,
  dataCriterion,
  RUBICS_SCORE,
  rewriteStatus,
  notNullCriterion,
  ORDER_TYPE_RUBICS,
  ORDER_TYPE_RUBICS_WITH_REVISION_ORDER,
  criticalCriteria,
  RUBICS_SWITCH_STATUS,
};
