import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import {
  ordersSetFilterTime,
  ordersFindSetFilterType,
  ordersSetFilterSearch,
} from 'store/ordersFind/actions';

import utils from 'utils';

import OrderTableSearch, { OrderTableSearchBtn } from 'components/OrderTableSearch';
import Dropdown from 'components/Dropdown';
import Select from 'components/Select';
import Checkbox from 'components/Checkbox';
import SubjectsSearchForm from 'components/SubjectsSearchForm';
import StatusesSearchForm from 'components/StatusesSearchForm';

class OrdersTableFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
    };

    this.onToggleSearchClick = this.onToggleSearchClick.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onChangeTypeFilter = this.onChangeTypeFilter.bind(this);
  }

  componentDidMount() {
    const { setFilterSearch } = this.props;
    setFilterSearch('');
  }

  onSearchSubmit(event) {
    event.preventDefault();
    const { searchValue } = this.state;
    const { setFilterSearch } = this.props;
    setFilterSearch(searchValue.trim());
  }

  onToggleSearchClick() {
    const { setFilterSearch, isSearch, onSearchChange } = this.props;
    this.setState(() => {
      const newIsSearch = !isSearch;
      if (!newIsSearch) {
        setFilterSearch('');
      }
      onSearchChange(newIsSearch);
    });
  }

  onInputChange(event) {
    const { target } = event;
    this.setState(state => Object.assign({}, state, {
      [target.name]: target.value,
    }));
  }

  onChangeTypeFilter(value, checked) {
    const { filter, setFilterType } = this.props;
    const newData = !checked ? [...filter.type, value] : filter.type.filter(t => t !== value);
    setFilterType(newData);
  }

  render() {
    const {
      translate,
      typeId,
      className,
      filter,
      orderTimes,
      orderTypes,
      setFilterTime,
      isSearch,
      profile,
    } = this.props;
    const { searchValue } = this.state;

    const isStem = utils.isStem(profile);

    if (isSearch) {
      return (
        <div className={className}>
          <OrderTableSearch
            onSubmit={this.onSearchSubmit}
            value={searchValue}
            onChange={this.onInputChange}
            onToggle={this.onToggleSearchClick}
          />
        </div>
      );
    }

    const filterTimeVal = !filter.time || !filter.time.length ? 'all' : filter.time;

    return (
      <div className={className}>
        <div className="row">
          <div className="col-1">
            <OrderTableSearchBtn onClick={this.onToggleSearchClick} />
          </div>
          <div className="col-4">
            <Dropdown text={translate(`timeFilter.${filter.time}`) || 'All time'} closeAlways>
              <Select className="menu menu-selectable" onChange={setFilterTime} value={filterTimeVal}>
                {orderTimes.map(item => (
                  <li key={item} value={item} className="menu-item">{translate(`timeFilter.${item}`)}</li>
                ))}
              </Select>
            </Dropdown>
          </div>
          <div className="col-4 pos-static">
            <Dropdown text={filter.subject.length ? `Custom subjects (${filter.subject.length})` : 'All subjects'} fluid>
              <SubjectsSearchForm typeId={typeId} />
            </Dropdown>
          </div>
          <div className="col-4">
            <Dropdown text={filter.type.length ? `Custom types (${filter.type.length})` : 'All types'}>
              <ul className="menu">
                {orderTypes.map((item) => {
                  const isChecked = filter.type.indexOf(item) > -1;
                  return (
                    <li key={item}>
                      <Checkbox name={item} checked={isChecked} onChange={() => this.onChangeTypeFilter(item, isChecked)}>
                        {item}
                      </Checkbox>
                    </li>
                  );
                })}
              </ul>
            </Dropdown>

          </div>
          <div className="col-4">
            {!isStem &&
              <Dropdown text={filter.statuses.length ? `Custom statuses (${filter.statuses.length})` : 'All statuses'}>
                <StatusesSearchForm typeId={typeId} />
              </Dropdown>
            }
          </div>
          <div className="col-4" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const translate = getTranslate(state.locale);
  const filter = state.ordersFind.filter[ownProps.typeId];
  const orderTimes = state.settings.orderTimesDe;
  const { orderTypes } = state.settings;

  return {
    filter,
    orderTimes,
    orderTypes,
    translate,
    profile: state.user || {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFilterTime: value => dispatch(ordersSetFilterTime(ownProps.typeId, value)),
  setFilterSearch: value => dispatch(ordersSetFilterSearch(ownProps.typeId, value)),
  setFilterType: value => dispatch(ordersFindSetFilterType(ownProps.typeId, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTableFilter);
