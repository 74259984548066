/* eslint-disable consistent-return */
import { urls } from 'config';

import storage from 'utils/localStorageHandler';

import utils from 'utils';

import authFetch from 'utils/authFetch';

import { userSetShowProposed, userFetch } from 'store/user/actions';

import { ordersProgressFetch, removeOrdersFromInProgressTable } from 'store/ordersProgress/actions';

import { removeOrdersFromTable } from 'store/ordersFind/actions';

import { clearNewOrdersNotifications } from 'store/ordersNotifications/actions';

import { orderDetailsStatusError, orderDetailsError } from './details';

import {
  ORDERS_SET_STATUS_REQUEST,
  ORDERS_SET_STATUS_RECEIVE,
} from '../constants';

const ordersSetStatusRequest = number => ({
  type: ORDERS_SET_STATUS_REQUEST,
  number,
});

const ordersSetStatusReceive = (number, details) => ({
  type: ORDERS_SET_STATUS_RECEIVE,
  number,
  details,
});

const ordersSetStatusFetch = (number, value, payload) => (dispatch, getState) => {
  const order = getState().orders[number] || {};
  const isNTorder = utils.isNTorder(order);
  const { _id: id, writer_chat_message_at = '' } = order;

  if (!id) {
    alert('id not found');
    return;
  }
  dispatch(ordersSetStatusRequest(number));
  let body = { action: value };

  if (payload) body = { ...body, ...payload };

  if (window.location.href.indexOf('suggested') > -1) {
    body.is_suggested = true;
  }

  return authFetch(`${urls.orders}/${id}`, {
    method: 'POST',
    body: JSON.stringify(body),
  }).then((json) => {
    if (value === 'request_reject') {
      json = { ...json, ...{ allow_request_reject: false } };
    }
    if (value === 'join_chat') {
      json = { ...json, ...{ writer_chat_message_at } };
      dispatch(userSetShowProposed(false));
    }
    if (value === 'join_chat' || value === 'reject_chat') {
      dispatch(userFetch(true));
    }
    dispatch(clearNewOrdersNotifications((value === 'accept' || value === 'reject'), number));
    dispatch(ordersSetStatusReceive(number, json));
    if (value === 'accept') {
      dispatch(ordersProgressFetch());
      dispatch(removeOrdersFromTable(number));
      storage.set('userViewedCount', 0);
    }
    if (value === 'writer_send' && !isNTorder) {
      dispatch(userSetShowProposed(true, 'submit_btn'));
    }
    if (value === 'request_reject') {
      dispatch(removeOrdersFromInProgressTable(number));
    }
    dispatch(userFetch(true));
  }).catch((error) => {
    console.log(error);
    if (error.status === 405 || error.status === 422) {
      dispatch(orderDetailsStatusError(number, error.error_message));
      return;
    }
    dispatch(orderDetailsError(number));
  });
};

export const ordersSetStatusReservedFetch = number => ordersSetStatusFetch(number, 'reserve');
export const ordersSetStatusRejectedFetch = number => ordersSetStatusFetch(number, 'reject');
export const ordersSetStatusAcceptedFetch = (number, payload) => ordersSetStatusFetch(number, 'accept', payload);
export const ordersSetStatusJoinChatFetch = number => ordersSetStatusFetch(number, 'join_chat');
export const ordersSetStatusRejectJoinChatFetch = (number, payload) => ordersSetStatusFetch(number, 'reject_chat', payload);
export const ordersSetStatusRevisionCompletedFetch = number => ordersSetStatusFetch(number, 'revision_complete');
export const ordersSetStatusCompletedFetch = number => ordersSetStatusFetch(number, 'writer_send');
export const ordersSetStatusCheckFetch = number => ordersSetStatusFetch(number, 'check_reserve');
export const ordersSetStatusRejectedAcceptedFetch = (number, payload) => ordersSetStatusFetch(number, 'request_reject', payload);
export const ordersSetStatusRevisionConfirm = number => ordersSetStatusFetch(number, 'half_revision_confirm');
