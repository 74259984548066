import getRandomIntInclusive from 'utils/getRandomIntInclusive';

import {
  ORDERS_DETAILS_INVALIDATE,
  ORDERS_DETAILS_REQUEST,
  ORDERS_DETAILS_RECEIVE,
  ORDERS_DETAILS_NA_ERROR,
  ORDERS_DETAILS_STATUS_ERROR,
  ORDERS_DETAILS_ERROR,

  ORDERS_SET_FAVORITE_REQUEST,
  ORDERS_SET_FAVORITE_RECEIVE,
  ORDERS_SET_VIEWED_REQUEST,
  ORDERS_SET_VIEWED_RECEIVE,
  ORDERS_SET_STATUS_REQUEST,
  ORDERS_SET_STATUS_RECEIVE,
  ORDERS_SET_NEW_ORDER_RECEIVE,
  ORDERS_SET_RESERVED_RECEIVE,
  ORDERS_SET_RESERVATION_OVER,

  ORDERS_FILES_REQUEST,
  ORDERS_FILES_RECEIVE,
  ORDERS_FILES_UPLOAD_REQUEST,
  ORDERS_FILES_UPLOAD_PROGRESS,
  ORDERS_FILES_UPLOAD_COMPLETE,
  ORDERS_FILES_UPLOAD_RECEIVE,
  ORDERS_FILES_UPLOAD_ERROR,
  ORDERS_FILES_REMOVE_REQUEST,
  ORDERS_FILES_REMOVE_RECEIVE,

  ORDERS_MESSAGES_REQUEST,
  ORDERS_MESSAGES_RECEIVE,
  ORDERS_MESSAGES_SET_READ_REQUEST,
  ORDERS_MESSAGES_SET_READ_RECEIVE,
  ORDERS_MESSAGES_ADD_REQUEST,
  ORDERS_MESSAGES_ADD_RECEIVE,

  ORDERS_MESSAGES_ADD_ATTACH_RECEIVE,

  ORDERS_MESSAGES_ADD_CONFIRM_REQUEST,
  ORDERS_MESSAGES_ADD_CONFIRM_RECEIVE,
  ORDERS_MESSAGES_DELETE_CONFIRM_REQUEST,
  ORDERS_MESSAGES_DELETE_CONFIRM_RECEIVE,
  ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE,

  ORDERS_REPORT_REQUEST,
  ORDERS_REPORT_RECEIVE,
  ORDERS_DDL_EXTENSION_REQUEST,
  ORDERS_DDL_EXTENSION_RECEIVE,
  ORDERS_DDL_EXTENSION_ERROR,

  ORDERS_FIELD_UPDATE,
} from '../constants';

import files from './files';
import messages from './messages';
import setTags from './tags';

const generateViewingCount = () => {
  const zero = getRandomIntInclusive(0, 4);
  if (zero === 0) {
    return 0;
  }
  return getRandomIntInclusive(1, 20);
};

const order = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_DETAILS_INVALIDATE:
      return Object.assign({}, state, {
        didInvalidate: true,
        files: files(state.files, action),
        messages: messages(state.messages, action),
        error: false,
        naError: false,
      });
    case ORDERS_DETAILS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        error: false,
        naError: false,
      });
    case ORDERS_DETAILS_RECEIVE:
      action.user && setTags(action.details, action.user, action.settings);
      return Object.assign({}, state, action.details, {
        isFetching: false,
        didInvalidate: false,
        viewingCount: generateViewingCount(),
      });
    case ORDERS_DETAILS_NA_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        naError: true,
      });
    case ORDERS_DETAILS_STATUS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        errorMessage: action.message,
      });
    case ORDERS_DETAILS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        error: true,
      });
    case ORDERS_SET_FAVORITE_REQUEST:
      return Object.assign({}, state, {
        isFetchingFavorite: true,
      });
    case ORDERS_SET_FAVORITE_RECEIVE:
      return Object.assign({}, state, {
        isFetchingFavorite: false,
        is_favorite: action.value,
      });
    case ORDERS_SET_VIEWED_REQUEST:
      return Object.assign({}, state, {
        isFetchingViewed: true,
      });
    case ORDERS_SET_VIEWED_RECEIVE:
      return Object.assign({}, state, {
        isFetchingViewed: false,
        is_viewed: true,
      });
    case ORDERS_SET_NEW_ORDER_RECEIVE:
      return Object.assign({}, state, {
        is_new_order: false,
      });
    case ORDERS_SET_RESERVED_RECEIVE:
      return Object.assign({}, state, {
        reserved: true,
        ready_reserved: false,
      });
    case ORDERS_SET_STATUS_REQUEST:
      return Object.assign({}, state, {
        isFetchingStatus: true,
      });
    case ORDERS_SET_STATUS_RECEIVE:
      return Object.assign({}, action.details, {
        files: state.files,
        messages: state.messages,
        isFetchingStatus: false,
      });
    case ORDERS_SET_RESERVATION_OVER:
      return Object.assign({}, state, {
        isReservationOver: true,
      });
    case ORDERS_FILES_REQUEST:
    case ORDERS_FILES_RECEIVE:
    case ORDERS_FILES_UPLOAD_REQUEST:
    case ORDERS_FILES_UPLOAD_PROGRESS:
    case ORDERS_FILES_UPLOAD_COMPLETE:
    case ORDERS_FILES_UPLOAD_ERROR:
    case ORDERS_FILES_REMOVE_REQUEST:
    case ORDERS_FILES_REMOVE_RECEIVE:
    {
      const result = {
        ...state,
        files: files(state.files, action),
      };

      return result;
    }
    case ORDERS_FILES_UPLOAD_RECEIVE:
    {
      const result = {
        ...state,
        files: files(state.files, action),
      };
      if (action.data.group === 'paper') {
        result.can_complete = true;
      }
      return result;
    }
    case ORDERS_MESSAGES_REQUEST:
    case ORDERS_MESSAGES_RECEIVE:
    case ORDERS_MESSAGES_SET_READ_REQUEST:
    case ORDERS_MESSAGES_SET_READ_RECEIVE:
    case ORDERS_MESSAGE_CONVERT_TO_TICKET_RECEIVE:
    case ORDERS_MESSAGES_ADD_REQUEST:
    case ORDERS_MESSAGES_ADD_RECEIVE:
    case ORDERS_MESSAGES_ADD_CONFIRM_REQUEST:
    case ORDERS_MESSAGES_ADD_CONFIRM_RECEIVE:
    case ORDERS_MESSAGES_DELETE_CONFIRM_REQUEST:
    case ORDERS_MESSAGES_DELETE_CONFIRM_RECEIVE:
    case ORDERS_MESSAGES_ADD_ATTACH_RECEIVE:
      return Object.assign({}, state, {
        messages: messages(state.messages, action),
      });
    case ORDERS_REPORT_REQUEST:
      return Object.assign({}, state, {
        isFetchingReport: true,
      });
    case ORDERS_REPORT_RECEIVE:
      return Object.assign({}, state, {
        isFetchingReport: false,
        isReportSent: true,
      });
    case ORDERS_DDL_EXTENSION_REQUEST:
      return Object.assign({}, state, {
        ddlExtensionError: null,
      });
    case ORDERS_DDL_EXTENSION_RECEIVE:
      return Object.assign({}, state, {
        wdd_extension_available: action.json.wdd_extension_available,
        wdd_extension_cost: action.json.wdd_extension_cost,
        writer_price: action.json.writer_price,
        writer_deadline: action.json.writer_deadline,
      });
    case ORDERS_DDL_EXTENSION_ERROR:
      return Object.assign({}, state, {
        ddlExtensionError: action.error,
      });
    case ORDERS_FIELD_UPDATE:
      return Object.assign({}, state, {
        [action.fieldName]: action.value,
      });
    default:
      return state;
  }
};

export default order;
