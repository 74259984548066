import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Navigate, Route, Routes, useLocation } from 'react-router-dom';


import { ticketsSetFilter } from './../store/tickets/actions';
import { feedbacksSetFilter } from './../store/feedbacks/actions';

import SubNav from './../components/SubNav';
import WriterTicketingFilter from './../components/WriterTicketing/WriterTicketingFilter';
import OrderFeedbackFilter from './OrderFeedbackFilter';
import WriterTicketingPage from './WriterTicketingPage';
import OrderFeedbackPage from './OrderFeedbackPage';

const IssueResolution = () => {
  const pageTickets = useSelector(state => parseInt(state.tickets.page || 1));
  const filterTickets = useSelector(state => state.tickets.filter || null);
  const sortingTickets = useSelector(state => state.tickets.sorting || null);
  const pageFeedbacks = useSelector(state => parseInt(state.feedbacks.page || 1));
  const filterFeedbacks = useSelector(state => state.feedbacks.filter || null);
  const sortingFeedbacks = useSelector(state => state.feedbacks.sorting || null);

  const dispatch = useDispatch();
  const location = useLocation();
  const isFeedback = location.pathname.indexOf('order-feedback') > -1;

  const setFilterFeedback = (page, sorting, filter) => {
    dispatch(feedbacksSetFilter(page, sorting, filter));
  };

  const setFilterTicket = (page, sorting, filter) => {
    dispatch(ticketsSetFilter(page, sorting, filter));
  };

  return (
    <>
      <SubNav sub noBorder className="ticketing-nav-bar">
        <NavLink to="reported-issues">Reported issues</NavLink>
        <NavLink to="order-feedback">Order feedback</NavLink>
      </SubNav>
      <div className="container container-ticketing pt20">
        <div className="row help-container">
          {isFeedback &&
            <OrderFeedbackFilter
              setFilter={setFilterFeedback}
              filter={filterFeedbacks}
              sorting={sortingFeedbacks}
              page={pageFeedbacks}
            />
          }
          {!isFeedback &&
            <WriterTicketingFilter
              setFilter={setFilterTicket}
              filter={filterTickets}
              sorting={sortingTickets}
              page={pageTickets}
            />
          }

          <section className="right-content container-border">
            <Routes>
              <Route path="reported-issues" element={<WriterTicketingPage />} />
              <Route path="order-feedback" element={<OrderFeedbackPage />} />
              <Route path="*" element={<Navigate to="reported-issues" replace />} />
            </Routes>
          </section>
        </div>
      </div>
    </>
  );
};

export default IssueResolution;
