import moment from 'moment-timezone';

const feelWeek = (date) => {
  const week = [];
  for (let i = 0; i < 7; i += 1) {
    week.push(moment(date));
    date.add(1, 'd');
  }
  return week;
};

export default {
  generateMonthDates(date) {
    const month = [];
    const momentedDate = moment(date);

    // Find start of current month and get monday of that week
    const iterableDate = moment(date).date(1).startOf('isoweek');

    // Feel first week for normalize month
    month.push(feelWeek(iterableDate));

    while (iterableDate.month() === momentedDate.month()) {
      month.push(feelWeek(iterableDate));
    }

    return month;
  },
};
