import React from 'react';
import moment from 'moment-timezone';
import formatTime from 'utils/formatTime';

const taskDeadline = (task) => {
  const value = task.deadline_date;
  if (!value) {
    return null;
  }

  const momentDeadLine = moment(value);
  const hoursDiff = momentDeadLine.diff(moment(), 'hours');

  const completed = task.status && task.status === 'done';

  if (completed || hoursDiff < 0 || hoursDiff > 24) {
    return (
      <span>{formatTime(momentDeadLine, 'dt')}</span>
    );
  }

  return (
    <span>
      {`${formatTime(momentDeadLine, 'dt')} / `}
      <b className="text-warning">{hoursDiff}h</b>
    </span>
  );
};

export default taskDeadline;
