import {
  ADD_MODAL,
  CLOSE_MODAL,
  DELETE_MODAL,
  DELETE_ALL_MODAL,
} from './actions';

const modals = (state = {
  modalList: [],
  modalOptions: {},
}, action) => {
  switch (action.type) {
    case ADD_MODAL:
      return {
        ...state,
        modalList: [...state.modalList, action.payload],
        modalOptions: action.modalOptions,
      };

    case CLOSE_MODAL:
      return {
        ...state,
        modalList: state.modalList.filter(m => m.name !== action.nameModal),
      };

    case DELETE_MODAL:
      return {
        ...state,
        modalList: state.modalList.slice(1),
      };

    case DELETE_ALL_MODAL:
      return {
        ...state,
        modalList: [],
        modalOptions: {},
      };

    default:
      return state;
  }
};

export default modals;
