import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import modalList from 'constants/modalList';

import getPreferredHelp from 'utils/getPreferredHelp';

import { addModalComponent, closeModalComponent } from 'store/modals/actions';

import {
  STATUS_OPEN,
} from 'store/orders/actions';

import { getStatus } from 'components/order/utils';
import TemplateModal from 'components/TemplateModal';
import CreateSession from 'components/order/OrderClientSessions/CreateSession';

const HelpFormat = ({
  order, profile, onClose, onSubmit,
}) => {
  const {
    chat_progress_state: chatProgressState = {}, output_formats: helpFormats = [],
    other_output_format: otherOutputFormat = '',
    writer_chat_message_at: writerChatMessageAt,
    writer_completed: writerCompleted,
  } = order;

  const dispatch = useDispatch();

  const isNoHelpFormat = useMemo(() => helpFormats.length === 0 || (helpFormats.length === 1 && (helpFormats.includes('Decide later') || helpFormats.includes('No live session'))), [order]);
  const isBtnDisabled = useMemo(() => {
    if (getStatus(order) !== STATUS_OPEN) return false;

    return !writerChatMessageAt;
  }, [chatProgressState]);

  const onAskPreferredFormat = () => {
    const dataModal = modalList.Templates;

    dispatch(addModalComponent({
      component: <TemplateModal
        onSubmit={text => onSubmit(text, 'tutorSentToClientOutputClarification')}
        onClose={onClose}
        templateIndex={7}
      />,
      ...dataModal,
    }));
  };

  const onOfferTutoringSession = () => {
    const dataModal = modalList.Templates;

    dispatch(addModalComponent({
      component: <TemplateModal
        onSubmit={text => onSubmit(text, 'tutorSentToClientLiveSessionOffer')}
        onClose={onClose}
        templateIndex={8}
      />,
      ...dataModal,
    }));
  };

  const onScheduleSession = () => {
    const onCloseScheduleSession = () => {
      dispatch(closeModalComponent(modalList.ScheduleSession.name));
    };

    const dataModal = modalList.ScheduleSession;
    const component = <CreateSession session={{}} order={order} toggleModal={onCloseScheduleSession} />;
    dispatch(addModalComponent({ component, ...dataModal }));
  };

  const renderText = () => {
    if (isNoHelpFormat) {
      return 'Client did not specified what format of help they prefer. You can ask the client about their preferred format, and optionally offer an online tutoring session where you can help using screenshare, whiteboards and voice.';
    }

    return 'Client specified they need help in the following format(s):';
  };

  const renderHelpFormatList = () => {
    if (isNoHelpFormat) return null;

    return (
      <ul className="mb2 step-container-list">
        {getPreferredHelp(order)
          .map((hF, index, array) => <li className="step-container-list__item row vertical" key={hF}>{array.length - 1 === index ? `${hF}.` : `${hF};`}</li>)}
      </ul>
    );
  };

  const renderBottomSection = () => {
    if (isNoHelpFormat) {
      return (
        <>
          <div className="step-container__tips">Use if applicable</div>
          <button className="btn btn-light mb8" type="button" disabled={isBtnDisabled || Boolean(writerCompleted)} onClick={onAskPreferredFormat}>Ask preferred format</button>
          <div className="step-container__tips">Use if applicable</div>
          <button className="btn btn-light mb8" type="button" disabled={isBtnDisabled} onClick={onOfferTutoringSession}>Offer tutoring session</button>
        </>
      );
    }

    if (helpFormats.includes('Video call') || helpFormats.includes('Tutoring session')) {
      return (
        <>
          <div className="step-container__title">
            You need to make sure you will be available to conduct an online tutoring session, and help out the client with the format(s) they picked.
          </div>
          <div className="step-container__tips">Schedule now or later</div>
          <button className="btn btn-light mb8" type="button" disabled={isBtnDisabled} onClick={onScheduleSession}>Schedule a tutoring session</button>
        </>
      );
    }

    return (
      <>
        <div className="step-container__title">
          You need to make sure you will be able to help out the client with the format(s) they picked.
        </div>
        <div className="step-container__title">
          Optionally, you can offer an online tutoring session.
        </div>
        <div className="step-container__tips">Use  if applicable</div>
        <button className="btn btn-light mb8" type="button" disabled={isBtnDisabled} onClick={onOfferTutoringSession}>Offer tutoring session</button>
      </>
    );
  };

  return (
    <div>
      <div className="step-container__title">
        {renderText()}
      </div>
      <>
        {renderHelpFormatList()}
      </>
      <>
        {renderBottomSection()}
      </>
      <div className="step-container__tips">Obligatory to accept the order</div>
    </div>
  );
};

export default HelpFormat;
