import React from 'react';
import utils from 'utils';

import {
  NT_MAX_COURSE_ORDERS,
} from 'constants/order';

import { TAGS_FOR_DESCRIPTION } from './constants';

export const isDisabledJoinChatByCourse = (user = {}, order = {}) => {
  const { qc_group: userGroup, metric_data = {} } = user || {};
  const { is_recommended: isRecommended, recomended_by_client: recomendedByClient } = order;

  if (isRecommended || recomendedByClient) return false;

  const { completed_orders_count_nt, current_orders_count_nt } = metric_data;
  const tutorsCourse = utils.getTutorCourse(user) || {};
  const NTorders = completed_orders_count_nt + current_orders_count_nt;
  // Freelancer is in A or A+ group,
  // has Tutoring course not passed,
  // has 10+ NT orders in work/revision/completed
  return ((userGroup === 'A' || userGroup === 'A+') && tutorsCourse.status !== 'Completed' && NTorders >= NT_MAX_COURSE_ORDERS);
};


export const isDisableNTAccept = (order = {}, user = {}) => {
  const { can_accept_nt: canAcceptNt } = user;
  const {
    writer_in_chat: writerInChat = false,
    current_writer_in_chat: currentWriterInChat = false,
    writer_chat_message_at = false,
    chat_progress_state: chatProgressState = {},
  } = order;

  if (!writer_chat_message_at) return true;

  if (writerInChat && !currentWriterInChat) return true;

  if (!writerInChat) return true;

  const { instructions, price, output } = chatProgressState;
  const isAllStepsConfirmed = [instructions, price, output].every(step => step);

  if (!isAllStepsConfirmed) return true;

  return !canAcceptNt;
};


export const renderDescriptionReserve = (canReserve, isNTorder) => (
  <div className="bg-context-wrap">
    {
      isNTorder ? (
        <>
          {!canReserve &&
            <p className="bg-context">You can reserve each order only once, and join chat in each order only twice.</p>
          }
          {canReserve &&
            <p className="bg-context">You can reserve each order only once, and join chat in each order only twice.</p>
          }
        </>
      ) : (
        <>
          {!canReserve &&
            <p className="bg-context">You cannot reserve more than 1 order at the same time.</p>
          }
          {canReserve &&
            <p className="bg-context">You can reserve each order only once.</p>
          }
        </>
      )
    }
  </div>
);

export const renderDescriptionTag = (tags) => {
  let showDescription = false;
  (tags || []).map((t) => {
    if (TAGS_FOR_DESCRIPTION.indexOf(t) > -1) {
      showDescription = true;
    }
  });
  if (showDescription) {
    return (
      <div className="desc-instruction">The instructions are being clarified <br /> with the Client now.</div>
    );
  }
  return (
    <div className="desc-instruction" />
  );
};

export const renderNTAcceptTooltip = () => (
  [
    'This is a tutoring <br />',
    'order. Before you can <br />',
    'accept this order, you <br />',
    'need to join chat with <br />',
    'the Client, introduce <br />',
    'yourself and confirm <br />',
    'instructions and time <br />',
    'estimate required for <br />',
    'completion. <br />',
  ].join('')
);

export const renderJoinChatTooltip = (isDisabledJoinChat, order = {}, user = {}) => {
  const {
    writer_in_chat: writerInChat = false,
    current_writer_in_chat: currentWriterInChat = false,
    block_chat_reason: blockChatReason,
  } = order;

  const {
    verified_order_limitation_ok: verifiedOrderLimitationOk,
    metric_data: {
      current_chat_count: currentChatCount = 0,
    },
  } = user;

  if (isDisabledJoinChat) {
    if (!verifiedOrderLimitationOk) return 'Please verify your account to join chat.';

    if (isDisabledJoinChatByCourse(user, order)) return 'You need to pass the <br /> "LR Tutors: <br /> Introduction to <br /> Tutoring" course in <br /> Learning Lab to join <br /> chat.';

    if (blockChatReason) return `${blockChatReason}`;

    if (writerInChat && !currentWriterInChat) return 'Another Tutor is in <br /> chat now.';

    if (currentChatCount >= 2) return 'You cannot join chat <br /> at more than <br /> 2 orders <br /> simultaneously.</strong>';
  }

  return [
    '<strong>Join the chat if you <br /> want to:</strong>',
    '<ul><li>ask student a <br /> question about the <br /> instructions/ <br /> request additional <br /> details;</li>',
    '<li>give student your <br /> estimate;</li>',
    '<li>accept the task <br /> into work.</li></ul>',
    'You need to contact <br /> student within <br /> <strong>5 minutes</strong> after <br /> joining the chat, <br /> otherwise you will be <br /> rejected from <br /> the order.',
  ].join('');
};
