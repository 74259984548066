import {
  ADD_BANNER,
  DELETE_BANNER,
  DELETE_ALL_BANNER,
} from './actions';

const sortByPriority = (list, newItem) => [...list, newItem].sort((a, b) => a.priority - b.priority);

const banners = (state = {
  bannerList: [],
}, action) => {
  switch (action.type) {
    case ADD_BANNER:
      return {
        ...state,
        bannerList: sortByPriority(state.bannerList, action.payload),
      };
    case DELETE_BANNER:
      return {
        ...state,
        bannerList: state.bannerList.filter(banner => banner.name !== action.payload.name),
      };
    case DELETE_ALL_BANNER:
      return {
        ...state,
        bannerList: [],
      };
    default:
      return state;
  }
};

export default banners;
