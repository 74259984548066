import { urls } from './../../config';
import authFetch from './../../utils/authFetch';
import utils from '../../utils';

export const INBOX_UPDATES_REQUEST = 'INBOX_UPDATES_REQUEST';
export const INBOX_UPDATES_RECEIVE = 'INBOX_UPDATES_RECEIVE';
export const INBOX_UPDATES_ACTION_REQUEST = 'INBOX_UPDATES_ACTION_REQUEST';
export const INBOX_UPDATES_ACTION_RECEIVE = 'INBOX_UPDATES_ACTION_RECEIVE';
export const INBOX_UPDATES_NEXT_REQUEST = 'INBOX_UPDATES_NEXT_REQUEST';
export const INBOX_UPDATES_NEXT_RECEIVE = 'INBOX_UPDATES_NEXT_RECEIVE';
export const INBOX_UPDATES_SET_VIEW_REQUEST = 'INBOX_UPDATES_SET_VIEW_REQUEST';
export const INBOX_UPDATES_SET_VIEW_RECEIVE = 'INBOX_UPDATES_SET_VIEW_RECEIVE';
export const INBOX_UPDATES_DETAIL_REQUEST = 'INBOX_UPDATES_DETAIL_REQUEST';
export const INBOX_UPDATES_DETAIL_RECEIVE = 'INBOX_UPDATES_DETAIL_RECEIVE';

const inboxUpdatesRequest = () => ({
  type: INBOX_UPDATES_REQUEST,
});

const inboxUpdatesReceive = data => ({
  type: INBOX_UPDATES_RECEIVE,
  data,
});

export const inboxUpdatesFetch = (page, sorting, filter) => {
  return (dispatch) => {
    const params = utils.getTicketsQueryString(page, sorting, filter);
    dispatch(inboxUpdatesRequest());
    const url = `${urls.inboxUpdates}${params}`;
    return authFetch(url).then((json) => {
      dispatch(inboxUpdatesReceive(json, sorting, filter));
    });
  };
};

const inboxUpdatesShouldFetch = (state) => {
  const { inboxUpdates } = state;
  if (inboxUpdates.isFetching) {
    return false;
  }
  return inboxUpdates.didInvalidate;
};

export const inboxUpdatesFetchExtraNeeded = (page, sorting, filter) => {
  return (dispatch) => {
    dispatch(inboxUpdatesFetch(page, sorting, filter));
  };
};

export const inboxUpdatesFetchIfNeeded = (page, sorting, filter) => {
  return (dispatch, getState) => {
    if (inboxUpdatesShouldFetch(getState())) {
      return dispatch(inboxUpdatesFetch(page, sorting, filter));
    }
  };
};

const inboxUpdatesActionRequest = () => ({
  type: INBOX_UPDATES_REQUEST,
});

const inboxUpdatesActionReceive = data => ({
  type: INBOX_UPDATES_RECEIVE,
  data,
});

export const inboxUpdatesActionFetch = data => (dispatch) => {
  dispatch(inboxUpdatesActionRequest());
  return authFetch(urls.inboxUpdates, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(_data => dispatch(inboxUpdatesActionReceive(_data)));
};



const feedbacksNextReceive = data => ({
  type: INBOX_UPDATES_NEXT_RECEIVE,
  data,
});

const inboxUpdatesNextRequest = data => ({
  type: INBOX_UPDATES_NEXT_REQUEST,
  data,
});

const inboxUpdatesNextFetch = (page, sorting, filter) => {
  const params = utils.getTicketsQueryString(page, sorting, filter);
  return (dispatch) => {
    const url = `${urls.inboxUpdates}${params}`;
    return authFetch(url).then((json) => {
      dispatch(feedbacksNextReceive(json));
    });
  };
};

export const getInboxUpdatesNextAction = (page, sorting, filter) => {
  return (dispatch) => {
    dispatch(inboxUpdatesNextRequest());
    return dispatch(inboxUpdatesNextFetch(page, sorting, filter));
  };
};



const inboxUpdatesSetViewedRequest = () => ({
  type: INBOX_UPDATES_SET_VIEW_REQUEST,
});

const inboxUpdatesSetViewedReceive = data => ({
  type: INBOX_UPDATES_SET_VIEW_RECEIVE,
  data,
});


export const inboxUpdatesSetViewedFetch = id => (dispatch) => {
  dispatch(inboxUpdatesSetViewedRequest(id));
  return authFetch(`${urls.inboxUpdates}/${id}`, {
    method: 'POST',
    body: JSON.stringify({ action: 'seen' }),
  }).then(() => {
    dispatch(inboxUpdatesSetViewedReceive(id));
  });
};


const inboxUpdatesDetailFetchRequest = id => ({
  type: INBOX_UPDATES_DETAIL_REQUEST,
  id,
});

const inboxUpdatesDetailFetchReceive = data => ({
  type: INBOX_UPDATES_DETAIL_RECEIVE,
  data,
});


export const inboxUpdatesDetailFetch = id => (dispatch) => {
  dispatch(inboxUpdatesDetailFetchRequest(id));
  return authFetch(`${urls.inboxUpdates}/${id}`, {
    method: 'GET',
  }).then((data) => {
    dispatch(inboxUpdatesDetailFetchReceive(data));
  });
};
