import moment from 'moment-timezone';

const setTags = (item, user, settings) => {
  const isGroup = user && (user.group_name === 'A' || user.group_name === 'A+' || user.group_name === 'B');
  if ((item.tags || []).includes('draft_needed')) {
    item.tag_draft = true;
  }
  if (item.recomended_by_client) {
    item.tag_trust = true;
  }
  if ((item.custom_tags || []).includes('prioritize_my_task') || ((item.custom_tags || []).includes('top_writer') && isGroup)) {
    item.tag_feature = true;
  }
  if ((item.custom_tags || []).includes('prioritized')) {
    item.is_prioritized = true;
  }

  const momentDeadLine = moment(item.writer_deadline);
  const hoursDiff = momentDeadLine.diff(moment(), 'hours');

  if (hoursDiff < 24) {
    item.tag_is_hot = true;
  }

  if ((item.custom_tags || []).includes('instr_updated')) {
    item.tag_is_updated = true;
  }

  const myTimeZone = user ? user.timezone_str : null;

  if (myTimeZone) {
    const profileTz = myTimeZone;
    const momentNow = moment.tz(profileTz);
    const momentPaid = moment.tz(item.available_from, profileTz);
    const hoursDiffPaid = momentNow.diff(momentPaid, 'hours', true);

    if (hoursDiffPaid <= 2 && hoursDiffPaid > 0) {
      item.tag_is_new = true;
    }
  }

  const mySubjects = user ? user.subjects : [];
  const isDe = (user && user.isDe) || false;
  const orderSubjectGroups = settings ? settings.orderSubjectGroups : null;

  if (orderSubjectGroups && mySubjects.length) {
    const mySubjectItems = isDe
      ? mySubjects
      : mySubjects.reduce((result, sItem) => {
        if (!orderSubjectGroups[sItem]) {
          console.log('cannot find subject group: ', sItem);
          return result;
        }
        return [...result, ...(orderSubjectGroups[sItem] || {})];
      }, []);
    if (mySubjectItems.some(subject => subject.toLowerCase() === (item.subject || '').toLowerCase())) {
      item.tag_is_subjects = true;
    }
  }

  return item;
};

export default setTags;
