import React from 'react';
import { connect } from 'react-redux';

import { userSetFieldFetch } from 'store/user/actions';

import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

const options = [];
for (let i = 0; i < 24; i++) {
  options.push(i * 60);
}
const minsToString = (mins) => {
  let str = (mins / 60).toString();
  if (str.length === 1) {
    str = `0${str}`;
  }
  return `${str}:00`;
};

const TimeDropdown = (props) => {
  const { value, save, isEditable } = props;
  return (
    <span>
      {isEditable ?
        <Dropdown buttonClassName="lnk-dropdown" text={minsToString(value)} closeAlways>
          <Select value={value} onChange={save} className="menu menu-selectable menu-selectable-overflow">
            {options.map(option =>
                          (<li key={option} value={option} className="menu-item">
                            {minsToString(option)}
                            <Icon className="svg-icon" iconName="check-empty" />
                          </li>))}
          </Select>
        </Dropdown> :
        <span className="profile-noneditable-field">{minsToString(value)}</span>
            }
    </span>
  );
};

const mapStateToProps = (state, props) => ({
  value: state.user[props.name] || options[0],
});
const mapDispatchToProps = (dispatch, props) => ({
  save: value => dispatch(userSetFieldFetch(props.name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeDropdown);
