import React, { useState, useEffect, useMemo } from 'react';

import { usePrevious } from 'hooks';

import { urls } from 'config';

import { getToken } from 'utils/tokenStorage';
import isValidIBANNumber from 'utils/isValidIBANNumber';
import storage from 'utils/localStorageHandler';
import isValidBankAccount from 'utils/isValidBankAccount';
import isValidSWIFT from 'utils/isValidSWIFT';
import classNames from 'utils/classNames';

import Icon from 'components/Icon';

import PersonalInfo from './PersonalInfo';
import BankInfo from './BankInfo';
import BankIntInfo from './BankIntInfo';
import AddressInfo from './AddressInfo';

import '../styles/formModal.scss';

let gisLoading = false;
let gisLoadingText = '';

const FormModal = ({
  toggleFormWireTransferModal, profile, countryList, savePayMethodValue,
}) => {
  const storageData = storage.get('wire-transfer-data') || null;

  const [personalInfo, setPersonalInfo] = useState(storageData ? JSON.parse(storageData).personalInfo : {
    full_name: profile.userName,
    default_currency: 'USD',
    country_of_residency: profile.userCountry,
  });


  const [isLoading, setIsLoading] = useState(gisLoading);
  const [loadingLabel, setLoadingLabel] = useState(gisLoadingText);
  const [bankInfo, setBankInfo] = useState(storageData ? JSON.parse(storageData).bankInfo : {
    bank_account: '',
    bank_swift: '',
    bank_country: profile.userCountry,
    bank_name: '',
    bank_address: '',
    bank_ifsc: '',
    isBankAccountValid: true,
    isBankSwiftValid: true,
    isBankIFSCValid: false,
  });

  const [bankIntermediaryInfo, setBankIntermediaryInfo] = useState(storageData ? JSON.parse(storageData).bankIntermediaryInfo : {
    interm_bank_account: '',
    interm_bank_swift: '',
    interm_bank_country: 'United States',
    interm_bank_name: '',
    interm_bank_address: '',
    isIntermBankAccountValid: true,
    isIntermBankSwiftValid: true,
  });

  const [addressInfo, setAddressInfo] = useState(storageData ? JSON.parse(storageData).addressInfo : {
    beneficiary_address: '',
    city: '',
    province: '',
    postal_code: '',
  });

  const prevBankInfo = usePrevious(bankInfo);
  const prevBankIntInfo = usePrevious(bankIntermediaryInfo);

  const bankMapping = (result, prevData, isIntBank = false) => {
    if (isIntBank) {
      const {
        address: interm_bank_address = prevData.interm_bank_address,
        swift_code: interm_bank_swift = prevData.interm_bank_swift,
        bank: interm_bank_name = prevData.interm_bank_name,
        country: interm_bank_country = prevData.interm_bank_country,
      } = result;
      return {
        interm_bank_address,
        interm_bank_swift,
        interm_bank_name,
        interm_bank_country,
      };
    }
    const {
      address: bank_address = prevData.bank_address,
      swift_code: bank_swift = prevData.bank_swift,
      bank: bank_name = prevData.bank_name,
      country: bank_country = prevData.bank_country,
    } = result;

    return {
      bank_address,
      bank_swift,
      bank_name,
      bank_country,
    };
  };

  const checkOnBankAccount = (value, callback, prevData = {}, isIntBank = false) => {
    setIsLoading(true);
    setLoadingLabel('Checking IBAN');
    fetch(urls.checkIBAN(value), {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: getToken(),
      },
    })
      .then(response => response.json())
      .then(({ result = {} }) => {
        if (!result.data) return;
        const mappingData = bankMapping(result.data, prevData, isIntBank);

        callback({
          ...prevData,
          ...mappingData,
        });
      })
      .catch((error) => {
        console.error(`There has been a problem with get bank_account info: ' ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkOnSWIFT = (value, callback, prevData = {}, isIntBank) => {
    setIsLoading(true);
    setLoadingLabel('Checking SWIFT');
    fetch(urls.checkSWIFT(value), {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: getToken(),
      },
    })
      .then(response => response.json())
      .then((data = null) => {
        if (!data) return;

        const mappingData = bankMapping(data, prevData, isIntBank);

        callback({
          ...prevData,
          ...mappingData,
        });
      })
      .catch((error) => {
        console.error(`There has been a problem with get bank_account info: ' ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    storage.set('wire-transfer-data', JSON.stringify({
      bankInfo,
      bankIntermediaryInfo,
      personalInfo,
      addressInfo,
    }));
  });

  useEffect(() => {
    if (!prevBankInfo) return;


    if (bankInfo.bank_account && prevBankInfo.bank_account !== bankInfo.bank_account) {
      const isValid = isValidBankAccount(bankInfo.bank_account);

      if (!isValid) {
        setBankInfo({ ...bankInfo, isBankAccountValid: false });
        return;
      }

      const isIBAN = isValidIBANNumber(bankInfo.bank_account);

      if (!isIBAN) return;

      checkOnBankAccount(bankInfo.bank_account, setBankInfo, bankInfo);
      return;
    }

    if (bankInfo.bank_swift && prevBankInfo.bank_swift !== bankInfo.bank_swift) {
      const isValid = isValidSWIFT(bankInfo.bank_swift);

      if (!isValid) {
        setBankInfo({ ...bankInfo, isBankSwiftValid: false });
        return;
      }
      const isIBAN = isValidIBANNumber(bankInfo.bank_account);

      if (isIBAN) return;

      checkOnSWIFT(bankInfo.bank_swift, setBankInfo, bankInfo);
    }
  }, [bankInfo, prevBankInfo]);

  useEffect(() => {
    if (!prevBankIntInfo) return;

    if (bankIntermediaryInfo.interm_bank_account && prevBankIntInfo.interm_bank_account !== bankIntermediaryInfo.interm_bank_account) {
      const isValid = isValidBankAccount(bankIntermediaryInfo.interm_bank_account);

      if (!isValid) {
        setBankIntermediaryInfo({ ...bankIntermediaryInfo, isIntermBankAccountValid: false });
        return;
      }

      const isIBAN = isValidIBANNumber(bankIntermediaryInfo.interm_bank_account);

      if (!isIBAN) return;

      checkOnBankAccount(bankIntermediaryInfo.interm_bank_account, setBankIntermediaryInfo, bankIntermediaryInfo, true);
      return;
    }

    if (bankIntermediaryInfo.interm_bank_swift && prevBankIntInfo.interm_bank_swift !== bankIntermediaryInfo.interm_bank_swift) {
      const isValid = isValidSWIFT(bankIntermediaryInfo.interm_bank_swift);

      if (!isValid) {
        setBankIntermediaryInfo({ ...bankIntermediaryInfo, isIntermBankSwiftValid: false });
        return;
      }
      const isIBAN = isValidIBANNumber(bankIntermediaryInfo.interm_bank_account);

      if (isIBAN) return;

      checkOnSWIFT(bankIntermediaryInfo.interm_bank_swift, setBankIntermediaryInfo, bankIntermediaryInfo, true);
    }
  }, [bankIntermediaryInfo, prevBankIntInfo]);

  const isValidForm = useMemo(() => [personalInfo, bankInfo, bankIntermediaryInfo, addressInfo].map(obj => !Object.values(obj).some(item => !item)).every(it => it), [personalInfo, bankInfo, bankIntermediaryInfo, addressInfo]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isValidForm) return;
    gisLoading = true;
    setIsLoading(true);
    setLoadingLabel('Sending data');
    gisLoadingText = 'Sending data';

    const data = {
      ...personalInfo,
      ...bankInfo,
      ...bankIntermediaryInfo,
      ...addressInfo,
    };
    ['isBankAccountValid', 'isBankSwiftValid', 'isIntermBankAccountValid', 'isIntermBankSwiftValid', 'isBankIFSCValid'].forEach((item) => {
      delete data[item];
    });
    savePayMethodValue('WireTransfer', data).then(() => {
      setTimeout(() => {
        storage.remove('wire-transfer-data');
        toggleFormWireTransferModal(null, true);
      }, 10);
    })
      .catch((error) => {
        console.error('error while save Pay Method WT ', error);
        toggleFormWireTransferModal();
      });
  };

  return (
    <>
      {
        isLoading &&
        <div className="wire-transfer-form-loader-layout">
          <div className="row center_center column">
            <i className="common-blue-loader" />
            <div className="wire-transfer-form-loader-layout__text">{loadingLabel}...</div>
          </div>
        </div>
      }
      <button className="btn btn-close" disabled={isLoading} onClick={toggleFormWireTransferModal}><Icon className="svg-icon" iconName="close" /></button>
      <form className={classNames({ 'wire-transfer-form-loader-layout--loading': isLoading })}>
        <h2 className="wire-transfer-form__header">Adding New Beneficiary</h2>
        <PersonalInfo personalInfo={personalInfo} setPersonalInfo={setPersonalInfo} countryList={countryList} />
        <BankInfo bankInfo={bankInfo} setBankInfo={setBankInfo} countryList={countryList} />
        <BankIntInfo bankInfo={bankIntermediaryInfo} setBankInfo={setBankIntermediaryInfo} countryList={countryList} />
        <AddressInfo addressInfo={addressInfo} setAddressInfo={setAddressInfo} />
        <div className="row vertical left">
          <button className="btn btn-complited btn-complited--pointer btn-8-4 mr10" onClick={(e) => { e.preventDefault(); toggleFormWireTransferModal(); }}>Cancel</button>
          <button className={classNames('btn btn-bright btn-big', { 'disabled-only-opacity': !isValidForm })} onClick={onSubmitForm}>Submit form</button>
        </div>
      </form>
    </>
  );
};

export default FormModal;
