export const ADD_MODAL = 'ADD_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const DELETE_MODAL = 'DELETE_MODAL';
export const DELETE_ALL_MODAL = 'DELETE_ALL_MODAL';

export const addModalComponent = (component, modalOptions = {}) => (dispatch, store) => {
  const { modals: { modalList = [] } } = store();

  if (component.name && modalList.find(modal => modal.name === component.name)) return;

  dispatch({
    type: ADD_MODAL,
    payload: component,
    modalOptions,
  });
};

export const closeModalComponent = nameModal => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL,
    nameModal,
  });
};

export const cancelModalComponent = component => (dispatch) => {
  dispatch({
    type: DELETE_MODAL,
    component,
  });
};

export const deleteAllModalComponent = () => (dispatch) => {
  dispatch({
    type: DELETE_ALL_MODAL,
  });
};
