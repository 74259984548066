import React from 'react';
import utils from 'utils';
import shortid from 'shortid';

import { addModalComponent } from 'store/modals/actions';
import ProposedOrdersModal from 'components/ProposedOrdersModal';

import uploadFetchAmazon from 'utils/uploadFetchAmazon';
import uploadUtils from './../../utils/uploadFetch';

import { urls } from './../../config';
import authFetch from './../../utils/authFetch';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_RECEIVE = 'USER_RECEIVE';
export const USER_RECEIVE_SOCKET_UPDATE = 'USER_RECEIVE_SOCKET_UPDATE';
export const USER_INVALIDATE = 'USER_INVALIDATE';
export const USER_TASKS_REQUEST = 'USER_TASKS_REQUEST';
export const USER_TASKS_RECEIVE = 'USER_TASKS_RECEIVE';

export const USER_FEEDBACKS_REQUEST = 'USER_FEEDBACKS_REQUEST';
export const USER_FEEDBACKS_RECEIVE = 'USER_FEEDBACKS_RECEIVE';

export const USER_SET_FEEDBACK_REQUEST = 'USER_SET_FEEDBACK_REQUEST';
export const USER_SET_FEEDBACK_RECEIVE = 'USER_SET_FEEDBACK_RECEIVE';
export const USER_FEEDBACKS_LOAD_NEXT_REQUEST = 'USER_FEEDBACKS_LOAD_NEXT_REQUEST';
export const USER_FEEDBACKS_LOAD_NEXT_RECEIVE = 'USER_FEEDBACKS_LOAD_NEXT_RECEIVE';

export const USER_WARNING_REQUEST = 'USER_WARNING_REQUEST';
export const USER_WARNING_RECEIVE = 'USER_WARNING_RECEIVE';
export const USER_WARNINGS_REQUEST = 'USER_WARNINGS_REQUEST';
export const USER_WARNINGS_RECEIVE = 'USER_WARNINGS_RECEIVE';
export const USER_ACTIVE_WARNINGS_REQUEST = 'USER_ACTIVE_WARNINGS_REQUEST';
export const USER_ACTIVE_WARNINGS_RECEIVE = 'USER_ACTIVE_WARNINGS_RECEIVE';

export const USER_WARNING_SET_VIEWED_REQUEST = 'USER_WARNING_SET_VIEWED_REQUEST';
export const USER_WARNING_SET_VIEWED_RECEIVE = 'USER_WARNING_SET_VIEWED_RECEIVE';

export const USER_SET_AVAILABLE_REQUEST = 'USER_SET_AVAILABLE_REQUEST';
export const USER_SET_AVAILABLE_RECEIVE = 'USER_SET_AVAILABLE_RECEIVE';

export const USER_SET_SUBJECTS_REQUEST = 'USER_SET_SUBJECTS_REQUEST';
export const USER_SET_SUBJECTS_RECEIVE = 'USER_SET_SUBJECTS_RECEIVE';

export const USER_SET_FIELD_REQUEST = 'USER_SET_FIELD_REQUEST';
export const USER_SET_FIELD_RECEIVE = 'USER_SET_FIELD_RECEIVE';
export const USER_SET_FIELD_RECEIVE_PAYMENT_ERROR = 'USER_SET_FIELD_RECEIVE_PAYMENT_ERROR';
export const USER_CLEAR_PAYMENT_ERROR = 'USER_CLEAR_PAYMENT_ERROR';
export const USER_SET_FIELDS_REQUEST = 'USER_SET_FIELDS_REQUEST';
export const USER_SET_FIELDS_RECEIVE = 'USER_SET_FIELDS_RECEIVE';
export const USER_SET_FIELD = 'USER_SET_FIELD';

export const USER_FILE_UPLOAD_REQUEST = 'USER_FILE_UPLOAD_REQUEST';
export const USER_FILE_UPLOAD_PROGRESS = 'USER_FILE_UPLOAD_PROGRESS';
export const USER_FILE_UPLOAD_RECEIVE = 'USER_FILE_UPLOAD_RECEIVE';

export const USER_ACTION_REQUEST = 'USER_ACTION_REQUEST';
export const USER_ACTION_RECEIVE = 'USER_ACTION_RECEIVE';

export const USER_SET_SHOW_PROPOSED = 'USER_SET_SHOW_PROPOSED';

const userRequest = () => ({
  type: USER_REQUEST,
});

export const userReceive = user => ({
  type: USER_RECEIVE,
  user,
});


export const userReceiveSocketUpdate = user => ({
  type: USER_RECEIVE_SOCKET_UPDATE,
  user,
});

export const userFetch = (silent = false) => (dispatch) => {
  if (!silent) dispatch(userRequest());
  return authFetch(urls.user)
    .then((json) => {
      dispatch(userReceive(json));
      return Promise.resolve();
    })
    .catch(er => console.error(er));
};

const userShouldFetch = (state) => {
  const { user } = state;
  if (user.isFetching) {
    return false;
  }
  return user.didInvalidate;
};

export const userFetchIfNeeded = () => (dispatch, getState) => {
  if (userShouldFetch(getState())) {
    return dispatch(userFetch());
  }
};

const userTasksReceive = tasks => ({
  type: USER_TASKS_RECEIVE,
  tasks,
});

const userTasksRequest = () => ({
  type: USER_TASKS_REQUEST,
});

const userTasksShouldFetch = (state) => {
  const { tasks } = state.user;
  if (!tasks) {
    return true;
  }
  if (tasks.isFetching) {
    return false;
  }
  return tasks.didInvalidate;
};

const userTasksFetch = () => (dispatch) => {
  dispatch(userTasksRequest());
  return authFetch(urls.tasks).then(json => dispatch(userTasksReceive(json.results)));
};

export const userTasksFetchIfNeeded = () => (dispatch, getState) => {
  if (userTasksShouldFetch(getState())) {
    return dispatch(userTasksFetch());
  }
};

const userFeedbacksRequest = page => ({
  type: USER_FEEDBACKS_REQUEST,
  page,
});

const userFeedbacksReceive = (feedbacks, page) => ({
  type: USER_FEEDBACKS_RECEIVE,
  feedbacks,
  page,
});


const userFeedbacksShouldFetch = (state) => {
  const { feedbacks } = state.user;
  if (!feedbacks) {
    return true;
  }
  if (feedbacks.isFetching) {
    return false;
  }
  return feedbacks.didInvalidate;
};

const userFeedbacksFetch = page => (dispatch) => {
  const params = `?page=${page}`;
  return authFetch(urls.feedbacks(params)).then(json => dispatch(userFeedbacksReceive(json, page)));
};

export const userFeedbacksLoadNext = page => dispatch => dispatch(userFeedbacksFetch(page));

export const userFeedbacksFetchIfNeeded = (page = 1) => (dispatch, getState) => {
  if (userFeedbacksShouldFetch(getState())) {
    dispatch(userFeedbacksRequest(page));
    return dispatch(userFeedbacksFetch(page));
  }
};

const userSetFeedbackRequest = () => ({
  type: USER_SET_FEEDBACK_REQUEST,
});

const userSetFeedbackReceive = data => ({
  type: USER_SET_FEEDBACK_RECEIVE,
  data,
});

export const userSetFeedbackFetch = value => (dispatch) => {
  dispatch(userSetFeedbackRequest());
  return authFetch(urls.feedbackUpdate(value), {
    method: 'PATCH',
    body: JSON.stringify({
      writer_seen: true,
    }),
  }).then(data => dispatch(userSetFeedbackReceive(data)));
};

const pluckUserWarning = json => ({
  items: json.results,
  page: json.page,
  total: json.total,
  limit: json.limit,
});

const userWarningRequest = warningTag => ({
  type: USER_WARNING_REQUEST,
  warningTag,
});

const userWarningReceive = (data, warningTag) => ({
  type: USER_WARNING_RECEIVE,
  data,
  warningTag,
});

const userWarningShouldFetch = (state, warningTag) => {
  const warnings = state.user.warnings[warningTag];
  if (!warnings) return true;
  if (warnings.isFetching) return false;
};

const userWarningFetch = warningTag => (dispatch) => {
  dispatch(userWarningRequest(warningTag));
  return authFetch(urls.warnings(warningTag))
    .then(json => dispatch(userWarningReceive(pluckUserWarning(json), warningTag)));
};

export const userWarningFetchIfNeeded = warningTag => (dispatch, getState) => {
  if (userWarningShouldFetch(getState(), warningTag)) {
    return dispatch(userWarningFetch(warningTag));
  }
};

const userActiveWarningRequest = warningTag => ({
  type: USER_ACTIVE_WARNINGS_REQUEST,
});

const userActiveWarningReceive = data => ({
  type: USER_ACTIVE_WARNINGS_RECEIVE,
  data,
});

const userActiveWarningShouldFetch = (state) => {
  const { activeWarnings } = state.user;
  if (!activeWarnings) return true;
  if (activeWarnings.isFetching) return false;
};

const userActiveWarningFetch = profileId => (dispatch) => {
  dispatch(userActiveWarningRequest());
  return authFetch(urls.activeWarnings(profileId))
    .then(json => dispatch(userActiveWarningReceive(pluckUserWarning(json))));
};

export const userActiveWarningFetchIfNeeded = profileId => (dispatch, getState) => {
  if (userActiveWarningShouldFetch(getState())) {
    return dispatch(userActiveWarningFetch(profileId));
  }
};


const userWarningSetViewedRequest = warningTag => ({
  type: USER_WARNING_SET_VIEWED_REQUEST,
  warningTag,
});

const userWarningSetViewedReceive = (data, warningTag) => ({
  type: USER_WARNING_SET_VIEWED_RECEIVE,
  data,
  warningTag,
});

export const userWarningSetViewedFetch = warningTag => (dispatch) => {
  dispatch(userWarningSetViewedRequest(warningTag));
  return authFetch(urls.warnings(warningTag), {
    method: 'PATCH',
    body: JSON.stringify({
      seen: true,
    }),
  }).then(json => dispatch(userWarningSetViewedReceive(pluckUserWarning(json), warningTag)));
};

const userWarningsRequest = () => ({
  type: USER_WARNINGS_REQUEST,
});

const userWarningsReceive = warnings => ({
  type: USER_WARNINGS_RECEIVE,
  warnings,
});

const userWarningsShouldFetch = (state) => {
  const { warnings } = state.user;
  if (!warnings) return true;
  if (warnings.isFetching) return false;
};

const userWarningsFetch = () => (dispatch) => {
  dispatch(userWarningsRequest());
  return authFetch(urls.warnings())
    .then((json) => {
      dispatch(userWarningsReceive(json.results));
      return Promise.resolve();
    })
    .catch(er => console.error(er));
};

export const userWarningsFetchIfNeeded = () => (dispatch, getState) => {
  if (userWarningsShouldFetch(getState())) {
    return dispatch(userWarningsFetch());
  }
};

const userSetAvailableRequest = () => ({
  type: USER_SET_AVAILABLE_REQUEST,
});

const userSetAvailableReceive = data => ({
  type: USER_SET_AVAILABLE_RECEIVE,
  data,
});

export const userSetAvailableFetch = value => (dispatch) => {
  dispatch(userSetAvailableRequest());
  return authFetch(urls.user, {
    method: 'PATCH',
    body: JSON.stringify({
      available_for: value,
    }),
  }).then(data => dispatch(userSetAvailableReceive(data)));
};

const userSetSubjectsRequest = subjects => ({
  type: USER_SET_SUBJECTS_REQUEST,
  subjects,
});

const userSetSubjectsReceive = data => ({
  type: USER_SET_SUBJECTS_RECEIVE,
  data,
});

export const userSetSubjectsFetch = subjects => (dispatch) => {
  dispatch(userSetSubjectsRequest(subjects));
  return authFetch(urls.user, {
    method: 'PATCH',
    body: JSON.stringify({
      subjects,
    }),
  }).then(data => dispatch(userSetSubjectsReceive(data)));
};

const userSetFieldRequest = (name, value) => ({
  type: USER_SET_FIELD_REQUEST,
  name,
  value,
});

const userSetFieldReceive = (name, data) => ({
  type: USER_SET_FIELD_RECEIVE,
  name,
  data,
});

const userSetFetchPaymentError = (name, value) => ({
  type: USER_SET_FIELD_RECEIVE_PAYMENT_ERROR,
  name,
  value,
});

export const userClearPaymentError = () => ({
  type: USER_CLEAR_PAYMENT_ERROR,
});

export const userSetFieldFetch = (name, value, oldValue) => (dispatch) => {
  dispatch(userSetFieldRequest(name, value));
  return authFetch(urls.user, {
    method: 'PATCH',
    body: JSON.stringify({
      [name]: value,
    }),
  })
    .then(data => dispatch(userSetFieldReceive(name, data)))
    .catch(e => dispatch(userSetFetchPaymentError(name, oldValue)));
};

const userSetFieldsRequest = data => ({
  type: USER_SET_FIELDS_REQUEST,
  data,
});

const userSetFieldsReceive = (data, json) => ({
  type: USER_SET_FIELDS_RECEIVE,
  data,
  json,
});

export const userSetFieldsFetch = data => (dispatch) => {
  dispatch(userSetFieldsRequest(data));
  return authFetch(urls.user, {
    method: 'PATCH',
    body: JSON.stringify(data),
  }).then(json => dispatch(userSetFieldsReceive(data, json)));
};

export const userSetField = (name, value) => ({
  type: USER_SET_FIELD,
  name,
  value,
});

const userFileUploadRequest = (fileType, fileName) => ({
  type: USER_FILE_UPLOAD_REQUEST,
  fileType,
  fileName,
});

const userFileUploadProgress = (fileType, progress) => ({
  type: USER_FILE_UPLOAD_PROGRESS,
  fileType,
  progress,
});

const userFileUploadReceive = (fileType, data) => ({
  type: USER_FILE_UPLOAD_RECEIVE,
  fileType,
  data,
});

export const userFileUploadFetch = (fileType, file, wrId) => (dispatch) => {
  const xhr = new XMLHttpRequest();
  const fileId = `wt502fy0/${wrId}/${shortid.generate()}/${uploadUtils.recursiveDecodeURIComponent(file.name)}`;
  dispatch(userFileUploadRequest(fileType, file.name));
  return uploadFetchAmazon(fileId, file, percent => dispatch(userFileUploadProgress(fileType, percent)), xhr).then((fileUrl) => {
    dispatch(userSetFieldFetch(fileType, {
      location: fileUrl,
      name: file.name,
      size: file.size,
    }));
    const fileData = {
      name: file.name,
      location: fileUrl,
    };
    dispatch(userFileUploadReceive(fileType, fileData));
    switch (fileType) {
      case 'passport_copy':
        dispatch(userSetField('passport_verified', false));
        break;
      case 'residence_copy':
        dispatch(userSetField('profile_residence_verified', false));
        break;
      default:
        break;
    }
  });
};

const userActionRequest = () => ({
  type: USER_ACTION_REQUEST,
});

const userActionReceive = data => ({
  type: USER_ACTION_RECEIVE,
  data,
});

export const userActionFetch = data => (dispatch) => {
  dispatch(userActionRequest());
  return authFetch(urls.user, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => dispatch(userActionReceive(data)));
};

export const userSetShowProposed = (value, openPlace = null) => (dispatch) => {
  const isMobile = utils.detectMob();
  if (isMobile) {
    dispatch(addModalComponent(<ProposedOrdersModal isMobile openPlace={openPlace} />));
    return;
  }
  dispatch({
    type: USER_SET_SHOW_PROPOSED,
    value,
    openPlace,
  });
};
