import moment from 'moment-timezone';
import {
  ORDERS_REVISION_REQUEST,
  ORDERS_REVISION_RECEIVE,

  ORDERS_REVISION_SET_SORTING,
  ORDERS_REVISION_SET_FILTER_SEARCH,
  ORDERS_REVISION_SET_MESSAGE_FILTER,
  ORDERS_REVISION_RESET_FILTER,
} from './actions';

import { ORDERS_HIDE_IN_LISTS } from './../orders/actions';

const filter = (state, action) => {
  switch (action.type) {
    case ORDERS_REVISION_SET_FILTER_SEARCH:
      return Object.assign({}, state, {
        search: action.value,
      });
    case ORDERS_REVISION_SET_MESSAGE_FILTER:
      return Object.assign({}, state, {
        new_messages: action.value,
      });
    case ORDERS_REVISION_RESET_FILTER:
      return { search: '', new_messages: false };
    default:
      return state;
  }
};

const sorting = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_REVISION_SET_SORTING:
      return Object.assign({}, state, {
        field: action.value,
        isAsc: state.field === action.value ? !state.isAsc : true,
      });
    default:
      return state;
  }
};

const ordersRevision = (state = {
  isFetching: false,
  didInvalidate: true,
  numbers: [],
  sorting: { field: 'writer_deadline', isAsc: true },
  filter: { search: '', new_messages: false },
  unreadOrderMessages: null,
}, action) => {
  switch (action.type) {
    case ORDERS_HIDE_IN_LISTS:
      return Object.assign({}, state, {
        numbers: state.numbers.filter(number => number !== action.number),
      });
    case ORDERS_REVISION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case ORDERS_REVISION_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        updatedAt: moment(),
        numbers: action.numbers,
        unreadOrderMessages: action.unreadOrderMessages,
      });
    case ORDERS_REVISION_SET_SORTING:
      return Object.assign({}, state, {
        sorting: sorting(state.sorting, action),
      });
    case ORDERS_REVISION_SET_FILTER_SEARCH:
    case ORDERS_REVISION_SET_MESSAGE_FILTER:
    case ORDERS_REVISION_RESET_FILTER:
      return Object.assign({}, state, {
        filter: filter(state.filter, action),
      });
    default:
      return state;
  }
};

export default ordersRevision;
