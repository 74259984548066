const RUBICS_SCORE = {
  writing: {
    dataInstructionsFollowed: {
      data: {
        plagiarism_free: {
          default: [0.0001, 0.0002, 0.0003, 0.0004, 0],
        },
        content: {
          default: [1.5, 1.2, 1.05, 0.9, 0],
        },
        sources: {
          default: [1, 0.8, 0.7, 0.6, 0],
        },
        word_count: {
          default: [0.5, 0.4, 0.35, 0.3, 0],
        },
      },
      max: [3, 2.4, 2.1, 1.8, 0],
    },
    dataLanguageAndFormat: {
      data: {
        structure: {
          default: [0.5, 0.4, 0.35, 0.3, 0],
        },
        language: {
          default: [1, 0.8, 0.7, 0.6, 0],
        },
        format: {
          default: [0.5, 0.4, 0.35, 0.3, 0],
        },
      },
      max: [2, 1.6, 1.4, 1.2, 0],
    },
  },
  calculations: {
    calculations: {
      data: {
        final_answer: {
          default: [1.500, 1.200, 1.050, 0.900, 0],
        },
        explanation: {
          default: [1.000, 0.800, 0.700, 0.600, 0],
        },
        formulas: {
          default: [1.000, 0.800, 0.700, 0.600, 0],
        },
        language: {
          default: [1.000, 0.800, 0.700, 0.600, 0],
        },
        format: {
          default: [0.500, 0.400, 0.350, 0.300, 0],
        },
        plagiarism: {
          default: [0.0001, 0.0002, 0.0003, 0.0004, 0],
        },
      },
      max: [5, 4, 3.5, 3, 0],
    },
  },
  presentation: {
    presentation: {
      data: {
        content: {
          default: [1.500, 1.200, 1.050, 0.900, 0],
        },
        plagiarism: {
          default: [0.0001, 0.0002, 0.0003, 0.0004, 0],
        },
        visual: {
          default: [1.000, 0.800, 0.700, 0.600, 0],
        },
        language: {
          default: [1.000, 0.800, 0.700, 0.600, 0],
        },
        format: {
          default: [0.500, 0.400, 0.350, 0.300, 0],
        },
        slide_count: {
          default: [0.500, 0.400, 0.350, 0.300, 0],
        },
        sources: {
          default: [0.500, 0.400, 0.350, 0.300, 0],
        },
      },
      max: [5, 4, 3.5, 3, 0],
    },
  },
  editing: {
    editing: {
      data: {
        content: {
          default: [1.500, 1.200, 1.050, 0.900, 0],
        },
        language: {
          default: [1.000, 0.800, 0.700, 0.600, 0],
        },
        structure: {
          default: [1.000, 0.800, 0.700, 0.600, 0],
        },
        sources: {
          default: [1.000, 0.800, 0.700, 0.600, 0],
        },
        format: {
          default: [0.500, 0.400, 0.350, 0.300, 0],
        },
        plagiarism: {
          default: [0.0001, 0.0002, 0.0003, 0.0004, 0],
        },
      },
      max: [5, 4, 3.5, 3, 0],
    },
  },
  proofreading: {
    proofreading: {
      data: {
        language: {
          default: [4.000, 3.200, 2.800, 2.400, 0],
        },
        format: {
          default: [1.000, 0.800, 0.700, 0.600, 0],
        },
      },
      max: [5, 4, 3.5, 3, 0],
    },
  },
  quiz: {
    quiz: {
      data: {
        correct_answers: {
          default: [5.000, 4.000, 3.000, 2.000, 0],
        },
      },
      max: [5, 4, 3, 2, 0],
    },
  },
  'Revision by new Freelancer': {
    'Revision by new Freelancer': {
      data: {
        final_answer: {
          default: [1.000, 0.900, 0.850, 0.800, 0],
        },
        Explanation: {
          default: [0.500, 0.400, 0.350, 0.300, 0],
        },
        Formulas: {
          default: [0.500, 0.400, 0.350, 0.300, 0],
        },
        Language: {
          default: [0.500, 0.400, 0.350, 0.300, 0],
        },
        Format: {
          default: [0.200, 0.100, 0.05, 0.0004, 0],
        },
        Content: {
          default: [1.000, 0.900, 0.850, 0.800, 0],
        },
        Visual: {
          default: [0.200, 0.100, 0.05, 0.0004, 0],
        },
        Sources: {
          default: [0.300, 0.200, 0.15, 0.1, 0],
        },
        'Slide/word count': {
          default: [0.500, 0.400, 0.350, 0.300, 0],
        },
        Structure: {
          default: [0.300, 0.200, 0.15, 0.1, 0],
        },
        plagiarism: {
          default: [0.0001, 0.0002, 0.0003, 0.0004, 0],
        },
      },
      max: [5, 4, 3.5, 3, 0],
    },
  },
};

export default RUBICS_SCORE;
