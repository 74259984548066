import React, { Component } from 'react';

import FinalPaperFileRow from './FinalPaperFileRow';

class OrderFileList extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        const { fileInputRef } = this.props;

        e.stopPropagation();
        fileInputRef.click();
    }

    render() {
        const { fileList, removeFile } = this.props;
        const isFetching = fileList.some((file) => file.isFetching);
        const isError = fileList.some((file) => file.error);

        if (!isFetching) { return null; }

        return (
            <div className="modal-wrap">
                <div className="modal-container modal-upload">
                    <h1>File uploading</h1>

                    {fileList.filter((file) => file.isFetching).map(file =>
                        <FinalPaperFileRow file={file} key={file._id} isModal isError={isError} onRemove={removeFile} />
                    )}
                    <div className="upload-button">
                        <button className="btn btn-light btn-big" onClick={this.onClick}>Upload more</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrderFileList;