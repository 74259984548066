import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'components/Modal';
import Checkbox from 'components/Checkbox';
import Tooltip from 'components/Tooltip';

import { userSetSubjectsFetch } from 'store/user/actions';
import { addAlert } from 'store/alerts/actions';

import classNames from 'utils/classNames';
import storage from 'utils/localStorageHandler';

import questionIcon from 'img/icons/question-copy.svg';

import 'components/MajorSubjectsForm/index.scss';

const MAX_COUNT = 2;

const OtherSubjectsModal = ({ onClose, onToggleMajorModal }) => {
  const dispatch = useDispatch();

  const [otherSubjects, setOtherSubjects] = useState([]);
  const [isLoading, toggleLoading] = useState(false);

  const orderSubjectGroups = useSelector(state => state.settings.orderSubjectGroups);

  const subjectList = useMemo(() => Object.keys(orderSubjectGroups).sort().filter(s => s.toLowerCase() !== 'other') || [], [orderSubjectGroups]);
  const subjects1 = useMemo(() => subjectList.slice(0, Math.ceil(subjectList.length / 2)), [subjectList]);
  const subjects2 = useMemo(() => subjectList.slice(Math.ceil(subjectList.length / 2)), [subjectList]);
  const subjectGroupContents = useMemo(() => subjectList.reduce((result, item) => ({ ...result, [item]: orderSubjectGroups[item].join('\n') }), {}), [subjectList]);

  const selectedSubjects = [storage.get('pref-subjects')];
  const isDisabled = otherSubjects.length !== MAX_COUNT || isLoading;

  const onSubmitForm = useCallback(
    () => {
      if (isDisabled) return;
      toggleLoading(true);
      storage.remove('pref-subjects');
      storage.remove('welcome-subjects-modal');
      storage.set('show-thanks-modal', true);
      onClose();
      dispatch(userSetSubjectsFetch([...selectedSubjects, ...otherSubjects]));
    },
    [dispatch, otherSubjects],
  );
  const onSubjectsAdd = useCallback(
    (event) => {
      const { target } = event;
      if (target.checked) {
        setOtherSubjects(oldSubjects => [...oldSubjects, target.name]);
      } else {
        setOtherSubjects(oldSubjects => oldSubjects.filter(it => it !== target.name));
      }
    },
    [setOtherSubjects],
  );

  const renderCheckbox = (subject) => {
    const isFirstPreferSubject = selectedSubjects.indexOf(subject) > -1;
    const selected = isFirstPreferSubject || otherSubjects.indexOf(subject) > -1;
    const disabled = isLoading || isFirstPreferSubject;
    return (
      <div className="row vertical subjects-modal__items row vertical" key={subject}>
        <Checkbox
          disabled={disabled}
          name={subject}
          checked={selected}
          onChange={onSubjectsAdd}
          className="fsL"
        >{subject}</Checkbox>
        <div className="subjects-info-box ml8">
          {
            !isFirstPreferSubject && (
            <Tooltip content={subjectGroupContents[subject]} className="subjects-modal__wrap-tooltip">
              <img className="info-icon" src={questionIcon} alt="" />
            </Tooltip>
            )
          }
          {
            isFirstPreferSubject && (
              <i className="subjects-modal__selected-icon" />
            )
          }
        </div>
      </div>
    );
  };

  return (
    <div className="ta-center subjects-modal">
      <div className="subjects-modal__steps">Step 2 out of 2</div>
      <h2 className="title mb15">Select two more preferred subjects</h2>
      <p className="subjects-modal__descriptions fsMd mb30">Select other two subjects you also consider working on</p>
      <div className="row ta-left">
        <div className="col-1">
          {subjects1.map(renderCheckbox)}
        </div>
        <div className="col-1">
          {subjects2.map(renderCheckbox)}
        </div>
      </div>
      <div className="subjects-modal__footer row space-between">
        <button className="btn btn-8-4 btn-transparent btn-default" onClick={onToggleMajorModal}>Back</button>
        <button className={classNames('btn btn-bright btn-big', { disabled: isDisabled })} onClick={onSubmitForm}>Submit</button>
      </div>
    </div>
  );
};

export default OtherSubjectsModal;
