import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import timeConstants from 'constants/time';

import classNames from 'utils/classNames';
import calcNowTime from 'utils/calcNowTime';

class ReservedCounter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      diff: 0,
    };
    this.setDiff = this.setDiff.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this.setDiff();
    this.interval = setInterval(this.setDiff, timeConstants.ONE_SECOND);
    this._isMounted = true;
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this._isMounted = false;
    this.setState(state => Object.assign({}, state, { diff: 0 }));
  }

  setDiff() {
    const { time, onOver, diffBetweenServerClientTime } = this.props;
    const diff = !time ? 0 : moment(time).diff(calcNowTime(diffBetweenServerClientTime));
    if (diff <= 0) {
      clearInterval(this.interval);
      onOver && onOver();

      if (!this._isMounted) return;

      this.setState(state => Object.assign({}, state, { diff: 0 }));
      return;
    }
    this.setState(state => Object.assign({}, state, { diff }));
  }

  render() {
    const { diff } = this.state;
    const { children, classes, textClass = '' } = this.props;
    const { ONE_MINUTE, ONE_HOUR } = timeConstants;
    const diffDuration = moment.duration(diff);
    const diffDay = diffDuration.days() > 0 ? `${diffDuration.days()}d ` : '';
    const text = diff <= 0 ? '0:00' : `${diffDay} ${moment.utc(diff).format(diff < ONE_HOUR ? 'm:ss' : 'HH:mm:ss')}`;

    const className = classNames(textClass, { 'text-warning': diff <= ONE_MINUTE });

    if (children) {
      return React.cloneElement(children, { diff, classes });
    }

    return (
      <span className={className}>{text}</span>
    );
  }
}

const mapStateToProps = (state) => {
  const settings = state.settings || {};

  return {
    diffBetweenServerClientTime: settings.diffBetweenServerClientTime,
  };
};

export default connect(mapStateToProps)(ReservedCounter);
