import storage from '../../utils/localStorageHandler';

import {
  SETTINGS_REQUEST,
  SETTINGS_RECEIVE,
  SWITCH_DND_MODE,
} from './actions';

import {
  ORDERS_FIND_RECEIVE,
} from './../ordersFind/actions';

const settings = (state = {
  isFetching: false,
  didInvalidate: true,
  orderSubjects: null,
  orderSubjectGroups: {},
  orderTypes: null,
  dataListOfFixes: null,
  countryList: null,
  orderTimesDe: ['all', '3hours', '5hours', '12hours', '24hours', 'yesterday', 'lastWeek'],
  // orderTimesWithNew: ['all', 'new', '3hours', '5hours', '12hours', '24hours', 'yesterday', 'lastWeek'],
  orderTimes: {
    '1-6 hours': {
      value: [0, 6],
      count: 0,
    },
    '6-12 hours': {
      value: [6, 12],
      count: 0,
    },
    '12-24 hours': {
      value: [12, 24],
      count: 0,
    },
    '1-2 days': {
      value: [24, 48],
      count: 0,
    },
    '2-3 days': {
      value: [48, 72],
      count: 0,
    },
    '3+ days': {
      value: [72],
      count: 0,
    },
  },

  filterTimesLong: ['all', 'thisMonth', 'lastMonth', 'thisYear', 'lastYear'],
  DNDmode: storage.get('dnd-mode'),
  templates: {
    apa: {
      id: 'apa',
      text: 'APA template',
      location: 'https://modrcxviikco.s3.amazonaws.com/000/APA+template.docx',
    },
    chicago: {
      id: 'chicago',
      text: 'Chicago template',
      location: 'https://modrcxviikco.s3.amazonaws.com/000/Chicago+template.docx',
    },
    harvard: {
      id: 'harvard',
      text: 'Harvard template',
      location: 'https://modrcxviikco.s3.amazonaws.com/000/Harvard+template.docx',
    },
    mla: {
      id: 'mla',
      text: 'MLA template',
      location: 'https://modrcxviikco.s3.amazonaws.com/000/MLA+template.docx',
    },
  },
}, action) => {
  switch (action.type) {
    case SETTINGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    // eslint-disable-next-line no-case-declarations
    case ORDERS_FIND_RECEIVE:
      let otherSubjects = [];
      if (action.items) {
        Object.values(action.items).filter(o => { if (o.is_subject_other) { otherSubjects = [...otherSubjects, o.subject] } });
      }
      return Object.assign({}, state, {
        orderSubjects: [...(state.orderSubjects || []), ...otherSubjects],
        orderSubjectGroups: {
          ...state.orderSubjectGroups,
          other: otherSubjects,
        },
      });

    case SETTINGS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        orderSubjects: action.orderSubjects,
        orderTypes: action.orderTypes,
        dataListOfFixes: action.dataListOfFixes,
        countryList: action.countryList,
        orderSubjectGroups: action.orderSubjectGroups,
        diffBetweenServerClientTime: action.diffBetweenServerClientTime,
      });
    case SWITCH_DND_MODE:
      return Object.assign({}, state, {
        DNDmode: !state.DNDmode,
      });
    default:
      return state;
  }
};

export default settings;
