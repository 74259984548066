import React, { Component } from 'react';
import { connect } from 'react-redux';

import classNames from 'utils/classNames';
import { userSetFieldFetch } from 'store/user/actions';

import Checkbox from 'components/Checkbox';

import { TimeDropdown, SubjectsTypeDropdown } from '../Controls';

class NotificationsForm extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { target } = event;
    const { notifications, save, isEditable } = this.props;
    if (!isEditable) return null;
    notifications[target.name] = target.checked;
    save(notifications);
  }

  render() {
    const {
      notifications, isLoading, phoneVerified, isEditable,
    } = this.props;
    const smsBlockClassName = classNames('text-grey mb12', { 'text-warning': !phoneVerified });
    return (
      <div className="notifications-box">
        <h4 className="text-grey mb12">Email</h4>
        <div className="row mb20">
          <div className="col-1">
            <Checkbox checked={notifications.orders_top} name="orders_top" onChange={this.onChange} disabled={isLoading}>
              Morning digest of Available orders
            </Checkbox>
            <div className="dropdown-box">
              <TimeDropdown isEditable={isEditable} name="orders_top_time" />
              <SubjectsTypeDropdown isEditable={isEditable} name="orders_top_subjects" />
            </div>
            <Checkbox checked={notifications.orders_top_evening} name="orders_top_evening" onChange={this.onChange} disabled={isLoading}>
              Evening digest of Available orders
            </Checkbox>
            <div className="dropdown-box">
              <TimeDropdown isEditable={isEditable} name="orders_top_time_evening" />
              <SubjectsTypeDropdown isEditable={isEditable} name="orders_top_evening_subjects" />
            </div>
            <Checkbox checked={notifications.task_list} name="task_list" onChange={this.onChange} disabled={isLoading}>
              Regular To Do digest
            </Checkbox>
            <Checkbox checked={notifications.task_done} name="task_done" onChange={this.onChange} disabled={isLoading}>
              Task from your To Do list is done
            </Checkbox>
          </div>
          <div className="col-1">
            <Checkbox checked disabled>A new message in the system</Checkbox>
            <Checkbox checked disabled>Restore password</Checkbox>
            <Checkbox checked disabled>A new revision is required</Checkbox>
            <Checkbox checked={notifications.new_group} name="new_group" onChange={this.onChange} disabled={isLoading}>
              Writer group change
            </Checkbox>
            <Checkbox checked={notifications.recomended_writer} name="recomended_writer" onChange={this.onChange} disabled={isLoading}>
              A new order is recommended to me
            </Checkbox>
          </div>
        </div>
        <h4 className={smsBlockClassName}>
          SMS {!phoneVerified && '(phone not verified)'}
        </h4>
        <div className="row">
          <div className="col-4">
            <Checkbox
              checked={notifications.recomended_writer_sms}
              name="recomended_writer_sms"
              onChange={this.onChange}
              disabled={isLoading || !phoneVerified}
            >
              A new order is recommended to me
            </Checkbox>
          </div>
          <div className="col-4">
            <Checkbox checked disabled>A new revision is required</Checkbox>
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.user.notifications,
  isLoading: state.user.fetchingFields.indexOf('notifications') > -1,
  phoneVerified: state.user.phone_number_status === 'Verified',
});

const mapDispatchToProps = dispatch => ({
  save: value => dispatch(userSetFieldFetch('notifications', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsForm);
