import React, { useMemo } from 'react';

const Bage = ({ user }) => {
  const { qc_full_group: qcFullGroup } = user;

  const percent = useMemo(() => {
    if (qcFullGroup === 'A-pro') {
      return '+25%';
    } else if (qcFullGroup === 'A+-pro') {
      return '+35%';
    }

    return '0%';
  }, [qcFullGroup]);

  return (<span className="bage-pro">{percent}</span>);
};

export default Bage;
