
import React from 'react';
import classnames from 'classnames';

import Tooltip from 'components/Tooltip';
import { renderJoinChatTooltip } from './utils';

const ButtonJoinChatTooltip = ({ isDisabledJoinChat, order, user, onJoinChatWithEvent, isButtonSuccess = false }) => (
  <Tooltip className="top-btn-tooltip tooltip-with-list" isHtml content={renderJoinChatTooltip(isDisabledJoinChat, order, user)}><button className={classnames('btn btn-bright btn-md2 mb15 ml10', { 'btn-success': isButtonSuccess })} type="button" disabled={isDisabledJoinChat} onClick={onJoinChatWithEvent()}>Join chat</button></Tooltip>
);

export default ButtonJoinChatTooltip;
