import React from 'react';

import Overview from 'components/profile/Overview';

const IssueSources = ({
  onCommentChange,
  sourcesComment,
}) => (
  <div className="issue-comment">
    <Overview
      onChangeFields={onCommentChange}
      value={sourcesComment}
      fieldName="sourcesComment"
      placeholder="If you have any additional comments, please let us know"
      cntSymbols={150}
    />
  </div>
);

export default IssueSources;
