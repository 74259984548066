import React, { Component } from 'react';
import WarningsTableRow from './WarningsTableRow';
import WarningTableSortingHead from './WarningTableSortingHead';

export default class WarningsTable extends Component {
  render() {
    const { warnings, sorting, setSorting } = this.props;
    return (
      <div className='warning-collapse-table'>
        <div className='warning-collapse-table-header'>
          <div className='warning-collapse-table-header-order'>
            <WarningTableSortingHead sorting={sorting} setSorting={setSorting} field='order'>Order</WarningTableSortingHead>
          </div>
          <div className='warning-collapse-table-header-created'>
            <WarningTableSortingHead sorting={sorting} setSorting={setSorting} field='created_at'>Created</WarningTableSortingHead>
          </div>
          <div className='warning-collapse-table-header-closed'>
            <WarningTableSortingHead sorting={sorting} setSorting={setSorting} field='closed_at'>Closed</WarningTableSortingHead>
          </div>
          <div className='warning-collapse-table-header-status'>
            <WarningTableSortingHead sorting={sorting} setSorting={setSorting} field='status'>Status</WarningTableSortingHead>
          </div>
        </div>
        {
          warnings.map(item => (
            <WarningsTableRow key={item._id} warning={item} />
          ))
        }
      </div>
    )
  }
}
