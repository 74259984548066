import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';
import utils from 'utils';
import convertMinsToHrsMins from 'utils/convertMinsToHrsMins';
import calcNowTime from 'utils/calcNowTime';

import { SIZES_ORDER } from 'constants/order';
import { MAX_VIEW_ITEMS } from 'constants';

import { ordersRevisionFetchIfNeeded, ordersRevisionSetSorting, setRevisionMessageFilter, ordersRevisionFetch } from 'store/ordersRevision/actions';
import { getOrdersRevisionSorted } from 'store/ordersRevision/selectors';
import { getOrderIdsWithUnreadMessages, getHasUnreadChatMessagesOrdersRevision } from 'store/notification/selectors';

import { StickyContainer, StickyHead, StickyBody } from './Sticky';
import Icon from './Icon';
import Emoji from './Emoji';
import OrdersRevisionTableFilter from './OrdersRevisionTableFilter';
import TableSortingHead from './TableSortingHead';
import UpToButton from './UpToButton';

const renderDeadline = (value) => {
  const momentDeadLine = moment(value);
  const hoursDiff = Math.round(momentDeadLine.diff(calcNowTime(), 'hours', true));

  if (hoursDiff < 0 || hoursDiff >= 96) {
    return (<span>{formatTime(momentDeadLine, 'dt')}</span>);
  }
  const className = classNames({ 'text-warning': hoursDiff < 24 });
  return (
    <span>
      {`${formatTime(momentDeadLine, 'dt')} / `}
      <b className={className}>{hoursDiff}h</b>
    </span>
  );
};

const renderPayout = (value, payoutDateMin) => {
  const cntAddDays = payoutDateMin ? 10 : 15;
  const payoutDate = moment(value).add(cntAddDays, 'days');
  return (
    <span>{formatTime(payoutDate, 'd')}</span>
  );
};

class OrdersRevisionTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: MAX_VIEW_ITEMS,
      isSearch: false,
    };

    this.onMoreClick = this.onMoreClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    const { loadOrders } = this.props;
    loadOrders();
  }

  onSearchChange(isSearch) {
    this.setState(state => Object.assign({}, state, {
      isSearch,
    }));
  }

  onMoreClick() {
    const { orderList } = this.props;
    this.setState(state => Object.assign({}, state, {
      count: Math.min(state.count + MAX_VIEW_ITEMS, orderList.length),
    }));
  }

  hasUnread(orderId) {
    const { unreadOrderIds } = this.props;
    return unreadOrderIds.indexOf(orderId) > -1;
  }

  renderRow(order) {
    const {
      onSelect, selectedNumber, profile, payoutDateMin, isDe,
      diffBetweenServerClientTime,
    } = this.props;
    const className = classNames('table-row row vertical', { active: selectedNumber === order.number });
    const isStem = utils.isStem(profile);

    let isSizeTooltip = false;
    let orderSizes = null;
    let orderSizeMH = null;

    if (order.is_complex && order.extras && order.extras.length > 0) {
      orderSizes = order.extras.filter(s => s.size && s.payment_status === 'Paid');
      if (orderSizes.length > 1) {
        isSizeTooltip = true;
        orderSizes = orderSizes.map((s) => {
          const [size, type] = s.size.split(' ');
          if (type === 'minutes' && !isDe) {
            return convertMinsToHrsMins(size, false, ['h', 'm']);
          }
          return size + SIZES_ORDER[type];
        }).join(' /');
      } else {
        orderSizes = orderSizes.map(s => s.size).toString();
      }
    }

    if (order.size && !isDe) {
      const [size, type] = order.size.split(' ');
      if (type === 'minutes') {
        orderSizeMH = convertMinsToHrsMins(size);
      }
    }

    return (
      <div className={className} key={order._id} onClick={() => onSelect(order.number)}>
        <div className="col-4">
          {order.number}
          <a href={`/order/${order._id}`} target="_blank" rel="noopener noreferrer">
            <Icon className="svg-icon" iconName="open-new-window" />
          </a>
        </div>
        <div className="col-3 text-ellipsis no-wrap">{order.subject}</div>
        <div className="col-3">{order.type}</div>
        <div className="col-2">
          {isStem ?
                utils.getMinutesSize(orderSizes || order.size) :
                `$${order.writer_price.toFixed(2)}`
              }
        </div>
        {isSizeTooltip &&
        <div className="col-2 block tooltip tooltip-top tooltip-table">
          <a className="tooltip-trigger">{orderSizes}</a>
          <div className="tooltip-content">
            {orderSizes}
          </div>
        </div>
            }
        {!isSizeTooltip &&
        <div className="col-2">
          {orderSizes || orderSizeMH || order.size}
        </div>
            }
        <div className="col-4">{renderDeadline(order.revision_deadline, diffBetweenServerClientTime)}</div>
        {!isStem &&
          <div className="col-3">{renderPayout(order.writer_deadline, payoutDateMin)}</div>
        }
        <div className="col-2 ta-center">
          {order.has_revision_paper &&
            <Icon className="svg-icon success" iconName="check-fill" />
          }
        </div>
      </div>
    );
  }

  renderOrderList() {
    const { orderList, isLoading } = this.props;
    const { count } = this.state;

    if (isLoading) {
      return (<div className="preloading preloading-box"><p>Loading</p></div>);
    }

    if (!orderList.length) {
      return (
        <div className="page-not-result">
          <Emoji id="crySad" />
          <h1>Oops, there are no orders in this section.</h1>
          <p className="notes">Only revision orders are shown here.</p>
        </div>
      );
    }

    return orderList.slice(0, count).map(order => this.renderRow(order));
  }

  renderNextButton() {
    const { orderList, isLoading } = this.props;

    if (!orderList.length || isLoading) {
      return null;
    }

    const { count } = this.state;
    const nextCount = Math.min(orderList.length - count, MAX_VIEW_ITEMS);
    if (nextCount <= 0) {
      return null;
    }
    return (
      <button type="button" className="btn btn-show-next" onClick={this.onMoreClick}>Show next {nextCount} orders</button>
    );
  }

  render() {
    const { isSearch } = this.state;
    const { setSorting, sorting, profile } = this.props;
    const isStem = utils.isStem(profile);

    return (
      <div className="table orders-table">
        <StickyContainer className="sticky-table">
          <StickyHead className="sticky-table-head" classNameFixed="sticky-table-head-fixed">
            <OrdersRevisionTableFilter isSearch={isSearch} onSearchChange={this.onSearchChange} className="table-filter" />
            <div className="table-header">
              <div className="row">
                <div className="col-4">Order Number</div>
                <div className="col-3">Subject</div>
                <div className="col-3">Type of work</div>
                <div className="col-2">
                  <TableSortingHead sorting={sorting} setSorting={setSorting} field={isStem ? 'pages' : 'writer_price'}>Your price</TableSortingHead>
                </div>
                <div className="col-2">
                  <TableSortingHead sorting={sorting} setSorting={setSorting} field="pages">Size</TableSortingHead>
                </div>
                <div className="col-4">
                  <TableSortingHead sorting={sorting} setSorting={setSorting} field="revision_deadline">Deadline / time left</TableSortingHead>
                </div>
                {!isStem &&
                  <div className="col-3">
                    <TableSortingHead sorting={sorting} setSorting={setSorting} field="payoutDateComputed">Payout date</TableSortingHead>
                  </div>
                }
                <div className="col-2 ta-center">
                  <TableSortingHead sorting={sorting} setSorting={setSorting} field="has_revision_paper">Revision <br /> paper</TableSortingHead>
                </div>
              </div>
            </div>
          </StickyHead>
          <StickyBody className="sticky-table-body">
            {this.renderOrderList()}
          </StickyBody>
        </StickyContainer>
        <UpToButton />
        {this.renderNextButton()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const orders = state.ordersRevision;
  const userGroup = state.user && state.user.group_name;
  const payoutDateMin = (userGroup === 'A' || userGroup === 'A+' || userGroup === 'B' || userGroup === 'C') || false;
  const settings = state.settings || {};

  return {
    isLoading: orders.isFetching,
    orderList: getOrdersRevisionSorted(state),
    sorting: orders.sorting,
    unreadOrderIds: getOrderIdsWithUnreadMessages(state),
    profile: state.user,
    payoutDateMin,
    hasRevisionChatNotifications: getHasUnreadChatMessagesOrdersRevision(state),
    diffBetweenServerClientTime: settings.diffBetweenServerClientTime,
  };
};

const mapDispatchToProps = dispatch => ({
  loadOrders: () => dispatch(ordersRevisionFetchIfNeeded()),
  forceLoadOrders: filter => dispatch(ordersRevisionFetch(filter)),
  setSorting: value => dispatch(ordersRevisionSetSorting(value)),
  setMessageFilter: value => dispatch(setRevisionMessageFilter(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersRevisionTable);
