import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Star from './Star';

const ratingValues = [1, 2, 3, 4, 5];

const getStarFilled = (starValue, rating) => {
  if ((rating - starValue) >= 0) {
    return 1;
  }
  if ((rating - starValue) < 0 && (rating - starValue) > -1) {
    return (rating - starValue) + 1;
  }
  if ((rating - starValue) <= -1) {
    return 0;
  }
};

export default class StarRating extends Component {
  constructor(props) {
    super(props);
    const rate = props.rate || 0;
    this.state = {
      rating: rate || 0,
    };

    this.instanceKey = uuidv4();

    this.onClickStar = this.onClickStar.bind(this);
    this.onStarOver = this.onStarOver.bind(this);
    this.onStarOut = this.onStarOut.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ rating: props.rate });
  }

  onStarOver(rating) {
    if (!this.props.isEditing) return;
    this.setState({ rating });
  }

  onStarOut() {
    if (!this.props.isEditing) return;
    const rating = this.props.rate;
    this.setState({ rating });
  }

  onClickStar(rating) {
    if (!this.props.isEditing) return;
    this.props.onSetMark(rating);
  }

  render() {
    const { rating } = this.state;
    const { isEditing, width, customFill } = this.props;
    return (
      <div style={{ width: width }}>
        {
          ratingValues.map(value => (
            <Star
              key={value}
              rate={value}
              instanceKey={this.instanceKey}
              customFill={customFill}
              filled={getStarFilled(value, rating)}
              isEditing={isEditing}
              onClick={this.onClickStar}
              onStarOver={this.onStarOver}
              onStarOut={this.onStarOut}
            />
          ))
        }
      </div>
    );
  }
}

StarRating.defaultProps = {
  width: 165,
};
