import React from 'react';
import { connect } from 'react-redux';

import utils from 'utils';

import { ordersSetStatusCompletedFetch } from 'store/orders/actions';

const OrderCompleteButton = (props) => {
  const {
    loading, updating, wasCompleted, canComplete, complete, isNTorder,
  } = props;
  const text = wasCompleted ? 'Order submitted' : 'Submit order';
  const disabled = loading || updating;

  const renderTooltip = () => {
    if (canComplete || wasCompleted) {
      const tooltipText = wasCompleted ? (<div>
        Once the order deadline is reached it will be sent to the Client.
      </div>) : <span>You are about to confirm that you finished with this order and all uploaded files are final.</span>;

      return (
        <div className="tooltip-content">
          {tooltipText}
        </div>
      );
    }

    return null;
  };

  if (!isNTorder) return null;

  return (
    <div className="tooltip tooltip-vmiddle tooltip-top tooltip-right">
      <button className="btn btn-bright-success tooltip-trigger" disabled={disabled} onClick={complete}>{text}</button>
      {renderTooltip()}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const order = state.orders[ownProps.orderNumber];
  const loading = order.isFetching;
  const updating = order.isFetchingStatus;
  const wasCompleted = order.completed;
  // const canComplete = order.can_complete;
  const canComplete = true;
  const isNTorder = utils.isNTorder(order);

  return {
    loading, updating, wasCompleted, canComplete, isNTorder,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  complete: () => dispatch(ordersSetStatusCompletedFetch(ownProps.orderNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCompleteButton);
