import React, { Fragment } from 'react';

const PollWelcomeBlock = ({ isWeekPoll }) => (
  <Fragment>
    <h2 className="title fs15">
        Hi there <i className="poll-modal__icon poll-modal__icon--hi" />!<br />
      {isWeekPoll ?
          'Have a really quick question for you'
          :
          'Let`s set up plans for the next month'
        }
    </h2>
    <p className="poll-modal__second-title">
      {isWeekPoll ?
        <Fragment>How many pages are you planning<br />to complete during the next 7 days?</Fragment>
          :
        <Fragment>How many pages are you planning<br />to complete?</Fragment>
        }
    </p>
  </Fragment>
);

export default PollWelcomeBlock;
