import moment from 'moment-timezone';

const convertTimeFilter = (({ time, search, new_messages }, isSearch = false) => {
  if (isSearch) return { search };
  let returnFilter = {};

  if (time === 'thisMonth') returnFilter = { date__gte__writer_completed_at: moment().startOf('month').format('YYYY-MM-DD'), date__lte__writer_completed_at: moment().add(1, 'months').startOf('month').format('YYYY-MM-DD') };
  if (time === 'lastMonth') returnFilter = { date__gte__writer_completed_at: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), date__lte__writer_completed_at: moment().startOf('month').format('YYYY-MM-DD') };
  if (time === 'thisYear') returnFilter = { date__gte__writer_deadline: moment().startOf('year').format('YYYY-MM-DD') };
  if (time === 'lastYear') returnFilter = { date__lte__writer_deadline: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD') };

  if (new_messages) return { ...returnFilter, new_messages: true };

  return returnFilter;
});

export default convertTimeFilter;
