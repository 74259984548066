import thunkMiddleware from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { localeReducer } from 'react-localize-redux';

import socketMiddleware from '../middleware/socketMiddleware';

import orders from './orders/reducers';
import orderSingle from './orderSingle/reducers';
import ordersFind from './ordersFind/reducers';
import ordersFilteredFind from './ordersFilteredFind/reducers';
import modals from './modals/reducers';
import ordersProgress from './ordersProgress/reducers';
import ordersRevision from './ordersRevision/reducers';
import ordersComplete from './ordersComplete/reducers';
import ordersRefunded from './ordersRefunded/reducers';
import settings from './settings/reducers';
import user from './user/reducers';
import notices from './notices/reducers';
import finances from './finances/reducers';
import help from './help/reducers';
import updates from './updates/reducers';
import notification from './notification/reducers';
import ticketsSettings from './ticketsSettings/reducers';
import alerts from './alerts/reducers';
import { tickets, newTicketFiles, ticket } from './tickets/reducers';
import { feedbacks } from './feedbacks/reducers';
import ordersNotifications from './ordersNotifications/reducers';
import clientSessions from './clientSessions/reducers';
import plugins from './plugins/reducers';
import clientChatNotifications from './clientChatNotifications/reducers';
import verifyModal from './verifyModal/reducers';
import previewImages from './previewImages/reducers';
import banners from './banners/reducers';
import inboxUpdates from './inboxUpdates/reducers/inboxUpdates';
import inboxUpdateDetail from './inboxUpdates/reducers/inboxUpdateDetail';

const rootReducer = combineReducers({
  locale: localeReducer,
  orders,
  orderSingle,
  ordersFind,
  ordersFilteredFind,
  ordersProgress,
  ordersRevision,
  ordersComplete,
  ordersRefunded,
  settings,
  user,
  notices,
  finances,
  help,
  updates,
  notification,
  tickets,
  newTicketFiles,
  ticket,
  ticketsSettings,
  modals,
  ordersNotifications,
  alerts,
  feedbacks,
  plugins,
  clientChatNotifications,
  clientSessions,
  verifyModal,
  previewImages,
  banners,
  inboxUpdates,
  inboxUpdateDetail,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunkMiddleware, socketMiddleware()];

const configureStore = preloadedState => (
  createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware)),
  )
);

export default configureStore;
