import { useEffect, useRef } from 'react';

/*
how to use

const isFirstRender = useFirstRender();
useEffect(() => {
  if (!isFirstRender) onFilterOrders();
}, [stateFilter.subjectGroups]);
*/

const useFirstRender = () => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
};

export default useFirstRender;
