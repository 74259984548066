import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNotificationListQuery } from '@connect/connect-xmpp';

import { subscribeChatNotifications } from 'store/clientChatNotifications/actions';

import { getOrdersWithJabberNode } from 'store/orders/selectors';
import { getProfile } from 'store/user/selectors';

const ClientChatNotifications = () => {
  const jabberNodes = useSelector(getOrdersWithJabberNode);
  const user = useSelector(getProfile);

  const { jabber_jid: jid } = user;

  if (!jid || (jabberNodes || []).length === 0) return null;

  return (<ClientChatNotificationsContainerMemo jabberNodes={jabberNodes} />);
};

export default ClientChatNotifications;


const ClientChatNotificationsContainer = ({ jabberNodes }) => {
  const dispatch = useDispatch();
  const {
    fetchNotificationList, nodes = [], hasNotification = false, isReadyToRequest,
  } = useNotificationListQuery();

  const ordersNodes = useMemo(() => jabberNodes.map(order => ({ node: order.jabber_node, to: order.jabber_server })), [jabberNodes]);

  useEffect(() => {
    const __nodes = nodes.map((node) => {
      const __order = jabberNodes.find(order => order.jabber_node === node.node) || {};
      const { number, _id: orderId } = __order;

      return {
        ...node,
        ...{
          number,
          orderId,
        },
      };
    });
    dispatch(subscribeChatNotifications({ nodes: __nodes, hasNotification }));
  }, [nodes, hasNotification]);

  useEffect(() => {
    if (!isReadyToRequest) return;
    fetchNotificationList(ordersNodes);
  }, [ordersNodes.length, isReadyToRequest]);

  return null;
};

const areEqual = (prev, next) => {
  if (!next.jabberNodes || !prev.jabberNodes) return true;
  return prev.jabberNodes.length === next.jabberNodes.length;
};

const ClientChatNotificationsContainerMemo = React.memo(ClientChatNotificationsContainer, areEqual);
