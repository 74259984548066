import React, { Component } from 'react';
import { UnmountClosed } from 'react-collapse';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { STATUS_PROGRESS } from 'store/orders/actions';
import { setPlugin } from 'store/plugins/actions';
import { addModalComponent } from 'store/modals/actions';

import Icon from 'components/Icon';
import UploadButton from 'components/UploadButton';
import FilesPreview from 'components/FilesPreview';

import utils from 'utils';
import classNames from 'utils/classNames';
import { onDownloadAll as onDownloadAllHandler } from 'utils/downloadFile';

import DeselectControls from 'components/order/OrderFileList/DeselectControls';
import FinalPaperFileRow from 'components/order/FinalPaperFileRow';
import ModalFilesProgress from 'components/order/ModalFilesProgress';

class OrderDraftPaperList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      downloadFetching: false,
      selectedFiles: [],
    };

    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDownloadAll = this.onDownloadAll.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.clearSelect = this.clearSelect.bind(this);
    this.openFilePreviewHandler = this.openFilePreviewHandler.bind(this);
    this.fileRowClick = this.fileRowClick.bind(this);
  }

  componentDidMount() {
    const { order = {} } = this.props;
    const { no_sensitive: noSensitive = false } = order;
    if (noSensitive) {
      this.setState({
        isOpen: false,
      });
    }
  }

  onDownloadAll(e) {
    if (e) e.stopPropagation();

    const { fileList, fileWebhook, orderNumber } = this.props;
    const { selectedFiles } = this.state;
    this.setState({ downloadFetching: true });
    onDownloadAllHandler({
      fileList,
      fileWebhook,
      orderNumber,
      selectedFiles,
    })
      .finally(() => {
        this.setState({ downloadFetching: false });
        this.clearSelect();
      });
  }

  onChange(event) {
    const { uploadFile } = this.props;
    const fileList = event.target.files;
    for (let i = 0; i < fileList.length; i++) {
      uploadFile(fileList[i], 'file_draft_paper');
    }
    event.target.value = '';
  }

  fileRowClick(e, file) {
    e.preventDefault();
    e.stopPropagation();

    this.openFilePreviewHandler(e, file._id);
  }

  toggle() {
    this.setState(state => Object.assign({}, state, {
      isOpen: !state.isOpen,
    }));
  }

  clearSelect(e) {
    if (e) e.stopPropagation();

    this.setState({ selectedFiles: [] });
  }

  selectFile(e, id) {
    e.stopPropagation();
    const { selectedFiles } = this.state;

    if (selectedFiles.includes(id)) {
      this.setState({ selectedFiles: selectedFiles.filter(file => file !== id) });
      return;
    }

    this.setState({ selectedFiles: [...selectedFiles, id] });
  }

  openFilePreviewHandler(e, fileID) {
    const {
      openFilePreview, fileList, orderNumber, fileWebhook, addModal,
    } = this.props;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const sortedList = fileList
      .filter(file => !file.deleted)
      .map(file => ({ ...file, id: file._id }));
    const activeIndex = sortedList.findIndex(file => file.id === fileID);
    const isMobile = utils.detectMob();

    if (isMobile) {
      addModal(<FilesPreview docs={sortedList} activeIndex={activeIndex} />, {
        overFlowHidden: true,
      });
      return;
    }

    openFilePreview({
      plugin: 'FilePreviewPlugin',
      data: {
        docs: sortedList,
        orderNumber,
        activeIndex,
        fileWebhook,
      },
    });
  }

  render() {
    const {
      fileList,
      onResize,
      status,
      removeFile,
      uploadFile,
      // orderNumber,
      // hasParallelOrder,
      disabled,
      order = {},
    } = this.props;
    const { isOpen, selectedFiles, downloadFetching } = this.state;
    const { no_sensitive: noSensitive = false } = order;

    if (!fileList.length && !noSensitive) {
      if (status !== STATUS_PROGRESS) {
        return null;
      }
      return (
        <div className="ta-right mt20">
          <UploadButton className="btn btn-bright" multiple onChange={this.onChange} disabled={disabled}>Upload draft</UploadButton>
        </div>
      );
    }
    const sortedList = fileList.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));

    const filesStr = `${sortedList.length} ${sortedList.length === 1 ? 'file' : 'files'}`;

    return (
      <div className="order-tab-collapse">
        <div className={classNames('order-tab-collapse-head row space-between', { 'disabled-no-cursor': noSensitive })} onClick={!noSensitive && this.toggle}>
          <div>
            <Icon className="svg-icon" iconName="clip" />
            <span className="order-tab-head-name">Draft papers ({filesStr})</span>
          </div>
          {isOpen
            ? <span className="arrow-down active" />
            : <span className="arrow-down" />
          }
          {
            isOpen && !!selectedFiles.length && <DeselectControls selectedFiles={selectedFiles} onDownloadAll={this.onDownloadAll} downloadFetching={downloadFetching} clearSelect={this.clearSelect} />
          }
          <ModalFilesProgress fileList={fileList} uploadFile={uploadFile} fileInputRef={this.fileInputRef} removeFile={removeFile} />
        </div>

        <UnmountClosed isOpened={isOpen} className="order-tab-collapse-body" onRender={onResize}>
          <div className="order-tab-content">
            {status === STATUS_PROGRESS &&
              <div className="row space-between vertical mb15">
                <div>{filesStr} uploaded</div>
                <div>
                  <UploadButton className="btn btn-bright" multiple onChange={this.onChange} inputRef={(ref) => { this.fileInputRef = ref; }} disabled={disabled}>Upload draft more</UploadButton>
                </div>
              </div>
            }

            <div>
              {sortedList.map(file =>
                (<FinalPaperFileRow
                  selectedFiles={selectedFiles}
                  selectFile={this.selectFile}
                  file={file}
                  key={file._id}
                  onRemove={status === STATUS_PROGRESS && removeFile}
                  openFilePreviewHandler={this.openFilePreviewHandler}
                  fileRowClick={this.fileRowClick}
                />))}
            </div>
          </div>
          {sortedList.length > 0 &&
            <div className="row flex-end mb10">
              <button
                className={classNames('btn btn-light btn-md btn-download-all', { hide: !!selectedFiles.length })}
                onClick={this.onDownloadAll}
                disabled={downloadFetching}
              >
                {downloadFetching ? 'Loading...' : 'Download all'}
              </button>
              <div className="clear" />
            </div>
          }
        </UnmountClosed>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openFilePreview: data => dispatch(setPlugin(data)),
  addModal: (component, modalOptions) => dispatch(addModalComponent(component, modalOptions)),
});


export default connect(null, mapDispatchToProps)(OrderDraftPaperList);
