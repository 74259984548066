import React, { Component } from 'react';
import moment from 'moment-timezone';

import classNames from './../../utils/classNames';

const formatDate = (value) => {
  const momentObj = moment.isMoment(value) ? value : moment(value);
  return momentObj.format('MMM D, YYYY');
};


export default class WarningsTableRow extends Component {
  constructor(props) {
    super(props);

    this.getClosedTimeOrOrders = this.getClosedTimeOrOrders.bind(this);
  }
  getClosedTimeOrOrders() {
    const { orders_live, closed_at } = this.props.warning;
    if (closed_at) {
      return formatDate(closed_at);
    }
    if (orders_live) {
      return orders_live === 1 ?
        `${orders_live} order` :
        `${orders_live} orders`;
    }
    return '';
  }
  render() {
    const { warning } = this.props;
    const { orders_live } = warning;
    const isActive = warning.status.toLowerCase() === 'active';
    const statusClassName = classNames('warning-collapse-table-row-status', {
      active: isActive,
    });
    const createdClassName = classNames('warning-collapse-table-row-created', {
      active: isActive,
    });
    const closedClassName = classNames('warning-collapse-table-row-closed', {
      active: isActive,
      'orders-leave': orders_live,
    });
    return (
      <div className="warning-collapse-table-row">
        <div className="warning-collapse-table-row-order">{(warning.order_info && warning.order_info.number) || ''}</div>
        <div className={createdClassName}>{formatDate(warning.created_at)}</div>
        <div className={closedClassName}>{this.getClosedTimeOrOrders()}</div>
        <div className={statusClassName}>{warning.status}</div>
      </div>
    )
  }
}
