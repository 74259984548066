import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';

import {
  ORDERS_TYPE_ALL,
  ORDERS_TYPE_FAVORITE,
  ORDERS_TYPE_RESERVED,
} from './orderTypes';
import { sortByOrderNumber, getSorterByField } from './../orderSorters';
import utils from './../../utils';
import { writerTags } from '../../constants/index';

const stable = require('stable');

const TAG_WEIGHTS = {
  isHot: 0,
  isNew: 0,
  isSubjects: 0,
  isUpdated: 0,
  isDraft: 0,
  isPrioritized: 0,
  isNt: 0,
  isInstrFixed: 80,
  isFeature: 80,
  isSuggested: 85,
  isAutoSuggested: 85,
  isVip: 90,
  isRecommended: 98,
  isRecommendedByClient: 99,
  isNewWS: 100,
  isPinned: 200,
  isInChat: 499,
  isReservedByMe: +Infinity,
};

const orderWeightCriteria = {
  isUpdated: order => (order.custom_tags && order.custom_tags.includes('instr_updated')),
  isInstrFixed: order => (order.custom_tags && (order.custom_tags.includes('instr_fixed') || order.custom_tags.includes('instr_fixed_minor') || order.custom_tags.includes('instr_not_ok_crucial_fixed') || order.custom_tags.includes('instr_not_ok_fixed'))),
  isVip: order => (order.tags && order.tags.includes('first_order_paid')),
  isDraft: order => (order.tags && order.tags.includes('draft_needed')),
  isPrioritized: (order, userGroup) => (order.custom_tags && order.custom_tags.includes('prioritized') && (userGroup === 'A' || userGroup === 'A+')),
  isRecommended: order => order.is_recommended && !order.recomended_by_client,
  isFeature: (order, myGroup) => order.custom_tags && myGroup && (order.custom_tags.includes('prioritize_my_task') || order.custom_tags.includes('top_writer')),
  isAutoSuggest: order => (order.tags && order.tags.includes('auto_suggest')),
};

export const getUserGroup = state => state.user.group_name;
const getUserStatus = state => state.user.view_status;
const getIsStem = state => utils.isStem(state.user);
const getUserIsDe = state => state.user.profile_type === 'D';

export const getOrdersAll = state => state.ordersFind.numbers.map(number => state.orders[number]).filter(item => item !== undefined);
const getOrdersFavorite = state => state.ordersFind.numbers.map(number => state.orders[number]).filter((order = {}) => order.is_favorite);
const getOrdersReserved = state => state.ordersFind.numbers.map(number => state.orders[number]).filter((order = {}) => order.reserved_by_me);
export const getOrderWithoutPinned = state => state.ordersFind.numbers.map(number => state.orders[number]).filter((order = {}) => !order.reserved_by_me && !order.is_pinned);
export const getNewOrders = state => state.ordersFind.numbers.map(number => state.orders[number]).filter((order = {}) => order.is_new_order);
export const getPinnedOrders = state => state.ordersFind.numbers.map(number => state.orders[number]).filter((order = {}) => order.is_pinned);
// const getFilteredOrders = state => state.ordersFilteredFind.numbers.map(number => state.orders[number]).filter(order => !order.is_pinned && !order.reserved_by_me);

const filterOrdersByUserGroup = (orderList, userGroup, userStatus) => {
  if (userGroup === 'A' || userGroup === 'A+' || userGroup === 'B' || userGroup === 'C' || userStatus === 'Fulltimer' || userStatus === 'Stem') {
    return orderList;
  }
  return orderList.filter(o => (o.custom_tags && o.custom_tags.indexOf('important') === -1) || (o.custom_tags && o.custom_tags.indexOf('important') > -1 && (o.is_recommended || o.is_suggested)));
};

const isOrderReservedByMe = order => order.reserved_by_me;
const isOrderInChat = order => order.writer_in_chat && order.current_writer_in_chat;

const isNotFilterOrder = order => order.is_pinned || isOrderReservedByMe(order) || order.is_new_order;

const getOrderList = {
  [ORDERS_TYPE_ALL]: createSelector([getOrdersAll, getUserGroup, getUserStatus], filterOrdersByUserGroup),
  [ORDERS_TYPE_FAVORITE]: createSelector([getOrdersFavorite, getUserGroup, getUserStatus], filterOrdersByUserGroup),
  [ORDERS_TYPE_RESERVED]: createSelector([getOrdersReserved, getUserGroup, getUserStatus], filterOrdersByUserGroup),
};

// const getFilteredOrderList = {
//   [ORDERS_TYPE_ALL]: createSelector([getFilteredOrders, getUserGroup, getUserStatus], filterOrdersByUserGroup),
//   [ORDERS_TYPE_FAVORITE]: createSelector([getOrdersFavorite, getUserGroup, getUserStatus], filterOrdersByUserGroup),
//   [ORDERS_TYPE_RESERVED]: createSelector([getOrdersReserved, getUserGroup, getUserStatus], filterOrdersByUserGroup),
// };

const getFilter = {
  [ORDERS_TYPE_ALL]: state => state.ordersFind.filter[ORDERS_TYPE_ALL],
  [ORDERS_TYPE_FAVORITE]: state => state.ordersFind.filter[ORDERS_TYPE_FAVORITE],
  [ORDERS_TYPE_RESERVED]: state => state.ordersFind.filter[ORDERS_TYPE_RESERVED],
};

const getSorting = state => state.ordersFind.sorting;

const getOrderSubjects = state => state.settings.orderSubjects || [];
const getOrderSubjectGroups = state => state.settings.orderSubjectGroups || {};
const getOrderTypes = state => state.settings.orderTypes || [];
const getOrderDeadlines = state => state.settings.orderTimes || [];
const getMySubjects = state => state.user.subjects || [];
const getMyTimeZone = state => state.user.timezone_str || null;
export const getMyGroup = state => state.user.group_name === 'B' || state.user.group_name === 'A' || state.user.group_name === 'A+' || null;

export const getOrdersFind = {
  [ORDERS_TYPE_ALL]: createSelector([getOrderList[ORDERS_TYPE_ALL]], orderList => orderList),
  [ORDERS_TYPE_FAVORITE]: createSelector([getOrderList[ORDERS_TYPE_FAVORITE]], orderList => orderList),
  [ORDERS_TYPE_RESERVED]: createSelector([getOrderList[ORDERS_TYPE_RESERVED]], orderList => orderList),
};

export const getOrdersFindCounts = createSelector(
  [getOrderList[ORDERS_TYPE_ALL]],
  orderList => ({
    all: orderList.length,
    favorite: orderList.filter((order = {}) => order.is_favorite).length,
    reserved: orderList.filter((order = {}) => order.reserved_by_me).filter(order => !order.current_writer_in_chat).length,
  }),
);

const subjectsCounter = (orderSubjects, orderList = []) => {
  let orderSubjectsNew = {};

  orderSubjects.map((orderSubject) => {
    orderSubjectsNew = {
      ...orderSubjectsNew,
      [orderSubject]: {
        name: orderSubject,
        count: orderList.filter(order => orderSubject.toLowerCase() === (order.subject || '').toLowerCase() && !order.is_pinned && !order.reserved_by_me).length,
      },
    };
  });

  // orderList.filter(o => !o.is_pinned && !o.reserved_by_me && o.is_subject_other).map((o) => {
  //   const subj = o.subject;
  //   // console.log('subj: ', subj);
  //   const count = (orderSubjectsNew[subj] && orderSubjectsNew[subj].count) || 0;
  //   orderSubjectsNew = {
  //     ...orderSubjectsNew,
  //     [subj]: {
  //       name: subj,
  //       count: count + 1,
  //     },
  //   };
  //   // console.log('orderSubjectsNew: ', orderSubjectsNew);
  // });


  return orderSubjectsNew;
};

// const subjectsCounter = (orderSubjects, orderList) => orderSubjects.map(orderSubject => ({
//   name: orderSubject,
//   count: orderList.filter(order => orderSubject.toLowerCase() === (order.subject || '').toLowerCase()).length,
// }));

export const getOrdersSubjectsWithCount = {
  [ORDERS_TYPE_ALL]: createSelector([getOrderSubjects, getOrderList[ORDERS_TYPE_ALL]], subjectsCounter),
  [ORDERS_TYPE_FAVORITE]: createSelector([getOrderSubjects, getOrderList[ORDERS_TYPE_FAVORITE]], subjectsCounter),
  [ORDERS_TYPE_RESERVED]: createSelector([getOrderSubjects, getOrderList[ORDERS_TYPE_RESERVED]], subjectsCounter),
};

const subjectGroupsCounter = (orderSubjectGroups, orderList) => {
  const subjectGroupList = Object.keys(orderSubjectGroups).sort();
  let subjectGroupListNew = {};
  subjectGroupList.map((subjectGroup) => {
    const count = orderList.filter((order) => {
      const groupSubjectList = orderSubjectGroups[subjectGroup];
      const subject = (order.subject || '').toLowerCase();
      return !!groupSubjectList.find(groupSubject => groupSubject.toLowerCase() === subject && !order.is_pinned && !order.reserved_by_me);
    }).length;

    subjectGroupListNew = {
      ...subjectGroupListNew,
      [subjectGroup]: {
        name: subjectGroup,
        count,
      },
    };
  });

  // subjectGroupListNew.Other = { name: 'Other', count: orderList.filter(o => o.is_subject_other).length };

  return subjectGroupListNew;
};

export const getOrdersSubjectsByGroupWithCount = {
  [ORDERS_TYPE_ALL]: createSelector([getOrderSubjectGroups, getOrderList[ORDERS_TYPE_ALL]], subjectGroupsCounter),
  [ORDERS_TYPE_FAVORITE]: createSelector([getOrderSubjectGroups, getOrderList[ORDERS_TYPE_FAVORITE]], subjectGroupsCounter),
  [ORDERS_TYPE_RESERVED]: createSelector([getOrderSubjectGroups, getOrderList[ORDERS_TYPE_RESERVED]], subjectGroupsCounter),
};

const typesCounter = (orderTypes, orderList) => {
  let newOrderType = {};
  orderTypes.forEach((orderType) => {
    const count = orderList.filter(order => (new RegExp(orderType, 'i')).test(order.type) && !order.is_pinned && !order.reserved_by_me).length;
    newOrderType = {
      ...newOrderType,
      [orderType]: {
        count,
      },
    };
  });
  return newOrderType;
};


export const getOrdersTypesWithCount = {
  [ORDERS_TYPE_ALL]: createSelector([getOrderTypes, getOrderList[ORDERS_TYPE_ALL]], typesCounter),
  [ORDERS_TYPE_FAVORITE]: createSelector([getOrderTypes, getOrderList[ORDERS_TYPE_FAVORITE]], typesCounter),
  [ORDERS_TYPE_RESERVED]: createSelector([getOrderTypes, getOrderList[ORDERS_TYPE_RESERVED]], typesCounter),
};

const calcMomentBetween = (order, deadline) => {
  const wrDeadline = moment(order.writer_deadline);
  const curDate = moment().utc();
  const fromDate = moment(curDate).add(deadline[0], 'hours');
  let isBetween = false;
  let toDate = '';

  if (deadline[1]) {
    toDate = moment(curDate).add(deadline[1], 'hours');
    if (deadline[0] === 0) {
      isBetween = moment(wrDeadline).isBefore(toDate);
    } else {
      isBetween = moment(wrDeadline).isBetween(fromDate, toDate, 'undefined', '[)');
    }
    // isBetween = moment(wrDeadline).isBetween(fromDate, toDate);
  } else {
    isBetween = moment(wrDeadline).isAfter(fromDate);
  }
  return isBetween;
};

const deadlinesCounter = (orderDeadline, orderList) => Object.keys(orderDeadline).map((deadline) => {
  const itemDdl = orderDeadline[deadline].value;
  return ({
    value: itemDdl,
    name: deadline,
    count: orderList.filter(order => calcMomentBetween(order, itemDdl) && !order.is_pinned && !order.reserved_by_me).length,
  });
});

export const getOrdersDeadlineWithCount = {
  [ORDERS_TYPE_ALL]: createSelector([getOrderDeadlines, getOrderList[ORDERS_TYPE_ALL]], deadlinesCounter),
  [ORDERS_TYPE_FAVORITE]: createSelector([getOrderDeadlines, getOrderList[ORDERS_TYPE_FAVORITE]], deadlinesCounter),
  [ORDERS_TYPE_RESERVED]: createSelector([getOrderDeadlines, getOrderList[ORDERS_TYPE_RESERVED]], deadlinesCounter),
};


const filterByTimeDe = (filterTime, orderList) => {
  if (filterTime === 'new') {
    return orderList.filter((order = {}) => order.is_recommended || order.is_suggested || !order.is_viewed);
  }
  const stamp = moment();
  switch (filterTime) {
    case '3hours': {
      stamp.subtract(3, 'hours');
      break;
    }
    case '5hours': {
      stamp.subtract(5, 'hours');
      break;
    }
    case '12hours': {
      stamp.subtract(12, 'hours');
      break;
    }
    case '24hours': {
      stamp.subtract(24, 'hours');
      break;
    }
    case 'yesterday': {
      stamp.startOf('day').subtract(1, 'day');
      break;
    }
    case 'lastWeek': {
      stamp.startOf('week');
      break;
    }
    default:
      return orderList;
  }
  return orderList.filter(item => item.is_recommended || item.is_suggested || moment(item.available_from).isSameOrAfter(stamp));
};

const filterByTime = (filterTime, orderList) => {
  let newOrderList = null;

  filterTime.map((range) => {
    let toDate = '';
    const fromDate = moment().add(range[0], 'hours').format();
    if (range[1]) {
      toDate = moment().add(range[1], 'hours').format();
      orderList.map((o) => {
        if (moment(o.writer_deadline).isBetween(fromDate, toDate, 'undefined', '[)') || isNotFilterOrder(o)) {
          newOrderList = {
            ...newOrderList,
            [o._id]: o,
          };
        }
      });
    } else {
      orderList.map((o) => {
        if (moment(o.writer_deadline).isAfter(fromDate) || isNotFilterOrder(o)) {
          newOrderList = {
            ...newOrderList,
            [o._id]: o,
          };
        }
      });
    }
  });

  return newOrderList ? Object.values(newOrderList) : orderList;
};

const filterByStatuses = (filterStatuses, orderList) => {
  let listStatuses = filterStatuses;
  if (filterStatuses.indexOf(writerTags.IS_SUGGESTED) > -1) {
    listStatuses = [writerTags.IS_SUGGESTED, writerTags.IS_PRIORITIZED];
  }
  return orderList.filter(order => listStatuses.some(filterItem => order[filterItem]) || isNotFilterOrder(order));
};

const filterBySubject = (filterSubject, orderList) => (!filterSubject.length ? orderList : orderList.filter(order => isNotFilterOrder(order) || filterSubject.some(filterItem => filterItem.toLowerCase() === (order.subject || '').toLowerCase())));
const filterBySubjectGroup = (filterSubject, orderList, orderSubjectGroups) => {
  if (!filterSubject.length) {
    return orderList;
  }
  return orderList.filter((order) => {
    if (isNotFilterOrder(order)) {
      return true;
    }
    const filterSubjectItems = filterSubject.reduce((result, item) => [...result, ...orderSubjectGroups[item]], []);
    return filterSubjectItems.some(filterItem => filterItem.toLowerCase() === (order.subject || '').toLowerCase());
  });
};

const filterByType = (filterType, orderList) => (!filterType.length ? orderList : orderList.filter((order = {}) => order.is_pinned || isOrderReservedByMe(order) || filterType.some(filterItem => (new RegExp(filterItem, 'i').test(order.type)))));
const filterByOnline = (filterType, orderList) => (filterType ? orderList : orderList.filter((order = {}) => order.is_pinned || isOrderReservedByMe(order) || !order.is_online_dashboard));
const filterByReservedOther = (filterType, orderList) => (!filterType ?
  orderList.filter(order => (order.is_pinned || isOrderReservedByMe(order)) || !(order.reserved && !order.reserved_by_me)) :
  orderList.filter(order => (order.is_pinned || isOrderReservedByMe(order)) || !(order.reserved && !order.reserved_by_me) || (order.reserved && !order.reserved_by_me)));

// const filterByRecommended = (filterRecommended, orderList) => !filterRecommended ? orderList : orderList.filter(item => item.is_recommended);
// const filterByHot = (filterHot, orderList) => {
//     if (!filterHot) {
//         return orderList;
//     }
//     const momentNow = moment();
//     return orderList.filter((order = {}) => order.is_pinned || moment(order.writer_deadline).diff(momentNow, 'hours') < 24);
// };
// const filterByMy = (filterMy, mySubjects, orderList) => {
//     if (!filterMy || !mySubjects.length) {
//         return orderList;
//     }
//     return orderList.filter((order = {}) => order.is_pinned || mySubjects.some(subject => (new RegExp(subject, 'i').test(order.subject))));
// };

export const checkOnFeReSuPriNt = (order = {}, myGroup, userGroup) => {
  let orderWeight = 0;
  if (orderWeightCriteria.isFeature(order, myGroup)) {
    orderWeight += TAG_WEIGHTS.isFeature;
  }
  if (orderWeightCriteria.isRecommended(order)) {
    orderWeight += TAG_WEIGHTS.isRecommended;
  }
  if (order.is_suggested) {
    orderWeight += TAG_WEIGHTS.isSuggested;
  }
  if (orderWeightCriteria.isPrioritized(order, userGroup)) {
    orderWeight += TAG_WEIGHTS.isPrioritized;
  }
  if (orderWeightCriteria.isInstrFixed(order)) {
    orderWeight += TAG_WEIGHTS.isInstrFixed;
  }
  if (isOrderInChat(order)) {
    orderWeight += TAG_WEIGHTS.isInChat;
  }

  return orderWeight;
};

const calculatetagsWeight = (item = {}, mySubjects, myTimeZone, myGroup, isDe, orderSubjectGroups, userGroup) => {
  let tagsWeight = 0;

  if (myTimeZone) {
    const profileTz = myTimeZone;
    const momentNow = moment.tz(profileTz);
    const momentPaid = moment.tz(item.available_from, profileTz);
    const hoursDiffPaid = momentNow.diff(momentPaid, 'hours', true);

    if (hoursDiffPaid <= 2 && hoursDiffPaid > 0) {
      item.tag_is_new = true;
      tagsWeight += TAG_WEIGHTS.isNew;
    }
  }

  const momentDeadLine = moment(item.writer_deadline);
  const hoursDiff = momentDeadLine.diff(moment(), 'hours');

  if (hoursDiff < 24) {
    item.tag_is_hot = true;
    tagsWeight += TAG_WEIGHTS.isHot;
  }

  if (mySubjects.length) {
    const mySubjectItems = isDe
      ? mySubjects
      : mySubjects.reduce((result, sItem) => {
        if (!orderSubjectGroups[sItem]) {
          console.log('cannot find subject group: ', sItem);
          return result;
        }
        return [...result, ...(orderSubjectGroups[sItem] || {})];
      }, []);
    if (mySubjectItems.some(subject => subject.toLowerCase() === (item.subject || '').toLowerCase())) {
      item.tag_is_subjects = true;
      tagsWeight += TAG_WEIGHTS.isSubjects;
    }
  }

  if (orderWeightCriteria.isUpdated(item)) {
    item.tag_is_updated = true;
    tagsWeight += TAG_WEIGHTS.isUpdated;
  }

  if (orderWeightCriteria.isVip(item)) {
    item.tag_is_vip = true;
    tagsWeight += TAG_WEIGHTS.isVip;
  }

  if (orderWeightCriteria.isDraft(item)) {
    item.tag_draft = true;
    tagsWeight += TAG_WEIGHTS.isDraft;
  }

  if (orderWeightCriteria.isAutoSuggest(item)) {
    tagsWeight += TAG_WEIGHTS.isAutoSuggested;
  }

  tagsWeight += checkOnFeReSuPriNt(item, myGroup, userGroup);

  if (orderWeightCriteria.isFeature(item, myGroup)) {
    item.tag_feature = true;
  }

  if (orderWeightCriteria.isPrioritized(item, userGroup)) {
    item.is_prioritized = true;
  }

  if (item.recomended_by_client) {
    item.tag_trust = true;
    tagsWeight += TAG_WEIGHTS.isRecommendedByClient;
  }

  if (item.is_pinned) tagsWeight += TAG_WEIGHTS.isPinned;

  if (item.is_new_order && !item.is_pinned) tagsWeight += TAG_WEIGHTS.isNewWS;

  if (isOrderReservedByMe(item) && !item.is_pinned) tagsWeight += TAG_WEIGHTS.isReservedByMe;

  return tagsWeight;
};

const setPinnedOrders = (orders, myGroup, userGroup) => {
  let pinned = [];
  orders.forEach((order) => {
    let tagsWeight = 0;
    tagsWeight = checkOnFeReSuPriNt(order, myGroup, userGroup);

    if (tagsWeight > 0) pinned.push({ order, tagsWeight });
  });

  if (pinned.length === 0) return orders;

  pinned = pinned.sort((a, b) => {
    if (a.tagsWeight !== b.tagsWeight) return b.tagsWeight - a.tagsWeight;
    const sorter = getSorterByField('writer_deadline', true);
    return sorter(a.order, b.order);
  });
  pinned = pinned.slice(0, 5).map(o => o.order._id);
  orders.forEach((order) => {
    if (pinned.includes(order._id)) order.is_pinned = true;
    else if (order.is_pinned) order.is_pinned = false;
  });
  return orders;
};

// const filterByPrice = (priceMin, priceMax, orderList) => orderList.filter(item => item.is_recommended || item.is_suggested || (item.writer_price >= priceMin && item.writer_price <= priceMax));
const filterBySearch = (filterSearch, orderList) => (!filterSearch ? orderList : orderList.filter(item => item.number.toLowerCase().indexOf(filterSearch.toLowerCase()) > -1));
const ordersFiltererSorter = (filter, sorting, mySubjects, myTimeZone, myGroup, orderList, isStem, isDe, orderSubjectGroups, userGroup) => {
  let orders;
  const isMobile = utils.detectMob();

  orderList = setPinnedOrders(orderList, myGroup, userGroup);
  if (filter.search) {
    orders = filterBySearch(filter.search, orderList);
  } else {
    orders = orderList;
    if (filter.time) orders = isDe ? filterByTimeDe(filter.time, orderList) : filterByTime(filter.time, orders);
    if (filter.type) orders = filterByType(filter.type, orders);

    else orders = [...orderList].filter(o => o);
    if (!isStem) {
      orders.forEach((order) => {
        order.tagsWeight = calculatetagsWeight(order, mySubjects, myTimeZone, myGroup, isDe, orderSubjectGroups, userGroup);
      });
    }

    if (Object.keys(filter).length > 0) {
      if (filter.statuses.length) {
        orders = filterByStatuses(filter.statuses, orders);
      }
      // if (filter.subject_group.length) {
      //   orders = filterBySubjectGroup(filter.subject_group, orders, orderSubjectGroups);
      // }
      orders = filterBySubject(filter.subject, orders);
      // orders = isDe
      //   ? filterBySubject(filter.subject, orders)
      //   : filterBySubjectGroup(filter.subject, orders, orderSubjectGroups);
      if (!isMobile) {
        orders = filterByOnline(filter.show_online, orders);
        orders = filterByReservedOther(filter.show_reserved, orders);
      }
      // orders = filterByRecommended(filter.recommended, orders);
      // orders = filterByHot(filter.hot, orders);
      // orders = filterByMy(filter.my, mySubjects, orders);
      // orders = filterByPrice(filter.priceMin, filter.priceMax, orders);
    }
  }
  if (sorting && sorting.field) {
    const {
      pinned, reserved, newOrders, simpleOrders,
    } = orders.reduce((prev, order) => {
      if (order.is_pinned) {
        prev.pinned.push(order);
        return prev;
      }
      if (isOrderReservedByMe(order)) {
        prev.reserved.push(order);
        return prev;
      }

      if (order.is_new_order) {
        prev.newOrders.push(order);
        return prev;
      }

      prev.simpleOrders.push(order);

      return prev;
    }, {
      pinned: [],
      reserved: [],
      newOrders: [],
      simpleOrders: [],
    });
    orders = stable(simpleOrders, sortByOrderNumber);
    const sorter = getSorterByField(sorting.field, sorting.isAsc);
    orders = stable(orders, sorter);

    return [...reserved, ...pinned.sort((a, b) => b.tagsWeight - a.tagsWeight), ...newOrders, ...orders];
  }
  // const recommendedSorter = getSorterByField('recommended', false);
  if (!isStem) {
    const tagsWeightSorter = getSorterByField('tagsWeight', false);
    return stable(orders, tagsWeightSorter);
  }
  return orders;
};

export const getOrdersFilteredSorted = {
  [ORDERS_TYPE_ALL]: createSelector([getFilter[ORDERS_TYPE_ALL], getSorting, getMySubjects, getMyTimeZone, getMyGroup, getOrderList[ORDERS_TYPE_ALL], getIsStem, getUserIsDe, getOrderSubjectGroups, getUserGroup], ordersFiltererSorter),
  [ORDERS_TYPE_FAVORITE]: createSelector([getFilter[ORDERS_TYPE_FAVORITE], getSorting, getMySubjects, getMyTimeZone, getMyGroup, getOrderList[ORDERS_TYPE_FAVORITE], getIsStem, getUserIsDe, getOrderSubjectGroups], ordersFiltererSorter),
  [ORDERS_TYPE_RESERVED]: createSelector([getFilter[ORDERS_TYPE_RESERVED], getSorting, getMySubjects, getMyTimeZone, getMyGroup, getOrderList[ORDERS_TYPE_RESERVED], getIsStem, getUserIsDe, getOrderSubjectGroups], ordersFiltererSorter),
};

const getCounts = (step, orders, dedlines, orderTypes, orderSubjects, orderSubjectGroups) => {
  switch (step) {
    case 'times':
      return deadlinesCounter(dedlines, orders);
    case 'types':
      return typesCounter(orderTypes, orders);
    case 'subjects':
      return subjectsCounter(orderSubjects, orders);
    case 'subjectGroups':
      return subjectGroupsCounter(orderSubjectGroups, orders);
    default:
      break;
  }
};

const checkAndFilter = (filter, type, orderList, orderSubjectGroups) => {
  switch (type) {
    case 'times':
      return filterByTime(filter.time, orderList);
    case 'types':
      return filterByType(filter.type, orderList);
    case 'subjects':
      return filterBySubject(filter.subject, orderList);
    case 'subjectGroups':
      return filterBySubjectGroup(filter.subject_group, orderList, orderSubjectGroups);
    default:
      break;
  }
};

const ordersFiltererSorterCounts = (filter, myGroup, userGroup, dedlines, orderTypes, orderSubjects, orderSubjectGroups, orderList, pinnedOrderList) => {
  const filterList = ['times', 'types', 'subjects'];
  let orders;
  let arCounts;
  if (!pinnedOrderList.length && orderList.length) {
    orderList = setPinnedOrders(orderList, myGroup, userGroup);
  }

  if (filter.search) {
    orders = filterBySearch(filter.search, orderList);
  } else {
    orders = orderList.filter(o => !o.is_pinned && !o.reserved_by_me);
    const { steps } = filter;

    if (steps.length > 0) {
      const allSteps = uniq([...steps, ...filterList]);
      if (steps[0] === 'subjects') {
        arCounts = {
          ...arCounts,
          subjectGroups: getCounts('subjectGroups', orders, dedlines, orderTypes, orderSubjects, orderSubjectGroups),
        };
      }
      arCounts = {
        ...arCounts,
        [steps[0]]: getCounts(steps[0], orders, dedlines, orderTypes, orderSubjects, orderSubjectGroups),
      };
      orders = checkAndFilter(filter, steps[0], orders, orderSubjectGroups);

      steps.forEach((st, idx) => {
        orders = checkAndFilter(filter, st, orders, orderSubjectGroups);
        allSteps.slice(idx + 1).forEach((step) => {
          if (step === 'subjects') {
            arCounts = {
              ...arCounts,
              subjectGroups: getCounts('subjectGroups', orders, dedlines, orderTypes, orderSubjects, orderSubjectGroups),
            };
          }
          arCounts = {
            ...arCounts,
            [step]: getCounts(step, orders, dedlines, orderTypes, orderSubjects, orderSubjectGroups),
          };
        });
      });
    } else {
      orders = orderList.filter(o => !o.is_pinned && !o.reserved_by_me);
      filterList.forEach(tf =>
        arCounts = {
          ...arCounts,
          [tf]: getCounts(tf, orders, dedlines, orderTypes, orderSubjects, orderSubjectGroups),
        });
      arCounts = {
        ...arCounts,
        subjectGroups: getCounts('subjectGroups', orders, dedlines, orderTypes, orderSubjects, orderSubjectGroups),
      };
    }
    return arCounts;
  }
};

export const getOrdersFilteredSortedCounts = {
  [ORDERS_TYPE_ALL]: createSelector([getFilter[ORDERS_TYPE_ALL], getMyGroup, getUserGroup, getOrderDeadlines, getOrderTypes, getOrderSubjects, getOrderSubjectGroups, getOrderList[ORDERS_TYPE_ALL], getPinnedOrders], ordersFiltererSorterCounts),
  [ORDERS_TYPE_FAVORITE]: createSelector([getFilter[ORDERS_TYPE_FAVORITE], getMyGroup, getUserGroup, getOrderDeadlines, getOrderTypes, getOrderSubjects, getOrderSubjectGroups, getOrderList[ORDERS_TYPE_FAVORITE], getPinnedOrders], ordersFiltererSorterCounts),
  [ORDERS_TYPE_RESERVED]: createSelector([getFilter[ORDERS_TYPE_RESERVED], getMyGroup, getUserGroup, getOrderDeadlines, getOrderTypes, getOrderSubjects, getOrderSubjectGroups, getOrderList[ORDERS_TYPE_RESERVED], getPinnedOrders], ordersFiltererSorterCounts),
};

export const getAllOrdersByInitialFilters = (state) => {
  let orders = state.ordersFind.numbers.map(number => state.orders[number]);
  orders = filterByOnline(true, orders);
  orders = filterByReservedOther(false, orders);

  return orders;
};
