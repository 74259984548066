import React, { Component } from 'react';
import moment from 'moment-timezone';

import { getTokenExpire } from './../../utils/tokenStorage';

import bigClock from './../../img/icons/big-clock-icon.svg';

const millisecondsToStr = (millseconds) => {
  const duration = moment.duration(millseconds);
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

class SessionPromptModal extends Component {
  constructor(props) {
    super(props);

    const promptVal = getTokenExpire();
    const promptDate = moment.unix(promptVal);
    const timeoutToLogout = promptDate.diff(moment());

    this.state = {
      countdown: timeoutToLogout,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(state => ({
        countdown: state.countdown - 1000,
      }));
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { onBtnClick } = this.props;
    const { countdown } = this.state;
    const countdownStr = millisecondsToStr(countdown);
    return (
      <div className="modal-wrap">
        <div className="modal-container modal-sm ta-center">
          <img className="modal-icon" src={bigClock} alt="" />
          <h2 className="title mb15">Are you still here?</h2>
          <p className="modal-text">
            We need to know, are you still working or already finished. Please confirm that you are online - in other case you will be logged out.
          </p>
          <div className="modal-btn">
            <button
              type="button"
              className="btn btn-bright btn-big"
              onClick={onBtnClick}
            >Yes. I’m here</button>
          </div>
          <p className="modal-note">Automatical log out in: {countdownStr}</p>
        </div>
      </div>
    );
  }
}

export default SessionPromptModal;
