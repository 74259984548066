import React, { Component } from 'react';
import { Collapse } from 'react-collapse';

import classNames from 'utils/classNames';

class QACollapseItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(state => Object.assign({}, state, {
      isOpen: !state.isOpen,
    }));
  }

  render() {
    const {
      title, children, isError, titleCounters,
    } = this.props;
    const { isOpen } = this.state;
    const arrowClassName = classNames('arrow-down', { active: isOpen });
    const className = classNames('person-info-item', { 'is-error': isError }, { active: isOpen });
    return (
      <section className={className}>
        <div onClick={this.toggle}>
          <h3>{title}</h3>
          <span className="cnt">{titleCounters}</span>
          <span className={arrowClassName} />
        </div>
        <Collapse isOpened={isOpen}>
          {children}
        </Collapse>
      </section>
    );
  }
}

export default QACollapseItem;
